import { Injectable } from '@angular/core';
import { API } from 'src/app/services/Api.service';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { switchMap, map, catchError, tap, take } from 'rxjs/operators';
import { of, Observable } from 'rxjs';
import { NgxPermissionsService } from 'ngx-permissions';

// tslint:disable-next-line:max-line-length
import {
  Login,
  LoginSuccess,
  GetUserDetails,
  GetUserRoles,
  GetUserCapabilites,
  GetUserRolesSuccess,
  GetRegisterUserStartAction,
  GetRegisterUserSuccessAction,
  GetUserDetailsSuccess,
  GetUserCapabilitesSuccess,
  ErrorAction,
  ForgotPassword,
  ForgotPasswordVerify,
  ResetPassword,
  SetNewPassword,
  ForgotPasswordSuccess,
  ClearAction,
  GetOtp,
  VerifyOtp,
  VerifyOtpSuccess,
  ResendOtp,
  RegisterLinkValidate,
  GetIpAddress,
  GetIpAddressSuccess,
  RecaptchaPermissionStart,
  RecaptchaPermissionSuccess,
  SendOtpToAdminMail,
  SendOtpToMailSuccess,
  VerifyOtpStart,
  VerifyOtpSuccessForAdmin,
  Logout,
  LogoutSuccess,
} from './auth.actions';
import { AuthActionTypes } from './auth.actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { NotificationsService } from 'angular2-notifications';
import { LoaderHide } from 'src/app/features/loader/store/loader.actions';
import { GetUserDetailsByAdmin } from 'src/app/features/home/profile/store/profile.actions';
import { GetWebNotificationCount } from '../../features/home/notifications/store/notifications.actions'

@Injectable()
export class AuthEffects {
  @Effect({ dispatch: false })
  login$ = this.action$.pipe(
    ofType(AuthActionTypes.LOGIN),
    switchMap((action: Login) => {
      return this.api.login(action.payload).pipe(
        map((response: any) => {
          const token = response.token;
          // window.localStorage.setItem('auth', response.token);
          this.store.dispatch(new LoginSuccess(token));
          this.store.dispatch(new GetUserDetails());
          this.store.dispatch(new GetUserRoles());
          this.store.dispatch(new GetUserCapabilites());
          this.store.dispatch(new GetWebNotificationCount())
          let routePermission = [
            {
              path: "user-management",
              permission: ["activate-deactivate-user", "bulk-invite", "edit-user-profile", "change-user-role", "create-user", "replace-user"]
            },
            {
              path: "program-dashboard/project",
              permission: ["project-view"]
            },
            {
              path: "resources",
              permission: ["all"]
            },
            {
              path: "messages",
              permission: ["all"],
            },
            {
              path: "role-management",
              permission: ["display-role-management"]
            },
          ]

          let breakFlag = false
          let selectedPath

          this.store.select(store => store.auth.user['capabilities']).pipe(take(2)).subscribe(
            res => {
              if (res) {
                if (res.length > 0) {
                  const allCapabilities = (res || []).reduce((acc, item) => {
                    return [...acc, ...item.capabilities];
                  }, []);
                  if (allCapabilities) {
                    for (let i = 0; i < routePermission.length; i++) {
                      breakFlag = false
                      if (routePermission[i].permission) {
                        for (let j = 0; j < routePermission[i].permission.length; j++) {
                          if (allCapabilities.includes(routePermission[i].permission[j])) {
                            selectedPath = routePermission[i].path
                            breakFlag = true
                            break
                          }
                        }
                      }
                      if (breakFlag) {
                        this.router.navigate([`/home/${selectedPath}`])
                        break
                      }
                    }

                    if (!selectedPath) {
                      this.router.navigate([`/home/`])
                    }
                  }
                }
              }
            })
        }),
        catchError(err => {
          if (err) {
            this.notificationService.error(err.error.errors[0].error);
          }
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  getUserDetails$ = this.action$.pipe(
    ofType(AuthActionTypes.GET_USER_DETAILS),
    switchMap((action: GetUserDetails) => {
      return this.api.getUserDetails().pipe(
        map((response: any) => {
          return new GetUserDetailsSuccess(response);
        }),
        catchError(err => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  getUserRoles$ = this.action$.pipe(
    ofType(AuthActionTypes.GET_USER_ROLES),
    switchMap((action: GetUserRoles) => {
      return this.api.getUserRoles().pipe(
        map((response: any) => {
          return new GetUserRolesSuccess(response.roles);
        }),
        catchError(err => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  getCapabilities$ = this.action$.pipe(
    ofType(AuthActionTypes.GET_USER_CAPABILTITES),
    switchMap((action: GetUserCapabilites) => {
      return this.api.getUserCapabilities().pipe(
        map((response: any) => {
          return new GetUserCapabilitesSuccess(response);
        }),
        catchError(err => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  $userRegister = this.action$.pipe(
    ofType(AuthActionTypes.GET_USER_DATA),
    switchMap((action: GetRegisterUserStartAction) => {
      console.log(action.data);
      return this.api.getRegisterData(action.token, action.data).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success('Thank you for registering on CMP. Please login with your registered Email address and password to continue');
            return new GetRegisterUserSuccessAction(response);
          }
        }),
        catchError((err) => {
          this.store.dispatch(new LoaderHide());
          return of(new ErrorAction(err))
        })
      );
    })
  );

  @Effect()
  $forgotPassword = this.action$.pipe(
    ofType(AuthActionTypes.FORGOT_PASSWORD),
    switchMap((action: ForgotPassword) => {
      return this.api.forgotPassword(action.email).pipe(
        map((response: any) => {
          // this.router.navigate(['user', 'verify-forgot-password'], {queryParams: {email: response.email}});
          this.notificationService.success('OTP has been sent to your registered email address and phone number');
          return new ForgotPasswordSuccess(response);
        }),
        // catchError((error) => {
        //     if (error) {
        //         this.notificationService.error('Email ID not registered.');
        //     }
        //     return of(new ErrorAction(error));
        // })
        catchError(err => of(new ErrorAction(err)))
      );
    })
  );

  @Effect({ dispatch: false })
  $forgotPasswordVerify = this.action$.pipe(
    ofType(AuthActionTypes.FORGOT_PASSWORD_VERIFY),
    switchMap((action: ForgotPasswordVerify) => {
      return this.api.forgotPasswordVerify(action.payload).pipe(
        map((response: any) => {
          this.router.navigate(['user', 'set-password'], { skipLocationChange: true, queryParams: { userId: response.id } });
          this.notificationService.success('OTP verified successfully');
        }),
        catchError((error) => {
          if (error) {
            this.notificationService.error('Incorrect OTP');
          }
          return of(new ErrorAction(error));
        })
      );
    })
  );

  @Effect({ dispatch: false })
  $setNewPassword = this.action$.pipe(
    ofType(AuthActionTypes.SET_NEW_PASSWORD),
    switchMap((action: SetNewPassword) => {
      return this.api.setNewPassword(action.password, action.id).pipe(
        map((response: any) => {
          this.notificationService.success('Password changed successfully.');
          this.router.navigate(['user', 'login']);
        }),
        catchError(error => of(new ErrorAction(error)))
      );
    })
  );

  @Effect({ dispatch: false })
  $resetPassword = this.action$.pipe(
    ofType(AuthActionTypes.RESET_PASSWORD),
    switchMap((action: ResetPassword) => {
      return this.api.resetPassword(action.password, action.token).pipe(
        map((response: any) => {
          this.notificationService.success('Password reset successful.');
          this.router.navigate(['user', 'login']);
        }),
        catchError(err => of(new ErrorAction(err)))
      );
    })
  );

  @Effect({ dispatch: false })
  $getOtp = this.action$.pipe(
    ofType(AuthActionTypes.GET_OTP),
    switchMap((action: GetOtp) => {
      return this.api.getRegistrationOtp(action.id, action.payload).pipe(
        map((response: any) => {
          this.notificationService.success(response.message);
        }),
        catchError(err => of(new ErrorAction(err)))
      )
    })
  );

  @Effect()
  $verifyOtp = this.action$.pipe(
    ofType(AuthActionTypes.VERIFY_OTP),
    switchMap((action: VerifyOtp) => {
      return this.api.verifyRegistrationOtp(action.id, action.payload).pipe(
        map((response: any) => {
          this.notificationService.success(response.message);
          return new VerifyOtpSuccess(response);
        }),
        catchError(err => of(new ErrorAction(err)))
      )
    })
  );

  @Effect({ dispatch: false })
  $resendOtp = this.action$.pipe(
    ofType(AuthActionTypes.RESEND_OTP),
    switchMap((action: ResendOtp) => {
      return this.api.resendRegistrationOtp(action.id, action.payload).pipe(
        map((response: any) => {
          this.notificationService.success(response.message);
        }),
        catchError(err => of(new ErrorAction(err)))
      )
    })
  );

  @Effect({ dispatch: false })
  $validateRegisterLink = this.action$.pipe(
    ofType(AuthActionTypes.REGISTER_LINK_VALIDATE),
    switchMap((action: RegisterLinkValidate) => {
      return this.api.validateRegisterLink(action.token).pipe(
        map((response) => {

        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          // this.router.navigate(['user', 'login']);
          return of(new ErrorAction(err))
        })
      )
    })
  );

  @Effect()
  $getIpAddress = this.action$.pipe(
    ofType(AuthActionTypes.GET_IP_ADDRESS),
    switchMap((action: GetIpAddress) => {
      return this.api.getIpAddress().pipe(
        map((response: any) => {
          return new GetIpAddressSuccess(response);
        }),
        catchError(err => of(new ErrorAction(err)))
      )
    })
  );

  // @Effect()
  // $errorAction = this.action$.pipe(
  //     ofType(AuthActionTypes.ERROR),
  //     tap((action: ErrorAction) => {
  //         console.log(action.error.error, "error");
  //         const error = (action.error || {}).error;
  //         let errorMsg = "";
  //         if (error && error.error) {
  //             errorMsg = error.error;
  //         } else {
  //             errorMsg = "something went wrong";
  //         }
  //         this.notificationService.error(errorMsg);
  //     }),
  // );

  // tslint:disable-next-line:max-line-length

  @Effect()
  reCaptcha$ = this.action$.pipe(
    ofType(AuthActionTypes.RECAPTCHA_PERMISSION_START),
    switchMap((action: RecaptchaPermissionStart) => {
      return this.api.getRecaptchaPermission().pipe(
        map((resp: any) => {
          return new RecaptchaPermissionSuccess(resp);
        }),
        catchError(err => of(new ErrorAction(err)))
      )
    })
  );

  @Effect()
  getOtp$ = this.action$.pipe(
    ofType(AuthActionTypes.GET_OTP_START),
    switchMap((action: SendOtpToAdminMail) => {
      return this.api.getOtp(action.payload, action.id).pipe(
        map((res: any) => {
          this.notificationService.success(res.message);
          return new SendOtpToMailSuccess(res)
        }),
        catchError(err => of(new ErrorAction(err)))
      )
    })
  );

  @Effect()
  OtpVerification$ = this.action$.pipe(
    ofType(AuthActionTypes.VERIFY_OTP_START),
    switchMap((action: VerifyOtpStart) => {
      return this.api.verifyOtp(action.payload, action.id).pipe(
        map((res: any) => {
          this.notificationService.success(res.message || 'OTP verified successfully');
          this.store.dispatch(new GetUserDetailsByAdmin(action.id));
          return new VerifyOtpSuccessForAdmin(res)
        }),
        catchError(err => of(new ErrorAction(err)))
      )
    })
  );

  @Effect({ dispatch: false })
  logout$ = this.action$.pipe(
    ofType(AuthActionTypes.LOGOUT),
    switchMap((action: Logout) => {
      return this.api.logout(action.userId, action.token).pipe(
        map((response: any) => {
          this.ngxPermissionService.flushPermissions();
          this.store.dispatch(new LogoutSuccess());
          this.router.navigate(['user/login']);
        }),
        catchError((error) => {
          return of(new ErrorAction(error));
        })
      );
    })
  );

  constructor(
    private store: Store<AppState>,
    private api: API,
    private action$: Actions,
    private router: Router,
    private notificationService: NotificationsService,
    private ngxPermissionService: NgxPermissionsService,
  ) { }
}
