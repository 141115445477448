import { Action } from '@ngrx/store';

export enum TasksActionTypes {
  GET_TASK_LIST = "[TASK MGMT] Get Tasks List",
  GET_TASK_LIST_SUCCESS = "[TASK MGMT] Get Tasks List Success",
  GET_TAGS_LIST = "[TASK MGMT] Get Tags List",
  GET_TAGS_LIST_SUCCESS = "[TASK MGMT] Get Tags List Success",
  ADD_NEW_TASK = "[TASK MGMT] Add New Task",
  GET_TASK_DETAILS = "[TASK MGMT] Get Task Details",
  GET_TASK_DETAILS_SUCCESS = "[TASK MGMT] Get Task Details Success",
  UPDATE_TASK_DETAILS = "[TASK MGMT] Update Task Details",
  GET_APPROVERS = "[approvers] start action",
  GET_APPROVERS_SUCCESS = "[approvers] success action",
  ADD_TAG_START = "[tag add start]",
  ADD_TAG_SUCCESS = '[tag add success]',
  GET_ASSIGNED_TASK_LIST = '[get assigned task ]',
  GET_CREATED_TASK_LIST = '[get created task list]',
  GET_LINKED_TASKS = '[TASK MGMT] Get Linked tasks',
  GET_LINKED_TASKS_SUCCESS = '[TASK MGMT] Get Linked tasks',
  GET_SUB_TASKS = '[TASK MGMT] Get subtasks',
  GET_SUB_TASKS_SUCCESS = '[TASK MGMT] Get subtasks success',
  MOVE_TASK_TO_NEXT_STATE = '[TASK MGMT] Move task to next state',
  CREATE_SUBTASKS = '[TASK MGMT] Create subtask',
  UPDATE_TASK_DETAILSSUCCESS = '[Update task details] success',
  SEARCH_TASK_Id_START = '[Search task list start]',
  SEARCH_TASK_Id_SUCCESS = '[Search task list success]',
  CANCEL_TASK = '[Cancel Task]',
  CLEAR_TAG = '[cancel tag]',
  DELETE_DOCUMENT_IN_TASK_START = 'delete start',
  GET_ACTIVITY_LOG = '[TASK MGMT] Get Activity Log',
  GET_ACTIVITY_LOG_SUCCESS = '[TASK MGMT] Get Activity Log Success',
  TASK_SUCCESS = '[Task Success Action] common suuccess Action',
  GET_ALL_TASK_FILTERS = '[Task Filter] GET ALL TASK FILTERS',
  GET_ALL_TASK_FILTERS_SUCCESS = '[Task Filter] GET ALL TASK FILTERS SUCCESS',
  GET_CREATEDBYME_TASK_FILTERS = '[Task Filter] GET CREATEDBYME TASK FILTERS',
  GET_CREATEDBYME_TASK_FILTERS_SUCCESS = '[Task Filter] GET CREATEDBYME TASK FILTERS SUCCESS',
  GET_ASSIGNEDTOME_TASK_FILTERS = '[Task Filter] GET ASSIGNEDTOME TASK FILTERS',
  GET_ASSIGNEDTOME_TASK_FILTERS_SUCCESS = '[Task Filter] GET ASSIGNEDTOME TASK FILTERS SUCCESS',
  GET_FILTERED_TASKS = '[Tasks] Get Filtered Tasks',
  GET_FILTERED_TASKS_SUCCESS = '[Tasks] Get Filtered Tasks Success',
  SEARCH_TASKS_START = '[Tasks] search tasks start',
  SEARCH_TASKS_SUCCESS = '[Tasks] search tasks success',
  GET_ALL_CMP_TASK_START = '[get all tasks start]',
  GET_ALL_CMP_TASKS_SUCCESS = '[get all cmp task success]',
  GET_ENDORSE_OTP = '[Tasks] Get Endorse Otp',
  GET_ENDORSE_OTP_SUCCESS = '[Task] Get Endorse Otp Success',
  VERIFY_ENDORSE_OTP = '[Task] Verify Endorse Otp',
  VERIFY_ENDORSE_OTP_SUCCESS = '[Task] Verify Endorse Otp Success',
  ADD_SUGGESTED_TASK_TAGS = '[Task] Add suggested Tags',
  APPROVE_SUGGESTED_TASK_TAGS = '[Task] Approve suggested Tags',
  REJECT_SUGGESTED_TASK_TAGS = '[Task] Reject suggested Tags',
  DELETE_SUGGESTED_TASK_TAGS = '[Task] Delete suggested Tags',
  UNLINK_TASKS = "UNLINK_TASKS",
  VIEW_TASK_DETAILS = '[Task] View Task Details',
  VIEW_TASK_DETAILS_SUCCESS = '[Task] View Task Details Success',
  CLEAR_ACTION = '[Task] Clear Action',
  FILTER_APPLIED = '[Task] Filter Applied',
  EDIT_DUE_DATE = '[Task] Edit Due Date',
  GET_ALL_CMP_TASK_FILTERS = '[Task] Get AllCmp Tasks Filters',
  GET_ALL_CMP_TASK_FILTERS_SUCCESS = '[Task] Get AllCmp Tasks Filters Success',
  GET_INACTIVE_REPLACED_USER = '[Task] get Inactive Replaced user',
  GET_INACTIVE_REPLACED_USER_SUCCESS = '[Task] get Inactive Replaced user Success' ,
  UPDATE_PACKET_DETAILS='[Task] update task details',
  UPDATE_PACKET_DETAILS_SUCCESS='[Task] update task details success',
  CREATE_PACKET_DETAILS='[Task] create task details',
  CREATE_PACKET_DETAILS_SUCCESS='[Task] create task details success'
}

export class GetTaskListStartAction implements Action {
  readonly type = TasksActionTypes.GET_TASK_LIST;
  constructor() { }
}

export class GetTasksListSuccessAction implements Action {
  readonly type = TasksActionTypes.GET_TASK_LIST_SUCCESS;
  constructor(public data: any) { }
}

export class GetAllTagsListStartAction implements Action {
  readonly type: string = TasksActionTypes.GET_TAGS_LIST;
  constructor(public tagname: object) { }
}

export class GetAllTagsListSuccessAction implements Action {
  readonly type: string = TasksActionTypes.GET_TAGS_LIST_SUCCESS;
  constructor(public response: any) { }
}

export class AddNewTask implements Action {
  readonly type: string = TasksActionTypes.ADD_NEW_TASK;
  constructor(public payload: any) { }
}

export class GetTaskDetails implements Action {
  readonly type: string = TasksActionTypes.GET_TASK_DETAILS;
  constructor(public taskId: string) { }
}
///
export class GetTaskDetailsSuccess implements Action {
  readonly type: string = TasksActionTypes.GET_TASK_DETAILS_SUCCESS;
  constructor(public response: any) { }
}

export class GetLinkedTasks implements Action {
  readonly type: string = TasksActionTypes.GET_LINKED_TASKS;
  constructor(public taskId: string) { }
}

export class GetLinkedTasksSuccess implements Action {
  readonly type: string = TasksActionTypes.GET_LINKED_TASKS_SUCCESS;
  constructor(public response: any) { }
}

export class GetSubtasks implements Action {
  readonly type: string = TasksActionTypes.GET_SUB_TASKS;
  constructor(public taskId: string) { }
}

export class GetSubtaskSuccess implements Action {
  readonly type: string = TasksActionTypes.GET_SUB_TASKS_SUCCESS;
  constructor(public response: any) { }
}

export class MoveTaskToNextState implements Action {
  readonly type: string = TasksActionTypes.MOVE_TASK_TO_NEXT_STATE;
  constructor(public taskId: any, public action: any, public body: any) { }
}

export class UpdateTaskDetails implements Action {
  readonly type: string = TasksActionTypes.UPDATE_TASK_DETAILS;
  constructor(public taskId: string, public payload: any) { }
}
export class AddTagStartAction implements Action {
  readonly type: string = TasksActionTypes.ADD_TAG_START;
  constructor(public tag: object) { }
}
export class AddTagSuccessAction implements Action {
  readonly type: string = TasksActionTypes.ADD_TAG_SUCCESS;
  constructor(public response: any) { }
}
export class GetAssignedTaskListStartAction implements Action {
  readonly type: string = TasksActionTypes.GET_ASSIGNED_TASK_LIST;
  constructor() { }
}
export class GetCreatedTaskListStartAction implements Action {
  readonly type: string = TasksActionTypes.GET_CREATED_TASK_LIST;
  constructor() { }
}

export class CreateSubTask implements Action {
  readonly type: string = TasksActionTypes.CREATE_SUBTASKS;
  constructor(public taskId: any, public payload: any) { }
}

export class CancelTask implements Action {
  readonly type: string = TasksActionTypes.CANCEL_TASK;
  constructor(public taskId: any) { }
}
export class SearchTaskIdStartAction implements Action {
  readonly type: string = TasksActionTypes.SEARCH_TASK_Id_START;
  constructor(public taskId, public search: object) { }
}
export class ClearTagAction implements Action {

  readonly type: string = TasksActionTypes.CLEAR_TAG;
  constructor() { }
}
export class DeleteDocumentInTaskStartAction implements Action {
  readonly type: string = TasksActionTypes.DELETE_DOCUMENT_IN_TASK_START;
  constructor(public taskId: string, public documentId: any) { }

}
// export class DeleteDocumentInTaskSuccessAction implements Action {
//   readonly type :string =TasksActionTypes.DELETE_DOCUMENT_INTASK_SUCCESS;
//   constructor(public ) {}
// }

export class GetActivityLog implements Action {
  readonly type: string = TasksActionTypes.GET_ACTIVITY_LOG;
  constructor(public taskId: string) { }
}

export class GetActivityLogSuccess implements Action {
  readonly type: string = TasksActionTypes.GET_ACTIVITY_LOG_SUCCESS;
  constructor(public response: any) { }
}
export class CommonSuccessForTask implements Action {
  readonly type: string = TasksActionTypes.TASK_SUCCESS;
  constructor() { }
}

export class GetAllFiltersList implements Action {
  readonly type: string = TasksActionTypes.GET_ALL_TASK_FILTERS;
  constructor() { }
}

export class GetAllFiltersListSuccess implements Action {
  readonly type: string = TasksActionTypes.GET_ALL_TASK_FILTERS_SUCCESS;
  constructor(public response: any) { }
}

export class GetCreatedByMeFiltersList implements Action {
  readonly type: string = TasksActionTypes.GET_CREATEDBYME_TASK_FILTERS;
  constructor() { }
}

export class GetCreatedByMeFiltersListSuccess implements Action {
  readonly type: string = TasksActionTypes.GET_CREATEDBYME_TASK_FILTERS_SUCCESS;
  constructor(public response: any) { }
}

export class GetAssignedToMeFiltersList implements Action {
  readonly type: string = TasksActionTypes.GET_ASSIGNEDTOME_TASK_FILTERS;
  constructor() { }
}

export class GetAssignedToMeFiltersListSuccess implements Action {
  readonly type: string = TasksActionTypes.GET_ASSIGNEDTOME_TASK_FILTERS_SUCCESS;
  constructor(public response: any) { }
}

export class GetAllCMPTasksFilters implements Action {
  readonly type: string = TasksActionTypes.GET_ALL_CMP_TASK_FILTERS;
  constructor() { }
}

export class GetAllCMPTasksFiltersSuccess implements Action {
  readonly type: string = TasksActionTypes.GET_ALL_CMP_TASK_FILTERS_SUCCESS;
  constructor(public response: any) { }
}

export class GetFilteredTasks implements Action {
  readonly type: string = TasksActionTypes.GET_FILTERED_TASKS;
  constructor(public payload: any, public params: any) { }
}

export class GetFilteredTasksSuccess implements Action {
  readonly type: string = TasksActionTypes.GET_FILTERED_TASKS_SUCCESS;
  constructor(public tasks: any) { }
}
export class SearchTasksStart implements Action {
  readonly type: string = TasksActionTypes.SEARCH_TASKS_START;
  constructor(public filterType: string, public searchString: string, public pageNumber?: number, public limit?: number) { }
}
export class SearchTasksSuccess implements Action {
  readonly type: string = TasksActionTypes.SEARCH_TASKS_SUCCESS;
  constructor(public response: any) { }
}
export class GetAllCmpTasksList implements Action {
  readonly type: string = TasksActionTypes.GET_ALL_CMP_TASK_START;
  constructor() { }
}
export class GetAllCmpTasksSuccess implements Action {
  readonly type: string = TasksActionTypes.GET_ALL_CMP_TASKS_SUCCESS;
  constructor(public res: any) { }
}

export class GetEndorseOtp implements Action {
  readonly type: string = TasksActionTypes.GET_ENDORSE_OTP;
  constructor() { }
}

export class GetEndorseOtpSuccess implements Action {
  readonly type: string = TasksActionTypes.GET_ENDORSE_OTP_SUCCESS;
  constructor(public response: any) { }
}

export class VerifyEndorseOtp implements Action {
  readonly type: string = TasksActionTypes.VERIFY_ENDORSE_OTP;
  constructor(public taskId: string, public payload: any) { }
}

export class VerifyEndorseOtpSuccess implements Action {
  readonly type: string = TasksActionTypes.VERIFY_ENDORSE_OTP_SUCCESS;
  constructor(public response: any) { }
}

export class SuggestTaskTags implements Action {
  readonly type: string = TasksActionTypes.ADD_SUGGESTED_TASK_TAGS;
  constructor(public id: any, public payload: any) { }
}

export class ApproveTaskTags implements Action {
  readonly type: string = TasksActionTypes.APPROVE_SUGGESTED_TASK_TAGS;
  constructor(public id: any, public payload: any) { }
}

export class RejectTaskTags implements Action {
  readonly type: string = TasksActionTypes.REJECT_SUGGESTED_TASK_TAGS;
  constructor(public id: any, public payload: any) { }
}

export class DeleteSuggestTaskTags implements Action {
  readonly type: string = TasksActionTypes.DELETE_SUGGESTED_TASK_TAGS;
  constructor(public id: any, public payload: any) { }
}

export class UnlinkTasks implements Action {
  readonly type: string = TasksActionTypes.UNLINK_TASKS;
  constructor(public payload: any) { }
}

export class ViewTaskDetails implements Action {
  readonly type: string = TasksActionTypes.VIEW_TASK_DETAILS;
  constructor(public projectId: string, public taskId: string) { }
}

export class ViewTaskDetailsSuccess implements Action {
  readonly type: string = TasksActionTypes.VIEW_TASK_DETAILS_SUCCESS;
  constructor(public response: any) { }
}

export class ClearAction implements Action {
  readonly type: string = TasksActionTypes.CLEAR_ACTION;
  constructor() { }
}

export class FilterApplied implements Action {
  readonly type: string = TasksActionTypes.FILTER_APPLIED;
  constructor(public selectedFilter: any) { }
}

export class EditDueDate implements Action {
  readonly type: string = TasksActionTypes.EDIT_DUE_DATE;
  constructor(public date: any, public id: any) { }
}

export class GetInactiveReplacedUsers implements Action {
  readonly type: string = TasksActionTypes.GET_INACTIVE_REPLACED_USER
  constructor(public taskId: string) {
  }
}

export class GetInactiveReplacedUsersSuccess implements Action {
  readonly type: string = TasksActionTypes.GET_INACTIVE_REPLACED_USER_SUCCESS
  constructor(public response: any) {
  }
}
  export class updatePacketDetails implements Action {
    readonly type: string = TasksActionTypes.UPDATE_PACKET_DETAILS;
    constructor(public taskId: any,public payload: any) { }
  }
  
  export class updatePacketDetailsSuccess implements Action {
    readonly type: string = TasksActionTypes.UPDATE_PACKET_DETAILS_SUCCESS;
    constructor(public response: any) { }
  }


  export class createPacketDetails implements Action {
    readonly type: string = TasksActionTypes.CREATE_PACKET_DETAILS;
    constructor(public taskId: any,public projectID: any,public payload: any) { }
  }
  
  export class createPacketDetailsSuccess implements Action {
    readonly type: string = TasksActionTypes.CREATE_PACKET_DETAILS_SUCCESS;
    constructor(public response: any) { }
  }
