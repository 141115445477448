import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { Observable, of } from "rxjs";
import { environment } from "src/environments/environment";
export const BASE_URL = environment.host;

@Injectable()
export class ContractManagementService {


    constructor(private http: HttpClient) { }

    getAllContracts(type) {
        return this.http.get(`${BASE_URL}/contract/v1/contract/list`, { params: type });
        // return of([{
        //     'id': '1234',
        //     'contractno': '123CMS42',
        //     'name': 'Establishing City WiFi',
        //     'owner': {
        //         'firstName': 'USER 1',
        //     },
        //     'startDate': new Date(Date.now()).getMilliseconds(),
        //     'endDate': new Date(Date.now()).getMilliseconds(),
        //     'value': '20000',
        //     'status': 'Active'

        // },
        // {
        //     'id': '1234',
        //     'contractno': '123CMS42',
        //     'name': 'Establishing City WiFi',
        //     'owner': {
        //         'firstName': 'USER 1',
        //     },
        //     'startDate': new Date(Date.now()).getMilliseconds(),
        //     'endDate': new Date(Date.now()).getMilliseconds(),
        //     'value': '20000',
        //     'status': 'Closed'

        // }]);
    }

    getMyContracts(type) {
        return this.http.get(`${BASE_URL}/contract/v1/contract/list`, { params: type });
        return of([{
            'id': '1234',
            'contractno': '123CMS42',
            'name': 'Establishing City WiFi',
            'owner': {
                'firstName': 'USER 1',
            },
            'startDate': new Date(Date.now()).getMilliseconds(),
            'endDate': new Date(Date.now()).getMilliseconds(),
            'value': '20000',
            'status': 'Active'
        }]);
    }

    createContract(payload) {
        return this.http.post(`${BASE_URL}/contract/v1/contract/create`, {
            ...payload
        });
    }
    editContract(payload) {
        return this.http.post(`${BASE_URL}/contract/v1/contract/${payload._id}/edit`, {
            ...payload
        });
    }
    getContractDetail(id,isMainView) {
        if(isMainView){
            return this.http.get(`${BASE_URL}/contract/v1/contract/detail/${id}`);
        }else{
            return this.http.get(`${BASE_URL}/contract/v1/contract/view-detail/${id}`);
        }
    }

    archiveContract(id) {
        return this.http.get(`${BASE_URL}/contract/v1/contract/archive/${id}`);
    }

    reopenContract(id) {
        return this.http.get(`${BASE_URL}/contract/v1/contract/re-open/${id}`);
    }

    closeContract(id) {
        return this.http.get(`${BASE_URL}/contract/v1/contract/close/${id}`);
    }

    getPaymentScheduleDetails(id) {
        return this.http.get(`${BASE_URL}/contract/v1/contract/${id}/phases`);
    }
    updateMilestones(id, payload) {
        return this.http.post(`${BASE_URL}/contract/v1/contract/phase/${id}/updateMileStones`, {
            ...payload
        });
    }

    updateTimesheet(payload) {
        return this.http.post(`${BASE_URL}/contract/v1/contract/project/update-timesheet`, {
            ...payload
        });
    }

    getContractActivityLog(contractID) {
        return this.http.get(`${BASE_URL}/activity/get-contract-logs/?contractId=${contractID}`);
    }

    getInvoiceActivityLog(id) {
        return this.http.get(`${BASE_URL}/activity/get-invoice-logs/?invoiceId=${id}`);
    }

    getTimesheetActivityLog(id,month,projId) {
        return this.http.get(`${BASE_URL}/activity/get-timesheet-logs/?contractId=${id}&month=${month}&projectId=${projId}`);
    }

    createInvoice(payload: any, id) {
        return this.http.post(`${BASE_URL}/contract/v1/contract/${id}/create-invoice`, {
            ...payload
        });
    }
    getInvoiceDetails(id) {
        return this.http.get(`${BASE_URL}/contract/v1/contract/${id}/invoices`);
    }

    getInvoicetDetail(id) {
        return this.http.get(`${BASE_URL}/contract/v1/contract/invoice/${id}`);
    }

    getTimeshetDetails(id) { 
        return this.http.get(`${BASE_URL}/contract/v1/contract/${id}/projectsList`);
        return of([
            {
                'id': '1234',
                'name': 'Abc Abc Abc',
                'city': 'Hyderabad'

            },
            {
                'id': '12345',
                'name': 'Our Neighbourhood',
                'city': 'Hyderabad'

            },
            {
                'id': '12346',
                'name': 'Establishing City WiFi',
                'city': 'Vizag'

            },
            {
                'id': '12347',
                'name': 'Social Inclusion',
                'city': 'Vizag'

            },
        ]);

    }

    getTimeshetProjectDetails(phaseId,projId) {
        return this.http.get(`${BASE_URL}/contract/v1/contract/project/${projId}/${phaseId}/timesheet-list`);

        return of([
            {
                'phaseName': 'phase 1',
                'id': '1234',
                'timeArray': [
                    {
                        'month': 'Jan 2020',
                        'hours': '15'
                    },
                    {
                        'month': 'Feb 2020',
                        'hours': '15'
                    },
                    {
                        'month': 'Mar 2020',
                        'hours': '15'
                    },
                    {
                        'month': 'Apr 2020',
                        'hours': '15'
                    },
                    {
                        'month': 'May 2020',
                        'hours': '15'
                    },
                    {
                        'month': 'June 2020',
                        'hours': '15'
                    },
                    {
                        'month': 'July 2020',
                        'hours': '15'
                    },
                    {
                        'month': 'Aug 2020',
                        'hours': '15'
                    },
                    {
                        'month': 'Sep 2020',
                        'hours': '15'
                    },
                    {
                        'month': 'Oct 2020',
                        'hours': '15'
                    },
                    {
                        'month': 'Nov 2020',
                        'hours': '15'
                    },
                    {
                        'month': 'Dec 2020',
                        'hours': '15'
                    },

                ]

            },
            {
                'phaseName': 'phase 2',
                'id': '12345',
                'timeArray': [
                    {
                        'month': 'Jan 2020',
                        'hours': '35'
                    },
                    {
                        'month': 'Feb 2020',
                        'hours': '35'
                    },
                    {
                        'month': 'Mar 2020',
                        'hours': '35'
                    },
                    {
                        'month': 'Apr 2020',
                        'hours': '35'
                    },
                    {
                        'month': 'May 2020',
                        'hours': '35'
                    },
                    {
                        'month': 'June 2020',
                        'hours': '35'
                    },
                    {
                        'month': 'July 2020',
                        'hours': '35'
                    },
                    {
                        'month': 'Aug 2020',
                        'hours': '35'
                    },
                    {
                        'month': 'Sep 2020',
                        'hours': '35'
                    },
                    {
                        'month': 'Oct 2020',
                        'hours': '35'
                    },
                    {
                        'month': 'Nov 2020',
                        'hours': '35'
                    },
                    {
                        'month': 'Dec 2020',
                        'hours': '35'
                    },

                ]

            },
            {
                'phaseName': 'phase 3',
                'id': '12346',
                'timeArray': [
                    {
                        'month': 'Jan 2020',
                        'hours': '45'
                    },
                    {
                        'month': 'Feb 2020',
                        'hours': '45'
                    },
                    {
                        'month': 'Mar 2020',
                        'hours': '45'
                    },
                    {
                        'month': 'Apr 2020',
                        'hours': '45'
                    },
                    {
                        'month': 'May 2020',
                        'hours': '45'
                    },
                    {
                        'month': 'June 2020',
                        'hours': '45'
                    },
                    {
                        'month': 'July 2020',
                        'hours': '45'
                    },
                    {
                        'month': 'Aug 2020',
                        'hours': '45'
                    },
                    {
                        'month': 'Sep 2020',
                        'hours': '45'
                    },
                    {
                        'month': 'Oct 2020',
                        'hours': '45'
                    },
                    {
                        'month': 'Nov 2020',
                        'hours': '45'
                    },
                    {
                        'month': 'Dec 2020',
                        'hours': '45'
                    },

                ]

            },
        ]);
    }

    getTimeshetProjectMonthDetails(projId,phaseId,payload) {
        return this.http.post(`${BASE_URL}/contract/v1/contract/project/${projId}/${phaseId}/timesheet-detail`,{ ...payload});
        return of({
            'id': '1234',
            'date': 'Jan 2020',
            'city': 'Hyderabad',
            'totalHours':'84',
            'onsitehours':'48',
            'remoteHours':'36',
            'totalDays':'10',
            'datesArray':[
                {
                    'date':'01',
                    'day' : 'monday',
                    'hours':4,
                    'loaction_type':'onsite',
                    'remarks':"dfaaasdasdasasdasdas",
                },
                {
                    'date':'02',
                    'day' : 'monday',
                    'hours':4,
                    'loaction_type':'onsite',
                    'remarks':"dfaaasdasdasasdasdas",
                },
                {
                    'date':'03',
                    'day' : 'monday',
                    'hours':4,
                    'loaction_type':'onsite',
                    'remarks':"dfaaasdasdasasdasdas",
                },
                {
                    'date':'04',
                    'day' : 'monday',
                    'hours':4,
                    'loaction_type':'onsite',
                    'remarks':"dfaaasdasdasasdasdas",
                },
                {
                    'date':'05',
                    'day' : 'monday',
                    'hours':4,
                    'loaction_type':'onsite',
                    'remarks':"dfaaasdasdasasdasdas",
                },
                {
                    'date':'06',
                    'day' : 'monday',
                    'hours':4,
                    'loaction_type':'onsite',
                    'remarks':"dfaaasdasdasasdasdas",
                },
                {
                    'date':'01',
                    'day' : 'monday',
                    'hours':4,
                    'loaction_type':'onsite',
                    'remarks':"dfaaasdasdasasdasdas",
                },
                {
                    'date':'01',
                    'day' : 'monday',
                    'hours':4,
                    'loaction_type':'onsite',
                    'remarks':"dfaaasdasdasasdasdas",
                },
                {
                    'date':'01',
                    'day' : 'monday',
                    'hours':4,
                    'loaction_type':'onsite',
                    'remarks':"dfaaasdasdasasdasdas",
                },
                {
                    'date':'01',
                    'day' : 'monday',
                    'hours':4,
                    'loaction_type':'onsite',
                    'remarks':"dfaaasdasdasasdasdas",
                },
            ]

        });
    }

    downloadAllContracts(){
        return this.http.get(`${BASE_URL}/contract/v1/contract/download-all-contracts`,{ responseType: 'blob' });
    }

    addEditTimesheets(payload,id){
        return this.http.post(`${BASE_URL}/contract/v1/contract/project/update-timesheet`,{ ...payload});
    }

    invoiceActions(payload,id){
        return this.http.post(`${BASE_URL}/contract/v1/contract/invoice/${id}/change-status`,{ ...payload});
    }

}
