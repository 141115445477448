import { Action } from "@ngrx/store";
import {
    ResourcesActionTypes,
    GetMyDocumentsSuccess,
    GetAllDocumentsSuccess,
    GetThemesSuccess,
    GetAsOfVersionSuccess,
    GetTagListSuccess,
    GetApprovalDataSuccess,
    GetDocumentSuccess,
    GetApprovalDetailsSuccess,
    GetCollaborators,
    GetCollaboratorsSuccess,
    GetViewersSuccess,
    GetSharedDocumentsSuccess,
    GetShareSuggestionsSuccess,
    GetSharedUsersListSuccess,
    GetPublishedDocumentsSuccess,
    GetUserCapabilitesOfDocumentSuccess,
    SearchResultsSuccess,
    GetFileByIdSuccess,
    AddNewDocumentSuccess,
    getCommentsSuccess,
    GetMyFoldersSuccess,
    GetMyFilesAndFoldersSuccess,
    GetFilesInMyFolderSuccess,
    MoveFileToSuccess,
    CreateFolderSuccess,
    DeleteFolderSuccess,
    GetMoveMyFoldersSuccess,
    GetMoveFilesInMyFolderSuccess,
    PostCommentsSuccess,
    AddDocument,
    AddDocumentSuccess,
    GetActivityLogSuccess,
    GetAllCmpDocumentsSuccess,
    GetRequestDocAccessListSuccess,
    GetUnpublishedDocumentsSuccess,
    UpdateDocumentStartActionSuccess,
    GetCitisFoldersSuccess,
    GetDocActivityLogSuccess,
    GetFolderDetailsSuccess,
} from './resources.actions';

export interface Resources {
    allDocuments: any;
    myDocuments: Array<any>;
    sharedDocuments: any;
    themes: Array<any>;
    asofVersion: Array<any>;
    approvalData: Array<any>;
    tags: Array<any>;
    approvalDetails: any;
    docDetail: any;
    collaborators: Array<any>;
    viewers: Array<any>;
    shareSuggestions: Array<any>;
    sharedUsers: Array<any>;
    publishedDocuments: any;
    userCapability: Array<any>;
    docSearchResults: any;
    file: any;
    document: any
    comments: object;
    comment: object;
    myFolders: Array<any>;
    myFilesAndFolders: any;
    myMoveFolders: Array<any>;
    filesInFolder: any;
    moveFilesInFolder: any;
    files: any;
    createFolderRes: any;
    deleteFolderRes: any;
    docAdded: any;
    activityLog: Array<any>,
    allCmpDocuments: any,
    docAccessRequestList: Array<any>,
    unpublishedDocs: any,
    updateDocument: any,
    citisFolders:any,
    docActivityLog: Array<any>;
    folderDetails:any;
}

const intialState = {
    allDocuments: {
        loaded: false,
        docList: [],
        details: {}
    },
    myDocuments: [],
    sharedDocuments: {
        loaded: false,
        docList: [],
        details: {}
    },
    themes: [],
    asofVersion: [{}],
    tags: [],
    docDetail: {},
    approvalData: [],
    approvalDetails: {},
    collaborators: [],
    viewers: [],
    shareSuggestions: [],
    sharedUsers: [],
    publishedDocuments: {
        loaded: false,
        docList: [],
    },
    userCapability: [],
    docSearchResults: [],
    file: {},
    document: {},
    comments: [],
    comment: {},
    myFolders: [],
    myMoveFolders: [],
    filesInFolder: {},
    moveFilesInFolder: {},
    files: [],
    createFolderRes: {},
    deleteFolderRes: {},
    myFilesAndFolders: {
        foldersList: [],
        filesList: [],
        details: {},
        loaded: false,
    },
    docAdded: {} || [],
    activityLog: [],
    allCmpDocuments: {
        loaded: false,
        docList: [],
        details: {}
    },
    docAccessRequestList: [],
    unpublishedDocs: {
        doclist: [],
        details: {}
    },
    updateDocument: '',
    citisFolders:{},
    docActivityLog: [],
    folderDetails:{}

};

export function reducer(state: Resources = intialState, action: Action): Resources {
    switch (action.type) {
        case ResourcesActionTypes.GET_MY_DOCUMENTS_SUCCESS:
            return Object.assign({}, state, {
                myDocuments: (action as GetMyDocumentsSuccess).docs
            });
        case ResourcesActionTypes.GET_SHARED_DOCUMENTS_SUCCESS:
            let sharedDocsList = (action as GetSharedDocumentsSuccess).response.docs;
            let sharedDocPageNum = (action as GetSharedDocumentsSuccess).response.page;
            if (sharedDocPageNum == 1) {
                state.sharedDocuments.docList = [];
            }
            return Object.assign({}, state, {
                sharedDocuments: {
                    loaded: true,
                    docList: [ ...sharedDocsList],
                    details: (action as GetSharedDocumentsSuccess).response
                }
            });
        case ResourcesActionTypes.GET_ALL_DOCUMENTS_SUCCESS:
            let publicDocsList = (action as GetAllDocumentsSuccess).response.docs;
            let publicDocPageNum = (action as GetAllDocumentsSuccess).response.page;
            if (publicDocPageNum == 1) {
                state.allDocuments.docList = [];
            }
            return Object.assign({}, state, {
                allDocuments: {
                    loaded: true,
                    details: (action as GetAllDocumentsSuccess).response,
                    docList: [ ...publicDocsList]
                }
            });
        case ResourcesActionTypes.GET_THEMES_SUCCESS:
            return Object.assign({}, state, {
                themes: (action as GetThemesSuccess).data
            });
        case ResourcesActionTypes.GET_AS_OF_VERSION_SUCCESS:
            return Object.assign({}, state, {
                asofVersion: (action as GetAsOfVersionSuccess).data
            });
        case ResourcesActionTypes.GET_TAG_LIST_SUCCESS:
            return Object.assign({}, state, {
                tags: (action as GetTagListSuccess).data
            });
        case ResourcesActionTypes.GET_DOCUMENT_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                docDetail: (action as GetDocumentSuccess).documentDetail
            });
        case ResourcesActionTypes.GET_APPROVAL_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                approvalDetails: (action as GetApprovalDetailsSuccess).data
            });
        case ResourcesActionTypes.GET_APPROVAL_DATA_SUCCESS:
            return Object.assign({}, state, {
                approvalData: (action as GetApprovalDataSuccess).data
            });
        case ResourcesActionTypes.GET_COLLOBORATORS_SUCCESS:
            return Object.assign({}, state, {
                collaborators: (action as GetCollaboratorsSuccess).collaborators
            });
        case ResourcesActionTypes.GET_VIEWERS_SUCCESS:
            return Object.assign({}, state, {
                viewers: (action as GetViewersSuccess).viewers
            });
        case ResourcesActionTypes.GET_SHARE_SUGGESTIONS_SUCCESS:
            return Object.assign({}, state, {
                shareSuggestions: (action as GetShareSuggestionsSuccess).suggestions
            });
        case ResourcesActionTypes.GET_SHARED_USERS_LIST_SUCCESS:
            return Object.assign({}, state, {
                sharedUsers: (action as GetSharedUsersListSuccess).sharedUsers
            });
        case ResourcesActionTypes.GET_PUBLISHED_DOCUMENT_LIST_SUCCESS:
            return Object.assign({}, state, {
                publishedDocuments: {
                    loaded: true,
                    docList: (action as GetPublishedDocumentsSuccess).docs,
                }
            });
        case ResourcesActionTypes.GET_USER_CAPABILITIES_FOR_DOC_SUCCESS:
            return Object.assign({}, state, {
                userCapability: (action as GetUserCapabilitesOfDocumentSuccess)
                    .capability
            });
        case ResourcesActionTypes.SEARCH_RESULTS_SUCCESS:
            let page: any = (action as SearchResultsSuccess).searchResults.page;
            let docs: any = (action as SearchResultsSuccess).searchResults.docs;
            let pages: number = (action as SearchResultsSuccess).searchResults.pages;
            if (page == 1) {
                return Object.assign({}, state, {
                    docSearchResults: {
                        docs: [...docs],
                        pages: (action as SearchResultsSuccess).searchResults.pages,
                        page: 1,
                        total: (action as SearchResultsSuccess).searchResults.total
                    }
                });
            }
            return Object.assign({}, state, {
                docSearchResults: {
                    docs: [...docs],
                    pages: pages,
                    page: page,
                    total: (action as SearchResultsSuccess).searchResults.total
                }
            });
        case ResourcesActionTypes.GET_FILE_BY_ID_SUCCESS:
            return Object.assign({}, state, { file: (action as GetFileByIdSuccess).response });
        case ResourcesActionTypes.ADD_NEW_DOCUMENT_SUCCESS:
            return Object.assign({}, state, { document: (action as AddNewDocumentSuccess).response });
        case ResourcesActionTypes.GET_COMMENTS_SUCCESS:
            return Object.assign({}, state, { comments: (action as getCommentsSuccess).comments });
        case ResourcesActionTypes.POST_COMMENT_SUCCESS:
            return Object.assign({}, state, { comment: (action as PostCommentsSuccess).comment });
        case ResourcesActionTypes.MOVE_FILE_SUCCESS:
            return Object.assign({}, state, { files: (action as MoveFileToSuccess).files });
        case ResourcesActionTypes.GET_MY_FOLDERS_SUCCESS:
            return Object.assign({}, state, { myFolders: (action as GetMyFoldersSuccess).folderList });
        case ResourcesActionTypes.GET_FILES_IN_MY_FOLDER_SUCCESS:
            return Object.assign({}, state, { filesInFolder: (action as GetFilesInMyFolderSuccess).response });
        case ResourcesActionTypes.CREATE_FOLDER_SUCCESS:
            return Object.assign({}, state, { createFolderRes: (action as CreateFolderSuccess).response });
        case ResourcesActionTypes.DELETE_FOLDER_SUCCESS:
            return Object.assign({}, state, { deleteFolderRes: (action as DeleteFolderSuccess).response });
        case ResourcesActionTypes.GET_MOVE_MY_FOLDERS_SUCCESS:
            return Object.assign({}, state, { myMoveFolders: (action as GetMoveMyFoldersSuccess).folderList });
        case ResourcesActionTypes.GET_MOVE_FILES_IN_MY_FOLDER_SUCCESS:
            return Object.assign({}, state, { moveFilesInFolder: (action as GetMoveFilesInMyFolderSuccess).response });
        case ResourcesActionTypes.GET_MY_FILES_AND_FOLDERS_SUCCESS:
            let foldersList = (action as GetMyFilesAndFoldersSuccess).filesAndFolderList.foldersList;
            let filesList = (action as GetMyFilesAndFoldersSuccess).filesAndFolderList.docsList;
            let pageNum = (action as GetMyFilesAndFoldersSuccess).filesAndFolderList.page;
            if (pageNum == 1) {
                state.myFilesAndFolders.filesList = [];
                state.myFilesAndFolders.foldersList = [];
            }
            return Object.assign({}, state, {
                myFilesAndFolders: {
                    foldersList: foldersList,
                    filesList: filesList,
                    details: (action as GetMyFilesAndFoldersSuccess).filesAndFolderList,
                    loaded: true,
                }
            });
        case ResourcesActionTypes.CLEAR_DOC_DETAILS:
            return Object.assign({}, state, {
                ...state,
                docDetail: {}
            });
        case ResourcesActionTypes.ADD_DOCUMENT_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                docAdded: (action as AddDocumentSuccess).response
            });
        case ResourcesActionTypes.CLEAR_SHARE_SUGGESTIONS:
            return Object.assign({}, state, {
                shareSuggestions: []
            });
        case ResourcesActionTypes.GET_ACTIVITY_LOG_SUCCESS:
            return Object.assign([], state, { activityLog: (action as GetActivityLogSuccess).activityLog });
        case ResourcesActionTypes.GET_ALL_CMP_DOCUMENTS_SUCCESS:
            let cmpDocsList = (action as GetAllCmpDocumentsSuccess).response.docs;
            let cmpDocPageNum = (action as GetAllCmpDocumentsSuccess).response.page;
            if (cmpDocPageNum == 1) {
                state.allCmpDocuments.docList = [];
            }
            return Object.assign({}, state, {
                allCmpDocuments: {
                    loaded: true,
                    docList: [ ...cmpDocsList],
                    details: (action as GetAllCmpDocumentsSuccess).response
                }
            })
        case ResourcesActionTypes.GET_REQUEST_ACCESS_LIST_SUCCESS:
            return Object.assign({}, state, { docAccessRequestList: (action as GetRequestDocAccessListSuccess).accessList });
        case ResourcesActionTypes.GET_UNPUBLISHED_DOCUMENTS_SUCCESS:
            let docList = (action as GetUnpublishedDocumentsSuccess).response.docs;
            let pageNumber = (action as GetUnpublishedDocumentsSuccess).response.page;
            if (pageNumber == 1) {
                state.unpublishedDocs.docList = [];
            }
            return Object.assign({}, state, {
                unpublishedDocs: {
                    docList: [...state.unpublishedDocs.docList, ...docList],
                    details: (action as GetUnpublishedDocumentsSuccess).response,
                }
            });
        
        case ResourcesActionTypes.UPDATE_DOCUMENT_SUCCESS:
            return Object.assign({}, state, { updateDocument: (action as UpdateDocumentStartActionSuccess).response });

        case ResourcesActionTypes.GET_CITIS_FOLDER_SUCCESS:
            return Object.assign({}, state, { citisFolders: (action as GetCitisFoldersSuccess).response });
    
        case ResourcesActionTypes.GET_DOC_ACTIVITY_SUCCESS:
            return Object.assign({}, state, { docActivityLog: (action as GetDocActivityLogSuccess).response });
        
        case ResourcesActionTypes.GET_FOLDER_DETAILS_SUCCESS:
            return Object.assign({}, state, { folderDetails: (action as GetFolderDetailsSuccess).response });
            
        default:
            return state;
    }
}
