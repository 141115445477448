import { Injectable } from '@angular/core';
import { API } from 'src/app/services/Api.service';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { Router } from '@angular/router';

import {
    GetCapabilities,
    GetCapabilitiesSuccess,
    GetAllCapabilitiesOfRoles,
    GetAllCapabilitiesOfRolesSuccess,
    CapabilitiesActionTypes,
    AddCapability,
    AddCapabilitySuccess,
    RemoveCapability,
    RemoveCapabilitySuccess,
    GetAllRoles,
    GetAllRolesSuccess,
    AddRole,
    AddRoleSuccess,
    EditRole,
    EditRoleSuccess,
    AddRemoveCapability,
    AddRemoveCapabilitySuccess,
    ResetCapability,
    ResetCapabilitySuccess,
    GetCapabilityHistoryLog,
    GetCapabilityHistoryLogSuccess
} from './capabilities.actions';

import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { ErrorAction } from 'src/app/login/store/auth.actions';
import { LoaderHide } from 'src/app/features/loader/store/loader.actions';

@Injectable()
export class CapabilitiesEffects {

    @Effect()
    getCapabilities$ = this.action$.pipe(
        ofType(CapabilitiesActionTypes.GET_CAPABILITIES),
        switchMap((action: GetCapabilities) => {
            return this.api.getCapabilities().pipe(
                map((capabilities: any) => {
                    this.store.dispatch(new LoaderHide());
                    return new GetCapabilitiesSuccess(capabilities);
                }),
                catchError((err) => {
                    this.store.dispatch(new LoaderHide());
                    return of(new ErrorAction(err))
                })
            );
        })
    );

    @Effect()
    getAllCapabilitiesOfRoles$ = this.action$.pipe(
        ofType(CapabilitiesActionTypes.GET_ALL_CAPABILITIES_OF_ROLES),
        switchMap((action: GetAllCapabilitiesOfRoles) => {
            return this.api.getAllCapabilitiesOfRoles().pipe(
                map((allCapabilitiesOfRoles: any) => {
                    return new GetAllCapabilitiesOfRolesSuccess(allCapabilitiesOfRoles);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    )

    @Effect()
    addCapability$ = this.action$.pipe(
        ofType(CapabilitiesActionTypes.ADD_CAPABILITY),
        switchMap((action: AddCapability) => {
            return this.api.addCapability(action.payload).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide());
                    this.notificationService.success(response.message);
                    return new AddCapabilitySuccess(response);
                }),
                catchError((err) => {
                    if (err.status == 409) {
                        alert("This capability is being changed by another user. Please refresh the page.");
                        return of(new ErrorAction(""))
                    }
                    return of(new ErrorAction(err))
                })
            );
        })
    );

    @Effect()
    removeCapability$ = this.action$.pipe(
        ofType(CapabilitiesActionTypes.REMOVE_CAPABILITY),
        switchMap((action: RemoveCapability) => {
            return this.api.removeCapability(action.payload).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide());
                    this.notificationService.success(response.message);
                    return new RemoveCapabilitySuccess(response);
                }),
                catchError((err) => {
                    if (err.status == 409) {
                        alert("Please refresh the screen!");
                        return of(new ErrorAction(""))
                    }
                    return of(new ErrorAction(err))
                })
            );
        })
    );

    @Effect()
    getAllRoles$ = this.action$.pipe(
        ofType(CapabilitiesActionTypes.GET_ALL_ROLES),
        switchMap((action: GetAllRoles) => {
            return this.api.getAllRoles().pipe(
                map((response: any) => {
                    return new GetAllRolesSuccess(response.roles || []);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    addRole$ = this.action$.pipe(
        ofType(CapabilitiesActionTypes.ADD_ROLE),
        switchMap((action: AddRole) => {
            return this.api.addRole(action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success("Role added successfully");
                    this.router.navigate(['home', 'role-management', 'list-capabilities']);
                    return new AddRoleSuccess(response);
                }),
                catchError((err) => {
                    // this.notificationService.error("Error occurred while adding role.");
                    return of(new ErrorAction(err));
                })
            )
        })
    )

    @Effect()
    editRole$ = this.action$.pipe(
        ofType(CapabilitiesActionTypes.EDIT_ROLE),
        switchMap((action: EditRole) => {
            return this.api.editRole(action.role, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success("Role edited successfully");
                    this.router.navigate(['home', 'role-management', 'list-capabilities']);
                    return new EditRoleSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )



    @Effect()
    addRemoveCapability$ = this.action$.pipe(
        ofType(CapabilitiesActionTypes.ADD_REMOVE_CAPABILITY),
        switchMap((action: AddRemoveCapability) => {
            return this.api.addRemoveCapability(action.payload).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide());
                    this.notificationService.success(response.message);
                    return new AddRemoveCapabilitySuccess(response);
                }),
                catchError((err) => {
                    if (err.status == 409) {
                        alert("This capability is being changed by another user. Please refresh the page.");
                        return of(new ErrorAction(""))
                    }
                    return of(new ErrorAction(err))
                })
            );
        })
    );

    @Effect()
    resetAllCapabilitiesOfRoles$ = this.action$.pipe(
        ofType(CapabilitiesActionTypes.RESET_CAPABILITY),
        switchMap((action: ResetCapability) => {
            return this.api.resetAllCapabilitiesOfRoles().pipe(
                map((response: any) => {
                    this.notificationService.success(response.successMessage);
                    this.store.dispatch(new GetCapabilities());
                    this.store.dispatch(new GetAllCapabilitiesOfRoles());
                    this.store.dispatch(new GetAllRoles());
                    return new ResetCapabilitySuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    )

    @Effect()
    getCapabilityHistoryLog$ = this.action$.pipe(
        ofType(CapabilitiesActionTypes.GET_CAPABILITIES_ROLES_HISTORY_LOG),
        switchMap((action: GetCapabilityHistoryLog) => {
            return this.api.getCapabilityHistoryLog().pipe(
                map((response: any) => {
                    return new GetCapabilityHistoryLogSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )


    constructor(
        private api: API,
        private action$: Actions,
        private notificationService: NotificationsService,
        private store: Store<AppState>,
        private router: Router
    ) { }
}