
import { Component, OnDestroy, Inject } from "@angular/core";
import { $ } from "protractor";
import { Subject } from "rxjs";
import { MAT_DIALOG_DATA, MatDialogRef } from "@angular/material";
import { FormControl, Validators, FormBuilder } from "@angular/forms";
import { takeUntil, debounceTime } from "rxjs/operators";
import { Store } from "@ngrx/store";
import { AppState } from "src/app/app.reducer";
import { VerifyOtpStart, ClearActionOthers, Logout, SendOtpToAdminMail } from "src/app/login/store/auth.actions";
import { UserChangeEmail, UserChangePhone } from "src/app/features/home/user-management/store/user-managment.actions";



@Component({
    selector: 'otp-screen',
    styleUrls: ['./otp-screen.component.scss'],
    templateUrl: './otp-screen.component.html'

})

export class OtpScreenComponent implements OnDestroy {
    stopper$: Subject<any> = new Subject();
    pattern: boolean = false;
    emailOtp: FormControl;
    mobileOtp: FormControl;
    form;
    verifyButtonClicked: boolean = false;

    ngOnDestroy() {
        this.stopper$.next();
        this.stopper$.complete();
        this.verifyButtonClicked = false;
        // this.dialogRef.close();
    }

    constructor(@Inject(MAT_DIALOG_DATA) public data: any, public _store: Store<AppState>, private fb: FormBuilder, private dialogRef: MatDialogRef<any>) {
    }
    ngOnInit() {
        this._store.dispatch(new ClearActionOthers());
        this.form = this.fb.group({
            emailOtp: ['', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^[0-9]*$')])],
            mobileOtp: ['', Validators.compose([Validators.required, Validators.minLength(4), Validators.maxLength(4), Validators.pattern('^[0-9]*$')])]
        })

        this._store.select(state => state.auth).pipe(takeUntil(this.stopper$)).subscribe((resp: any) => {
            if (this.verifyClicked && Object.keys(resp.otp).length) {
                this.dialogRef.close(true);
                if (!this.data.byAdmin) {
                    this._store.dispatch(new Logout(this.data.id, resp.accessToken));
                }
            }
        })


    }
    verifyClicked() {
        this.verifyButtonClicked = true;
        if (this.form.valid) {
            let payload = {
                mobileOtp: parseInt(this.form.controls.mobileOtp.value),
                otp: parseInt(this.form.controls.emailOtp.value)
            }
            this._store.dispatch(new VerifyOtpStart(this.data.id, payload))
        }
        else {
            for (let control in this.form.controls) {
                this.form.controls[control].markAsTouched();
            }
        }
    }

    isInputNumber(event) {
        const ch = String.fromCharCode(event.which);
        if (!(/[0-9]/.test(ch))) {
            event.preventDefault();
        }
    }

    onNoClick() {
        this.dialogRef.close();
    }

    resendOTP() {
        if (this.data.type == "changeEmail") {
            this._store.dispatch(new UserChangeEmail({ email: this.data.email }, this.data.id))
        }
        else if (this.data.type == "changePhone") {
            this._store.dispatch(new UserChangePhone({ countryCode: this.data.countryCode, phone: this.data.phone }, this.data.id));
        }
        else {
            this._store.dispatch(new SendOtpToAdminMail({ password: this.data.password }, this.data.id))
        }
    }
} 