import * as eventManagement  from './features/home/events/store/event.reducer';
import * as userManagement from './features/home/user-management/store/user-managment.reducer';
import * as resources from './features/home/resources/_store/resources.reducer';
import * as fromAuth from './login/store/auth.reducer';
import { AuthState } from './login/store/auth.reducer';
import * as programDashboard from './features/home/program-dashboard/store/program-dashboard.reducer';
import { ProgramDashboard } from './features/home/program-dashboard/store/program-dashboard.reducer';
import * as fromUserGroup from './features/home/user-groups/store/user-groups.reducer';
import * as tasks from './features/home/tasks/store/tasks.reducer'
import { MetaReducer, ActionReducer } from '@ngrx/store';
import { localStorageSync } from 'ngrx-store-localstorage';
import { AuthActionTypes } from './login/store/auth.actions';
import * as messages from "./features/home/user-messages/_store/messages.reducer";
import * as tags from './features/home/tags/store/tags.reducer';
import * as profile from './features/home/profile/store/profile.reducer';
import * as capabilities from './features/home/capabilities/store/capabilities.reducer';
import * as template from './features/home/templates/store/templates.reducer';
import { LoaderState, reducer as loaderReducer } from "./features/loader/store/loader.reducer";
import * as privateMember from './features/home/private-members/store/private-member.reducer';
import * as configurations from './features/home/configurations/store/configurations.reducer';
import * as notifications from './features/home/notifications/store/notifications.reducer';
import * as contractManagement from './features/contract-management/store/contract-managment.reducer';
import * as monitoringEvaluation from './features/home/monitoring-evaluation/store/monitoring-evaluation.reducer';
import * as  procumentManagement from './features/home/procurement/store/procurement.reducer';
import * as  budgetManagement from './features/home/budget/store/budget.reducer';



export interface AppState {
  auth: AuthState;
  userManagment: userManagement.UserManagement;
  resources: resources.Resources;
  programDashboard: ProgramDashboard;
  userGroups: fromUserGroup.UserGroup;
  tasks: tasks.TasksState;
  messages: messages.Messages;
  tags: tags.Tags;
  profile: profile.Profile;
  capabilities: capabilities.Capabilities;
  template: template.Template;
  loader: LoaderState;
  privateMember: privateMember.PrivateMemberGroup;
  configurations: configurations.Configurations;
  notifications: notifications.Notifications;
  contractManagement: contractManagement.ContractManagement;
  monitoringEvaluation: monitoringEvaluation.MonitoringEvaluation;
  eventManagement:eventManagement.EventManagement;
  procumentManagement:procumentManagement.ProcumentManagement;
  budgetManagement:budgetManagement.BudgetManagement;

}

export const reducer = {
  auth: fromAuth.reducer,
  userManagment: userManagement.reducer,
  resources: resources.reducer,
  programDashboard: programDashboard.reducer,
  userGroups: fromUserGroup.reducer,
  tasks: tasks.reducer,
  messages: messages.reducer,
  tags: tags.reducer,
  profile: profile.reducer,
  capabilities: capabilities.reducer,
  template: template.reducer,
  loader: loaderReducer,
  configurations: configurations.reducer,
  notifications: notifications.reducer,
  privateMember: privateMember.reducer,
  contractManagement: contractManagement.reducer,
  monitoringEvaluation: monitoringEvaluation.reducer,
  eventManagement: eventManagement.reducer,
  procumentManagement:procumentManagement.reducer,
  budgetManagement:budgetManagement.reducer

};

export const metaReducers: MetaReducer<any>[] = [localStorageSyncReducer];

export function localStorageSyncReducer(reducer: ActionReducer<any>): ActionReducer<any> {
  return localStorageSync({
    keys: ['auth'],
    rehydrate: true
  })(reducer);
}
