import { Injectable } from '@angular/core';
import { API } from 'src/app/services/Api.service';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Location } from '@angular/common';
import { NotificationsService } from 'angular2-notifications';
import { ErrorAction } from 'src/app/login/store/auth.actions';
import {
    GetPrivateMembersGroupSuccess,
    GetPrivateMembersGroups,
    PrivateMemberGroupActionTypes,
    DeletePrivateMemberGroup,
    GetPrivateMemberGroupDetails,
    GetPrivateMemberGroupDetailsSuccess,
    RemoveUserFromPrivateMemberGroup,
    AddPrivateMemberToGroup,
    AddPrivateMemberToGroupSuccess,

    CreatePrivateMemberGroup,
    CreatePrivateMemberGroupSuccess,
    UpdatePrivateMemberGroup


} from './private-member.action';
import { Router } from '@angular/router';

@Injectable()
export class PrivateUserGroupEffects {

    @Effect()
    addUserToGroup$ = this.action$.pipe(
        ofType(PrivateMemberGroupActionTypes.ADD_PRIVATE_MEMBER_TO_GROUP),
        switchMap((action: AddPrivateMemberToGroup) => {
            return this.api.addPrivateMembersToGroup(action.groupId, action.payload).pipe(
                map((response: any) => {
                    console.log('im called')
                    //  new GetPrivateMemberGroupDetails(action.groupId);
                     return new AddPrivateMemberToGroupSuccess(response)
                }),
                catchError(err => {
                    return of(new ErrorAction(err));
                }
                )
            );
        })
    )


    @Effect()
    createGroup$ = this.action$.pipe(
        ofType(PrivateMemberGroupActionTypes.CREATE_PRIVATE_MEMBER_GROUP),
        switchMap((action: CreatePrivateMemberGroup) => {
            return this.api.createPrivateMemberGroup(action.payload).pipe(
                map((response: any) => {
                    return new CreatePrivateMemberGroupSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );


    @Effect()
    getPrivateUserGroups$ = this.action$.pipe(
        ofType(PrivateMemberGroupActionTypes.GET_PRIVATE_MEMBER_GROUPS),
        switchMap((action: GetPrivateMembersGroups) => {
            return this.api.getPrivateUserGroups().pipe(
                map((groups: any) => {
                    return new GetPrivateMembersGroupSuccess(groups);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );


    @Effect()
    deletePrivateMembersGroup$ = this.action$.pipe(
        ofType(PrivateMemberGroupActionTypes.DELETE_PRIVATE_MEMBER_GROUP),
        switchMap((action: DeletePrivateMemberGroup) => {
            return this.api.deletePrivateMemberGroup(action.groupId, { is_active: false }).pipe(
                map((response: any) => {
                    this.notificationService.success("Private member list deleted successfully");
                    this.router.navigateByUrl('/home/private-members');
                    return new GetPrivateMembersGroups();
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );


    @Effect()
    getGroupDetails$ = this.action$.pipe(
        ofType(PrivateMemberGroupActionTypes.GET_PRIVATE_MEMBER_GROUP_DETAILS),
        switchMap((action: GetPrivateMemberGroupDetails) => {
            return this.api.getPrivateMemberGroupDetails(action.groupId).pipe(
                map((groupDetails: any) => {
                    return new GetPrivateMemberGroupDetailsSuccess(groupDetails);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );


    @Effect()
    removeUserFromGroup$ = this.action$.pipe(
        ofType(PrivateMemberGroupActionTypes.REMOVE_USER_FROM_PRIVATE_MEMBER_GROUP),
        switchMap((action: RemoveUserFromPrivateMemberGroup) => {
            return this.api.removeUserFromPrivateGroup(action.groupId, action.userId).pipe(
                map((response: any) => {
                    this.notificationService.success("User removed successfully");
                    return new GetPrivateMemberGroupDetails(action.groupId);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

        @Effect({dispatch: false})
        updatePrivateMemberGroup = this.action$.pipe(
            ofType(PrivateMemberGroupActionTypes.UPDATE_PRIVATE_MEMBER_GROUP),
            switchMap((action: UpdatePrivateMemberGroup) => {
                return this.api.updatePrivateMemberGroup(action.groupId, action.payload).pipe(
                    map((response: any) => {
                        this.notificationService.success("Details updated successfully");
                    }),
                    catchError((err) => {
                        return of(new ErrorAction(err))
                    })
                )
            })
        )
    


    constructor(private api: API,
        private action$: Actions,
        private location: Location,
        private notificationService: NotificationsService,
        private router: Router,
    ) { }


}