import { Injectable } from '@angular/core';
import { API } from 'src/app/services/Api.service';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import {
    GetProjects,
    GetBudgetProjects,
    ProgramDashboardActionTypes,
    GetProjectsSucess,GetBudgetProjectsSucess, CreateTask,
    CreateTaskSuccess, GetMembers,
    GetMembersSucess, GetProjectDetail,
    GetProjectDetailSucess,
    GetTasksForProjects,
    GetTasksForProjectsSuccess,
    GetProjectPillars,
    GetProjectPillarSuccess,
    AddNewPillar,
    EditPillar,
    GetGanttChartData,
    GetGanttChartDataSuccess,
    CreateProject,
    GetMemberRoles,
    GetMemberRolesSuccess,
    CreateTaskForProject,
    EditTaskInProject,
    GetMembersList,
    GetMembersListSuccess,
    ManageMembers,
    ManageMembersSuccess,
    EditProject,
    GetProjectSteps,
    AddNewStep,
    EditSteps,
    GetProjectStepsSuccess,
    GetFinancialInfo,
    GetFinancialInfoSuccess,
    AddFundReleaseItem,
    AddFundUtilisedItem,
    DeleteInstallmentItem,
    UpdateProjectCost,
    GetAllComplainces,
    GetAllComplaincesSuccess,
    CreateCompliance,
    GetRisksForProjects,
    GetRisksForProjectsSuccess,
    CreateRisksForProject,
    GetPhases,
    GetProjectPhase,
    GetProjectPhaseSuccess,
    AddNewPhase,
    EditPhase,
    GetOpportunitiesForProjects,
    GetOpportunitiesForProjectsSuccess,
    CreateOpportunityForProject,
    GetRiskDetails,
    GetRiskInfoSuccess,
    GetOpportunityDetail,
    GetOpportunityDetailSuccess,
    EditOpportunityForProject,
    EditRiskForProject,
    AddEditInstallment,
    GetInstallments,
    GetInstallmentsSuccess,
    AddRiskSForProjects,
    AddRiskSForProjectsSuccess,
    GetRiskHistory,
    GetRiskHistorySuccess,
    UpdateFundReleaseItem,
    GetMyOpenComment,
    GetMyOpenCommentSuccess,
    SaveOpenComment,
    SaveOpenCommentSuccess,
    GetCommentsHistoryLog,
    GetCommentsHistoryLogSuccess,
    GetCommentedUsers,
    GetCommentedUsersSuccess,
    GetOtherUsersOpenComment,
    GetOtherUsersOpenCommentSuccess,
    CreateBulkTask,
    EditOverallProgressDetails,
    GetOverallProgressDetails,
    GetProjectActivityLog,
    GetProjectActivityLogSuccess,
    EditTaskStepPillar,
    UpdateFundUtiliseItem,
    GetOpportunityHistory,
    GetOpportunityHistorySuccess,
    GetProjectPhases,
    GetProjectPhasesSuccess,
    AddProjectPhase,
    GetProjectStates,
    GetProjectStatesSuccess,
    GetDashboardInfo,
    GetDashboardInfoSuccess,
    CreateBulkComplianceTask,
    UpdateCitiisGrant,
    GetProjectFinancialInfoSuccess,
    GetProjectFinancialInfo,
    GetL1Indicators,
    GetL1IndicatorsSuccess,
    GetKCIndicators,
    GetKCIndicatorsSuccess,
    GetESIndicatorsSuccess,
    GetESIndicators,
    GetPLFIndicatorsSuccess,
    GetPLFIndicators,
    GetInnovationMatrix,
    GetInnovationMatrixSuccess,
    UpdateIndicatorValues,
    UpdateIndicatorValuesSuccess,
    GetProjectCities,
    GetProjectCitiesSuccess,
    AggregatedValuesSuccess,
    AggregatedValues,
    GetSectoralIndicators,
    GetSectoralIndicatorsSuccess,
    GetProjectTaskInfoSuccess,
    GetProjectTaskInfo,
    BulkTasksUpload,
    BulkTasksUploadSuccess,
    GetESIndicatorsList,
    GetESIndicatorsListSuccess,
    GetFavList,
    GetProcurementProjectsSucess,
    GetProcurementProjects
} from './program-dashboard.actions';
import { GetProjectCodesSuccess, GetUsers } from '../../user-management/store/user-managment.actions';
import { Location } from '@angular/common';
import { ErrorAction } from 'src/app/login/store/auth.actions';
import { NotificationsService } from 'angular2-notifications';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { CommonSuccessAction } from '../../resources/_store/resources.actions';
import { LoaderHide } from 'src/app/features/loader/store/loader.actions';


@Injectable()
export class ProgramDashboardEffects {


    @Effect()
    getProjects$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_PROJECTS),
        switchMap((action: GetProjects) => {
            return this.api.getProjects(action.params).pipe(
                map((response: any) => {
                    return new GetProjectsSucess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getBudgetProjects$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_BUDGET_PROJECTS),
        switchMap((action: GetBudgetProjects) => {
            return this.api.getBudgetProjects(action.role,action.params).pipe(
                map((response: any) => {
                    return new GetBudgetProjectsSucess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getProjectDetails$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_PROJECT_DETAILS),
        switchMap((action: GetProjectDetail) => {
            return this.api.getProjectDetail(action.id).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide())
                    return new GetProjectDetailSucess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    // @Effect()
    // getPhases$ = this.action$.pipe(
    //     ofType(ProgramDashboardActionTypes.GET_PHASES),
    //     switchMap((action: GetPhases) => {
    //         return this.api.getPhases().pipe(
    //             map((response: any) => {
    //                 return new GetProjectDetailSucess(response);
    //             }),
    //             catchError(err => of(new ErrorAction(err)))
    //         );
    //     })
    // );

    @Effect()
    addTask$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.CREATE_TASK),
        switchMap((action: CreateTask) => {
            return this.api.createTask(action.prjId, action.payload).pipe(
                map((response: any) => {
                    this.location.back();
                    return new CreateTaskSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getTasks$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_TASKS_FOR_PROJECT),
        switchMap((action: GetTasksForProjects) => {
            return this.api.getTasksForProject(action.projectId).pipe(
                map((response: any) => {
                    return new GetTasksForProjectsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getRisks$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_RISKS_FOR_PROJECT),
        switchMap((action: GetRisksForProjects) => {
            return this.api.getRisksForProject(action.projectId).pipe(
                map((response: any) => {
                    return new GetRisksForProjectsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getOpportunities$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_OPPORTUNITIES_FOR_PROJECT),
        switchMap((action: GetOpportunitiesForProjects) => {
            return this.api.getOpportunitiesForProject(action.projectId).pipe(
                map((response: any) => {
                    return new GetOpportunitiesForProjectsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getOpportunityDetails$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_OPPORTUNITY_DETAIL),
        switchMap((action: GetOpportunityDetail) => {
            return this.api.getOpportunityDetail(action.projectId, action.opportunityId).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide());
                    return new GetOpportunityDetailSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    createRisksForProject$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.CREATE_RISK_FOR_PROJECT),
        switchMap((action: CreateRisksForProject) => {
            return this.api.createRisksForProject(action.projectId, action.payload).pipe(
                map((response: any) => {
                    console.log(response);
                    this.location.back();
                    return new GetRisksForProjects(action.projectId);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    editRiskForProject$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.EDIT_RISK_FOR_PROJECT),
        switchMap((action: EditRiskForProject) => {
            return this.api.editRiskForProject(action.projectId, action.riskId, action.payload).pipe(
                map((response: any) => {
                    this.location.back();
                    return new GetRisksForProjects(action.projectId);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    createOpportunityForProject$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.CREATE_OPPORTUNITY_FOR_PROJECT),
        switchMap((action: CreateOpportunityForProject) => {
            return this.api.createOpportunityForProject(action.projectId, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message);
                    this.store.dispatch(new GetOpportunitiesForProjects(action.projectId));
                    return new GetOpportunitiesForProjects(action.projectId);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    editOpportunityForProject$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.EDIT_OPPORTUNITY_FOR_PROJECT),
        switchMap((action: EditOpportunityForProject) => {
            return this.api.editOpportunityForProject(action.projectId, action.opportunityId, action.payload).pipe(
                map((response: any) => {
                    console.log(response);
                    this.location.back();
                    return new GetOpportunitiesForProjects(action.projectId);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getMembers$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_MEMBERS),
        switchMap((action: GetMembers) => {
            return this.api.getRoleMembers(action.role).pipe(
                map((response: any) => {
                    return new GetMembersSucess(response.users);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getPillars$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_PILLARS),
        switchMap((action: GetProjectPillars) => {
            return this.api.getPillars().pipe(
                map((response: any) => {
                    return new GetProjectPillarSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    addNewPillar$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.ADD_NEW_PILLAR),
        switchMap((action: AddNewPillar) => {
            return this.api.createPillar(action.pillar).pipe(
                map((response: any) => {
                    this.notificationService.success(response.successMessage)
                    return new GetProjectPillars();
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    editPillar$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.EDIT_PILLAR),
        switchMap((action: EditPillar) => {
            return this.api.editPillar(action.pillarId, action.pillarName).pipe(
                map((response: any) => {
                    this.notificationService.success(response.successMessage)
                    return new GetProjectPillars();
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getGanttChartData$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_GANTT_CHART_DATA),
        switchMap((action: GetGanttChartData) => {
            return this.api.getGanttChartData(action.projectId).pipe(
                map((response: any) => {
                    return new GetGanttChartDataSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    createProject$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.CREATE_PROJECT),
        switchMap((action: CreateProject) => {
            return this.api.createProject(action.payload).pipe(
                map((response: any) => {
                    this.location.back();
                    this.notificationService.success("Project created successfully");
                    return new GetProjects({ page: 1, limit: 30 });
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getMemberRolesOfProject$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_MEMBER_ROLES),
        switchMap((action: GetMemberRoles) => {
            return this.api.getProjectMembersWithRoles(action.prjId).pipe(
                map((response: any) => {
                    console.log(response);
                    return new GetMemberRolesSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    createTaskForProject$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.CREATE_TASK_FOR_PROJECT),
        switchMap((action: CreateTaskForProject) => {
            return this.api.createTaskForProject(action.projectId, action.payload).pipe(
                map((response: any) => {
                    if (action.complianceType) {
                        const payload = {
                            complianceType: action.complianceType,
                            name: response.name,
                            taskId: response._id
                        };
                        console.log(payload);
                        this.store.dispatch(new CreateCompliance(action.projectId, payload));
                    } else {
                        this.notificationService.success('Task created successfully');
                    }
                    this.location.back();
                    return new GetTasksForProjects(action.projectId);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    editTaskInProject$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.EDIT_TASK_IN_PROJECT),
        switchMap((action: EditTaskInProject) => {
            return this.api.editTaskInProject(action.projectId, action.taskId, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.successMessage || 'Task updated successfully');
                    this.store.dispatch(new GetGanttChartData(action.projectId));
                    return new GetTasksForProjects(action.projectId);

                }),
                catchError(err => of(new ErrorAction(err)))

            );
        })
    );

    @Effect()
    getMembersList$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_MEMBERS_LIST),
        switchMap((action: GetMembersList) => {
            return this.api.getProjectMembersList(action.projectID).pipe(
                map((response: any) => {
                    return new GetMembersListSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    manageMembers$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.MANAGE_MEMBERS),
        switchMap((action: ManageMembers) => {
            return this.api.manageMembers(action.id, action.userId).pipe(
                map((response: any) => {
                    this.notificationService.success('Team member added successfully')
                    return new GetMembersList(action.id);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    editProject$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.EDIT_PROJECT),
        switchMap((action: EditProject) => {
            return this.api.editProject(action.projectId, action.payload).pipe(
                map((response: any) => {
                    console.log(response);
                    this.location.back();
                    this.notificationService.success("Project updated successfully");
                    return new GetProjectDetail(action.projectId);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getSteps$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.LIST_STEP),
        switchMap((action: GetProjectSteps) => {
            return this.api.getSteps().pipe(
                map((response: any) => {
                    return new GetProjectStepsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    addNewStep$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.ADD_STEP),
        switchMap((action: AddNewStep) => {
            return this.api.createStep(action.step).pipe(
                map((response: any) => {
                    this.notificationService.success(response.successMessage)
                    return new GetProjectSteps();
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    editStep$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.EDIT_STEP),
        switchMap((action: EditSteps) => {
            return this.api.editStep(action.stepId, action.stepName).pipe(
                map((response: any) => {
                    this.notificationService.success(response.successMessage)
                    return new GetProjectSteps();
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getFinancialInfo$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_FINANCIAL_INFO),
        switchMap((action: GetFinancialInfo) => {
            return this.api.getFinancialSteps(action.projectId,action.apiType).pipe(
                map((response: any) => {
                    return new GetFinancialInfoSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    projectList:any =['5e2353b02f122472a40a04c0','5e23531b2f122472a40a04bc','6486cd12dfaf6d1949881d4e']

    idExists(projectList:any,id: string): boolean {
        return projectList.includes(id);
    }


    @Effect()
    addFundReleaseItem$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.ADD_FUND_RELEASE_ITEM),
        switchMap((action: AddFundReleaseItem) => {
            return this.api.addFundReleaseItem(action.projectId, action.payload).pipe(
                map((response: any) => {
 
                    let value = this.projectList.includes(action.projectId)?'new':'old'


                    return new GetFinancialInfo(action.projectId,value);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    addFundUtilisedItem$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.ADD_FUND_UTILISED_ITEM),
        switchMap((action: AddFundUtilisedItem) => {
            return this.api.addFundUtilisedItem(action.projectId, action.payload).pipe(
                map((response: any) => {
                        let value = this.projectList.includes(action.projectId)?'new':'old'
                    return new GetFinancialInfo(action.projectId,value);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    deleteInstallmentItem$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.DELETE_INSTALLMENT_ITEM),
        switchMap((action: DeleteInstallmentItem) => {
            return this.api.deleteInstallmentItem(action.projectId, action.payload, action.itemType).pipe(
                map((response: any) => {
                    if (action.itemType === 'released') {
                        this.notificationService.success(response.successMessage);
                    } else {
                        this.notificationService.success(response.successMessage);
                    }
                        let value = this.projectList.includes(action.projectId)?'new':'old'
                    return new GetFinancialInfo(action.projectId,value);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );


    @Effect()
    getPhases$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.LIST_PHASE),
        switchMap((action: GetProjectPhase) => {
            return this.api.getPhases().pipe(
                map((response: any) => {
                    console.log(response);
                    return new GetProjectPhaseSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    addNewPhase$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.ADD_PHASE),
        switchMap((action: AddNewPhase) => {
            return this.api.createPhase(action.phase, action.colorCode).pipe(
                map((response: any) => {
                    this.notificationService.success(response.successMessage)
                    return new GetProjectPhase();
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    editPhase$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.EDIT_PHASE),
        switchMap((action: EditPhase) => {
            return this.api.editPhase(action.phaseId, action.phaseName, action.colorCode).pipe(
                map((response: any) => {
                    this.notificationService.success(response.successMessage)
                    return new GetProjectPhase();
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );



    @Effect()
    updateProjectCost$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.UPDATE_PROJECT_COST),
        switchMap((action: UpdateProjectCost) => {
            return this.api.updateProjectCost(action.projectId, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success("Project cost updated successfully");
                        let value = this.projectList.includes(action.projectId)?'new':'old'
                    return new GetFinancialInfo(action.projectId,value);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    updateCitiisGrant$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.UPDATE_CITIIS_GRANT),
        switchMap((action: UpdateCitiisGrant) => {
            return this.api.updateCitiisGrants(action.projectId, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success("CITIIS Grant updated successfully");
                        let value = this.projectList.includes(action.projectId)?'new':'old'
                    return new GetFinancialInfo(action.projectId,value);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getAllComplainces$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_ALL_COMPLAINCES),
        switchMap((action: GetAllComplainces) => {
            return this.api.getAllComplainces(action.projectId).pipe(
                map((response: any) => {
                    return new GetAllComplaincesSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    createCompliance$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.CREATE_COMPLIANCE),
        switchMap((action: CreateCompliance) => {
            return this.api.createCompliance(action.projectId, action.payload).pipe(
                map((response: any) => {
                    return new GetAllComplainces(action.projectId);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getRiskInfo$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_RISK_INFO),
        switchMap((action: GetRiskDetails) => {
            return this.api.getRiskDetails(action.projectId, action.riskId).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide());
                    return new GetRiskInfoSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );


    @Effect()
    bulkInvitation$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.CREATE_BULK_TASK),
        switchMap((action: CreateBulkTask) => {
            return this.api.bulkInvite(action.projectId, action.payload).pipe(
                map((response: any) => {
                    // this.location.back();
                    this.notificationService.success(`Tasks created successfully`);
                    return new GetTasksForProjects(action.projectId);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    addRisksForProject$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.ADD_RISKS_FOR_PROJECT),
        switchMap((action: AddRiskSForProjects) => {
            return this.api.addRisksForProjects(action.projectId, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message);
                    this.store.dispatch(new GetRisksForProjects(action.projectId));
                    return new AddRiskSForProjectsSuccess(response);
                }),
                catchError((err) => {
                    // this.notificationService.error(err.error.errors[0].error);
                    return of(new ErrorAction(err));
                })
            )
        })
    )

    @Effect()
    getRiskHistory$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_RISK_HISTORY),
        switchMap((action: GetRiskHistory) => {
            return this.api.getRiskHistory(action.projectId, action.riskId).pipe(
                map((response: any) => {
                    return new GetRiskHistorySuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    addEditInstallment$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.ADD_EDIT_INSTALLMENT),
        switchMap((action: AddEditInstallment) => {
            return this.api.addEditInstallment(action.projectId, action.payload, action.installmentType,action.apiType).pipe(
                map((response: any) => {
                    if(action.apiType == 'fundsReleased'){
                        this.notificationService.success('Funds planned details updated successfully'); 
                    }else{
                        this.notificationService.success('Installments updated successfully');
                        this.location.back();
                    }
                   
                    let value = this.projectList.includes(action.projectId)?'new':'old'
                    return new GetFinancialInfo(action.projectId,value);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getInstallments$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_INSTALLMENTS),
        switchMap((action: GetInstallments) => {
            return this.api.getInstallments(action.projectId, action.installmentType).pipe(
                map((response: any) => {
                    return new GetInstallmentsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );


    @Effect()
    updateFundReleaseItem$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.UPDATE_FUND_RELEASE_ITEM),
        switchMap((action: UpdateFundReleaseItem) => {
            return this.api.updateFundReleaseItem(action.projectId, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success('Funds released details updated successfully');
                            let value = this.projectList.includes(action.projectId)?'new':'old'
                    return new GetFinancialInfo(action.projectId,value);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    updateFundUtiliseItem$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.UPDATE_FUND_UTILISE_ITEM),
        switchMap((action: UpdateFundUtiliseItem) => {
            return this.api.updateFundUtiliseItem(action.projectId, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success('Funds utilised details updated successfully');
                            let value = this.projectList.includes(action.projectId)?'new':'old'
                    return new GetFinancialInfo(action.projectId,value);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );


    @Effect()
    getMyOpenComment$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_MY_OPEN_COMMENT),
        switchMap((action: GetMyOpenComment) => {
            return this.api.getMyOpenComment(action.projectId).pipe(
                map((response: any) => {
                    return new GetMyOpenCommentSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    saveOpenComment$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.SAVE_OPEN_COMMENT),
        switchMap((action: SaveOpenComment) => {
            return this.api.saveOpenComment(action.projectId, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message);
                    this.store.dispatch(new GetMyOpenCommentSuccess(action.payload));
                    return new SaveOpenCommentSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    getCommentsHistoryLog$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_COMMENTS_HISTORY_LOG),
        switchMap((action: GetCommentsHistoryLog) => {
            return this.api.getCommentsHistoryLog(action.projectId).pipe(
                map((response: any) => {
                    return new GetCommentsHistoryLogSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    getCommentedUsers$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_COMMENTED_USERS),
        switchMap((action: GetCommentedUsers) => {
            return this.api.getCommentedUsers(action.projectId).pipe(
                map((response: any) => {
                    return new GetCommentedUsersSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    getOtherUsersOpenComment$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_OTHER_USERS_OPEN_COMMENT),
        switchMap((action: GetOtherUsersOpenComment) => {
            return this.api.GetOtherUsersOpenComment(action.projectId, action.userId).pipe(
                map((response: any) => {
                    return new GetOtherUsersOpenCommentSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    editOverallProgressDetails$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.EDIT_OVERALL_PROGESS_DETAILS),
        switchMap((action: EditOverallProgressDetails) => {
            return this.api.editOverallProgressDetails(action.projectId, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success("Tri-Partite agreement date updated successfully")
                    return new CommonSuccessAction();
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getOverallProgressDetails$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_OVERALL_PROGESS_DETAILS),
        switchMap((action: GetOverallProgressDetails) => {
            return this.api.getOverallProjectDetails(action.projectId).pipe(
                map((response: any) => {
                    return new CommonSuccessAction();
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getActivityLogForProject$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_PROJECT_ACTIVITY_LOG),
        switchMap((action: GetProjectActivityLog) => {
            return this.api.getProjectActivityLog(action.projectId).pipe(
                map((response: any) => {
                    return new GetProjectActivityLogSuccess(response);
                }),
                catchError(err => {
                    this.store.dispatch(new GetProjectActivityLogSuccess([]));
                    return of(new ErrorAction(err));
                })
            );
        })
    );

    @Effect()
    editTaskStepPillar$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.EDIT_TASK_STEP_PILLAR),
        switchMap((action: EditTaskStepPillar) => {
            return this.api.editTaskStepPillar(action.taskId, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.successMessage || 'Task updated successfully');
                    this.store.dispatch(new GetGanttChartData(action.projectId));
                    return new GetTasksForProjects(action.projectId);

                }),
                catchError(err => of(new ErrorAction(err)))

            );
        })
    );

    @Effect()
    getOpportunityHistory$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_OPPORTUNITY_HISTORY),
        switchMap((action: GetOpportunityHistory) => {
            return this.api.getOpportunityHistory(action.projectId, action.opportunityId).pipe(
                map((response: any) => {
                    return new GetOpportunityHistorySuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    GetProjectPhases$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_PROJECT_PHASES),
        switchMap((action: GetProjectPhases) => {
            return this.api.getProjectPhases(action.projectId).pipe(
                map((response: any) => {
                    return new GetProjectPhasesSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );


    @Effect()
    AddProjectPhase$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.ADD_PROJECT_PHASE),
        switchMap((action: AddProjectPhase) => {
            return this.api.addProjectPhase(action.projectId, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success('Phases updated successfully');
                    this.location.back();
                    return new GetProjectPhases(action.projectId);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    GetProjectStates$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_PROJECT_STATES),
        switchMap((action: GetProjectStates) => {
            return this.api.getProjectStates().pipe(
                map((response: any) => {
                    return new GetProjectStatesSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getDashboardInfo$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_DASHBOARD_INFO),
        switchMap((action: GetDashboardInfo) => {
            return this.api.getDashboardInfo().pipe(
                map((response: any) => {
                    return new GetDashboardInfoSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    createBulkComplianceTask$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.CREATE_BULK_COMPLIANCE_TASK),
        switchMap((action: CreateBulkComplianceTask) => {
            return this.api.createBulkComplianceTask(action.projectId, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message);
                    return new GetAllComplainces(action.projectId)
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    getProjectFinancialInfo$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_PROJECT_FINANCIAL_INFO),
        switchMap((action: GetProjectFinancialInfo) => {
            return this.api.getFinancialInfo().pipe(
                map((response: any) => {
                    return new GetProjectFinancialInfoSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getL1$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_L1_INDICATORS),
        switchMap((action: GetL1Indicators) => {
            return this.api.getL1Indicators(action.level, action.payload).pipe(
                map((response: any) => {
                    return new GetL1IndicatorsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getKC$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_KC_INDICATORS),
        switchMap((action: GetKCIndicators) => {
            return this.api.getComponentIndicators(action.component, action.payload).pipe(
                map((response: any) => {
                    return new GetKCIndicatorsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getES$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_ES_INDICATORS),
        switchMap((action: GetESIndicators) => {
            return this.api.getComponentIndicators(action.component, action.payload).pipe(
                map((response: any) => {
                    return new GetESIndicatorsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getPLF$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_PLF_INDICATORS),
        switchMap((action: GetPLFIndicators) => {
            return this.api.getComponentIndicators(action.component, action.payload).pipe(
                map((response: any) => {
                    return new GetPLFIndicatorsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getInnovation$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_INNOVATION_MATRIX),
        switchMap((action: GetInnovationMatrix) => {
            return this.api.getInnovation(action.payload).pipe(
                map((response: any) => {
                    return new GetInnovationMatrixSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    updateIndicator$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.UPDATE_INDICATOR),
        switchMap((action: UpdateIndicatorValues) => {
            return this.api.addValues(action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message);
                    return new UpdateIndicatorValuesSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getCitites$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_CITIES),
        switchMap((action: GetProjectCities) => {
            return this.api.getCities(action.id, action.payload).pipe(
                map((response: any) => {
                    return new GetProjectCitiesSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getValues$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_AGGREGATED_VALUES),
        switchMap((action: AggregatedValues) => {
            return this.api.getValues(action.id, action.payload).pipe(
                map((response: any) => {
                    return new AggregatedValuesSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getSectoral$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_SECTORAL_INDICATORS),
        switchMap((action: GetSectoralIndicators) => {
            return this.api.getSectoralIndicators(action.payload).pipe(
                map((response: any) => {
                    return new GetSectoralIndicatorsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getProjectTaskInfo$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_PROJECT_TASK_INFO),
        switchMap((action: GetProjectTaskInfo) => {
            return this.api.getProgramTaskStatus().pipe(
                map((response: any) => {
                    return new GetProjectTaskInfoSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    taskBulkUpload$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.BULK_TASK_UPLOAD),
        switchMap((action: BulkTasksUpload) => {
            console.log('TCL: action.payload', action.payload);
            return this.api.bulkTasksUpload(action.payload,action.projId).pipe(
                map((response: any) => {
                    // this.location.back();
                    this.notificationService.success(`Bulk Tasks sent successfully`);
                    // this.store.dispatch(new GetUsers({ limit: 40, page: 1 }));
                    this.store.dispatch(new GetESIndicatorsList(action.projId))
                    return new BulkTasksUploadSuccess(response);
                }),
                catchError(err => {
                    return of(new ErrorAction(err));
                })
            );
        })
    );

    @Effect()
    getESIndicatorsList$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_E_S_INDICATORS_LIST),
        switchMap((action: GetESIndicatorsList) => {
            return this.api.getESINdicatorsList(action.id).pipe(
                map((response: any) => {
                    return new GetESIndicatorsListSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getFav$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_FAV_LIST),
        switchMap((action: GetFavList) => {
            return this.api.getFavList().pipe(
                map((response: any) => {
                    console.log(response);
                    return new GetProjectPhaseSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getProcurementProjects$ = this.action$.pipe(
        ofType(ProgramDashboardActionTypes.GET_PROCUREMENT_PROJECTS),
        switchMap((action: GetProcurementProjects) => {
            return this.api.getProcurementProjects(action.role,action.params).pipe(
                map((response: any) => {
                    return new GetProcurementProjectsSucess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    constructor(private api: API,
        private action$: Actions,
        private location: Location,
        private store: Store<AppState>,
        private notificationService: NotificationsService) { }
}


