import { Injectable } from '@angular/core';
import { API } from 'src/app/services/Api.service';
import { DataService } from 'src/app/services/data.service';
import { Effect, Actions, ofType } from '@ngrx/effects';
import {
  MessagesActionTypes,
  GetInbox,
  GetInboxSuccess,
  GetMailById,
  GetMailByIdSuccess,
  MarkMailAsRead,
  DeleteMail,
  GetAllCmpMeassagesStart,
  GetSearchedMail,
  GetSearchedMailSuccess,
  GetMailByIdInAllCmpStart,
  GetMailByIdInAllCmpSuccess,
  GetSearchedAllMails,
  GetSearchedAllMailSuccess,
  CheckViewAccessGorDocStart,
  CheckViewAccessGorDocSuccess,
  AddViewAccessForDocumentStart,
  AddViewAccessForDocumentSuccess,
  DeleteMessage,
  AddMessageTag,
  SendContentToMail,
  DummyMessageAction,
  MarkMailAsReadSuccess,
  DeleteMessageSuccess,
  GetReplacedUsers,
  GetReplacedUsersSuccess,
  DeleteMailSuccess,
  GetMessageCount,
  ClearAction
} from './messages.actions';
import { of, EMPTY } from 'rxjs';
import { switchMap, map, catchError } from 'rxjs/operators';
import { NotificationsService } from "angular2-notifications";
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
import { ErrorAction } from 'src/app/login/store/auth.actions';
import { Router } from "@angular/router";
import { LoaderHide } from 'src/app/features/loader/store/loader.actions';
import { Message } from '@angular/compiler/src/i18n/i18n_ast';
import { CommonSuccessAction } from '../../resources/_store/resources.actions';

@Injectable()
export class MessagesEffects {

  @Effect()
  getInboxMails$ = this.action$.pipe(
    ofType(MessagesActionTypes.GET_INBOX),
    switchMap((action: GetInbox) => {
      return this.api.getMailList(action.payload).pipe(
        map((response: any) => {
          return new GetInboxSuccess(response);
        }),
        catchError(err => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  getMailById$ = this.action$.pipe(
    ofType(MessagesActionTypes.GET_MAIL_BY_ID),
    switchMap((action: GetMailById) => {
      return this.api.getMailById(action.threadId, action.replacedUsersId).pipe(
        map((response: any) => {
          return new GetMailByIdSuccess(response);
        }),
        catchError(err => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  markMailAsRead$ = this.action$.pipe(
    ofType(MessagesActionTypes.MARK_MAIL_AS_READ),
    switchMap((action: MarkMailAsRead) => {
      return this.api.markMailAsRead(action.threadId).pipe(
        map((response: any) => {
          if (action.myMessages) {
            return new MarkMailAsReadSuccess(response, action.threadId);
            // this.store.dispatch(new GetSearchedMail({searchString:'', 'page': 1, 'limit': 10}))
          }
          return of(EMPTY);
        }),
        catchError(err => of(EMPTY))
      );
    })
  );

  @Effect()
  deleteMail$ = this.action$.pipe(
    ofType(MessagesActionTypes.DELETE_MAIL),
    switchMap((action: DeleteMail) => {
      return this.api.deleteMail(action.threadId).pipe(
        map((response: any) => {
          return new DeleteMailSuccess(action.threadId);
          // return this.store.dispatch(new GetInbox({ 'page': 1, "limit": 10 }));
        }),
        catchError(err => of(new ErrorAction(err)))
      );
    })
  );
  @Effect()
  getAllInboxMails$ = this.action$.pipe(
    ofType(MessagesActionTypes.GET_CMP_ALL_START),
    switchMap((action: GetAllCmpMeassagesStart) => {
      return this.api.getAllMailList(action.obj).pipe(
        map((response: any) => {
          return new GetInboxSuccess(response);
        }),
        catchError(err => of(new ErrorAction(err)))
      );
    })
  );
  @Effect()
  getMailByIdInAllCmp$ =
    this.action$.pipe(
      ofType(MessagesActionTypes.GET_MAIL_BY_ID_IN_ALL_CMP_START),
      switchMap((action: GetMailByIdInAllCmpStart) => {
        return this.api.getMailByIdForAllCmp(action.threadId).pipe(
          map((response: any) => {
            return new GetMailByIdInAllCmpSuccess(response);
          }),
          catchError(err => of(new ErrorAction(err)))
        );
      })
    );


  @Effect()
  searchDocuments$ = this.action$.pipe(
    ofType(MessagesActionTypes.GET_SEARCHED_MAIL),
    switchMap((action: GetSearchedMail) => {
      return this.api.searchMail(action.payload).pipe(
        map((response: any) => {
          return new GetSearchedMailSuccess(response);
        }),
        catchError(err => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  searchAllDocuments$ = this.action$.pipe(
    ofType(MessagesActionTypes.GET_SEARCHED_ALL_MAIL),
    switchMap((action: GetSearchedAllMails) => {
      return this.api.searchAllMail(action.payload).pipe(
        map((response: any) => {
          return new GetSearchedAllMailSuccess(response);
        }),
        catchError(err => of(new ErrorAction(err)))
      );
    })
  );


  @Effect()
  checkViewAccess$ = this.action$.pipe(
    ofType(MessagesActionTypes.CHECK_VIEW_ACCESS_FOR_DOC_START),
    switchMap((action: CheckViewAccessGorDocStart) => {
      return this.api.checkViewAccessForDocsNew(action.payload).pipe(
        map((response: any) => {
          //   if(response && Object.keys(response).length) {
          //     this.store.dispatch(new LoaderHide());
          //   }
          if (response) {
            this.store.dispatch(new LoaderHide());
          }
          return new CheckViewAccessGorDocSuccess(response);
        }),
        catchError(err => of(new ErrorAction(err)))
      )
    })
  )

  @Effect()
  addViewAccess$ = this.action$.pipe(
    ofType(MessagesActionTypes.ADD_VIEW_ACCESS_FOR_DOCUMENTS_START),
    switchMap((action: AddViewAccessForDocumentStart) => {
      return this.api.addViewAccessForDocument(action.payload).pipe(
        map((resp: any) => {
          return new AddViewAccessForDocumentSuccess(resp);
        }),
        catchError(err => of(new ErrorAction(err)))
      )
    })
  )


  @Effect()
  deleteMessage$ = this.action$.pipe(
    ofType(MessagesActionTypes.DELETE_MESSAGE),
    switchMap((action: DeleteMessage) => {
      return this.api.deleteMessage(action.messageId).pipe(
        map((response: any) => {
          console.log(action);
          const rsp = {
            threadId: action.threadId,
            msgLength: action.messageLength
          };
          this.store.dispatch(new ClearAction());
          this.store.dispatch(new GetMessageCount({
            count: action.messageLength,
            thread: action.threadId,
            message: action.messageId
          }))

          this.notificationService.success("Message deleted successfully")
          this.store.dispatch(new DeleteMailSuccess(rsp));
          if (action.messageLength > 1) {
            return new GetMailById(action.threadId);
          } else {
            return new CommonSuccessAction();
          }

        }),
        catchError(err => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  addmessageTags$ = this.action$.pipe(
    ofType(MessagesActionTypes.ADD_MESSAGE_TAGS),
    switchMap((action: AddMessageTag) => {
      return this.api.addMessageTags(action.parentId, action.payload).pipe(
        map((response: any) => {
          this.notificationService.success(response.message)
          return new GetMailById(action.threadId)
        }),
        catchError(err => of(EMPTY))
      );
    })
  )

  @Effect()
  sendContentToMail$ = this.action$.pipe(
    ofType(MessagesActionTypes.SEND_CONTENT_TO_MAIL),
    switchMap((action: SendContentToMail) => {
      return this.api.sendContentToMail(action.parentId).pipe(
        map((response: any) => {
          this.notificationService.success("Message forwarded to your registered email successfully")
          return new DummyMessageAction()
        }),
        catchError(err => of(new ErrorAction(err)))
      )
    })
  )

  @Effect()
  getReplacedUsersInbox$ = this.action$.pipe(
    ofType(MessagesActionTypes.GET_REPLACED_USERS),
    switchMap((action: GetReplacedUsers) => {
      return this.api.getReplacedUsersInbox().pipe(
        map((response: any) => {
          return new GetReplacedUsersSuccess(response);
        }),
        catchError(err => of(new ErrorAction(err)))
      )
    })
  )

  constructor(
    private api: API,
    private action$: Actions,
    private notificationService: NotificationsService,
    private store: Store<AppState>,
    private location: Location,
    private router: Router,
  ) { }
}
