import { Action } from '@ngrx/store';
import {
    NotificationsActionTypes,
    GetAllRolesSuccess,
    GetNotificationsSuccess,
    UpdateNotificationSettingSuccess,
    GetWebNotificationsSuccess,
    GetWebNotificationCountSuccess,
    GetNewNotificationsSuccess
} from './notifications.actions';

export interface Notifications {
    roles: Array<any>;
    notifList: Array<any>;
    updNotifSettingRes: any;
    notifWebList: Array<any>;
    notificationCount: number;
    notifNewList: Array<any>;
}

const intiState = {
    roles: [],
    notifList: [],
    updNotifSettingRes: {},
    notifWebList: [],
    notificationCount: 0,
    notifNewList: []
}


export function reducer(state: Notifications = intiState, action: Action): Notifications {
    switch (action.type) {
        case NotificationsActionTypes.GET_ALL_ROLES_SUCCESS:
            return Object.assign({}, state, { roles: (action as GetAllRolesSuccess).roles });

        case NotificationsActionTypes.GET_NOTIFICATIONS_SUCCESS:
            return Object.assign({}, state, { notifList: (action as GetNotificationsSuccess).notificationsList });

        case NotificationsActionTypes.UPDATE_NOTIFICATION_SETTING_SUCCESS:
            return Object.assign({}, state, { updNotifSettingRes: (action as UpdateNotificationSettingSuccess).response })

        case NotificationsActionTypes.GET_WEB_NOTIFICATION_COUNT_SUCCESS:
            return Object.assign({}, state, { notificationCount: (action as GetWebNotificationCountSuccess).response });
            
        case NotificationsActionTypes.GET_WEB_NOTIFICATIONS_SUCCESS:
            return Object.assign({}, state, { notifWebList: (action as GetWebNotificationsSuccess).notificationsWebList });
        case NotificationsActionTypes.GET_NEW_NOTIFICATIONS_SUCCESS:
            return Object.assign({}, state, { notifNewList: (action as GetNewNotificationsSuccess).notificationsNewList });   
        default:
            return state;
    }
}