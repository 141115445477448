import { Action } from '@ngrx/store';

export enum UserGroupActionTypes {
  GET_USERS_GROUPS = '[UserGroup] Get Users Groups',
  GET_USERS_GROUPS_SUCCESS = '[UserGroup] Get Users Success',
  CREATE_GROUP = '[UserGroup] Create Group',
  GET_GROUP_DETAILS = '[UserGroup] Get Group Details',
  GET_GROUP_DETAILS_SUCCESS = '[UserGroup] Get Group Details Success',
  ADD_USERS_TO_GROUP = '[UserGroup] Add Users to Group',
  UPDATE_GROUP = '[UserGroup] Update Group',
  REMOVE_USER_FROM_GROUP = '[UserGroup] Remove User from Group',
  DELETE_GROUP = '[UserGroup] Delete Group',
  CREATE_GROUP_SUCCESS ='[UserGroup] Create Group Success',
  ADD_USER_TO_GROUP_SUCCESS ="[USerGroup] Add User To Group Success",
  UPDATE_GROUP_SUCCESS ="[Update groupSuccess]",
  CLEAR_RESPONSE_DATA_FOR_CREATE_GROUP ="[clear group data]",
  CLEAR_DATA_FOR_UPDATEGROUP ='[clear update group]',
  Clear_DATA_FOR_USER_ADD ='[clear data for added user]'
}

export class GetUsersGroups implements Action {
  readonly type: string = UserGroupActionTypes.GET_USERS_GROUPS;
  constructor() { }
}

export class GetUserGroupsSuccess implements Action {
  readonly type: string = UserGroupActionTypes.GET_USERS_GROUPS_SUCCESS;
  constructor(public userGroups: any) { }
}

export class CreateGroup implements Action {
  readonly type: string = UserGroupActionTypes.CREATE_GROUP;
  constructor(public payload: any, public selectedUsers? : any) { }
}

export class GetGroupDetails implements Action {
  readonly type: string = UserGroupActionTypes.GET_GROUP_DETAILS;
  constructor(public groupId: any) { }
}

export class GetGroupDetailsSuccess implements Action {
  readonly type: string = UserGroupActionTypes.GET_GROUP_DETAILS_SUCCESS;
  constructor(public groupDetails: any) { }
}

export class AddUsersToGroup implements Action {
  readonly type: string = UserGroupActionTypes.ADD_USERS_TO_GROUP;
  constructor(public groupId: string, public users: any, public action?: any) { }
}

export class UpdateGroup implements Action {
  readonly type: string = UserGroupActionTypes.UPDATE_GROUP;
  constructor(public groupId: string, public payload: any) { }
}

export class RemoveUserFromGroup implements Action {
  readonly type: string = UserGroupActionTypes.REMOVE_USER_FROM_GROUP;
  constructor(public groupId: string, public userId: string) { }
}

export class DeleteGroup implements Action {
  readonly type: string = UserGroupActionTypes.DELETE_GROUP;
  constructor(public groupId: string) { }
}
export class CreateGroupSuccess implements Action {
  readonly type : string =UserGroupActionTypes.CREATE_GROUP_SUCCESS;
  constructor(public response : any) {} 
}
export class AddUsersToGroupSuccess implements Action {
  readonly type : string =UserGroupActionTypes.ADD_USER_TO_GROUP_SUCCESS;
  constructor(public response :any) {}
}
export class UpdateGroupSuccess implements Action {
  readonly type : string =UserGroupActionTypes.UPDATE_GROUP_SUCCESS;
  constructor(public response : any) {}
}
export class ClearDataForAddGroup implements Action {
  readonly type :string =UserGroupActionTypes.CLEAR_RESPONSE_DATA_FOR_CREATE_GROUP;
  constructor() {}
}
export class ClearDataForUpdateGroup implements Action {
  readonly type : string =UserGroupActionTypes.CLEAR_DATA_FOR_UPDATEGROUP;
  constructor() {}
}
export class ClearDataForUserAdd implements Action {
  readonly type :string =UserGroupActionTypes.Clear_DATA_FOR_USER_ADD;
  constructor () {}
}
