import { Injectable } from "@angular/core";
import { API, BASE_URL } from "src/app/services/Api.service";
import { Effect, Actions, ofType } from "@ngrx/effects";
import {
    ResourcesActionTypes,
    GetAllDocuments,
    GetAllDocumentsSuccess,
    GetMyDocuments,
    GetMyDocumentsSuccess,
    GetThemes,
    GetThemesSuccess,
    AddDocument,
    GetAsOfVersionStartAction,
    GetAsOfVersionSuccess,
    GetTagList,
    GetTagListSuccess,
    GetDocumentById,
    SubmitForApproval,
    GetDocumentSuccess,
    GetDocumentVersionDetails,
    DownloadDocument,
    GetApprovalDataStartAction,
    GetApprovalDataSuccess,
    GetApprovalDetailsStartAction,
    GetApprovalDetailsSuccess,
    PublishDocument,
    RejectDocument,
    AddColloborators,
    GetCollaborators,
    AddViewers,
    GetViewers,
    GetViewersSuccess,
    GetCollaboratorsSuccess,
    GetSharedDocuments,
    GetSharedDocumentsSuccess,
    GetShareSuggestions,
    GetShareSuggestionsSuccess,
    GetSharedUsersList,
    ShareDocumentToMembers,
    EditUserPermissionForDoc,
    GetSharedUsersListSuccess,
    RemoveUserForDoc,
    GetUserCapabilitesOfDocument,
    GetPublishedDocuments,
    GetPublishedDocumentsSuccess,
    UnPublishDocument,
    GetUserCapabilitesOfDocumentSuccess,
    SearchDocuments,
    SearchResultsSuccess,
    UPDATEDOCUMENTSTARTACTION,
    GetFileByIdStart,
    GetFileByIdSuccess,
    AddNewDocumentSuccess,
    getComments,
    getCommentsSuccess,
    postComment,
    PostCommentsSuccess,
    CreateFolder,
    CreateFolderSuccess,
    GetMyFolders,
    GetMyFoldersSuccess,
    GetMyFilesAndFolders,
    GetMyFilesAndFoldersSuccess,
    GetFilesInMyFolder,
    GetFilesInMyFolderSuccess,
    DeleteFolder,
    DeleteFolderSuccess,
    MoveFileTo,
    MoveFileToSuccess,
    GetMoveMyFoldersSuccess,
    GetMoveFilesInMyFolderSuccess,
    DeleteFile,
    AddDocumentSuccess,
    AddSuggestedTags,
    ApproveTags,
    RejectTags,
    GetActivityLog,
    GetActivityLogSuccess,
    DeleteSuggestedTag,
    GetAllCmpDocuments,
    GetAllCmpDocumentsSuccess,
    CommonSuccessAction,
    RequestDocAccess,
    GetRequestDocAccessList,
    AcceptDocAccess,
    DenyDocAccess,
    GetRequestDocAccessListSuccess,
    GetUnpublishedDocuments,
    GetUnpublishedDocumentsSuccess,
    ReplaceDocument,
    MarkPublicDocument,
    MoveToRootFolder,
    CancelActivityLogStartForDocument,
    CancelActivityLogSuccess,
    MarkUnPublicDocument,
    RenameFolder,
    UpdateDocumentStartActionSuccess,
    GetDocUpdatedDetails,
    GetProjectSuggestions,
    GetCitisFolders,
    GetCitisFoldersSuccess,
    GetDocActivityLog,
    GetDocActivityLogSuccess,
    GetFolderDetails,
    GetFolderDetailsSuccess
} from "./resources.actions";
import { of } from "rxjs";
import { switchMap, map, catchError, tap } from "rxjs/operators";
import { NotificationsService } from "angular2-notifications";
import { AppState } from "src/app/app.reducer";
import { Store } from "@ngrx/store";
import { Location } from "@angular/common";
import { saveAs } from "file-saver";
import { ErrorAction } from "src/app/login/store/auth.actions";
import { Router } from "@angular/router";
import { LoaderHide } from "src/app/features/loader/store/loader.actions";
import { MatDialogRef } from "@angular/material";
import { UploadDocumentDialog } from "src/app/dialogs/upload-document/upload-document-dialog.component";
import { CheckViewAccessGorDocStart } from "../../user-messages/_store/messages.actions";
import { ViewListService } from "src/app/services/list-grid-view.service";

@Injectable()
export class ResourcesEffects {
    @Effect()
    getMyDocuments$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_MY_DOCUMENTS),
        switchMap((action: GetMyDocuments) => {
            return this.api.getMyAllDocuments(action.params).pipe(
                map((response: any) => {
                    return new GetMyDocumentsSuccess(response.docs);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getSharedDocuments$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_SHARED_DOCUMENTS),
        switchMap((action: GetSharedDocuments) => {
            return this.api.getSharedDocuments(action.params).pipe(
                map((response: any) => {
                    return new GetSharedDocumentsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getAllDocuments$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_ALL_DOCUMENTS),
        switchMap((action: GetAllDocuments) => {
            return this.api.getAllDocuments(action.params).pipe(
                map((response: any) => {
                    return new GetAllDocumentsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getAllCmpDocuments$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_ALL_CMP_DOCUMENTS),
        switchMap((action: GetAllCmpDocuments) => {
            return this.api.getAllCmpDocuments(action.params).pipe(
                map((response: any) => {
                    return new GetAllCmpDocumentsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getThemes$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_THEMES),
        switchMap((action: GetThemes) => {
            return this.api.getProjectThemes().pipe(
                map((response: any) => {
                    if (response.status) {
                        return new GetThemesSuccess(response.data);
                    } else {
                        return new GetThemesSuccess([]);
                    }
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    addDocument$ = this.action$.pipe(
        ofType(ResourcesActionTypes.SUBMIT_FOR_APPROVAL),
        switchMap((action: SubmitForApproval) => {
            return this.api.submitForApproval(action.docId, action.versionId).pipe(
                map((response: any) => {
                    if (response.docId && response.fileId) {
                        this.store.dispatch(new LoaderHide());
                        this.notificationService.success(
                            `Document uploaded successfully`
                        );
                        this.location.back();
                    }
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getAsOfVersion$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_AS_OF_VERSION),
        switchMap((action: GetAsOfVersionStartAction) => {
            return this.api.getAsOfVersion(action.data).pipe(
                map((response: any) => {
                    return new GetAsOfVersionSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getApprovalData$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_APPROVAL_START),
        switchMap((action: GetApprovalDataStartAction) => {
            return this.api.getApprovalData().pipe(
                map((response: any) => {
                    return new GetApprovalDataSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getApprovalDetails$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_APPROVAL_DETAILS_START),
        switchMap((action: GetApprovalDetailsStartAction) => {
            return this.api.getApprovalDetails(action.documentId).pipe(
                map((response: any) => {
                    return new GetApprovalDetailsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    publishDocument$ = this.action$.pipe(
        ofType(ResourcesActionTypes.PUBLISH_DOCUMENT),
        switchMap((action: PublishDocument) => {
            return this.api.publishDocument(action.docId, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(`Document published successfully`);
                    // this.store.dispatch(new GetAllDocuments());
                    this.router.navigateByUrl('home/resources/list');
                    return new CommonSuccessAction()
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        }
        ))

    @Effect()
    getPubDocs$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_PUBLISHED_DOCUMENT_LIST),
        switchMap((action: GetPublishedDocuments) => {
            return this.api.getPublishedDocuments().pipe(
                map((response: any) => {
                    return new GetPublishedDocumentsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    unPublishDocument$ = this.action$.pipe(
        ofType(ResourcesActionTypes.UN_PUBLISH_DOCUMENT),
        switchMap((action: UnPublishDocument) => {
            return this.api.unPublishDocument(action.docId).pipe(
                map((response: any) => {
                    this.notificationService.success(
                        `Document unpublished successfully`
                    );
                    this.router.navigateByUrl('home/resources/me');
                    return new CommonSuccessAction();
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    rejectDocument$ = this.action$.pipe(
        ofType(ResourcesActionTypes.REJECT_DOCUMENT),
        switchMap((action: RejectDocument) => {
            return this.api.rejectDocument(action.docId, action.versionId).pipe(
                map((response: any) => {
                    this.notificationService.success(`Document rejected`);
                    return new CommonSuccessAction()
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    // @Effect()
    // getTagList$ = this.action$.pipe(
    //   ofType(ResourcesActionTypes.GET_TAG_LIST),
    //   switchMap((action: GetTagList) => {
    //     return this.api.getTagList().pipe(
    //       map((response: any) => {
    //         if (response.status) {
    //           return new GetTagListSuccess(response.data);
    //         } else {
    //           return new GetTagListSuccess([]);
    //         }
    //       }),
    //       catchError(err => of(new ErrorAction(err)))
    //     );
    //   })
    // );

    @Effect()
    getDocument$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_DOCUMENT_BY_ID),
        switchMap((action: GetDocumentById) => {
            return this.api.getDocumentDetail(action.documentId, action.allCmp ).pipe(
                map((response: any) => {
                    return new GetDocumentSuccess(response);
                }),
                catchError(err => {
                    return of(new ErrorAction(err))
                })
            );
        })
    );

    @Effect()
    getUpdateDocument$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_UPDATED_DOC_DETAILS),
        switchMap((action: GetDocUpdatedDetails) => {
            return this.api.getDocUpdatedDetails(action.docId).pipe(
                map((response: any) => {
                    return new GetDocumentSuccess(response);
                }),
                catchError(err => {
                    return of(new ErrorAction(err))
                })
            );
        })
    );

    @Effect()
    getDocVersionDetail$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_DOCUMENT_VERSION_DETAILS),
        switchMap((action: GetDocumentVersionDetails) => {
            return this.api.getDocumentVersionDetail(action.id, action.version).pipe(
                map((response: any) => {
                    return new GetDocumentSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    addColloborators$ = this.action$.pipe(
        ofType(ResourcesActionTypes.ADD_COLLOBORATORS),
        switchMap((action: AddColloborators) => {
            return this.api.addCollaborators(action.documentId, action.payload).pipe(
                map((response: any) => {
                    return new GetCollaborators(action.documentId);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getColloborators$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_COLLABORATORS),
        switchMap((action: GetCollaborators) => {
            return this.api.getCollaborators(action.documentId).pipe(
                map((response: any) => {
                    return new GetCollaboratorsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    addViewers$ = this.action$.pipe(
        ofType(ResourcesActionTypes.ADD_VIEWERS),
        switchMap((action: AddViewers) => {
            return this.api.addViewers(action.documentId, action.payload).pipe(
                map((response: any) => {
                    return new GetViewers(action.documentId);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getViewers$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_VIEWERS),
        switchMap((action: GetViewers) => {
            return this.api.getViewers(action.documentId).pipe(
                map((response: any) => {
                    return new GetViewersSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getShareSuggestions$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_SHARE_SUGGESTIONS),
        switchMap((action: GetShareSuggestions) => {
            return this.api.shareSuggestions({ search: action.searchString, searchKeys: action.suggestions || '' }).pipe(
                map((response: any) => {
                    console.log("response",response);
                    return new GetShareSuggestionsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getSharedUsers$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_SHARED_USERS_LIST),
        switchMap((action: GetSharedUsersList) => {
            return this.api.getSharedUserForDocument(action.documentId).pipe(
                map((response: any) => {
                    return new GetSharedUsersListSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    shareDocument$ = this.action$.pipe(
        ofType(ResourcesActionTypes.SHARE_DOCUMENT),
        switchMap((action: ShareDocumentToMembers) => {
            return this.api
                .shareDocment(action.docId, action.permission, action.users)
                .pipe(
                    map((response: any) => {
                        this.notificationService.success(response.message);
                        return new GetSharedUsersList(action.docId);
                    }),
                    catchError(err => of(new ErrorAction(err)))
                );
        })
    );

    @Effect()
    editDocPermissionForUser$ = this.action$.pipe(
        ofType(ResourcesActionTypes.EDIT_USER_PERMISSION_FOR_DOC),
        switchMap((action: EditUserPermissionForDoc) => {
            return this.api
                .editDocPermissionForUser(
                    action.docId,
                    action.userType,
                    action.userId,
                    action.permission
                )
                .pipe(
                    map((response: any) => {
                        if (action.isFromAccessModal) {
                            // return new CheckViewAccessGorDocStart(action.docId);
                            return new CommonSuccessAction();
                        } else {
                            return new GetSharedUsersList(action.docId);
                        }
                    }),
                    catchError(err => of(new ErrorAction(err)))
                );
        })
    );

    @Effect()
    removeUser$ = this.action$.pipe(
        ofType(ResourcesActionTypes.REMOVE_USER_FOR_DOC),
        switchMap((action: RemoveUserForDoc) => {
            return this.api
                .removeUserForDoc(
                    action.docId,
                    action.userType,
                    action.userId,
                    action.permission
                )
                .pipe(
                    map((response: any) => {
                        return new GetSharedUsersList(action.docId);
                    }),
                    catchError(err => of(new ErrorAction(err)))
                );
        })
    );

    @Effect()
    searchDocuments$ = this.action$.pipe(
        ofType(ResourcesActionTypes.SEARCH_DOCUMENTS),
        switchMap((action: SearchDocuments) => {
            return this.api.searchDocuments(action.searchString, action.params, action.searchCallFromAllCmp).pipe(
                map((response: any) => {
                    return new SearchResultsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getUserCapabilitiesForDoc$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_USER_CAPABILITIES_FOR_DOC),
        switchMap((action: GetUserCapabilitesOfDocument) => {
            return this.api.getUserCapabilitiesForDocument(action.docId).pipe(
                map((response: any) => {
                    this.store.dispatch(
                        new GetUserCapabilitesOfDocumentSuccess(response)
                    );
                    return new GetSharedUsersList(action.docId);
                }),
                catchError(err => {
                    if (action.callType == 'documentDetail') {

                    }
                    return of(new ErrorAction(err))
                })
            );
        })
    );

    @Effect({ dispatch: false })
    downloadDocument$ = this.action$.pipe(
        ofType(ResourcesActionTypes.DOWNLOAD_DOCUMENT),

        switchMap((action: DownloadDocument) => {
            return this.api.fileDocLog(action.id).pipe(
                map((response: any) => {
                    let url;
                    url = `${BASE_URL}/docs/${action.id}/file`;
                    console.log("URL",url);
                    window.open(url);
                    return new CommonSuccessAction()
                }),
                catchError(err => {
                    return of(new ErrorAction(err))
                })
            );
        })
        // tap((action: DownloadDocument) => {
        //     let url;
        //     url = `${BASE_URL}/docs/${action.id}/file`;
        //     window.open(url);
        //     return new CommonSuccessAction()
        // }),
        // catchError(err => of(new ErrorAction(err)))
    );


    @Effect()
    addNewDocument$ = this.action$.pipe(
        ofType(ResourcesActionTypes.ADD_DOCUMENT),
        switchMap((action: AddDocument) => {
            if (action.callType == 'addDocument') {
                return this.api.uploadNewDocument(action.payload).pipe(
                    map((response: any) => {
                        if (response && response._id) {
                            this.notificationService.success(`Document created successfully`);
                            this.store.dispatch(new LoaderHide());
                            const folderId = action.payload.get('folderId');
                            if (folderId) {
                                if(action.isCitis){
                                this.router.navigateByUrl(`home/resources/me/citis/${folderId}`);
                                }else{
                                this.router.navigateByUrl(`home/resources/me/${folderId}`);
                                }
                            } else {
                                this.router.navigateByUrl('home/resources/me');
                            }
                            return new AddDocumentSuccess(response);
                        }
                    }),
                    catchError(err => {
                        this.store.dispatch(new LoaderHide());
                        return of(new ErrorAction(err));
                    })
                )
            }
            else if (action.callType == 'fromTask') {
                return this.api.uploadNewDocument(action.payload).pipe(
                    map((response: any) => {
                        if (response && response._id) {
                            this.store.dispatch(new LoaderHide());
                            // this.notificationService.success(`Document attached successfully`);
                            return new AddDocumentSuccess(response)
                        }
                    }),
                    catchError(err => of(new ErrorAction(err)))

                )
            }
            return of('')
        }))


    @Effect()
    getFileStart$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_FILE_BY_ID_START),
        switchMap((action: GetFileByIdStart) => {
            return this.api.getFileById(action.docId).pipe(
                map((resp: any) => {
                    if (resp) {
                        return new GetFileByIdSuccess(resp);
                    }
                }),
                catchError(err => {
                    return of(new ErrorAction(err));
                })
            )
        })
    )

    @Effect()
    tagUpdate$ = this.action$.pipe(
        ofType(ResourcesActionTypes.UPDATE_DOCUMENT_START_ACTION),
        switchMap((action: UPDATEDOCUMENTSTARTACTION) => {
            if (action.callType === 'tagUpdate') {
                return this.api.updateDocumentDetails(action.payload, action.id).pipe(
                    map((response: any) => {
                        this.notificationService.success(`Tags updated successfully`);
                        // this.location.back();
                        // this.store.dispatch(new GetDocumentById(response._id));
                        return new GetDocUpdatedDetails(response._id);
                    }),
                    catchError(err => {
                        return of(new ErrorAction(err));
                    }));
            } else if (action.callType === 'update') {
                return this.api.updateDocumentDetails(action.payload, action.id).pipe(
                    map((response: any) => {
                        this.store.dispatch(new LoaderHide());
                        this.notificationService.success(`Document updated successfully`);
                        this.location.back();
                        return new GetDocUpdatedDetails(response._id);
                    }),
                    catchError(err => {
                        this.store.dispatch(new LoaderHide());
                        let errorMsg = err.error.errors[0].error
                        if (errorMsg && errorMsg.length > 0) {
                            this.notificationService.error(errorMsg);
                        }
                        return of(new ErrorAction(err));
                    }));
            }
        })
    )

    @Effect({ dispatch: false })
    updateDocument$ = this.action$.pipe(
        ofType(ResourcesActionTypes.UPDATE_DOCUMENT_START_ACTION),
        switchMap((action: UPDATEDOCUMENTSTARTACTION) => {
            if (action.callType == 'addDocument') {
                return this.api.updateDocumentDetails(action.payload, action.id).pipe(
                    map((response: any) => {
                        if (response && response._id) {
                            this.notificationService.success(`Document created successfully`);
                            return this.router.navigateByUrl('home/resources/me');
                        }
                        return new CommonSuccessAction()
                    }),
                    catchError(err => of(new ErrorAction(err))
                    ));
            }
            return of('')
        })
    );

    @Effect()
    createFolder$ = this.action$.pipe(
        ofType(ResourcesActionTypes.CREATE_FOLDER),
        switchMap((action: CreateFolder) => {
            return this.api.createFolder(action.payload,action.isEdit).pipe(
                map((response: any) => {
                    if(action.isEdit){
                        this.notificationService.success('Folder Updated successfully');
                    }else{
                        this.notificationService.success('Folder created successfully');
                    }

                    if (action.payload.parentId) {
                        return new GetFilesInMyFolder(action.payload.parentId, { 'page': 1, 'limit': 30 });
                    } else {
                    console.log(action.payload.isSystemFolder)
                        if(action.payload.isSystemFolder){
                            this.viewData.CurrentSortBy.subscribe(sortBy => this.sortBy = sortBy)
                            this.viewData.CurrentSortOrder.subscribe(sortOrder => this.sortOrder = sortOrder)
                            return new GetCitisFolders({ 'page': 1, 'limit': 30 ,'sortBy': this.sortBy ,'sortOrder':this.sortOrder });
                        }
                        return new GetMyFilesAndFolders({ 'page': 1, 'limit': 30 });
                    }
                    //this.router.navigate(['home', 'resources', 'me']);
                    //return new CreateFolderSuccess(response)
                }),
                catchError(err => {
                    this.router.navigate(['home', 'resources', 'me'])
                    return of(new ErrorAction(err));
                })
            )
        })
    );

    @Effect()
    getMyFolders$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_MY_FOLDERS),
        switchMap((action: GetMyFolders) => {
            return this.api.getMyFolders().pipe(
                map((response: any) => {
                    return new GetMyFoldersSuccess(response.folders);
                }),
                catchError(err => {
                    return of(new ErrorAction(err));
                })
            )
        })
    );

    @Effect()
    getMyFilesAndFolders$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_MY_FILES_AND_FOLDERS),
        switchMap((action: GetMyFilesAndFolders) => {
            return this.api.getMyFilesAndFolders(action.params).pipe(
                map((response: any) => {
                    return new GetMyFilesAndFoldersSuccess(response);
                }),
                catchError(err => {
                    return of(new ErrorAction(err));
                })
            )
        })
    );

    @Effect()
    getFilesInMyFolder$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_FILES_IN_MY_FOLDER),
        switchMap((action: GetFilesInMyFolder) => {
            return this.api.getFilesInMyFolder(action.folderId, action.params).pipe(
                map((response: any) => {
                    return new GetFilesInMyFolderSuccess(response);
                }),
                catchError(err => {
                    return of(new ErrorAction(err))
                })
            )
        })
    );

    @Effect()
    deleteFolder$ = this.action$.pipe(
        ofType(ResourcesActionTypes.DELETE_FOLDER),
        switchMap((action: DeleteFolder) => {
            return this.api.deleteFolder(action.folderId).pipe(
                map((response) => {
                    if (response['success']) {
                        this.notificationService.success("Folder deleted successfully");
                    }
                    else {
                        this.notificationService.error(response['error']);
                    }
                    if (action.parentId) {
                        return new GetFilesInMyFolder(action.parentId, { 'page': 1, 'limit': 30 })
                    }
                    else
                        return new DeleteFolderSuccess(response);
                }),
                catchError(err => {
                    // this.notificationService.error("Error occurred while deleting folder");
                    return of(new ErrorAction(err));
                })
            )
        })
    );

    @Effect()
    getComments$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_COMMENTS),
        switchMap((action: getComments) => {
            return this.api.getComments(action.id, action.commentType).pipe(
                map((response: any) => {
                    return new getCommentsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    postComments$ = this.action$.pipe(
        ofType(ResourcesActionTypes.POST_COMMENT),
        switchMap((action: postComment) => {
            return this.api.postComment(action.comment).pipe(
                map((response: any) => {
                    this.notificationService.success(`Comment added successfully`);
                    return new PostCommentsSuccess(response);
                }),
                catchError(err => {
                    this.notificationService.error(err.error.error)
                    return of(new ErrorAction(err));
                })
            );
        })
    );

    @Effect()
    moveFileTo$ = this.action$.pipe(
        ofType(ResourcesActionTypes.MOVE_FILE),
        switchMap((action: MoveFileTo) => {
            return this.api.moveFile(action.docID, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success('File/Folder moved successfully');
                    return new MoveFileToSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getMoveMyFolders$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_MOVE_MY_FOLDERS),
        switchMap((action: GetMyDocuments) => {
            return this.api.getMyFolders().pipe(
                map((response: any) => {
                    return new GetMoveMyFoldersSuccess(response.folders);
                }),
                catchError(err => {
                    return of(new ErrorAction(err));
                })
            )
        })
    );

    @Effect()
    getMoveFilesInMyFolder$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_MOVE_FILES_IN_MY_FOLDER),
        switchMap((action: GetFilesInMyFolder) => {
            return this.api.getFilesInMyFolder(action.folderId, action.params).pipe(
                map((response: any) => {
                    return new GetMoveFilesInMyFolderSuccess(response);
                }),
                catchError(err => {
                    return of(new ErrorAction(err))
                })
            )
        })
    );

    @Effect()
    deleteFile$ = this.action$.pipe(
        ofType(ResourcesActionTypes.DELETE_FILE),
        switchMap((action: DeleteFile) => {
            return this.api.deleteDocument(action.id, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success('File deleted successfully');

                    if (action.folderId) {
                        let payload = { page: 1, limit: 30 };
                        let fId = action.folderId;
                        return new GetFilesInMyFolder(fId, payload);
                    } else {
                        return new GetMyFilesAndFolders({ page: 1, limit: 30 });
                    }

                    //return this.router.navigateByUrl('home/resources/me');
                }),
                catchError(err => {
                    return of(new ErrorAction(err))
                })
            )
        })
    );

    @Effect()
    tagSuggested$ = this.action$.pipe(
        ofType(ResourcesActionTypes.SUBMIT_SUGGESTED_TAGS),
        switchMap((action: AddSuggestedTags) => {
            return this.api.addSuggestedTags(action.id, action.suggestedTags).pipe(
                map((response: any) => {
                    this.notificationService.success(`Tag suggested successfully`);
                    this.store.dispatch(new GetDocUpdatedDetails(action.id));
                    return new CommonSuccessAction()
                }),
                catchError(err => of(new ErrorAction(err))
                ));
        })
    )

    @Effect()
    approveTag$ = this.action$.pipe(
        ofType(ResourcesActionTypes.APPROVE_TAG),
        switchMap((action: ApproveTags) => {
            return this.api.approveTag(action.id, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message || `Tag approved successfully`);
                    this.store.dispatch(new GetDocUpdatedDetails(action.id));
                    return new CommonSuccessAction();
                }),
                catchError(err => of(new ErrorAction(err))
                ))
        })
    )

    @Effect()
    rejectTag$ = this.action$.pipe(
        ofType(ResourcesActionTypes.REJECT_TAG),
        switchMap((action: RejectTags) => {
            return this.api.rejectTag(action.id, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message || `Tag rejected successfully`);
                    this.store.dispatch(new GetDocUpdatedDetails(action.id));
                    return new CommonSuccessAction()
                }),
                catchError(err => of(new ErrorAction(err))));
        })
    )

    @Effect()
    getActivityLog$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_ACTIVITY_LOG),
        switchMap((action: GetActivityLog) => {
            return this.api.getDocumentActivityLog(action.documentId).pipe(
                map((activityLog: any) => {
                    return new GetActivityLogSuccess(activityLog);
                }),
                // catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    deleteSuggestedTag$ = this.action$.pipe(
        ofType(ResourcesActionTypes.DELETE_SUGGESTED_TAG),
        switchMap((action: DeleteSuggestedTag) => {
            return this.api.deleteSuggetsedTag(action.docId, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(`Suggested tag removed successfully`);
                    this.store.dispatch(new GetDocUpdatedDetails(action.docId));
                    return new CommonSuccessAction()
                }),
                catchError(err => of(new ErrorAction(err))
                ));
        })
    )

    @Effect()
    getUnpublishedDocuments$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_UNPUBLISHED_DOCUMENTS),
        switchMap((action: GetUnpublishedDocuments) => {
            return this.api.getUnpublishedDocuments(action.params).pipe(
                map((response: any) => {
                    // this.router.navigateByUrl('/home/resources/list');
                    return new GetUnpublishedDocumentsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect({ dispatch: false })
    replaceDocument$ = this.action$.pipe(
        ofType(ResourcesActionTypes.REPLACE_DOCUMENT),
        switchMap((action: ReplaceDocument) => {
            return this.api.replaceDocument(action.params, action.payload).pipe(
                map((response: any) => {
                    this.router.navigateByUrl('/home/resources/list');
                    this.notificationService.success("Document replaced successfully");
                    // return new CommonSuccessAction();
                }),
                catchError((err) => {
                    this.notificationService.error(err.error.errors[0].error);
                    return of(new ErrorAction(err));
                })
            )
        })
    )

    @Effect()
    AcceptAccessDocRequest$ = this.action$.pipe(
        ofType(ResourcesActionTypes.ACCEPT_REQUEST_ACCESS),
        switchMap((action: AcceptDocAccess) => {
            return this.api.acceptAccessRequest(action.id).pipe(
                map((response: any) => {
                    this.notificationService.success(`Request approved successfully`);
                    return new GetRequestDocAccessList(action.docId);
                }),
                catchError(err => of(new ErrorAction(err))
                ))
        })
    )

    @Effect()
    DenyAccessDocRequest$ = this.action$.pipe(
        ofType(ResourcesActionTypes.DENY_REQUEST_ACCESS),
        switchMap((action: DenyDocAccess) => {
            return this.api.denyAccessRequest(action.id).pipe(
                map((response: any) => {
                    this.notificationService.success(`Request rejected successfully`);
                    return new GetRequestDocAccessList(action.docId);
                }),
                catchError(err => of(new ErrorAction(err))
                ))
        })
    )

    @Effect()
    markPublicDocument$ = this.action$.pipe(
        ofType(ResourcesActionTypes.MARK_PUBLIC_DOCUMENT),
        switchMap((action: MarkPublicDocument) => {
            return this.api.markPublicDocument(action.docId).pipe(
                map((response: any) => {
                    this.notificationService.success(
                        `Document published successfully`
                    );
                    this.store.dispatch(new GetDocUpdatedDetails(action.docId));
                    return new CommonSuccessAction()
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    RequestDocAccess$ = this.action$.pipe(
        ofType(ResourcesActionTypes.REQUEST_ACCESS),
        switchMap((action: RequestDocAccess) => {
            return this.api.requestDocAccess(action.id).pipe(
                map((response: any) => {
                    this.notificationService.success(`Edit access requested successfully`);
                    return new GetDocUpdatedDetails(action.id);
                }),
                catchError(err => of(new ErrorAction(err))
                ))
        })
    )

    @Effect()
    GetAccessRequestList$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_REQUEST_ACCESS_LIST),
        switchMap((action: GetRequestDocAccessList) => {
            return this.api.getAccessRequestsList(action.id).pipe(
                map((response: any) => {
                    return new GetRequestDocAccessListSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err))
                ))
        })
    )

    @Effect({ dispatch: false })
    moveToRootFolder$ = this.action$.pipe(
        ofType(ResourcesActionTypes.MOVE_TO_ROOT_FOLDER),
        switchMap((action: MoveToRootFolder) => {
            return this.api.moveToRootFolder(action.folderId, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success("File/Folder moved successfully");
                    this.store.dispatch(new GetFilesInMyFolder(action.folderId, { page: 1, limit: 30 }));
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    activityLog$ = this.action$.pipe(
        ofType(ResourcesActionTypes.CANCEL_ACTIVITY_LOG_START),
        switchMap((action: CancelActivityLogStartForDocument) => {
            return this.api.postActivityLogForDocument(action.parentId).pipe(map((resp) => {
                return new CancelActivityLogSuccess()
            }),
                catchError(err => of(new ErrorAction(err))))

        })
    )
    @Effect()
    unPublicDocument$ = this.action$.pipe(
        ofType(ResourcesActionTypes.MARK_DOCUMENT_AS_UNPUBLIC),
        switchMap((action: MarkUnPublicDocument) => {
            return this.api.unPublicDocument(action.docId).pipe(
                map((response: any) => {
                    this.notificationService.success(
                        `Document unpublished successfully`);
                    this.store.dispatch(new GetDocUpdatedDetails(action.docId));
                    return new CommonSuccessAction()
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    renameFolder$ = this.action$.pipe(
        ofType(ResourcesActionTypes.RENAME_FOLDER),
        switchMap((action: RenameFolder) => {

            return this.api.renameFolder(action.folderObj, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success('Folder renamed successfully');
                    if (action.folderObj.parentId) {
                        return new GetFilesInMyFolder(action.folderObj.parentId, { 'page': 1, 'limit': 30 });
                    } else {
                        this.store.dispatch(new GetCitisFolders({}));
                        return new GetMyFilesAndFolders({ 'page': 1, 'limit': 30 });
                    }
                    //this.router.navigate(['home', 'resources', 'me']);
                    //return new CreateFolderSuccess(response)
                }),
                catchError(err => {
                    this.router.navigate(['home', 'resources', 'me'])
                    return of(new ErrorAction(err));
                })
            )
        })
    );

    @Effect()
    getProjectSuggestions$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_PROJECT_SUGGESTIONS),
        switchMap((action: GetProjectSuggestions) => {
            return this.api.shareProjectSuggestions({ search: action.searchString}).pipe(
                map((response: any) => {
                    return new GetShareSuggestionsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getCitisFolder$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_CITIS_FOLDER),
        switchMap((action: GetCitisFolders) => {
            return this.api.getCitisMyFolders(action.params).pipe(
                map((response: any) => {
                    return new GetCitisFoldersSuccess(response);
                }),
                catchError(err => {
                    return of(new ErrorAction(err));
                })
            )
        })
    );

    @Effect()
    getDocActivity$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_DOC_ACTIVITY),
        switchMap((action: GetDocActivityLog) => {
            return this.api.getDocActivity({ 'folderId': action.payload }).pipe(
                map((response: any) => {
                    console.log(response)
                    return new GetDocActivityLogSuccess(response);
                }),
                catchError(err => {
                    return of(new ErrorAction(err));
                })
            )
        })
    );

    @Effect()
    getFolderDetails$ = this.action$.pipe(
        ofType(ResourcesActionTypes.GET_FOLDER_DETAILS),
        switchMap((action: GetFolderDetails) => {
            return this.api.getFolderDetails(action.folderId).pipe(
                map((response: any) => {
                    // this.notificationService.success(response.message);
                    return new GetFolderDetailsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );
    sortBy: any;
    sortOrder: string;


    constructor(
        private api: API,
        private action$: Actions,
        private notificationService: NotificationsService,
        private store: Store<AppState>,
        private location: Location,
        private router: Router,
        private viewData: ViewListService,

        // private dialogRef: MatDialogRef<>

    ) { }
}
