import { EventEffects } from "./features/home/events/store/event.effects";
import { ProcurementEffects } from "./features/home/procurement/store/procurement.effects";
import { UserManagementEffects } from "./features/home/user-management/store/user-managment.effects";
import { ResourcesEffects } from "./features/home/resources/_store/resources.effects";
import { AuthEffects } from "./login/store/auth.effects";
import { ProgramDashboardEffects } from "./features/home/program-dashboard/store/program-dashboard.effects";
import { UserGroupEffects } from "./features/home/user-groups/store/user-groups.effects";
import { TasksEffects } from "./features/home/tasks/store/tasks.effects";
import { MessagesEffects } from "./features/home/user-messages/_store/messages.effects";
import { TagsEffects } from "./features/home/tags/store/tags.effects";
import { ProfileEffects } from "./features/home/profile/store/profile.effects";
import { TemplateEffects } from "./features/home/templates/store/templates.effects";
import { CapabilitiesEffects } from "./features/home/capabilities/store/capabilities.effects";
import { ConfigurationsEffects } from "./features/home/configurations/store/configurations.effects";
import { NotificationsEffects } from "./features/home/notifications/store/notifications.effects";
import { PrivateUserGroupEffects } from "./features/home/private-members/store/private-member.effects";
import { ContractManagementEffects } from "./features/contract-management/store/contract-management.effects";
import { MonitoringEvaluationEffects } from "./features/home/monitoring-evaluation/store/monitoring-evaluation.effects";
import { BudgetEffects } from "./features/home/budget/store/budget.effects";

export const AppEffects = [
  UserManagementEffects,
  ResourcesEffects,
  AuthEffects,
  ProgramDashboardEffects,
  UserGroupEffects,
  TasksEffects,
  MessagesEffects,
  TagsEffects,
  ProfileEffects,
  TemplateEffects,
  PrivateUserGroupEffects,
  CapabilitiesEffects,
  ConfigurationsEffects,
  NotificationsEffects,
  ContractManagementEffects,
  MonitoringEvaluationEffects,
  EventEffects,
  ProcurementEffects,
  BudgetEffects
];
