import { Action } from "@ngrx/store";

export enum EventActionTypes {
  CREATE_EVENT = "[Event] Create Event",
  CREATE_EVENT_SUCCESS = "[Event] Event Success",
  UPDATE_EVENT = "[Event] Update Event",
  UPDATE_EVENT_SUCCESS = "[Event] Update Event Success",
  GET_FAV_LIST = "[Fav] list ",
  GET_FAV_LIST_SUCCESS = "[Fav] Fav list loaded",
  GET_FAV_MEMBER_LIST = "[Fav] Member list",
  GET_FAV_MEMBER_LIST_SUCCESS = "[Fav] Member list success",
  CREATE_FAV_GROUP_INVITIEE = "[FavGroup] Create Fav Group Invitee",
  CREATE_FAV_GROUP_INVITIEE_SUCCESS = "[FavGroup] Create Fav Group Invitee Success",
  GET_INVITEE_MEMBER_LIST_BY_ID = "[Member List] Get Invitee Member List By Id",
  GET_INVITEE_MEMBER_LIST_BY_ID_SUCCESS = "[Member List] Get Invitee Member List By Id Success",
  INSERT_INVITEES_TO_EVENT = "[Event Send] Insert Invitees To Event",
  INSERT_INVITEES_TO_EVENT_SUCCESS = "[Event Send] Insert Invitees To Event Success",
  DELETE_INVITEES = "[delete] delete invitee ",
  DELETE_INVITEES_SUCCESS = "[delete] delete invitee success",
  INSERT_End_AND_APR_TO_EVENT = "[APPROVER ENDSERVER SEND] Insert APP  To Event  ",
  INSERT_End_AND_APR_TO_EVENT_SUCCESS = "[APPROVER ENDSERVER SEND] Insert APP To Event Success",
  INSERT_NEW_FIELD_TO_EVENT = "[ADD FIELD] Insert Fild for Invitees",
  INSERT_NEW_FIELD_TO_EVENT_SUCCESS = "[ADD FIELD] Insert Fild for Invitees Success",
  GET_EVENT_LIST = "[Event] Event List",
  GET_EVENT_LIST_BY_SUCCESS = "[Event] Event List Sucecss",
  GET_TEMPLATE_FIELD = "[GET FIELD] Get Template Field",
  GET_TEMPLATE_FIELD_SUCCESS = "[GET FIELD]  Get Template Field  Sucecss",
  GET_EVENT_DETAILS_BY_ID = "[Event Details] Get Events Details By Id",
  GET_EVENT_DETAILS_BY_ID_SUCCESS = "[Event Details] Get Events Details By Id Success",
  UPDATE_MEMBER_LIST = "[Update Member] Update Member List By Id",
  UPDATE_MEMBER_LIST_SUCCESS = "[Update Member] Update Member ListBy Id Success",
  DELETE_MEMBER_LIST = "[DELETE Member] Delete Member List By Id",
  DELETE_MEMBER_LIST_SUCCESS = "[DELETE Member] Delete Member ListBy Id Success",
  UPDATE_EMAIL_TEMPLATE = "[Update Template] Update Template by Id",
  UPDATE_EMAIL_TEMPLATE_SUCCESS = "[Update Template] Update Template by Id Success",
  SUBMIT_FOR_APPROVAL = "[For Approval] submit for approval by Id ",
  SUBMIT_FOR_APPROVAL_SUCCESS = "[For Approval] submit for approval by Id  Success",
  GET_FOR_APPROVAL_LIST = "[Approval List] Approval Event List ",
  GET_FOR_APPROVAL_LIST_SUCCESS = "[Approval List] Approval Event List Success",
  GET_FOR_APPROVAL_LIST_FILTER = "[Approval List] Approval filter Event List Success",
  EVENT_APPROVE_REJECT = "[Event approve Reject] Approve Reject",
  EVENT_APPROVE_REJECT_SUCCESS = "[Event approve Reject] Approve Reject Success",

  EVENT_APPROVE_REJECT_CLEAR = "[CLEAR] event Approve reject clear",

  GET_FOR_ENDORSEMENT_LIST = "[Endorse List] Endorse Event List ",
  GET_FOR_ENDORSEMENT_LIST_FILTER = "[Endorse List] Endorse Filter Event List ",
  GET_FOR_ENDORSEMENT_LIST_SUCCESS = "[Endorse List] Endorse Event List Success",
  EVENT_ENDORSE_REJECT = "[Event Endorse Reject] Endorse Reject",
  EVENT_ENDORSE_REJECT_SUCCESS = "[Event Endorse Reject] Endorse Reject Success",

  EVENT_ENDORSE_REJECT_CLEAR = "[CLEAR] event endorse reject clear",

  INVITEE_MAIL_SENT = "[Email Sent] Invitees Mail Sent",
  INVITEE_MAIL_SENT_SUCCESS = "[Email Sent] Invitees Mail Sent Success",
  SUBMIT_RSVP = "[RSVP] Registration data",
  SUBMIT_RSVP_SUCCESS = "[RSVP] Registration data saved successfully",
  INSERT_NEW_FIELD_TO_FEEDBACK = "[ADD FIELD] Insert field for feedback",
  INSERT_NEW_FIELD_TO_FEEDBACK_SUCCESS = "[ADD FIELD] Insert field for feedback success",
  GET_FEEDBACK_TEMPLATE_FIELD = "[ Feedback Template ] Get Feedback Template Fields",
  GET_FEEDBACK_TEMPLATE_FIELD_SUCCESS = "[ Feedback Template ] Get Feedback Template Fields Success",
  UPDATE_FEEDBACK_TEMPLATE = "[Update feedback Template] Update feedback Template by Id",
  UPDATE_FEEDBACK_TEMPLATE_SUCCESS = "[Update feedback Template] Update feedback Template by Id Success",
  UPDATE_EMAIL_TEMPLATE_FIELD = "[Update Email Template Field] Update Email Template Field by Id",
  UPDATE_EMAIL_TEMPLATE_FIELD_SUCCESS = "[Update Email Template Field] Update Email Template Field by Id Success",
  SUBMIT_RSVP_FEEDBACK = "[RSVP Feedback] RSVP Feedback data",
  SUBMIT_RSVP_FEEDBACK_SUCCESS = "[RSVP Feedback] RSVP Feedback data saved successfully",
  UPDATE_FEEDBACK_TEMPLATE_FIELD = "[Update Feedback Template Field] Update Feedback Template Field by Id",
  UPDATE_FEEDBACK_TEMPLATE_FIELD_SUCCESS = "[Update Feedback Template Field] Update Feedback Template Field by Id Success",
  ADD_COMMENTS = "[Add Comments] Add Comments saved",
  ADD_COMMENTS_SUCCESS = "[Add Comments] Add Comments saved successfully",
  GET_EVENTS_COMMENTS = "[Get Event Comment] get event comment by id",
  GET_EVENTS_COMMENTS_SUCCESS = "[Get Event Comment] get event comment by id successfully",
  GET_EVENTS_FEEDBACK = "[GET FEEDBACK] Get Event feedback details",
  GET_EVENTS_FEEDBACK_SUCCESS = "[GET FEEDBACK] Get Event feedback details Success",
  SUBMIT_EVENT_REPORT = "[Event Report] Submit Event Report",
  SUBMIT_EVENT_REPORT_SUCCESS = "[Event Report] Submit Event Report Success",
  SUBMIT_APPROVER_ENDORSER_REPORT = "[Event Report] Submit approver and Endorser in Report",
  SUBMIT_APPROVER_ENDORSER_REPORT_SUCCESS = "[Event Report] Submit approver and Endorser in Report Success",
  ADD_VIDEO_IMAGE_REPORT = "[Event Report] Add image and Video In Report",
  ADD_VIDEO_IMAGE_REPORT_SUCCESS = "[Event Report] Add image and Video In Report Success",
  REMOVE_VIDEO_IMAGE_REPORT = "[Event Report] Remove image and Video In Report",
  REMOVE_VIDEO_IMAGE_REPORT_SUCCESS = "[Event Report] Remove image and Video In Report Success",
  GET_EVENTS_REPORT = "[Event Report] Get Event Report",
  GET_EVENTS_REPORT_SUCCESS = "[Event Report] Get Event Report Success",
  GET_EVENTS_APPROVER = "[Event Report] Approver List of Report",
  GET_EVENTS_APPROVER_SUCCESS = "[Event Report] Approver List of Report Success",
  GET_EVENTS_ENDORSER = "[Event Report] Endorser List of Report",
  GET_EVENTS_ENDORSER_SUCCESS = "[Event Report] Endorser List of Report Success",
  UPDATE_EVENT_REPORT = "[Event Report] Update Event Report",
  UPDATE_EVENT_REPORT_SUCCESS = "[Event Report] Update Event Report Success",
  DELETE_APPROVER_ENDORSER_IN_EVENT = "[Event Report] DELETE APPROVER endorser in event",
  DELETE_APPROVER_ENDORSER_IN_EVENT_SUCCESS = "[Event Report] DELETE APPROVER endorser in event success",
  CANCEL_EVENT = "[Event Report] cancel event",
  CANCEL_EVENT_SUCCESS = "[Event Report] cancel event success",
  GET_RSVP_LIST = "[List] rsvp list",
  GET_RSVP_LIST_SUCCESS = "[List] rsvp list Success",
  GET_FEEDBACK_LIST = "[feedback] event feedback list ",
  GET_FEEDBACK_LIST_SUCCESS = "[feedback] event feedback list Success ",
  GET_CALENDER_LIST = "[calender] calender list",
  GET_CALENDER_LIST_SUCCESS = "[calender] calender list Success",

  EVENT_REPORT_LIST = "[event report] event Report list",
  EVENT_REPORT_LIST_SUCCESS = "[event report] event Report list success",
  EVENT_REPORT_APPROVE_REJECT = "[event report] event report approve and reject",
  EVENT_REPORT_APPROVE_REJECT_SUCCESS = "[event report] event report approve and reject Success",
  EVENT_REPORT_ENDORSE_REJECT = "[event report] event report endorse and reject",
  EVENT_REPORT_ENDORSE_REJECT_SUCCESS = "[event report] event endorse approve and reject Success",

  SUBMIT_FOR_APPROVAL_REPORT = "[For Approval Report] submit for approval report by Id ",
  SUBMIT_FOR_APPROVAL_REPORT_SUCCESS = "[For Approval Report] submit for approval report by Id  Success",
  EVENT_FEEDBACK_LIST = "[ list] feedback list",
  EVENT_FEEDBACK_LIST_SUCCESS = "[ list]feedback list Success",
  LOG_LIST = "[log list] log list ",
  LOG_LIST_SUCCESS = "[log list]log list Success",

  CLAIM_REASSIGN_REQUEST_EVENT = "[reassign] claim request reassign for owner ",
  CLAIM_REASSIGN_REQUEST_EVENT_SUCCESS = "[reassign] claim request reassign for owner Success",
  UPDATE_OWNER_EVENT = "[update owner] update owner of event",
  UPDATE_OWNER_EVENT_SUCCESS = "[update owner] update owner of event success",
  GET_EVENT_DETAILS_CLEAR = "[clear details]",

  EVENT_SCHEDULED = "[scheduled] event sceduled",
  EVENT_SCHEDULED_SUCCESS = "[scheduled] event sceduled success",

  EVENT_LOG_CLEAR = "[log] clear log",
  EVENT_COMMENTS_CLEAR = "[comment] clear comment",

  COMMENT_SUBMISSION_CLEAR = "[SUBMIT CLEAREANCE]",

  DONT_WANT_ATTEND="[DONT ATTEND] NOT attending",
  DONT_WANT_ATTEND_SUCCESS="[DONT ATTEND] NOT attending Success",

  ATTENDEE_LIST="[LIST] attendee list",
  ATTENDEE_LIST_SUCCESS ="[LIST] attendee list Success",

  FEEDBACK_USER_LIST="[LIST] feedback user list",
  FEEDBACK_USER_LIST_SUCCESS="[LIST] feedback user list success",
  ADD_FEEDBACK_USER="[ADD] ADD FEEDBACK USER",
  ADD_FEEDBACK_USER_SUCCESS="[ADD] ADD FEEDBACK USER Success",

EDIT_FEEDBACK_USER="[EDIT] feedback user edit",
EDIT_FEEDBACK_USER_SUCCESS="[EDIT] feedback user edit success",
DELETE_FEEDBACK_USER="[DELETE] feedback user delete",
DELETE_FEEDBACK_USER_SUCCESS="[DELETE] feedback user delete success",
FEEDBACK_MAIL_SENT="[MAIL SENT] feedback mail sent",
FEEDBACK_MAIL_SENT_SUCCESS="[MAIL SENT] feedback mail sent success",
GET_QR_OF_EVENT="[QR] Get Event Qr",
GET_QR_OF_EVENT_SUCCESS="[QR] Get Event Qr Success",

DELETE_APPROVAR_FROM_CR_EVENT = "[DELETE] approvar delete in create event",
DELETE_APPROVAR_FROM_CR_EVENT_SUCCESS = "[DELETE] approvar delete in create event success",
DELETE_ENDORSER_FROM_CR_EVENT = "[DELETE] endorser delete in create event ",
DELETE_ENDORSER_FROM_CR_EVENT_SUCCESS = "[DELETE] endorser delete in create event success",

DELETE_REGISTRAION_TEMPLATE_FIELD = "[DELETE] Registration template field delete ",
DELETE_REGISTRAION_TEMPLATE_FIELD_SUCCESS = "[DELETE] Registration template field deletesuccess",
DELETE_FEEDBACK_TEMPLATE_FIELD = "[DELETE] feedback template field delete ",
DELETE_FEEDBACK_TEMPLATE_FIELD_SUCCESS = "[DELETE] feedback template field delete success",

MARK_AS_A_COMPLETE="[mark] Mark as a Complete report ",
MARK_AS_A_COMPLETE_SUCCESS="[mark] Mark as a Complete report Success",

DELETE_APPROVAR_FROM_CR_EVENT_REPORT = "[DELETE] approvar delete in create event Report",
DELETE_APPROVAR_FROM_CR_EVENT_REPORT_SUCCESS = "[DELETE] approvar delete in create event Report success",
DELETE_ENDORSER_FROM_CR_EVENT_REPORT = "[DELETE] endorser delete in create event Report ",
DELETE_ENDORSER_FROM_CR_EVENT_REPORT_SUCCESS = "[DELETE] endorser delete in create event  Report success",

}

export class CreateEvent implements Action {
  readonly type: string = EventActionTypes.CREATE_EVENT;
  constructor(public payload: any) {}
}

export class CreateEventSuccess implements Action {
  readonly type: string = EventActionTypes.CREATE_EVENT_SUCCESS;
  constructor(public response: any) {}
}

export class UpdateEvent implements Action {
  readonly type: string = EventActionTypes.UPDATE_EVENT;
  constructor(public payload: any) {}
}

export class UpdateEventSuccess implements Action {
  readonly type: string = EventActionTypes.UPDATE_EVENT_SUCCESS;
  constructor(public response: any) {}
}

export class FavList implements Action {
  readonly type: string = EventActionTypes.GET_FAV_LIST;
  constructor() {}
}

export class FavListSuccess implements Action {
  readonly type: string = EventActionTypes.GET_FAV_LIST_SUCCESS;
  constructor(public response: any) {}
}

export class FavMemberList implements Action {
  readonly type: string = EventActionTypes.GET_FAV_MEMBER_LIST;
  constructor(public payload: any) {}
}

export class FavMemberListSuccess implements Action {
  readonly type: string = EventActionTypes.GET_FAV_MEMBER_LIST_SUCCESS;
  constructor(public response: any) {}
}

export class CreatefavGroupInvitee implements Action {
  readonly type: string = EventActionTypes.CREATE_FAV_GROUP_INVITIEE;
  constructor(public payload: any) {}
}

export class CreatefavGroupInviteeSuccess implements Action {
  readonly type: string = EventActionTypes.CREATE_FAV_GROUP_INVITIEE_SUCCESS;
  constructor(public response: any) {}
}

export class getInviteeMemberListById implements Action {
  readonly type: string = EventActionTypes.GET_INVITEE_MEMBER_LIST_BY_ID;
  constructor(public payload: any) {}
}

export class getInviteeMemberListByIdSuccess implements Action {
  readonly type: string =
    EventActionTypes.GET_INVITEE_MEMBER_LIST_BY_ID_SUCCESS;
  constructor(public response: any) {}
}

export class insertInviteesToEvent implements Action {
  readonly type: string = EventActionTypes.INSERT_INVITEES_TO_EVENT;
  constructor(public payload: any, public Id: any) {}
}

export class insertInviteesToEventSuccess implements Action {
  readonly type: string = EventActionTypes.INSERT_INVITEES_TO_EVENT_SUCCESS;
  constructor(public response: any) {}
}

export class deleteInvtee implements Action {
  readonly type: string = EventActionTypes.DELETE_INVITEES;
  constructor(public eventId: any, public inviteId: any) {}
}

export class deleteInvteeSuccess implements Action {
  readonly type: string = EventActionTypes.DELETE_INVITEES_SUCCESS;
  constructor(public response: any) {}
}

export class EventList implements Action {
  readonly type: string = EventActionTypes.GET_EVENT_LIST;
  constructor(public payload: any, public eventListType: any) {}
}

export class getEventListBySuccess implements Action {
  readonly type: string = EventActionTypes.GET_EVENT_LIST_BY_SUCCESS;
  constructor(public response: any) {}
}
export class insertEndAndAPRToEvent implements Action {
  readonly type: string = EventActionTypes.INSERT_End_AND_APR_TO_EVENT;
  constructor(public payload: any, public Id: any) {}
}

export class insertEndAndAPRToEventSuccess implements Action {
  readonly type: string = EventActionTypes.INSERT_End_AND_APR_TO_EVENT_SUCCESS;
  constructor(public response: any) {}
}

export class AddnewfieldEvent implements Action {
  readonly type: string = EventActionTypes.INSERT_NEW_FIELD_TO_EVENT;
  constructor(public payload: any, public Id: any) {}
}

export class AddnewfieldEventSuccess implements Action {
  readonly type: string = EventActionTypes.INSERT_NEW_FIELD_TO_EVENT_SUCCESS;
  constructor(public response: any) {}
}

export class getFieldTemplate implements Action {
  readonly type: string = EventActionTypes.GET_TEMPLATE_FIELD;
  constructor(public Id: any) {}
}

export class getFieldTemplateSuccess implements Action {
  readonly type: string = EventActionTypes.GET_TEMPLATE_FIELD_SUCCESS;
  constructor(public response: any) {}
}

export class getEventDetailsById implements Action {
  readonly type: string = EventActionTypes.GET_EVENT_DETAILS_BY_ID;
  constructor(public Id: any) {}
}

export class getEventDetailByIdSuccess implements Action {
  readonly type: string = EventActionTypes.GET_EVENT_DETAILS_BY_ID_SUCCESS;
  constructor(public response: any) {}
}

export class updateMemberlist implements Action {
  readonly type: string = EventActionTypes.UPDATE_MEMBER_LIST;
  constructor(public payload: any, public Id: any) {}
}

export class updateMemberlistSuccess implements Action {
  readonly type: string = EventActionTypes.UPDATE_MEMBER_LIST_SUCCESS;
  constructor(public response: any) {}
}

export class deleteMemberlist implements Action {
  readonly type: string = EventActionTypes.DELETE_MEMBER_LIST;
  constructor(public Id: any) {}
}

export class deleteMemberlistSuccess implements Action {
  readonly type: string = EventActionTypes.DELETE_MEMBER_LIST_SUCCESS;
  constructor(public response: any) {}
}

export class updateEmailTemplate implements Action {
  readonly type: string = EventActionTypes.UPDATE_EMAIL_TEMPLATE;
  constructor(public payload: any, public Id: any) {}
}

export class updateEmailTemplateSuccess implements Action {
  readonly type: string = EventActionTypes.UPDATE_EMAIL_TEMPLATE_SUCCESS;
  constructor(public response: any) {}
}

export class submitforapproval implements Action {
  readonly type: string = EventActionTypes.SUBMIT_FOR_APPROVAL;
  constructor(public Id: any) {}
}

export class submitforapprovalSuccess implements Action {
  readonly type: string = EventActionTypes.SUBMIT_FOR_APPROVAL_SUCCESS;
  constructor(public response: any) {}
}

export class forApprovaList implements Action {
  readonly type: string = EventActionTypes.GET_FOR_APPROVAL_LIST;
  response: any;
  constructor(public payload: any) {}
}

export class forApprovaListSuccess implements Action {
  readonly type: string = EventActionTypes.GET_FOR_APPROVAL_LIST_SUCCESS;
  constructor(public response: any) {}
}

export class filterApprovalList implements Action {
  readonly type: string = EventActionTypes.GET_FOR_APPROVAL_LIST_FILTER;
  response: any;
  constructor(public payload: any) {}
}

export class forEndorsementList implements Action {
  readonly type: string = EventActionTypes.GET_FOR_ENDORSEMENT_LIST;
  response: any;
  constructor(public payload: any) {}
}

export class filterEndorsementList implements Action {
  readonly type: string = EventActionTypes.GET_FOR_ENDORSEMENT_LIST_FILTER;
  response: any;
  constructor(public payload: any) {}
}

export class forEndorsementListSuccess implements Action {
  readonly type: string = EventActionTypes.GET_FOR_ENDORSEMENT_LIST_SUCCESS;
  constructor(public response: any) {}
}

export class eventApproveReject implements Action {
  readonly type: string = EventActionTypes.EVENT_APPROVE_REJECT;
  response: any;
  constructor(public Id: any, public action_type: any) {}
}

export class eventApproveRejectSuccess implements Action {
  readonly type: string = EventActionTypes.EVENT_APPROVE_REJECT_SUCCESS;
  constructor(public response: any) {}
}

export class eventEndorseReject implements Action {
  readonly type: string = EventActionTypes.EVENT_ENDORSE_REJECT;
  response: any;
  constructor(public Id: any, public action_type: any) {}
}

export class eventEndorseRejectSuccess implements Action {
  readonly type: string = EventActionTypes.EVENT_ENDORSE_REJECT_SUCCESS;
  constructor(public response: any) {}
}

export class inviteesMailSent implements Action {
  readonly type: string = EventActionTypes.INVITEE_MAIL_SENT;
  constructor(public Id: any) {}
}

export class inviteesMailSentSuccess implements Action {
  readonly type: string = EventActionTypes.INVITEE_MAIL_SENT_SUCCESS;
  constructor(public response: any) {}
}

export class SubmitRsvp implements Action {
  readonly type: string = EventActionTypes.SUBMIT_RSVP;
  constructor(public payload: any, public Id: any) {}
}

export class SubmitRsvpSuccess implements Action {
  readonly type: string = EventActionTypes.SUBMIT_RSVP_SUCCESS;
  constructor(public response: any) {}
}

export class AddFeedbackTemplateField implements Action {
  readonly type: string = EventActionTypes.INSERT_NEW_FIELD_TO_FEEDBACK;
  constructor(public payload: any, public Id: any) {}
}

export class AddFeedbackTemplateFieldSuccess implements Action {
  readonly type: string = EventActionTypes.INSERT_NEW_FIELD_TO_FEEDBACK_SUCCESS;
  constructor(public response: any) {}
}

export class GetFeedbackTemplateField implements Action {
  readonly type: string = EventActionTypes.GET_FEEDBACK_TEMPLATE_FIELD;
  constructor(public Id: any) {}
}

export class getFeedbackTemplateFieldSuccess implements Action {
  readonly type: string = EventActionTypes.GET_FEEDBACK_TEMPLATE_FIELD_SUCCESS;
  constructor(public response: any) {}
}

export class updateFeedbackTemplate implements Action {
  readonly type: string = EventActionTypes.UPDATE_FEEDBACK_TEMPLATE;
  constructor(public payload: any, public Id: any) {}
}

export class updateFeedbackTemplateSuccess implements Action {
  readonly type: string = EventActionTypes.UPDATE_FEEDBACK_TEMPLATE_SUCCESS;
  constructor(public response: any) {}
}

export class updateEmailTemplateField implements Action {
  readonly type: string = EventActionTypes.UPDATE_EMAIL_TEMPLATE_FIELD;
  constructor(public payload: any, public Id: any) {}
}

export class updateEmailTemplateFieldSuccess implements Action {
  readonly type: string = EventActionTypes.UPDATE_EMAIL_TEMPLATE_FIELD_SUCCESS;
  constructor(public response: any) {}
}

export class submitRsvpfeedback implements Action {
  readonly type: string = EventActionTypes.SUBMIT_RSVP_FEEDBACK;
  constructor(public payload: any, public Id: any) {}
}

export class submitRsvpfeedbackSuccess implements Action {
  readonly type: string = EventActionTypes.SUBMIT_RSVP_FEEDBACK_SUCCESS;
  constructor(public response: any) {}
}

export class updateFeedbackTemplateField implements Action {
  readonly type: string = EventActionTypes.UPDATE_FEEDBACK_TEMPLATE_FIELD;
  constructor(public payload: any, public Id: any) {}
}

export class updateFeedbackTemplateFieldSuccess implements Action {
  readonly type: string =
    EventActionTypes.UPDATE_FEEDBACK_TEMPLATE_FIELD_SUCCESS;
  constructor(public response: any) {}
}

export class addComments implements Action {
  readonly type: string = EventActionTypes.ADD_COMMENTS;
  constructor(public payload: any) {}
}

export class addCommentsSuccess implements Action {
  readonly type: string = EventActionTypes.ADD_COMMENTS_SUCCESS;
  constructor(public response: any) {}
}
export class getEventComments implements Action {
  readonly type: string = EventActionTypes.GET_EVENTS_COMMENTS;
  constructor(public Id: any) {}
}

export class getEventCommentsSuccess implements Action {
  readonly type: string = EventActionTypes.GET_EVENTS_COMMENTS_SUCCESS;
  constructor(public response: any) {}
}

export class getEventFeedbackDetails implements Action {
  readonly type: string = EventActionTypes.GET_EVENTS_FEEDBACK;
  constructor(public Id: any) {}
}

export class getEventFeedbackDetailsSuccess implements Action {
  readonly type: string = EventActionTypes.GET_EVENTS_FEEDBACK_SUCCESS;
  constructor(public response: any) {}
}

export class submitEventReport implements Action {
  readonly type: string = EventActionTypes.SUBMIT_EVENT_REPORT;
  constructor(public payload: any, public Id: any) {}
}

export class submitEventReportSuccess implements Action {
  readonly type: string = EventActionTypes.SUBMIT_EVENT_REPORT_SUCCESS;
  constructor(public response: any) {}
}

export class submitApproverEndorser implements Action {
  readonly type: string = EventActionTypes.SUBMIT_APPROVER_ENDORSER_REPORT;
  constructor(public payload: any, public Id: any) {}
}

export class submitApproverEndorserSuccess implements Action {
  readonly type: string =
    EventActionTypes.SUBMIT_APPROVER_ENDORSER_REPORT_SUCCESS;
  constructor(public response: any) {}
}

export class addImageAndVideos implements Action {
  readonly type: string = EventActionTypes.ADD_VIDEO_IMAGE_REPORT;
  constructor(public payload: any, public Id: any) {}
}

export class addImageAndVideosSuccess implements Action {
  readonly type: string = EventActionTypes.ADD_VIDEO_IMAGE_REPORT_SUCCESS;
  constructor(public response: any) {}
}

export class removeImageAndVideos implements Action {
  readonly type: string = EventActionTypes.REMOVE_VIDEO_IMAGE_REPORT;
  constructor(public eventid: any, public fileid: any) {}
}

export class removeImageAndVideosSuccess implements Action {
  readonly type: string = EventActionTypes.REMOVE_VIDEO_IMAGE_REPORT_SUCCESS;
  constructor(public response: any) {}
}

export class getEventReportDetails implements Action {
  readonly type: string = EventActionTypes.GET_EVENTS_REPORT;
  constructor(public Id: any) {}
}

export class getEventReportDetailsSuccess implements Action {
  readonly type: string = EventActionTypes.GET_EVENTS_REPORT_SUCCESS;
  constructor(public response: any) {}
}

export class getEventReportApprovar implements Action {
  readonly type: string = EventActionTypes.GET_EVENTS_APPROVER;
  constructor(public payload: any) {}
}

export class getEventReportApprovarSuccess implements Action {
  readonly type: string = EventActionTypes.GET_EVENTS_APPROVER_SUCCESS;
  constructor(public response: any) {}
}

export class getEventReportEndorser implements Action {
  readonly type: string = EventActionTypes.GET_EVENTS_ENDORSER;
  constructor(public payload: any) {}
}

export class getEventReportEndorserSuccess implements Action {
  readonly type: string = EventActionTypes.GET_EVENTS_ENDORSER_SUCCESS;
  constructor(public response: any) {}
}

export class updateEventReport implements Action {
  readonly type: string = EventActionTypes.UPDATE_EVENT_REPORT;
  constructor(public payload: any, public Id: any) {}
}

export class updateEventReportSuccess implements Action {
  readonly type: string = EventActionTypes.UPDATE_EVENT_REPORT_SUCCESS;
  constructor(public response: any) {}
}

export class removeApproverAndEndorser implements Action {
  readonly type: string = EventActionTypes.DELETE_APPROVER_ENDORSER_IN_EVENT;
  constructor(public id: any, public payload: any) {}
}

export class removeApproverAndEndorserSuccess implements Action {
  readonly type: string =
    EventActionTypes.DELETE_APPROVER_ENDORSER_IN_EVENT_SUCCESS;
  constructor(public response: any) {}
}

export class rsvpList implements Action {
  readonly type: string = EventActionTypes.GET_RSVP_LIST;
  constructor(public payload: any, public id: any) {}
}

export class rsvpListSuccess implements Action {
  readonly type: string = EventActionTypes.GET_RSVP_LIST_SUCCESS;
  constructor(public response: any) {}
}
export class FeedbackList implements Action {
  readonly type: string = EventActionTypes.GET_FEEDBACK_LIST;
  constructor(public payload: any, public eventListType: any) {}
}

export class getFeedbackListBySuccess implements Action {
  readonly type: string = EventActionTypes.GET_FEEDBACK_LIST_SUCCESS;
  constructor(public response: any) {}
}

export class CalenderList implements Action {
  readonly type: string = EventActionTypes.GET_CALENDER_LIST;
  constructor(public payload: any, public eventListType: any) {}
}

export class getCalenderListBySuccess implements Action {
  readonly type: string = EventActionTypes.GET_CALENDER_LIST_SUCCESS;
  constructor(public response: any) {}
}

export class getEventReportList implements Action {
  readonly type: string = EventActionTypes.EVENT_REPORT_LIST;
  constructor(public payload: any, public eventListType: any) {}
}

export class getEventReportListBySuccess implements Action {
  readonly type: string = EventActionTypes.EVENT_REPORT_LIST_SUCCESS;
  constructor(public response: any) {}
}

export class eventReportApproveReject implements Action {
  readonly type: string = EventActionTypes.EVENT_REPORT_APPROVE_REJECT;
  response: any;
  constructor(public Id: any, public action_type: any) {}
}

export class eventReportApproveRejectSuccess implements Action {
  readonly type: string = EventActionTypes.EVENT_REPORT_APPROVE_REJECT_SUCCESS;
  constructor(public response: any) {}
}

export class eventReportEndorseReject implements Action {
  readonly type: string = EventActionTypes.EVENT_REPORT_ENDORSE_REJECT;
  response: any;
  constructor(public Id: any, public action_type: any) {}
}

export class eventReportEndorseRejectSuccess implements Action {
  readonly type: string = EventActionTypes.EVENT_REPORT_ENDORSE_REJECT_SUCCESS;
  constructor(public response: any) {}
}

export class submitforapprovalReport implements Action {
  readonly type: string = EventActionTypes.SUBMIT_FOR_APPROVAL_REPORT;
  constructor(public Id: any) {}
}

export class submitforapprovalReportSuccess implements Action {
  readonly type: string = EventActionTypes.SUBMIT_FOR_APPROVAL_REPORT_SUCCESS;
  constructor(public response: any) {}
}

export class getfeedbackList implements Action {
  readonly type: string = EventActionTypes.EVENT_FEEDBACK_LIST;
  constructor(public payload: any, public eventListType: any) {}
}

export class getfeedbackListSuccess implements Action {
  readonly type: string = EventActionTypes.EVENT_FEEDBACK_LIST_SUCCESS;
  constructor(public response: any) {}
}

export class cencelEvent implements Action {
  readonly type: string = EventActionTypes.CANCEL_EVENT;
  constructor(public Id: any) {}
}

export class gcencelEventSuccess implements Action {
  readonly type: string = EventActionTypes.CANCEL_EVENT_SUCCESS;
  constructor(public response: any) {}
}

export class loglist implements Action {
  readonly type: string = EventActionTypes.LOG_LIST;
  constructor(public Id: any) {}
}

export class loglistSuccess implements Action {
  readonly type: string = EventActionTypes.LOG_LIST_SUCCESS;
  constructor(public response: any) {}
}

export class claimReassignRequest implements Action {
  readonly type: string = EventActionTypes.CLAIM_REASSIGN_REQUEST_EVENT;
  constructor(public Id: any, public action_type: any) {}
}

export class claimReassignRequestSuccess implements Action {
  readonly type: string = EventActionTypes.CLAIM_REASSIGN_REQUEST_EVENT_SUCCESS;
  constructor(public response: any) {}
}
export class updateOwner implements Action {
  readonly type: string = EventActionTypes.UPDATE_OWNER_EVENT;
  constructor(public event_id: any, public Id: any) {}
}

export class updateOwnerSuccess implements Action {
  readonly type: string = EventActionTypes.UPDATE_OWNER_EVENT_SUCCESS;
  constructor(public response: any) {}
}

export class submitforscededule implements Action {
  readonly type: string = EventActionTypes.EVENT_SCHEDULED;
  constructor(public Id: any) {}
}

export class submitforscededuleSuccess implements Action {
  readonly type: string = EventActionTypes.EVENT_SCHEDULED_SUCCESS;
  constructor(public response: any) {}
}

export class ClearEventAction implements Action {
  readonly type: string = EventActionTypes.GET_EVENT_DETAILS_CLEAR;
  constructor() {}
}

export class ClearApproveRejectAction implements Action {
  readonly type: string = EventActionTypes.EVENT_APPROVE_REJECT_CLEAR;
  constructor() {}
}

export class ClearEndorseRejectAction implements Action {
  readonly type: string = EventActionTypes.EVENT_ENDORSE_REJECT_CLEAR;
  constructor() {}
}

export class ClearCommentsAction implements Action {
  readonly type: string = EventActionTypes.EVENT_COMMENTS_CLEAR;
  constructor() {}
}

export class ClearLogAction implements Action {
  readonly type: string = EventActionTypes.EVENT_LOG_CLEAR;
  constructor() {}
}

export class ClearcommentSubmitAction implements Action {
  readonly type: string = EventActionTypes.COMMENT_SUBMISSION_CLEAR;
  constructor() {}
}


export class donotattend implements Action {
  readonly type: string = EventActionTypes.DONT_WANT_ATTEND;
  constructor(public Id: any,public inviteeId: any) {}
}

export class donotattendSuccess implements Action {
  readonly type: string = EventActionTypes.DONT_WANT_ATTEND_SUCCESS;
  constructor(public response: any) {}
}


export class attendeelist implements Action {
  readonly type: string = EventActionTypes.ATTENDEE_LIST;
  constructor(public eventid:any,public status: any,public paylod: any,) {}
}

export class attendeelistSuccess implements Action {
  readonly type: string = EventActionTypes.ATTENDEE_LIST_SUCCESS;
  constructor(public response: any) {}
}


export class feedbackuserList implements Action {
  readonly type: string = EventActionTypes.FEEDBACK_USER_LIST;
  constructor(public Id: any) {}
}

export class feedbackuserListSuccess implements Action {
  readonly type: string = EventActionTypes.FEEDBACK_USER_LIST_SUCCESS;
  constructor(public response: any) {}
}

export class addfeedbackuser implements Action {
  readonly type: string = EventActionTypes.ADD_FEEDBACK_USER;
  constructor(public Id: any,public payload: any) {}
}

export class addfeedbackuserSuccess implements Action {
  readonly type: string = EventActionTypes.ADD_FEEDBACK_USER_SUCCESS;
  constructor(public response: any) {}
}

export class editfeedbackuser implements Action {
  readonly type: string = EventActionTypes.EDIT_FEEDBACK_USER;
  constructor(public Id: any,public payload: any,public userid: any) {}
}

export class editfeedbackuserSuccess implements Action {
  readonly type: string = EventActionTypes.EDIT_FEEDBACK_USER_SUCCESS;
  constructor(public response: any) {}
}

export class deletefeedbackuser implements Action {
  readonly type: string = EventActionTypes.DELETE_FEEDBACK_USER;
  constructor(public Id: any,public userid: any) {}
}

export class deletefeedbackuserSuccess implements Action {
  readonly type: string = EventActionTypes.DELETE_FEEDBACK_USER_SUCCESS;
  constructor(public response: any) {}
}

export class feedbackmailsent implements Action {
  readonly type: string = EventActionTypes.FEEDBACK_MAIL_SENT;
  constructor(public Id: any) {}
}

export class feedbackmailsentSuccess implements Action {
  readonly type: string = EventActionTypes.FEEDBACK_MAIL_SENT_SUCCESS;
  constructor(public response: any) {}
}

export class getQR implements Action {
  readonly type: string = EventActionTypes.GET_QR_OF_EVENT;
  constructor(public Id: any) {}
}

export class getQRSuccess implements Action {
  readonly type: string = EventActionTypes.GET_QR_OF_EVENT_SUCCESS;
  constructor(public response: any) {}
}


export class deleteApproveruser implements Action {
  readonly type: string = EventActionTypes.DELETE_APPROVAR_FROM_CR_EVENT;
  constructor(public Id: any,public userid: any) {}
}

export class deleteApproveruserSuccess implements Action {
  readonly type: string = EventActionTypes.DELETE_APPROVAR_FROM_CR_EVENT_SUCCESS;
  constructor(public response: any) {}
}

export class deleteEndorseruser implements Action {
  readonly type: string = EventActionTypes.DELETE_ENDORSER_FROM_CR_EVENT;
  constructor(public Id: any,public userid: any) {}
}

export class deleteEndorseruserSuccess implements Action {
  readonly type: string = EventActionTypes.DELETE_ENDORSER_FROM_CR_EVENT_SUCCESS;
  constructor(public response: any) {}
}



export class deleteRegistrationField implements Action {
  readonly type: string = EventActionTypes.DELETE_REGISTRAION_TEMPLATE_FIELD;
  constructor(public Id: any,public fieldId: any) {}
}

export class deleteRegistrationFieldSuccess implements Action {
  readonly type: string = EventActionTypes.DELETE_REGISTRAION_TEMPLATE_FIELD_SUCCESS;
  constructor(public response: any) {}
}

export class deleteFeedbackField implements Action {
  readonly type: string = EventActionTypes.DELETE_FEEDBACK_TEMPLATE_FIELD;
  constructor(public Id: any,public fieldId: any) {}
}

export class deleteFeedbackFieldSuccess implements Action {
  readonly type: string = EventActionTypes.DELETE_FEEDBACK_TEMPLATE_FIELD_SUCCESS;
  constructor(public response: any) {}
}

export class markasComplete implements Action {
  readonly type: string = EventActionTypes.MARK_AS_A_COMPLETE;
  constructor(public Id: any) {}
}

export class markasCompleteSuccess implements Action {
  readonly type: string = EventActionTypes.MARK_AS_A_COMPLETE_SUCCESS;
  constructor(public response: any) {}
}


export class deleteApproverReportuser implements Action {
  readonly type: string = EventActionTypes.DELETE_APPROVAR_FROM_CR_EVENT_REPORT;
  constructor(public Id: any,public userid: any) {}
}

export class deleteApproverReportuserSuccess implements Action {
  readonly type: string = EventActionTypes.DELETE_APPROVAR_FROM_CR_EVENT_REPORT_SUCCESS;
  constructor(public response: any) {}
}

export class deleteEndorserReportuser implements Action {
  readonly type: string = EventActionTypes.DELETE_ENDORSER_FROM_CR_EVENT_REPORT;
  constructor(public Id: any,public userid: any) {}
}

export class deleteEndorserReportuserSuccess implements Action {
  readonly type: string = EventActionTypes.DELETE_ENDORSER_FROM_CR_EVENT_REPORT_SUCCESS;
  constructor(public response: any) {}
}






