import { Action } from '@ngrx/store';
import { GetPrivateMembersGroupSuccess, PrivateMemberGroupActionTypes, GetPrivateMemberGroupDetailsSuccess, AddPrivateMemberToGroupSuccess, CreatePrivateMemberGroupSuccess } from './private-member.action';

export interface PrivateMemberGroup {
    privateMemberGroups: Array<any>,
    groupDetails: any,
    createdGroup: any,
    userAddedToPrivateGroup: any
}

const intiState = {
    privateMemberGroups: [],
    groupDetails: {},
    createdGroup: {},
    userAddedToPrivateGroup: {}
};


export function reducer(state: PrivateMemberGroup = intiState, action: Action): PrivateMemberGroup {
    switch (action.type) {
        case PrivateMemberGroupActionTypes.GET_PRIVATE_MEMBER_GROUPS_SUCCESS:
            return Object.assign({}, state, { privateMemberGroups: (action as GetPrivateMembersGroupSuccess).privateMembers });
        case PrivateMemberGroupActionTypes.GET_PRIVATE_MEMBER_GROUP_DETAILS_SUCCESS:
            return Object.assign({}, state, { groupDetails: (action as GetPrivateMemberGroupDetailsSuccess).groupDetails });
        case PrivateMemberGroupActionTypes.ADD_PRIVATE_MEMBER_TO_GROUP_SUCCESS:
            return Object.assign({}, state, { userAddedToPrivateGroup: (action as AddPrivateMemberToGroupSuccess).response });
        case PrivateMemberGroupActionTypes.CREATE_PRIVATE_MEMBER_GROUP_SUCCESS:
            return Object.assign({}, state, { createdGroup: (action as CreatePrivateMemberGroupSuccess).response });
        case PrivateMemberGroupActionTypes.Clear_DATA_FOR_USER_ADD:
            return Object.assign({}, state, { createdGroup: {} })
            case PrivateMemberGroupActionTypes.CLEAR_PRIVATE_MEMBERS_IN_PRIVATE_GROUP:
                return Object.assign({}, state, { userAddedToPrivateGroup: {}})
        default:
            return state;
    }
}


