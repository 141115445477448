import { CanActivate, Router } from "@angular/router";
import { AppState } from "../app.reducer";
import { Store } from "@ngrx/store";
import { takeUntil, take } from "rxjs/operators";
import { Subject } from "rxjs";
import { OnDestroy, Injectable } from "@angular/core";

@Injectable()
export class LoginGuard implements CanActivate, OnDestroy {

    stopper$: Subject<any> = new Subject();

    constructor(public store: Store<AppState>, public router: Router) { }
    canActivate(
        route: import("@angular/router").ActivatedRouteSnapshot,
        state: import("@angular/router").RouterStateSnapshot): boolean | import("@angular/router").UrlTree | import("rxjs").Observable<boolean | import("@angular/router").UrlTree> | Promise<boolean | import("@angular/router").UrlTree> {
        this.store.select(state => state).pipe(take(1)).subscribe((state) => {
            if (state.auth.isLoggedIn) {
                this.router.navigate(['home', 'resources', 'me']);
                return false;
            }
        });

        return true;

    }
    ngOnDestroy() {
        this.stopper$.next();
        this.stopper$.complete();
    }

}