import { Action } from '@ngrx/store';
import {
    AuthActionTypes,
    LoginSuccess,
    GetUserDetailsSuccess,
    GetUserRolesSuccess,
    GetUserCapabilitesSuccess,
    ForgotPasswordSuccess,
    VerifyOtpSuccess,
    BackToLogin,
    GetIpAddressSuccess,
    RecaptchaPermissionSuccess,
    SendOtpToMailSuccess,
    VerifyOtpSuccessForAdmin,
    GetRegisterUserSuccessAction
} from './auth.actions';
export interface AuthState {
    isLoggedIn: boolean;
    accessToken: any;
    user: any;
    // verifyOtpRes: any;
    configurations: any;
    otp: any;
    registrationSuccess: any;
}

const initState = {
    isLoggedIn: false,
    accessToken: null,
    user: {},
    configurations: {},
    // verifyOtpRes: {}
    otp: {},
    registrationSuccess:{},
};

export function reducer(state: AuthState = initState, action: Action): AuthState {
    switch (action.type) {
        case AuthActionTypes.LOGIN_SUCCESS:
            return Object.assign({}, state, {
                ...state,
                isLoggedIn: true,
                accessToken: (action as LoginSuccess).token
            });
        case AuthActionTypes.LOGOUT_SUCCESS:
            return Object.assign({}, state, {
                isLoggedIn: false,
                accessToken: null,
                user: {},
                configurations: {},
                // verifyOtpRes: {}
                otp: {},
                registrationSuccess: {},
            });
        case AuthActionTypes.GET_USER_DETAILS_SUCCESS:
            return Object.assign({}, state, {
                user: Object.assign({}, { ...state.user }, {
                    ...state.user,
                    details: (action as GetUserDetailsSuccess).userDetails
                })
            });
        case AuthActionTypes.GET_USER_ROLES_SUCCESS:
            return Object.assign({}, state, {
                user: Object.assign({}, { ...state.user }, {
                    ...state.user,
                    role: (action as GetUserRolesSuccess).roles
                })
            });
        case AuthActionTypes.GET_USER_CAPABILTITES_SUCCESS:
            return Object.assign({}, state, {
                user: Object.assign({}, { ...state.user }, {
                    ...state.user,
                    capabilities: (action as GetUserCapabilitesSuccess).capabilities
                })
            });
        case AuthActionTypes.FORGOT_PASSWORD_SUCCESS:
            return Object.assign({}, state, {
                user: {
                    ...state.user,
                    forgotPassword: (action as ForgotPasswordSuccess).response
                }
            })
        case AuthActionTypes.CLEAR_ACTION:

            return Object.assign({}, state, {
                user: {
                    ...state.user,
                    forgotPassword: {},
                    verifyOtpRes: {},
                    backToLogin: false,
                    capabilities: []
                },
                registrationSuccess: {}
            })
        case AuthActionTypes.VERIFY_OTP_SUCCESS:
            return Object.assign({}, state, {
                user: {
                    ...state.user,
                    verifyOtpRes: (action as VerifyOtpSuccess).response
                }
            })

        case AuthActionTypes.BACK_TO_LOGIN:
            return Object.assign({}, state, {
                user: {
                    ...state.user,
                    backToLogin: true
                }
            })

        case AuthActionTypes.GET_IP_ADDRESS_SUCCESS:
            return Object.assign({}, state, {
                user: {
                    ...state.user,
                    ipAddress: (action as GetIpAddressSuccess).response
                }
            })


        case AuthActionTypes.RECAPTCHA_PERMISSION_SUCCESS:
            return Object.assign({}, state, {
                configurations :     (action as RecaptchaPermissionSuccess).response
                
            })

        case AuthActionTypes.GET_USER_DATA_SUCCESS:
        return Object.assign({}, state, { registrationSuccess: (action as GetRegisterUserSuccessAction).res });

        case AuthActionTypes.VERIFY_OTP_SUCCESS_:
            return Object.assign({}, state, {
                otp: (action as VerifyOtpSuccessForAdmin).response
            });

        case AuthActionTypes.CLEAR_ACTION_OTHERS:
            return Object.assign({}, state, {
                otp: {}
            })

        default:
            return state;
    }
}
