import { Component, Inject } from "@angular/core";
import { MatDialogRef, MAT_DIALOG_DATA, MatDialog } from "@angular/material";
import { Router } from '@angular/router';


@Component({
    selector: 'registration-success',
    styleUrls:['./registration-successful.scss'],
    templateUrl: './registration-successful.html'

})
export class RegistrationSuccesful {
    constructor(
        private router: Router,
        private dialogRef: MatDialogRef<any>,
        private dialog: MatDialog, 
        @Inject(MAT_DIALOG_DATA) public data: any
    ) {
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
        
    }
    loginClicked() {
        this.router.navigate(['user', 'login']);
        this.dialogRef.close(true);
    }
}