
import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';
@Directive({
  selector: '[appNumbersOnly]'
})
export class NumbersOnlyDirective {

  constructor(private el: ElementRef,private control : NgControl) {}
 
  @HostListener('input', ['$event']) onInputChange(event) {
     const initalValue = this.el.nativeElement.value;
 
     this.el.nativeElement.value = initalValue.replace(/[^0-9]/g, '');
     if (initalValue !== this.el.nativeElement.value) {
       event.stopPropagation();
     }
  }

  @HostListener('paste', ['$event']) onPaste(event: ClipboardEvent) {
    event.preventDefault();
    const pastedInput: string = event.clipboardData.getData('text');
    const sanitizedInput = this.sanitizeInput(pastedInput);
    this.el.nativeElement.value = sanitizedInput;
    this.control.control.setValue(this.el.nativeElement.value);
 }

 private sanitizeInput(input: string): string {
    return input.replace(/[^0-9]/g, '');
 }
 }
