import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { AppState } from 'src/app/app.reducer';
import { map } from 'rxjs/operators'
import { Store } from '@ngrx/store';
import { Router, ActivatedRoute } from '@angular/router';
import { API } from '../../../../services/Api.service';
import { ChangeEmail } from '../store/profile.actions'
import { NgxPermissionsService } from 'ngx-permissions';
import { OtpScreenComponent } from 'src/app/dialogs/otp-screen/otp-screen.component';
import { UserChangeEmail, ClearAction } from '../../user-management/store/user-managment.actions'

@Component({
    selector: 'app-change-email',
    templateUrl: './change-email.component.html',
    styleUrls: ['./change-email.component.scss']
})
export class ChangeEmailComponent implements OnInit {
    email: any = {};
    changeEmailForm;
    showPassword: boolean;
    showCurrentPasswordField: boolean = false;
    endPoint: string = '';
    otpScreenOpened: boolean = false; // this flag is enabled to prevent opening new otp pop's on top of the existing one on click of resend otp button

    constructor(
        private dialogRef: MatDialogRef<any>,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private store: Store<AppState>,
        private api: API,
        private router: Router,
        private ngxPermissionService: NgxPermissionsService,
        private fb: FormBuilder) {
        this.dialogRef.disableClose = true;
        this.email = data.email;
        this.showPassword = false;

        this.endPoint = this.router.url.split('/').pop();
    }


    ngOnInit() {
        this.store.dispatch(new ClearAction());
        this.showCurrentPasswordField = (['edit'].includes(this.endPoint)) ? true : false;

        this.changeEmailForm = this.fb.group({
            email: [
                '',
                Validators.compose([
                    Validators.required,
                    Validators.pattern(/^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+$/)
                ])
            ],
            password: [
                '',
                Validators.compose([
                    Validators.required,
                    // Validators.pattern(
                    //     "(?=.*?[#?!@$%^&*-]).{6,}"
                    // )
                ])
            ]
        });
    }

    changeEmail() {
        if (!this.showCurrentPasswordField) {
            this.changeEmailForm.removeControl('password');
        }

        if (this.changeEmailForm.invalid) {
            return false;
        }

        const payload = {
            email: this.changeEmailForm.value.email,
            password: this.changeEmailForm.value.password
        }

        if (this.showCurrentPasswordField) {
            this.store.dispatch(new ChangeEmail(this.data.registeredCountryCode, this.data.registeredPhone, payload));
            this.store.select(state => state.profile.changeEmailRes).subscribe(response => {
                if (Object.keys(response).length) {
                    this.dialogRef.close();
                }
            })
        }
        else {
            console.log(this.endPoint)
            this.store.dispatch(new UserChangeEmail({ email: this.changeEmailForm.value.email }, this.endPoint));
            this.store.select(state => state.userManagment.userChangeEmailRes).subscribe(response => {
                if (Object.keys(response).length) {
                    if (!this.otpScreenOpened) {
                        this.dialogRef.close();
                        this.otpScreenOpened = true;
                        const dialogRef = this.dialog.open(OtpScreenComponent, {
                            width: '700px',
                            height: '500px',
                            panelClass: 'cmp-modal',
                            data: {
                                alertTitle: "Verify OTP",
                                alertText: `OTP has been sent to the registered phone number and email address`,
                                id: this.endPoint,
                                byAdmin: true,
                                email: this.changeEmailForm.value.email,
                                type: "changeEmail"
                            }
                        })
                        dialogRef.afterClosed().subscribe((response: any) => {
                            console.log(response);
                            this.otpScreenOpened = false;
                        })
                    }
                }
            })


        }

    }
    showPasswordFunction() {
        this.showPassword = !this.showPassword;
    }

    onCloseDialog(): void {
        this.dialogRef.close();
    }
}
