import { Component, Inject, ElementRef, ViewChild, Output, EventEmitter, OnDestroy } from "@angular/core";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatAutocomplete, MatChipInputEvent, MatAutocompleteSelectedEvent } from "@angular/material";
import { Store } from "@ngrx/store";
import { FormControl, FormGroup, FormBuilder, FormArray, Validators } from "@angular/forms";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { Observable, Subject, pipe } from "rxjs";
import { debounceTime, distinctUntilChanged, tap, map, takeUntil } from "rxjs/operators";
import { GetAllTagsListStartAction, AddTagStartAction, SearchTaskIdStartAction, ClearTagAction } from "src/app/features/home/tasks/store/tasks.actions";
import { GetShareSuggestions, UpdateDocument, UPDATEDOCUMENTSTARTACTION, GetDocumentById } from "src/app/features/home/resources/_store/resources.actions";
import { CheckViewAccessGorDocStart } from "src/app/features/home/user-messages/_store/messages.actions";
import { NotificationModalComponent } from "src/app/features/common-components/notification-modal/notification-modal.component";
import { LoaderShow } from 'src/app/features/loader/store/loader.actions';
import { GetTaskDetails } from '../../features/home/tasks/store/tasks.actions'
import { EditContract } from "src/app/features/contract-management/store/contract-management.actions";



@Component({
  selector: 'tags-dialog',
  templateUrl: './tags-dialog.component.html',
  styleUrls: ['./tags-dialog.component.scss']
})

export class TagsDialogComponent implements OnDestroy {

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  // fruits: string[] = ['Lemon'];
  allTags: string[] = [];
  selectedItems: string[] = [];
  selectedLinkIds = [];
  formData = new FormData();
  stopper$: Subject<any> = new Subject();


  @ViewChild('tagInput', { static: false }) tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  form: FormGroup;
  responseTags$: Observable<any>;
  selectedTagIds: any = [];
  selectedTagWithId: any = [];
  addTag: boolean = false;
  specialCharregex;
  multiSpaceRegex;
  error = false;
  taskDetails: any = {};
  searchTasks: boolean = false;
  responseTags: any;
  regExError: boolean = false
  // allSelectedApproverIds: Array<any> = [];
  // allSelectedEndorserIds: Array<any> = [];
  // allSelectedApproverobjs: Array<any> = [];
  // allSelectedEndorserObjs: Array<any> = [];
  appr = this.fb.group({
    'approverField': [''],
  });
  endr = this.fb.group({
    'endorserField': [''],
  })
  selectedIds: Array<any> = [];
  dropDownList: Array<any> = [];
  selectedApprovers = [];
  selectedEndorsers = [];
  selectedApproversIds: any = [];
  selectedEndorsersIds: any = [];
  submitButtonClicked: boolean = false;
  searchString;
  contractDetails: any;


  constructor(private dialogRef: MatDialogRef<any>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private _store: Store<any>,
    private fb: FormBuilder) {
    this.dialogRef.disableClose = true;
    this.taskDetails = data.allDetails;
    if (data.type == 'tags') {
      if (data.module == 'task') {
        this._store.dispatch(new GetTaskDetails(this.taskDetails._id));
        this._store.select(state => state.tasks.taskDetail).subscribe(taskDetails => {


          // this.DocumentTagsList = taskDetails.tags.map(tag => tag)
          this.selectedItems = taskDetails.tags.map((tagData) => tagData.tag);
          this.selectedTagIds = taskDetails.tags.map((tagData) => tagData._id);

        })
      }
      // if (data.allDetails && data.allDetails.tags.length) {
      //   this.selectedItems = data.allDetails.tags.map((tagData) => tagData.tag);
      //   this.selectedTagIds = data.allDetails.tags.map((tagData) => tagData._id);
      // }
      if (data.selectedItems != undefined) {
        // this.selectedItems = data.selectedItems.map((tag) => tag.tag);
      }
    }
    // else if (data.type == 'approvers') {
    //   if (data.allDetails != undefined) {
    //     console.log(data.allDetails, "allDetails");
    //     this.selectedItems = [];
    //     this.selectedItems = data.allDetails.approvers.filter((obj) => {
    //       let apprIndex = this.allSelectedApproverIds.findIndex((id) => id === obj._id);
    //       if (apprIndex === -1) {
    //         this.allSelectedApproverobjs.push({ _id: obj._id, approvedStatus: obj.approved || false, name: ((obj.firstName || '') + ' '+(obj.middleName || '') +' '+ (obj.lastName || ''))  })
    //       }
    //       return obj;
    //     }).map((obj) =>((obj.firstName || '') + ' '+(obj.middleName || '') +' '+ (obj.lastName || '')))
    //   }
    //   console.log(this.selectedItems, "selected items");

    // }
    // else if (data.type == 'endorsers') {
    //   if (data.allDetails != undefined) {
    //     this.selectedItems = [];
    //     this.selectedItems = data.allDetails.endorsers.filter((obj) => {
    //       let endrIndex = this.allSelectedEndorserIds.findIndex((id) => id === obj._id);
    //       if (endrIndex === -1) {
    //         this.allSelectedEndorserObjs.push({ _id: obj._id, aprovedStatus: obj.approved || false , name : ((obj.firstName || '') + ' '+(obj.middleName || '') +' '+ (obj.lastName || ''))})
    //       }
    //       return obj;
    //     }).map((obj) => ((obj.firstName || '') + ' '+ (obj.middleName || '') + ' '+ (obj.lastName || ''))
    //     )
    //   }
    // }


    this.form = this.fb.group({

      approverArray: this.fb.array([this.appr]),
      supporterArray: this.fb.array([this.endr]),

    });
    if (data.type === 'approvers') {
      let selectedApprovers = this.taskDetails.approvers
      let allApproversName = selectedApprovers.map((eachApprover) => {
        return ((eachApprover.firstName || '') + ' ' + (eachApprover.secondName || '') + +' ' + (eachApprover.lastName || ''))
      })
      let allApproverIds = selectedApprovers.map((eachApprover) => eachApprover._id);
      if (allApproverIds.length) {
        const approverControlArray = this.form.controls.approverArray as FormArray;
        // allApproverIds.forEach((id, i) => {
        //   approverControlArray.at(i).patchValue({
        //     'approverField' :allApproversName[i] })
        // })

      }

    }
    else if (data.type === 'endorsers') {

    }
    else if (data.type === 'contract') {
      this._store.select(state => state.contractManagement.contracDetail).subscribe(data => {
        if(data){
          this.contractDetails=data
        }
        if (data && data.tags.length) {
          this.selectedItems = data.tags.map((tagData) => tagData.tag);
          this.selectedTagIds = data.tags.map((tagData) => tagData._id);
        }
      })
    }
    else if (data.type === 'documentHub') {

      this._store.dispatch(new GetDocumentById(data.docID, data.allCMPDoc));
      this._store.select(state => state.resources.docDetail).subscribe(docData => {
        if (docData && docData.tags.length) {
          this.selectedItems = docData.tags.map((tagData) => tagData.tag);
          this.selectedTagIds = docData.tags.map((tagData) => tagData._id);
        }
      })
    }

  }

  ngOnInit() {
    let loginId = JSON.parse(localStorage.getItem('auth')).user.details._id;
    this._store.select(state => state.tasks.tagsList).subscribe((taglist) => {

      if (this.searchString && (!this.error && !this.regExError) && (this.searchString != null)) {
        taglist.unshift({ tag: ` + Create tag  for '${this.searchString}'` });
      }

      this.responseTags = taglist.filter((tagData) => !this.selectedTagIds.includes(tagData._id));
      if (taglist.length) {
        this.addTag = false;
      }
      else {
        this.addTag = true;
      }

      // console.log(state.tasks.tasksList, "list")
    })

    this.responseTags$ = this._store.select(state => {
      if (this.searchTasks) {

        return state.tasks.tasksList
      }
    })
    this._store.select(state => state.tasks.tasksList).subscribe((list) => {
    })
    this.tagCtrl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(((val) => {


      this.searchString = val;

      this.error = false;
      this.regExError = false;

      if (val != null) {

        (typeof (val) === 'number') ? val.toString() : val;
        let value = val.trim();
        if (value.length) {
          if (this.data.type === 'tags' || this.data.type === 'documentHub' || this.data.type === 'contract') {

            this.specialCharregex = new RegExp(/^[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/);
            this.multiSpaceRegex = new RegExp(/[ ]{2,}/);

            if (this.specialCharregex.test(value)) {
              let value = val.trim();
              this._store.dispatch(new GetAllTagsListStartAction({ 'search': value }))
            }
            else {
              this.responseTags = []
              this.error = false;
              this.regExError = true;
            }

            if (!this.multiSpaceRegex.test(value)) {
              let value = val.trim();
              this._store.dispatch(new GetAllTagsListStartAction({ 'search': value }))
            }
            else {
              this.responseTags = []
              this.regExError = false;
              this.error = true;
            }
          }

          else if (this.data.type === 'linkedTasks') {
            this._store.dispatch(new SearchTaskIdStartAction(this.data.allDetails._id, val));
            this.searchTasks = true;

          }
          else if (this.data.type === 'approvers') {
            this._store.dispatch(new GetShareSuggestions(val.trim(), ''));

          }
          else if (this.data.type === 'endorsers') {
            this._store.dispatch(new GetShareSuggestions(val.trim(), ''));


          }
        }
        else {
          this.responseTags = []
        }
      }
    }))


    this._store.select(state => state.tasks.addedTag).subscribe((response) => {
      //check for response when added new tag
      if (Object.keys(response).length) {
        let data = response;
        let index = this.selectedItems.findIndex(tag => tag === response.tag);
        if (index === -1) {
          this.selectedItems.push(response.tag);
          this.selectedTagIds.push(data._id);
          this.selectedTagWithId.push({ value: response.tag, _id: data._id });
          this._store.dispatch(new ClearTagAction());
          this.responseTags = []
        }
      }
    })
    // this._store.select(state => state.resources.shareSuggestions).subscribe((list) => {
    //   if(this.data.type === "approvers") {

    //     this.dropDownList = list.filter((obj) => !this.allSelectedApproverobjs.map((obj) => obj._id).includes(obj._id)).filter((obj) => {
    //      return  !this.data.allDetails.endorsers.map((endObj) =>  endObj._id).includes(obj._id)
    //     })
    //   }
    //   else if (this.data.type === 'endorsers'){
    //     this.dropDownList = list.filter((obj) => !this.allSelectedEndorserObjs.map((obj) => obj._id).includes(obj._id)).filter((obj) => {

    //       return !this.data.allDetails.approvers.map((apprObj) => apprObj._id).includes(obj._id)
    //     })

    //   }
    // })
    this.appr.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((val) => {
      // console.log(val, "value")
      if (val.approverField && val.approverField.length) {
        this._store.dispatch(new GetShareSuggestions(val.approverField.trim(), 'user'));

      }

    })
    this.endr.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((val) => {
      if (val && val.endorserField.length) {

        this._store.dispatch(new GetShareSuggestions(val.endorserField.trim(), 'user'));

      }

    });
    this._store.select(state => state.resources.shareSuggestions).subscribe((list) => {
      this.dropDownList = list;
      // this.dropDownList = list.filter((obj) => !this.selectedIds.includes(obj._id)).filter((obj) => obj._id !== loginId)
    });
    this.responseTags = [];
    // selector checking view access is written here
    this._store.select(state => state.messages.viewAccessForDoc).pipe(takeUntil(this.stopper$)).subscribe((resp) => {
      if (resp && Object.keys(resp) && this.submitButtonClicked) {
        const { documents, noAccessDocuments, ...others } = resp;
        let users = Object.keys(others).some(key => others[key].length);
        let noAccess = (noAccessDocuments || []).length;
        if (users || noAccess) {
          const dialogRef = this.dialog.open(NotificationModalComponent, {
            width: '700px',
            hasBackdrop: true,
            panelClass: 'cmp-modal',
            data: {
              type: 'taskViewAccess',
              response: resp,
            }
          })
          dialogRef.afterClosed().subscribe((response) => {
            if (this.data.type === 'approvers') {

              this.dialogRef.close(this.selectedApprovers)
            }
            else if (this.data.type === 'endorsers') {
              // this.dialogRef.close(this.selectedEndorsers)
            }
          })
        }



        if (!users && noAccess === 0) {

          if (this.data.type === 'approvers') {

            this.dialogRef.close(this.selectedApprovers)
          }
          else if (this.data.type === 'endorsers') {
            this.dialogRef.close(this.selectedEndorsers)
          }
        }

      }

    })
  }


  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value.trim();

    this.addTagSubmit(value);

    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  addTagSubmit(value) {
    if (!this.matAutocomplete.isOpen) {
      if (value || '') {
        if (this.data.type === "tags") {
          this.specialCharregex = new RegExp(/^[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/);
          this.multiSpaceRegex = new RegExp(/[ ]{2,}/);

          if (this.specialCharregex.test(value) && !this.multiSpaceRegex.test(value)) {

            let index = this.selectedItems.findIndex((tag) => tag === value.trim());
            if (index === -1) {
              if (this.addTag) {
                this._store.dispatch(new LoaderShow());
                this._store.dispatch(new AddTagStartAction({ 'tag': value }));
                this.responseTags = []
              }
              else {
                this._store.dispatch(new LoaderShow());
                this._store.dispatch(new AddTagStartAction({ 'tag': value }));
                this.responseTags = []
              }
            }
          }
        }
        else if (this.data.type === "contract") {
          this.specialCharregex = new RegExp(/^[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/);
          this.multiSpaceRegex = new RegExp(/[ ]{2,}/);

          if (this.specialCharregex.test(value) && !this.multiSpaceRegex.test(value)) {

            let index = this.selectedItems.findIndex((tag) => tag === value.trim());
            if (index === -1) {
              if (this.addTag) {
                this._store.dispatch(new LoaderShow());
                this._store.dispatch(new AddTagStartAction({ 'tag': value }));
                this.responseTags = []
              }
              else {
                this._store.dispatch(new LoaderShow());
                this._store.dispatch(new AddTagStartAction({ 'tag': value }));
                this.responseTags = []
              }
            }
          }
        }
        else if (this.data.type === 'documentHub') {
          this.specialCharregex = new RegExp(/^[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/);
          this.multiSpaceRegex = new RegExp(/[ ]{2,}/);
          if (this.specialCharregex.test(value) && !this.multiSpaceRegex.test(value)) {

            let index = this.selectedItems.findIndex((tag) => tag === value.trim());

            if (index === -1) {
              if (this.addTag) {
                this._store.dispatch(new LoaderShow());
                this._store.dispatch(new AddTagStartAction({ 'tag': value }));
                this.responseTags = []

              }
              else {
                this._store.dispatch(new AddTagStartAction({ 'tag': value }));
                this.responseTags = []

              }

            }
          }

        }
        else if (this.data.type === "linkedTasks") {
          //do nothing
        }
        else if (this.data.type === 'approvers') {
          //do nothing
        }
        else if (this.data.type === 'endorsers') {

        }
      }
      // Reset the input value

    }
    else {
      if ((this.data.type === 'tags') || (this.data.type === 'documentHub') || (this.data.type === 'contract')) {
        let index = this.responseTags.findIndex((tagData) => (tagData.tag === value))

        if (index === -1) {
          this._store.dispatch(new LoaderShow());
          this._store.dispatch(new AddTagStartAction({ 'tag': value }));
          this.responseTags = []

        }
        else {
          let index = this.selectedItems.findIndex((tag) => tag === value.trim());
          let tagData: any = this.responseTags.filter((tag) => tag.tag === value);
          if (index === -1) {

            this.selectedItems.push(tagData[0].tag);
            this.selectedTagIds.push(tagData[0]._id);
            this.selectedTagWithId.push({ value: tagData[0].tag, _id: tagData[0]._id });
            // this.selectedTagsEvent.emit(this.selectedTags);
            // this.selectedTagIdsEvent.emit(this.selectedTagIds);
            this.responseTags = []
          }

        }
      }
    }
  }




  remove(item: string): void {
    if ((this.data.type == "tags") || (this.data.type === 'documentHub') || (this.data.type === 'contract')) {
      const index = this.selectedItems.indexOf(item);
      this.selectedTagWithId.forEach((obj, i) => {
        if (obj.value === item) {
          this.selectedTagWithId.splice(i, 1);
        }
      });

      if (index >= 0) {
        this.selectedTagIds.splice(index, 1);
        this.selectedItems.splice(index, 1);
      }
    }
    else if (this.data.type === 'linkedTasks') {
      const index = this.selectedItems.indexOf(item);
      this.selectedItems.forEach((taskId, i) => {
        if (taskId === item) {
          this.selectedItems.splice(i, 1);
        }
      })

    }
    // else if (this.data.type === 'approvers') {
    //   this.allSelectedApproverobjs.forEach((obj,i) => {
    //     if(!obj.approved) {
    //       if(obj.name === item) {
    //         this.selectedItems.splice(i,1)
    //       }
    //     }
    //   })
    //  this.allSelectedApproverobjs = this.allSelectedApproverobjs.filter((obj) => this.selectedItems.includes(obj.name))  
    // }
    // else if(this.data.type === 'endorsers') {
    //   console.log(this.allSelectedEndorserObjs, "endrs objs");
    //   console.log(this.selectedItems, "items")
    //   this.allSelectedEndorserObjs.forEach((obj,i) => {
    //     if(!obj.approved) {
    //       if(obj.name === item) {
    //         this.selectedItems.splice(i,1)
    //       }
    //     }
    //   })
    //  this.allSelectedEndorserObjs = this.allSelectedEndorserObjs.filter((obj) => this.selectedItems.includes(obj.name))  
    // }
  }
  displayFn(selectedValue) {
    if (selectedValue && Object.keys(selectedValue).length) {
      // console.log(this.selectedApproversIds, "ids")
      return selectedValue.taskId;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if ((this.data.type === "tags") || (this.data.type === 'documentHub') || (this.data.type === 'contract')) {
      let index = this.selectedItems.findIndex(tag => tag === event.option.value.tag);
      if (index === -1) {
        if (!event.option.value._id) {
          this.addTagSubmit(this.searchString);
        } else {
          this.selectedItems.push(event.option.value.tag);
          this.selectedTagIds.push(event.option.value._id);
          this.selectedTagWithId.push({ value: event.option.value.tag, _id: event.option.value._id });
        }
      }
    }
    else if (this.data.type === 'linkedTasks') {
      let index = this.selectedItems.findIndex(taskId => taskId === event.option.value.taskId);
      // let LinkedIdIndex =this.selectedLinkIds.findIndex(linkedId => linkedId === event.option.value._id)
      if (index === -1) {
        this.selectedItems.push(event.option.value.taskId);
        this.selectedLinkIds.push(event.option.value._id);

      }
    }
    else if (this.data.type === 'approvers') {
      //   let approverIndex = this.allSelectedApproverobjs.map((obj) => obj._id).findIndex((id) => id === event.option.value._id);
      //   if (approverIndex === -1) {
      //     this.allSelectedApproverobjs.push({_id: event.option.value._id, name: ((event.option.value.firstName || '') + ' '+(event.option.value.middleName || '') + ' '+ (event.option.value.lastName || '')), approvedStatus: event.option.value.approvedStatus || false });
      //     this.selectedItems.push(((event.option.value.firstName || '') + ' '+(event.option.value.middleName || '') + ' '+ (event.option.value.lastName || '')))
      //   }
      this.dropDownList = [];
      // let Index = this.selectedIds.findIndex((id) => id === event.option.value._id);
      // if (Index === -1) {
      // }
      this.selectedIds.push(event.option.value._id);
      let approverArray = this.form.controls.supporterArray as FormArray
      if (approverArray) {
        let counts = [];
        for (let i = 0; i < approverArray.length; i++) {
          if (counts[approverArray.value[i].endorserField] === undefined) {
            counts[approverArray.value[i].endorserField] = 1
          }
          else {
            // approverArray.controls[i].value.duplicate = true;

          }
        }

      }


      this.selectedApprovers.push(event.option.value._id);
      // let index = this.selectedApprovers.findIndex((id) => id === event.option.value._id)
      // if (index === -1) {

      //   // this.selectedApproversIds.push(event.option.value)
      //   // this.selectedIds.push(event.option.value._id);
      // }
      // else {
      //   console.log("write error case here")
      // }
      return
    }
    else if (this.data.type === 'endorsers') {
      // this.dropDownList =[];
      // let endorserIndex =this.allSelectedEndorserObjs.map((obj) => obj._id).findIndex((id) => id === event.option.value._id);
      // if(endorserIndex === -1) {
      //   this.allSelectedEndorserObjs.push({_id: event.option.value._id, name: ((event.option.value.firstName || '') + ' '+(event.option.value.middleName || '') + ' '+ (event.option.value.lastName || '')), approvedStatus: event.option.value.approvedStatus || false });
      //   this.selectedItems.push(((event.option.value.firstName || '') + ' '+(event.option.value.middleName || '') + ' '+ (event.option.value.lastName || '')))

      // }
      this.dropDownList = [];
      let index = this.selectedIds.findIndex((id) => id === event.option.value._id);
      if (index === -1) {
        this.selectedIds.push(event.option.value._id);
      }
      let endorserArray = this.form.controls.supporterArray as FormArray
      if (endorserArray) {
        let counts = [];
        for (let i = 0; i < endorserArray.length; i++) {
          if (counts[endorserArray.value[i].endorserField] === undefined) {
            counts[endorserArray.value[i].endorserField] = 1
          }
          else {
            // endorserArray.controls[i].value.duplicate = true;
          }
        }

      }
      this.selectedEndorsers.push(event.option.value._id);
      // let sIndex = this.selectedEndorsers.findIndex((id) => id === event.option.value._id);
      // if (sIndex === -1) {
      //   // this.selectedIds.push(event.option.value._id);

      // }
      // else {
      //   console.log('error for it')
      // }
      return
    }
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }
  submit() {
    let approverIds = (this.data.allDetails.approvers || []).map(({ _id }) => _id);
    let endorserIds = (this.data.allDetails.endorsers || []).map(({ _id }) => _id);
    let ownerId = (this.data.allDetails.owner)?this.data.allDetails.owner._id:"";
    let docIds = (this.data.allDetails.documents || []).map(({ _id }) => _id);
    let checkObj = {
      userIds: [...approverIds, ...endorserIds, ...ownerId, ...this.selectedApprovers, ...this.selectedEndorsers],
      docIds: docIds,
      unique: true
    }
    if (this.data.type === "tags") {

      this.dialogRef.close(this.selectedTagIds);
    }
    else if (this.data.type === "linkedTasks") {
      if (this.selectedLinkIds.length) {
        this.dialogRef.close(this.selectedLinkIds);
      }

    }
    else if (this.data.type === 'approvers') {
      // console.log(this.selectedApprovers, "approver")
      // this.dialogRef.close(this.selectedApprovers);
      // this._store.dispatch(new UpdateTaskDetails(this.data.allDetails._id, { 'approvers': this.selectedApprovers }))
      if (this.selectedApprovers.length) {

        this._store.dispatch(new CheckViewAccessGorDocStart(checkObj))
      }
      else {
        this.dialogRef.close('');
      }



    }
    else if (this.data.type === 'endorsers') {
      // this.dialogRef.close(this.selectedEndorsers);
      // this._store.dispatch(new UpdateTaskDetails(this.data.allDetails._id, {'endorsers': this.selectedEndorsers}))
      if (this.selectedEndorsers.length) {

        this._store.dispatch(new CheckViewAccessGorDocStart(checkObj))
      }
      else {
        this.dialogRef.close('');
      }
    }
    else if (this.data.type === 'documentHub') {
      // let newTagAdded = true;
      // if (this.data.allDetails.tags.length === this.selectedTagIds.length) {
      //   newTagAdded = false
      //   this.data.allDetails.tags.forEach(allDetailsTags => {
      //     if (this.selectedTagIds.indexOf(allDetailsTags._id) == -1) {
      //       newTagAdded = true;
      //     }
      //   });
      // }
      // if (this.data.allDetails.tags && !newTagAdded){
      //   this.dialogRef.close();
      //   return ;
      // }
      let selectedTags = Object.assign([], this.selectedTagIds)
      this.formData.set('tags', JSON.stringify(selectedTags));
      this._store.dispatch(new UPDATEDOCUMENTSTARTACTION(this.formData, (this.taskDetails.parentId) ? this.taskDetails.parentId : this.taskDetails._id, 'tagUpdate'));
      this.dialogRef.close();
    }
    else if (this.data.type === 'contract') {
      let selectedTags = Object.assign([], this.selectedTagIds)
      // this.formData.set('tags', JSON.stringify(selectedTags));
      console.log("data",this.contractDetails)
      console.log("tags",selectedTags)
      // add api call for Add-edit-tag
      let payload=this.contractDetails
      delete payload.tags
      let obj = {};
      obj['_id'] = payload.id;
      obj['tags'] = selectedTags
      // payload.tags=selectedTags
      // payload._id = payload.id;
      this._store.dispatch(new EditContract(obj));
      this.dialogRef.close();
    }
    this.submitButtonClicked = true;
  }


  displayFnForApprover(selectedValue) {
    if (selectedValue && Object.keys(selectedValue).length) {
      return ((selectedValue.firstName || '') + ' ' + (selectedValue.middleName || '') + ' ' + (selectedValue.lastName || ''));
    }

  }
  displayFnForEndorser(selectedValue) {
    if (selectedValue && Object.keys(selectedValue).length) {
      return ((selectedValue.firstName || '') + ' ' + (selectedValue.middleName || '') + ' ' + (selectedValue.lastName || ''));
    }

  }
  checkStatus(i, type) {
    const approver = this.form.controls.approverArray as FormArray;
    const endorser = this.form.controls.supporterArray as FormArray
    switch (type) {
      case 'approver':
        if (typeof (approver.controls[i].get('approverField').value) === 'object') {
          return true
        }
        return false
      case 'endorser':
        if (typeof (endorser.controls[i].get('endorserField').value) === 'object') {
          return true;
        }
        return false;

    }

  }
  removeUser(userType: string, index: any, formControl) {
    switch (userType) {
      case "approver":
        const approver = this.form.controls.approverArray as FormArray;
        approver.removeAt(index);

        let Index = this.selectedApprovers.findIndex((name) => {
          if (typeof (formControl.value.approverField) === 'object') {

            name === formControl.value.approverField.firstName.trim();
            // this.selectedIds.splice(this.selectedIds.indexOf(formControl.value.approverField._id), 1);
          }
          else {
            name === formControl.value.approverField.trim();
          }
        })
        this.selectedApproversIds.splice(this.selectedApproversIds.indexOf(formControl.value.approverField._id))
        if (Index > -1) {
          this.selectedApprovers.splice(this.selectedApproversIds, 1);
          this.selectedApproversIds.splice(this.selectedApproversIds.indexOf(formControl.value.approverField._id))
        }
        this.selectedIds.splice(this.selectedIds.indexOf(formControl.value.approverField._id), 1);
        break;
      case "endorser":
        const endorser = this.form.controls.supporterArray as FormArray;
        endorser.removeAt(index);
        let endIndex = this.selectedApprovers.findIndex((name) => {
          if (typeof (formControl.value.endorserField) === 'object') {

            return name === formControl.value.endorserField.firstName.trim();
          }
          else {
            return name === formControl.value.endorserField.trim();
          }
        })
        if (endIndex > -1) {

          this.selectedEndorsers.splice(endIndex, 1);
          this.selectedEndorsersIds.splice(this.selectedEndorsersIds.indexOf(formControl.value.endorserField._id))

        }
        // this.selectedIds.splice(this.selectedIds.indexOf(formControl.value.endorserField._id), 1);

        break;
    }

  }
  addUser(userType: string) {

    switch (userType) {
      case "approver":

        const approver = this.form.controls.approverArray as FormArray
        let appr = this.fb.group({
          'approverField': ['']
        })
        approver.push(appr);
        appr.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((val) => {
          if (val.approverField && val.approverField.length) {
            this._store.dispatch(new GetShareSuggestions(val.approverField.trim()));
          }
        })
        break;
      case "endorser":
        const endorser = this.form.controls.supporterArray as FormArray
        let endr = this.fb.group({
          'endorserField': ['']
        })
        endorser.push(endr);
        endr.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((val) => {
          if (val && val.endorserField.length) {
            this._store.dispatch(new GetShareSuggestions(val.endorserField.trim()));
          }
        })
        break;
    }

  }
  onCrossClick() {
    this.dialogRef.close('');
  }

  ngOnDestroy() {
    this.stopper$.next();
    this.stopper$.complete()
  }
}