import { Action } from '@ngrx/store';
import { CapabilitiesActionTypes, GetCapabilitiesSuccess, GetAllCapabilitiesOfRolesSuccess, AddCapabilitySuccess, RemoveCapabilitySuccess, GetAllRolesSuccess, AddRoleSuccess, EditRoleSuccess, AddRemoveCapabilitySuccess, GetCapabilityHistoryLogSuccess } from './capabilities.actions';

export interface Capabilities {
    capabilities: {};
    allCapabilitiesOfRoles: {};
    addCapResponse: {};
    removeCapResponse: {};
    roles: Array<any>;
    addRoleRespone: {};
    editRoleResponse: {};
    addRemoveRoleResponse: {};
    capabilityLogResponse: Array<any>;

}

const intiState = {
    capabilities: {},
    allCapabilitiesOfRoles: {},
    addCapResponse: {},
    removeCapResponse: {},
    roles: [],
    addRoleRespone: {},
    editRoleResponse: {},
    addRemoveRoleResponse: {},
    capabilityLogResponse: []
};


export function reducer(state: Capabilities = intiState, action: Action): Capabilities {
    switch (action.type) {
        case CapabilitiesActionTypes.GET_CAPABILITIES_SUCCESS:
            return Object.assign({}, state, { capabilities: (action as GetCapabilitiesSuccess).capabilities });
        case CapabilitiesActionTypes.GET_ALL_CAPABILITIES_OF_ROLES_SUCCESS:
            return Object.assign({}, state, { allCapabilitiesOfRoles: (action as GetAllCapabilitiesOfRolesSuccess).allCapabilitiesOfRoles });
        case CapabilitiesActionTypes.ADD_CAPABILITY_SUCCESS:
            return Object.assign({}, state, { addCapResponse: (action as AddCapabilitySuccess).response });
        case CapabilitiesActionTypes.REMOVE_CAPABILITY_SUCCESS:
            return Object.assign({}, state, { removeCapResponse: (action as RemoveCapabilitySuccess).response });
        case CapabilitiesActionTypes.GET_ALL_ROLES_SUCCESS:
            return Object.assign({}, state, { roles: (action as GetAllRolesSuccess).roles });
        case CapabilitiesActionTypes.ADD_ROLE_SUCCESS:
            return Object.assign({}, state, { addRoleRespone: (action as AddRoleSuccess).response });
        case CapabilitiesActionTypes.EDIT_ROLE_SUCCESS:
            return Object.assign({}, state, { editRoleResponse: (action as EditRoleSuccess).response });
        case CapabilitiesActionTypes.ADD_REMOVE_CAPABILITY_SUCCESS:
            return Object.assign({}, state, { addRemoveRoleResponse: (action as AddRemoveCapabilitySuccess).response });
        case CapabilitiesActionTypes.GET_CAPABILITIES_ROLES_LOG_SUCCESS:
            return Object.assign({}, state, { capabilityLogResponse: (action as GetCapabilityHistoryLogSuccess).response });
        default:
            return state;
    }
}