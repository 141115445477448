import { Action } from '@ngrx/store';
import {
  MessagesActionTypes,
  GetInboxSuccess,
  GetMailByIdSuccess,
  GetSearchedMailSuccess,
  GetMailByIdInAllCmpSuccess,
  GetSearchedAllMailSuccess,
  CheckViewAccessGorDocSuccess,
  AddViewAccessForDocumentSuccess,
  MarkMailAsReadSuccess,
  DeleteMessageSuccess,
  GetReplacedUsersSuccess,
  ClearAction,
  DeleteMailSuccess,
  GetMessageCount
} from './messages.actions';

export interface Messages {
  inbox?: any;
  messageDetail?: any;
  searchMailResults: any;
  searchAllMailResults: any;
  viewAccessForDoc: any;
  addViewAccessForDoc: any;
  markMailAsReadSuccess: any;
  deleteMessage: any;
  replacedUsers: Array<any>;
  messageCount: any;
}

const intialState = {
  inbox: {
    docs: [],
    details: {}
  },
  messageDetail: {},
  searchMailResults: {
    docs: [],
    searchedDetails: {}
  },
  searchAllMailResults: {
    docs: [],
    searchedAllMailDetails: {}
  },
  viewAccessForDoc: [],
  addViewAccessForDoc: {},
  markMailAsReadSuccess: {} || [],
  deleteMessage: {},
  replacedUsers: [],
  messageCount: {}
};

export function reducer(state: Messages = intialState, action: Action): Messages {
  switch (action.type) {
    case MessagesActionTypes.GET_INBOX_SUCCESS:
      // console.log((action as GetInboxSuccess).mails)
      let docs = (action as GetInboxSuccess).mails.docs;
      let pageNum = (action as GetInboxSuccess).mails.page;
      if (pageNum == 1) {
        state.inbox.docs = []
      }

      return Object.assign({}, state, {
        inbox: {
          docs: [...state.inbox.docs, ...docs],
          details: (action as GetInboxSuccess).mails
        },
      });
    case MessagesActionTypes.GET_MAIL_BY_ID_SUCCESS:
      return {
        ...state,
        messageDetail: (action as GetMailByIdSuccess).data,
      }
    case MessagesActionTypes.GET_SEARCHED_MAIL_SUCCESS:
      let searchedMailDocs =(action as GetSearchedMailSuccess).data.docs;
      let searchedMailpageNumber =(action as GetSearchedMailSuccess).data.page;
      if(searchedMailpageNumber === 1) {
        state.searchMailResults.docs =[];
      }    
      return Object.assign({}, state, { searchMailResults: {
        docs:[...state.searchMailResults.docs, ...searchedMailDocs],
       
        searchedDetails: (action as GetSearchedMailSuccess).data} });
    case MessagesActionTypes.GET_SEARCHED_ALL_MAIL_SUCCESS:
        let searchedAllMailDocs =(action as GetSearchedAllMailSuccess).data.docs;
      let searchedAllMailpageNumber =(action as GetSearchedAllMailSuccess).data.page;
      if(searchedAllMailpageNumber == 1) {
        state.searchAllMailResults.docs =[]
      }
      return Object.assign({}, state, { searchAllMailResults: {
        docs: [...state.searchAllMailResults.docs, ...searchedAllMailDocs],
        searchedAllMailDetails: (action as GetSearchedAllMailSuccess).data} });
    case MessagesActionTypes.GET_MAIL_BY_ID_IN_ALL_CMP_SUCCESS:
      return {
        ...state,
        messageDetail: (action as GetMailByIdInAllCmpSuccess).response
      }
    case MessagesActionTypes.CHECK_VIEW_ACCESS_FOR_DOC_SUCCESS:
      return {
        ...state,
        viewAccessForDoc: (action as CheckViewAccessGorDocSuccess).response
      }
    case MessagesActionTypes.ADD_VIEW_ACCESS_FOR_DOCUMENTS_SUCCESS:
      return {
        ...state,
        addViewAccessForDoc: (action as AddViewAccessForDocumentSuccess).response
      }
    case MessagesActionTypes.CLEAR_VIEW_ACCESS_FOR_RESPONSE:
      return {
        ...state,
        viewAccessForDoc: []
      }
      case MessagesActionTypes.Mark_MAIL_AS_READ_SUCCESS:
        const markedMessageInInbox = [];
        const markedMessageInSearch = [];
        const hasMarkedMailIndex = state.inbox.docs
                      .findIndex(mail => ((action as MarkMailAsReadSuccess).threadId === mail.threadId));
        const hasMarkedMailIndexInSearch = state.searchMailResults.docs.
                      findIndex(mail => ((action as MarkMailAsReadSuccess).threadId === (mail || {}).message[0].threadId));
        if (hasMarkedMailIndex >= 0) {
          const markedMessage =  state.inbox.docs.splice(hasMarkedMailIndex, 1);
          markedMessage[0].messages[markedMessage[0].messages.length - 1].read = true;
          markedMessageInInbox.push(markedMessage);
          // state.inbox.docs[hasMarkedMailIndex].read = true;
        }
        if (hasMarkedMailIndexInSearch >= 0) {
          const markedMessageSearch =  state.searchMailResults.docs.splice(hasMarkedMailIndexInSearch, 1);
          markedMessageSearch[0].message[0].read = true;
          markedMessageInSearch.push(markedMessageSearch);
        }
        if(hasMarkedMailIndex >= 0 && hasMarkedMailIndexInSearch >= 0) {
          return Object.assign({}, state, {
            inbox: {
              ...state.inbox,
              docs: [
                ...state.inbox.docs.slice(0, hasMarkedMailIndex),
                ...markedMessageInInbox[0],
                ...state.inbox.docs.slice(hasMarkedMailIndex + 1)
              ],
            },
            searchMailResults: {
              ...state.searchMailResults,
              docs: [
                ...state.searchMailResults.docs.slice(0, hasMarkedMailIndexInSearch),
                ...markedMessageInSearch[0],
                ...state.searchMailResults.docs.slice(hasMarkedMailIndexInSearch)
              ],
            },
          });
        } else if (hasMarkedMailIndex >= 0) {
          return Object.assign({}, state, {
            inbox: {
              ...state.inbox,
              docs: [
                ...state.inbox.docs.slice(0, hasMarkedMailIndex),
                ...markedMessageInInbox[0],
                ...state.inbox.docs.slice(hasMarkedMailIndex)
              ],
            },
          });

        } else if (hasMarkedMailIndexInSearch >= 0) {
          return Object.assign({}, state, {
            searchMailResults: {
              ...state.searchMailResults,
              docs: [
                ...state.searchMailResults.docs.slice(0, hasMarkedMailIndexInSearch),
                ...markedMessageInSearch[0],
                ...state.searchMailResults.docs.slice(hasMarkedMailIndexInSearch)
              ],
            },
          });

        } else {
          return state;
        }
        // return {
        //   ...state,
        //   markMailAsReadSuccess: (action as MarkMailAsReadSuccess).response
        // };
        case MessagesActionTypes.DELETE_MESSAGE_SUCCESS:
          return {
            ...state,
            deleteMessage: (action as DeleteMessageSuccess).response
          }
        case MessagesActionTypes.GET_REPLACED_USERS_SUCCESS:
            return Object.assign({}, state, { replacedUsers: (action as GetReplacedUsersSuccess).response });
        case MessagesActionTypes.CLEAR_ACTION:
            return {
                ...state,
                messageDetail: {},
                messageCount: {}
            }
        case MessagesActionTypes.DELETE_MAIL_SUCCESS:
            const response = (action as DeleteMailSuccess).rsp;
            const hasDeletedMailIndex = state.inbox.docs
                      .findIndex(mail => ((action as DeleteMailSuccess).rsp.threadId === mail.threadId));
            const hasDeletedMailIndexInSearch = state.searchMailResults.docs.
                      findIndex(mail => ((action as DeleteMailSuccess).rsp.threadId === mail.threadId));
            if (hasDeletedMailIndex >= 0 && ((action as DeleteMailSuccess).rsp.msgLength === 1)) {
                state.inbox.docs.splice(hasDeletedMailIndex, 1);
            }
            if (hasDeletedMailIndexInSearch >= 0 && ((action as DeleteMailSuccess).rsp.msgLength === 1)) {
              state.searchMailResults.docs.splice(hasDeletedMailIndex, 1);
             }
          if (hasDeletedMailIndex >= 0 && hasDeletedMailIndex >= 0) {
            return Object.assign({}, state, {
              inbox: {
                ...state.inbox,
                docs: [...state.inbox.docs],
              },
              searchMailResults: {
                ...state.searchMailResults,
                docs: [...state.searchMailResults.docs],
              },
            });
          } else if (hasDeletedMailIndexInSearch >= 0) {
            return Object.assign({}, state, {
              searchMailResults: {
                ...state.searchMailResults,
                docs: [...state.searchMailResults.docs],
              },
            });
          } else if (hasDeletedMailIndex >= 0) {
            return Object.assign({}, state, {
              inbox: {
                ...state.inbox,
                docs: [...state.inbox.docs],
              }
            });
          }
          return state;
        case MessagesActionTypes.GET_MESSAGE_COUNT:
            return Object.assign({}, state, {
                messageCount: (action as GetMessageCount).messageCountObj
            })
    default:
      return state;
  }
}
