import { Effect, Actions, ofType } from '@ngrx/effects';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import {
    TasksActionTypes,
    GetTaskListStartAction,
    GetTasksListSuccessAction,
    GetAllTagsListSuccessAction,
    GetAllTagsListStartAction,
    AddNewTask,
    GetTaskDetails,
    GetTaskDetailsSuccess,
    UpdateTaskDetails,
    AddTagStartAction,
    AddTagSuccessAction,
    GetAssignedTaskListStartAction,
    GetCreatedTaskListStartAction,
    GetLinkedTasks,
    GetLinkedTasksSuccess,
    GetSubtasks,
    GetSubtaskSuccess,
    MoveTaskToNextState,
    CreateSubTask,
    SearchTaskIdStartAction,
    CancelTask,
    DeleteDocumentInTaskStartAction,
    GetActivityLog,
    GetActivityLogSuccess,
    CommonSuccessForTask,
    GetAllFiltersList,
    GetCreatedByMeFiltersList,
    GetAssignedToMeFiltersList,
    GetAllFiltersListSuccess,
    GetCreatedByMeFiltersListSuccess,
    GetAssignedToMeFiltersListSuccess,
    GetFilteredTasks,
    GetFilteredTasksSuccess,
    SearchTasksStart,
    SearchTasksSuccess,
    GetAllCmpTasksList,
    GetAllCmpTasksSuccess,
    GetEndorseOtp,
    GetEndorseOtpSuccess,
    VerifyEndorseOtp,
    VerifyEndorseOtpSuccess,
    SuggestTaskTags,
    ApproveTaskTags,
    RejectTaskTags,
    DeleteSuggestTaskTags,
    UnlinkTasks,
    ViewTaskDetails,
    ViewTaskDetailsSuccess,
    EditDueDate,
    GetAllCMPTasksFilters,
    GetAllCMPTasksFiltersSuccess,
    GetInactiveReplacedUsers,
    GetInactiveReplacedUsersSuccess,
    updatePacketDetails,
    updatePacketDetailsSuccess,
    createPacketDetails,
    createPacketDetailsSuccess
} from './tasks.actions';
import { API } from 'src/app/services/Api.service';
import { of } from 'rxjs';
import { switchMap, catchError, map, tap } from 'rxjs/operators';
import { ErrorAction, AuthActionTypes } from 'src/app/login/store/auth.actions';
import { NotificationsService } from "angular2-notifications";
import { Location } from '@angular/common';
import { LoaderHide, LoaderShow } from 'src/app/features/loader/store/loader.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { DeleteSuggestedTag } from '../../resources/_store/resources.actions';
import { NgxPermissionsService } from 'ngx-permissions';
import { getComments } from '../../resources/_store/resources.actions';


@Injectable()
export class TasksEffects {
    taskId: string;

    @Effect()
    getTasksList$ = this.actions$.pipe(
        ofType(TasksActionTypes.GET_TASK_LIST),
        switchMap((action: GetTaskListStartAction) => {
            return this.api.getTasksList('allTasks').pipe(
                map((response: any) => {
                    if (response) {
                        return new GetTasksListSuccessAction(response);
                    }
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    getTagsList$ = this.actions$.pipe(
        ofType(TasksActionTypes.GET_TAGS_LIST),
        switchMap((action: GetAllTagsListStartAction) => {
            return this.api.getSearchedTag(action.tagname).pipe(
                map((response: any) => {
                    return new GetAllTagsListSuccessAction(response.data)
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    );

    @Effect()
    addNewTask$ = this.actions$.pipe(
        ofType(TasksActionTypes.ADD_NEW_TASK),
        switchMap((action: AddNewTask) => {
            return this.api.createNewTask(action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(`Task created successfully`);
                    this.router.navigate(['home/tasks']);
                    return new CommonSuccessForTask()
                }),
                catchError(err => {
                    console.log(err, "err")

                    return of(new ErrorAction(err))
                }

                )
            )
        })
    );

    @Effect()
    getTaskDetails$ = this.actions$.pipe(
        ofType(TasksActionTypes.GET_TASK_DETAILS),
        switchMap((action: GetTaskDetails) => {
            this.store.dispatch(new LoaderShow())
            return this.api.getTaskDetail(action.taskId).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide())
                    this.taskId = action.taskId;
                    return new GetTaskDetailsSuccess(response);
                }),
                catchError((err) => {
                    this.location.back();
                    return of(new ErrorAction(err))
                })
            );
        })
    );

    @Effect()
    moveTaskToNextState$ = this.actions$.pipe(
        ofType(TasksActionTypes.MOVE_TASK_TO_NEXT_STATE),
        switchMap((action: MoveTaskToNextState) => {
            return this.api.changeTaskState(action.taskId, action.action, action.body).pipe(
                map((response: any) => {
                    this.notificationService.success(response.successMessage || 'Task updated successfully');
                    if (Object.keys(this.ngxPermissionService.getPermissions()).includes('view-activity-log')) {
                        this.store.dispatch(new GetActivityLog(action.taskId));
                    }
                    this.store.dispatch(new getComments(action.taskId, 'task'));
                    return new GetTaskDetails(action.taskId);
                }),
                // catchError(err => {
                //   this.notificationService.error(err.error.errors[0].error);
                //   return of(new ErrorAction(err));
                // })
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getLinkedTasksForTask$ = this.actions$.pipe(
        ofType(TasksActionTypes.GET_LINKED_TASKS),
        switchMap((action: GetLinkedTasks) => {
            return this.api.getLinkedTasksForTaskId(action.taskId).pipe(
                map((response: any) => {
                    return new GetLinkedTasksSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    unlinkTaks$ = this.actions$.pipe(
        ofType(TasksActionTypes.UNLINK_TASKS),
        switchMap((action: UnlinkTasks) => {
            return this.api.unlinkTasks(action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message);
                    if (Object.keys(this.ngxPermissionService.getPermissions()).includes('view-activity-log')) {
                        this.store.dispatch(new GetActivityLog(action.payload.id));
                    }
                    return new GetTaskDetails(action.payload.id);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    cancelTask$ = this.actions$.pipe(
        ofType(TasksActionTypes.CANCEL_TASK),
        switchMap((action: CancelTask) => {
            return this.api.cancelTask(action.taskId).pipe(
                map((response: any) => {
                    this.notificationService.success('Task cancelled successfully');
                    if (Object.keys(this.ngxPermissionService.getPermissions()).includes('view-activity-log')) {
                        this.store.dispatch(new GetActivityLog(action.taskId));
                    }
                    return new GetTaskDetails(action.taskId);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getSubtasksForTask$ = this.actions$.pipe(
        ofType(TasksActionTypes.GET_SUB_TASKS),
        switchMap((action: GetSubtasks) => {
            return this.api.getSubtaskForTaskId(action.taskId).pipe(
                map((response: any) => {
                    return new GetSubtaskSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    createSubTask$ = this.actions$.pipe(
        ofType(TasksActionTypes.CREATE_SUBTASKS),
        switchMap((action: CreateSubTask) => {
            return this.api.createSubTask(action.taskId, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.successMessage);
                    if (Object.keys(this.ngxPermissionService.getPermissions()).includes('view-activity-log')) {
                        this.store.dispatch(new GetActivityLog(action.taskId));
                    }
                    this.location.back();
                    return new GetTaskDetails(action.taskId);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    updateTaskDetails$ = this.actions$.pipe(
        ofType(TasksActionTypes.UPDATE_TASK_DETAILS),
        switchMap((action: UpdateTaskDetails) => {
            return this.api.updateTaskDetails(action.taskId, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.successMessage);
                    if (Object.keys(this.ngxPermissionService.getPermissions()).includes('view-activity-log')) {
                        this.store.dispatch(new GetActivityLog(action.taskId));
                    }
                    return new GetTaskDetails(action.taskId);
                }),
                catchError((error) => {
                    this.notificationService.error(error.error.errors[0].error);
                    return of(new GetTaskDetails(action.taskId));
                })
            )
        })
    );
    @Effect()
    addTag$ = this.actions$.pipe(
        ofType(TasksActionTypes.ADD_TAG_START),
        switchMap((action: AddTagStartAction) => {
            return this.api.addNewTag(action.tag).pipe(
                map((response: any) => {
                    if (Object.keys(response)) {
                        this.store.dispatch(new LoaderHide());
                        if (Object.keys(this.ngxPermissionService.getPermissions()).includes('view-activity-log')) {
                            this.store.dispatch(new GetActivityLog(this.taskId));
                        }
                        return new AddTagSuccessAction(response)
                    }
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )
    @Effect()
    getAssignedList$ = this.actions$.pipe(
        ofType(TasksActionTypes.GET_ASSIGNED_TASK_LIST),
        switchMap((action: GetAssignedTaskListStartAction) => {
            return this.api.getTasksList('assigned').pipe(
                map((response: any) => {
                    if (response) {
                        return new GetTasksListSuccessAction(response)
                    }
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )
    @Effect()
    getCreatedTaskList$ = this.actions$.pipe(
        ofType(TasksActionTypes.GET_CREATED_TASK_LIST),
        switchMap((action: GetCreatedTaskListStartAction) => {
            return this.api.getTasksList('created').pipe(
                map((response: any) => {
                    if (response) {
                        return new GetTasksListSuccessAction(response)
                    }
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )
    @Effect()
    searchTaskId$ = this.actions$.pipe(
        ofType(TasksActionTypes.SEARCH_TASK_Id_START),
        switchMap((action: SearchTaskIdStartAction) => {
            return this.api.getSearchedTaskId(action.taskId, action.search).pipe(
                map((response: any) => {
                    if (response) {
                        return new GetTasksListSuccessAction(response)
                    }
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    deleteDocument$ = this.actions$.pipe(
        ofType(TasksActionTypes.DELETE_DOCUMENT_IN_TASK_START),
        switchMap((action: DeleteDocumentInTaskStartAction) => {
            return this.api.deleteDocumentInTask(action.taskId, action.documentId).pipe(
                map((response) => {
                    if (response) {
                        if (Object.keys(this.ngxPermissionService.getPermissions()).includes('view-activity-log')) {
                            this.store.dispatch(new GetActivityLog(action.taskId));
                        }
                        return new GetTaskDetails(action.taskId);
                    }
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    getActivityLog$ = this.actions$.pipe(
        ofType(TasksActionTypes.GET_ACTIVITY_LOG),
        switchMap((action: GetActivityLog) => {
            return this.api.getActivityLog(action.taskId).pipe(
                map((response) => {
                    return new GetActivityLogSuccess(response)
                }),
                // catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    getAllTasksFilters = this.actions$.pipe(
        ofType(TasksActionTypes.GET_ALL_TASK_FILTERS),
        switchMap((action: GetAllFiltersList) => {
            this.store.dispatch(new LoaderShow());

            return this.api.getAllTaskFilters().pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide());
                    if (response) {
                        return new GetAllFiltersListSuccess(response)
                    }
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    getCreatedByMeTasksFilters = this.actions$.pipe(
        ofType(TasksActionTypes.GET_CREATEDBYME_TASK_FILTERS),
        switchMap((action: GetCreatedByMeFiltersList) => {
            this.store.dispatch(new LoaderShow());

            return this.api.getCreatedByMeTasksFilters().pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide());
                    if (response) {
                        return new GetCreatedByMeFiltersListSuccess(response)
                    }
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    getAssignedToMeFilters = this.actions$.pipe(
        ofType(TasksActionTypes.GET_ASSIGNEDTOME_TASK_FILTERS),
        switchMap((action: GetAssignedToMeFiltersList) => {
            this.store.dispatch(new LoaderShow());
            return this.api.getAssignedToMeTasksFilters().pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide());
                    if (response) {
                        return new GetCreatedByMeFiltersListSuccess(response)
                    }
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    getAllCmpTaskFilter$ = this.actions$.pipe(
        ofType(TasksActionTypes.GET_ALL_CMP_TASK_FILTERS),
        switchMap((action: GetAllCMPTasksFilters) => {
            this.store.dispatch(new LoaderShow());
            return this.api.getAllCmpTaskFilters().pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide());
                    if (response) {
                        return new GetAllCMPTasksFiltersSuccess(response);
                    }
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    getFilteredTasks = this.actions$.pipe(
        ofType(TasksActionTypes.GET_FILTERED_TASKS),
        switchMap((action: GetFilteredTasks) => {
            this.store.dispatch(new LoaderHide());
            return this.api.getFilteredTasks(action.payload, action.params).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide());
                    if (response) {
                        return new GetFilteredTasksSuccess(response)
                    }
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )
    @Effect()
    searchTasks$ = this.actions$.pipe(
        ofType(TasksActionTypes.SEARCH_TASKS_START),
        switchMap((action: SearchTasksStart) => {
            return this.api.searchTasks(action.filterType, action.searchString, action.pageNumber, action.limit).pipe(
                map((response: any) => {
                    if (response) {
                        return new SearchTasksSuccess(response);
                    }
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        }))

    @Effect()
    getAlltasks$ = this.actions$.pipe(
        ofType(TasksActionTypes.GET_ALL_CMP_TASK_START),
        switchMap((action: GetAllCmpTasksList) => {
            return this.api.getAllCmpTask().pipe(
                map((response: any) => {
                    if (response) {
                        return new GetAllCmpTasksSuccess(response)
                    }
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    getEndorseOtp$ = this.actions$.pipe(
        ofType(TasksActionTypes.GET_ENDORSE_OTP),
        switchMap((action: GetEndorseOtp) => {
            return this.api.getEndorseOtp().pipe(
                map((response: any) => {
                    this.notificationService.success(response.message);
                    return new GetEndorseOtpSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    verifyEndorseOtp$ = this.actions$.pipe(
        ofType(TasksActionTypes.VERIFY_ENDORSE_OTP),
        switchMap((action: VerifyEndorseOtp) => {
            return this.api.verifyEndorseOtp(action.taskId, action.payload).pipe(
                map((response: any) => {
                    // this.notificationService.success(response.message);
                    return new VerifyEndorseOtpSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    SuggestTaskTags$ = this.actions$.pipe(
        ofType(TasksActionTypes.ADD_SUGGESTED_TASK_TAGS),
        switchMap((action: SuggestTaskTags) => {
            return this.api.addSuggestedTaskTags(action.id, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message);
                    if (Object.keys(this.ngxPermissionService.getPermissions()).includes('view-activity-log')) {
                        this.store.dispatch(new GetActivityLog(action.id));
                    }
                    return new GetTaskDetails(action.id);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    ApproveTaskTag$ = this.actions$.pipe(
        ofType(TasksActionTypes.APPROVE_SUGGESTED_TASK_TAGS),
        switchMap((action: ApproveTaskTags) => {
            return this.api.approveTaskTag(action.id, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message);
                    if (Object.keys(this.ngxPermissionService.getPermissions()).includes('view-activity-log')) {
                        this.store.dispatch(new GetActivityLog(action.id));
                    }
                    return new GetTaskDetails(action.id);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    RejectTaskTag$ = this.actions$.pipe(
        ofType(TasksActionTypes.REJECT_SUGGESTED_TASK_TAGS),
        switchMap((action: RejectTaskTags) => {
            return this.api.rejectTaskTag(action.id, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message);
                    if (Object.keys(this.ngxPermissionService.getPermissions()).includes('view-activity-log')) {
                        this.store.dispatch(new GetActivityLog(action.id));
                    }
                    return new GetTaskDetails(action.id);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    DeleteTaskTag$ = this.actions$.pipe(
        ofType(TasksActionTypes.DELETE_SUGGESTED_TASK_TAGS),
        switchMap((action: DeleteSuggestTaskTags) => {
            return this.api.deleteSuggetsedTaskTag(action.id, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message);
                    if (Object.keys(this.ngxPermissionService.getPermissions()).includes('view-activity-log')) {
                        this.store.dispatch(new GetActivityLog(action.id));
                    }
                    return new GetTaskDetails(action.id);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    ViewTaskDetails$ = this.actions$.pipe(
        ofType(TasksActionTypes.VIEW_TASK_DETAILS),
        switchMap((action: ViewTaskDetails) => {
            this.store.dispatch(new LoaderShow())
            return this.api.viewTaskDetails(action.projectId, action.taskId).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide())
                    return new GetTaskDetailsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    EditDueDate$ = this.actions$.pipe(
        ofType(TasksActionTypes.EDIT_DUE_DATE),
        switchMap((action: EditDueDate) => {
            return this.api.editTaskDueDate(action.date, action.id).pipe(
                map((response: any) => {
                    this.notificationService.success('Completed date updated successfully');
                    return new GetTaskDetails(action.id);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    getReplacedInactiveUsers$ = this.actions$.pipe(
        ofType(TasksActionTypes.GET_INACTIVE_REPLACED_USER),
        switchMap((action: GetInactiveReplacedUsers) => {
            return this.api.getInactiveReplacedusers(action.taskId).pipe(
                map((response: any) => {
                    return new GetInactiveReplacedUsersSuccess(response);
                }),
                catchError((err) => {
                    return of(new ErrorAction(err))
                })
            );


        })
    );

    @Effect()
    updatepacketdetals$ = this.actions$.pipe(
        ofType(TasksActionTypes.UPDATE_PACKET_DETAILS),
        switchMap((action: updatePacketDetails) => {
            return this.api.updatepacketdetails(action.taskId,action.payload).pipe(
                map((response: any) => {
                    return new updatePacketDetailsSuccess(response);
                }),
                catchError((err) => {
                    return of(new ErrorAction(err))
                })
            );


        })
    );


    
    @Effect()
    createpacketdetals$ = this.actions$.pipe(
        ofType(TasksActionTypes.CREATE_PACKET_DETAILS),
        switchMap((action: createPacketDetails) => {
            return this.api.createpacketdetails(action.taskId,action.projectID,action.payload).pipe(
                map((response: any) => {
                    return new createPacketDetailsSuccess(response);
                }),
                catchError((err) => {
                    return of(new ErrorAction(err))
                })
            );


        })
    );

    constructor(
        private actions$: Actions,
        private api: API,
        private notificationService: NotificationsService,
        private router: Router,
        private location: Location,
        private ngxPermissionService: NgxPermissionsService,
        private store: Store<AppState>
    ) { }
}
