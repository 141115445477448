import { COMMA, ENTER } from '@angular/cdk/keycodes';
import { Component, ElementRef, ViewChild, Input, OnInit, Output, EventEmitter, OnDestroy } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MatAutocompleteSelectedEvent, MatAutocomplete } from '@angular/material/autocomplete';
import { Observable, combineLatest, of, Subject } from 'rxjs';
import { map, startWith, tap, debounceTime, filter, distinctUntilChanged, take, takeUntil } from 'rxjs/operators';
import { Store } from '@ngrx/store';
import { GetShareSuggestions, ClearShareSuggestions } from 'src/app/features/home/resources/_store/resources.actions';
import { CommonService } from 'src/app/services/tasks.services';
import { LoaderShow, LoaderHide } from "src/app/features/loader/store/loader.actions";


@Component({
  selector: 'app-chiplist-autocomplete',
  templateUrl: './chiplist-autocomplete.component.html',
  styleUrls: ['./chiplist-autocomplete.component.scss']
})
export class ChiplistAutoCompleteComponent implements OnInit, OnDestroy {

  visible = true;
  selectable = true;
  removable = true;
  addOnBlur = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  formCtrl = new FormControl('');
  filteredList = [];
  selectedItems: Array<any> = [];
  selectedOwnerIds: Array<any> = []
  noResultMsg: boolean = false

  @ViewChild('input', { static: false }) input: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;

  @Input() placeholder;
  @Input() type;
  @Input() allList: Observable<any> = of([]);
  @Input() dropDownList: any;
  @Input() defaultSelection: Array<any> = [];
  @Output() selectedItemsEvent: EventEmitter<any> = new EventEmitter();
  @Output() removedIdEvent: EventEmitter<any> = new EventEmitter;
  @Input() removedId = [];
  @Input() selectedIds: Array<any> = [];
  @Input() recipientList: any = { to: [], from: [] };
  @Input() replyFlag: boolean = false;
  @Input() replyOne: any = { flag: false, userId: '' };
  duplicate = false;
  @Input() defaultSelectionObject: any = new EventEmitter();
  showDropDown = false;
  doneOnce = false;
  stopper$: Subject<any> = new Subject();
  @Output() focuseOutEvent: EventEmitter<any> = new EventEmitter();
  constructor(private store: Store<any>, private commonService: CommonService) {

    if (this.type === 'createTaskForProject') {
      return;
    }

    this.store.select(state => state.resources.shareSuggestions).pipe(takeUntil(this.stopper$), map((list) => {
      list = (list.length) ? list : [];
      if (this.type === 'createGroup') {
        return list.filter((obj) => !(this.selectedIds || []).includes(obj._id)).filter((obj) => obj.type === 'user')
      } else if (this.type === 'message' || this.type === 'add-viewers') {
        if (list.length) {
          if (!this.doneOnce) {
            this.selectedItems = list.filter((user) => this.selectedIds.includes(user._id));

            if (this.replyFlag) {
              this.selectedItems = []
              if (this.replyOne.flag == true && (this.selectedItems.length < this.recipientList.from.length)) {
                this.selectedItems = this.recipientList.from.map(user => {
                  if (user._id == this.replyOne.userId) {
                    return {
                      ...user,
                      type: 'user'
                    }
                  }
                })
              }
              else if (this.replyOne.flag == false && (this.selectedItems.length < this.recipientList.to.length))
                this.selectedItems = this.recipientList.to.map(user => {
                  let typeProperty
                  typeProperty = user.members ? 'group' : 'user'
                  return {
                    ...user,
                    type: typeProperty
                  }
                })
            }
            this.selectedIds = this.selectedItems.map((user) => user._id);
            this.doneOnce = true;
          }
        }
        this.selectedItemsEvent.emit({ selectedItems: this.selectedItems });
        // return list.filter((eachRes) => !this.selectedIds.includes(eachRes._id));
        return list
      }
      return (list || []).filter((eachRes) => !(this.selectedIds || []).includes(eachRes._id));


    })).subscribe((list) => {
      if (this.showDropDown) {
        this.filteredList = list;
        this.noResultMsg = true
      }
      if (this.type === 'message' || this.type === 'shareDocs' || this.type === 'add-viewers' || this.type === 'replaceUser') {
        // let findIndex =this.selectedIds
        this.filteredList = list.map((obj) => {
          if (obj.type === 'group') {
            return obj;
          }
          return obj;
        })
      } else if (this.type === 'createTask') {
        this.filteredList = list.filter((obj) => ((obj.type !== 'group') && (obj.type !== 'private-list')))
      }

      this.store.dispatch(new LoaderHide())

    });
  }

  ngOnInit() {

    if (this.type === 'add-viewers' || this.type === 'message') { //this.type === 'message'
      this.store.dispatch(new LoaderShow())
      this.selectedIds = this.defaultSelection;
      this.selectedItems = this.defaultSelectionObject;
      switch (this.type) {
        case 'message':
        case 'shareDocs':
          this.store.dispatch(new GetShareSuggestions('', 'user,private-list,group'));
          break;
        case 'replaceUser':
          this.store.dispatch(new GetShareSuggestions('', 'user'));
          break;
        default:
          this.store.dispatch(new GetShareSuggestions('', 'user'))
      }
    }

    // this.store.dispatch(new GetShareSuggestions('', 'users'));
    //  if (this.defaultSelection && this.defaultSelection.length) {
    //   this.allList.pipe(
    //     map((userList: any) => {
    //       console.log(userList, "userList")
    //       return userList.filter(user => this.defaultSelection.includes(user._id));
    //     })
    //   ).subscribe(list => {
    //     this.selectedItems = list;
    //   });
    // } 

    /* TODO:: this code has to be removed as no code is added to this condition check, commenting for now */
    /* if (this.type && this.type.length && this.type === 'createTask') {

    } */

    this.formCtrl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe((value) => {
      if (value !== null) {

        if (this.type && this.type === 'createTaskForProject') {
          this.dropDownList.pipe(take(1)).subscribe(roles => {
            // const rolesList = roles.filter(role => role.key !== 'Program Coordinator');
            this.filteredList = roles.filter(role => role.is_active)
            .filter(role => (role.key || {}).toLowerCase().includes(value.toLowerCase()));
          });
        } else {
          // this.commonService.getGroupDetails = true;
          // write type suggestions here for document, task, message
          switch (this.type) {
            case 'message':
            case 'shareDocs':
              this.store.dispatch(new GetShareSuggestions(value, 'user,private-list,group'));
              break;
            case 'replaceUser':
              this.store.dispatch(new GetShareSuggestions(value, 'user'));
              break;
            // case 'createTask':
            // case 'createGroup':
            // case 'add-viewers':
            //  this.store.dispatch(new GetShareSuggestions(value, 'user'));
            //   break;
            // case 'shareDocs': this.store.dispatch(new GetShareSuggestions(value, 'user,private-group,group'));
            //   break;
            default:
              this.store.dispatch(new GetShareSuggestions(value, 'user'));
          }
        }
        this.showDropDown = true;
      }
    });
  }

  remove(removedItem: any) {

    if ((this.type === 'message') || (this.type == 'createGroup') || (this.type == 'shareDocs') || (this.type == 'add-viewers') || (this.type == 'replaceUser')) {
      const Selectedindex = this.selectedIds.findIndex((id) => id === removedItem._id);
      if (Selectedindex > -1) {
        this.selectedIds.splice(Selectedindex, 1);
        this.selectedItems.splice(Selectedindex, 1);

      }
    }
    const index = this.removedId.findIndex((id) => id === removedItem._id || removedItem.value);
    if (index === -1) {
      this.removedId.push(removedItem._id || removedItem.value);
    }
    const Selectedindex = this.selectedOwnerIds.findIndex((id) => id === removedItem._id || removedItem.value);
    if (Selectedindex !== -1) {
      this.selectedOwnerIds.splice(Selectedindex, 1);
      this.selectedItems.splice(Selectedindex, 1);
    }
    this.formCtrl.setValue(null);
    this.removedIdEvent.emit({ removedItems: this.removedId });

    this.selectedItemsEvent.emit({ selectedItems: this.selectedItems });

    this.removedId = [];
  }

  selected(event: MatAutocompleteSelectedEvent) {
    if (this.type === 'createTask' || this.type === 'createTaskForProject' || this.type === 'replaceUser') {
      const index = this.selectedOwnerIds.findIndex((selected) => {
        return event.option.value._id === selected;
      });
      if (index === -1) {
        if (!this.selectedOwnerIds.length) {
          if (this.type === 'createTaskForProject') {
            this.selectedItems.push(event.option.value);
            this.selectedOwnerIds.push(event.option.value.value);
          }
          else {
            this.selectedItems.push(event.option.value);
            this.selectedOwnerIds.push(event.option.value._id || event.option.value.value);
          }

        } else {
          if (this.type == 'createTaskForProject') {
            this.selectedItems.splice(0, 1, event.option.value);
            this.selectedOwnerIds.splice(0, 1, event.option.value.value);


          }
          else {
            this.selectedItems.splice(0, 1, event.option.value);
            this.selectedOwnerIds.splice(0, 1, event.option.value._id || event.option.value.value);
          }
        }
      } else {
        // this.duplicate =true
      }
    } else if (this.type === 'message' || this.type === 'add-viewers' || this.type === 'shareDocs' || this.type == 'replaceUser') {
      const index = this.selectedItems.findIndex((obj) => obj._id === event.option.value._id);
      if (index === -1) {
        if (event.option.value.type === 'private-list') {
          event.option.value.members.forEach(member => {
            this.selectedItems.push(member);
            this.selectedIds.push(member._id);
          });
        } else {
          this.selectedItems.push(event.option.value);
          this.selectedIds.push(event.option.value._id);
        }
      } else {
        this.duplicate = true;
      }
    } else {
      this.selectedItems.push(event.option.value);
      this.selectedIds.push(event.option.value._id);
    }

    this.input.nativeElement.value = '';
    this.formCtrl.setValue(null);
    this.selectedItemsEvent.emit({ selectedItems: this.selectedItems });
  }

  // private _filter(allList, value) {
  //   // if(typeof(value) === 'object') {

  //   const filterValue = ((typeof (value) === 'object') ? (value.name || value.firstName) : value).toLowerCase();
  //   // const filterValue = (value.name || value.firstName || value ).toLowerCase();
  //   const filteredList = allList.filter(item =>
  //     (((item.name || '').toLowerCase().indexOf(filterValue) === 0) ||
  //       ((item.firstName || '').toLowerCase().indexOf(filterValue) === 0) ||
  //       ((item.middleName || '').toLowerCase().indexOf(filterValue) === 0) ||
  //       ((item.lastName || '').toLowerCase().indexOf(filterValue) === 0) ||
  //       ((item.email || '').toLowerCase().indexOf(filterValue) === 0)));
  //   return filteredList;
  //   // }
  // }

  checkForDuplicate() {
    // used this because  combine latest is checking every second
    if (this.duplicate) {
      this.duplicate = false;
    }
  }

  onFocus() {
    this.filteredList = [];
    this.noResultMsg = false
  }
  focusOut() {
    this.selectedItemsEvent.emit({ selectedItems: this.selectedItems });
  }
  ngOnDestroy() {
    this.stopper$.next();
    this.stopper$.complete();
    this.store.dispatch(new ClearShareSuggestions())

  }
}
