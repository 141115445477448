import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { API } from 'src/app/services/Api.service';

@Component({
  selector: 'app-profile-tooltip',
  templateUrl: './profile-tooltip.component.html',
  styleUrls: ['./profile-tooltip.component.scss']
})
export class ProfileTooltipComponent implements OnInit {

  @Input() user: any;
  groupUsers: any;
  groupDetails: any;
  showProfileComponent: boolean = false;
  @Input() type: string;
  @ViewChild('profileTooltipWrappper', { static: false }) tooltipWrapper: ElementRef;
  yPos: number;
  xPos: number;
  tooltipClass: any = 'bottom-position';
  constructor(
    private store: Store<AppState>,
    private api: API,
  ) { }

  ngOnInit() {
    console.log(this.user)
    if (this.user) {
      if (((this.type !== 'message') && (this.type !== 'toMessage') && (this.type !== 'groupDetails'))) {
        this.tooltipClass = 'position-top'
        if (this.user.type === 'group') {
          this.api.getGroupDetails(this.user.id).subscribe((data) => this.groupDetails = data);
        }
      }
    }
  }

  showProfile(event) {
    /* if((this.user || {}).role) {
      this.user.dispRole = (this.user.role.length) ? this.user.role.join(', ') : '';
    } */
    if (this.type == 'message' || this.type == 'toMessage' || this.type == 'groupDetails') {
      this.tooltipClass = 'position-top'
    } else {
      if (event.clientY + 70 > 300) {
        this.tooltipClass = ' top-position'
      } else {
        this.tooltipClass = 'bottom-position'
      }
    }
    this.showProfileComponent = true;
  }

  hideProfile() {
    this.showProfileComponent = false;
  }
}
