import { Action } from '@ngrx/store';

export enum ConfigurationsActionTypes {
    GET_CONFIGURATIONS = '[config] Get Configurations',
    GET_CONFIGURATIONS_SUCCESS = '[config] Get Configurations Success',
    UPDATE_CONFIGURATIONS = '[config] Update Configurations',
    UPDATE_CONFIGURATIONS_SUCCESS = '[config] Update Configurations Success'
}

export class GetConfigurations implements Action {
    readonly type: string = ConfigurationsActionTypes.GET_CONFIGURATIONS;
    constructor() { }
}

export class GetConfigurationsSuccess implements Action {
    readonly type: string = ConfigurationsActionTypes.GET_CONFIGURATIONS_SUCCESS;
    constructor(public configList: any) {}
}

export class UpdateConfigurations implements Action {
    readonly type: string = ConfigurationsActionTypes.UPDATE_CONFIGURATIONS;
    constructor(public configObj: any) { }
}

export class UpdateConfigurationsSuccess implements Action {
    readonly type: string = ConfigurationsActionTypes.UPDATE_CONFIGURATIONS_SUCCESS;
    constructor() { }
}

