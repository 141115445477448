import { Component, OnInit, Input } from '@angular/core';
import { API } from 'src/app/services/Api.service';

@Component({
  selector: 'app-test-component',
  templateUrl: './test-component.component.html',
  styleUrls: ['./test-component.component.scss']
})
export class TestComponentComponent implements OnInit {

  @Input() user: any;
  groupUsers: any;
  groupDetails: any;
  showProfileComponent: boolean = false;
  @Input() type: string;
  tooltipClass: string;
  constructor( 
    private api: API,) { }

  ngOnInit() {
    console.log(this.user)
    if (this.user) {
      if (((this.type !== 'message') && (this.type !== 'toMessage') && (this.type !== 'groupDetails'))) {
        this.tooltipClass = 'position-top'
        if (this.user.type === 'group') {
          this.api.getGroupDetails(this.user.id).subscribe((data) => this.groupDetails = data);
        }
      }
    }
  }

}
