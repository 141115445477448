import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class newMessageTagService {

    private messageTags = new BehaviorSubject([]);
    currentTags = this.messageTags.asObservable();

    constructor() { }
    newTagsList(tags) {
        this.messageTags.next(tags)
    }

}