import { Action } from '@ngrx/store';
import { GetProjectsSucess, MonitoringEvaluationActionTypes, GetIndicatorDetailsSuccess, GetListDetailsSuccess, GetIndicatorActivityLogSuccess, GetInnovationDetailsSuccess, RolesAndResponsibilitesSuccess, GetInstitutionalCoordinationSuccess, GetSingleInstitutionalCoordinationSuccess, GetPhysicalProgressSuccess, GetFinancialProgressSuccess, GetSingleFinancialProgressSuccess, GetSinglePhysicalProgressSuccess, GetMajorEventsIndicatorsSuccess, GetQuartersSuccess, GetMonitoringTypesSuccess, GetCircumstancesActionsSuccess, GetSingleCircumstancesSucess, GetCapacityBuildingSuccess, GetPeerExchangeSuccess, GetCommunicationOutreachSuccess, GetQuestionsJsonSuccess, GetSingleProjectDataManagementSuccess, GetSingleRoleandRespSuccess, GetOtherObservationsSuccess, GetCapacityBuildingDetailSuccess, GetCommunicationOutreachDetailSuccess, GetPeerExchangeDetailSuccess, GetOverViewPLFOneSuccess, GetOverViewPLFTwoSuccess, GetOverViewDetailsSuccess, IndicatorOperationsSuccess, BlukIndicatorSuccess, GetInnovationActivityLogSuccess, GetBiAnnualOverViewDetailsSuccess } from './monitoring-evaluation.actions';
export interface MonitoringEvaluation {
    projects: any;
    listDetails: Array<any>;
    indicatorDetails: Array<any>;
    activityLogDetails: Array<any>;
    innovationDetails: Array<any>;
    role_responsibilites: Array<any>;
    institutationalCoordiation: Array<any>;
    projDataManagement: Array<any>;
    singleInstitutionalCoordination: Array<any>;
    physicalProgress: Array<any>;
    financialProgress: Array<any>;
    singleFinancialProgress: Array<any>;
    singlePhysicalProgress: Array<any>;
    majorEventIndicators: Array<any>;
    getQuarters: Array<any>;
    monitoringTypes: Array<any>;
    circumstancesActions: Array<any>;
    circumstanceRecord: Array<any>;
    capacityBuilding: Array<any>;
    peerExchange: Array<any>;
    communicationOutreach: Array<any>;
    json_questions: Array<any>;
    singleProjectDataManagement: Array<any>
    singleRoleResp: Array<any>,
    otherObservations: any,
    capacityBuildingDetail: any;
    peerExchangeDetail: any;
    communicationOutreachDetail: any;
    plf1: any;
    plf2: any;
    overviewDetails: any;
    biAnnualQuarters: any;
    operations: any;
    bioverviewDetails:any;
}
const intiState = {
    projects: {
        projectList: [],
        details: {},
    },
    listDetails: [],
    indicatorDetails: [],
    activityLogDetails: [],
    innovationDetails: [],
    role_responsibilites: [],
    institutationalCoordiation: [],
    projDataManagement: [],
    singleInstitutionalCoordination: [],
    physicalProgress: [],
    financialProgress: [],
    singlePhysicalProgress: [],
    singleFinancialProgress: [],
    majorEventIndicators: [],
    getQuarters: [],
    monitoringTypes: [],
    circumstancesActions: [],
    circumstanceRecord: [],
    capacityBuilding: [],
    peerExchange: [],
    communicationOutreach: [],
    json_questions: [],
    singleProjectDataManagement: [],
    singleRoleResp: [],
    otherObservations: {},
    capacityBuildingDetail: {},
    peerExchangeDetail: {},
    communicationOutreachDetail: {},
    plf1: [],
    plf2: [],
    overviewDetails: [],
    biAnnualQuarters: [],
    operations: {},
    innovationActivityLogDetails: [],
    bioverviewDetails:[]
}
export function reducer(state: MonitoringEvaluation = intiState, action: Action): MonitoringEvaluation {
    switch (action.type) {
        case MonitoringEvaluationActionTypes.GET_PROJECTS_SUCCESS:
            let projectList = (action as GetProjectsSucess).response.docs;
            let projectPageNum = (action as GetProjectsSucess).response.page;
            if (projectPageNum == 1) {
                state.projects.projectList = [];
            }
            return Object.assign({}, state, {
                projects: {
                    projectList: [...projectList],
                    details: (action as GetProjectsSucess).response
                }
            });
        case MonitoringEvaluationActionTypes.GET_LIST_DETAILS_SUCCESS:
            console.log((action as GetListDetailsSuccess).response);
            return {
                ...state,
                listDetails: (action as GetListDetailsSuccess).response
            }
        case MonitoringEvaluationActionTypes.GET_INDICATOR_DETAILS_SUCCESS:
            return {
                ...state,
                indicatorDetails: (action as GetIndicatorDetailsSuccess).response
            }
        case MonitoringEvaluationActionTypes.ADD_NEW_VALUE_SUCCESS:
            return {
                ...state,
            }
        case MonitoringEvaluationActionTypes.INDICATOR_ACTIVITY_SUCCESS:
            return {
                ...state,
                activityLogDetails: (action as GetIndicatorActivityLogSuccess).response
            }
        case MonitoringEvaluationActionTypes.REMOVE_INDICATOR_SUCCESS:
            return {
                ...state,
                // activityLogDetails: (action as GetIndicatorActivityLogSuccess).response
            }
        case MonitoringEvaluationActionTypes.GET_INNOVATION_DETAILS_SUCCESS:
            return {
                ...state,
                innovationDetails: (action as GetInnovationDetailsSuccess).response
            }
        case MonitoringEvaluationActionTypes.UPDATE_INNOVATION_MATRIX_SUCCESS:
            return {
                ...state,
                // activityLogDetails: (action as GetIndicatorActivityLogSuccess).response
            }
        case MonitoringEvaluationActionTypes.INDICATOR_OPERATIONS_SUCCESS:
            return {
                ...state,
            }
        case MonitoringEvaluationActionTypes.ROLES_AND_RESPONSIBILITES_SUCCESS:
            return {
                ...state,
                role_responsibilites: (action as RolesAndResponsibilitesSuccess).response
            }
        case MonitoringEvaluationActionTypes.CREATE_ROLES_RESPONSIBILITES_SUCCESS:
            return {
                ...state,
            }
        case MonitoringEvaluationActionTypes.GET_INSTITUTIONAL_COORDINATION_SUCCESS:
            return {
                ...state,
                institutationalCoordiation: (action as GetInstitutionalCoordinationSuccess).response
            }
        case MonitoringEvaluationActionTypes.CREATE_INSTITUTAINAL_COORDINATION_SUCCESS:
            return {
                ...state,
            }
        case MonitoringEvaluationActionTypes.GET_PROJECT_DATA_MANAGEMENT_SUCCESS:
            return {
                ...state,
                projDataManagement: (action as GetInstitutionalCoordinationSuccess).response
            }
        case MonitoringEvaluationActionTypes.CREATE_PROJECT_DATA_MANAGEMENTN_SUCCESS:
            return {
                ...state,
            }
        case MonitoringEvaluationActionTypes.GET_SINGLE_PROJECT_DATA_MANAGEMENT_SUCCESS:
            return {
                ...state,
                singleProjectDataManagement: (action as GetSingleProjectDataManagementSuccess).response
            }
        case MonitoringEvaluationActionTypes.GET_SINGLE_INSTITUTANAL_COORDINATION_SUCCESS:
            return {
                ...state,
                singleInstitutionalCoordination: (action as GetSingleInstitutionalCoordinationSuccess).response
            }

        case MonitoringEvaluationActionTypes.GET_PHYSICAL_PROGRESS_SUCCESS:
            return {
                ...state,
                physicalProgress: (action as GetPhysicalProgressSuccess).response
            }

        case MonitoringEvaluationActionTypes.CREATE_PHYSICAL_PROGRESS_SUCCESS:
            return {
                ...state,
            }
        case MonitoringEvaluationActionTypes.GET_FINANCIAL_PROGRESS_SUCCESS:
            return {
                ...state,
                financialProgress: (action as GetFinancialProgressSuccess).response
            }

        case MonitoringEvaluationActionTypes.CREATE_FINANCAIL_PROGRESS_SUCCESS:
            return {
                ...state,
            }
        case MonitoringEvaluationActionTypes.GET_SINGLE_FINANCIAL_PROGRESS_SUCCESS:
            return {
                ...state,
                singleFinancialProgress: (action as GetSingleFinancialProgressSuccess).response
            }
        case MonitoringEvaluationActionTypes.GET_SINGLE_PHYSICAL_PROGRESS_SUCCESS:
            return {
                ...state,
                singlePhysicalProgress: (action as GetSinglePhysicalProgressSuccess).response
            }
        case MonitoringEvaluationActionTypes.GET_MAJOR_EVENT_INDICATORS_SUCCESS:
            return {
                ...state,
                majorEventIndicators: (action as GetMajorEventsIndicatorsSuccess).response
            }
        case MonitoringEvaluationActionTypes.GET_QUARTERS_SUCCESS:
            if ((action as GetQuartersSuccess).quarter_type == 'quarter') {
                return {
                    ...state,
                    getQuarters: (action as GetQuartersSuccess).response
                }
            } else {
                return {
                    ...state,
                    biAnnualQuarters: (action as GetQuartersSuccess).response
                }
            }
        case MonitoringEvaluationActionTypes.GET_MONITORING_TYPES_SUCCESS:
            return {
                ...state,
                monitoringTypes: { ...state.monitoringTypes, ...(action as GetMonitoringTypesSuccess).response }
            }
        case MonitoringEvaluationActionTypes.GET_CIRCUMSTANCES_SUCCESS:
            return {
                ...state,
                circumstancesActions: (action as GetCircumstancesActionsSuccess).response
            }
        case MonitoringEvaluationActionTypes.CREATE_CIRCUMSTANCES_SUCCESS:
            return {
                ...state,
            }
        case MonitoringEvaluationActionTypes.GET_SINGLE_CIRCUMSTANCES_SUCCESS:
            return {
                ...state,
                circumstanceRecord: (action as GetSingleCircumstancesSucess).response
            }
        case MonitoringEvaluationActionTypes.GET_CAPACITY_BUILDING_SUCCESS:
            return {
                ...state,
                capacityBuilding: (action as GetCapacityBuildingSuccess).response
            }
        case MonitoringEvaluationActionTypes.GET_PEER_EXCHANGE_SUCCESS:
            return {
                ...state,
                peerExchange: (action as GetPeerExchangeSuccess).response
            }
        case MonitoringEvaluationActionTypes.GET_COMMUNICATION_OUTREACH_SUCCESS:
            return {
                ...state,
                communicationOutreach: (action as GetCommunicationOutreachSuccess).response
            }
        case MonitoringEvaluationActionTypes.GET_QUESTIONS_JSON_SUCCESS:
            return {
                ...state,
                json_questions: (action as GetQuestionsJsonSuccess).response
            }
        case MonitoringEvaluationActionTypes.SEND_APPROVAL_SUCCESS:
            return {
                ...state,
            }
        case MonitoringEvaluationActionTypes.GET_SINGLE_ROLE_RESP_SUCCESS:
            return {
                ...state,
                singleRoleResp: (action as GetSingleRoleandRespSuccess).response
            }
        case MonitoringEvaluationActionTypes.GET_OTHER_OBSERVATIONS_SUCCESS:
            return {
                ...state,
                otherObservations: (action as GetOtherObservationsSuccess).response
            }
        case MonitoringEvaluationActionTypes.UPDATE_MAJOR_EVENTS:
            return {
                ...state,
            }
        case MonitoringEvaluationActionTypes.GET_CAPACITY_BUILDING_DETAIL_SUCCESS:
            console.log((action as GetCapacityBuildingDetailSuccess).response)
            return {
                ...state,
                capacityBuildingDetail: (action as GetCapacityBuildingDetailSuccess).response
            }
        case MonitoringEvaluationActionTypes.GET_PEER_EXCHANGE_DETAIL_SUCCESS:
            return {
                ...state,
                peerExchangeDetail: (action as GetPeerExchangeDetailSuccess).response
            }
        case MonitoringEvaluationActionTypes.GET_COMMUNICATION_OUTREACH_DETAIL_SUCCESS:
            return {
                ...state,
                communicationOutreachDetail: (action as GetCommunicationOutreachDetailSuccess).response
            }
        case MonitoringEvaluationActionTypes.GET_OVER_VIEW_PLF_ONE_SUCCESS:
            return {
                ...state,
                plf1: (action as GetOverViewPLFOneSuccess).response
            }
        case MonitoringEvaluationActionTypes.GET_OVER_VIEW_PLF_TWO_SUCCESS:
            return {
                ...state,
                plf2: (action as GetOverViewPLFTwoSuccess).response
            }
        case MonitoringEvaluationActionTypes.GET_OVER_VIEW_DETAILS_SUCCESS:
            // if ((action as GetOverViewDetailsSuccess).response) {
                return {
                    ...state,
                    overviewDetails: (action as GetOverViewDetailsSuccess).response
                }
            // } 
        case MonitoringEvaluationActionTypes.GET_BI_ANNUAL_OVER_VIEW_DETAILS_SUCCESS:
                return {
                    ...state,
                    bioverviewDetails: (action as GetBiAnnualOverViewDetailsSuccess).response
                }
        case MonitoringEvaluationActionTypes.INDICATOR_OPERATIONS_SUCCESS:
            return {
                ...state,
                operations: (action as IndicatorOperationsSuccess).response
            }
        case MonitoringEvaluationActionTypes.BULK_INDICATOR_SUCCESS:
            return {
                ...state
            }
        case MonitoringEvaluationActionTypes.INNOVATION_ACTIVITY_SUCCESS:
            return {
                ...state,
                activityLogDetails: (action as GetInnovationActivityLogSuccess).response
            }
        default:
            return {
                ...state
            }
    }
}