import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatOption, MatSelect } from "@angular/material";
import { FormBuilder, FormControl, FormGroup, Validators, NgModel } from '@angular/forms';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { CreateFolder, GetFolderDetails } from '../_store/resources.actions';
import { ValidationUtil, VALIDATION_MESSAGES } from "../../../../utils/validation.util";
import { isConstructorDeclaration } from 'typescript';
import { GetRoles } from '../../user-management/store/user-managment.actions';

@Component({
  selector: 'app-create-folder',
  templateUrl: './create-folder.component.html',
  styleUrls: ['./create-folder.component.scss']
})
export class CreateFolderComponent implements OnInit {

  @ViewChild('select',{static:false}) select: MatSelect;

  createFolderForm: FormGroup;
  validationMsg = VALIDATION_MESSAGES;
  roles = []
  allSelected=false;
  isOpened: Boolean =false;
  constructor(
    private dialogRef: MatDialogRef<any>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private store: Store<AppState>,
    private fb: FormBuilder,
  ) {
    this.dialogRef.disableClose = true;
    this.store.dispatch(new GetRoles());
  }

  ngOnInit() {
    this.createFolderForm = this.fb.group({
      name: ["", Validators.compose([
        Validators.required,
        ValidationUtil.cannotBeBlank,
      ])]
    });
    this.store.select(state => state.userManagment.roles).pipe().subscribe(response => {
      if (response.status) {
        this.roles = response.roles
        // const categories = response.roles.map(role => role.category);
        // this.categories = Array.from(new Set(categories));

        // if (typeof this.roleDetails.category != "undefined") {
        //   this.selectedCategory = this.roleDetails.category;
        // }
      }
    });

    if(this.data.isEditRoles){
      this.store.dispatch(new GetFolderDetails(this.data.folderIdParam));
      (this.createFolderForm as FormGroup).addControl("roles", new FormControl(""));
      this.createFolderForm.get('roles').setValidators([Validators.required])
      // (this.createFolderForm as FormGroup).addControl("roles", new FormControl(""));
      // this.createFolderForm.get('roles').setValidators([Validators.required])
    }

    if (this.data.type === "citiis" && !this.data['folderIdParam']) {
      (this.createFolderForm as FormGroup).addControl("roles", new FormControl(""));
      this.createFolderForm.get('roles').setValidators([Validators.required])
      if(this.data.isEditRoles){
      }
    }

    this.store.select(state => state.resources.folderDetails).pipe().subscribe(response => {
      if(response && this.data.type === "citiis" && this.data.isEditRoles){
        this.createFolderForm.get('name').setValue(response.name)
        this.createFolderForm.get('roles').setValue(response.roles)
        this.createFolderForm.controls['name'].disable();

      }      
    });
    
    
    
    
  }

  showName(role) {
    let obj = this.roles.filter(item => item.role === role)[0]
    return obj.roleName
  }

  selectAll(checkAll, select: NgModel, values) {
    //this.toCheck = !this.toCheck;
    if(checkAll){
      select.update.emit(values); 
    }
    else{
      select.update.emit([]);
    }
  }

  testClick(isOpened){
    this.isOpened = isOpened;
    console.log(isOpened);
  }

  toggleAllSelection() {
    if (this.allSelected) {
      this.select.options.forEach((item: MatOption) => item.select());
    } else {
      this.select.options.forEach((item: MatOption) => item.deselect());
    }
  }
   optionClick() {
    let newStatus = true;
    this.select.options.forEach((item: MatOption) => {
      if (!item.selected) {
        newStatus = false;
      }
    });
    this.allSelected = newStatus;
  }

  createFolder() {
    if (this.createFolderForm.invalid) {
      return false;
    }
    if (this.data.type === 'my') {
      const parentFolderId = this.data['folderIdParam'];
      const folderName = this.createFolderForm.value.name.trim();
      const payload = {
        parentId: parentFolderId,
        name: folderName,
        isSystemFolder: false
      }
      this.store.dispatch(new CreateFolder(payload));
      this.dialogRef.close();
    }

    else if (this.data.type === 'citiis') {
      const parentFolderId = this.data['folderIdParam'];
      const folderName = this.createFolderForm.get('name').value.trim();
      const roles = this.createFolderForm.value.roles;
      const payload = {
        parentId: this.data.folderIdParam,
        name: folderName,
        roles: roles,
        isSystemFolder: true
      }
      if(this.data.isEditRoles){
        this.store.dispatch(new CreateFolder(payload,true));
      }else{
      this.store.dispatch(new CreateFolder(payload));
    }
      this.dialogRef.close();
    }

  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  onRoleRemoved(role: string) {
    console.log(role)
    const roles = this.createFolderForm.value.roles as string[];
    this.removeFirst(roles, role);
    this.createFolderForm.controls.roles.setValue(roles); // To trigger change detection
  }

  private removeFirst<T>(array: T[], toRemove: T): void {
    const index = array.indexOf(toRemove);
    if (index !== -1) {
      array.splice(index, 1);
    }
  }
}
