import { Component, EventEmitter, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { Observable, of, Subject } from 'rxjs';
import { takeUntil, tap, map, debounceTime, catchError } from 'rxjs/operators';
import { AppState } from 'src/app/app.reducer';
// import { GetAllContracts, GetMyContracts, DownloadContractsExcel } from '../store/contract-management.actions';
import { ClearViewAccess, CheckViewAccessGorDocStart } from '../../home/user-messages/_store/messages.actions';
import { GetUserDetails } from '../../home/user-management/store/user-managment.actions';
import { FormBuilder, FormGroup } from '@angular/forms';
import { saveAs } from "file-saver";
import { HttpClient } from '@angular/common/http';
// import { BASE_URL } from '../contract-management.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { MatPaginator } from '@angular/material/paginator';
import { PAGE_LIMIT } from 'src/app/app.constants';
import * as FileSaver from 'file-saver';
import { NotificationsService } from 'angular2-notifications';
import { ErrorAction } from 'src/app/login/store/auth.actions';

@Component({
  selector: "app-entity-list",
  templateUrl: "./entity-list.component.html",
  styleUrls: ["./entity-list.component.scss"],
})
export class EntityListComponent implements OnInit {
  stopper: EventEmitter<any> = new EventEmitter();
  type: any;
  contracts$: Observable<any>;
  tableIds = ['contNo', 'name', 'owner', 'startDate', 'endDate', 'value', 'status'];
  allContractsList: [];
  activeTab: any = 'all';
  form: FormGroup;
  searchontract: any;
  stopper$: Subject<any> = new Subject();
  isAccessAllContracts: boolean;
  @ViewChild('paginator', { static: false })
  paginator: MatPaginator;

  @ViewChild('taskTable', { static: false })
  taskTable: any;

  PageNumber: number = 1;

  pageSize = 30;

  limitPerPageOptions;
  pageLimit = '30';
  count: any;
  constructor(
      private activatedRoute: ActivatedRoute,
      private router: Router,
      private store: Store<AppState>,
      private fb: FormBuilder,
      private http: HttpClient,
      private ngxPermissionService: NgxPermissionsService,
      private notificationService: NotificationsService,

  ) {
      this.form = this.fb.group({
          searchontract: [''],
      })
      this.isAccessAllContracts = Object.keys(this.ngxPermissionService.getPermissions()).includes('all-contracts');
      if (this.isAccessAllContracts == false) {
          console.log("routed")
          this.router.navigate(['/home/contract-management/contracts/my']);
      }
      this.activatedRoute.params.pipe(takeUntil(this.stopper)).subscribe(params => {
          this.type = params.type;
          console.log(this.type)
          if (this.type == 'all' && !this.isAccessAllContracts) {
              this.type = 'my';
              this.router.navigate(['/home/contract-management/contracts/my']);
          }
          if (this.type == 'all') {
              // this.form.get('searchontract').setValue('');
              // this.store.dispatch(new GetAllContracts(this.form.value.searchontract, this.PageNumber, this.pageLimit));
          } else {
              this.activeTab = 'my';
              // this.form.get('searchontract').setValue('');
              // this.store.dispatch(new GetMyContracts(this.form.value.searchontract, this.PageNumber, this.pageLimit));
          }
          this.contracts$ = (this.type === 'all') ?
              this.store.select(state => state.contractManagement.allContracts).pipe(map((list: any) => {
                  this.PageNumber = list.page;
                  this.count = list.total;
                  return list.docs;
              })) :
              this.store.select(state => state.contractManagement.myContracts).pipe(map((list: any) => {
                  this.PageNumber = list.page;
                  this.count = list.total;
                  return list.docs;
              }));
      });

  }
  ngOnInit() {
      this.limitPerPageOptions = PAGE_LIMIT;

      // this.form.get('searchontract').valueChanges.subscribe(string => {
      //     console.log(this.type);
      //     // console.log(this.isAccessAllContracts);
      //     if (this.type == 'all')
      //         this.store.dispatch(new GetAllContracts(string, 1, this.pageLimit));
      //     else
      //         this.store.dispatch(new GetMyContracts(string, 1, this.pageLimit));
      // });

  }


  onChange(event) {
      this.pageSize = event.value;
      this.PageNumber = 1;
      // this.pageLimit = ;
      if (this.type == 'all') {
          // this.store.dispatch(new GetAllContracts(this.form.value.searchontract, 1, event.value));
      } else {
          // this.store.dispatch(new GetMyContracts(this.form.value.searchontract, 1, event.value));
      }
  }

  getServerData(event: any) {
      // console.log(event)
      {
          const params = {
              page: event,
              limit: this.pageSize,
          };
          if (this.type == 'all') {
              // this.store.dispatch(new GetAllContracts(this.form.value.searchontract, params.page, params.limit));
          } else {
              // this.store.dispatch(new GetMyContracts(this.form.value.searchontract, params.page, params.limit));
          }
      }
      // this.store.dispatch(new LoaderShow());

  }
  downloadList() {
      // this.http.get(`${BASE_URL}/contract/v1/contract/download-all-contracts`, { responseType: 'blob' })
      //     .subscribe((res: any) => {
      //         const blob = new Blob([res], { type: 'application/vnd.ms-excel;charset=utf-8' });
      //         FileSaver.saveAs(blob, "ContractList.xls");
      //     }), (error) => {
      //         console.log(error)
      //         let message = error.errors[0].error
      //         this.notificationService.error(message);
      //     }
  }

  isAllContract() {
      if (this.allContractsList != undefined) {
          if (this.allContractsList.length > 0) {
              // this.allContractsList.map(element => {
              //     this.store.dispatch(new GetUserDetails(element.owner))         
              // });
              return true;
          } else {
              return false;
          }
      } else {
          return false;
      }
  }

  // onContractClick(contract) {
  //     console.log(contract);
  // }

  onContractClick(a, isExternalLink = false) {
      if (isExternalLink) {
          window.open(window.location.origin + '/home/contract-management/' + a, '_blank');
      } else {
          if (Object.keys(a).length) {
              this.router.navigate(['/home/contract-management/' + `${a.id || a._id}`]);
          }
      }
  }

  onSearchList() {
  }

  getStatus(status){
      if(status == 'new'){
          return 'Pending'
      }else if(status == 'completed'){
          return 'Contract Period Completed'
      }else{
          return status
      }
  }
}

