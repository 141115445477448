import { Action } from "@ngrx/store";

export enum ProcurementActionTypes {
  FETCH_CONTRACT_ACTIVITY_LIST = "[Contract List] get contract list",
  FETCH_CONTRACT_ACTIVITY_LIST_SUCCESS = "[Contract List] get contract list Success",
  GENERATE_PROCUREMENT_ID = "[Generate Procurement Id] Generation Details",
  GENERATE_PROCUREMENT_ID_SUCCESS = "[Generate Procurement Id] Generation Details Success",
  ADD_PROCUREMENT_UNIT = "[ Procurement Unit] Add Procurement Unit",
  ADD_PROCUREMENT_UNIT_SUCCESS = "[ Procurement Unit] Add Procurement Unit Success",
  DELETE_PROCUREMENT_UNIT = "[ Procurement Unit] Delete Procurement Unit",
  DELETE_PROCUREMENT_UNIT_SUCCESS = "[ Procurement Unit] Delete Procurement Unit Success",
  UPDATE_PROCUREMENT_UNIT = "[ Procurement Unit] Update Procurement Unit",
  UPDATE_PROCUREMENT_UNIT_SUCCESS = "[ Procurement Unit] Update Procurement Unit Success",
  GET_PROCUREMENT_DOCUMENT_LIST = "[ procurement Document] Get Procurement Document List",
  GET_PROCUREMENT_DOCUMENT_LIST_SUCCESS = "[ procurement Document] Get Procurement Document List Success",
  APPROVE_FOR_DOCUMENT_PROCUREMENT_DOCUMENT = "[Approve Document] submit for Approval",
  APPROVE_FOR_DOCUMENT_PROCUREMENT_DOCUMENT_SUCCESS = "[Approve Document] submit for Approval Success",
  PROCUREMENT_MEMBERLIST = "[Member List] procurement member list",
  PROCUREMENT_MEMBERLIST_SUCCESS = "[Member List] procurement member list Success",
  ADD_APPROVER = "[Member List] add approver Member List",
  ADD_APPROVER_SUCCESS = "[Member List] add approver Member List Success",
  APPROVE_REJECT = "[Approve Reject] approval",
  APPROVE_REJECT_SUCCESS = "[Approve Reject] approval Success",
  ADDED_MEMBER = "[Member List] added Member List ",
  ADDED_MEMBER_SUCCESS = "[Member List] added Member List Success",
  SUBMIT_FOR_APPROVE_PROCUREMENT_MILESTONE = "[Approvar Milestone] Submit for Approval Milestone",
  SUBMIT_FOR_APPROVE_PROCUREMENT_MILESTONE_SUCCESS = "[Approvar Milestone] Submit for Approval Milestone Success",
  GET_ENTITY_LIST = "[procurement] Get Entity List",
  GET_ENTITY_LIST_SUCCESS = "[procurement] Get Entity List Success",
  GET_ENTITY_AND_PROJECT = "[procurement selection] get entity and project List",
  GET_ENTITY_AND_PROJECT_SUCCESS = "[procurement selection] get entity and project List Success",
  GET_PROJECT_LIST = "[procurement project] Get Project List",
  GET_PROJECT_LIST_SUCCESS = "[procurement project] Get Project List Success",
  GET_PROJECT_LIST_BY_ENT_ID = "[procurement project List] Get Project List by Id",
  GET_PROJECT_LIST_BY_ENT_ID_SUCCESS = "[procurement project List] Get Project List by Id Success",
  PROCUREMENT_UNIT_SUBMIT = "[Procurement Unit] Procurement Unit Details Submission",
  PROCUREMENT_UNIT_SUBMIT_SUCCESS = "[Procurement Unit] Procurement Unit Details Submission Success",
  ADD_PROCUREMENT_DOCUMENT = "[Add procurement Document] Add",
  ADD_PROCUREMENT_DOCUMENT_SUCCESS = "[Add procurement Document] Add Success",
  UPDATE_PROCUREMENT_DOCUMENT = "[UPDATE procurement Document] UPDATE",
  UPDATE_PROCUREMENT_DOCUMENT_SUCCESS = "[UPDATE procurement Document] UPDATE Success",
  DELETE_PROCUREMENT_DOCUMENT = "[Delete Document] Delete Procurement Document ",
  DELETE_PROCUREMENT_DOCUMENT_SUCCESS = "[Delete Document] Delete Procurement Document Success",
  GET_BUDGET = "[Budget] Get Budget List",
  GET_BUDGET_SUCCESS = "[Budget] Get Budget List Success",
  DELETE_BUDGET = "[Budget] delete Budget",
  DELETE_BUDGET_SUCCESS = "[Budget] delete Budget Success",
  ADD_BUDGET = "[Budget] add Budget",
  ADD_BUDGET_SUCCESS = "[Budget] add Budget Success",
  UPDATE_BUDGET = "[Budget] Update Budget",
  UPDATE_BUDGET_SUCCESS = "[Budget] Update Budget Success",
  GET_CLOUSER_DOCUMENT = "[Clouser Document]  Get Clouser Document List",
  GET_CLOUSER_DOCUMENT_SUCCESS = "[Clouser Document] Get Clouser Document List Success",
  ADD_CLOUSER_INFORMATION = "[clouser Information] Add clouser information ",
  ADD_CLOUSER_INFORMATION_SUCCESS = "[clouser Information] Add clouser information Success",
  UPDATE_CLOUSER_INFORMATION = "[clouser Information] update clouser information",
  UPDATE_CLOUSER_INFORMATION_SUCCESS = "[clouser Information] update clouser information Success",
  DELETE_CLOUSER_INFO = "[clouser Information ] delete clouser Information ",
  DELETE_CLOUSER_INFO_SUCCESS = "[clouser Information ] delete clouser Information Success ",
  GET_DOCUMENT_OF_PROCUREMENT_DOCS = "[ Document] Get Document Of Procurement Docs",
  // GET_DOCUMENT_OF_PROCUREMENT_DOCS_SUCCESS = "[Procurement Document] Get Document Of Procurement Docs Success",
  GET_DOCUMENT_OF_CLOUSER_INFO = "[ Document] Get Document Of clouser Information",
  GET_DOCUMENT_SUCCESS = "[ Document ] Get Document Success",
  GET_MILESTONES = "[Milestones] Get Milestones",
  GET_MILESTONES_SUCCESS = "[Milestones] Get Milestones Success",
  DELETE_MILESTONES = "[Milestones] Delete Milestones",
  DELETE_MILESTONES_SUCCESS = "[Milestones] Delete Milestones Success",
  ADD_MILESTONES = "[Milestones] add Milestones",
  ADD_MILESTONES_SUCCESS = "[Milestones] add Milestones Success ",
  UPDATE_MILESTONES = "[Milestones] update Milestones",
  UPDATE_MILESTONES_SUCCESS = "[Milestones] update Milestones Success",
  GET_PROCUREMENT_PAYMENT = "[PROC Payment] Get Procurement Payement",
  GET_PROCUREMENT_PAYMENT_SUCCESS = "[PROC Payment] Get Procurement Payements Success",
  GET_PROCUREMENT_LIST = "[Procurement List] get procurement list",
  GET_PROCUREMENT_LIST_SUCCESS = "[Procurement List] get procurement list Success",
  ADD_COMMENTS = "[comments] add comments",
  ADD_COMMENTS_SUCCESS = "[comments] add comments Success",
  GET_COMMENTS_LIST = "[comments] comments List",
  GET_COMMENTS_LIST_SUCCESS = "[comments] comments List Success",
  SUBMIT_PHYSICAL_PERCENTAGE = "[pyhsical] add pyhsical percentage",
  SUBMIT_PHYSICAL_PERCENTAGE_SUCCESS = "[pyhsical] add pyhsical percentage Success",
  GET_PHYSICAL_PERCENTAGE = "[get physical] get pyhsical percentage",
  GET_PHYSICAL_PERCENTAGE_SUCCESS = "[get physical] get pyhsical percentage Success",


  // New Module

  // pull get packect data 

  CREATE_SINGLE_PACKET = "[Packet] create single packet",
  CREATE_SINGLE_PACKET_SUCCESS = "[Packet] create single packet success",
  UPDATE_SINGLE_PACKET = "[Packet] update single  packet",
  UPDATE_SINGLE_PACKET_SUCCESS = "[Packet] update single packet sucesss",
  DELETE_SINGLE_PACKET = "[Packet] delete single packet",
  DELETE_SINGLE_PACKET_SUCCESS = "[Packet] delete single packet success",
  CLEAR_ACTION = '[Packet] Clear Action',
  PULL_ALL_PACKET = "[Packet] pull all packet ",
  PULL_ALL_PACKET_SUCCESS = "[Packet] pull all packet success",

  CREATE_TENDER = "[Packet] create tender",
  CREATE_TENDER_SUCCESS = "[Packet] create tender Success",
  CLEAR_TENDER_ACTION = '[Packet] Clear tender  Action',
  PULL_ALL_TENDER = "[Packet] all tender list",
  PULL_ALL_TENDER_SUCCESS = "[Packet] all tender list success",
  UPDATE_TENDER = "[Packet] update packet ",
  UPDATE_TENDER_SUCCESS = "[Packet] update packet success",
  DELETE_TENDER = "[Packet] delete packet ",
  DELETE_TENDER_SUCCESS= "[Packet] delete packet success ",


  CREATE_AWARD = "[Packet] create AWARD",
  CREATE_AWARD_SUCCESS = "[Packet] create AWARD Success",
  CLEAR_AWARD_ACTION = '[Packet] Clear AWARD  Action',
  PULL_ALL_AWARD = "[Packet] all AWARD list",
  PULL_ALL_AWARD_SUCCESS = "[Packet] all AWARD list success",
  UPDATE_AWARD = "[Packet] update AWARD ",
  UPDATE_AWARD_SUCCESS = "[Packet] update AWARD success",
  DELETE_AWARD = "[Packet] delete AWARD ",
  DELETE_AWARD_SUCCESS= "[Packet] delete AWARD success ",


}

export class fetchContractActivityList implements Action {
  readonly type: string = ProcurementActionTypes.FETCH_CONTRACT_ACTIVITY_LIST;
  constructor(public payload: any) { }
}

export class fetchContractActivityListSuccess implements Action {
  readonly type: string =
    ProcurementActionTypes.FETCH_CONTRACT_ACTIVITY_LIST_SUCCESS;
  constructor(public response: any) { }
}

export class generateProcurementId implements Action {
  readonly type: string = ProcurementActionTypes.GENERATE_PROCUREMENT_ID;
  constructor(public payload: any) { }
}

export class generateProcurementIdSuccess implements Action {
  readonly type: string =
    ProcurementActionTypes.GENERATE_PROCUREMENT_ID_SUCCESS;
  constructor(public response: any) { }
}

export class addProcurementUnit implements Action {
  readonly type: string = ProcurementActionTypes.ADD_PROCUREMENT_UNIT;
  constructor(public payload: any) { }
}

export class addProcurementUnitSuccess implements Action {
  readonly type: string =
    ProcurementActionTypes.ADD_PROCUREMENT_UNIT_SUCCESS;
  constructor(public response: any) { }
}

export class updateProcurementUnit implements Action {
  readonly type: string = ProcurementActionTypes.UPDATE_PROCUREMENT_UNIT;
  constructor(public payload: any) { }
}

export class updateProcurementUnitSuccess implements Action {
  readonly type: string =
    ProcurementActionTypes.UPDATE_PROCUREMENT_UNIT_SUCCESS;
  constructor(public response: any) { }
}
export class deleteProcurementUnit implements Action {
  readonly type: string = ProcurementActionTypes.DELETE_PROCUREMENT_UNIT;
  constructor(public payload: any) { }
}

export class deleteProcurementUnitSuccess implements Action {
  readonly type: string =
    ProcurementActionTypes.DELETE_PROCUREMENT_UNIT_SUCCESS;
  constructor(public response: any) { }
}

export class getProcureDocumentList implements Action {
  readonly type: string = ProcurementActionTypes.GET_PROCUREMENT_DOCUMENT_LIST;
  constructor(public payload: any) { }
}

export class getProcureDocumentListSuccess implements Action {
  readonly type: string =
    ProcurementActionTypes.GET_PROCUREMENT_DOCUMENT_LIST_SUCCESS;
  constructor(public response: any) { }
}

export class addProcureDocument implements Action {
  readonly type: string = ProcurementActionTypes.ADD_PROCUREMENT_DOCUMENT;
  constructor(public payload: any) { }
}

export class addProcureDocumentSuccess implements Action {
  readonly type: string =
    ProcurementActionTypes.ADD_PROCUREMENT_DOCUMENT_SUCCESS;
  constructor(public response: any) { }
}

export class updateProcureDocument implements Action {
  readonly type: string = ProcurementActionTypes.UPDATE_PROCUREMENT_DOCUMENT;
  constructor(public payload: any) { }
}

export class updateProcureDocumentSuccess implements Action {
  readonly type: string =
    ProcurementActionTypes.UPDATE_PROCUREMENT_DOCUMENT_SUCCESS;
  constructor(public response: any) { }
}


export class deleteProcureDocument implements Action {
  readonly type: string = ProcurementActionTypes.DELETE_PROCUREMENT_DOCUMENT;
  constructor(public Id: any) { }
}

export class deleteProcureDocumentSuccess implements Action {
  readonly type: string =
    ProcurementActionTypes.DELETE_PROCUREMENT_DOCUMENT_SUCCESS;
  constructor(public response: any) { }
}

export class approvalForDocumentProcureDocument implements Action {
  readonly type: string = ProcurementActionTypes.APPROVE_FOR_DOCUMENT_PROCUREMENT_DOCUMENT;
  constructor(public id: any) { }
}

export class approvalForDocumentProcureDocumentSuccess implements Action {
  readonly type: string =
    ProcurementActionTypes.APPROVE_FOR_DOCUMENT_PROCUREMENT_DOCUMENT_SUCCESS;
  constructor(public response: any) { }
}
export class memberList implements Action {
  readonly type: string = ProcurementActionTypes.PROCUREMENT_MEMBERLIST;
  constructor(public id: any) { }
}

export class memberListSuccess implements Action {
  readonly type: string =
    ProcurementActionTypes.PROCUREMENT_MEMBERLIST_SUCCESS;
  constructor(public response: any) { }
}

export class addApprover implements Action {
  readonly type: string = ProcurementActionTypes.ADD_APPROVER;
  constructor(public payload: any) { }
}

export class addApproverSuccess implements Action {
  readonly type: string =
    ProcurementActionTypes.ADD_APPROVER_SUCCESS;
  constructor(public response: any) { }
}

export class approveRejectFile implements Action {
  readonly type: string = ProcurementActionTypes.APPROVE_REJECT;
  constructor(public subSection: any, public id: any, public action: any) { }
}

export class approveRejectFileSuccess implements Action {
  readonly type: string =
    ProcurementActionTypes.APPROVE_REJECT_SUCCESS;
  constructor(public response: any) { }
}

export class addedMemberList implements Action {
  readonly type: string = ProcurementActionTypes.ADDED_MEMBER;
  constructor(public projectId: any) { }
  // public entityID:any,
}

export class addedMemberListSuccess implements Action {
  readonly type: string =
    ProcurementActionTypes.ADDED_MEMBER_SUCCESS;
  constructor(public response: any) { }
}


export class approvalForSubmitProcureMilestone implements Action {
  readonly type: string = ProcurementActionTypes.SUBMIT_FOR_APPROVE_PROCUREMENT_MILESTONE;
  constructor(public id: any, public type1: any) { }
}

export class approvalForSubmitProcureMilestoneSuccess implements Action {
  readonly type: string =
    ProcurementActionTypes.SUBMIT_FOR_APPROVE_PROCUREMENT_MILESTONE_SUCCESS;
  constructor(public response: any) { }
}




















export class GetEnityList implements Action {
  readonly type: string = ProcurementActionTypes.GET_ENTITY_LIST;
  constructor() { }
}

export class GetEnityListSuccess implements Action {
  readonly type: string = ProcurementActionTypes.GET_ENTITY_LIST_SUCCESS;
  constructor(public response: any) { }
}

export class GetEnityAndProject implements Action {
  readonly type: string = ProcurementActionTypes.GET_ENTITY_AND_PROJECT;
  constructor() { }
}

export class GetEnityAndProjectSuccess implements Action {
  readonly type: string = ProcurementActionTypes.GET_ENTITY_AND_PROJECT_SUCCESS;
  constructor(public response: any) { }
}

export class GetProjectList implements Action {
  readonly type: string = ProcurementActionTypes.GET_PROJECT_LIST;
  constructor() { }
}

export class GetProjectListSuccess implements Action {
  readonly type: string = ProcurementActionTypes.GET_PROJECT_LIST_SUCCESS;
  constructor(public response: any) { }
}

export class GetProjectListById implements Action {
  readonly type: string = ProcurementActionTypes.GET_PROJECT_LIST_BY_ENT_ID;
  constructor(public Id: any) { }
}

export class GetProjectListByIdSuccess implements Action {
  readonly type: string =
    ProcurementActionTypes.GET_PROJECT_LIST_BY_ENT_ID_SUCCESS;
  constructor(public response: any) { }
}



export class procurementUnitSubmit implements Action {
  readonly type: string = ProcurementActionTypes.PROCUREMENT_UNIT_SUBMIT;
  constructor(public payload: any) { }
}

export class procurementUnitSubmitResponse implements Action {
  readonly type: string =
    ProcurementActionTypes.PROCUREMENT_UNIT_SUBMIT_SUCCESS;
  constructor(public response: any) { }
}

export class getBudget implements Action {
  readonly type: string = ProcurementActionTypes.GET_BUDGET;
  constructor(public payload: any) { }
}

export class getBudgetSuccess implements Action {
  readonly type: string = ProcurementActionTypes.GET_BUDGET_SUCCESS;
  constructor(public response: any) { }
}

export class deleteBudget implements Action {
  readonly type: string = ProcurementActionTypes.DELETE_BUDGET;
  constructor(public Id: any) { }
}

export class deleteBudgetSuccess implements Action {
  readonly type: string = ProcurementActionTypes.DELETE_BUDGET_SUCCESS;
  constructor(public response: any) { }
}

export class addBudget implements Action {
  readonly type: string = ProcurementActionTypes.ADD_BUDGET;
  constructor(public payload: any) { }
}

export class addBudgetSuccess implements Action {
  readonly type: string = ProcurementActionTypes.ADD_BUDGET_SUCCESS;
  constructor(public response: any) { }
}

export class updateBudget implements Action {
  readonly type: string = ProcurementActionTypes.UPDATE_BUDGET;
  constructor(public payload: any, public Id: any) { }
}

export class updateBudgetSuccess implements Action {
  readonly type: string = ProcurementActionTypes.UPDATE_BUDGET_SUCCESS;
  constructor(public response: any) { }
}

export class getClouserDocument implements Action {
  readonly type: string = ProcurementActionTypes.GET_CLOUSER_DOCUMENT;
  constructor(public payload: any) { }
}

export class getClouserDocumentSuccess implements Action {
  readonly type: string = ProcurementActionTypes.GET_CLOUSER_DOCUMENT_SUCCESS;
  constructor(public response: any) { }
}

export class addClouserInformation implements Action {
  readonly type: string = ProcurementActionTypes.ADD_CLOUSER_INFORMATION;
  constructor(public payload: any) { }
}

export class addClouserInformationSuccess implements Action {
  readonly type: string =
    ProcurementActionTypes.ADD_CLOUSER_INFORMATION_SUCCESS;
  constructor(public response: any) { }
}

export class updateClouserInformation implements Action {
  readonly type: string = ProcurementActionTypes.UPDATE_CLOUSER_INFORMATION;
  constructor(public payload: any, public Id: any) { }
}

export class updateClouserInformationSuccess implements Action {
  readonly type: string =
    ProcurementActionTypes.UPDATE_CLOUSER_INFORMATION_SUCCESS;
  constructor(public response: any) { }
}

export class deleteClouserInfo implements Action {
  readonly type: string = ProcurementActionTypes.DELETE_CLOUSER_INFO;
  constructor(public Id: any) { }
}

export class deleteClouserInfoSuccess implements Action {
  readonly type: string = ProcurementActionTypes.DELETE_CLOUSER_INFO_SUCCESS;
  constructor(public response: any) { }
}

export class getDocOfClouserInfo implements Action {
  readonly type: string = ProcurementActionTypes.GET_DOCUMENT_OF_CLOUSER_INFO;
  constructor(public Id: any) { }
}

export class getProcurementPayment implements Action {
  readonly type: string =
    ProcurementActionTypes.GET_PROCUREMENT_PAYMENT;
  constructor(public payload: any) { }
}

// export class getDocumentSuccess implements Action {
//   readonly type: string =
//     ProcurementActionTypes.GET_DOCUMENT_SUCCESS;
//   constructor(public response: any) {}
// }

export class getProcurementPaymentSuccess implements Action {
  readonly type: string =
    ProcurementActionTypes.GET_PROCUREMENT_PAYMENT_SUCCESS;
  constructor(public response: any) { }
}

export class getMilestones implements Action {
  readonly type: string = ProcurementActionTypes.GET_MILESTONES;
  constructor(public payload: any) { }
}

export class getMilestonesSuccess implements Action {
  readonly type: string = ProcurementActionTypes.GET_MILESTONES_SUCCESS;
  constructor(public response: any) { }
}

export class deleteMilestones implements Action {
  readonly type: string = ProcurementActionTypes.DELETE_MILESTONES;
  constructor(public Id: any) { }
}

export class deleteMilestonesSuccess implements Action {
  readonly type: string = ProcurementActionTypes.DELETE_MILESTONES_SUCCESS;
  constructor(public response: any) { }
}

export class addMilestones implements Action {
  readonly type: string = ProcurementActionTypes.ADD_MILESTONES;
  constructor(public payload: any) { }
}

export class addMilestonesSuccess implements Action {
  readonly type: string = ProcurementActionTypes.ADD_MILESTONES_SUCCESS;
  constructor(public response: any) { }
}

export class updateMilestones implements Action {
  readonly type: string = ProcurementActionTypes.UPDATE_MILESTONES;
  constructor(public payload: any, public Id: any) { }
}

export class updateMilestonesSuccess implements Action {
  readonly type: string = ProcurementActionTypes.UPDATE_MILESTONES_SUCCESS;
  constructor(public response: any) { }
}

export class getProcurementList implements Action {
  readonly type: string = ProcurementActionTypes.GET_PROCUREMENT_LIST;
  constructor(public pro_type: any) { }
}

export class getProcurementListSuccess implements Action {
  readonly type: string = ProcurementActionTypes.GET_PROCUREMENT_LIST_SUCCESS;
  constructor(public response: any) { }
}


export class addComments implements Action {
  readonly type: string = ProcurementActionTypes.ADD_COMMENTS;
  constructor(public payload: any) { }
}

export class addCommentsSuccess implements Action {
  readonly type: string = ProcurementActionTypes.ADD_COMMENTS_SUCCESS;
  constructor(public response: any) { }
}

export class commentsList implements Action {
  readonly type: string = ProcurementActionTypes.GET_COMMENTS_LIST;
  constructor(public id: any) { }
}

export class commentsListSuccess implements Action {
  readonly type: string = ProcurementActionTypes.GET_COMMENTS_LIST_SUCCESS;
  constructor(public response: any) { }
}


export class submitPhysicalpercentage implements Action {
  readonly type: string = ProcurementActionTypes.SUBMIT_PHYSICAL_PERCENTAGE;
  constructor(public payload: any) { }
}

export class submitPhysicalpercentageSuccess implements Action {
  readonly type: string = ProcurementActionTypes.SUBMIT_PHYSICAL_PERCENTAGE_SUCCESS;
  constructor(public response: any) { }
}

export class getPhysicalpercentage implements Action {
  readonly type: string = ProcurementActionTypes.GET_PHYSICAL_PERCENTAGE;
  constructor(public payload: any) { }
}

export class getPhysicalpercentageSuccess implements Action {
  readonly type: string = ProcurementActionTypes.GET_PHYSICAL_PERCENTAGE_SUCCESS;
  constructor(public response: any) { }
}


export class createPacket implements Action {
  readonly type: string = ProcurementActionTypes.CREATE_SINGLE_PACKET;
  constructor(public id: any, public payload: any) { }
}

export class createPacketSuccess implements Action {
  readonly type: string = ProcurementActionTypes.CREATE_SINGLE_PACKET_SUCCESS;
  constructor(public response: any) { }
}

export class deletePacket implements Action {
  readonly type: string = ProcurementActionTypes.DELETE_SINGLE_PACKET;
  constructor(public id: any) { }
}

export class deletePacketSuccess implements Action {
  readonly type: string = ProcurementActionTypes.DELETE_SINGLE_PACKET_SUCCESS;
  constructor(public response: any) { }
}

export class updatePacket implements Action {
  readonly type: string = ProcurementActionTypes.UPDATE_SINGLE_PACKET;
  constructor(public projectId:any,public id: any, public payload: any) { }
}

export class updatePacketSuccess implements Action {
  readonly type: string = ProcurementActionTypes.UPDATE_SINGLE_PACKET_SUCCESS;
  constructor(public response: any) { }
}

export class ClearAction implements Action {
  readonly type: string = ProcurementActionTypes.CLEAR_ACTION;
  constructor() { }
}

export class pullAllPacket implements Action {
  readonly type: string = ProcurementActionTypes.PULL_ALL_PACKET;
  constructor(public id: any) { }
}

export class pullAllPacketSuccess implements Action {
  readonly type: string = ProcurementActionTypes.PULL_ALL_PACKET_SUCCESS;
  constructor(public response: any) { }
}

export class createTender implements Action {
  readonly type: string = ProcurementActionTypes.CREATE_TENDER;
  constructor(public payload: any) { }
}

export class createTenderSuccess implements Action {
  readonly type: string = ProcurementActionTypes.CREATE_TENDER_SUCCESS;
  constructor(public response: any) { }
}

export class ClearTenderAction implements Action {
  readonly type: string = ProcurementActionTypes.CLEAR_TENDER_ACTION;
  constructor() { }
}


export class pullAllTender implements Action {
  readonly type: string = ProcurementActionTypes.PULL_ALL_TENDER;
  constructor(public id: any,public module:any) { }
}

export class pullAllTenderSuccess implements Action {
  readonly type: string = ProcurementActionTypes.PULL_ALL_TENDER_SUCCESS;
  constructor(public response: any) { }
}


export class updateTender implements Action {
  readonly type: string = ProcurementActionTypes.UPDATE_TENDER;
  constructor(public id: any, public payload: any) { }
}

export class updateTenderSuccess implements Action {
  readonly type: string = ProcurementActionTypes.UPDATE_TENDER_SUCCESS;
  constructor(public response: any) { }
}

export class deleteTender implements Action {
  readonly type: string = ProcurementActionTypes.DELETE_TENDER;
  constructor(public id: any) { }
}

export class deleteTenderSuccess implements Action {
  readonly type: string = ProcurementActionTypes.DELETE_TENDER_SUCCESS;
  constructor(public response: any) { }
}

export class createAward implements Action {
  readonly type: string = ProcurementActionTypes.CREATE_AWARD;
  constructor(public payload: any) { }
}

export class createAwardSuccess implements Action {
  readonly type: string = ProcurementActionTypes.CREATE_AWARD_SUCCESS;
  constructor(public response: any) { }
}

export class ClearAwardAction implements Action {
  readonly type: string = ProcurementActionTypes.CLEAR_AWARD_ACTION;
  constructor() { }
}


export class pullAllAward implements Action {
  readonly type: string = ProcurementActionTypes.PULL_ALL_AWARD;
  constructor(public id: any) { }
}

export class pullAllAwardSuccess implements Action {
  readonly type: string = ProcurementActionTypes.PULL_ALL_AWARD_SUCCESS;
  constructor(public response: any) { }
}


export class updateAward implements Action {
  readonly type: string = ProcurementActionTypes.UPDATE_AWARD;
  constructor(public id: any, public payload: any) { }
}

export class updateAwardSuccess implements Action {
  readonly type: string = ProcurementActionTypes.UPDATE_AWARD_SUCCESS;
  constructor(public response: any) { }
}

export class deleteAward implements Action {
  readonly type: string = ProcurementActionTypes.DELETE_AWARD;
  constructor(public id: any) { }
}

export class deleteAwardSuccess implements Action {
  readonly type: string = ProcurementActionTypes.DELETE_AWARD_SUCCESS;
  constructor(public response: any) { }
}
