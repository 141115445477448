import { Component, Inject, ElementRef, ViewChild, Output, EventEmitter } from "@angular/core";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatAutocomplete, MatChipInputEvent, MatAutocompleteSelectedEvent } from "@angular/material";
import { Store } from "@ngrx/store";
import { FormControl, FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { Observable } from "rxjs";
import { debounceTime, distinctUntilChanged, tap, map } from "rxjs/operators";
import { GetAllTagsListStartAction, AddTagStartAction, ClearTagAction, } from "src/app/features/home/tasks/store/tasks.actions";
import { AddSuggestedTags, DeleteSuggestedTag, GetDocumentById } from "../../features/home/resources/_store/resources.actions";
import { SuggestTaskTags, DeleteSuggestTaskTags } from '../../features/home/tasks/store/tasks.actions'
import { LoaderShow } from "src/app/features/loader/store/loader.actions";
import { GetTaskDetails } from '../../features/home/tasks/store/tasks.actions'




@Component({
  selector: 'suggest-tags-dialog',
  templateUrl: './suggest-tags-dialog.component.html',
  styleUrls: ['./suggest-tags-dialog.component.scss']
})

export class SuggestTagsDialogComponent {
  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  // fruits: string[] = ['Lemon'];
  allTags: string[] = [];
  selectedItems: string[] = [];
  selectedLinkIds = [];
  formData = new FormData();
  documentId: string = '';
  selectedTagsRemovalList: any = []
  taskTag: boolean = false


  @ViewChild('tagInput', { static: false }) tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  form: FormGroup;
  responseTags$: Observable<any>;
  selectedTagIds: any = [];
  selectedTagWithId: any = [];
  selectedTagsList: any = []
  addTag: boolean = false;
  specialCharregex;
  multiSpaceRegex;
  error = false;
  taskDetails: any = {};
  searchTasks: boolean = false;
  responseTags: any;
  regExError: boolean = false
  // allSelectedApproverIds: Array<any> = [];
  // allSelectedEndorserIds: Array<any> = [];
  // allSelectedApproverobjs: Array<any> = [];
  // allSelectedEndorserObjs: Array<any> = [];

  selectedIds: Array<any> = [];
  dropDownList: Array<any> = [];
  DocumentTagsList: any = [];

  searchString;


  constructor(private dialogRef: MatDialogRef<any>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private _store: Store<any>,
    private fb: FormBuilder) {
    this.dialogRef.disableClose = true;
    this.taskDetails = data.allDetails;




    if (data.type === 'documentHub') {
      if (data.taskTags) {
        this._store.dispatch(new GetTaskDetails(this.taskDetails._id));
        this._store.select(state => state.tasks.taskDetail).subscribe(taskDetails => {
         
          if (taskDetails.suggestTagsToRemove.length > 0) {
            this.selectedTagsRemovalList = taskDetails.suggestTagsToRemove[0].tags.map(tags => tags)
            this.DocumentTagsList = taskDetails.tags.map(tag => tag)
          }
          else {
            this.DocumentTagsList = taskDetails.tags.map(tag => tag)
          }

          if (taskDetails && taskDetails.suggestTagsToAdd.length) {
            this.documentId = taskDetails.parentId || taskDetails._id;
            this.selectedItems = taskDetails.suggestTagsToAdd[0].tags.map((tagData) => (tagData.tag))
            this.selectedTagIds = taskDetails.suggestTagsToAdd[0].tags.map((tagData) => (tagData._id))
            this.selectedTagsList = taskDetails.suggestTagsToAdd[0].tags.map(tagData => tagData)
          }
        })
        this.taskTag = data.taskTags
      }
      else {
        this._store.dispatch(new GetDocumentById(data.docID, data.allCMPDoc));
        this._store.select(state => state.resources.docDetail).subscribe(docData => {
          if (docData.suggestTagsToRemove.length > 0) {
            this.selectedTagsRemovalList = docData.suggestTagsToRemove[0].tags.map(tags => tags)
            this.DocumentTagsList = docData.tags.map(tag => tag)
          }
          else {
            this.DocumentTagsList = docData.tags.map(tag => tag)
          }

          if (docData && docData.suggestTagsToAdd.length) {
            this.documentId = docData.parentId || docData._id;
            this.selectedItems = docData.suggestTagsToAdd[0].tags.map((tagData) => (tagData.tag))
            this.selectedTagIds = docData.suggestTagsToAdd[0].tags.map((tagData) => (tagData._id))
            this.selectedTagsList = docData.suggestTagsToAdd[0].tags.map(tagData => tagData)
          }
        })
      }

    }
  }

  ngOnInit() {
    let loginId = JSON.parse(localStorage.getItem('auth')).user.details._id;
    this._store.select(state => state.tasks.tagsList).subscribe((taglist) => {

      if (this.searchString && (!this.error && !this.regExError)) {
        taglist.unshift({ tag: ` + Create tag for '${this.searchString}'` });
      }

      this.responseTags = taglist.filter((tagData) => !this.selectedTagIds.includes(tagData._id));
      if (taglist.length) {
        this.addTag = false;
      }
      else {
        this.addTag = true;
      }
      // console.log(state.tasks.tasksList, "list")
    })

    this.responseTags$ = this._store.select(state => {
      if (this.searchTasks) {

        return state.tasks.tasksList
      }
    })
    this._store.select(state => state.tasks.tasksList).subscribe((list) => {
    })
    this.tagCtrl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(((val) => {

      this.searchString = val;


      this.error = false;
      this.regExError = false;

      if (val != null) {

        (typeof (val) === 'number') ? val.toString() : val;
        let value = val.trim();
        if (value.length) {
          if (this.data.type === 'documentHub') {
            this.specialCharregex = new RegExp(/^[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/);
            this.multiSpaceRegex = new RegExp(/[ ]{2,}/);

            if (this.specialCharregex.test(value)) {
              let value = val.trim();
              this._store.dispatch(new GetAllTagsListStartAction({ 'search': value }))
            }
            else {
              this.responseTags = []
              this.error = false;
              this.regExError = true;
            }

            if (!this.multiSpaceRegex.test(value)) {
              let value = val.trim();
              this._store.dispatch(new GetAllTagsListStartAction({ 'search': value }))
            }
            else {
              this.responseTags = []
              this.regExError = false;
              this.error = true;
            }


          }

        }
        else {
          this.responseTags = []
        }
      }
    }))


    this._store.select(state => state.tasks.addedTag).subscribe((response) => {
      //check for response when added new tag
      if (Object.keys(response).length) {
        let data = response;
        let index = this.selectedItems.findIndex(tag => tag === response.tag);
        if (index === -1) {
          this.selectedItems.push(response.tag);
          this.selectedTagIds.push(data._id);
          this.selectedTagWithId.push({ value: response.tag, _id: data._id });
          this.selectedTagsList.push(response)
          this._store.dispatch(new ClearTagAction());
          this.responseTags = []
        }
      }
    })

    this._store.select(state => state.resources.shareSuggestions).subscribe((list) => {
      this.dropDownList = list.filter((obj) => !this.selectedIds.includes(obj._id)).filter((obj) => obj._id !== loginId)

    });
    this.responseTags = [];

  }


  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value.trim();

    this.addTagSubmit(value);

    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  addTagSubmit(value) {
    if (!this.matAutocomplete.isOpen) {
      if (value || '') {
        if (this.data.type === 'documentHub') {
          this.specialCharregex = new RegExp(/^[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/);
          this.multiSpaceRegex = new RegExp(/[ ]{2,}/);
          if (this.specialCharregex.test(value) && !this.multiSpaceRegex.test(value)) {
            // if (!this.multiSpaceRegex.test(value)) {

            let index = this.selectedItems.findIndex((tag) => tag === value.trim());

            if (index === -1) {
              if (this.addTag) {
                this._store.dispatch(new LoaderShow());
                this._store.dispatch(new AddTagStartAction({ 'tag': value }));
                this.responseTags = []
              }
              else {
                this._store.dispatch(new AddTagStartAction({ 'tag': value }));
                this.responseTags = []
              }

            }
          }

        }

      }
      // Reset the input value

    }
    else {
      if ((this.data.type === 'tags') || (this.data.type === 'documentHub')) {
        let index = this.responseTags.findIndex((tagData) => (tagData.tag === value))

        if (index === -1) {
          this._store.dispatch(new LoaderShow());
          this._store.dispatch(new AddTagStartAction({ 'tag': value }));
          this.responseTags = []

        }
        else {
          let index = this.selectedItems.findIndex((tag) => tag === value.trim());
          let tagData: any = this.responseTags.filter((tag) => tag.tag === value);
          if (index === -1) {
            this.selectedItems.push(tagData[0].tag);
            this.selectedTagIds.push(tagData[0]._id);
            this.selectedTagWithId.push({ value: tagData[0].tag, _id: tagData[0]._id });
            this.selectedTagsList.push(tagData[0])
            // this.selectedTagsEvent.emit(this.selectedTags);
            // this.selectedTagIdsEvent.emit(this.selectedTagIds);
            this.responseTags = []
          }

        }
      }
    }
  }

  remove(item: string, id: string = '', RemoveFlag?: string): void {
    console.log(item, "item", id, "id", RemoveFlag, "flags")
    if ((this.data.type == "tags") || (this.data.type === 'documentHub')) {
      const index = this.selectedItems.indexOf(item);
      this.selectedTagWithId.forEach((obj, i) => {
        if (obj.value === item) {
          this.selectedTagWithId.splice(i, 1);
        }
      });

      if (index >= 0) {
        this.selectedTagIds.splice(index, 1);
        this.selectedItems.splice(index, 1);
      }

      this.selectedTagsList.splice(this.selectedTagsList.findIndex(tags => tags._id == id), 1)
      let payload = {
        "tagIdToAdd": id,
      }


      let selectedTags = Object.assign([], this.selectedTagIds)
      this.formData.append('tags', JSON.stringify(selectedTags));
      let dataObj = { "addTags": [], "removeTags": [] }
      dataObj['addTags'] = selectedTags
      dataObj['removeTags'] = this.selectedTagsRemovalList.map(tag => tag._id)


      if (this.taskTag) {
        console.log('tasks')
        this._store.dispatch(new SuggestTaskTags(this.taskDetails._id, dataObj));
        this._store.dispatch(new DeleteSuggestTaskTags(this.documentId, payload))
      } else {
        this._store.dispatch(new AddSuggestedTags((this.taskDetails.parentId) ? this.taskDetails.parentId : this.taskDetails._id, dataObj));
        this._store.dispatch(new DeleteSuggestedTag(this.documentId, payload))
      }

      console.log(this.selectedIds, "ids")
    }
  }

  displayFn(selectedValue) {
    if (selectedValue && Object.keys(selectedValue).length) {
      // console.log(this.selectedApproversIds, "ids")
      return selectedValue.taskId;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if ((this.data.type === "tags") || (this.data.type === 'documentHub')) {
      let index = this.selectedItems.findIndex(tag => tag === event.option.value.tag);
      if (index === -1) {

        if (!event.option.value._id) {
          this.addTagSubmit(this.searchString);
        } else {

          this.selectedItems.push(event.option.value.tag);
          this.selectedTagIds.push(event.option.value._id);
          this.selectedTagWithId.push({ value: event.option.value.tag, _id: event.option.value._id });
          this.selectedTagsList.push(event.option.value)
        }
      }
    }
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  submit() {
    if (this.data.type === 'documentHub') {
      let selectedTags = Object.assign([], this.selectedTagIds)
      this.formData.append('tags', JSON.stringify(selectedTags));
      let dataObj = { "addTags": [], "removeTags": [] }
      dataObj['addTags'] = selectedTags
      dataObj['removeTags'] = this.selectedTagsRemovalList.map(tag => tag._id)
      console.log(dataObj)
      console.log(this.taskDetails.parentId ? this.taskDetails.parentId : this.taskDetails._id)

      if (this.taskTag) {
        this._store.dispatch(new SuggestTaskTags(this.taskDetails._id, dataObj));
      } else {
        this._store.dispatch(new AddSuggestedTags((this.taskDetails.parentId) ? this.taskDetails.parentId : this.taskDetails._id, dataObj));
      }
      this.dialogRef.close();
    }
  }

  onNoClick(): void {
    this.dialogRef.close();
  }


  suggestTagRemoval(tag) {
    if (this.selectedTagsRemovalList.length > 0) {
      let index = this.selectedTagsRemovalList.findIndex(tags => tags._id == tag._id)
      if (index == -1) {
        this.selectedTagsRemovalList.push(tag)
      }
    }
    else {
      this.selectedTagsRemovalList.push(tag)
    }
  }

  removeTag(name, id) {
    this.selectedTagsRemovalList.splice(this.selectedTagsRemovalList.findIndex(tag => tag._id == id), 1)
    let payload = { "tagIdToRemove": id }
    if (this.taskTag) {
      console.log('tasks')
      this._store.dispatch(new DeleteSuggestTaskTags(this.documentId, payload))
    }
    else {
      this._store.dispatch(new DeleteSuggestedTag(this.documentId, payload))
    }
  }
}