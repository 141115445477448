import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { catchError, map, switchMap, tap } from 'rxjs/operators';
import { ErrorAction } from 'src/app/login/store/auth.actions';
import { ContractManagementService } from '../contract-management.service';
import {
    ContractManagementActions,
    GetAllContracts, GetAllContractsSuccess, GetMyContracts, GetMyContractsSuccess, AddNewContract, GetContractDetails, GetContractDetailsSuccess, ContractId, ContractIdSuccess, ArchiveContract, CloseContract, CloseContractSuccess, ArchiveContractSuccess, GetPaymentScheduleDetails, GetPaymentScheduleDetailsSuccess, UpdateMilestone, GetContractActivityLog, GetContractActivityLogSuccess, CreateInvoice, GetInvoiceDetails, GetInvoiceDetailsSuccess, GetIndividualInvoiceDetails, GetIndividualInvoiceDetailsSuccess, GetTimesheetDetails, GetTimesheetDetailsSuccess, GetTimesheetProjectDetails, GetTimesheetProjectDetailsSuccess, GetTimesheetProjectMonthDetails, GetTimesheetProjectMonthDetailsSuccess, DownloadContractsExcel, DownloadContractsExcelSuccess, ReOpenContract, ReOpenContractSuccess,EditContract, EditContractSuccess, AddEditTimeSheets, AddEditTimeSheetsSuccess, InvoiceAction, InvoiceActionSuccess, GetInvoiceActivityLogSuccess, GetInvoiceActivityLog, GetTimesheetActivityLog, GetTimesheetActivityLogSuccess, CreateInvoiceSuccess
} from './contract-management.actions';
import { NotificationsService } from "angular2-notifications";
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { LoaderShow, LoaderHide } from '../../loader/store/loader.actions';
import { Location } from '@angular/common';
import { Router } from '@angular/router';

@Injectable()
export class ContractManagementEffects {

    constructor(
        private actions$: Actions,
        private cmApiService: ContractManagementService,
        private notificationService: NotificationsService,
        private store: Store<AppState>,
        private location: Location,
        private router: Router,


    ) { }

    @Effect()
    getAllContracts$ = this.actions$.pipe(
        ofType(ContractManagementActions.GET_ALL_CONTRACTS),
        switchMap((action: GetAllContracts) => {
            let obj = {}
            if(action.searchString){
                obj = { type :"all", search: action.searchString , page : action.page , limit:action.limit  }
            }else{
                obj = { type: "all" , page : action.page , limit:action.limit };
            }
            return this.cmApiService.getAllContracts(obj).pipe(
                map((response: any) => {
                    if (response) {
                        console.log("res", response);
                        return new GetAllContractsSuccess(response);
                    }
                    else{
                        this.router.navigate(["home"+"contract-management"+"contracts"+"my"])
                    }
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getMyContracts$ = this.actions$.pipe(
        ofType(ContractManagementActions.GET_MY_CONTRACTS),
        switchMap((action: GetMyContracts) => {
            let obj = {}
            if(action.searchString){
                obj = { type :"mycontracts", search: action.searchString, page : action.page , limit:action.limit }
            }else{
                obj = { type: "mycontracts", page : action.page , limit:action.limit };
            }
            return this.cmApiService.getMyContracts(obj).pipe(
                map((response: any) => {
                    if (response) {
                        return new GetMyContractsSuccess(response);
                    }
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    addNewContract$ = this.actions$.pipe(
        ofType(ContractManagementActions.ADD_NEW_CONTRACT),
        switchMap((action: AddNewContract) => {
            console.log("entered in api call");
            return this.cmApiService.createContract(action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(`Contract created successfully`);
                    this.router.navigate(['home/contract-management/contracts/all']);
                    return new GetMyContractsSuccess(response);
                    // console.log(response);
                }),
                catchError(err => {
                    console.log(err, "err")

                    return of(new ErrorAction(err))
                }

                )
            )
        })
    );

    @Effect()
    editContract$ = this.actions$.pipe(
        ofType(ContractManagementActions.EDIT_CONTRACT),
        switchMap((action: EditContract) => {
            console.log("entered in api call",action.payload);
            return this.cmApiService.editContract(action.payload).pipe(
                map((response: any) => {
                    this.store.dispatch(new GetPaymentScheduleDetails(action.payload._id));
                    this.store.dispatch(new GetContractActivityLog(action.payload._id));
                    this.notificationService.success(response.successMessage);
                    this.router.navigate([`home/contract-management/${action.payload._id}/contract-details`]);
                    return new EditContractSuccess(response);
                    // console.log(response);
                }),
                catchError(err => {
                    console.log(err, "err")

                    return of(new ErrorAction(err))
                }

                )
            )
        })
    );

    @Effect()
    getContractDetails$ = this.actions$.pipe(
        ofType(ContractManagementActions.GET_CONTRACT_DETAILS),
        switchMap((action: GetContractDetails) => {
            // this.store.dispatch(new LoaderShow())
            return this.cmApiService.getContractDetail(action.taskId,action.isMainView).pipe(
                map((response: any) => {
                    // this.taskId = action.taskId;
                    this.store.dispatch(new LoaderHide())
                    console.log("In effects", response)
                    return new GetContractDetailsSuccess(response);
                }),
                catchError((err) => {
                    this.store.dispatch(new LoaderHide())
                    this.location.back();
                    return of(new ErrorAction(err))
                })
            );
        })
    );

    @Effect()
    archiveContract$ = this.actions$.pipe(
        ofType(ContractManagementActions.ARCHIVE_CONTRACT),
        switchMap((action: ArchiveContract) => {
            return this.cmApiService.archiveContract(action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(`Contract archives successfully`);
                    this.store.dispatch(new GetContractActivityLog(action.payload));

                    // this.store.dispatch(new GetContractDetails(action.payload,false))

                    // this.router.navigate(['home/contract-management/contracts/all']);
                    // return new GetMyContractsSuccess(response);
                    return new ArchiveContractSuccess(response);

                    console.log(response);
                }),
                catchError(err => {
                    console.log(err, "err")

                    return of(new ErrorAction(err))
                }

                )
            )
        })
    );

    @Effect()
    closeContract$ = this.actions$.pipe(
        ofType(ContractManagementActions.CLOSE_CONTRACT),
        switchMap((action: CloseContract) => {
            console.log("entered in api call");
            return this.cmApiService.closeContract(action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(`Contract Closed successfully`);
                    this.store.dispatch(new GetContractActivityLog(action.payload));
                    // this.router.navigate(['home/contract-management/contracts/all']);
                    return new CloseContractSuccess(response);
                    console.log(response);
                }),
                catchError(err => {
                    console.log(err, "err")

                    return of(new ErrorAction(err))
                }

                )
            )
        })
    );

    @Effect()
    reopenContract$ = this.actions$.pipe(
        ofType(ContractManagementActions.REOPEN_CONTRACT),
        switchMap((action: ReOpenContract) => {
            return this.cmApiService.reopenContract(action.contractID).pipe(
                map((response: any) => {
                    this.notificationService.success(`Contract Reopened successfully`);
                    this.store.dispatch(new GetContractActivityLog(action.contractID));
                    // this.router.navigate(['home/contract-management/contracts/all']);
                    // return new GetMyContractsSuccess(response);
                    return new ReOpenContractSuccess(response);

                    console.log(response);
                }),
                catchError(err => {
                    console.log(err, "err")

                    return of(new ErrorAction(err))
                }

                )
            )
        })
    );

    @Effect()
    getPaymentScheduleDetails$ = this.actions$.pipe(
        ofType(ContractManagementActions.GET_PAYMENT_SCHEDULE_DETAILS),
        switchMap((action: GetPaymentScheduleDetails) => {
            this.store.dispatch(new LoaderShow())
            return this.cmApiService.getPaymentScheduleDetails(action.contractId).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide())
                    // this.taskId = action.taskId;
                    console.log("In effects", response)
                    return new GetPaymentScheduleDetailsSuccess(response);
                }),
                catchError((err) => {
                    this.location.back();
                    return of(new ErrorAction(err))
                })
            );
        })
    );

    @Effect()
    updateMileStones$ = this.actions$.pipe(
        ofType(ContractManagementActions.UPDATE_MILESTONES),
        switchMap((action: UpdateMilestone) => {
            console.log("entered in api call");
            return this.cmApiService.updateMilestones(action.id, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(`MileStone updated successfully`);
                    this.router.navigate([`home/contract-management/${action.contractID}/payment-schedule`]);
                    return new GetMyContractsSuccess(response);
                    // console.log(response);
                }),
                catchError(err => {
                    console.log(err, "err")

                    return of(new ErrorAction(err))
                }

                )
            )
        })
    );

    @Effect()
    getActivityLogForProject$ = this.actions$.pipe(
        ofType(ContractManagementActions.GET_CONTRACT_ACTIVITY),
        switchMap((action: GetContractActivityLog) => {
            return this.cmApiService.getContractActivityLog(action.contractID).pipe(
                map((response: any) => {
                    return new GetContractActivityLogSuccess(response);
                }),
                catchError(err => {
                    this.store.dispatch(new GetContractActivityLogSuccess([]));
                    return of(new ErrorAction(err));
                })
            );
        })
    );

    @Effect()
    getActivityLogForInvoice$ = this.actions$.pipe(
        ofType(ContractManagementActions.GET_INVOICE_ACTIVITY),
        switchMap((action: GetInvoiceActivityLog) => {
            return this.cmApiService.getInvoiceActivityLog(action.invoiceID).pipe(
                map((response: any) => {
                    return new GetInvoiceActivityLogSuccess(response);
                }),
                catchError(err => {
                    this.store.dispatch(new GetInvoiceActivityLogSuccess([]));
                    return of(new ErrorAction(err));
                })
            );
        })
    );

    @Effect()
    getActivityLogForTimesheet$ = this.actions$.pipe(
        ofType(ContractManagementActions.GET_TIMESHEET_ACTIVITY),
        switchMap((action: GetTimesheetActivityLog) => {
            return this.cmApiService.getTimesheetActivityLog(action.contractID, action.month, action.projectID).pipe(
                map((response: any) => {
                    return new GetTimesheetActivityLogSuccess(response);
                }),
                catchError(err => {
                    this.store.dispatch(new GetTimesheetActivityLogSuccess([]));
                    return of(new ErrorAction(err));
                })
            );
        })
    );

    @Effect()
    createInvoice$ = this.actions$.pipe(
        ofType(ContractManagementActions.CREATE_INVOICE),
        switchMap((action: CreateInvoice) => {
            // console.log("entered in api call");
            return this.cmApiService.createInvoice(action.payload,action.id).pipe(
                map((response: any) => {
                    this.notificationService.success(`Invoice created successfully`);
                    return new CreateInvoiceSuccess(response);
                    // console.log(response);
                }),
                catchError(err => {
                    console.log(err, "err")

                    return of(new ErrorAction(err))
                }

                )
            )
        })
    );

    @Effect()
    getInvoiceDetails$ = this.actions$.pipe(
        ofType(ContractManagementActions.GET_INVOICE_DETAILS),
        switchMap((action: GetInvoiceDetails) => {
            this.store.dispatch(new LoaderShow())
            return this.cmApiService.getInvoiceDetails(action.contractId).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide())
                    // this.taskId = action.taskId;
                    console.log("In effects", response)
                    return new GetInvoiceDetailsSuccess(response);
                }),
                catchError((err) => {
                    this.location.back();
                    return of(new ErrorAction(err))
                })
            );
        })
    );

    @Effect()
    getIndividualInvoiceDetails$ = this.actions$.pipe(
        ofType(ContractManagementActions.GET_INDIVIDUAL_INVOICE_DETAILS),
        switchMap((action: GetIndividualInvoiceDetails) => {
            this.store.dispatch(new LoaderShow())
            return this.cmApiService.getInvoicetDetail(action.invoiceId).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide())
                    // this.taskId = action.taskId;
                    console.log("In effects", response)
                    return new GetIndividualInvoiceDetailsSuccess(response);
                }),
                catchError((err) => {
                    this.location.back();
                    return of(new ErrorAction(err))
                })
            );
        })
    );

    @Effect()
    getTimeSheetDetails$ = this.actions$.pipe(
        ofType(ContractManagementActions.GET_TIMESHEET_DETAILS),
        switchMap((action: GetTimesheetDetails) => {
            this.store.dispatch(new LoaderShow())
            return this.cmApiService.getTimeshetDetails(action.contractId).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide())
                    // this.taskId = action.taskId;
                    console.log("In effects", response)
                    return new GetTimesheetDetailsSuccess(response);
                }),
                catchError((err) => {
                    this.location.back();
                    return of(new ErrorAction(err))
                })
            );
        })
    );

    @Effect()
    getTimeSheetProjectDetails$ = this.actions$.pipe(
        ofType(ContractManagementActions.GET_TIMESHEET_PROJECT_DETAILS),
        switchMap((action: GetTimesheetProjectDetails) => {
            this.store.dispatch(new LoaderShow())
            return this.cmApiService.getTimeshetProjectDetails(action.phaseId,action.projectId).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide())
                    // this.taskId = action.taskId;
                    console.log("In effects", response)
                    return new GetTimesheetProjectDetailsSuccess(response);
                }),
                catchError((err) => {
                    this.location.back();
                    return of(new ErrorAction(err))
                })
            );
        })
    );

    @Effect()
    getTimeSheetProjectMonthDetails$ = this.actions$.pipe(
        ofType(ContractManagementActions.GET_TIMESHEET_PROJECT_MONTH_DETAILS),
        switchMap((action: GetTimesheetProjectMonthDetails) => {
            this.store.dispatch(new LoaderShow())
            let obj = { month: action.monthId };
            return this.cmApiService.getTimeshetProjectMonthDetails(action.projId,action.phaseId,obj).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide())
                    // this.taskId = action.taskId;
                    console.log("In effects", response)
                    return new GetTimesheetProjectMonthDetailsSuccess(response);
                }),
                catchError((err) => {
                    this.location.back();
                    return of(new ErrorAction(err))
                })
            );
        })
    );

    @Effect()
    downloadAllContracts$ = this.actions$.pipe(
        ofType(ContractManagementActions.DOWNLOAD_LIST),
        switchMap((action: DownloadContractsExcel) => {
            return this.cmApiService.downloadAllContracts().pipe(
                map((response: any) => {
                    if (response) {
                        console.log("res", response);
                        return new DownloadContractsExcelSuccess(response);
                    }
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    addEditTimesheets$ = this.actions$.pipe(
        ofType(ContractManagementActions.ADD_EDIT_TIMESHEETS),
        switchMap((action: AddEditTimeSheets) => {
            return this.cmApiService.addEditTimesheets(action.payload,action.id).pipe(
                map((response: any) => {
                    this.notificationService.success(`Timesheet Updated successfully`);
                    return new AddEditTimeSheetsSuccess(response);
                }),
                catchError(err => {
                    console.log(err, "err")
                    return of(new ErrorAction(err))
                }

                )
            )
        })
    );

    @Effect()
    invoiceActions$ = this.actions$.pipe(
        ofType(ContractManagementActions.INVOICE_ACTIONS),
        switchMap((action: InvoiceAction) => {
            this.store.dispatch(new LoaderShow())
            return this.cmApiService.invoiceActions(action.payload,action.id).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide())
                    this.notificationService.success(response.message);
                    this.router.navigate([`home/contract-management/${action.contractId}/invoices`]);
                    // this.taskId = action.taskId;
                    // console.log("In effects", response)
                    return new InvoiceActionSuccess(response);
                }),
                catchError((err) => {
                    // this.location.back();
                    // this.router.navigate([`home/contract-management/${action.id}/invoices`]);
                    return of(new ErrorAction(err))
                })
            );
        })
    );



}
