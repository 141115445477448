import { Component, OnInit, Inject, OnDestroy } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { GetRegisterUserStartAction, GetOtp, RegisterLinkValidate, Logout, LogoutSuccess, ErrorAction } from '../../login/store/auth.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { API } from 'src/app/services/Api.service';
import { NgxPermissionsService } from 'ngx-permissions';
import { map, catchError, takeUntil } from 'rxjs/operators';
import { of, Subject } from 'rxjs';



@Component({
  selector: 'app-logout-dialog',
  templateUrl: './logout-dialog.component.html',
  styleUrls: ['./logout-dialog.component.scss']
})
export class LogoutDialogComponent implements OnInit , OnDestroy {

  userDetail;
  userName;
  token: any;
  stopper$ = new Subject<any>();

  constructor(
    public dialogRef: MatDialogRef<LogoutDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private api: API,
    private ngxPermissionService: NgxPermissionsService,
    private _store: Store<AppState>,

  ) {
    this.dialogRef.disableClose = true;
    this.userDetail = this.data.userDetail;
    this.token = this.data.token;
    this.userName = this.userDetail.firstName + ' ' + this.userDetail.lastName;
  }

  logoutUser() {
    // this._store.dispatch(new Logout(this.userDetail._id, this.token));
     this.api.logout(this.userDetail._id, this.token).pipe(
       takeUntil(this.stopper$),
      map((response: any) => {
        console.log("LogutDialogComponent");
        this.ngxPermissionService.flushPermissions();
        this._store.dispatch(new LogoutSuccess());
        this.dialogRef.close(true);
        // this.router.navigate(['user/login']);
      }),
      catchError((error) => {
        return of(new ErrorAction(error));
      })).subscribe();
  }

  ngOnInit() {
  }

  ngOnDestroy(){
    this.stopper$.next();
    this.stopper$.complete();
  }

}
