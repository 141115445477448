import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { CreateEntityComponent } from "./create-entity/create-entity.component";
import { EntityListComponent } from './entity-list/entity-list.component';
const routes: Routes = [
  { path: "", redirectTo: "entity/my", pathMatch: "full" },
  { path: "entity/:type", component: EntityListComponent },
  { path: "create-entity", component: CreateEntityComponent },
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule],
})
export class EntityManagementRoutingModule {}
