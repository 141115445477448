import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { environment } from "src/environments/environment";
export const BASE_URL = environment.host;

@Injectable({
  providedIn: "root",
})
export class ProcurementService {
  constructor(private http: HttpClient) {}

  getContractActivityList(payload) {
    return this.http.get(`${BASE_URL}/procurement/summary/generate`, {
      params: payload,
    });
  }

  getDetals(payload) {
    return this.http.get(`${BASE_URL}/procurement/summaryUnits/details`, {
      params: payload,
    });
  }

  addProcurementUnit(payload) {
    return this.http.post(
      `${BASE_URL}/procurement/generate/procurementUnit`,
      payload
    );
  }
  deleteProcurementUnit(payload) {
    const options = {
      headers: new HttpHeaders({
        "Content-Type": "application/json",
      }),
      body: payload,
    };
    return this.http.delete(`${BASE_URL}/procurement/unit/delete`, options);
  }

  updateProcurementUnit(payload) {
    return this.http.patch(`${BASE_URL}/procurement/unit/update`, payload);
  }

  getDocumentList(payload) {
    return this.http.get(`${BASE_URL}/procurement/procUnit/getDocumentList`, {
      params: payload,
    });
  }

  addProcurementDocument(payload) {
    return this.http.post(
      `${BASE_URL}/procurement/procUnit/addDocument`,
      payload
    );
  }

  updateDocument(payload) {
    return this.http.patch(
      `${BASE_URL}/procurement/procUnit/updateDocument`,
      payload
    );
  }

  deleteDocument(Id) {
    return this.http.delete(
      `${BASE_URL}/procurement/procUnit/deleteDocument/${Id}`
    );
  }

  submitForApprovalForUploadDoc(id) {
    return this.http.get(
      `${BASE_URL}/procurement/procUnit/document/submitForApproval/${id}`
    );
  }

  getMemberList(id) {
    return this.http.get(
      `${BASE_URL}/procurement/project/getMemberList?projectId=${id}`
    );
  }

  addApprovar(payload) {
    return this.http.post(
      `${BASE_URL}/procurement/addApprovers`,
      payload
    );
  }

  approveRejectFile(subSection,id,action) {
    return this.http.get(
      `${BASE_URL}/procurement/procUnit/${subSection}/approver/${id}/${action}`
    );
  }

  addedMemberList(projectId){
    // entityID
    return this.http.get(
      `${BASE_URL}/procurement/getApprovers?projectId=${projectId}`
    );
    // entityId=${entityID}&
  }

  submitForApprovalForMilestone(id,type){
    return this.http.get(
      `${BASE_URL}/procurement/procUnit/${type}/submitForApproval/${id}`
    );
  }
  

































  getEntityList() {
    return this.http.get(`${BASE_URL}/procurement/entity/list`);
  }

  // getEntityAndProject() {
  //   return this.http.get(
  //     `${BASE_URL}/procurement/mapping/entityProject/details`
  //   );
  // }

  getEntityAndProject() {
    return this.http.get(`${BASE_URL}/procurement/entity/list`);
  }

  getProjectList() {
    return this.http.get(`${BASE_URL}/procurement/project/list`);
  }

  getProjectDetailsbyIntity(entityId) {
    return this.http.get(`${BASE_URL}/procurement/${entityId}/project/details`);
  }

  // getContractActivityList(payload) {
  //   return this.http.post(
  //     `${BASE_URL}/procurement/generate/procurementNo`,
  //     payload
  //   );
  // }

  submitProcurementUnit(payload) {
    return this.http.put(
      `${BASE_URL}/procurement/generate/procurementUnit`,
      payload
    );
  }

  // getDetals(payload) {
  //   return this.http.post(`${BASE_URL}/procurement/detail`, payload);
  // }

  getBudgetList(payload) {
    return this.http.get(`${BASE_URL}/procurement/project/getBudget`, {
      params: payload,
    });
  }

  deleteBudget(Id) {
    return this.http.delete(
      `${BASE_URL}/procurement/project/deleteBudget/${Id}`
    );
  }

  addBudget(payload) {
    return this.http.post(`${BASE_URL}/procurement/project/addBudget`, payload);
  }

  updateBudget(payload, Id) {
    return this.http.patch(
      `${BASE_URL}/procurement/project/updateBudget/${Id}`,
      payload
    );
  }

  getClouserDocumentList(payload) {
    return this.http.get(
      `${BASE_URL}/procurement/project/getClosureDocumentList`,
      { params: payload }
    );
  }

  addClouserDocument(payload) {
    return this.http.post(
      `${BASE_URL}/procurement/project/addClosureDocument`,
      payload
    );
  }

  deleteClouserDocument(Id) {
    return this.http.delete(
      `${BASE_URL}/procurement/project/deleteClosureDocument/${Id}`
    );
  }

  updateClouserDocument(payload, Id) {
    return this.http.patch(
      `${BASE_URL}/procurement/project/updateClosureDocument/${Id}`,
      payload
    );
  }

  // getclouserDocument(Id) {
  //   return this.http.get(
  //     `${BASE_URL}/procurement/project/getClosureDocument/${Id}`
  //   );
  // }

  // getprocurementDocument(Id) {
  //   return this.http.get(`${BASE_URL}/procurement/project/getDocument/${Id}`);
  // }

  addMilestones(payload) {
    return this.http.post(`${BASE_URL}/procurement/milestone/add`, payload);
  }

  getMilestoneList(payload) {
    return this.http.get(`${BASE_URL}/procurement/milestone/get`, {
      params: payload,
    });
  }

  updateMilestones(payload, Id) {
    return this.http.patch(
      `${BASE_URL}/procurement/milestone/update/${Id}`,
      payload
    );
  }

  deleteMilestone(Id) {
    return this.http.delete(`${BASE_URL}/procurement/milestone/delete/${Id}`);
  }

  getProcurementPayment(payload) {
    return this.http.get(`${BASE_URL}/procurement/monitor/getPaymentList`, {
      params: payload,
    });
  }

  getProcurementList(pro_type) {
    return this.http.get(
      `${BASE_URL}/procurement/getProcurementList/${pro_type}`,
    );
  }


  addComments(obj){
    return this.http.post(`${BASE_URL}/procurement/procUnit/subUnit/add/comment`, obj);
  }

  Commentslist(id) {
    return this.http.get(
      `${BASE_URL}/procurement/procUnit/subUnit/add/commentList/${id}`,
    );
  }

  addphysicalpercentage(payload) {
    return this.http.post(`${BASE_URL}/procurement/procUnit/completion/submit`, payload);
  }

  getpysicalpercent(payload){
    return this.http.get(
      `${BASE_URL}/procurement/procUnit/completion/get/${payload.projectId}/${payload.procUnitRefId}`,
    );
  }

    //new Procurement

    createPacket(projectId,payload){
      return this.http.post(`${BASE_URL}/proc/createPacket/${projectId}`,payload);
    }
  
    updatePacket(packetId,payload){
      return this.http.post(`${BASE_URL}/proc/updateSinglePacket/${packetId}`,payload);
    }

    pullpacketlist(id){
      return this.http.get(
        `${BASE_URL}/proc/getProjectPacketList/${id}`,
      );
    }

    uploadFile(
      file: File,
    ) {
      const formData = new FormData();
      formData.append("upfile", file);
      return this.http.post(`${BASE_URL}/challenge/uploadFile`, formData);
    }

    getData(packetId){
      return this.http.get(`${BASE_URL}/proc/getPacketDetail/${packetId}`);
    }


    createTender(payload){
      return this.http.post(`${BASE_URL}/proc/tender`,payload);
    }
    
    pulltenderlist(packetId,module){
      return this.http.get(
        `${BASE_URL}/proc/tenders/${packetId}/${module}`
      );
    }
    updateTender(tenderId,payload){
      return this.http.put(`${BASE_URL}/proc/tender/${tenderId}`,payload);
    }

    deleteTender(tenderId){
      return this.http.delete(`${BASE_URL}/proc/tender/${tenderId}`);
    }


    //award


    createaward(payload){
      return this.http.post(`${BASE_URL}/proc/award`,payload);
    }
    
    pullawardlist(packetId){
      return this.http.get(
        `${BASE_URL}/proc/awards/${packetId}`
      );
    }
    updateaward(awardId,payload){
      return this.http.put(`${BASE_URL}/proc/award/${awardId}`,payload);
    }

    deleteaward(awardId){
      return this.http.delete(`${BASE_URL}/proc/award/${awardId}`);
    }
    
    updateAwardType(packetId:any,awardType:number){
      return this.http.put(`${BASE_URL}/proc/award/${packetId}/${awardType}`,{})
    }

    deletePacket(id){
      return  this.http.delete(`${BASE_URL}/proc/deletePacket/${id}`);
    }


}

