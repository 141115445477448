// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {

  //for DEV environment
  // host: 'https://cmp-dev.niua.org/api/v1',
  // sockethost: 'https://cmp-dev.niua.org',
  // download_template:'https://cmp-dev.niua.org/',

  //For UAT environemnt
  // host : 'https://cmp-test.niua.org/api',
  // sockethost : 'https://cmp-test.niua.org',


  // for Production Even

  host: 'https://cmp.niua.org/api/v1',
  sockethost: 'https://cmp.niua.org',
  download_template: 'https://cmp.niua.org/',

  // host: 'http://192.168.1.212:3000',
  production: false,
  reduxDebug: true
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
