import { Injectable } from '@angular/core';
import { API } from 'src/app/services/Api.service';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { ErrorAction } from 'src/app/login/store/auth.actions';

import {
    GetConfigurations,
    GetConfigurationsSuccess,
    ConfigurationsActionTypes,
    UpdateConfigurations,
    UpdateConfigurationsSuccess
} from './configurations.actions';

import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Location } from '@angular/common';
import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class ConfigurationsEffects {
    @Effect()
    getConfigurations$ = this.action$.pipe(
        ofType(ConfigurationsActionTypes.GET_CONFIGURATIONS),
        switchMap((action: GetConfigurations) => {
            return this.api.getConfigurations().pipe(
                map((configList: any) => {
                    // let obj  =configList.reduce((main, curr) => Object.assign({}, main,{[curr.key] : curr}, {}))
                    let obj =configList.reduce((prev, curr) => {
                        prev[curr.key] =curr
                    return prev
                    }, {})
                    return new GetConfigurationsSuccess(obj);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    updateConfiguration$ = this.action$.pipe(
        ofType(ConfigurationsActionTypes.UPDATE_CONFIGURATIONS),
        switchMap((action: UpdateConfigurations) => {
            return this.api.updateConfigurations(action.configObj).pipe(
                map((response: any) => {
                    this.notificationService.success("Configurations updated successfully");
                    // return new GetConfigurations();
                    return new UpdateConfigurationsSuccess()
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    constructor(
        private api: API,
        private action$: Actions,
        private location: Location,
        private notificationService: NotificationsService,
        private store: Store<AppState>
    ) { }
}