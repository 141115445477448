import { Action } from '@ngrx/store';

export enum TagsActionTypes {
    GET_TAGS = '[Tags] Get Tags',
    GET_TAGS_SUCCESS = '[Tags] Get Tags Sucess',
    MERGE_TAGS = '[Tags] Merge Tags',
    MERGE_TAGS_SUCCESS = '[Tags] Merge Tags Success',
    GET_TAGS_ACTIVITY_LOGS = '[Tags] Get Tags Activity Logs',
    GET_TAGS_ACTIVITY_LOGS_SUCCESS = '[Tags] Get Tags Activity Logs Success',
}


export class GetTags implements Action {
    readonly type: string = TagsActionTypes.GET_TAGS;
    constructor() { }
}

export class GetTagsSuccess implements Action {
    readonly type: string = TagsActionTypes.GET_TAGS_SUCCESS;
    constructor(public tags: any) { }
}

export class MergeTags implements Action {
    readonly type: string = TagsActionTypes.MERGE_TAGS;
    constructor(public payload: any) { }
}

export class MergeTagsSuccess implements Action {
    readonly type: string = TagsActionTypes.MERGE_TAGS_SUCCESS;
    constructor(public response: any) { }
}

export class GetTagsActivityLog implements Action {
    readonly type: string = TagsActionTypes.GET_TAGS_ACTIVITY_LOGS;
    constructor() { }
}

export class GetTagsActivityLogSuccess implements Action {
    readonly type: string = TagsActionTypes.GET_TAGS_ACTIVITY_LOGS_SUCCESS;
    constructor(public tagsLogs: any) { }
}