import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { APP_PROVIDERS } from './app.providers';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { AppEffects } from './app.effects';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { reducer, metaReducers } from './app.reducer';
import { HttpClientModule } from '@angular/common/http';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { SimpleNotificationsModule } from 'angular2-notifications';
import { environment as env } from '../environments/environment';
import { TagsDialogComponent } from './dialogs/tags-dialog/tags-dialog.component';
import { MAT_DECLARATIONS } from './shared/material.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ChangeEmailComponent } from './features/home/profile/change-email/change-email.component'
import { VerifyChangeEmailComponent } from './features/home/profile/verify-change-email/verify-change-email.component'
import { ChangeEmailSuccessComponent } from './features/home/profile/change-email-success/change-email-success.component';
import { RegistrationSuccesful } from './dialogs/registation-successful/registration-successful';
import { CreateFolderComponent } from './features/home/resources/create-folder/create-folder.component'
import { SharedModule } from './shared/shared.module';
import { VerifyOtpComponent } from './login/registration-screen/verify-otp/verify-otp.component'
import { Loader } from './features/loader/loader.component';
import { AddViewersComponent } from './features/home/tasks/add-viewers/add-viewers.component';
import { SuggestTagsDialogComponent } from './dialogs/suggest-tags-dialog/suggest-tags-dialog.component';
import { TagMessagesDialogComponent } from './dialogs/tag-messages-dialog/tag-messages-dialog.component'
import { EditRoleComponent, AddRoleComponent } from './features/home';
import { VerifyChangePhoneComponent } from './features/home/profile/change-phone/verify-change-phone/verify-change-phone.component';
import { OtpScreenComponent } from './dialogs/otp-screen/otp-screen.component';
import { LogoutDialogComponent } from './dialogs/logout-dialog/logout-dialog.component';
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { EntityManagementModule } from './features/entity-management/entity-management.module';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';



const REDUX_DEVTOOLS = env.reduxDebug ?
  [StoreDevtoolsModule.instrument({
    maxAge: 25,
  })] : [];

@NgModule({
  declarations: [
    AppComponent,
    TagsDialogComponent,
    ChangeEmailComponent,
    VerifyChangeEmailComponent,
    ChangeEmailSuccessComponent,
    RegistrationSuccesful,
    CreateFolderComponent,
    Loader,
    VerifyOtpComponent,
    AddViewersComponent,
    SuggestTagsDialogComponent,
    TagMessagesDialogComponent,
    VerifyChangePhoneComponent,
    OtpScreenComponent,
    LogoutDialogComponent
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    CommonModule,
    BrowserAnimationsModule,
    MatDatepickerModule,
    StoreModule.forRoot(reducer, { metaReducers }),
    ...REDUX_DEVTOOLS,
    EffectsModule.forRoot(AppEffects),
    NgxPermissionsModule.forRoot(),
    SimpleNotificationsModule.forRoot(),
    MAT_DECLARATIONS,
    ReactiveFormsModule,
    FormsModule,
    SharedModule,
    NgbModule,
    EntityManagementModule,
    NgxMaterialTimepickerModule,
  ],
  providers: [APP_PROVIDERS],
  bootstrap: [AppComponent],
  entryComponents: [
    TagsDialogComponent,
    ChangeEmailComponent,
    VerifyChangeEmailComponent,
    ChangeEmailSuccessComponent,
    RegistrationSuccesful,
    CreateFolderComponent,
    // AddRoleComponent,
    // EditRoleComponent,
    AddViewersComponent,
    SuggestTagsDialogComponent,
    VerifyOtpComponent,
    TagMessagesDialogComponent,
    VerifyChangePhoneComponent,
    VerifyOtpComponent,
    OtpScreenComponent,
    LogoutDialogComponent
  ]
})
export class AppModule { }
