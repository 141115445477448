import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { Subject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { AddViewAccessForDocumentStart, ClearViewAccess } from '../../home/user-messages/_store/messages.actions';
import { takeUntil } from 'rxjs/operators';
import { CommonService } from 'src/app/services/tasks.services';

@Component({
  selector: 'notification-modal',
  templateUrl: './notification-modal.component.html',
  styleUrls: ['./notification-modal.component.scss']
})
export class NotificationModalComponent implements OnInit {
  yesButtonText: string = 'Yes';
  noButtonText: string = 'No';
  showLabel = '';
  showOkButton: boolean = false;
  showDocumentViewAccess: boolean = false;
  showNoAccessDocuments: Array<any> = [];
  documentsWithViewAccess: Array<any> = [];
  constructor(public dialogRef: MatDialogRef<NotificationModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any, public _store: Store<AppState>, public commonService: CommonService) { 
    dialogRef.disableClose =true;
  }
  ngOnInit() {
    this.showDocumentViewAccess = (this.data.type === 'documentViewAccess' || this.data.type === 'taskViewAccess') ? true : false;
    if (!this.showDocumentViewAccess) {

      this.showLabel = this.data.text;
      this.dialogRef.disableClose = true;
      if (this.data.button) {
        //show only ok button
        if (this.data.button == 'ok') {

          this.showOkButton = true
        }
      }
    }
    else {
      let documentIds: Array<any> = Object.keys(this.data.response).filter((id) => ((id !== 'documents') && (id !== 'noAccessDocuments')))
      this.documentsWithViewAccess = this.data.response.documents.filter((doc) => documentIds.includes(doc._id));
      let noAccessDocumentIds = this.data.response.noAccessDocuments
      this.showNoAccessDocuments = this.data.response.documents.filter((doc) => noAccessDocumentIds.includes(doc._id));
      // this.yesButtonText = (this.documentsWithViewAccess.length) ? 'Give Access & Send email' : 'OK & Send'
      // this.noButtonText = (this.documentsWithViewAccess.length) ? 'Send email without access' : 'no';

       this.yesButtonText = this.data.yesButtonText;
       this.noButtonText = this.data.noButtonText;
      if(this.data.type === 'taskViewAccess') {
        // this.yesButtonText =this.data.yesButtonText;
        // this.noButtonText =this.data.noButtonText;
        this.showOkButton =    (this.showNoAccessDocuments.length) ? true: false ;
      }
      else if (this.data.type === 'documentViewAccess') {
        this.showOkButton =    (this.showNoAccessDocuments.length) ? true: false ;

      }
     
    }
  }




  onYesClick() {
    
    if (this.data.type !== 'userGroups') {

      if (!this.showDocumentViewAccess) {
        this.dialogRef.close(true);
      }
      else {
        if (this.documentsWithViewAccess.length) {
          this._store.dispatch(new AddViewAccessForDocumentStart(this.data.response))
          this.dialogRef.close(true)
        }
        else if (!this.documentsWithViewAccess.length) {
          this.dialogRef.close(true);
        }
      }
    }
    else {
      this.dialogRef.close(true)
    }
  }
  onNoClick() {
     if (this.data.type === 'taskViewAccess') {
      this.dialogRef.close(true);
    } else {
      this.dialogRef.close(false);
    }
  }
  onCloseDialog(): void {
    if(this.data.type === 'documentViewAccess') {
      this.dialogRef.close('')
    }
    else {

      this.dialogRef.close(false);
    }
}
onOkClick() {
  if(this.data.type === 'documentViewAccess') {

    this._store.dispatch(new AddViewAccessForDocumentStart(this.data.response))
  }

  this.dialogRef.close(true);
}

}
