import {
  CreateEvent,
  CreateEventSuccess,
  EventActionTypes,
  FavListSuccess,
  FavMemberListSuccess,
  SubmitRsvpSuccess,
  addCommentsSuccess,
  addImageAndVideosSuccess,
  attendeelistSuccess,
  claimReassignRequestSuccess,
  deleteApproverReportuserSuccess,
  deleteApproveruserSuccess,
  deleteEndorserReportuserSuccess,
  deleteEndorseruserSuccess,
  deleteFeedbackFieldSuccess,
  deleteInvteeSuccess,
  deleteRegistrationFieldSuccess,
  deletefeedbackuserSuccess,
  donotattendSuccess,
  editfeedbackuserSuccess,
  eventApproveRejectSuccess,
  eventEndorseRejectSuccess,
  eventReportApproveRejectSuccess,
  eventReportEndorseRejectSuccess,
  feedbackmailsentSuccess,
  feedbackuserListSuccess,
  forApprovaListSuccess,
  forEndorsementListSuccess,
  gcencelEventSuccess,
  getCalenderListBySuccess,
  getEventCommentsSuccess,
  getEventDetailByIdSuccess,
  getEventFeedbackDetailsSuccess,
  getEventListBySuccess,
  getEventReportApprovarSuccess,
  getEventReportDetailsSuccess,
  getEventReportEndorserSuccess,
  getEventReportListBySuccess,
  getFeedbackListBySuccess,
  getFeedbackTemplateFieldSuccess,
  getFieldTemplateSuccess,
  getInviteeMemberListByIdSuccess,
  getQRSuccess,
  getfeedbackListSuccess,
  insertInviteesToEventSuccess,
  loglistSuccess,
  markasCompleteSuccess,
  removeApproverAndEndorserSuccess,
  removeImageAndVideosSuccess,
  rsvpListSuccess,
  submitApproverEndorserSuccess,
  submitEventReportSuccess,
  submitRsvpfeedbackSuccess,
  submitforapprovalReportSuccess,
  submitforapprovalSuccess,
  submitforscededuleSuccess,
  updateEventReportSuccess,
  updateOwnerSuccess,
} from "./event.actions";
import { Action } from "@ngrx/store";
export interface EventManagement {
  getFieldList: Array<any>;
  event: {};
  fav_list: Array<any>;
  fav_member_list: Array<any>;
  memberlist: Array<any>;
  getEventById: {};
  forApprovallist: {};
  forEndorsementlist: {};
  getFeedbackTemplateFields: Array<any>;
  commentList: Array<any>;
  eventFeedback: {};
  eventReport: {};
  submitAppEnd: {};
  imageVideoUploadResponse: {};
  removeImageVideoUploadResponse: {};
  eventReportRespomse: Array<any>;
  forReportApprovallist: Array<any>;
  forReportEndorsementlist: Array<any>;
  updatedeventReport: {};
  eventSuccess: {};
  deleteApprovarEndorserStatus: {};
  rsvpData: {};
  CalenderListData: {};
  feedbackData: {};
  EventReportListData: {};
  eventApproveRejectData: {};
  eventEndorseRejectData: {};
  eventApproveReject: {};
  eventEndorseReject: {};
  submitforAprovalRes: {};
  submitRsbp: {};
  feedbackListResponse: {};
  addCommentResponse: {};
  cenceleventResponse: {};
  logListResponse: {};
  deleteInviteeResp: {};
  updateInviteeResp: {};
  claimAssignEventResponse: {};
  updateOwnerResponse: {};
  submitforApprovalResp: {};
  submitforscededuleResp: {};
  donotattendResponse: {};
  attendeelistResponse: {};
  userfeedbackUserListResp: {};
  userfeedbackUserADDResp: {};
  userfeedbackUsereditResp: {};
  userfeedbackUserdeleteResp: {};
  feedbackmailSentResponse: {};
  feedbackSubmissionResponse: {};
  showQRCodeResponse: {};
  deleteApproverUserResponse: {};
  deleteEndorserUserResponse: {};
  deleteRegisterFormFieldResponse: {};
  deleteFeedbackFormTemplateResponse: {};
  markAsAcompleteResponse: {};
}

const intiState = {
  event: {},
  fav_list: [],
  fav_member_list: [],
  memberlist: [],
  eventSuccess: {},
  getFieldList: [],
  getEventById: {},
  forApprovallist: {},
  forEndorsementlist: {},
  getFeedbackTemplateFields: [],
  commentList: [],
  eventFeedback: {},
  eventReport: {},
  submitAppEnd: {},
  imageVideoUploadResponse: {},
  removeImageVideoUploadResponse: {},
  eventReportRespomse: [],
  forReportApprovallist: [],
  forReportEndorsementlist: [],
  updatedeventReport: {},
  deleteApprovarEndorserStatus: {},
  rsvpData: {},
  CalenderListData: {},
  feedbackData: {},
  EventReportListData: {},
  eventApproveRejectData: {},
  eventEndorseRejectData: {},
  eventApproveReject: {},
  eventEndorseReject: {},
  submitforAprovalRes: {},
  submitRsbp: {},
  addCommentResponse: {},
  feedbackListResponse: {},
  cenceleventResponse: {},
  logListResponse: {},
  deleteInviteeResp: {},
  updateInviteeResp: {},
  claimAssignEventResponse: {},
  updateOwnerResponse: {},
  submitforApprovalResp: {},
  submitforscededuleResp: {},
  donotattendResponse: {},
  attendeelistResponse: {},
  userfeedbackUserListResp: {},
  userfeedbackUserADDResp: {},
  userfeedbackUsereditResp: {},
  userfeedbackUserdeleteResp: {},
  feedbackmailSentResponse: {},
  feedbackSubmissionResponse: {},
  showQRCodeResponse: {},
  deleteApproverUserResponse: {},
  deleteEndorserUserResponse: {},
  deleteRegisterFormFieldResponse: {},
  deleteFeedbackFormTemplateResponse: {},
  markAsAcompleteResponse: {},
};

export function reducer(
  state: EventManagement = intiState,
  action: Action
): EventManagement {
  switch (action.type) {
    case EventActionTypes.CREATE_EVENT_SUCCESS:
      return Object.assign({}, state, {
        event: (action as CreateEventSuccess).response,
      });
    case EventActionTypes.GET_EVENT_LIST_BY_SUCCESS:
      return Object.assign({}, state, {
        eventSuccess: (action as getEventListBySuccess).response,
      });
    case EventActionTypes.GET_FAV_LIST_SUCCESS:
      return Object.assign({}, state, {
        fav_list: (action as FavListSuccess).response.data,
      });
    case EventActionTypes.GET_FAV_MEMBER_LIST_SUCCESS:
      return Object.assign({}, state, {
        fav_member_list: (action as FavMemberListSuccess).response.data,
      });
    case EventActionTypes.GET_INVITEE_MEMBER_LIST_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        memberlist: (action as getInviteeMemberListByIdSuccess).response.data,
      });
    case EventActionTypes.GET_TEMPLATE_FIELD_SUCCESS:
      return Object.assign({}, state, {
        getFieldList: (action as getFieldTemplateSuccess).response.data,
      });
    case EventActionTypes.GET_EVENT_DETAILS_BY_ID_SUCCESS:
      return Object.assign({}, state, {
        getEventById: (action as getEventDetailByIdSuccess).response.data,
      });
    case EventActionTypes.GET_FOR_APPROVAL_LIST_SUCCESS:
      return Object.assign({}, state, {
        forApprovallist: (action as forApprovaListSuccess).response,
      });

    case EventActionTypes.GET_FOR_ENDORSEMENT_LIST_SUCCESS:
      return Object.assign({}, state, {
        forEndorsementlist: (action as forEndorsementListSuccess).response,
      });
    case EventActionTypes.GET_FEEDBACK_TEMPLATE_FIELD_SUCCESS:
      console.log(
        "Response reducer",
        (action as getFeedbackTemplateFieldSuccess).response
      );
      return Object.assign({}, state, {
        getFeedbackTemplateFields: (action as getFeedbackTemplateFieldSuccess)
          .response.data,
      });
    case EventActionTypes.GET_EVENTS_COMMENTS_SUCCESS:
      console.log(
        "Response reducer",
        (action as getEventCommentsSuccess).response
      );
      return Object.assign({}, state, {
        commentList: (action as getEventCommentsSuccess).response.comments,
      });
    case EventActionTypes.GET_EVENTS_FEEDBACK_SUCCESS:
      return Object.assign({}, state, {
        eventFeedback: (action as getEventCommentsSuccess).response.data,
      });
    case EventActionTypes.SUBMIT_EVENT_REPORT_SUCCESS:
      return Object.assign({}, state, {
        eventReport: (action as submitEventReportSuccess).response,
      });
    case EventActionTypes.SUBMIT_APPROVER_ENDORSER_REPORT_SUCCESS:
      return Object.assign({}, state, {
        submitAppEnd: (action as submitApproverEndorserSuccess).response,
      });
    case EventActionTypes.ADD_VIDEO_IMAGE_REPORT_SUCCESS:
      return Object.assign({}, state, {
        imageVideoUploadResponse: (action as addImageAndVideosSuccess).response
          .data,
      });
    case EventActionTypes.REMOVE_VIDEO_IMAGE_REPORT_SUCCESS:
      return Object.assign({}, state, {
        removeImageVideoUploadResponse: (action as removeImageAndVideosSuccess)
          .response,
      });
    case EventActionTypes.GET_EVENTS_REPORT_SUCCESS:
      return Object.assign({}, state, {
        eventReportRespomse: (action as getEventReportDetailsSuccess).response
          .data,
      });
    case EventActionTypes.GET_EVENTS_APPROVER_SUCCESS:
      return Object.assign({}, state, {
        forReportApprovallist: (action as getEventReportApprovarSuccess)
          .response,
      });
    case EventActionTypes.GET_EVENTS_ENDORSER_SUCCESS:
      return Object.assign({}, state, {
        forReportEndorsementlist: (action as getEventReportEndorserSuccess)
          .response,
      });
    case EventActionTypes.UPDATE_EVENT_REPORT_SUCCESS:
      return Object.assign({}, state, {
        updatedeventReport: (action as updateEventReportSuccess).response,
      });
    case EventActionTypes.DELETE_APPROVER_ENDORSER_IN_EVENT_SUCCESS:
      return Object.assign({}, state, {
        deleteApprovarEndorserStatus: (
          action as removeApproverAndEndorserSuccess
        ).response,
      });
    case EventActionTypes.GET_RSVP_LIST_SUCCESS:
      return Object.assign({}, state, {
        rsvpData: (action as rsvpListSuccess).response,
      });
    case EventActionTypes.GET_FEEDBACK_LIST_SUCCESS:
      return Object.assign({}, state, {
        feedbackData: (action as getFeedbackListBySuccess).response,
      });
    case EventActionTypes.GET_CALENDER_LIST_SUCCESS:
      return Object.assign({}, state, {
        CalenderListData: (action as getCalenderListBySuccess).response,
      });

    case EventActionTypes.EVENT_APPROVE_REJECT_SUCCESS:
      return Object.assign({}, state, {
        eventApproveReject: (action as eventApproveRejectSuccess).response,
      });
    case EventActionTypes.EVENT_ENDORSE_REJECT_SUCCESS:
      return Object.assign({}, state, {
        eventEndorseReject: (action as eventEndorseRejectSuccess).response,
      });

    case EventActionTypes.EVENT_REPORT_LIST_SUCCESS:
      return Object.assign({}, state, {
        EventReportListData: (action as getEventReportListBySuccess).response,
      });
    case EventActionTypes.EVENT_REPORT_APPROVE_REJECT_SUCCESS:
      return Object.assign({}, state, {
        eventApproveRejectData: (action as eventReportApproveRejectSuccess)
          .response,
      });
    case EventActionTypes.EVENT_REPORT_ENDORSE_REJECT_SUCCESS:
      return Object.assign({}, state, {
        eventEndorseRejectData: (action as eventReportEndorseRejectSuccess)
          .response,
      });
    case EventActionTypes.SUBMIT_FOR_APPROVAL_REPORT_SUCCESS:
      return Object.assign({}, state, {
        submitforAprovalRes: (action as submitforapprovalReportSuccess)
          .response,
      });
    case EventActionTypes.SUBMIT_RSVP_SUCCESS:
      return Object.assign({}, state, {
        submitRsbp: (action as SubmitRsvpSuccess).response,
      });
    case EventActionTypes.ADD_COMMENTS_SUCCESS:
      return Object.assign({}, state, {
        addCommentResponse: (action as addCommentsSuccess).response,
      });
    case EventActionTypes.EVENT_FEEDBACK_LIST_SUCCESS:
      return Object.assign({}, state, {
        feedbackListResponse: (action as getfeedbackListSuccess).response,
      });
    case EventActionTypes.CANCEL_EVENT_SUCCESS:
      return Object.assign({}, state, {
        cenceleventResponse: (action as gcencelEventSuccess).response,
      });
    case EventActionTypes.LOG_LIST_SUCCESS:
      return Object.assign({}, state, {
        logListResponse: (action as loglistSuccess).response,
      });
    case EventActionTypes.DELETE_INVITEES_SUCCESS:
      return Object.assign({}, state, {
        deleteInviteeResp: (action as deleteInvteeSuccess).response,
      });
    case EventActionTypes.INSERT_INVITEES_TO_EVENT_SUCCESS:
      return Object.assign({}, state, {
        updateInviteeResp: (action as insertInviteesToEventSuccess).response,
      });
    case EventActionTypes.CLAIM_REASSIGN_REQUEST_EVENT_SUCCESS:
      return Object.assign({}, state, {
        claimAssignEventResponse: (action as claimReassignRequestSuccess)
          .response,
      });
    case EventActionTypes.UPDATE_OWNER_EVENT_SUCCESS:
      return Object.assign({}, state, {
        updateOwnerResponse: (action as updateOwnerSuccess).response,
      });
    case EventActionTypes.SUBMIT_FOR_APPROVAL_SUCCESS:
      return Object.assign({}, state, {
        submitforApprovalResp: (action as submitforapprovalSuccess).response,
      });
    case EventActionTypes.EVENT_SCHEDULED_SUCCESS:
      return Object.assign({}, state, {
        submitforscededuleResp: (action as submitforscededuleSuccess).response,
      });

    case EventActionTypes.GET_EVENT_DETAILS_CLEAR:
      return { ...state, getEventById: {} };

    case EventActionTypes.EVENT_APPROVE_REJECT_CLEAR:
      return { ...state, eventApproveReject: {} };

    case EventActionTypes.EVENT_ENDORSE_REJECT_CLEAR:
      return { ...state, eventEndorseReject: {} };

    case EventActionTypes.EVENT_COMMENTS_CLEAR:
      return { ...state, commentList: [] };

    case EventActionTypes.EVENT_LOG_CLEAR:
      return { ...state, logListResponse: {} };

    case EventActionTypes.COMMENT_SUBMISSION_CLEAR:
      return { ...state, addCommentResponse: {} };

    case EventActionTypes.DONT_WANT_ATTEND_SUCCESS:
      return Object.assign({}, state, {
        donotattendResponse: (action as donotattendSuccess).response,
      });
    case EventActionTypes.ATTENDEE_LIST_SUCCESS:
      return Object.assign({}, state, {
        attendeelistResponse: (action as attendeelistSuccess).response,
      });

    case EventActionTypes.FEEDBACK_USER_LIST_SUCCESS:
      return Object.assign({}, state, {
        userfeedbackUserListResp: (action as feedbackuserListSuccess).response,
      });

    case EventActionTypes.ADD_FEEDBACK_USER_SUCCESS:
      return Object.assign({}, state, {
        userfeedbackUserADDResp: (action as feedbackuserListSuccess).response,
      });

    case EventActionTypes.EDIT_FEEDBACK_USER_SUCCESS:
      return Object.assign({}, state, {
        userfeedbackUsereditResp: (action as editfeedbackuserSuccess).response,
      });

    case EventActionTypes.DELETE_FEEDBACK_USER_SUCCESS:
      return Object.assign({}, state, {
        userfeedbackUserdeleteResp: (action as deletefeedbackuserSuccess)
          .response,
      });

    case EventActionTypes.FEEDBACK_MAIL_SENT_SUCCESS:
      return Object.assign({}, state, {
        feedbackmailSentResponse: (action as feedbackmailsentSuccess).response,
      });

    case EventActionTypes.SUBMIT_RSVP_FEEDBACK_SUCCESS:
      return Object.assign({}, state, {
        feedbackSubmissionResponse: (action as submitRsvpfeedbackSuccess)
          .response,
      });

    case EventActionTypes.GET_QR_OF_EVENT_SUCCESS:
      return Object.assign({}, state, {
        showQRCodeResponse: action as getQRSuccess,
      });

    case EventActionTypes.DELETE_APPROVAR_FROM_CR_EVENT_SUCCESS:
      return Object.assign({}, state, {
        deleteApproverUserResponse: (action as deleteApproveruserSuccess)
          .response,
      });

    case EventActionTypes.DELETE_ENDORSER_FROM_CR_EVENT_SUCCESS:
      return Object.assign({}, state, {
        deleteEndorserUserResponse: (action as deleteEndorseruserSuccess)
          .response,
      });

    case EventActionTypes.DELETE_REGISTRAION_TEMPLATE_FIELD_SUCCESS:
      return Object.assign({}, state, {
        deleteRegisterFormFieldResponse: (
          action as deleteRegistrationFieldSuccess
        ).response,
      });

    case EventActionTypes.DELETE_FEEDBACK_TEMPLATE_FIELD_SUCCESS:
      return Object.assign({}, state, {
        deleteFeedbackFormTemplateResponse: (
          action as deleteFeedbackFieldSuccess
        ).response,
      });

    case EventActionTypes.MARK_AS_A_COMPLETE_SUCCESS:
      return Object.assign({}, state, {
        markAsAcompleteResponse: (action as markasCompleteSuccess).response,
      });

      case EventActionTypes.DELETE_APPROVAR_FROM_CR_EVENT_REPORT_SUCCESS:
        return Object.assign({}, state, {
          deleteApproverUserReportResponse: (action as deleteApproverReportuserSuccess),
        });
  
      case EventActionTypes.DELETE_ENDORSER_FROM_CR_EVENT_REPORT_SUCCESS:
        return Object.assign({}, state, {
          deleteEndorserUserReportResponse: (action as deleteEndorserReportuserSuccess),
        });

    default:
      return state;
  }
}
