import {
  EventActionTypes,
  CreateEvent,
  CreateEventSuccess,
  FavList,
  FavListSuccess,
  CreatefavGroupInvitee,
  CreatefavGroupInviteeSuccess,
  getInviteeMemberListById,
  getInviteeMemberListByIdSuccess,
  insertInviteesToEvent,
  insertInviteesToEventSuccess,
  EventList,
  getEventListBySuccess,
  insertEndAndAPRToEvent,
  insertEndAndAPRToEventSuccess,
  AddnewfieldEvent,
  AddnewfieldEventSuccess,
  getFieldTemplate,
  getFieldTemplateSuccess,
  getEventDetailsById,
  getEventDetailByIdSuccess,
  updateMemberlist,
  updateMemberlistSuccess,
  deleteMemberlist,
  deleteMemberlistSuccess,
  updateEmailTemplateSuccess,
  updateEmailTemplate,
  submitforapproval,
  submitforapprovalSuccess,
  forApprovaList,
  forApprovaListSuccess,
  UpdateEvent,
  UpdateEventSuccess,
  eventApproveReject,
  eventApproveRejectSuccess,
  forEndorsementList,
  forEndorsementListSuccess,
  filterEndorsementList,
  filterApprovalList,
  eventEndorseReject,
  eventEndorseRejectSuccess,
  inviteesMailSent,
  inviteesMailSentSuccess,
  SubmitRsvp,
  SubmitRsvpSuccess,
  AddFeedbackTemplateField,
  AddFeedbackTemplateFieldSuccess,
  getFeedbackTemplateFieldSuccess,
  GetFeedbackTemplateField,
  updateFeedbackTemplate,
  updateFeedbackTemplateSuccess,
  updateEmailTemplateFieldSuccess,
  updateEmailTemplateField,
  submitRsvpfeedbackSuccess,
  submitRsvpfeedback,
  updateFeedbackTemplateField,
  updateFeedbackTemplateFieldSuccess,
  getEventComments,
  getEventCommentsSuccess,
  addCommentsSuccess,
  addComments,
  getEventFeedbackDetails,
  getEventFeedbackDetailsSuccess,
  submitEventReport,
  submitEventReportSuccess,
  submitApproverEndorserSuccess,
  submitApproverEndorser,
  addImageAndVideosSuccess,
  addImageAndVideos,
  removeImageAndVideos,
  removeImageAndVideosSuccess,
  getEventReportDetails,
  getEventReportDetailsSuccess,
  getEventReportEndorser,
  getEventReportEndorserSuccess,
  getEventReportApprovarSuccess,
  getEventReportApprovar,
  updateEventReport,
  updateEventReportSuccess,
  removeApproverAndEndorser,
  removeApproverAndEndorserSuccess,
  rsvpList,
  rsvpListSuccess,
  FeedbackList,
  getFeedbackListBySuccess,
  CalenderList,
  getCalenderListBySuccess,
  eventReportApproveReject,
  eventReportApproveRejectSuccess,
  eventReportEndorseReject,
  eventReportEndorseRejectSuccess,
  getEventReportList,
  getEventReportListBySuccess,
  submitforapprovalReport,
  submitforapprovalReportSuccess,
  getfeedbackList,
  cencelEvent,
  gcencelEventSuccess,
  getfeedbackListSuccess,
  loglist,
  loglistSuccess,
  deleteInvtee,
  deleteInvteeSuccess,
  updateOwner,
  updateOwnerSuccess,
  claimReassignRequest,
  claimReassignRequestSuccess,
  submitforscededule,
  submitforscededuleSuccess,
  donotattend,
  donotattendSuccess,
  attendeelist,
  attendeelistSuccess,
  feedbackuserList,
  feedbackuserListSuccess,
  addfeedbackuser,
  addfeedbackuserSuccess,
  editfeedbackuser,
  editfeedbackuserSuccess,
  deletefeedbackuser,
  deletefeedbackuserSuccess,
  feedbackmailsent,
  feedbackmailsentSuccess,
  getQRSuccess,
  getQR,
  deleteApproveruser,
  deleteApproveruserSuccess,
  deleteEndorseruser,
  deleteEndorseruserSuccess,
  deleteRegistrationField,
  deleteRegistrationFieldSuccess,
  deleteFeedbackField,
  deleteFeedbackFieldSuccess,
  markasComplete,
  markasCompleteSuccess,
  deleteApproverReportuser,
  deleteApproverReportuserSuccess,
  deleteEndorserReportuser,
  deleteEndorserReportuserSuccess,
} from "./event.actions";

import { Injectable } from "@angular/core";
import { API } from "src/app/services/Api.service";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { switchMap, map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { Location } from "@angular/common";
import { ErrorAction } from "src/app/login/store/auth.actions";
import { NotificationsService } from "angular2-notifications";
import { AppState } from "src/app/app.reducer";
import { Store } from "@ngrx/store";
import { CommonSuccessAction } from "../../resources/_store/resources.actions";
import { LoaderHide } from "src/app/features/loader/store/loader.actions";
@Injectable()
export class EventEffects {

  
  @Effect()
  createEvent$ = this.action$.pipe(
    ofType(EventActionTypes.CREATE_EVENT),
    switchMap((action: CreateEvent) => {
      console.log("Event Create", action.payload);
      return this.api.createEvent(action.payload).pipe(
        map((response: any) => {
          // this.location.back();
          this.notificationService.success(`Event details saved successfully`);

          return new CreateEventSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );



  @Effect()
  getFavMemberList$ = this.action$.pipe(
    ofType(EventActionTypes.GET_FAV_MEMBER_LIST),
    switchMap((action: CreateEvent) => {
      console.log("Event Create", action.payload);
      return this.api.getFavoriteMemberList(action.payload).pipe(
        map((response: any) => {
          // this.location.back();
          // this.notificationService.success(`Event detail saved successfully`);

          return new FavListSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  getFavList$ = this.action$.pipe(
    ofType(EventActionTypes.GET_FAV_LIST),
    switchMap((action: FavList) => {
      return this.api.getFavoriteList().pipe(
        map((response: any) => {
          // this.location.back();

          return new FavListSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  createFevGroupInvitee$ = this.action$.pipe(
    ofType(EventActionTypes.CREATE_FAV_GROUP_INVITIEE),
    switchMap((action: CreatefavGroupInvitee) => {
      return this.api.createFavoriteGroupInvitee(action.payload).pipe(
        map((response: any) => {
          this.notificationService.success(`Favorite invitee list created`);
          return new CreatefavGroupInviteeSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  getInviteeMemberListById$ = this.action$.pipe(
    ofType(EventActionTypes.GET_INVITEE_MEMBER_LIST_BY_ID),
    switchMap((action: getInviteeMemberListById) => {
      return this.api.getMemberbyIds(action.payload).pipe(
        map((response: any) => {
          // this.notificationService.success(`Fetched member list`);
          return new getInviteeMemberListByIdSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  taskBulkUpload$ = this.action$.pipe(
    ofType(EventActionTypes.INSERT_INVITEES_TO_EVENT),
    switchMap((action: insertInviteesToEvent) => {
      return this.api.insert_invitees_To_Event(action.payload, action.Id).pipe(
        map((response: any) => {
          // this.location.back();
          this.notificationService.success(`Invitee list added successfully`);
          // this.store.dispatch(new GetUsers({ limit: 40, page: 1 }));
          // this.store.dispatch(new GetESIndicatorsList(action.projId))
          return new insertInviteesToEventSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );


  @Effect()
  delteInviteelist$ = this.action$.pipe(
    ofType(EventActionTypes.DELETE_INVITEES),
    switchMap((action: deleteInvtee) => {
      return this.api.deleteInvitee(action.eventId,action.inviteId).pipe(
        map((response: any) => {
          this.notificationService.success(`Invitee deleted successfully`);
          return new deleteInvteeSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );




  @Effect()
  getEventList$ = this.action$.pipe(
    ofType(EventActionTypes.GET_EVENT_LIST),
    switchMap((action: EventList) => {
      console.log(action.eventListType);

      return this.api.getEventList(action.payload, action.eventListType).pipe(
        map((response: any) => {
          // this.location.back();
          this.store.dispatch(new LoaderHide());

          return new getEventListBySuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  EventAPPUpload$ = this.action$.pipe(
    ofType(EventActionTypes.INSERT_End_AND_APR_TO_EVENT),
    switchMap((action: insertEndAndAPRToEvent) => {
      // console.log('hi')
      return this.api.insert_end_appr_To_Event(action.payload, action.Id).pipe(
        map((response: any) => {
          // this.location.back();
          this.notificationService.success(
            `Event submitted successfully`
          );
          // this.store.dispatch(new GetUsers({ limit: 40, page: 1 }));
          // this.store.dispatch(new GetESIndicatorsList(action.projId))
          return new insertEndAndAPRToEventSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  AddField$ = this.action$.pipe(
    ofType(EventActionTypes.INSERT_NEW_FIELD_TO_EVENT),
    switchMap((action: AddnewfieldEvent) => {
      // console.log('hi')
      return this.api.addnewField(action.payload, action.Id).pipe(
        map((response: any) => {
          // this.location.back();
          // this.notificationService.success(`Event Approver Endoser sent successfully`);
          // this.store.dispatch(new GetUsers({ limit: 40, page: 1 }));
          // this.store.dispatch(new GetESIndicatorsList(action.projId))
          return new AddnewfieldEventSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  getField$ = this.action$.pipe(
    ofType(EventActionTypes.GET_TEMPLATE_FIELD),
    switchMap((action: getFieldTemplate) => {
      return this.api.getTemplatefield(action.Id).pipe(
        map((response: any) => {
          // this.notificationService.success(`Field Fetch successfully`);
          return new getFieldTemplateSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  getEventDetailsById12$ = this.action$.pipe(
    ofType(EventActionTypes.GET_EVENT_DETAILS_BY_ID),
    switchMap((action: getEventDetailsById) => {
      return this.api.getEventDetailsByIdapi(action.Id).pipe(
        map((response: any) => {
          return new getEventDetailByIdSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  marksasaComplete$ = this.action$.pipe(
    ofType(EventActionTypes.MARK_AS_A_COMPLETE),
    switchMap((action:markasComplete ) => {
      return this.api.markAsAcomplete(action.Id).pipe(
        map((response: any) => {
          return new markasCompleteSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  updatemember$ = this.action$.pipe(
    ofType(EventActionTypes.UPDATE_MEMBER_LIST),
    switchMap((action: updateMemberlist) => {
      return this.api.updateInviteeMemberList(action.payload, action.Id).pipe(
        map((response: any) => {
          this.notificationService.success(`Invitee list updated successfully`);
          return new updateMemberlistSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  delteMemberlist$ = this.action$.pipe(
    ofType(EventActionTypes.DELETE_MEMBER_LIST),
    switchMap((action: deleteMemberlist) => {
      return this.api.deleteMemberGroup(action.Id).pipe(
        map((response: any) => {
          this.notificationService.success(`Invitee list deleted successfully`);
          return new deleteMemberlistSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  updatetemplate$ = this.action$.pipe(
    ofType(EventActionTypes.UPDATE_EMAIL_TEMPLATE),
    switchMap((action: updateEmailTemplate) => {
      return this.api.emailTemplateRegUpdate(action.payload, action.Id).pipe(
        map((response: any) => {
          this.notificationService.success(
            `Registration template updated successfully`
          );
          return new updateEmailTemplateSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  forApproval$ = this.action$.pipe(
    ofType(EventActionTypes.SUBMIT_FOR_APPROVAL),
    switchMap((action: submitforapproval) => {
      return this.api.submitforapproval(action.Id).pipe(
        map((response: any) => {
          this.notificationService.success(`Event submitted for approval`);
          return new submitforapprovalSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  forApprovalList$ = this.action$.pipe(
    ofType(EventActionTypes.GET_FOR_APPROVAL_LIST),
    switchMap((action: forApprovaList) => {
      return this.api.forApprovalList(action.payload).pipe(
        map((response: any) => {
          return new forApprovaListSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  updateEventDetails$ = this.action$.pipe(
    ofType(EventActionTypes.UPDATE_EVENT),
    switchMap((action: UpdateEvent) => {
      return this.api.updateEventDetails(action.payload).pipe(
        map((response: any) => {
          return new UpdateEventSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  eventapproveandreject$ = this.action$.pipe(
    ofType(EventActionTypes.EVENT_APPROVE_REJECT),
    switchMap((action: eventApproveReject) => {
      return this.api.eventApproveAndReject(action.Id, action.action_type).pipe(
        map((response: any) => {
          return new eventApproveRejectSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  eventEndorseandreject$ = this.action$.pipe(
    ofType(EventActionTypes.EVENT_ENDORSE_REJECT),
    switchMap((action: eventEndorseReject) => {
      return this.api.eventEndroseAndReject(action.Id, action.action_type).pipe(
        map((response: any) => {
          return new eventEndorseRejectSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  forEndorseList$ = this.action$.pipe(
    ofType(EventActionTypes.GET_FOR_ENDORSEMENT_LIST),
    switchMap((action: forEndorsementList) => {
      return this.api.forEndorsementList(action.payload).pipe(
        map((response: any) => {
          return new forEndorsementListSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  filterEndorseList$ = this.action$.pipe(
    ofType(EventActionTypes.GET_FOR_ENDORSEMENT_LIST_FILTER),
    switchMap((action: filterEndorsementList) => {
      return this.api.filterEndorsementList(action.payload).pipe(
        map((response: any) => {
          return new forEndorsementListSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  filterApprovalList$ = this.action$.pipe(
    ofType(EventActionTypes.GET_FOR_APPROVAL_LIST_FILTER),
    switchMap((action: filterApprovalList) => {
      return this.api.filterApprovalList(action.payload).pipe(
        map((response: any) => {
          return new forApprovaListSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  sentMail$ = this.action$.pipe(
    ofType(EventActionTypes.INVITEE_MAIL_SENT),
    switchMap((action: inviteesMailSent) => {
      return this.api.eventsendMails(action.Id).pipe(
        map((response: any) => {
          this.notificationService.success(`Emails sent successfully`);
          return new inviteesMailSentSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  submitRsvp$ = this.action$.pipe(
    ofType(EventActionTypes.SUBMIT_RSVP),
    switchMap((action: SubmitRsvp) => {
      return this.api.submitRsvp(action.payload, action.Id).pipe(
        map((response: any) => {
          // this.notificationService.success(
          //   "Thank you for registering for the event. Your registration is confirmed."
          // );
          return new SubmitRsvpSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  addFeedbackTemplateField$ = this.action$.pipe(
    ofType(EventActionTypes.INSERT_NEW_FIELD_TO_FEEDBACK),
    switchMap((action: AddFeedbackTemplateField) => {
      // console.log('hi')
      return this.api.addFeedbackTemplateField(action.payload, action.Id).pipe(
        map((response: any) => {
          // this.location.back();
          // this.notificationService.success(`Event Approver Endoser sent successfully`);
          // this.store.dispatch(new GetUsers({ limit: 40, page: 1 }));
          // this.store.dispatch(new GetESIndicatorsList(action.projId))
          return new AddFeedbackTemplateFieldSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  getFeedbackTemplateFields$ = this.action$.pipe(
    ofType(EventActionTypes.GET_FEEDBACK_TEMPLATE_FIELD),
    switchMap((action: GetFeedbackTemplateField) => {
      return this.api.getFeedbackTemplateFields(action.Id).pipe(
        map((response: any) => {
          return new getFeedbackTemplateFieldSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  updatefeedbacktemplate$ = this.action$.pipe(
    ofType(EventActionTypes.UPDATE_FEEDBACK_TEMPLATE),
    switchMap((action: updateFeedbackTemplate) => {
      return this.api.emailfeedbackRegUpdate(action.payload, action.Id).pipe(
        map((response: any) => {
          this.notificationService.success(
            `Feedback template updated successfully`
          );
          return new updateFeedbackTemplateSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  updateEmailTemplateField$ = this.action$.pipe(
    ofType(EventActionTypes.UPDATE_EMAIL_TEMPLATE_FIELD),
    switchMap((action: updateEmailTemplateField) => {
      return this.api.updateEmailTemplateField(action.payload, action.Id).pipe(
        map((response: any) => {
          // this.notificationService.success(
          //   ` template field Update successfully`
          // );
          return new updateEmailTemplateFieldSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );


  @Effect()
  submitRsvpfeedback$ = this.action$.pipe(
    ofType(EventActionTypes.SUBMIT_RSVP_FEEDBACK),
    switchMap((action: submitRsvpfeedback) => {
      return this.api.submitRsvpfeedback(action.payload, action.Id).pipe(
        map((response: any) => {
          this.notificationService.success(
            "Feedback data saved successfully"
          );
          return new submitRsvpfeedbackSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );


  @Effect()
  updateFeedbackTemplateField$ = this.action$.pipe(
    ofType(EventActionTypes.UPDATE_FEEDBACK_TEMPLATE_FIELD),
    switchMap((action: updateFeedbackTemplateField) => {
      return this.api.updatefeedbackFieldTemplateField(action.payload, action.Id).pipe(
        map((response: any) => {
          // this.notificationService.success(
          //   ` template field Update successfully`
          // );
          return new updateFeedbackTemplateFieldSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  addcomments$ = this.action$.pipe(
    ofType(EventActionTypes.ADD_COMMENTS),
    switchMap((action: addComments) => {
      return this.api.addComments(action.payload).pipe(
        map((response: any) => {
          this.notificationService.success(
            `Comment added successfully`
          );
          return new addCommentsSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  getEventsComments$ = this.action$.pipe(
    ofType(EventActionTypes.GET_EVENTS_COMMENTS),
    switchMap((action: getEventComments) => {
      return this.api.getCommentlist(action.Id).pipe(
        map((response: any) => {
          // this.notificationService.success(
          //   `Comments fetched successfully`
          // );
          return new getEventCommentsSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );


  @Effect()
  getEventsFeedback$ = this.action$.pipe(
    ofType(EventActionTypes.GET_EVENTS_FEEDBACK),
    switchMap((action: getEventFeedbackDetails) => {
      return this.api.feedbackDetails(action.Id).pipe(
        map((response: any) => {
          // this.notificationService.success(
          //   `Comments fetched successfully`
          // );
          return new getEventFeedbackDetailsSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );


  @Effect()
  submitEventReport$ = this.action$.pipe(
    ofType(EventActionTypes.SUBMIT_EVENT_REPORT),
    switchMap((action: submitEventReport) => {
      return this.api.submitEventReport(action.payload, action.Id).pipe(
        map((response: any) => {
          this.notificationService.success(
            ` Event report added successfully`
          );
          return new submitEventReportSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );


  @Effect()
  reportAppEnd$ = this.action$.pipe(
    ofType(EventActionTypes.SUBMIT_APPROVER_ENDORSER_REPORT),
    switchMap((action: submitApproverEndorser) => {
      return this.api.submit_end_appr_To_endorser(action.payload, action.Id).pipe(
        map((response: any) => {
          this.notificationService.success(
            `Report approver/endoser added successfully`
          );
          return new submitApproverEndorserSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );


  @Effect()
  addimageVideo$ = this.action$.pipe(
    ofType(EventActionTypes.ADD_VIDEO_IMAGE_REPORT),
    switchMap((action: addImageAndVideos) => {
      return this.api.submit_image_video(action.payload, action.Id).pipe(
        map((response: any) => {
          this.notificationService.success(
            `Video / Image added successfully`
          );
          return new addImageAndVideosSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  removeImageVideo$ = this.action$.pipe(
    ofType(EventActionTypes.REMOVE_VIDEO_IMAGE_REPORT),
    switchMap((action: removeImageAndVideos) => {
      return this.api.remove_image_video(action.eventid, action.fileid).pipe(
        map((response: any) => {
          this.notificationService.success(
            `File removed successfully`
          );
          return new removeImageAndVideosSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );


  
  @Effect()
  getEventsReport$ = this.action$.pipe(
    ofType(EventActionTypes.GET_EVENTS_REPORT),
    switchMap((action:getEventReportDetails ) => {
      return this.api.getEventReport(action.Id).pipe(
        map((response: any) => {
          // this.notificationService.success(
          //   `Comments fetched successfully`
          // );
          return new getEventReportDetailsSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  

  @Effect()
  getEventsApprover$ = this.action$.pipe(
    ofType(EventActionTypes.GET_EVENTS_APPROVER),
    switchMap((action: getEventReportApprovar) => {
      return this.api.reportApproverList(action.payload).pipe(
        map((response: any) => {
          // this.notificationService.success(
          //   `Comments fetched successfully`
          // );
          return new getEventReportApprovarSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  getEventsEndorser$ = this.action$.pipe(
    ofType(EventActionTypes.GET_EVENTS_ENDORSER),
    switchMap((action:getEventReportEndorser ) => {
      return this.api.reportEndorserList(action.payload).pipe(
        map((response: any) => {
          // this.notificationService.success(
          //   `Comments fetched successfully`
          // );
          return new getEventReportEndorserSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );


  @Effect()
  updateEventReport$ = this.action$.pipe(
    ofType(EventActionTypes.UPDATE_EVENT_REPORT),
    switchMap((action: updateEventReport) => {
      return this.api.updateEventReport(action.payload, action.Id).pipe(
        map((response: any) => {
          this.notificationService.success(
            ` Event report updated successfully`
          );
          return new updateEventReportSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );


  @Effect()
  deleteApproverAndEndorser$ = this.action$.pipe(
    ofType(EventActionTypes.DELETE_APPROVER_ENDORSER_IN_EVENT),
    switchMap((action: removeApproverAndEndorser) => {
      return this.api.deleteApprovarandEndorser(action.payload, action.id).pipe(
        map((response: any) => {
          this.notificationService.success(
            "Approver/Endorser deleted successfully"
          );
          return new removeApproverAndEndorserSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );


  @Effect()
  rsvplist$ = this.action$.pipe(
    ofType(EventActionTypes.GET_RSVP_LIST),
    switchMap((action: rsvpList) => {
      return this.api.getListRsvp(action.payload, action.id).pipe(
        map((response: any) => {
          // this.notificationService.success(
          //   "Approver/Endorser deleted successfully"
          // );
          return new rsvpListSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );


  @Effect()
  getfeedbackList$ = this.action$.pipe(
    ofType(EventActionTypes.GET_FEEDBACK_LIST),
    switchMap((action: FeedbackList) => {
      console.log(action.eventListType);

      return this.api.getfeedbackList(action.payload, action.eventListType).pipe(
        map((response: any) => {
          // this.location.back();
          this.store.dispatch(new LoaderHide());

          return new getFeedbackListBySuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  getCalenderList$ = this.action$.pipe(
    ofType(EventActionTypes.GET_CALENDER_LIST),
    switchMap((action: CalenderList) => {
      console.log(action.eventListType);

      return this.api.getCalenderList(action.payload, action.eventListType).pipe(
        map((response: any) => {
          // this.location.back();
          this.store.dispatch(new LoaderHide());

          return new getCalenderListBySuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );



  @Effect()
  eventReportList$ = this.action$.pipe(
    ofType(EventActionTypes.EVENT_REPORT_LIST),
    switchMap((action: getEventReportList) => {
      console.log(action.eventListType);

      return this.api.getEventReportList(action.payload, action.eventListType).pipe(
        map((response: any) => {
          // this.location.back();
          this.store.dispatch(new LoaderHide());

          return new getEventReportListBySuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );



  @Effect()
  eventReportapproveandreject$ = this.action$.pipe(
    ofType(EventActionTypes.EVENT_REPORT_APPROVE_REJECT),
    switchMap((action: eventReportApproveReject) => {
      return this.api.eventReportApproveAndReject(action.Id, action.action_type).pipe(
        map((response: any) => {
          return new eventReportApproveRejectSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  eventReportEndorseandreject$ = this.action$.pipe(
    ofType(EventActionTypes.EVENT_REPORT_ENDORSE_REJECT),
    switchMap((action: eventReportEndorseReject) => {
      return this.api.eventReportEndroseAndReject(action.Id, action.action_type).pipe(
        map((response: any) => {
          return new eventReportEndorseRejectSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  submitforApproval$ = this.action$.pipe(
    ofType(EventActionTypes.SUBMIT_FOR_APPROVAL_REPORT),
    switchMap((action: submitforapprovalReport) => {
      return this.api.submitForApprovalReport(action.Id).pipe(
        map((response: any) => {
          this.notificationService.success(`Event submitted for approval`);
          return new submitforapprovalReportSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  eventfeedabckList$ = this.action$.pipe(
    ofType(EventActionTypes.EVENT_FEEDBACK_LIST),
    switchMap((action: getfeedbackList) => {
      console.log(action.eventListType);

      return this.api.getfeedbackListList(action.payload, action.eventListType).pipe(
        map((response: any) => {
          // this.location.back();
          this.store.dispatch(new LoaderHide());

          return new getfeedbackListSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  cencelEvent$ = this.action$.pipe(
    ofType(EventActionTypes.CANCEL_EVENT),
    switchMap((action: cencelEvent) => {
      return this.api.cencelevent(action.Id).pipe(
        map((response: any) => {
          this.notificationService.success(`Event has been cancelled`);
          return new gcencelEventSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  getloglist$ = this.action$.pipe(
    ofType(EventActionTypes.LOG_LIST),
    switchMap((action: loglist) => {
      return this.api.getLogList(action.Id).pipe(
        map((response: any) => {
          return new loglistSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  changeCliamAndAssign$ = this.action$.pipe(
    ofType(EventActionTypes.CLAIM_REASSIGN_REQUEST_EVENT),
    switchMap((action: claimReassignRequest) => {
      return this.api.changeClaimAndReassign(action.Id,action.action_type).pipe(
        map((response: any) => {
          return new claimReassignRequestSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  updateowner$ = this.action$.pipe(
    ofType(EventActionTypes.UPDATE_OWNER_EVENT),
    switchMap((action: updateOwner) => {
      return this.api.updateOwner(action.event_id,action.Id).pipe(
        map((response: any) => {
          return new updateOwnerSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );


  @Effect()
  submitforSceduled$ = this.action$.pipe(
    ofType(EventActionTypes.EVENT_SCHEDULED),
    switchMap((action: submitforscededule) => {
      // console.log(action.Id);
      
      return this.api.eventSceduled(action.Id).pipe(
        map((response: any) => {
          // this.notificationService.success(`Event submitted for approval`);
          return new submitforscededuleSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );


  @Effect()
  dontattend$ = this.action$.pipe(
    ofType(EventActionTypes.DONT_WANT_ATTEND),
    switchMap((action: donotattend) => {
      // console.log(action.Id);
      
      return this.api.donotattendapi(action.Id,action.inviteeId).pipe(
        map((response: any) => {
          // this.notificationService.success(`Event submitted for approval`);
          return new donotattendSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );


  @Effect()
  attendeelist$ = this.action$.pipe(
    ofType(EventActionTypes.ATTENDEE_LIST),
    switchMap((action: attendeelist) => {
      return this.api.attendeelistApi(action.eventid,action.status,action.paylod).pipe(
        map((response: any) => {
          // this.notificationService.success(`Fetched member list`);
          return new attendeelistSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  feedbackuser$ = this.action$.pipe(
    ofType(EventActionTypes.FEEDBACK_USER_LIST),
    switchMap((action: feedbackuserList) => {
      return this.api.feedbackUserList(action.Id).pipe(
        map((response: any) => {
          return new feedbackuserListSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  addfeedbackuser$ = this.action$.pipe(
    ofType(EventActionTypes.ADD_FEEDBACK_USER),
    switchMap((action: addfeedbackuser) => {
      return this.api.addfeedbackUser(action.Id,action.payload).pipe(
        map((response: any) => {
          return new addfeedbackuserSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  editdfeedbackuser$ = this.action$.pipe(
    ofType(EventActionTypes.EDIT_FEEDBACK_USER),
    switchMap((action: editfeedbackuser) => {
      return this.api.editfeedbackUser(action.Id,action.payload,action.userid).pipe(
        map((response: any) => {
          return new editfeedbackuserSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  deletedfeedbackuser$ = this.action$.pipe(
    ofType(EventActionTypes.DELETE_FEEDBACK_USER),
    switchMap((action: deletefeedbackuser) => {
      return this.api.deletefeedbackUser(action.Id,action.userid).pipe(
        map((response: any) => {
          return new deletefeedbackuserSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  feedbackmailsent$ = this.action$.pipe(
    ofType(EventActionTypes.FEEDBACK_MAIL_SENT),
    switchMap((action: feedbackmailsent) => {
      return this.api.feedbackmailsent(action.Id).pipe(
        map((response: any) => {
          return new feedbackmailsentSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  qrshow$ = this.action$.pipe(
    ofType(EventActionTypes.GET_QR_OF_EVENT),
    switchMap((action: getQR) => {
      return this.api.showQrEvent(action.Id).pipe(
        map((response: any) => {
          console.log(response, 'hjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjjj');
          
          return new getQRSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );


  
  @Effect()
  deletedapproveruser$ = this.action$.pipe(
    ofType(EventActionTypes.DELETE_APPROVAR_FROM_CR_EVENT),
    switchMap((action: deleteApproveruser) => {
      return this.api.deleteApprovar(action.Id,action.userid).pipe(
        map((response: any) => {
          return new deleteApproveruserSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );


  
  @Effect()
  deletedEndorseruser$ = this.action$.pipe(
    ofType(EventActionTypes.DELETE_ENDORSER_FROM_CR_EVENT),
    switchMap((action: deleteEndorseruser) => {
      return this.api.deleteEndorser(action.Id,action.userid).pipe(
        map((response: any) => {
          return new deleteEndorseruserSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  deleteRegistrationField$ = this.action$.pipe(
    ofType(EventActionTypes.DELETE_REGISTRAION_TEMPLATE_FIELD),
    switchMap((action: deleteRegistrationField) => {
      return this.api.deleteRegistration(action.Id,action.fieldId).pipe(
        map((response: any) => {
          return new deleteRegistrationFieldSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );

  @Effect()
  deleteFeedbackField$ = this.action$.pipe(
    ofType(EventActionTypes.DELETE_FEEDBACK_TEMPLATE_FIELD),
    switchMap((action: deleteFeedbackField) => {
      return this.api.deleteFeedbackField(action.Id,action.fieldId).pipe(
        map((response: any) => {
          return new deleteFeedbackFieldSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );



  @Effect()
  deletedapproveruserReport$ = this.action$.pipe(
    ofType(EventActionTypes.DELETE_APPROVAR_FROM_CR_EVENT_REPORT),
    switchMap((action: deleteApproverReportuser ) => {
      return this.api.eventReportApproverDelete(action.Id,action.userid).pipe(
        map((response: any) => {
          return new deleteApproverReportuserSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );


  
  @Effect()
  deletedEndorseruserReport$ = this.action$.pipe(
    ofType(EventActionTypes.DELETE_ENDORSER_FROM_CR_EVENT_REPORT),
    switchMap((action: deleteEndorserReportuser) => {
      return this.api.eventReportEndorserDelete(action.Id,action.userid).pipe(
        map((response: any) => {
          return new deleteEndorserReportuserSuccess(response);
        }),
        catchError((err) => {
          this.notificationService.error(err.error.errors[0].error);
          return of(new ErrorAction(err));
        })
      );
    })
  );





  constructor(
    private api: API,
    private action$: Actions,
    private location: Location,
    private store: Store<AppState>,
    private notificationService: NotificationsService
  ) {}
}
