import { Action } from "@ngrx/store";

export enum BudgetActionTypes {
  DROPDOWN_CATEGORY_LIST = "[dropdown list] dropdown Category List",
  DROPDOWN_CATEGORY_LIST_SUCCESS = "[dropdown list] dropdown Category List Success",
  DROPDOWN_SUB_CATEGORY_LIST = "[dropdown list] dropdown Sub Category List",
  DROPDOWN_SUB_CATEGORY_LIST_SUCCESS = "[dropdown list] dropdown Sub Category List Success",

  DROPDOWN_SUB_CATEGORY_LIST_UPDATE = "[dropdown list] dropdown Sub Category Update List",
  DROPDOWN_SUB_CATEGORY_LIST_UPDATE_SUCCESS = "[dropdown list] dropdown Sub Category List Update Success",
  DROPDOWN_SUB_CATEGORY_LIST_UPDATE_CLEAR ="[dropdown list] dropdown Sub Category List Update clear",
  // category
  CATEGORY_LIST = "[List] category list",
  CATEGORY_LIST_SUCCESS = "[List] category list Success",
  ADD_CATEGORY = "[ADD] Add category",
  ADD_CATEGORY_SUCCESS = "[ADD] Add category Success",
  CATEGORY_DETAILS = "[DETAILS] category Details",
  CATEGORY_DETAILS_SUCCESS = "[DETAILS] category Details Success",
  EDIT_CATEGORY = "[EDIT]Edit category",
  EDIT_CATEGORY_SUCCESS = "[EDIT]Edit category Success",
  DELETE_CATEGORY = "[DELETE]Delete category",
  DELETE_CATEGORY_SUCCESS = "[DELETE]Delete category Success",

  // Sub category
  SUB_CATEGORY_LIST = "[List] Sub category list",
  SUB_CATEGORY_LIST_SUCCESS = "[List] Sub category list Success",
  ADD_SUB_CATEGORY = "[ADD] Add Sub category",
  ADD_SUB_CATEGORY_SUCCESS = "[ADD] Add Sub category Success",
  SUB_CATEGORY_DETAILS = "[DETAILS] Sub category Details",
  SUB_CATEGORY_DETAILS_SUCCESS = "[DETAILS] Sub category Details Success",
  EDIT_SUB_CATEGORY = "[EDIT]Edit Sub category",
  EDIT_SUB_CATEGORY_SUCCESS = "[EDIT]Edit Sub category Success",
  DELETE_SUB_CATEGORY = "[DELETE]Delete Sub category",
  DELETE_SUB_CATEGORY_SUCCESS = "[DELETE]Delete Sub category Success",
  IS_CHECK_SHOW_ADD_SUBCATEGORY_BUTTON="[Check Button] check add sub category button ",
  IS_CHECK_SHOW_ADD_SUBCATEGORY_BUTTON_SUCCESS="[Check Button] check add sub category button Success",

  // Line Item
  LINE_ITEM_LIST = "[List] line item list",
  LINE_ITEM_LIST_SUCCESS = "[List] line item list Success",
  ADD_LINE_ITEM = "[ADD] Add line item",
  ADD_LINE_ITEM_SUCCESS = "[ADD] Add line item Success",
  LINE_ITEM_DETAILS = "[DETAILS] line item Details",
  LINE_ITEM_DETAILS_SUCCESS = "[DETAILS] line item Details Success",
  EDIT_LINE_ITEM = "[EDIT]Edit line item",
  EDIT_LINE_ITEM_SUCCESS = "[EDIT]Edit line item Success",
  DELETE_LINE_ITEM = "[DELETE]Delete line item",
  DELETE_LINE_ITEM_SUCCESS = "[DELETE]Delete line item Success",
  IS_CHECK_SHOW_ADD_LINEITEMS_BUTTON="[Check Button] check add line item button ",
  IS_CHECK_SHOW_ADD_LINEITEMS_BUTTON_SUCCESS="[Check Button] check add line item button Success",

  //ALL
  ALL_LIST = "[List] All list",
  ALL_LIST_SUCCESS = "[List] All list Success",

  // update sequance
  UPDATE_SEQUANCE = "[Update] Update Sequance ",
  UPDATE_SEQUANCE_SUCCESS = "[Update] Update Sequance Success ",
  UPDATE_SUBCATEGORY_SEQUANCE = "[Update] Update Sub Category Sequance ",
  UPDATE_SUBCATEGORY_SEQUANCE_SUCCESS = "[Update] Update Sub Category Sequance Success ",

  //for grant
  GRANT_LIST = "[list] Grant List",
  GRANT_LIST_SUCCESS = "[list] Grant List Success",
  GENERATE_GRANT = "[generate] Generate Grant",
  GENERATE_GRANT_SUCCESS = "[generate] Generate Grant Success",
  UPDATE_GRANT = "[update] Grant Updated",
  UPATE_GRANT_SUCCESS = "[update] Grant Updated Success",
  DELETE_GRANT = "[delete] Grant Deleted",
  DELETE_GRANT_SUCCESS = "[delete] Grant Deleted Success",
  GRANT_DETAILS = "[details] Grant Details",
  GRANT_DETAILS_SUCCESS = "[details] Grant Details Success",

  // for entries - budget

  CHECK_EUR_CONVERSION = "[existing] check existing eur ",
  CHECK_EUR_CONVERSION_SUCCESS = "[existing] check existing eur Success",
  GENERATE_BUDGET_TEMPLATE = "[generate] Generate budget template",
  GENERATE_BUDGET_TEMPLATE_SUCCESS = "[generate] Generate budget template Success",
  UPDATE_BUDGET = "[update] Budget Updated",
  UPATE_BUDGET_SUCCESS = "[update] Budget Updated Success",
  BUDGET_LIST = "[list] Budget List",
  BUDGET_LIST_SUCCESS = "[list] Budget List Success",

  // for entries - Expenses
  CHECK_EUR_CONVERSION_EXPENSES = "[existing] check existing eur expenses ",
  CHECK_EUR_CONVERSION_EXPENSES_SUCCESS = "[existing] check existing eur expenses Success",
  GENERATE_EXPENSES_TEMPLATE = "[generate] Generate expenses template",
  GENERATE_EXPENSES_TEMPLATE_SUCCESS = "[generate] Generate expenses template Success",
  EXPENSE_LIST  = "[list] Expenses List",
  EXPENSE_LIST_SUCCESS  = "[list] Expenses List Success" ,
  UPDATE_EXPENSE= "[update] Expense Updated",
  UPDATE_EXPENSE_SUCCESS= "[update] Expense Updated Success",

// for MIS - Budget
  GET_BUDGET_REPORT = "[get report] Get Budget Report ",
  GET_BUDGET_REPORT_SUCCESS= "[get report] Get Budget Report Success",
  DOWNLOAD_REPORT = "[download] download report of mis",
  DOWNLOAD_REPORT_SUCCESS="[download] download report of mis Success",

// for Grant New

//MOHUA / NIUA  (SANCTION)

 GET_LIST_GRANT_MOHUA_OR_NIUA="[GET LIST MOHUA OR NIUA] GET GRANT MOHUA OR NIUA LIST ",
 GET_LIST_GRANT_MOHUA_OR_NIUA_SUCCESS="[GET LIST MOHUA OR NIUA] GET GRANT MOHUA OR NIUA LIST SUCCESS",
 CREATE_GRANT_MOHUA_OR_NIUA="[CREATE MOHUA GRANT OR NIUA] CREATE MOHUA OR NIUA GRANT",
 CREATE_GRANT_MOHUA_OR_NIUA_SUCCESS="[CREATE MOHUA GRANT OR NIUA] CREATE MOHUA  OR NIUA GRANT SUCCESS",
 UPDATE_GRANT_MOHUA_OR_NIUA="[UPDATE GRANT MOHUA OR NIUA] UPDATE GRANT MOHUA OR NIUA",
 UPDATE_GRANT_MOHUA_OR_NIUA_SUCCESS="[UPDATE GRANT MOHUA OR NIUA] UPDATE GRANT  MOHUA OR NIUA SUCCESS",
 DELETE_GRANT_MOHUA_OR_NIUA="[DELETE GRANT MOHUA OR NIUA] DELETE GRANT MOHUA OR NIUA",
 DELETE_GRANT_MOHUA_OR_NIUA_SUCCESS="[ DELETE GRANT MOHUA OR NIUA] DELETE GRANT MOHUA OR NIUA SUCCESS",

  //TOTAL FUND (SANCTION)
  GET_LIST_GRANT_TOTAL_FUND="[GET LIST FUND] GET GRANT FUND LIST",
  GET_LIST_GRANT_TOTAL_FUND_SUCCESS="[GET LIST FUND] GET GRANT FUND LIST Success",
  CREATE_GRANT_TOTAL_FUND="[CREATE FUND GRANT] CREATE FUND GRANT",
  CREATE_GRANT_TOTAL_FUND_SUCCESS="[CREATE FUND GRANT] CREATE FUND GRANT SUCCESS",
  UPDATE_GRANT_TOTAL_FUND="[UPDATE FUND GRANT] UPDATE FUND GRANT",
  UPDATE_GRANT_TOTAL_FUND_SUCCESS="[UPDATE FUND GRANT] UPDATE FUND GRANT SUCCESS",
  DELETE_GRANT_TOTAL_FUND="[DELETE FUND GRANT] DELETE FUND GRANT",
  DELETE_GRANT_TOTAL_FUND_SUCCESS="[DELETE FUND GRANT] DELETE FUND GRANT SUCCESS",

    //NIUA (UTILIZATION)
    GET_LIST_UTIL_NIUA="[GET LIST NIUA] GET UTIL NIUA LIST",
    GET_LIST_UTIL_NIUA_SUCCESS="[GET LIST UTIL] GET UTIL NIUA LIST Success",
    CREATE_UTIL_NIUA="[CREATE NIUA UTIL] CREATE NIUA UTIL",
    CREATE_UTIL_NIUA_SUCCESS="[CREATE NIUA UTIL] CREATE NIUA UTIL SUCCESS",
    UPDATE_UTIL_NIUA="[UPDATE NIUA UTIL] UPDATE NIUA UTIL",
    UPDATE_UTIL_NIUA_SUCCESS="[UPDATE NIUA UTIL] UPDATE NIUA UTIL SUCCESS",
    DELETE_UTIL_NIUA="[DELETE NIUA UTIL] DELETE NIUA UTIL",
    DELETE_UTIL_NIUA_SUCCESS="[DELETE NIUA UTIL] DELETE NIUA UTIL SUCCESS",

    // Mis Dashboard
    GET_MIS_DASHBOARD_DATA="[Dashboard] GET MIS DASHBOARD DATA",
    GET_MIS_DASHBOARD_DATA_SUCCESS="[Dashboard] GET MIS DASHBOARD DATA SUCCESS",
}

export class dropdownCategoryList implements Action {
  readonly type: string = BudgetActionTypes.DROPDOWN_CATEGORY_LIST;
  constructor(public id: any,public payload:any) {}
}

export class dropdownCategoryListSuccess implements Action {
  readonly type: string = BudgetActionTypes.DROPDOWN_CATEGORY_LIST_SUCCESS;
  constructor(public response: any) {}
}

export class dropdownSubCategoryList implements Action {
  readonly type: string = BudgetActionTypes.DROPDOWN_SUB_CATEGORY_LIST;
  constructor(public obj: any,public payload:any) {}
}

export class dropdownsubCategoryListSuccess implements Action {
  readonly type: string = BudgetActionTypes.DROPDOWN_SUB_CATEGORY_LIST_SUCCESS;
  constructor(public response: any) {}
}

export class dropdownSubCategoryListUpdateSeq implements Action {
  readonly type: string = BudgetActionTypes.DROPDOWN_SUB_CATEGORY_LIST_UPDATE;
 constructor(public payload: any, public id: any) {}
}

export class dropdownsubCategoryListUpdateSeqSuccess implements Action {
  readonly type: string = BudgetActionTypes.DROPDOWN_SUB_CATEGORY_LIST_UPDATE_SUCCESS;
  constructor(public response: any) {}
}


export class dropdownsubCategoryListUpdateSeqClear implements Action {
  readonly type: string = BudgetActionTypes.DROPDOWN_SUB_CATEGORY_LIST_UPDATE_CLEAR;
  constructor() {}
}

export class categoryList implements Action {
  readonly type: string = BudgetActionTypes.CATEGORY_LIST;
  constructor(public payload: any, public id: any) {}
}

export class categoryListSuccess implements Action {
  readonly type: string = BudgetActionTypes.CATEGORY_LIST_SUCCESS;
  constructor(public response: any) {}
}

export class createCategory implements Action {
  readonly type: string = BudgetActionTypes.ADD_CATEGORY;
  constructor(public payload: any) {}
}

export class createCategorySuccess implements Action {
  readonly type: string = BudgetActionTypes.ADD_CATEGORY_SUCCESS;
  constructor(public response: any) {}
}

export class categorydetails implements Action {
  readonly type: string = BudgetActionTypes.CATEGORY_DETAILS;
  constructor(public id: any) {}
}

export class categorydetailsSuccess implements Action {
  readonly type: string = BudgetActionTypes.CATEGORY_DETAILS_SUCCESS;
  constructor(public response: any) {}
}

export class editCategory implements Action {
  readonly type: string = BudgetActionTypes.EDIT_CATEGORY;
  constructor(public id: any, public payload: any) {}
}

export class editCategorySuccess implements Action {
  readonly type: string = BudgetActionTypes.EDIT_CATEGORY_SUCCESS;
  constructor(public response: any) {}
}

export class deleteCategory implements Action {
  readonly type: string = BudgetActionTypes.DELETE_CATEGORY;
  constructor(public id: any) {}
}

export class deleteCategorySuccess implements Action {
  readonly type: string = BudgetActionTypes.DELETE_CATEGORY_SUCCESS;
  constructor(public response: any) {}
}

//for sub category

export class subcategoryList implements Action {
  readonly type: string = BudgetActionTypes.SUB_CATEGORY_LIST;
  constructor(public payload: any, public id: any) {}
}

export class subcategoryListSuccess implements Action {
  readonly type: string = BudgetActionTypes.SUB_CATEGORY_LIST_SUCCESS;
  constructor(public response: any) {}
}

export class createsubCategory implements Action {
  readonly type: string = BudgetActionTypes.ADD_SUB_CATEGORY;
  constructor(public payload: any) {}
}

export class createsubCategorySuccess implements Action {
  readonly type: string = BudgetActionTypes.ADD_SUB_CATEGORY_SUCCESS;
  constructor(public response: any) {}
}

export class subcategorydetails implements Action {
  readonly type: string = BudgetActionTypes.SUB_CATEGORY_DETAILS;
  constructor(public id: any) {}
}

export class subcategorydetailsSuccess implements Action {
  readonly type: string = BudgetActionTypes.SUB_CATEGORY_DETAILS_SUCCESS;
  constructor(public response: any) {}
}

export class editsubCategory implements Action {
  readonly type: string = BudgetActionTypes.EDIT_SUB_CATEGORY;
  constructor(public id: any, public payload: any) {}
}

export class editsubCategorySuccess implements Action {
  readonly type: string = BudgetActionTypes.EDIT_SUB_CATEGORY_SUCCESS;
  constructor(public response: any) {}
}

export class deletesubCategory implements Action {
  readonly type: string = BudgetActionTypes.DELETE_SUB_CATEGORY;
  constructor(public id: any) {}
}

export class deletesubCategorySuccess implements Action {
  readonly type: string = BudgetActionTypes.DELETE_SUB_CATEGORY_SUCCESS;
  constructor(public response: any) {}
}

export class checkCategoryAvailable implements Action {
  readonly type: string = BudgetActionTypes.IS_CHECK_SHOW_ADD_SUBCATEGORY_BUTTON;
  constructor(public id: any,public fy:any) {}
}

export class checkCategoryAvailableSuccess implements Action {
  readonly type: string = BudgetActionTypes.IS_CHECK_SHOW_ADD_SUBCATEGORY_BUTTON_SUCCESS;
  constructor(public response: any) {}
}



// line item

export class lineItemList implements Action {
  readonly type: string = BudgetActionTypes.LINE_ITEM_LIST;
  constructor(public payload: any, public id: any) {}
}

export class lineItemListSuccess implements Action {
  readonly type: string = BudgetActionTypes.LINE_ITEM_LIST_SUCCESS;
  constructor(public response: any) {}
}

export class createlineItem implements Action {
  readonly type: string = BudgetActionTypes.ADD_LINE_ITEM;
  constructor(public payload: any) {}
}

export class createlineItemSuccess implements Action {
  readonly type: string = BudgetActionTypes.ADD_LINE_ITEM_SUCCESS;
  constructor(public response: any) {}
}

export class lineItemdetails implements Action {
  readonly type: string = BudgetActionTypes.LINE_ITEM_DETAILS;
  constructor(public id: any) {}
}

export class lineItemdetailsSuccess implements Action {
  readonly type: string = BudgetActionTypes.LINE_ITEM_DETAILS_SUCCESS;
  constructor(public response: any) {}
}

export class editlineItem implements Action {
  readonly type: string = BudgetActionTypes.EDIT_LINE_ITEM;
  constructor(public id: any, public payload: any) {}
}

export class editlineItemSuccess implements Action {
  readonly type: string = BudgetActionTypes.EDIT_LINE_ITEM_SUCCESS;
  constructor(public response: any) {}
}

export class deletelineItem implements Action {
  readonly type: string = BudgetActionTypes.DELETE_LINE_ITEM;
  constructor(public id: any) {}
}

export class deletelineItemSuccess implements Action {
  readonly type: string = BudgetActionTypes.DELETE_LINE_ITEM_SUCCESS;
  constructor(public response: any) {}
}


export class checkLineItemAvailable implements Action {
  readonly type: string = BudgetActionTypes.IS_CHECK_SHOW_ADD_LINEITEMS_BUTTON;
  constructor(public id: any,public fy:any) {}
}

export class checkLineItemAvailableSuccess implements Action {
  readonly type: string = BudgetActionTypes.IS_CHECK_SHOW_ADD_LINEITEMS_BUTTON_SUCCESS;
  constructor(public response: any) {}
}

// for all
export class allList implements Action {
  readonly type: string = BudgetActionTypes.ALL_LIST;
  constructor(public payload: any, public id: any) {}
}

export class allListSuccess implements Action {
  readonly type: string = BudgetActionTypes.ALL_LIST_SUCCESS;
  constructor(public response: any) {}
}

// update sequance

export class updateSequance implements Action {
  readonly type: string = BudgetActionTypes.UPDATE_SEQUANCE;
  constructor(public payload: any) {}
}

export class updateSequanceSuccess implements Action {
  readonly type: string = BudgetActionTypes.UPDATE_SEQUANCE_SUCCESS;
  constructor(public response: any) {}
}

export class updatesubcategorySequance implements Action {
  readonly type: string = BudgetActionTypes.UPDATE_SUBCATEGORY_SEQUANCE;
  constructor(public payload: any) {}
}

export class updatesubcategorySequanceSuccess implements Action {
  readonly type: string = BudgetActionTypes.UPDATE_SUBCATEGORY_SEQUANCE_SUCCESS;
  constructor(public response: any) {}
}

//for grant

export class grantList implements Action {
  readonly type: string = BudgetActionTypes.GRANT_LIST;
  constructor(public payload: any, public id: any) {}
}

export class grantListSuccess implements Action {
  readonly type: string = BudgetActionTypes.GRANT_LIST_SUCCESS;
  constructor(public response: any) {}
}

export class generateGrant implements Action {
  readonly type: string = BudgetActionTypes.GENERATE_GRANT;
  constructor(public payload: any) {}
}

export class generateGrantSuccess implements Action {
  readonly type: string = BudgetActionTypes.GENERATE_GRANT_SUCCESS;
  constructor(public response: any) {}
}

export class grantdetails implements Action {
  readonly type: string = BudgetActionTypes.GRANT_DETAILS;
  constructor(public id: any) {}
}

export class grantdetailsSuccess implements Action {
  readonly type: string = BudgetActionTypes.GRANT_DETAILS_SUCCESS;
  constructor(public response: any) {}
}

export class editgrant implements Action {
  readonly type: string = BudgetActionTypes.UPDATE_GRANT;
  constructor(public id: any, public payload: any) {}
}

export class editgrantSuccess implements Action {
  readonly type: string = BudgetActionTypes.UPATE_GRANT_SUCCESS;
  constructor(public response: any) {}
}

export class deletegrant implements Action {
  readonly type: string = BudgetActionTypes.DELETE_GRANT;
  constructor(public id: any) {}
}

export class deletegrantSuccess implements Action {
  readonly type: string = BudgetActionTypes.DELETE_GRANT_SUCCESS;
  constructor(public response: any) {}
}

// entries-budget
export class checkEurExisting implements Action {
  readonly type: string = BudgetActionTypes.CHECK_EUR_CONVERSION;
  constructor(public id: any, public fy: any) {}
}

export class checkEurExistingSuccess implements Action {
  readonly type: string = BudgetActionTypes.CHECK_EUR_CONVERSION_SUCCESS;
  constructor(public response: any) {}
}

export class generatebudgetTemplate implements Action {
  readonly type: string = BudgetActionTypes.GENERATE_BUDGET_TEMPLATE;
  constructor(public id: any, public payload: any) {}
}

export class generatebudgetTemplateSuccess implements Action {
  readonly type: string = BudgetActionTypes.GENERATE_BUDGET_TEMPLATE_SUCCESS;
  constructor(public response: any) {}
}

export class editBudget implements Action {
  readonly type: string = BudgetActionTypes.UPDATE_BUDGET;
  constructor(public id: any, public payload: any) {}
}

export class editBudgetSuccess implements Action {
  readonly type: string = BudgetActionTypes.UPATE_BUDGET_SUCCESS;
  constructor(public response: any) {}
}

export class budgetList implements Action {
  readonly type: string = BudgetActionTypes.BUDGET_LIST;
  constructor(public payload: any, public id: any) {}
}

export class budgetListSuccess implements Action {
  readonly type: string = BudgetActionTypes.BUDGET_LIST_SUCCESS;
  constructor(public response: any) {}
}

// export class deleteBudget implements Action {
//   readonly type: string = BudgetActionTypes.DELETE_BUDGET;
//   constructor( public id: any) {}
// }

// export class deleteBudgetSuccess implements Action {
//   readonly type: string = BudgetActionTypes.DELETE_BUDGET_SUCCESS;
//   constructor(public response: any) {}
// }

// entries-EXPENSES
export class checkEurExistingExpenses implements Action {
  readonly type: string = BudgetActionTypes.CHECK_EUR_CONVERSION_EXPENSES;
  constructor(public id: any, public fy: any, public month: any) {}
}

export class checkEurExistingExpensesSuccess implements Action {
  readonly type: string =
    BudgetActionTypes.CHECK_EUR_CONVERSION_EXPENSES_SUCCESS;
  constructor(public response: any) {}
}

export class generateExpensesTemplate implements Action {
  readonly type: string = BudgetActionTypes.GENERATE_EXPENSES_TEMPLATE;
  constructor(public id: any, public payload: any) {}
}

export class generateExpensesTemplateSuccess implements Action {
  readonly type: string = BudgetActionTypes.GENERATE_EXPENSES_TEMPLATE_SUCCESS;
  constructor(public response: any) {}
}

export class expenseList implements Action {
  readonly type: string = BudgetActionTypes.EXPENSE_LIST;
  constructor(public payload: any, public id: any) {}
}

export class expenseListSuccess implements Action {
  readonly type: string = BudgetActionTypes.EXPENSE_LIST_SUCCESS;
  constructor(public response: any) {}
}

export class editExpense implements Action {
  readonly type: string = BudgetActionTypes.UPDATE_EXPENSE;
  // public id: any,
  constructor( public payload: any,public id: any) {}
}

export class editExpenseSuccess implements Action {
  readonly type: string = BudgetActionTypes.UPDATE_EXPENSE_SUCCESS;
  constructor(public response: any) {}
}

// export class deleteExpense implements Action {
//   readonly type: string = BudgetActionTypes.DELETE_EXPENSE;
//   constructor( public id: any) {}
// }

// export class deleteExpenseSuccess implements Action {
//   readonly type: string = BudgetActionTypes.DELETE_EXPENSE_SUCCESS;
//   constructor(public response: any) {}
// }

// MIS - Budget Report

export class getBudgetReport implements Action {
  readonly type: string = BudgetActionTypes.GET_BUDGET_REPORT;
  constructor(public id: any, public fy: any, public month: any) {}
}

export class getBudgetReportSuccess implements Action {
  readonly type: string =BudgetActionTypes.GET_BUDGET_REPORT_SUCCESS;
  constructor(public response: any) {}
}

export class downloadReport implements Action {
  readonly type: string = BudgetActionTypes.DOWNLOAD_REPORT;
  constructor(public id: any, public fy: any) {}
}

export class downloadReportSuccess implements Action {
  readonly type: string =BudgetActionTypes.DOWNLOAD_REPORT_SUCCESS;
  constructor(public response: any) {}
}

export class createNiuaOrMohuaSaction implements Action {
  readonly type: string = BudgetActionTypes.CREATE_GRANT_MOHUA_OR_NIUA;
  constructor( public payload: any) {}
}

export class createNiuaOrMohuaSactionSuccess implements Action {
  readonly type: string = BudgetActionTypes.CREATE_GRANT_MOHUA_OR_NIUA_SUCCESS;
  constructor(public response: any) {}
}

export class editNiuaOrMohuaSaction implements Action {
  readonly type: string = BudgetActionTypes.UPDATE_GRANT_MOHUA_OR_NIUA;
  constructor(public id: any , public payload: any) {}
}

export class editNiuaOrMohuaSactionSuccess implements Action {
  readonly type: string = BudgetActionTypes.UPDATE_GRANT_MOHUA_OR_NIUA_SUCCESS;
  constructor(public response: any) {}
}

export class deleteNiuaOrMohuaSaction implements Action {
  readonly type: string = BudgetActionTypes.DELETE_GRANT_MOHUA_OR_NIUA;
  constructor(public id: any) {}
}

export class deleteNiuaOrMohuaSactionSuccess implements Action {
  readonly type: string = BudgetActionTypes.DELETE_GRANT_MOHUA_OR_NIUA_SUCCESS;
  constructor(public response: any) {}
}

export class sanctionList implements Action {
  readonly type: string = BudgetActionTypes.GET_LIST_GRANT_MOHUA_OR_NIUA;
  constructor(public payload: any, public projectId: any,public Id: any) {}
}

export class sanctionListSuccess implements Action {
  readonly type: string = BudgetActionTypes.GET_LIST_GRANT_MOHUA_OR_NIUA_SUCCESS;
  constructor(public response: any) {}
}

export class createtotalFundSaction implements Action {
  readonly type: string = BudgetActionTypes.CREATE_GRANT_TOTAL_FUND;
  constructor( public payload: any) {}
}

export class createtotalFundSactionSuccess implements Action {
  readonly type: string = BudgetActionTypes.CREATE_GRANT_TOTAL_FUND_SUCCESS;
  constructor(public response: any) {}
}

export class edittotalFundSaction implements Action {
  readonly type: string = BudgetActionTypes.UPDATE_GRANT_TOTAL_FUND;
  constructor(public id: any , public payload: any) {}
}

export class edittotalFundSactionSuccess implements Action {
  readonly type: string = BudgetActionTypes.UPDATE_GRANT_TOTAL_FUND_SUCCESS;
  constructor(public response: any) {}
}

export class deletetotalFundSaction implements Action {
  readonly type: string = BudgetActionTypes.DELETE_GRANT_TOTAL_FUND;
  constructor(public id: any) {}
}

export class deletetotalFundSactionSuccess implements Action {
  readonly type: string = BudgetActionTypes.DELETE_GRANT_TOTAL_FUND_SUCCESS;
  constructor(public response: any) {}
}

export class totalFundList implements Action {
  readonly type: string = BudgetActionTypes.GET_LIST_GRANT_TOTAL_FUND;
  constructor(public payload: any, public projectId: any,public Id: any) {}
}

export class totalFundListSuccess implements Action {
  readonly type: string = BudgetActionTypes.GET_LIST_GRANT_TOTAL_FUND_SUCCESS;
  constructor(public response: any) {}
}


export class createNiuaUtilization implements Action {
  readonly type: string = BudgetActionTypes.CREATE_UTIL_NIUA;
  constructor( public payload: any) {}
}

export class createNiuaUtilizationSuccess implements Action {
  readonly type: string = BudgetActionTypes.CREATE_UTIL_NIUA_SUCCESS;
  constructor(public response: any) {}
}

export class editNiuaUtilization implements Action {
  readonly type: string = BudgetActionTypes.UPDATE_UTIL_NIUA;
  constructor(public id: any , public payload: any) {}
}

export class editNiuaUtilizationSuccess implements Action {
  readonly type: string = BudgetActionTypes.UPDATE_UTIL_NIUA_SUCCESS;
  constructor(public response: any) {}
}

export class deleteNiuaUtilization implements Action {
  readonly type: string = BudgetActionTypes.DELETE_UTIL_NIUA;
  constructor(public id: any) {}
}

export class deleteNiuaUtilizationSuccess implements Action {
  readonly type: string = BudgetActionTypes.DELETE_UTIL_NIUA_SUCCESS;
  constructor(public response: any) {}
}

export class NiuaUtilizationList implements Action {
  readonly type: string = BudgetActionTypes.GET_LIST_UTIL_NIUA;
  constructor(public payload: any, public projectId: any,public Id: any) {}
}

export class NiuaUtilizationListSuccess implements Action {
  readonly type: string = BudgetActionTypes.GET_LIST_UTIL_NIUA_SUCCESS;
  constructor(public response: any) {}
}

export class getMisDashboard implements Action {
  readonly type: string = BudgetActionTypes.GET_MIS_DASHBOARD_DATA;
  constructor(public id: any , public start_year: any ,public start_month: any ,public end_year: any ,public end_month: any ) {}
}

export class getMisDashboardSuccess implements Action {
  readonly type: string = BudgetActionTypes.GET_MIS_DASHBOARD_DATA_SUCCESS;
  constructor(public response: any) {}
}