import { Injectable } from '@angular/core';
import { API } from 'src/app/services/Api.service';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Location } from '@angular/common';
import { NotificationsService } from 'angular2-notifications';
import { ErrorAction } from 'src/app/login/store/auth.actions';
import {
  UserGroupActionTypes,
  GetUsersGroups,
  GetUserGroupsSuccess,
  GetGroupDetails,
  GetGroupDetailsSuccess,
  RemoveUserFromGroup,
  DeleteGroup,
  CreateGroup,
  AddUsersToGroup,
  CreateGroupSuccess,
  AddUsersToGroupSuccess,
  UpdateGroup,
  UpdateGroupSuccess,
} from './user-groups.actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { CommonService } from 'src/app/services/tasks.services';

@Injectable()
export class UserGroupEffects {

  @Effect()
  getUserGroups$ = this.action$.pipe(
    ofType(UserGroupActionTypes.GET_USERS_GROUPS),
    switchMap((action: GetUsersGroups) => {
      return this.api.getUserGroups().pipe(
        map((groups: any) => {
          return new GetUserGroupsSuccess(groups);
        }),
        catchError(err => of(new ErrorAction(err)))
      );
    })
  );
  
  @Effect()
  getGroupDetails$ = this.action$.pipe(
    ofType(UserGroupActionTypes.GET_GROUP_DETAILS),
    switchMap((action: GetGroupDetails) => {
      return this.api.getGroupDetails(action.groupId).pipe(
        map((groupDetails: any) => {
          return new GetGroupDetailsSuccess(groupDetails);
        }),
        catchError(err => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  removeUserFromGroup$ = this.action$.pipe(
    ofType(UserGroupActionTypes.REMOVE_USER_FROM_GROUP),
    switchMap((action: RemoveUserFromGroup) => {
      return this.api.removeUserFromGroup(action.groupId, action.userId).pipe(
        map((response: any) => {
          this.notificationService.success("User removed successfully");
          return new GetGroupDetails(action.groupId);
        }),
        catchError(err => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  deleteGroup$ = this.action$.pipe(
    ofType(UserGroupActionTypes.DELETE_GROUP),
    switchMap((action: DeleteGroup) => {
      return this.api.deleteGroup(action.groupId).pipe(
        map((response: any) => {
          this.notificationService.success('Group activity has been changed');
          // this.router.navigateByUrl('/home/user-groups');
          return new GetUsersGroups();
        }),
       catchError(err => of(new ErrorAction(err)))
      );
    })
  );
  @Effect()
  createGroup$ =this.action$.pipe(
    ofType(UserGroupActionTypes.CREATE_GROUP),
    switchMap((action: CreateGroup) => {
      return this.api.createGroup(action.payload).pipe(
        map((response: any) => {
             return new CreateGroupSuccess(response);
        }),
        catchError(err => of(new ErrorAction(err)))
      );
    })
  );
  @Effect()
  addUserToGroup$ = this.action$.pipe(
    ofType(UserGroupActionTypes.ADD_USERS_TO_GROUP),
    switchMap((action: AddUsersToGroup) => {
      return this.api.addUserToGroup(action.groupId, action.users).pipe(
        map((response: any) => {
          return new AddUsersToGroupSuccess(response);
        }),
        catchError(err => {
         return of(new ErrorAction(err));
        })
      );
    })
  )

  @Effect()
  updateGroupDetails$ =  this.action$.pipe(
    ofType(UserGroupActionTypes.UPDATE_GROUP),
    switchMap((action: UpdateGroup) => {
      return this.api.updateGroup(action.groupId, action.payload).pipe(
        map((response: any) => {
          this._store.dispatch(new GetGroupDetails(action.groupId));
          return new  UpdateGroupSuccess(response);
        }),
        catchError(err => of(new ErrorAction(err)))
      );
    })
  )

  constructor(private api: API,
    private action$: Actions,
    private location: Location,
    private notificationService: NotificationsService,
    private router: Router,
    private _store: Store<any>,
    private commonService: CommonService
  ) { }
}
