import {
  GetEnityAndProject,
  GetEnityAndProjectSuccess,
  GetEnityList,
  GetEnityListSuccess,
  GetProjectList,
  GetProjectListById,
  GetProjectListByIdSuccess,
  GetProjectListSuccess,
  ProcurementActionTypes,
  addApprover,
  addApproverSuccess,
  addBudget,
  addBudgetSuccess,
  addClouserInformation,
  addClouserInformationSuccess,
  addComments,
  addCommentsSuccess,
  addMilestones,
  addMilestonesSuccess,
  addProcureDocument,
  addProcureDocumentSuccess,
  addProcurementUnit,
  addProcurementUnitSuccess,
  addedMemberList,
  addedMemberListSuccess,
  approvalForDocumentProcureDocument,
  approvalForDocumentProcureDocumentSuccess,
  approvalForSubmitProcureMilestone,
  approvalForSubmitProcureMilestoneSuccess,
  approveRejectFile,
  approveRejectFileSuccess,
  commentsList,
  commentsListSuccess,
  createAward,
  createAwardSuccess,
  createPacket,
  createPacketSuccess,
  createTender,
  createTenderSuccess,
  deleteAward,
  deleteAwardSuccess,
  deleteBudget,
  deleteBudgetSuccess,
  deleteClouserInfo,
  deleteClouserInfoSuccess,
  deleteMilestones,
  deleteMilestonesSuccess,
  deletePacket,
  deletePacketSuccess,
  deleteProcureDocument,
  deleteProcureDocumentSuccess,
  deleteProcurementUnit,
  deleteProcurementUnitSuccess,
  deleteTender,
  deleteTenderSuccess,
  fetchContractActivityList,
  fetchContractActivityListSuccess,
  generateProcurementId,
  generateProcurementIdSuccess,
  getBudget,
  getBudgetSuccess,
  getClouserDocument,
  getClouserDocumentSuccess,
  getDocOfClouserInfo,
  getMilestones,
  getMilestonesSuccess,
  getPhysicalpercentage,
  getPhysicalpercentageSuccess,
  getProcureDocumentList,
  getProcureDocumentListSuccess,
  getProcurementList,
  getProcurementListSuccess,
  getProcurementPayment,
  getProcurementPaymentSuccess,
  memberList,
  memberListSuccess,
  procurementUnitSubmit,
  procurementUnitSubmitResponse,
  pullAllAward,
  pullAllAwardSuccess,
  pullAllPacket,
  pullAllPacketSuccess,
  pullAllTender,
  pullAllTenderSuccess,
  submitPhysicalpercentage,
  submitPhysicalpercentageSuccess,
  updateAward,
  updateAwardSuccess,
  updateBudget,
  updateBudgetSuccess,
  updateClouserInformation,
  updateClouserInformationSuccess,
  updateMilestones,
  updateMilestonesSuccess,
  updatePacket,
  updatePacketSuccess,
  updateProcureDocument,
  updateProcureDocumentSuccess,
  updateProcurementUnit,
  updateProcurementUnitSuccess,
  updateTender,
  updateTenderSuccess,
} from "./procurement.action";

import { Injectable } from "@angular/core";
import { ProcurementService } from "../service/procurement.service";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { switchMap, map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { Location } from "@angular/common";
import { ErrorAction } from "../../../../login/store/auth.actions";
import { NotificationsService } from "angular2-notifications";
import { AppState } from "./../../../../app.reducer";
import { Store } from "@ngrx/store";
import { CommonSuccessAction } from "../../resources/_store/resources.actions";
import { LoaderHide } from "./../../../../features/loader/store/loader.actions";
import { error } from "console";
@Injectable()
export class ProcurementEffects {
  constructor(
    private api: ProcurementService,
    private action$: Actions,
    private location: Location,
    private store: Store<AppState>,
    private notificationService: NotificationsService
  ) {}



  @Effect()
  getContractList$ = this.action$.pipe(
    ofType(ProcurementActionTypes.FETCH_CONTRACT_ACTIVITY_LIST),
    switchMap((action: fetchContractActivityList) => {
      return this.api.getContractActivityList(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new fetchContractActivityListSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );


  @Effect()
  getprocumentDetails$ = this.action$.pipe(
    ofType(ProcurementActionTypes.GENERATE_PROCUREMENT_ID),
    switchMap((action: generateProcurementId) => {
      return this.api.getDetals(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Details fetched successfully");
            return new generateProcurementIdSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  addProUnit$ = this.action$.pipe(
    ofType(ProcurementActionTypes.ADD_PROCUREMENT_UNIT),
    switchMap((action: addProcurementUnit) => {
      return this.api.addProcurementUnit(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("Add Procurement Unit successfully");
            return new addProcurementUnitSuccess (response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  UPDATEProUnit$ = this.action$.pipe(
    ofType(ProcurementActionTypes.UPDATE_PROCUREMENT_UNIT),
    switchMap((action: updateProcurementUnit) => {
      return this.api.updateProcurementUnit(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("update Procurement Unit successfully");
            return new updateProcurementUnitSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  deleteProUnit$ = this.action$.pipe(
    ofType(ProcurementActionTypes.DELETE_PROCUREMENT_UNIT),
    switchMap((action: deleteProcurementUnit) => {
      return this.api.deleteProcurementUnit(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("update Procurement Unit successfully");
            return new deleteProcurementUnitSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  getProDoc$ = this.action$.pipe(
    ofType(ProcurementActionTypes.GET_PROCUREMENT_DOCUMENT_LIST),
    switchMap((action: getProcureDocumentList) => {
      return this.api.getDocumentList(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("get Document successfully");
            return new getProcureDocumentListSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  
  @Effect()
  addProDoc$ = this.action$.pipe(
    ofType(ProcurementActionTypes.ADD_PROCUREMENT_DOCUMENT),
    switchMap((action: addProcureDocument) => {
      return this.api.addProcurementDocument(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("Add Document successfully");
            return new addProcureDocumentSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  UPDATEProDoc$ = this.action$.pipe(
    ofType(ProcurementActionTypes.UPDATE_PROCUREMENT_DOCUMENT),
    switchMap((action: updateProcureDocument) => {
      return this.api.updateDocument(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("Add Document successfully");
            return new updateProcureDocumentSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );




  @Effect()
  deleteProDoc$ = this.action$.pipe(
    ofType(ProcurementActionTypes.DELETE_PROCUREMENT_DOCUMENT),
    switchMap((action: deleteProcureDocument) => {
      return this.api.deleteDocument(action.Id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("Delete Document successfully");
            return new deleteProcureDocumentSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  submitForApprovaProDoc$ = this.action$.pipe(
    ofType(ProcurementActionTypes.APPROVE_FOR_DOCUMENT_PROCUREMENT_DOCUMENT),
    switchMap((action: approvalForDocumentProcureDocument) => {
      return this.api.submitForApprovalForUploadDoc(action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("Document Sent For Approval successfully");
            return new approvalForDocumentProcureDocumentSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  memberlist$ = this.action$.pipe(
    ofType(ProcurementActionTypes.PROCUREMENT_MEMBERLIST),
    switchMap((action: memberList) => {
      return this.api.getMemberList(action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Member list successfully");
            return new memberListSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );


  @Effect()
  addApprover$ = this.action$.pipe(
    ofType(ProcurementActionTypes.ADD_APPROVER),
    switchMap((action: addApprover) => {
      return this.api.addApprovar(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("Add Member successfully");
            return new addApproverSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  approveReject$ = this.action$.pipe(
    ofType(ProcurementActionTypes.APPROVE_REJECT),
    switchMap((action: approveRejectFile) => {
      return this.api.approveRejectFile(action.subSection,action.id,action.action).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success(`${action.action} successfully`);
            return new approveRejectFileSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  addedList$ = this.action$.pipe(
    ofType(ProcurementActionTypes.ADDED_MEMBER),
    switchMap((action: addedMemberList) => {
      return this.api.addedMemberList(action.projectId).pipe(
        // action.entityID,
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success(`list successfully`);
            return new addedMemberListSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );


  @Effect()
  submitForApprovaPromiles$ = this.action$.pipe(
    ofType(ProcurementActionTypes.SUBMIT_FOR_APPROVE_PROCUREMENT_MILESTONE),
    switchMap((action: approvalForSubmitProcureMilestone) => {
      return this.api.submitForApprovalForMilestone(action.id,action.type1).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("Document Sent For Approval successfully");
            return new approvalForSubmitProcureMilestoneSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );


































  @Effect()
  getAllEntity$ = this.action$.pipe(
    ofType(ProcurementActionTypes.GET_ENTITY_LIST),
    switchMap((action: GetEnityList) => {
      return this.api.getEntityList().pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("Enitity fetched successfully");
            return new GetEnityListSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  getEntityAndProject$ = this.action$.pipe(
    ofType(ProcurementActionTypes.GET_ENTITY_AND_PROJECT),
    switchMap((action: GetEnityAndProject) => {
      return this.api.getEntityAndProject().pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Details fetched successfully");
            return new GetEnityAndProjectSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  getAllProject$ = this.action$.pipe(
    ofType(ProcurementActionTypes.GET_PROJECT_LIST),
    switchMap((action: GetProjectList) => {
      return this.api.getProjectList().pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("Project List fetched successfully");
            return new GetProjectListSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  getAllProjectbyEntityId$ = this.action$.pipe(
    ofType(ProcurementActionTypes.GET_PROJECT_LIST_BY_ENT_ID),
    switchMap((action: GetProjectListById) => {
      return this.api.getProjectDetailsbyIntity(action.Id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Project fetched successfully");
            return new GetProjectListByIdSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );



  @Effect()
  submitProcurementDetals$ = this.action$.pipe(
    ofType(ProcurementActionTypes.PROCUREMENT_UNIT_SUBMIT),
    switchMap((action: procurementUnitSubmit) => {
      return this.api.submitProcurementUnit(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("Procurement Details updated successfully");
            return new procurementUnitSubmitResponse(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );


  



  @Effect()
  deleteBudget$ = this.action$.pipe(
    ofType(ProcurementActionTypes.DELETE_BUDGET),
    switchMap((action: deleteBudget) => {
      return this.api.deleteBudget(action.Id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("Delete Budget successfully");
            return new deleteBudgetSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  getBudget$ = this.action$.pipe(
    ofType(ProcurementActionTypes.GET_BUDGET),
    switchMap((action: getBudget) => {
      return this.api.getBudgetList(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new getBudgetSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  addBudget$ = this.action$.pipe(
    ofType(ProcurementActionTypes.ADD_BUDGET),
    switchMap((action: addBudget) => {
      return this.api.addBudget(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("add Budget successfully");
            return new addBudgetSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );


  @Effect()
  UPDATEBudget$ = this.action$.pipe(
    ofType(ProcurementActionTypes.UPDATE_BUDGET),
    switchMap((action: updateBudget) => {
      return this.api.updateBudget(action.payload,action.Id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("update Budget successfully");
            return new updateBudgetSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );



  @Effect()
  getClouserDocument$ = this.action$.pipe(
    ofType(ProcurementActionTypes.GET_CLOUSER_DOCUMENT),
    switchMap((action: getClouserDocument) => {
      return this.api.getClouserDocumentList(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new getClouserDocumentSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );



  @Effect()
  addclouserDoc$ = this.action$.pipe(
    ofType(ProcurementActionTypes.ADD_CLOUSER_INFORMATION),
    switchMap((action: addClouserInformation) => {
      return this.api.addClouserDocument(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("Add Document successfully");
            return new addClouserInformationSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  updateCloserDoc$ = this.action$.pipe(
    ofType(ProcurementActionTypes.UPDATE_CLOUSER_INFORMATION),
    switchMap((action: updateClouserInformation) => {
      return this.api.updateClouserDocument(action.payload,action.Id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("update Document successfully");
            return new updateClouserInformationSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  deleteCloserDoc$ = this.action$.pipe(
    ofType(ProcurementActionTypes.DELETE_CLOUSER_INFO),
    switchMap((action: deleteClouserInfo) => {
      return this.api.deleteClouserDocument(action.Id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("Delete information successfully");
            return new deleteClouserInfoSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );


  // @Effect()
  // getCloserDoc$ = this.action$.pipe(
  //   ofType(ProcurementActionTypes.GET_DOCUMENT_OF_CLOUSER_INFO),
  //   switchMap((action: getDocOfClouserInfo) => {
  //     return this.api.getclouserDocument(action.Id).pipe(
  //       map((response: any) => {
  //         if (response) {
  //           this.store.dispatch(new LoaderHide());
  //           this.notificationService.success("get Document successfully");
  //           return new getDocumentSuccess(response);
  //         }
  //       }),
  //       catchError((err) => of(new ErrorAction(err)))
  //     );
  //   })
  // );

  // @Effect()
  // getProcurementDoc$ = this.action$.pipe(
  //   ofType(ProcurementActionTypes.GET_DOCUMENT_OF_PROCUREMENT_DOCS),
  //   switchMap((action: getDocOfProcurement) => {
  //     return this.api.getprocurementDocument(action.Id).pipe(
  //       map((response: any) => {
  //         if (response) {
  //           this.store.dispatch(new LoaderHide());
  //           this.notificationService.success("get Document successfully");
  //           return new getDocumentSuccess(response);
  //         }
  //       }),
  //       catchError((err) => of(new ErrorAction(err)))
  //     );
  //   })
  // );



  
  @Effect()
  deleteMilestone$ = this.action$.pipe(
    ofType(ProcurementActionTypes.DELETE_MILESTONES),
    switchMap((action: deleteMilestones) => {
      return this.api.deleteMilestone(action.Id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("Delete Milestones successfully");
            return new deleteMilestonesSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  getMilestone$ = this.action$.pipe(
    ofType(ProcurementActionTypes.GET_MILESTONES),
    switchMap((action:getMilestones) => {
      return this.api.getMilestoneList(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new getMilestonesSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  addMilestone$ = this.action$.pipe(
    ofType(ProcurementActionTypes.ADD_MILESTONES),
    switchMap((action: addMilestones) => {
      return this.api.addMilestones(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("add Milestones successfully");
            return new addMilestonesSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );


  @Effect()
  UPDATEMilestone$ = this.action$.pipe(
    ofType(ProcurementActionTypes.UPDATE_MILESTONES),
    switchMap((action: updateMilestones) => {
      return this.api.updateMilestones(action.payload,action.Id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("update Milestone successfully");
            return new updateMilestonesSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );



  @Effect()
  getProcurementPayemnt$ = this.action$.pipe(
    ofType(ProcurementActionTypes.GET_PROCUREMENT_PAYMENT),
    switchMap((action:getProcurementPayment) => {
      return this.api.getProcurementPayment(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new getProcurementPaymentSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  getProcurementlist$ = this.action$.pipe(
    ofType(ProcurementActionTypes.GET_PROCUREMENT_LIST),
    switchMap((action:getProcurementList) => {
      return this.api.getProcurementList(action.pro_type).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new getProcurementListSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );


  @Effect()
  addcomments$ = this.action$.pipe(
    ofType(ProcurementActionTypes.ADD_COMMENTS),
    switchMap((action:addComments) => {
      return this.api.addComments(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new addCommentsSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  commentslist$ = this.action$.pipe(
    ofType(ProcurementActionTypes.GET_COMMENTS_LIST),
    switchMap((action:commentsList) => {
      return this.api.Commentslist(action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new commentsListSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );


  @Effect()
  addpyhsical$ = this.action$.pipe(
    ofType(ProcurementActionTypes.SUBMIT_PHYSICAL_PERCENTAGE),
    switchMap((action:submitPhysicalpercentage) => {
      return this.api.addphysicalpercentage(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new submitPhysicalpercentageSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  getpyhsical$ = this.action$.pipe(
    ofType(ProcurementActionTypes.GET_PHYSICAL_PERCENTAGE),
    switchMap((action:getPhysicalpercentage) => {
      return this.api.getpysicalpercent(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new getPhysicalpercentageSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );


  @Effect()
  createpacket$ = this.action$.pipe(
    ofType(ProcurementActionTypes.CREATE_SINGLE_PACKET),
    switchMap((action:createPacket) => {
      return this.api.createPacket(action.id,action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new createPacketSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  deletepacket$ = this.action$.pipe(
    ofType(ProcurementActionTypes.DELETE_SINGLE_PACKET),
    switchMap((action:deletePacket) => {
      return this.api.deletePacket(action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new deletePacketSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );


  @Effect()
  updatepacket$ = this.action$.pipe(
    ofType(ProcurementActionTypes.UPDATE_SINGLE_PACKET),
    switchMap((action:updatePacket) => {
      return this.api.updatePacket(action.id,action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new updatePacketSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err),new pullAllPacket(action.projectId)))
      );
    })
  );

  @Effect()
  pullpacket$ = this.action$.pipe(
    ofType(ProcurementActionTypes.PULL_ALL_PACKET),
    switchMap((action:pullAllPacket) => {
      return this.api.pullpacketlist(action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new pullAllPacketSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  createTender$ = this.action$.pipe(
    ofType(ProcurementActionTypes.CREATE_TENDER),
    switchMap((action:createTender) => {
      return this.api.createTender(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new createTenderSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );


  @Effect()
  pulltender$ = this.action$.pipe(
    ofType(ProcurementActionTypes.PULL_ALL_TENDER),
    switchMap((action:pullAllTender) => {
      return this.api.pulltenderlist(action.id,action.module).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new pullAllTenderSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  updateTender$ = this.action$.pipe(
    ofType(ProcurementActionTypes.UPDATE_TENDER),
    switchMap((action:updateTender) => {
      return this.api.updateTender(action.id,action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new updateTenderSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  deleteTender$ = this.action$.pipe(
    ofType(ProcurementActionTypes.DELETE_TENDER),
    switchMap((action:deleteTender) => {
      return this.api.deleteTender(action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new deleteTenderSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );



  @Effect()
  createAward$ = this.action$.pipe(
    ofType(ProcurementActionTypes.CREATE_AWARD),
    switchMap((action:createAward) => {
      return this.api.createaward(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new createAwardSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );


  @Effect()
  pullAward$ = this.action$.pipe(
    ofType(ProcurementActionTypes.PULL_ALL_AWARD),
    switchMap((action:pullAllAward) => {
      return this.api.pullawardlist(action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new pullAllAwardSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  updateAward$ = this.action$.pipe(
    ofType(ProcurementActionTypes.UPDATE_AWARD),
    switchMap((action:updateAward) => {
      return this.api.updateaward(action.id,action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new updateAwardSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  deleteAward$ = this.action$.pipe(
    ofType(ProcurementActionTypes.DELETE_AWARD),
    switchMap((action:deleteAward) => {
      return this.api.deleteaward(action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Get Budget successfully");
            return new deleteAwardSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );





}
