import { Action } from '@ngrx/store';

export enum MessagesActionTypes {
    GET_INBOX = '[User Mailbox] Get Inbox Mails',
    GET_INBOX_SUCCESS = '[User Mailbox] Get Inbox Mails Sucess',
    SEND_NEW_MESSAGE = '[User Mailbox] Send New Message',
    GET_MAIL_BY_ID = '[User Mailbox] Get Mail By Id',
    GET_MAIL_BY_ID_SUCCESS = '[User Mailbox] Get Mail By Id Success',
    MARK_MAIL_AS_READ = '[User Mailbox] Mark Mail As Read',
    DELETE_MAIL = '[User Mailbox] Delete Mail',
    DELETE_MAIL_SUCCESS = '[User Mailbox] Delete Mail Success',
    GET_CMP_ALL_START = '[all messages] start',
    GET_CMP_ALL_SUCCESS = '[all messages] success',
    GET_MAIL_BY_ID_IN_ALL_CMP_START = '[ all cmp id details start]',
    GET_MAIL_BY_ID_IN_ALL_CMP_SUCCESS = '[all cmp id details success]',
    GET_SEARCHED_MAIL = "GET_SEARCHED_MAIL",
    GET_SEARCHED_ALL_MAIL = "GET_SEARCHED_ALL_MAIL",
    GET_SEARCHED_MAIL_SUCCESS = "GET_SEARCHED_MAIL_SUCCESS",
    GET_SEARCHED_ALL_MAIL_SUCCESS = "GET_SEARCHED_ALL_MAIL_SUCCESS",
    CHECK_VIEW_ACCESS_FOR_DOC_START = "check view acces for all the docs start",
    CHECK_VIEW_ACCESS_FOR_DOC_SUCCESS = "check view access for all the docs success",
    ADD_VIEW_ACCESS_FOR_DOCUMENTS_START = "add view access for documents start",
    ADD_VIEW_ACCESS_FOR_DOCUMENTS_SUCCESS = "add view access for documents success",
    DELETE_MESSAGE = "[Messages] Delete Message",
    ADD_MESSAGE_TAGS = '[Messages] Add Message Tags',
    CLEAR_VIEW_ACCESS_FOR_RESPONSE = "clear view access for data",
    SEND_CONTENT_TO_MAIL = '[Messages] Send Content To Mail',
    DUMMY_MESSAGE_ACTION ='[Messages] dummy action',
    Mark_MAIL_AS_READ_SUCCESS ="[Messages ] mark mail as read success",
    DELETE_MESSAGE_SUCCESS ="[Messages] Delete message success",
    GET_REPLACED_USERS = "[Messages] Get Replaced Users",
    GET_REPLACED_USERS_SUCCESS = "[Messages] Get Replaced Users Success",
    CLEAR_ACTION = "[Messages] Clear",
    GET_MESSAGE_COUNT = "[Messages] Get Message Count"
}

export class GetInbox implements Action {
    readonly type: string = MessagesActionTypes.GET_INBOX;
    constructor(public payload: any) { }
}

export class GetInboxSuccess implements Action {
    readonly type: string = MessagesActionTypes.GET_INBOX_SUCCESS;
    constructor(public mails: any) { }
}

export class SendNewMessage implements Action {
    readonly type: string = MessagesActionTypes.SEND_NEW_MESSAGE;
    constructor(public payload: any) { }
}

export class GetMailById implements Action {
    readonly type: string = MessagesActionTypes.GET_MAIL_BY_ID;
    constructor(public threadId: string, public replacedUsersId?: string) { }
}

export class GetMailByIdSuccess implements Action {
    readonly type: string = MessagesActionTypes.GET_MAIL_BY_ID_SUCCESS;
    constructor(public data: any) { }
}

export class MarkMailAsRead implements Action {
    readonly type: string = MessagesActionTypes.MARK_MAIL_AS_READ;
    constructor(public threadId: string, public myMessages: boolean) { }
}

export class DeleteMail implements Action {
    readonly type: string = MessagesActionTypes.DELETE_MAIL;
    constructor(public threadId: string) { }
}

export class DeleteMailSuccess implements Action {
    readonly type: string = MessagesActionTypes.DELETE_MAIL_SUCCESS;
    constructor(public rsp: any) { }
}
export class GetAllCmpMeassagesStart implements Action {
    readonly type: string = MessagesActionTypes.GET_CMP_ALL_START
    constructor(public obj: any) { }
}
export class GetSearchedMail implements Action {
    readonly type: string = MessagesActionTypes.GET_SEARCHED_MAIL
    constructor(public payload: Object) { }
}

export class GetSearchedMailSuccess implements Action {
    readonly type: string = MessagesActionTypes.GET_SEARCHED_MAIL_SUCCESS;
    constructor(public data: any) { }
}

export class GetSearchedAllMails implements Action {
    readonly type: string = MessagesActionTypes.GET_SEARCHED_ALL_MAIL
    constructor(public payload: Object) { }
}

export class GetSearchedAllMailSuccess implements Action {
    readonly type: string = MessagesActionTypes.GET_SEARCHED_ALL_MAIL_SUCCESS;
    constructor(public data: any) { }
}
export class GetMailByIdInAllCmpStart implements Action {
    readonly type: string = MessagesActionTypes.GET_MAIL_BY_ID_IN_ALL_CMP_START
    constructor(public threadId: any) { }
}
export class GetMailByIdInAllCmpSuccess implements Action {
    readonly type: string = MessagesActionTypes.GET_MAIL_BY_ID_IN_ALL_CMP_SUCCESS;
    constructor(public response: any) { }
}
export class CheckViewAccessGorDocStart implements Action {
    readonly type: string = MessagesActionTypes.CHECK_VIEW_ACCESS_FOR_DOC_START;
    constructor(public payload: any) { }

}
export class CheckViewAccessGorDocSuccess implements Action {
    readonly type: string = MessagesActionTypes.CHECK_VIEW_ACCESS_FOR_DOC_SUCCESS;
    constructor(public response: any) { }
}
export class AddViewAccessForDocumentStart implements Action {
    readonly type: string = MessagesActionTypes.ADD_VIEW_ACCESS_FOR_DOCUMENTS_START;
    constructor(public payload: any) { }
}
export class AddViewAccessForDocumentSuccess implements Action {
    readonly type: string = MessagesActionTypes.ADD_VIEW_ACCESS_FOR_DOCUMENTS_SUCCESS;
    constructor(public response: any) { }
}

export class DeleteMessage implements Action {
    readonly type: string = MessagesActionTypes.DELETE_MESSAGE;
    constructor(public messageId: string, public threadId: string, public messageLength: number) { }
}

export class AddMessageTag implements Action {
    readonly type: string = MessagesActionTypes.ADD_MESSAGE_TAGS;
    constructor(public parentId: string, public payload: any, public threadId: string) { }
}
export class ClearViewAccess implements Action {
    readonly type: string = MessagesActionTypes.CLEAR_VIEW_ACCESS_FOR_RESPONSE;
    constructor() { }
}

export class SendContentToMail implements Action {
    readonly type: string = MessagesActionTypes.SEND_CONTENT_TO_MAIL;
    constructor(public parentId: string) { }
}
export class DummyMessageAction implements Action {
    readonly type :string =MessagesActionTypes.DUMMY_MESSAGE_ACTION;
    constructor() {}
} 
export class MarkMailAsReadSuccess implements Action {
    readonly type :string =MessagesActionTypes.Mark_MAIL_AS_READ_SUCCESS;
    constructor(public response, public threadId) {}
}
export class DeleteMessageSuccess implements Action {
    readonly type :string =MessagesActionTypes.DELETE_MESSAGE_SUCCESS;
    constructor(public response){}
}

export class GetReplacedUsers implements Action {
    readonly type: string = MessagesActionTypes.GET_REPLACED_USERS;
    constructor() { }
}

export class GetReplacedUsersSuccess implements Action {
    readonly type: string = MessagesActionTypes.GET_REPLACED_USERS_SUCCESS;
    constructor(public response: any) { }
}

export class ClearAction implements Action {
    readonly type: string = MessagesActionTypes.CLEAR_ACTION;
    constructor() { }
}

export class GetMessageCount implements Action {
    readonly type: string = MessagesActionTypes.GET_MESSAGE_COUNT;
    constructor(public messageCountObj: any) { }
}



