import {
  MatFormFieldModule,
  MatInputModule,
  MatSelectModule,
  MatButtonModule,
  MatCardModule,
  MatChipsModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatIconModule,
  MatMenuModule,
  MatExpansionModule,
  MatProgressSpinnerModule,
  MatBadgeModule,
  MatListModule,

} from "@angular/material";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTableModule } from "@angular/material/table";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatTooltipModule } from "@angular/material/tooltip";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatGridListModule } from "@angular/material/grid-list";
import { MatDialogModule } from "@angular/material/dialog";
import { MatDividerModule } from "@angular/material/divider";
import { MatRippleModule } from "@angular/material/core";
import { MatProgressBarModule } from "@angular/material/progress-bar";
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatRadioModule } from '@angular/material/radio';
import {MatStepperModule} from '@angular/material/stepper';


export const MAT_DECLARATIONS = [
  MatTabsModule,
  MatTableModule,
  MatSlideToggleModule,
  MatFormFieldModule,
  MatInputModule,
  MatListModule,
  MatSelectModule,
  MatCheckboxModule,
  MatButtonModule,
  MatCardModule,
  MatChipsModule,
  MatDatepickerModule,
  MatNativeDateModule,
  MatTooltipModule,
  MatAutocompleteModule,
  MatGridListModule,
  MatDialogModule,
  MatIconModule,
  MatMenuModule,
  MatExpansionModule,
  MatDividerModule,
  MatRippleModule,
  MatProgressBarModule,
  MatBadgeModule,
  MatProgressSpinnerModule,
  MatPaginatorModule,
  MatRadioModule,
  MatStepperModule,
  
];

export const MAT_PROVIDERS = [MatDatepickerModule];
