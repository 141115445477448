import { Action } from '@ngrx/store';

export enum UserManagementActionTypes {
    GET_USERS = '[UserManagement] Get Users',
    GET_USERS_SUCCESS = '[UserManagement] Get Users Sucess',
    GET_USER_DETAILS = '[UserManagement] Get User By Id',
    GET_USER_DETAILS_SUCCESS = '[UserManagement] Get User by Id Success',
    GET_USER_IMAGE = '[UserManagement] Get User Image',
    GET_USER_IMAGE_SUCCESS = '[UserManagement] Get User Image Success',
    GET_ROLES = '[UserManagement] Get Roles',
    GET_ROLES_SUCCESS = '[UserManagement] Get Roles Sucess',
    GET_PROJECT_CODES = '[UserManagement] Get Project Codes',
    GET_PROJECT_CODES_SUCCESS = '[UserManagement] Get Project Codes Sucess',
    INVITE_USER = '[UserManagement] Invite User',
    ADD_ROLES_TO_USER = '[UserManagement] Add Roles to user',
    CHANGE_USER_STATE = '[UserManagment] Change User state',
    CHANGE_USER_STATE_SUCCESS = '[UserManagement] Change User State Success',
    CHANGE_PASSWORD = '[UserManagement] Change Password',
    CHANGE_PASSWORD_SUCCESS = '[UserManagement] Change Password Success',
    INVITE_BULK_USER = 'INVITE_BULK_USER',
    INVITE_BULK_USER_SUCCESS = 'INVITE_BULK_USER_SUCCESS',
    RESEND_INVITE = '[UserManagement] Resend Invite',
    USER_PROFILE_LOG = '[UserManagement] Get User Profile Log',
    USER_PROFILE_LOG_SUCCESS = '[UserManagement] Get User Profile Log Sucess',
    USER_LOGIN_LOG = '[UserManagement] Get User Login Log',
    USER_LOGIN_LOG_SUCCESS = '[UserManagement] Get User Login Log Sucess',
    CLEAR_ACTION ='[UserManagement] clear action start',
    USER_CHANGE_EMAIL = '[UserManagement] User Change Email',
    USER_CHANGE_EMAIL_SUCCESS = '[UserManagement] User Change Email Success',
    USER_CHANGE_PHONE = '[UserManagement] User Change Phone',
    USER_CHANGE_PHONE_SUCCESS = '[UserManagement] User Change Phone Success',
    CHANGE_PASSWORD_BY_ADMIN = '[UserManagement] change password by admin',
    REPLACE_USER = 'REPLACE_USER',
    RESET_USER = 'RESET_USER',
}

export class GetUsers implements Action {
    readonly type: string = UserManagementActionTypes.GET_USERS;
    constructor(public payload: any) { }
}

export class GetUserSuccess implements Action {
    readonly type: string = UserManagementActionTypes.GET_USERS_SUCCESS;
    constructor(public users: any) { }
}

export class GetUserDetails implements Action {
    readonly type: string = UserManagementActionTypes.GET_USER_DETAILS;
    constructor(public id: string) { }
}

export class GetUserDetailsSuccess implements Action {
    readonly type: string = UserManagementActionTypes.GET_USER_DETAILS_SUCCESS;
    constructor(public userDetails: object) { }
}

export class GetUserImage implements Action {
    readonly type: string = UserManagementActionTypes.GET_USER_IMAGE;
    constructor(public id: string) { }
}

export class GetUserImageSuccess implements Action {
    readonly type: string = UserManagementActionTypes.GET_USER_IMAGE_SUCCESS;
    constructor(public userImage: any) { }
}


export class GetRoles implements Action {
    readonly type: string = UserManagementActionTypes.GET_ROLES;
    constructor() { }
}

export class GetRolesSuccess implements Action {
    readonly type: string = UserManagementActionTypes.GET_ROLES_SUCCESS;
    constructor(public roles: any) { }
}

export class GetProjectCodes implements Action {
    readonly type: string = UserManagementActionTypes.GET_PROJECT_CODES;
    constructor() { }
}

export class GetProjectCodesSuccess implements Action {
    readonly type: string = UserManagementActionTypes.GET_PROJECT_CODES_SUCCESS;
    constructor(public projectCodes: any) { }
}

export class InviteUser implements Action {
    readonly type: string = UserManagementActionTypes.INVITE_USER;
    constructor(public payload: any) { }
}

export class InviteBulkUser implements Action {
    readonly type: string = UserManagementActionTypes.INVITE_BULK_USER;
    constructor(public payload: any) { }
}

export class InviteBulkUserSuccess implements Action {
    readonly type: string = UserManagementActionTypes.INVITE_BULK_USER_SUCCESS;
    constructor(public response: any) { }
}

export class AddRolesNProjectsToUser implements Action {
    readonly type: string = UserManagementActionTypes.ADD_ROLES_TO_USER;
    constructor(public userId, public payload: any) { }
}

export class ChangeUserState implements Action {
    readonly type: string = UserManagementActionTypes.CHANGE_USER_STATE;
    constructor(public userId: any) { }
}

export class ChangeUserStateSuccess implements Action {
    readonly type: string = UserManagementActionTypes.CHANGE_USER_STATE_SUCCESS;
    constructor(public response: any) { }
}

export class ChangePassword implements Action {
    readonly type: string = UserManagementActionTypes.CHANGE_PASSWORD;
    constructor(public payload: any) { }
}

export class ChangePasswordSuccess implements Action {
    readonly type: string = UserManagementActionTypes.CHANGE_PASSWORD_SUCCESS;
    constructor(public response: any) { }
}

export class ResendInvite implements Action {
    readonly type: string = UserManagementActionTypes.RESEND_INVITE;
    constructor(public payload: any) { }
}


export class UserProfileLog implements Action {
    readonly type: string = UserManagementActionTypes.USER_PROFILE_LOG;
    constructor(public userId: any) { }
}

export class UserProfileLogSuccess implements Action {
    readonly type: string = UserManagementActionTypes.USER_PROFILE_LOG_SUCCESS;
    constructor(public userProfileLogs: object) { }
}

export class UserLoginLog implements Action {
    readonly type: string = UserManagementActionTypes.USER_LOGIN_LOG;
    constructor(public userId: any) { }
}

export class UserLoginLogSuccess implements Action {
    readonly type: string = UserManagementActionTypes.USER_LOGIN_LOG_SUCCESS;
    constructor(public userLoginLogs: any) { }
}


export class ChangePasswordByAdmin implements Action {
  readonly type: string = UserManagementActionTypes.CHANGE_PASSWORD_BY_ADMIN;
  constructor(public userID: any, public payload: any) {} 
}

export class ClearAction implements Action {
    readonly type: string = UserManagementActionTypes.CLEAR_ACTION;
    constructor() { }
}

export class UserChangeEmail implements Action {
    readonly type: string = UserManagementActionTypes.USER_CHANGE_EMAIL;
    constructor(public payload: any, public userId: string) { }
}

export class UserChangeEmailSuccess implements Action {
    readonly type: string = UserManagementActionTypes.USER_CHANGE_EMAIL_SUCCESS;
    constructor(public response: any) { }
}

export class UserChangePhone implements Action {
    readonly type: string = UserManagementActionTypes.USER_CHANGE_PHONE;
    constructor(public payload: any, public userId: string) { }
}

export class UserChangePhoneSuccess implements Action {
    readonly type: string = UserManagementActionTypes.USER_CHANGE_PHONE_SUCCESS;
    constructor(public response: any) { }
}

export class ReplaceUser implements Action {
  readonly type: string = UserManagementActionTypes.REPLACE_USER;
  constructor(public oldUserId: any, public payload: any) { }
}

export class ResetUser implements Action {
    readonly type: string = UserManagementActionTypes.RESET_USER;
    constructor(public userId: any, public payload: any) { }
  }