import { Component, OnInit, Inject } from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";
import { ChangePhoneVerify, ChangePasswordOtpVerify, ClearChangePasswordRes, ChangePhone } from '../../store/profile.actions';
import { Logout } from '../../../../../login/store/auth.actions';
import { take } from 'rxjs/operators';
import { ChangePassword } from '../../../user-management/store/user-managment.actions';

@Component({
    selector: 'app-verify-change-phone',
    templateUrl: './verify-change-phone.component.html',
    styleUrls: ['./verify-change-phone.component.scss']
})
export class VerifyChangePhoneComponent implements OnInit {
    changePhoneVerifyForm: FormGroup;
    userId: any;
    token: any;

    constructor(
        private store: Store<AppState>,
        private fb: FormBuilder,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<any>,
    ) {
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
        this.store.select(state => state.auth).pipe(take(1)).subscribe((auth: any) => {
            this.token = auth.accessToken;
            if (auth.user) {
                this.userId = (auth.user.details || {})._id;
            }
        });
        this.changePhoneVerifyForm = this.fb.group({
            otp: ['', [Validators.required, Validators.minLength, Validators.maxLength, Validators.pattern]],
            mobileOtp: ['', [Validators.required, Validators.minLength, Validators.maxLength, Validators.pattern]]
        });
    }

    verifyOtp() {
        if (this.changePhoneVerifyForm.invalid) {
            return false;
        }

        if (this.data.type == 'changePassword') {
            const payload = {
                otp: this.changePhoneVerifyForm.value.otp,
                mobileOtp: this.changePhoneVerifyForm.value.mobileOtp,
            }

            this.store.dispatch(new ClearChangePasswordRes());
            this.store.dispatch(new ChangePasswordOtpVerify(payload));
            this.store.select(state => state.profile.changePasswordRes).subscribe((response) => {
                if (Object.keys(response).length) {
                    this.dialogRef.close();
                    this.store.dispatch(new Logout(this.userId, this.token));
                }
            });
        } else {
            const payload = {
                otp: this.changePhoneVerifyForm.value.otp,
                mobileOtp: this.changePhoneVerifyForm.value.mobileOtp,
                countryCode: this.data.countryCode,
                phone: this.data.phone
            }
            this.store.dispatch(new ChangePhoneVerify(payload));
        }
    }

    isInputNumber(event) {
        const ch = String.fromCharCode(event.which);
        if (!(/[0-9]/.test(ch))) {
            event.preventDefault();
        }
    }

    onCloseDialog(): void {
        this.dialogRef.close();
    }

    resendOTP() {
        if (!this.data.byAdmin && this.data.type == "changePassword") {
            const payload = {
                old_password: this.data.oldPass,
                new_password: this.data.newPass
            }
            this.store.dispatch(new ChangePassword(payload));
        }
        else {
            const payload = {
                newCountryCode: this.data.countryCode,
                newPhone: this.data.phone,
                password: this.data.password,
            }
            this.store.dispatch(new ChangePhone(this.data.registeredEmail, payload));
        }
    }
}
