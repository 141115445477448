import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { UpdateTaskDetails } from '../store/tasks.actions';

@Component({
  selector: 'app-add-viewers',
  templateUrl: './add-viewers.component.html',
  styleUrls: ['./add-viewers.component.scss']
})
export class AddViewersComponent implements OnInit {
  
  taskId;
  taskDetail: any;
  selectedUsers: any;
  defaultSelection: Array<string> = [];
  defaultSelectionObject: Array<any> = [];
  noViewers;
  constructor(
    public dialogRef: MatDialogRef<AddViewersComponent>,
    @Inject(MAT_DIALOG_DATA) public data: any,
    public store: Store<AppState>
  ) {
    this.dialogRef.disableClose = true;
    this.taskId = data.taskId;
    if(data.viewers.length) {
      this.defaultSelection = data.viewers.map(user => user._id);
      this.defaultSelectionObject = data.viewers;
    }
  }

  ngOnInit() {
  }

  onNoClick(): void {
    this.dialogRef.close('');
  }

  getMembers(event) {
    this.selectedUsers = event.selectedItems;
    if (this.selectedUsers.length == 0) {
      this.noViewers = true;
    } else {
      this.noViewers = false;
    }

  }

  addMember() {
    if (this.selectedUsers.length == 0) {
      this.noViewers = true;
    //   return;
    } 

    const users = this.selectedUsers.map(user => user._id);
    this.store.dispatch(new UpdateTaskDetails(this.taskId, { 'viewers': users }));
    this.dialogRef.close('');
  }
}
