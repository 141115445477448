import { Action } from '@ngrx/store';

export enum ProfileActionTypes {
    EDIT_PROFILE = '[Profile] Edit Profile',
    GET_PROFILE = '[Profile] Get Profile Details',
    GET_PROFILE_SUCCESS = '[Profile] Profile Success',
    GET_PROFILE_IMAGE = '[Profile] Get profile Image',
    GET_PROFILE_IMAGE_SUCCESS = '[Profile] Get profile Image Success',
    CHANGE_EMAIL = '[profile] Change Email',
    CHANGE_EMAIL_SUCCESS = '[profile] Change Email Success',
    CHANGE_EMAIL_VERIFY = '[profile] Change Email Verify',
    CHANGE_PHONE = '[profile] Change Phone',
    CHANGE_PHONE_SUCCESS = '[profile] Change Phone Success',
    CHANGE_PHONE_VERIFY = '[profile] Change Phone Verify',
    GET_USER_DETAILS = '[Edit User] get user details',
    GET_USER_DETAILS_SUCCESS = '[Edit User] get user details success',
    EDIT_PROFILE_BY_ADMIN = '[Edit user] Edit User by Admin',
    CLEAR_CHANGE_PSWD_RESPONSE= '[Profile] Clear change password response',
    PROFILE_ERROR_ACTION = '[Error action] Profile Error Action',
    PROFILE_ERROR_ACTION_COMPLETE ='[Errpr action ] profile Error Action',
    CHANGE_PASSWORD_OTP_VERIFY = '[profile] Change Password Otp Verify',
    CHANGE_PASSWORD_OTP_VERIFY_SUCCESS = '[profile] Change Password Otp Verify Success'
}

export class GetProfile implements Action {
    readonly type: string = ProfileActionTypes.GET_PROFILE;
    constructor() { }
}

export class GetProfileImage implements Action {
    readonly type: string = ProfileActionTypes.GET_PROFILE_IMAGE;
    constructor(public id: string) { }
}

export class GetProfileImageSuccess implements Action {
    readonly type: string = ProfileActionTypes.GET_PROFILE_IMAGE_SUCCESS;
    constructor(public image: any) { }
}

export class EditProfile implements Action {
    readonly type: string = ProfileActionTypes.EDIT_PROFILE;
    constructor(public id: string, public payload: any) { }
}

export class ProfileSuccess implements Action {
    readonly type: string = ProfileActionTypes.GET_PROFILE_SUCCESS;
    constructor(public profile: any) { }
}

export class ChangeEmail implements Action {
    readonly type: string = ProfileActionTypes.CHANGE_EMAIL;
    constructor(public registeredCountryCode: string, public registeredPhone: string, public payload: any) { }
}

export class ChangeEmailSuccess implements Action {
    readonly type: string = ProfileActionTypes.CHANGE_EMAIL_SUCCESS;
    constructor(public response: any) { }
}

export class ChangeEmailVerify implements Action {
    readonly type: string = ProfileActionTypes.CHANGE_EMAIL_VERIFY;
    constructor(public payload: any) { }
}

export class ChangePhone implements Action {
    readonly type: string = ProfileActionTypes.CHANGE_PHONE;
    constructor(public registeredEmail: string, public payload: any) { }
}

export class ChangePhoneSuccess implements Action {
    readonly type: string = ProfileActionTypes.CHANGE_PHONE_SUCCESS;
    constructor(public response: any) { }
}

export class ClearChangePasswordRes implements Action {
    readonly type: string = ProfileActionTypes.CLEAR_CHANGE_PSWD_RESPONSE;
    constructor() { }
}

export class ChangePhoneVerify implements Action {
    readonly type: string = ProfileActionTypes.CHANGE_PHONE_VERIFY
    constructor(public payload: any) { }
}

export class GetUserDetailsByAdmin implements Action {
    readonly type: string = ProfileActionTypes.GET_USER_DETAILS
    constructor(public Id: string) { }
}

export class GetUserDetailsByAdminSuccess implements Action {
    readonly type: string = ProfileActionTypes.GET_USER_DETAILS_SUCCESS
    constructor(public details: string) { }
}

export class EditUserByAdmin implements Action {
    readonly type: string = ProfileActionTypes.EDIT_PROFILE_BY_ADMIN
    constructor(public id: string, public payload: any) { }
}
export class ProfileErrorAcion implements Action {
    readonly type :string =ProfileActionTypes.PROFILE_ERROR_ACTION
    constructor(public error :any) {}
}
export class ProfileErrorActionComplete implements Action {
    readonly type: string =ProfileActionTypes.PROFILE_ERROR_ACTION_COMPLETE
    constructor() {}
}

export class ChangePasswordOtpVerify implements Action {
    readonly type: string = ProfileActionTypes.CHANGE_PASSWORD_OTP_VERIFY;
    constructor(public payload: any) { }
}

export class ChangePasswordOtpVerifySuccess implements Action {
    readonly type: string = ProfileActionTypes.CHANGE_PASSWORD_OTP_VERIFY_SUCCESS;
    constructor(public response: any) { }
}
