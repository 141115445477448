import { Injectable } from '@angular/core';
import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, Observer, fromEvent, merge } from 'rxjs';
import { Store, select } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { catchError, map } from 'rxjs/operators';
import { Logout } from '../login/store/auth.actions';
import { MatDialog } from '@angular/material';
import { LoaderHide, LoaderShow } from '../features/loader/store/loader.actions';
import { NotificationsService } from 'angular2-notifications';

@Injectable()
export class ServerURLInterceptor implements HttpInterceptor {

  private accessToken = null;
  userId: any;
  isOnline: boolean = true

  constructor(
    private store: Store<AppState>,
    private dialogRef: MatDialog,
    private notificationService: NotificationsService
  ) {
    this.createOnline$().subscribe(isOnline => {
      this.isOnline = isOnline
      if (!isOnline) {
        this.notificationService.error('No Internet Access. Please check your network and try again.')
      } else {
        console.log("Internet connected");
      }
    });

    store.select(state => state.auth).subscribe(auth => {
      this.accessToken = auth.accessToken;
      const userDetails = (auth.user || {}).details;
      this.userId = (userDetails || {})._id;
    });
  }

  intercept = ((req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> => {
    if (!this.accessToken) {
      return next.handle(req);
    }
    // this.store.dispatch(new LoaderShow());
    const authReq = req.clone({
      headers: req.headers.set('authorization', `Bearer ${this.accessToken}`)
    });
    return next.handle(authReq)
      // handles condition: when admin inactivates a user, that user should get logged out upon his next request
      .pipe(
        catchError((error: HttpErrorResponse) => {
          if (error.status === 401) {
            this.store.dispatch(new Logout(this.userId, this.accessToken));
            this.dialogRef.closeAll();
          } else if (error.status === 502 || error.status === 0) {
            if (!this.isOnline) {
              this.notificationService.error('No Internet Access. Please check your network and try again.')
            } else {
              this.notificationService.error('[Error: 9999] Server connection issue. Please contact administrator if problem persists');
            }
          }
          this.store.dispatch(new LoaderHide());
          return throwError(error);
        })
      );
  });

  createOnline$() {
    return merge<boolean>(
      fromEvent(window, 'offline').pipe(map(() => false)),
      fromEvent(window, 'online').pipe(map(() => true)),
      new Observable((sub: Observer<boolean>) => {
        sub.next(navigator.onLine);
        sub.complete();
      }));
  }
}
