import { Action } from "@ngrx/store";
import { AnyFn } from "@ngrx/store/src/selector";


export enum ContractManagementActions {
  GET_ALL_CONTRACTS = '[Contract Management] Get All Contracts',
  GET_ALL_CONTRACTS_SUCCESS = '[Contract Management] Get All Contracts Success',
  GET_MY_CONTRACTS_SUCCESS = '[Contract Management] Get My Contracts Success',
  GET_MY_CONTRACTS = '[Contract Management] Get My Contracts',
  ADD_NEW_CONTRACT = '[Contract Management] Add new contract',
  ADD_NEW_CONTRACT_SUCCESS = "[Contract Management] Add new contract Success",
  EDIT_CONTRACT = '[Contract Management] Edit contract',
  EDIT_CONTRACT_SUCCESS = "[Contract Management] Edit contract Success",
  GET_CONTRACT_DETAILS = "[Contract Management] Get contract Details",
  GET_CONTRACT_DETAILS_SUCCESS = "[Contract Management] Get contract Details Success",
  CONTRACT_ID = "[Contract Management] Contract Id",
  CONTRACT_ID_SUCCESS = "CONTRACT_ID_SUCCESS",
  ARCHIVE_CONTRACT = "[Contract Management] Archive Contract ",
  ARCHIVE_CONTRACT_SUCCESS = "[Contract Management] Archive Contract Success",
  CLOSE_CONTRACT = "[Contract Management] CLOSE Contract ",
  CLOSE_CONTRACT_SUCCESS = "[Contract Management] CLOSE Contract Success",
  GET_PAYMENT_SCHEDULE_DETAILS = "GET_PAYMENT_SCHEDULE_DETAILS",
  GET_PAYMENT_SCHEDULE_DETAILS_SUCCESS = "GET_PAYMENT_SCHEDULE_DETAILS_SUCCESS",
  UPDATE_MILESTONES = "[Contract Management] Add or Edit Milestones",
  UPDATE_MILESTONES_SUCCESS = "[Contract Management] Add or Edit Milestones Success",
  GET_CONTRACT_ACTIVITY = "[Contract Management] Get Contact Activity ",
  GET_CONTRACT_ACTIVITY_SUCCESS = "[Contract Management] Get Contact Activity Success",
  CREATE_INVOICE = "[Contract Management] Create Invoice",
  CREATE_INVOICE_SUCCESS = "[Contract Management] Create Invoice Success",
  GET_INVOICE_DETAILS = "[Contract Management] Get Invoice Details",
  GET_INVOICE_DETAILS_SUCCESS = "[Contract Management] Get Invoice Details Success",
  GET_INDIVIDUAL_INVOICE_DETAILS = "[Contract Management] Get Invoice Detail for one ",
  GET_INVOICE_ACTIVITY = "[Contract Management] Get Invoice Activity ",
  GET_INVOICE_ACTIVITY_SUCCESS = "[Contract Management] Get Invoice Activity Success",
  GET_INDIVIDUAL_INVOICE_DETAILS_SUCCESS = "[Contract Management] Get Invoice Detail for one  Success",
  GET_TIMESHEET_DETAILS = " [Contract Management]  GET_TIMESHEET_DETAILS",
  GET_TIMESHEET_DETAILS_SUCCESS = " [Contract Management]  GET_TIMESHEET_DETAILS_SUCCESS",
  GET_TIMESHEET_PROJECT_DETAILS = " [Contract Management]  GET_TIMESHEET_PROJECT_DETAILS",
  GET_TIMESHEET_ACTIVITY = "[Contract Management] Get Timesheet Activity ",
  GET_TIMESHEET_ACTIVITY_SUCCESS = "[Contract Management] Get Timesheet Activity Success",
  GET_TIMESHEET_PROJECT_DETAILS_SUCCESS = " [Contract Management]  GET_TIMESHEET_PROJECT_DETAILS_SUCCESS",
  GET_TIMESHEET_PROJECT_MONTH_DETAILS = " [Contract Management]  GET_TIMESHEET_PROJECT_MONTH_DETAILS",
  GET_TIMESHEET_PROJECT_MONTH_DETAILS_SUCCESS = " [Contract Management]  GET_TIMESHEET_PROJECT_MONTH_DETAILS_SUCCESS",
  DOWNLOAD_LIST = " [Contract Management]  DOWNLOAD_LIST",
  DOWNLOAD_LIST_SUCCESS = " [Contract Management]  DOWNLOAD_LIST_SUCCESS",
  REOPEN_CONTRACT = " [Contract Management]  Reopens Contract",
  REOPEN_CONTRACT_SUCCESS = " [Contract Management]  REOPEN_CONTRACT_SUCCESS",
  ADD_EDIT_TIMESHEETS = "ADD_EDIT_TIMESHEETS",
  ADD_EDIT_TIMESHEETS_SUCCESS = "ADD_EDIT_TIMESHEETS_SUCCESS",
  INVOICE_ACTIONS = "INVOICE_ACTIONS",
  INVOICE_ACTIONS_SUCCESS = "INVOICE_ACTIONS_SUCCESS"
}

export class GetAllContracts implements Action {
  readonly type: string = ContractManagementActions.GET_ALL_CONTRACTS;
  constructor(public searchString: any = "", public page: any = "", public limit: any = "") { }
}

export class GetAllContractsSuccess implements Action {
  readonly type: string = ContractManagementActions.GET_ALL_CONTRACTS_SUCCESS;
  constructor(public data: any) { }
}

export class GetMyContracts implements Action {
  readonly type: string = ContractManagementActions.GET_MY_CONTRACTS;
  constructor(public searchString: any = "", public page: any = "", public limit: any = "") { }
}

export class GetMyContractsSuccess implements Action {
  readonly type: string = ContractManagementActions.GET_MY_CONTRACTS_SUCCESS;
  constructor(public data: any) { }
}

export class AddNewContract implements Action {
  readonly type: string = ContractManagementActions.ADD_NEW_CONTRACT;
  constructor(public payload: any) { }
}

export class AddNewContractSuccess implements Action {
  readonly type: string = ContractManagementActions.ADD_NEW_CONTRACT_SUCCESS;
  constructor(public payload: any) { }
}

export class EditContract implements Action {
  readonly type: string = ContractManagementActions.EDIT_CONTRACT;
  constructor(public payload: any) { }
}

export class EditContractSuccess implements Action {
  readonly type: string = ContractManagementActions.EDIT_CONTRACT_SUCCESS;
  constructor(public response: any) { }
}
export class GetContractDetails implements Action {
  readonly type: string = ContractManagementActions.GET_CONTRACT_DETAILS;
  constructor(public taskId: string,public isMainView:boolean = true) { }
}

export class GetContractDetailsSuccess implements Action {
  readonly type: string = ContractManagementActions.GET_CONTRACT_DETAILS_SUCCESS;
  constructor(public response: any) { }
}

export class ContractId implements Action {
  readonly type: string = ContractManagementActions.CONTRACT_ID;
  constructor(public response: any) { }
}

export class ContractIdSuccess implements Action {
  readonly type: string = ContractManagementActions.CONTRACT_ID_SUCCESS;
  constructor(public response: any) { }
}

export class ArchiveContract implements Action {
  readonly type: string = ContractManagementActions.ARCHIVE_CONTRACT;
  constructor(public payload: any) { }
}

export class ArchiveContractSuccess implements Action {
  readonly type: string = ContractManagementActions.ARCHIVE_CONTRACT_SUCCESS;
  constructor(public payload: any) { }
}

export class CloseContract implements Action {
  readonly type: string = ContractManagementActions.CLOSE_CONTRACT;
  constructor(public payload: any) { }
}

export class CloseContractSuccess implements Action {
  readonly type: string = ContractManagementActions.CLOSE_CONTRACT_SUCCESS;
  constructor(public response: any) { }
}

export class GetPaymentScheduleDetails implements Action {
  readonly type: string = ContractManagementActions.GET_PAYMENT_SCHEDULE_DETAILS;
  constructor(public contractId: string) { }
}

export class GetPaymentScheduleDetailsSuccess implements Action {
  readonly type: string = ContractManagementActions.GET_PAYMENT_SCHEDULE_DETAILS_SUCCESS;
  constructor(public response: any) { }
}
export class UpdateMilestone implements Action {
  readonly type: string = ContractManagementActions.UPDATE_MILESTONES;
  constructor(public id: any, public payload: any, public contractID: any) { }
}

export class UpdateMilestoneSuccess implements Action {
  readonly type: string = ContractManagementActions.UPDATE_MILESTONES_SUCCESS;
  constructor(public payload: any) { }
}

export class GetContractActivityLog implements Action {
  readonly type: string = ContractManagementActions.GET_CONTRACT_ACTIVITY;
  constructor(public contractID: any) { }
}

export class GetContractActivityLogSuccess implements Action {
  readonly type: string = ContractManagementActions.GET_CONTRACT_ACTIVITY_SUCCESS;
  constructor(public response: any) { }
}

export class GetInvoiceActivityLog implements Action {
  readonly type: string = ContractManagementActions.GET_INVOICE_ACTIVITY;
  constructor(public invoiceID: any) { }
}

export class GetInvoiceActivityLogSuccess implements Action {
  readonly type: string = ContractManagementActions.GET_INVOICE_ACTIVITY_SUCCESS;
  constructor(public response: any) { }
}

export class GetTimesheetActivityLog implements Action {
  readonly type: string = ContractManagementActions.GET_TIMESHEET_ACTIVITY;
  constructor(public contractID: any, public month: any, public projectID:AnyFn) { }
}
export class GetTimesheetActivityLogSuccess implements Action {
  readonly type: string = ContractManagementActions.GET_TIMESHEET_ACTIVITY_SUCCESS;
  constructor(public response: any) { }
}
export class CreateInvoice implements Action {
  readonly type: string = ContractManagementActions.CREATE_INVOICE;
  constructor(public payload: any, public id: any) { }
}

export class CreateInvoiceSuccess implements Action {
  readonly type: string = ContractManagementActions.CREATE_INVOICE_SUCCESS;
  constructor(public response: any) { }
}

export class GetInvoiceDetails implements Action {
  readonly type: string = ContractManagementActions.GET_INVOICE_DETAILS;
  constructor(public contractId: string) { }
}

export class GetInvoiceDetailsSuccess implements Action {
  readonly type: string = ContractManagementActions.GET_INVOICE_DETAILS_SUCCESS;
  constructor(public response: any) { }
}

export class GetIndividualInvoiceDetails implements Action {
  readonly type: string = ContractManagementActions.GET_INDIVIDUAL_INVOICE_DETAILS;
  constructor(public invoiceId: string) { }
}

export class GetIndividualInvoiceDetailsSuccess implements Action {
  readonly type: string = ContractManagementActions.GET_INDIVIDUAL_INVOICE_DETAILS_SUCCESS;
  constructor(public response: any) { }
}

export class GetTimesheetDetails implements Action {
  readonly type: string = ContractManagementActions.GET_TIMESHEET_DETAILS;
  constructor(public contractId: string) { }
}

export class GetTimesheetDetailsSuccess implements Action {
  readonly type: string = ContractManagementActions.GET_TIMESHEET_DETAILS_SUCCESS;
  constructor(public response: any) { }
}

export class GetTimesheetProjectDetails implements Action {
  readonly type: string = ContractManagementActions.GET_TIMESHEET_PROJECT_DETAILS;
  constructor(public phaseId: string, public projectId: string) { }
}

export class GetTimesheetProjectDetailsSuccess implements Action {
  readonly type: string = ContractManagementActions.GET_TIMESHEET_PROJECT_DETAILS_SUCCESS;
  constructor(public response: any) { }
}

export class GetTimesheetProjectMonthDetails implements Action {
  readonly type: string = ContractManagementActions.GET_TIMESHEET_PROJECT_MONTH_DETAILS;
  constructor(public projId: string, public phaseId: string, public monthId: string) { }
}

export class GetTimesheetProjectMonthDetailsSuccess implements Action {
  readonly type: string = ContractManagementActions.GET_TIMESHEET_PROJECT_MONTH_DETAILS_SUCCESS;
  constructor(public response: any) { }
}

export class DownloadContractsExcel implements Action {
  readonly type: string = ContractManagementActions.DOWNLOAD_LIST;
  constructor() { }
}

export class DownloadContractsExcelSuccess implements Action {
  readonly type: string = ContractManagementActions.DOWNLOAD_LIST_SUCCESS;
  constructor(public response: any) { }
}


export class ReOpenContract implements Action {
  readonly type: string = ContractManagementActions.REOPEN_CONTRACT;
  constructor(public contractID: any) { }
}

export class ReOpenContractSuccess implements Action {
  readonly type: string = ContractManagementActions.REOPEN_CONTRACT_SUCCESS;
  constructor(public response: any) { }
}

export class AddEditTimeSheets implements Action {
  readonly type: string = ContractManagementActions.ADD_EDIT_TIMESHEETS;
  constructor(public payload: any, public id: any) { }
}


export class AddEditTimeSheetsSuccess implements Action {
  readonly type: string = ContractManagementActions.ADD_EDIT_TIMESHEETS_SUCCESS;
  constructor(public response: any) { }
}

export class InvoiceAction implements Action {
  readonly type: string = ContractManagementActions.INVOICE_ACTIONS;
  constructor(public payload: any, public id: any, public contractId: any) { }
}


export class InvoiceActionSuccess implements Action {
  readonly type: string = ContractManagementActions.INVOICE_ACTIONS_SUCCESS;
  constructor(public response: any) { }
}

