import {
  BudgetActionTypes,
  allList,
  allListSuccess,
  budgetList,
  budgetListSuccess,
  categoryList,
  categoryListSuccess,
  categorydetails,
  categorydetailsSuccess,
  checkCategoryAvailable,
  checkCategoryAvailableSuccess,
  checkEurExisting,
  checkEurExistingExpenses,
  checkEurExistingExpensesSuccess,
  checkEurExistingSuccess,
  checkLineItemAvailable,
  checkLineItemAvailableSuccess,
  createCategory,
  createCategorySuccess,
  createNiuaOrMohuaSaction,
  createNiuaOrMohuaSactionSuccess,
  createlineItem,
  createlineItemSuccess,
  createsubCategory,
  createsubCategorySuccess,
  deleteCategory,
  deleteCategorySuccess,
  deleteNiuaOrMohuaSaction,
  deleteNiuaOrMohuaSactionSuccess,
  deletegrant,
  deletegrantSuccess,
  deletelineItem,
  deletelineItemSuccess,
  deletesubCategory,
  deletesubCategorySuccess,
  downloadReport,
  downloadReportSuccess,
  dropdownCategoryList,
  dropdownCategoryListSuccess,
  dropdownSubCategoryList,
  dropdownsubCategoryListSuccess,
  editBudget,
  editBudgetSuccess,
  editCategory,
  editCategorySuccess,
  editExpense,
  editExpenseSuccess,
  editNiuaOrMohuaSaction,
  editNiuaOrMohuaSactionSuccess,
  editgrant,
  editgrantSuccess,
  editlineItem,
  editlineItemSuccess,
  editsubCategory,
  editsubCategorySuccess,
  expenseList,
  expenseListSuccess,
  generateExpensesTemplate,
  generateExpensesTemplateSuccess,
  generateGrant,
  generateGrantSuccess,
  generatebudgetTemplate,
  generatebudgetTemplateSuccess,
  getBudgetReport,
  getBudgetReportSuccess,
  grantList,
  grantListSuccess,
  grantdetails,
  grantdetailsSuccess,
  lineItemList,
  lineItemListSuccess,
  lineItemdetails,
  lineItemdetailsSuccess,
  sanctionList,
  sanctionListSuccess,
  totalFundListSuccess,
  subcategoryList,
  subcategoryListSuccess,
  subcategorydetails,
  subcategorydetailsSuccess,
  updateSequance,
  updateSequanceSuccess,
  updatesubcategorySequance,
  updatesubcategorySequanceSuccess,
  createtotalFundSaction,
  createtotalFundSactionSuccess,
  edittotalFundSaction,
  edittotalFundSactionSuccess,
  deletetotalFundSaction,
  deletetotalFundSactionSuccess,
  createNiuaUtilization,
  createNiuaUtilizationSuccess,
  editNiuaUtilization,
  editNiuaUtilizationSuccess,
  deleteNiuaUtilization,
  deleteNiuaUtilizationSuccess,
  NiuaUtilizationList,
  NiuaUtilizationListSuccess,
  getMisDashboard,
  getMisDashboardSuccess,
  dropdownSubCategoryListUpdateSeq,
  dropdownsubCategoryListUpdateSeqSuccess
} from "./budget.action";
import { Injectable } from "@angular/core";
import { BudgetService } from "../service/budget.service";
import { Effect, Actions, ofType } from "@ngrx/effects";
import { switchMap, map, catchError } from "rxjs/operators";
import { of } from "rxjs";
import { ErrorAction } from "../../../../login/store/auth.actions";
import { NotificationsService } from "angular2-notifications";
import { AppState } from "./../../../../app.reducer";
import { Store } from "@ngrx/store";
import { LoaderHide } from "./../../../../features/loader/store/loader.actions";

@Injectable()
export class BudgetEffects {
  constructor(
    private api: BudgetService,
    private action$: Actions,
    private store: Store<AppState>,
    private notificationService: NotificationsService
  ) {}

  @Effect()
  dropdowncategoryList$ = this.action$.pipe(
    ofType(BudgetActionTypes.DROPDOWN_CATEGORY_LIST),
    switchMap((action: dropdownCategoryList) => {
      return this.api.dropDownCategoryList(action.id,action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new dropdownCategoryListSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  dropdownsucategoryList$ = this.action$.pipe(
    ofType(BudgetActionTypes.DROPDOWN_SUB_CATEGORY_LIST),
    switchMap((action: dropdownSubCategoryList) => {
      return this.api.dropDownSubCategoryList(action.obj,action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new dropdownsubCategoryListSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );


  @Effect()
  dropdownsucategoryListupdateSeq$ = this.action$.pipe(
    ofType(BudgetActionTypes.DROPDOWN_SUB_CATEGORY_LIST_UPDATE),
    switchMap((action: dropdownSubCategoryListUpdateSeq) => {
      return this.api.subcategoryList(action.payload, action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new dropdownsubCategoryListUpdateSeqSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  // Category

  @Effect()
  categoryList$ = this.action$.pipe(
    ofType(BudgetActionTypes.CATEGORY_LIST),
    switchMap((action: categoryList) => {
      return this.api.categoryList(action.payload, action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new categoryListSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  add_category$ = this.action$.pipe(
    ofType(BudgetActionTypes.ADD_CATEGORY),
    switchMap((action: createCategory) => {
      return this.api.createCategory(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new createCategorySuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  category_details$ = this.action$.pipe(
    ofType(BudgetActionTypes.CATEGORY_DETAILS),
    switchMap((action: categorydetails) => {
      return this.api.categoryDetails(action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new categorydetailsSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  edit_category$ = this.action$.pipe(
    ofType(BudgetActionTypes.EDIT_CATEGORY),
    switchMap((action: editCategory) => {
      return this.api.editCategory(action.id, action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new editCategorySuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  delete_category$ = this.action$.pipe(
    ofType(BudgetActionTypes.DELETE_CATEGORY),
    switchMap((action: deleteCategory) => {
      return this.api.deleteCategory(action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new deleteCategorySuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  // for sub category

  @Effect()
  subcategoryList$ = this.action$.pipe(
    ofType(BudgetActionTypes.SUB_CATEGORY_LIST),
    switchMap((action: subcategoryList) => {
      return this.api.subcategoryList(action.payload, action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new subcategoryListSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  add_subcategory$ = this.action$.pipe(
    ofType(BudgetActionTypes.ADD_SUB_CATEGORY),
    switchMap((action: createsubCategory) => {
      return this.api.createsubCategory(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new createsubCategorySuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  subcategory_details$ = this.action$.pipe(
    ofType(BudgetActionTypes.SUB_CATEGORY_DETAILS),
    switchMap((action: subcategorydetails) => {
      return this.api.subcategoryDetails(action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new subcategorydetailsSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  edit_subcategory$ = this.action$.pipe(
    ofType(BudgetActionTypes.EDIT_SUB_CATEGORY),
    switchMap((action: editsubCategory) => {
      return this.api.editsubCategory(action.id, action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new editsubCategorySuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  delete_subcategory$ = this.action$.pipe(
    ofType(BudgetActionTypes.DELETE_SUB_CATEGORY),
    switchMap((action: deletesubCategory) => {
      return this.api.deletesubCategory(action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new deletesubCategorySuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  check_subcategory_btn$ = this.action$.pipe(
    ofType(BudgetActionTypes.IS_CHECK_SHOW_ADD_SUBCATEGORY_BUTTON),
    switchMap((action: checkCategoryAvailable) => {
      return this.api.checkforSubCategory(action.id,action.fy).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new checkCategoryAvailableSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  // for line item

  @Effect()
  lineItemList$ = this.action$.pipe(
    ofType(BudgetActionTypes.LINE_ITEM_LIST),
    switchMap((action: lineItemList) => {
      return this.api.lineItemList(action.payload, action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new lineItemListSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  add_lineItem$ = this.action$.pipe(
    ofType(BudgetActionTypes.ADD_LINE_ITEM),
    switchMap((action: createlineItem) => {
      return this.api.createlineItem(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new createlineItemSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  lineItem_details$ = this.action$.pipe(
    ofType(BudgetActionTypes.LINE_ITEM_DETAILS),
    switchMap((action: lineItemdetails) => {
      return this.api.lineItemDetails(action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new lineItemdetailsSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  edit_lineItem$ = this.action$.pipe(
    ofType(BudgetActionTypes.EDIT_LINE_ITEM),
    switchMap((action: editlineItem) => {
      return this.api.editlineItem(action.id, action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new editlineItemSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  delete_lineItem$ = this.action$.pipe(
    ofType(BudgetActionTypes.DELETE_LINE_ITEM),
    switchMap((action: deletelineItem) => {
      return this.api.deletelineItem(action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new deletelineItemSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  check_lineItem_btn$ = this.action$.pipe(
    ofType(BudgetActionTypes.IS_CHECK_SHOW_ADD_LINEITEMS_BUTTON),
    switchMap((action: checkLineItemAvailable) => {
      return this.api.checkforLineItem(action.id,action.fy).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new checkLineItemAvailableSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  //all

  @Effect()
  allList$ = this.action$.pipe(
    ofType(BudgetActionTypes.ALL_LIST),
    switchMap((action: allList) => {
      return this.api.allList(action.payload, action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new allListSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  //update Sequnce

  @Effect()
  updateSequance$ = this.action$.pipe(
    ofType(BudgetActionTypes.UPDATE_SEQUANCE),
    switchMap((action: updateSequance) => {
      return this.api.updatecategorySequence(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new updateSequanceSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  updatesubcateSequance$ = this.action$.pipe(
    ofType(BudgetActionTypes.UPDATE_SUBCATEGORY_SEQUANCE),
    switchMap((action: updatesubcategorySequance) => {
      return this.api.updatesubcategorySequence(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new updatesubcategorySequanceSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  //For Grant

  @Effect()
  grantList$ = this.action$.pipe(
    ofType(BudgetActionTypes.GRANT_LIST),
    switchMap((action: grantList) => {
      return this.api.grantList(action.payload, action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new grantListSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  generateGrant$ = this.action$.pipe(
    ofType(BudgetActionTypes.GENERATE_GRANT),
    switchMap((action: generateGrant) => {
      return this.api.grantGenerate(action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new generateGrantSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  grant_details$ = this.action$.pipe(
    ofType(BudgetActionTypes.GRANT_DETAILS),
    switchMap((action: grantdetails) => {
      return this.api.grantDetails(action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new grantdetailsSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  edit_grant$ = this.action$.pipe(
    ofType(BudgetActionTypes.UPDATE_GRANT),
    switchMap((action: editgrant) => {
      return this.api.grantUpdate(action.id, action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new editgrantSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  delete_grant$ = this.action$.pipe(
    ofType(BudgetActionTypes.DELETE_GRANT),
    switchMap((action: deletegrant) => {
      return this.api.deleteGrant(action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new deletegrantSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  // entries - budget
  @Effect()
  checkeurexist$ = this.action$.pipe(
    ofType(BudgetActionTypes.CHECK_EUR_CONVERSION),
    switchMap((action: checkEurExisting) => {
      return this.api.checkEurExist(action.id, action.fy).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new checkEurExistingSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );
  @Effect()
  budgetTemplate$ = this.action$.pipe(
    ofType(BudgetActionTypes.GENERATE_BUDGET_TEMPLATE),
    switchMap((action: generatebudgetTemplate) => {
      return this.api.generateTemplate(action.id, action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new generatebudgetTemplateSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  edit_budget$ = this.action$.pipe(
    ofType(BudgetActionTypes.UPDATE_BUDGET),
    switchMap((action: editBudget) => {
      return this.api.updateBudget(action.id, action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new editBudgetSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  budgetList$ = this.action$.pipe(
    ofType(BudgetActionTypes.BUDGET_LIST),
    switchMap((action: budgetList) => {
      return this.api.budgetList(action.payload, action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new budgetListSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  // entries - expense
  @Effect()
  checkeurexpensesexist$ = this.action$.pipe(
    ofType(BudgetActionTypes.CHECK_EUR_CONVERSION_EXPENSES),
    switchMap((action: checkEurExistingExpenses) => {
      return this.api
        .checkEurExistExpenses(action.id, action.fy, action.month)
        .pipe(
          map((response: any) => {
            if (response) {
              this.store.dispatch(new LoaderHide());
              // this.notificationService.success("Contact List fetched successfully");
              return new checkEurExistingExpensesSuccess(response);
            }
          }),
          catchError((err) => of(new ErrorAction(err)))
        );
    })
  );
  @Effect()
  expensesTemplate$ = this.action$.pipe(
    ofType(BudgetActionTypes.GENERATE_EXPENSES_TEMPLATE),
    switchMap((action: generateExpensesTemplate) => {
      return this.api.generateExpensesTemplate(action.id, action.payload).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new generateExpensesTemplateSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );

  @Effect()
  expenseList$ = this.action$.pipe(
    ofType(BudgetActionTypes.EXPENSE_LIST),
    switchMap((action: expenseList) => {
      return this.api.expenseList(action.payload, action.id).pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new expenseListSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
    })
  );
  
@Effect()
edit_expense$ = this.action$.pipe(
  ofType(BudgetActionTypes.UPDATE_EXPENSE),
  switchMap((action: editExpense) => {
    // action.id,
    return this.api.updateExpense( action.payload,action.id).pipe(
      map((response: any) => {
        if (response) {
          this.store.dispatch(new LoaderHide());
          // this.notificationService.success("Contact List fetched successfully");
          return new editExpenseSuccess(response);
        }
      }),
      catchError((err) => of(new ErrorAction(err)))
    );
  })
);


@Effect()
getReport$ = this.action$.pipe(
  ofType(BudgetActionTypes.GET_BUDGET_REPORT),
  switchMap((action: getBudgetReport) => {
    return this.api
      .getReport(action.id, action.fy, action.month)
      .pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new getBudgetReportSuccess (response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
  })
);


@Effect()
downloadReport$ = this.action$.pipe(
  ofType(BudgetActionTypes.DOWNLOAD_REPORT),
  switchMap((action:downloadReport ) => {
    return this.api
      .monthlyChart(action.id, action.fy)
      .pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            // this.notificationService.success("Contact List fetched successfully");
            return new downloadReportSuccess (response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
  })
);



@Effect()
createSanction$ = this.action$.pipe(
  ofType(BudgetActionTypes.CREATE_GRANT_MOHUA_OR_NIUA),
  switchMap((action:createNiuaOrMohuaSaction ) => {
    return this.api
      .sactionCreate(action.payload)
      .pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            return new createNiuaOrMohuaSactionSuccess (response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
  })
);

@Effect()
editSanction$ = this.action$.pipe(
  ofType(BudgetActionTypes.UPDATE_GRANT_MOHUA_OR_NIUA),
  switchMap((action:editNiuaOrMohuaSaction ) => {
    return this.api
      .sanctionsUpdate(action.id, action.payload)
      .pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            return new editNiuaOrMohuaSactionSuccess (response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
  })
);

@Effect()
deleteSanction$ = this.action$.pipe(
  ofType(BudgetActionTypes.DELETE_GRANT_MOHUA_OR_NIUA),
  switchMap((action: deleteNiuaOrMohuaSaction) => {
    return this.api
      .sactionsDelete(action.id)
      .pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            return new deleteNiuaOrMohuaSactionSuccess (response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
  })
);

@Effect()
listSanction$ = this.action$.pipe(
  ofType(BudgetActionTypes.GET_LIST_GRANT_MOHUA_OR_NIUA),
  switchMap((action:sanctionList ) => {
    return this.api
      .sactionList(action.payload,action.projectId,action.Id)
      .pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            return new sanctionListSuccess (response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
  })
);

@Effect()
createTotalFunds$ = this.action$.pipe(
  ofType(BudgetActionTypes.CREATE_GRANT_TOTAL_FUND),
  switchMap((action:createtotalFundSaction ) => {
    return this.api
      .totalFundCreate(action.payload)
      .pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("Total Fund created successfully");
            return new createtotalFundSactionSuccess (response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
  })
);

@Effect()
editTotalFunds$ = this.action$.pipe(
  ofType(BudgetActionTypes.UPDATE_GRANT_TOTAL_FUND),
  switchMap((action:edittotalFundSaction ) => {
    return this.api
      .totalFundUpdate(action.id, action.payload)
      .pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("Total Fund updated successfully");
            return new edittotalFundSactionSuccess (response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
  })
);

@Effect()
deleteTotalFunds$ = this.action$.pipe(
  ofType(BudgetActionTypes.DELETE_GRANT_TOTAL_FUND),
  switchMap((action: deletetotalFundSaction) => {
    return this.api
      .totalFundDelete(action.id)
      .pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("Total Fund deleted successfully");
            return new deletetotalFundSactionSuccess (response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
  })
);

@Effect()
listTotalFunds$ = this.action$.pipe(
  ofType(BudgetActionTypes.GET_LIST_GRANT_TOTAL_FUND),
  switchMap((action:sanctionList ) => {
    return this.api
      .totalFundList(action.payload,action.projectId,action.Id)
      .pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            return new totalFundListSuccess (response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
  })
);



@Effect()
createNiuaUtilization$ = this.action$.pipe(
  ofType(BudgetActionTypes.CREATE_UTIL_NIUA),
  switchMap((action:createNiuaUtilization ) => {
    return this.api
      .niuaUtilizationCreate(action.payload)
      .pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("NIUA created successfully");
            return new createNiuaUtilizationSuccess (response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
  })
);

@Effect()
editNiuaUtilization$ = this.action$.pipe(
  ofType(BudgetActionTypes.UPDATE_UTIL_NIUA),
  switchMap((action:editNiuaUtilization ) => {
    return this.api
      .niuaUtilizationUpdate(action.id, action.payload)
      .pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("NIUA updated successfully");
            return new editNiuaUtilizationSuccess (response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
  })
);

@Effect()
deleteNiuaUtilization$ = this.action$.pipe(
  ofType(BudgetActionTypes.DELETE_UTIL_NIUA),
  switchMap((action: deleteNiuaUtilization) => {
    return this.api
      .niuaUtilizationDelete(action.id)
      .pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            this.notificationService.success("NIUA deleted successfully");
            return new deleteNiuaUtilizationSuccess (response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
  })
);

@Effect()
listNiuaUtilization$ = this.action$.pipe(
  ofType(BudgetActionTypes.GET_LIST_UTIL_NIUA),
  switchMap((action:NiuaUtilizationList ) => {
    return this.api
      .niuaUtilizationList(action.payload,action.projectId,action.Id)
      .pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            return new NiuaUtilizationListSuccess (response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
  })
);

@Effect()
getmisDashboard$ = this.action$.pipe(
  ofType(BudgetActionTypes.GET_MIS_DASHBOARD_DATA),
  switchMap((action: getMisDashboard) => {
    return this.api
      .getMisDashboardData(action.id,action.start_year,action.start_month,action.end_year,action.end_month)
      .pipe(
        map((response: any) => {
          if (response) {
            this.store.dispatch(new LoaderHide());
            return new getMisDashboardSuccess(response);
          }
        }),
        catchError((err) => of(new ErrorAction(err)))
      );
  })
);


}

