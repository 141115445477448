import { Action } from '@ngrx/store';

export enum MonitoringEvaluationActionTypes {
    GET_PROJECTS = '[Monitoring Evaluations]Get Projects',
    GET_PROJECTS_SUCCESS = '[Monitoring Evaluations] Get Projects Success',
    GET_PROJECT_DETAILS = '[Monitoring Evaluations] Get Project Details',
    GET_PROJECT_DETAILS_SUCCESS = '[Monitoring Evaluations] Get Project Details Success',
    GET_LIST_DETAILS = "GET_LIST_DETAILS",
    GET_LIST_DETAILS_SUCCESS = "GET_LIST_DETAILS_SUCCESS",
    GET_INDICATOR_DETAILS = "GET_INDICATOR_DETAILS",
    GET_INDICATOR_DETAILS_SUCCESS = "GET_INDICATOR_DETAILS_SUCCESS",
    ADD_NEW_VALUE = "ADD_NEW_VALUE",
    ADD_NEW_VALUE_SUCCESS = "ADD_NEW_VALUE_SUCCESS",
    INDICATOR_ACTIVITY = "INDICATOR_ACTIVITY",
    INDICATOR_ACTIVITY_SUCCESS = "INDICATOR_ACTIVITY_SUCCESS",
    INNOVATION_ACTIVITY = "INNOVATION_ACTIVITY",
    INNOVATION_ACTIVITY_SUCCESS = "INNOVATIONACTIVITY_SUCCESS",
    REMOVE_INDICATOR = "REMOVE_INDICATOR",
    REMOVE_INDICATOR_SUCCESS = "REMOVE_INDICATOR_SUCCESS",
    INDICATOR_VALUES_ACTIVITY = "INDICATOR_VALUES_ACTIVITY",
    INDICATOR_VALUES_ACTIVITY_SUCCESS = "INDICATOR_VALUES_ACTIVITY_SUCCESS",
    GET_INNOVATION_DETAILS = "GET_INNOVATION_DETAILS",
    GET_INNOVATION_DETAILS_SUCCESS = "GET_INNOVATION_DETAILS_SUCCESS",
    UPDATE_INNOVATION_MATRIX = "UPDATE_INNOVATION_MATRIX",
    UPDATE_INNOVATION_MATRIX_SUCCESS = "UPDATE_INNOVATION_MATRIX_SUCCESS",
    INDICATOR_OPERATIONS = "INDICATOR_OPERATIONS",
    INDICATOR_OPERATIONS_SUCCESS = "INDICATOR_OPERATIONS_SUCCESS",
    ROLES_AND_RESPONSIBILITES = "ROLES_AND_RESPONSIBILITES",
    ROLES_AND_RESPONSIBILITES_SUCCESS = "ROLES_AND_RESPONSIBILITES_SUCCESS",
    CREATE_ROLES_RESPONSIBILITES = "CREATE_ROLES_RESPONSIBILITES",
    CREATE_ROLES_RESPONSIBILITES_SUCCESS = "CREATE_ROLES_RESPONSIBILITES_SUCCESS",
    GET_INSTITUTIONAL_COORDINATION_SUCCESS = "GET_INSTITUTIONAL_COORDINATION_SUCCESS",
    GET_INSTITUTIONAL_COORDINATION = "GET_INSTITUTIONAL_COORDINATION",
    CREATE_INSTITUTAINAL_COORDINATION = "CREATE_INSTITUTAINAL_COORDINATION",
    CREATE_INSTITUTAINAL_COORDINATION_SUCCESS = "CREATE_INSTITUTAINAL_COORDINATION_SUCCESS",
    GET_PROJECT_DATA_MANAGEMENT = "GET_PROJECT_DATA_MANAGEMENT",
    GET_PROJECT_DATA_MANAGEMENT_SUCCESS = "GET_PROJECT_DATA_MANAGEMENT_SUCCESS",
    CREATE_PROJECT_DATA_MANAGEMENTN = "CREATE_PROJECT_DATA_MANAGEMENTN",
    CREATE_PROJECT_DATA_MANAGEMENTN_SUCCESS = "CREATE_PROJECT_DATA_MANAGEMENTN_SUCCESS",
    GET_SINGLE_INSTITUTANAL_COORDINATION = "GET_SINGLE_INSTITUTANAL_COORDINATION",
    GET_SINGLE_INSTITUTANAL_COORDINATION_SUCCESS = "GET_SINGLE_INSTITUTANAL_COORDINATION_SUCCESS",
    GET_PHYSICAL_PROGRESS = "GET_PHYSICAL_PROGRESS",
    GET_PHYSICAL_PROGRESS_SUCCESS = "GET_PHYSICAL_PROGRESS_SUCCESS",
    CREATE_PHYSICAL_PROGRESS = "CREATE_PHYSICAL_PROGRESS",
    CREATE_PHYSICAL_PROGRESS_SUCCESS = "CREATE_PHYSICAL_PROGRESS_SUCCESS",
    GET_FINANCIAL_PROGRESS = "GET_FINANCIAL_PROGRESS",
    GET_FINANCIAL_PROGRESS_SUCCESS = "GET_FINANCIAL_PROGRESS_SUCCESS",
    CREATE_FINANCAIL_PROGRESS = "CREATE_FINANCAIL_PROGRESS",
    CREATE_FINANCAIL_PROGRESS_SUCCESS = "CREATE_FINANCAIL_PROGRESS_SUCCESS",
    GET_SINGLE_FINANCIAL_PROGRESS = "GET_SINGLE_FINANCIAL_PROGRESS",
    GET_SINGLE_FINANCIAL_PROGRESS_SUCCESS = "GET_SINGLE_FINANCIAL_PROGRESS_SUCCESS",
    GET_SINGLE_PHYSICAL_PROGRESS = "GET_SINGLE_PHYSICAL_PROGRESS",
    GET_SINGLE_PHYSICAL_PROGRESS_SUCCESS = "GET_SINGLE_PHYSICAL_PROGRESS_SUCCESS",
    GET_MAJOR_EVENT_INDICATORS = "GET_MAJOR_EVENT_INDICATORS",
    GET_MAJOR_EVENT_INDICATORS_SUCCESS = "GET_MAJOR_EVENT_INDICATORS_SUCCESS",
    GET_QUARTERS = "GET_QUARTERS",
    GET_QUARTERS_SUCCESS = "GET_QUARTERS_SUCCESS",
    GET_MONITORING_TYPES = "GET_MONITORING_TYPES",
    GET_MONITORING_TYPES_SUCCESS = "GET_MONITORING_TYPES_SUCCESS",
    GET_CIRCUMSTANCES = "GET_CIRCUMSTANCES",
    GET_CIRCUMSTANCES_SUCCESS = "GET_CIRCUMSTANCES_SUCCESS",
    CREATE_CIRCUMSTANCES = "CREATE_CIRCUMSTANCES",
    CREATE_CIRCUMSTANCES_SUCCESS = "CREATE_CIRCUMSTANCES_SUCCESS",
    CREATE_SINGLE_CIRCUMSTANCES = "CREATE_SINGLE_CIRCUMSTANCES",
    CREATE_SINGLE_CIRCUMSTANCES_SUCCESS = "CREATE_SINGLE_CIRCUMSTANCES_SUCCESS",
    GET_SINGLE_CIRCUMSTANCES = "GET_SINGLE_CIRCUMSTANCES",
    GET_SINGLE_CIRCUMSTANCES_SUCCESS = "GET_SINGLE_CIRCUMSTANCES_SUCCESS",
    GET_CAPACITY_BUILDING = "GET_CAPACITY_BUILDING",
    GET_CAPACITY_BUILDING_SUCCESS = "GET_CAPACITY_BUILDING_SUCCESS",
    GET_CAPACITY_BUILDING_DETAIL = "GET_CAPACITY_BUILDING_DETAIL",
    GET_CAPACITY_BUILDING_DETAIL_SUCCESS = "GET_CAPACITY_BUILDING_DETAIL_SUCCESS",
    CREATE_CAPACITY_BUILDING = "CREATE_CAPACITY_BUILDING",
    CREATE_CAPACITY_BUILDING_SUCCESS = "CREATE_CAPACITY_BUILDING_SUCCESS",
    EDIT_CAPACITY_BUILDING = "EDIT_CAPACITY_BUILDING",
    EDIT_CAPACITY_BUILDING_SUCCESS = "EDIT_CAPACITY_BUILDING_SUCCESS",
    GET_PEER_EXCHANGE = "GET_PEER_EXCHANGE",
    GET_PEER_EXCHANGE_SUCCESS = "GET_PEER_EXCHANGE_SUCCESS",
    GET_PEER_EXCHANGE_DETAIL = "GET_PEER_EXCHANGE_DETAIL",
    GET_PEER_EXCHANGE_DETAIL_SUCCESS = "GET_PEER_EXCHANGE_DETAIL_SUCCESS",
    CREATE_PEER_EXCHANGE = "CREATE_PEER_EXCHANGE",
    CREATE_PEER_EXCHANGE_SUCCESS = "CREATE_PEER_EXCHANGE_SUCCESS",
    EDIT_PEER_EXCHANGE = "EDIT_PEER_EXCHANGE",
    EDIT_PEER_EXCHANGE_SUCCESS = "EDIT_PEER_EXCHANGE_SUCCESS",
    GET_COMMUNICATION_OUTREACH = "GET_COMMUNICATION_OUTREACH",
    GET_COMMUNICATION_OUTREACH_SUCCESS = "GET_COMMUNICATION_OUTREACH_SUCCESS",
    GET_COMMUNICATION_OUTREACH_DETAIL = "GET_COMMUNICATION_OUTREACH_DETAIL",
    GET_COMMUNICATION_OUTREACH_DETAIL_SUCCESS = "GET_COMMUNICATION_OUTREACH_DETAIL_SUCCESS",
    CREATE_COMMUNICATION_OUTREACH = "CREATE_COMMUNICATION_OUTREACH",
    CREATE_COMMUNICATION_OUTREACH_SUCCESS = "CREATE_COMMUNICATION_OUTREACH_SUCCESS",
    EDIT_COMMUNICATION_OUTREACH = "EDIT_COMMUNICATION_OUTREACH",
    EDIT_COMMUNICATION_OUTREACH_SUCCESS = "EDIT_COMMUNICATION_OUTREACH_SUCCESS",
    GET_QUESTIONS_JSON = "GET_QUESTIONS_JSON",
    GET_QUESTIONS_JSON_SUCCESS = "GET_QUESTIONS_JSON_SUCCESS",
    GET_SINGLE_PROJECT_DATA_MANAGEMENT = "GET_SINGLE_PROJECT_DATA_MANAGEMENT",
    GET_SINGLE_PROJECT_DATA_MANAGEMENT_SUCCESS = "GET_SINGLE_PROJECT_DATA_MANAGEMENT_SUCCESS",
    SEND_APPROVAL = "SEND_APPROVAL",
    SEND_APPROVAL_SUCCESS = "SEND_APPROVAL_SUCCESS",
    GET_SINGLE_ROLE_RESP = "GET_SINGLE_ROLE_RESP",
    GET_SINGLE_ROLE_RESP_SUCCESS = "GET_SINGLE_ROLE_RESP_SUCCESS",
    UPDATE_OTHER_OBSERVATIONS = "UPDATE_OTHER_OBSERVATIONS",
    UPDATE_OTHER_OBSERVATIONS_SUCCESS = "UPDATE_OTHER_OBSERVATIONS_SUCCESS",
    GET_OTHER_OBSERVATIONS = "GET_OTHER_OBSERVATIONS",
    GET_OTHER_OBSERVATIONS_SUCCESS = "GET_OTHER_OBSERVATIONS_SUCCESS",
    UPDATE_MAJOR_EVENTS = "UPDATE_MAJOR_EVENTS",
    UPDATE_MAJOR_EVENTS_SUCCESS = "UPDATE_MAJOR_EVENTS_SUCCESS",
    APPROVE_SUCCESS = "APPROVE_SUCCESS",
    APPROVE = "APPROVE",
    REOPEN = "REOPEN",
    REOPEN_SUCCESS = "REOPEN_SUCCESS",
    GET_OVER_VIEW_PLF_ONE = "GET_OVER_VIEW_PLF_ONE",
    GET_OVER_VIEW_PLF_ONE_SUCCESS = "GET_OVER_VIEW_PLF_ONE_SUCCESS",
    GET_OVER_VIEW_PLF_TWO = "GET_OVER_VIEW_PLF_TWO",
    GET_OVER_VIEW_PLF_TWO_SUCCESS = "GET_OVER_VIEW_PLF_TWO_SUCCESS",
    GET_OVER_VIEW_DETAILS = "GET_OVER_VIEW_DETAILS",
    GET_OVER_VIEW_DETAILS_SUCCESS = "GET_OVER_VIEW_DETAILS_SUCCESS",
    EDIT_CIRCUMSTANCES = "EDIT_CIRCUMSTANCES",
    EDIT_CIRCUMSTANCES_SUCCESS = "EDIT_CIRCUMSTANCES_SUCCESS",
    CREATE_INDICATOR = "CREATE_INDICATOR",
    CREATE_INDICATOR_SUCCESS = "CREATE_INDICATOR_SUCCESS",
    EDIT_INDICATOR = "EDIT_INDICATOR",
    EDIT_INDICATOR_SUCCESS = "EDIT_INDICATOR_SUCCESS",
    SUGGEST_INDICATOR = "SUGGEST_INDICATOR",
    SUGGEST_INDICATOR_SUCCESS = "SUGGEST_INDICATOR_SUCCESS",
    BULK_INDICATOR = "BULK_INDICATOR",
    BULK_INDICATOR_SUCCESS = "BULK_INDICATOR_SUCCESS",
    GET_BI_ANNUAL_OVER_VIEW_DETAILS = "GET_BI_ANNUAL_OVER_VIEW_DETAILS",
    GET_BI_ANNUAL_OVER_VIEW_DETAILS_SUCCESS = "GET_BI_ANNUAL_OVER_VIEW_DETAILS_SUCCESS"
}

export class GetProjects implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_PROJECTS;
    constructor(public params: any) { }
}

export class GetProjectsSucess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_PROJECTS_SUCCESS;
    constructor(public response: any) { }
}

export class GetProjectDetail implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_PROJECT_DETAILS;
    constructor(public id: any) { }
}

export class GetProjectDetailSucess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_PROJECT_DETAILS_SUCCESS;
    constructor(public response: any) { }
} 

export class GetListDetails implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_LIST_DETAILS;
    constructor(public list_type: any,public payload: any) { }
}

export class GetListDetailsSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_LIST_DETAILS_SUCCESS;
    constructor(public response: any) { }
} 

export class GetIndicatorDetails implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_INDICATOR_DETAILS;
    constructor(public indicator_type: any,public id: any) { }
}

export class GetIndicatorDetailsSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_INDICATOR_DETAILS_SUCCESS;
    constructor(public response: any) { }
} 

export class AddNewValue implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.ADD_NEW_VALUE;
    constructor(public payload: any) { }
}

export class AddNewValueSucess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.ADD_NEW_VALUE_SUCCESS;
    constructor(public response: any) { }
}

export class GetIndicatorActivityLog implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.INDICATOR_ACTIVITY;
    constructor(public id: any) { }
}

export class GetIndicatorActivityLogSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.INDICATOR_ACTIVITY_SUCCESS;
    constructor(public response: any) { }
} 

export class GetInnovationActivityLog implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.INNOVATION_ACTIVITY;
    constructor(public projId: any) { }
}

export class GetInnovationActivityLogSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.INNOVATION_ACTIVITY_SUCCESS;
    constructor(public response: any) { }
} 

export class GetIndicatorValuesActivityLog implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.INDICATOR_VALUES_ACTIVITY;
    constructor(public projId: any,public id: any) { }
}

export class GetIndicatorValuesActivityLogSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.INDICATOR_VALUES_ACTIVITY_SUCCESS;
    constructor(public response: any) { }
}

export class RemoveIndicator implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.REMOVE_INDICATOR;
    constructor(public payload: any,public id:any) { }
}

export class RemoveIndicatorSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.REMOVE_INDICATOR_SUCCESS;
    constructor(public response: any) { }
} 

export class GetInnovationDetails implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_INNOVATION_DETAILS;
    constructor(public payload: any) { }
}

export class GetInnovationDetailsSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_INNOVATION_DETAILS_SUCCESS;
    constructor(public response: any) { }
}

export class UpdateInnovationMatrix implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.UPDATE_INNOVATION_MATRIX;
    constructor(public payload: any,public id:any,public quarter:any) { }
}

export class UpdateInnovationMatrixSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.UPDATE_INNOVATION_MATRIX_SUCCESS;
    constructor(public response: any) { }
} 


export class IndicatorOperations implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.INDICATOR_OPERATIONS;
    constructor(public operation_type: any,public id:any) { }
}

export class IndicatorOperationsSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.INDICATOR_OPERATIONS_SUCCESS;
    constructor(public response: any,public id:any) { }
}

export class RolesAndResponsibilites implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.ROLES_AND_RESPONSIBILITES;
    constructor(public payload: any) { }
}

export class RolesAndResponsibilitesSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.ROLES_AND_RESPONSIBILITES_SUCCESS;
    constructor(public response: any) { }
}

export class CreateRolesAndResponsibilites implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.CREATE_ROLES_RESPONSIBILITES;
    constructor(public payload: any,public isEdit: boolean) { }
}

export class CreateRolesAndResponsibilitesSucess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.CREATE_ROLES_RESPONSIBILITES_SUCCESS;
    constructor(public response: any) { }
}

export class GetInstitutionalCoordination implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_INSTITUTIONAL_COORDINATION;
    constructor(public payload: any) { }
}

export class GetInstitutionalCoordinationSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_INSTITUTIONAL_COORDINATION_SUCCESS;
    constructor(public response: any) { }
}

export class CreateInstitutionalCoordination implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.CREATE_INSTITUTAINAL_COORDINATION;
    constructor(public payload: any,public isEdit: boolean) { }
}

export class CreateInstitutionalCoordinationSucess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.CREATE_INSTITUTAINAL_COORDINATION_SUCCESS;
    constructor(public response: any) { }
}

export class GetProjectDataManagement implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_PROJECT_DATA_MANAGEMENT;
    constructor(public payload: any) { }
}

export class GetProjectDataManagementSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_PROJECT_DATA_MANAGEMENT_SUCCESS;
    constructor(public response: any) { }
}

export class CreateProjectDataManagement implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.CREATE_PROJECT_DATA_MANAGEMENTN;
    constructor(public payload: any) { }
}

export class CreateProjectDataManagementSucess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.CREATE_PROJECT_DATA_MANAGEMENTN_SUCCESS;
    constructor(public response: any) { }
}

export class GetSingleProjectDataManagement implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_SINGLE_PROJECT_DATA_MANAGEMENT;
    constructor(public id: any) { }
}

export class GetSingleProjectDataManagementSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_SINGLE_PROJECT_DATA_MANAGEMENT_SUCCESS;
    constructor(public response: any) { }
} 

export class GetSingleInstitutionalCoordination implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_SINGLE_INSTITUTANAL_COORDINATION;
    constructor(public id: any) { }
}

export class GetSingleInstitutionalCoordinationSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_SINGLE_INSTITUTANAL_COORDINATION_SUCCESS;
    constructor(public response: any) { }
} 

export class GetPhysicalProgress implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_PHYSICAL_PROGRESS;
    constructor(public payload: any) { }
}

export class GetPhysicalProgressSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_PHYSICAL_PROGRESS_SUCCESS;
    constructor(public response: any) { }
}

export class CreatePhysicalProgress implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.CREATE_PHYSICAL_PROGRESS;
    constructor(public payload: any,public isEdit: any) { }
}

export class CreatePhysicalProgressSucess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.CREATE_PHYSICAL_PROGRESS_SUCCESS;
    constructor(public response: any) { }
} 

export class GetFinancialProgress implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_FINANCIAL_PROGRESS;
    constructor(public payload: any) { }
}

export class GetFinancialProgressSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_FINANCIAL_PROGRESS_SUCCESS;
    constructor(public response: any) { }
}

export class CreateFinancialProgress implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.CREATE_FINANCAIL_PROGRESS;
    constructor(public payload: any,public isEdit: boolean) { }
}

export class CreateFinancialProgressSucess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.CREATE_FINANCAIL_PROGRESS_SUCCESS;
    constructor(public response: any) { }
}

export class GetSingleFinancialProgress implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_SINGLE_FINANCIAL_PROGRESS;
    constructor(public id: any) { }
}

export class GetSingleFinancialProgressSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_SINGLE_FINANCIAL_PROGRESS_SUCCESS;
    constructor(public response: any) { }
} 

export class GetSinglePhysicalProgress implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_SINGLE_PHYSICAL_PROGRESS;
    constructor(public id: any) { }
}

export class GetSinglePhysicalProgressSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_SINGLE_PHYSICAL_PROGRESS_SUCCESS;
    constructor(public response: any) { }
}

export class GetMajorEventsIndicators implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_MAJOR_EVENT_INDICATORS;
    constructor(public id: any) { }
}

export class GetMajorEventsIndicatorsSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_MAJOR_EVENT_INDICATORS_SUCCESS;
    constructor(public response: any) { }
}

export class GetQuarters implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_QUARTERS;
    constructor(public payload: any = 'quarter') { }
}

export class GetQuartersSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_QUARTERS_SUCCESS;
    constructor(public response: any,public quarter_type: any) { }
} 

export class GetMonitoringTypes implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_MONITORING_TYPES;
    constructor(public types: any) { }
}

export class GetMonitoringTypesSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_MONITORING_TYPES_SUCCESS;
    constructor(public response: any) { }
} 

export class GetCircumstancesActions implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_CIRCUMSTANCES;
    constructor(public payload: any) { }
}

export class GetCircumstancesActionsSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_CIRCUMSTANCES_SUCCESS;
    constructor(public response: any) { }
}

export class CreateCircumstances implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.CREATE_CIRCUMSTANCES;
    constructor(public payload: any) { }
}

export class CreateCircumstancesSucess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.CREATE_CIRCUMSTANCES_SUCCESS;
    constructor(public response: any) { }
}

export class EditCircumstances implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.EDIT_CIRCUMSTANCES;
    constructor(public payload: any) { }
}

export class EditCircumstancesSucess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.EDIT_CIRCUMSTANCES_SUCCESS;
    constructor(public response: any) { }
}

export class GetSingleCircumstances implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_SINGLE_CIRCUMSTANCES;
    constructor(public id: any) { }
}

export class GetSingleCircumstancesSucess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_SINGLE_CIRCUMSTANCES_SUCCESS;
    constructor(public response: any) { }
}




export class GetCapacityBuilding implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_CAPACITY_BUILDING;
    constructor(public payload:any) { }
}

export class GetCapacityBuildingSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_CAPACITY_BUILDING_SUCCESS;
    constructor(public response: any) { }
}

export class GetCapacityBuildingDetail implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_CAPACITY_BUILDING_DETAIL;
    constructor(public id:any) { }
}

export class GetCapacityBuildingDetailSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_CAPACITY_BUILDING_DETAIL_SUCCESS;
    constructor(public response: any) { }
}

export class CreateCapacityBuilding implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.CREATE_CAPACITY_BUILDING;
    constructor(public payload: any) { }
}

export class CreateCapacityBuildingSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.CREATE_CAPACITY_BUILDING_SUCCESS;
    constructor(public response: any) { }
}

export class EditCapacityBuilding implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.EDIT_CAPACITY_BUILDING;
    constructor(public payload: any) { }
}

export class EditCapacityBuildingSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.EDIT_CAPACITY_BUILDING_SUCCESS;
    constructor(public response: any) { }
}

export class GetPeerExchange implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_PEER_EXCHANGE;
    constructor(public payload:any) { }
}

export class GetPeerExchangeSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_PEER_EXCHANGE_SUCCESS;
    constructor(public response: any) { }
}

export class GetPeerExchangeDetail implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_PEER_EXCHANGE_DETAIL;
    constructor(public id:any) { }
}

export class GetPeerExchangeDetailSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_PEER_EXCHANGE_DETAIL_SUCCESS;
    constructor(public response: any) { }
}

export class CreatePeerExchange implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.CREATE_PEER_EXCHANGE;
    constructor(public payload: any) { }
}

export class CreatePeerExchangeSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.CREATE_PEER_EXCHANGE_SUCCESS;
    constructor(public response: any) { }
}

export class EditPeerExchange implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.EDIT_PEER_EXCHANGE;
    constructor(public payload: any) { }
}

export class EditPeerExchangeSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.EDIT_PEER_EXCHANGE_SUCCESS;
    constructor(public response: any) { }
}

export class GetCommunicationOutreach implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_COMMUNICATION_OUTREACH;
    constructor(public payload:any) { }
}

export class GetCommunicationOutreachSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_COMMUNICATION_OUTREACH_SUCCESS;
    constructor(public response: any) { }
}

export class GetCommunicationOutreachDetail implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_COMMUNICATION_OUTREACH_DETAIL;
    constructor(public id:any) { }
}

export class GetCommunicationOutreachDetailSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_COMMUNICATION_OUTREACH_DETAIL_SUCCESS;
    constructor(public response: any) { }
}

export class CreateCommunicationOutreach implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.CREATE_COMMUNICATION_OUTREACH;
    constructor(public payload: any) { }
}

export class CreateCommunicationOutreachSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.CREATE_COMMUNICATION_OUTREACH_SUCCESS;
    constructor(public response: any) { }
}

export class EditCommunicationOutreach implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.EDIT_COMMUNICATION_OUTREACH;
    constructor(public payload: any) { }
}

export class EditCommunicationOutreachSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.EDIT_COMMUNICATION_OUTREACH_SUCCESS;
    constructor(public response: any) { }
}

export class GetQuestionsJson implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_QUESTIONS_JSON;
    constructor() { }
}

export class GetQuestionsJsonSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_QUESTIONS_JSON_SUCCESS;
    constructor(public response: any) { }
}

export class SendApproval implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.SEND_APPROVAL;
    constructor(public payload: any) { }
}

export class SendApprovalSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.SEND_APPROVAL_SUCCESS;
    constructor(public response: any) { }
}

export class GetSingleRoleandResp implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_SINGLE_ROLE_RESP;
    constructor(public id: any) { }
}

export class GetSingleRoleandRespSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_SINGLE_ROLE_RESP_SUCCESS;
    constructor(public response: any) { }
}

export class UpdateOtherObservations implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.UPDATE_OTHER_OBSERVATIONS;
    constructor(public payload: any) { }
}

export class UpdateOtherObservationsSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.UPDATE_OTHER_OBSERVATIONS_SUCCESS;
    constructor(public response: any) { }
}

export class GetOtherObservations implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_OTHER_OBSERVATIONS;
    constructor(public payload: any) { }
}

export class GetOtherObservationsSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_OTHER_OBSERVATIONS_SUCCESS;
    constructor(public response: any) { }
}
export class UpdateMajorEvents implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.UPDATE_MAJOR_EVENTS;
    constructor(public payload: any) { }
}

export class UpdateMajorEventsSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.UPDATE_MAJOR_EVENTS_SUCCESS;
    constructor(public response: any) { }
}

export class Approve implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.APPROVE;
    constructor(public payload: any,public isApproved: any = true) { }
}

export class ApproveSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.APPROVE_SUCCESS;
    constructor(public response: any) { }
}

export class ReopenTable implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.REOPEN;
    constructor(public payload: any) { }
}

export class ReopenTableSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.REOPEN_SUCCESS;
    constructor(public response: any) { }
} 

export class GetOverViewPLFOne implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_OVER_VIEW_PLF_ONE;
    constructor(public id: any) { }
}

export class GetOverViewPLFOneSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_OVER_VIEW_PLF_ONE_SUCCESS;
    constructor(public response: any) { }
}

export class GetOverViewPLFTwo implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_OVER_VIEW_PLF_TWO;
    constructor(public id: any) { }
}

export class GetOverViewPLFTwoSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_OVER_VIEW_PLF_TWO_SUCCESS;
    constructor(public response: any) { }
}

export class GetOverViewDetails implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_OVER_VIEW_DETAILS;
    constructor(public payload: any) { }
}

export class GetOverViewDetailsSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_OVER_VIEW_DETAILS_SUCCESS;
    constructor(public response: any) { }
}

export class CreateIndicator implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.CREATE_INDICATOR;
    constructor(public payload: any,public projectId: any) { }
}

export class CreateIndicatorSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.CREATE_INDICATOR_SUCCESS;
    constructor(public response: any) { }
}

export class EditIndicator implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.EDIT_INDICATOR;
    constructor(public payload: any,public id: any,public projectId: any) { }
}

export class EditIndicatorSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.EDIT_INDICATOR_SUCCESS;
    constructor(public response: any) { }
}

export class SuggestIndicator implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.SUGGEST_INDICATOR;
    constructor(public payload: any,public projectId: any) { }
}

export class SuggestIndicatorSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.SUGGEST_INDICATOR_SUCCESS;
    constructor(public response: any) { }
}

export class BlukIndicator implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.BULK_INDICATOR;
    constructor(public payload: any,public projectId: any) { }
}

export class BlukIndicatorSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.BULK_INDICATOR_SUCCESS;
    constructor(public response: any) { }
}

export class GetBiAnnualOverViewDetails implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_BI_ANNUAL_OVER_VIEW_DETAILS;
    constructor(public payload: any) { }
}

export class GetBiAnnualOverViewDetailsSuccess implements Action {
    readonly type: string = MonitoringEvaluationActionTypes.GET_BI_ANNUAL_OVER_VIEW_DETAILS_SUCCESS;
    constructor(public response: any) { }
}

