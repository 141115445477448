import { Action } from '@ngrx/store';
import { TagsActionTypes, GetTagsSuccess, MergeTagsSuccess, GetTagsActivityLogSuccess } from './tags.action';

export interface Tags {
    tags: {};
    mergeTagsRes: {},
    tagsLogs: Array<any>
}

const intiState = {
    tags: {},
    mergeTagsRes: {},
    tagsLogs:[]
};

export function reducer(state: Tags = intiState, action: Action): Tags {
    switch (action.type) {
        case TagsActionTypes.GET_TAGS_SUCCESS:
            return Object.assign({}, state, { tags: (action as GetTagsSuccess).tags });
        case TagsActionTypes.MERGE_TAGS_SUCCESS:
            return Object.assign({}, state, {mergeTagsRes: (action as MergeTagsSuccess).response});
        case TagsActionTypes.GET_TAGS_ACTIVITY_LOGS_SUCCESS:
            return Object.assign({}, state, { tagsLogs: (action as GetTagsActivityLogSuccess).tagsLogs });
        default:
            return state;
    }
}