import { Injectable } from '@angular/core';

@Injectable()
export class CommonService {
    //for added tag selector in maintaining boolean

    componentForAddingTag: string ='';
    shareDocsInMessage: boolean =false;
  notificationShownOnceForCreateGroup: boolean =false;

}