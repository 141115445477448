import { Injectable } from '@angular/core';
import { Observable} from 'rxjs';
import * as Rx from 'rxjs';
import * as io from 'socket.io-client';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { GetRegisterUserStartAction, GetOtp, RegisterLinkValidate, Logout } from 'src/app/login/store/auth.actions';
import { environment } from '../../environments/environment';

// export const SOCKET_URL = location.host;


@Injectable()
export class SocketService {

  observable: Observable<string>;
  socket;
  accessToken;
  SOCKET_URL;

  constructor(
    private _store: Store<AppState>,
  ) {
    this.SOCKET_URL = environment.production ? location.host : environment.sockethost;
    _store.select(state => state.auth).subscribe(auth => {
        this.accessToken = auth.accessToken;
        if (this.accessToken) {
          this.socket = io(this.SOCKET_URL, { query: { access_token: this.accessToken, transport: 'websocket' }});
        }

    });
   }
   
   getData(): Observable<string> {
    return this.observable = new Observable((observer) => 
      this.socket.on('notificationCount', (data) => observer.next(data))
    );
  }

  getUnreadMessages(): Observable<string> {
    return this.observable = new Observable((observer) => 
      this.socket.on('inboxCount', (data) => observer.next(data))
    );
  }

  // This one is for send data from angular to node 
  // pushData(e) {
  //   this.socket.emit('hello', e);
  // }
}
