import { Action } from '@ngrx/store';
import { UserManagementActionTypes, GetUserSuccess, GetRolesSuccess, GetProjectCodesSuccess, GetUserDetailsSuccess, GetUserImageSuccess, UserProfileLogSuccess, UserLoginLogSuccess, ChangePasswordSuccess, UserChangeEmail, UserChangeEmailSuccess, UserChangePhoneSuccess, InviteBulkUserSuccess, ChangeUserStateSuccess } from './user-managment.actions';

export interface UserManagement {
    users: {};
    userDetails: object;
    userImage: any
    roles: any
    projectCodes: Array<any>;
    userProfileLogs: Array<any>;
    userLoginLogs: any;
    changePassRes: any;
    userChangeEmailRes: any;
    userChangePhoneRes: any;
    inviteBulkUserRes: any;
    changeUserStateRes: any;
}

const intiState = {
    users: {},
    userDetails: {},
    userImage: '',
    roles: {},
    projectCodes: [],
    userProfileLogs: [],
    userLoginLogs: {},
    changePassRes: {},
    userChangeEmailRes: {},
    userChangePhoneRes: {},
    inviteBulkUserRes: {},
    changeUserStateRes: {}
};

export function reducer(state: UserManagement = intiState, action: Action): UserManagement {
    switch (action.type) {
        case UserManagementActionTypes.GET_USERS_SUCCESS:
            return Object.assign({}, state, { users: (action as GetUserSuccess).users });
        case UserManagementActionTypes.GET_USER_DETAILS_SUCCESS:
            return Object.assign({}, state, { userDetails: (action as GetUserDetailsSuccess).userDetails });
        case UserManagementActionTypes.GET_USER_IMAGE_SUCCESS:
            return Object.assign({}, state, { userImage: (action as GetUserImageSuccess).userImage })
        case UserManagementActionTypes.GET_ROLES_SUCCESS:
            return Object.assign({}, state, { roles: (action as GetRolesSuccess).roles });
        case UserManagementActionTypes.GET_PROJECT_CODES_SUCCESS:
            return Object.assign({}, state, { projectCodes: (action as GetProjectCodesSuccess).projectCodes });
        case UserManagementActionTypes.USER_PROFILE_LOG_SUCCESS:
            return Object.assign({}, state, { userProfileLogs: (action as UserProfileLogSuccess).userProfileLogs });
        case UserManagementActionTypes.USER_LOGIN_LOG_SUCCESS:
            return Object.assign({}, state, { userLoginLogs: (action as UserLoginLogSuccess).userLoginLogs });
        case UserManagementActionTypes.CHANGE_PASSWORD_SUCCESS:
            return Object.assign({}, state, { changePassRes: (action as ChangePasswordSuccess).response });
        case UserManagementActionTypes.CLEAR_ACTION:
            return Object.assign({}, state, {
                changePassRes: {},
                userChangeEmailRes: {},
                userChangePhoneRes: {},
                inviteBulkUserRes: {},
                changeUserStateRes: {},
                roles: {}
            })
        case UserManagementActionTypes.USER_CHANGE_EMAIL_SUCCESS:
            return Object.assign({}, state, { userChangeEmailRes: (action as UserChangeEmailSuccess).response });
        case UserManagementActionTypes.USER_CHANGE_PHONE_SUCCESS:
            return Object.assign({}, state, { userChangePhoneRes: (action as UserChangePhoneSuccess).response });
        case UserManagementActionTypes.INVITE_BULK_USER_SUCCESS:
            return Object.assign({}, state, { inviteBulkUserRes: (action as InviteBulkUserSuccess).response });
        case UserManagementActionTypes.CHANGE_USER_STATE_SUCCESS:
            return Object.assign({}, state, { changeUserStateRes: (action as ChangeUserStateSuccess).response });
        default:
            return state;
    }
}
