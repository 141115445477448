import { Action } from '@ngrx/store';
import { TemplateActionTypes, TemplateSuccess, GetTemplateByIdSuccess, TemplateEditSuccess, GetReplacementPatternsSuccess, GetReplacementPatternDetailsSuccess, TestEmailTemplateSuccess } from './templates.actions';

export interface Template {
    templates: any;
    template: object;
    templateMsg: string;
    replacementPatternsList: Array<any>;
    replacementPatternDetails: object;
    testResponse: any
}

const intiState = {
    templates: [],
    template: {},
    templateMsg: '',
    replacementPatternsList: [],
    replacementPatternDetails: {},
    testResponse: ''
};

export function reducer(state: Template = intiState, action: Action): Template {
    switch (action.type) {
        case TemplateActionTypes.GET_TEMPLATES_SUCCESS:
            return Object.assign({}, state, { templates: (action as TemplateSuccess).templates });
        case TemplateActionTypes.GET_TEMPLATE_BY_ID_SUCCESS:
            return Object.assign({}, state, { template: (action as GetTemplateByIdSuccess).template });
        case TemplateActionTypes.EDIT_TEMPLATE_SUCCESS:
            return Object.assign({}, state, { templateMsg: (action as TemplateEditSuccess).templateObj })
        case TemplateActionTypes.GET_REPLACEMENT_PATTERNS_SUCCESS:
            return Object.assign({}, state, { replacementPatternsList: (action as GetReplacementPatternsSuccess).response });
        case TemplateActionTypes.GET_REPLACEMENT_PATTERN_DETAILS_SUCCESS:
            return Object.assign({}, state, { replacementPatternDetails: (action as GetReplacementPatternDetailsSuccess).response });
        case TemplateActionTypes.TEST_EMAIL_TEMPLATE_SUCCESS:
            return Object.assign({}, state, { testResponse: (action as TestEmailTemplateSuccess).response })
        default:
            return state;
    }
}
