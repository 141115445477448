import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { ReactiveFormsModule, FormsModule } from "@angular/forms";
import { RouterModule } from "@angular/router";
import { NgxPaginationModule } from "ngx-pagination";
import { NgxPermissionsModule } from "ngx-permissions";
import { EntityManagementRoutingModule } from "./entity-management-routing.module";
import { CreateEntityComponent } from "./create-entity/create-entity.component";
import { EntityListComponent } from "./entity-list/entity-list.component";
import {
  MAT_DECLARATIONS,
  MAT_PROVIDERS,
} from "src/app/shared/material.module";
import { SharedModule } from "src/app/shared/shared.module";
import { StoreModule } from "@ngrx/store";

@NgModule({
  declarations: [CreateEntityComponent, EntityListComponent],
  imports: [
    CommonModule,
    EntityManagementRoutingModule,
    SharedModule,
    ...MAT_DECLARATIONS,
    ReactiveFormsModule,
    FormsModule,
    RouterModule,
    NgxPaginationModule,
    NgxPermissionsModule,
    StoreModule,
  ],
  providers: [...MAT_PROVIDERS],
})
export class EntityManagementModule {}
1