import { Action } from "@ngrx/store";

export enum ResourcesActionTypes {
    GET_ALL_DOCUMENTS = "[Resources] Get All Documents",
    GET_ALL_DOCUMENTS_SUCCESS = "[Resources] Get All Documents Sucess",
    GET_MY_DOCUMENTS = "[Resources] Get My Documents",
    GET_MY_DOCUMENTS_SUCCESS = "[Resources] Get My Documents Sucess",
    GET_THEMES = "[Resources] Get Themes",
    GET_THEMES_SUCCESS = "[Resources] Get Themes Success",
    ADD_DOCUMENT = "[Resources] Add New Document",
    GET_AS_OF_VERSION = "[Resources] Get As Of Version",
    GET_AS_OF_VERSION_SUCCESS = "[Resources] Get As Of Version Success",
    GET_TAG_LIST = "[Resources] Get Tags",
    GET_TAG_LIST_SUCCESS = "[Resources] Get Tags Success",
    GET_DOCUMENT_BY_ID = "[Resources] Get Document By Id",
    SUBMIT_FOR_APPROVAL = "[Resources]Submit for Approval",
    GET_APPROVAL_START = "[Resources] Get Approival Data Start",
    GET_APPROVAL_DATA_SUCCESS = "[Resources] Get Approival Data Success",
    GET_DOCUMENT_SUCCESS = "[Resources] Get Document Success",
    GET_DOCUMENT_VERSION_DETAILS = "[Resources] Get Document Version Details",
    GET_APPROVAL_DETAILS_START = "[Resources] Get Approival Details Start",
    GET_APPROVAL_DETAILS_SUCCESS = "[Resources] Get Approival Details Success",
    DOWNLOAD_DOCUMENT = "[Resources] Download Document",
    UPDATE_DOCUMENT = "[Resources] Update Document",
    ADD_NEW_VERSION = "[Resources] Add New Document Version",
    PUBLISH_DOCUMENT = "[Resources] Publish document",
    REJECT_DOCUMENT = "[Resources] Reject document",
    ADD_COLLOBORATORS = "[Resources] Add Collaborator",
    ADD_VIEWERS = "[Resources] Add Viewers",
    GET_COLLABORATORS = "[Resources] Get Collaborators",
    GET_VIEWERS = "[Resources] Get Viewers",
    GET_COLLOBORATORS_SUCCESS = "[Resources] Get Collaborators success",
    GET_VIEWERS_SUCCESS = "[Resources] Get Viewers Success",
    GET_SHARED_DOCUMENTS = "[Resources] Get SharedDocuments",
    GET_SHARED_DOCUMENTS_SUCCESS = "[Resources]Get Shared Documents Success",
    GET_SHARE_SUGGESTIONS = "[Resources] Get Share Suggestions",
    GET_SHARE_SUGGESTIONS_SUCCESS = "[Resources] Get Share Suggestions Success",
    GET_SHARED_USERS_LIST = "[Resources] Get Shared Users List",
    GET_SHARED_USERS_LIST_SUCCESS = "[Resources] Get Shared Users List Success",
    SHARE_DOCUMENT = "[Resources] Share Documents",
    EDIT_USER_PERMISSION_FOR_DOC = "[Resources] Edit User Permission",
    REMOVE_USER_FOR_DOC = "[Resources] Remove User",
    GET_USER_CAPABILITIES_FOR_DOC = "[Resources] Get User Capabilities for Document",
    GET_PUBLISHED_DOCUMENT_LIST = "[Resources] Get Published Documents List",
    GET_PUBLISHED_DOCUMENT_LIST_SUCCESS = "[Resources] Get Published Documents List Success",
    UN_PUBLISH_DOCUMENT = "[Resources] Un Publish document",
    GET_USER_CAPABILITIES_FOR_DOC_SUCCESS = "[Resources] Get User Capabilities for Document Success",
    SEARCH_DOCUMENTS = "[Resources] Get Search Results",
    SEARCH_RESULTS_SUCCESS = "[Resources] Get ",
    UPDATE_DOCUMENT_START_ACTION = "update start",
    CREATE_FOLDER = "[Resources] Create Folder",
    CREATE_FOLDER_SUCCESS = "[Resources] Create Folder Success",
    GET_MY_FOLDERS = "[Resources] Get My Folders",
    GET_MY_FOLDERS_SUCCESS = "[Resources] Get My Folders Success",
    GET_FILES_IN_MY_FOLDER = "[Resources] Get Files In My Folder",
    GET_FILES_IN_MY_FOLDER_SUCCESS = "[Resources] Get Files In My Folder Success",
    DELETE_FOLDER = "[Resources] Delate Folder",
    DELETE_FOLDER_SUCCESS = "[Resources] Delete Folder Success",
    GET_COMMENTS = "[Resources] Get Comments",
    GET_COMMENTS_SUCCESS = "[Resources] Get Comments Success",
    POST_COMMENT = "[Resources] Post Comment",
    POST_COMMENT_SUCCESS = "[Resources] Post Comment Success",
    MOVE_FILE = '[Resources] Move File',
    MOVE_FILE_SUCCESS = '[Resources] Move File Success',
    GET_MOVE_MY_FOLDERS = '[Resources] Get Folder for Move Component',
    GET_MOVE_MY_FOLDERS_SUCCESS = '[Resources] Get Folder for Move Component Success',
    GET_MOVE_FILES_IN_MY_FOLDER = '[Resources] Get sub folders in move component',
    GET_MOVE_FILES_IN_MY_FOLDER_SUCCESS = '[Resources] Get sub folders in move component success',
    GET_FILE_BY_ID_START = "get file start",
    GET_FILE_BY_ID_SUCCESS = "get file success",
    ADD_NEW_DOCUMENT_SUCCESS = "add document success",
    DELETE_FILE = '[Resources] Delete File',
    GET_MY_FILES_AND_FOLDERS = "[Resources] Get My Files And Folders",
    GET_MY_FILES_AND_FOLDERS_SUCCESS = "[Resources] Get My Files And Folders Success",
    CLEAR_DOC_DETAILS = '[Resources] clear doc details',
    ADD_DOCUMENT_SUCCESS = '[Resources] document success',
    SUBMIT_SUGGESTED_TAGS = '[Resources] Submit Suggested Tags',
    APPROVE_TAG = '[Resources] Approve Tag',
    REJECT_TAG = '[Resources] Reject Tag',
    CLEAR_SHARE_SUGGESTIONS = '[clear share suggestions]',
    GET_ACTIVITY_LOG = '[Resources] Get Activity Log',
    GET_ACTIVITY_LOG_SUCCESS = '[Resources] Get Activity Log Success',
    DELETE_SUGGESTED_TAG = '[Resources] Delete Suggested Tag',
    GET_ALL_CMP_DOCUMENTS = "[Resources] Get All CMP Documents",
    GET_ALL_CMP_DOCUMENTS_SUCCESS = "[Resources] Get All CMP Documents Sucess",
    COMMON_SUCCESS_ACTION = "[Resources] common success Action",
    GET_REQUEST_ACCESS_LIST = '[Resources] Get Request Access List',
    GET_REQUEST_ACCESS_LIST_SUCCESS = '[Resources] Get Request Access List Success',
    REQUEST_ACCESS = '[Resources] Request Access',
    ACCEPT_REQUEST_ACCESS = '[Resources] Accept Request Access',
    DENY_REQUEST_ACCESS = '[Resources] Deny Request Access',
    GET_UNPUBLISHED_DOCUMENTS = '[Resources] Get Unpublished Documents',
    GET_UNPUBLISHED_DOCUMENTS_SUCCESS = '[Resources] Get Unpublished Documents Success',
    REPLACE_DOCUMENT = '[Resources] Replace Document',
    MARK_PUBLIC_DOCUMENT = "[Resources] mark as public document",
    MOVE_TO_ROOT_FOLDER = "[Resources] Move To Root Folder",
    CANCEL_ACTIVITY_LOG_START = "[Resources activity log start]",
    CANCEL_ACTIVITY_LOG_SUCCESS = "Resources activiy success",
    MARK_DOCUMENT_AS_UNPUBLIC = "Resources unmark this document",
    RENAME_FOLDER = "[Resources] Rename Folder",
    UPDATE_DOCUMENT_SUCCESS = "[Resouces] Update document Success",
    GET_UPDATED_DOC_DETAILS = '[Resources] Get Doc Updated details',
    GET_PROJECT_SUGGESTIONS = "[Resources] Get Project details",
    GET_CITIS_FOLDER = "[Resouces] Get Citis Folders",
    GET_CITIS_FOLDER_SUCCESS = "[Resouces] Get Citis Folders Success",
    GET_DOC_ACTIVITY = "GET_DOC_ACTIVITY",
    GET_DOC_ACTIVITY_SUCCESS = "GET_DOC_ACTIVITY_SUCCESS",
    GET_FOLDER_DETAILS = "GET_FOLDER_DETAILS",
    GET_FOLDER_DETAILS_SUCCESS = "GET_FOLDER_DETAILS_SUCCESS"
}

export class GetMyDocuments implements Action {
    readonly type: string = ResourcesActionTypes.GET_MY_DOCUMENTS;
    constructor(public params: any) { }
}

export class GetMyDocumentsSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_MY_DOCUMENTS_SUCCESS;
    constructor(public docs: any) { }
}

export class GetSharedDocuments implements Action {
    readonly type: string = ResourcesActionTypes.GET_SHARED_DOCUMENTS;
    constructor(public params: any) { }
}

export class GetSharedDocumentsSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_SHARED_DOCUMENTS_SUCCESS;
    constructor(public response: any) { }
}

export class GetAllDocuments implements Action {
    readonly type: string = ResourcesActionTypes.GET_ALL_DOCUMENTS;
    constructor(public params: any) { }
}

export class GetAllDocumentsSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_ALL_DOCUMENTS_SUCCESS;
    constructor(public response: any) { }
}

export class GetThemes implements Action {
    readonly type: string = ResourcesActionTypes.GET_THEMES;
    constructor() { }
}

export class GetThemesSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_THEMES_SUCCESS;
    constructor(public data: any) { }
}

export class AddDocument implements Action {
    readonly type: string = ResourcesActionTypes.ADD_DOCUMENT;
    constructor(public payload: any, public callType?: string,public isCitis: boolean = false) { }
}

export class SubmitForApproval implements Action {
    readonly type: string = ResourcesActionTypes.SUBMIT_FOR_APPROVAL;
    constructor(public docId: any, public versionId: any) { }
}

export class GetAsOfVersionStartAction implements Action {
    readonly type: string = ResourcesActionTypes.GET_AS_OF_VERSION;
    constructor(public data: any) { }
}

export class GetAsOfVersionSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_AS_OF_VERSION_SUCCESS;
    constructor(public data: any) { }
}

export class GetTagList implements Action {
    readonly type: string = ResourcesActionTypes.GET_TAG_LIST;
    constructor() { }
}

export class GetTagListSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_TAG_LIST_SUCCESS;
    constructor(public data: any) { }
}

export class GetDocumentById implements Action {
    readonly type: string = ResourcesActionTypes.GET_DOCUMENT_BY_ID;
    constructor(public documentId: string, public allCmp?: boolean) { }
}

export class GetDocumentSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_DOCUMENT_SUCCESS;
    constructor(public documentDetail: any) { }
}

export class GetApprovalDataStartAction implements Action {
    readonly type: string = ResourcesActionTypes.GET_APPROVAL_START;
    constructor() { }
}

export class GetApprovalDataSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_APPROVAL_DATA_SUCCESS;
    constructor(public data: any) { }
}

export class GetDocumentVersionDetails implements Action {
    readonly type: string = ResourcesActionTypes.GET_DOCUMENT_VERSION_DETAILS;
    constructor(public id: string, public version: string) { }
}

export class GetApprovalDetailsStartAction implements Action {
    readonly type: string = ResourcesActionTypes.GET_APPROVAL_DETAILS_START;
    constructor(public documentId: string) { }
}

export class PublishDocument implements Action {
    readonly type: string = ResourcesActionTypes.PUBLISH_DOCUMENT;
    constructor(public docId: string, public payload: any) { }
}

export class GetPublishedDocuments implements Action {
    readonly type: string = ResourcesActionTypes.GET_PUBLISHED_DOCUMENT_LIST;
    constructor() { }
}

export class GetPublishedDocumentsSuccess implements Action {
    readonly type: string =
        ResourcesActionTypes.GET_PUBLISHED_DOCUMENT_LIST_SUCCESS;
    constructor(public docs: any) { }
}

export class UnPublishDocument implements Action {
    readonly type: string = ResourcesActionTypes.UN_PUBLISH_DOCUMENT;
    constructor(public docId: string) { }
}

export class RejectDocument implements Action {
    readonly type: string = ResourcesActionTypes.REJECT_DOCUMENT;
    constructor(public docId: string, public versionId: string) { }
}

export class GetApprovalDetailsSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_APPROVAL_DETAILS_SUCCESS;
    constructor(public data: any) { }
}

export class DownloadDocument implements Action {
    readonly type: string = ResourcesActionTypes.DOWNLOAD_DOCUMENT;
    constructor(
        public id: string,
        public filename: any,
        public version?: string
    ) { }
}

// export class UpdateDocument implements Action {
//   readonly type: string = ResourcesActionTypes.UPDATE_DOCUMENT;
//   constructor(public id: string, public version: string, public payload: any) { }
// }
export class UpdateDocument implements Action {
    readonly type: string = ResourcesActionTypes.UPDATE_DOCUMENT;
    constructor(public payload: any, public id: any) { }
}

export class AddNewVersion implements Action {
    readonly type: string = ResourcesActionTypes.ADD_NEW_VERSION;
    constructor(public id: string, public version: string, public payload: any) { }
}

export class AddColloborators implements Action {
    readonly type: string = ResourcesActionTypes.ADD_COLLOBORATORS;
    constructor(public documentId, public payload: any) { }
}

export class AddViewers implements Action {
    readonly type: string = ResourcesActionTypes.ADD_VIEWERS;
    constructor(public documentId, public payload: any) { }
}

export class GetCollaborators implements Action {
    readonly type: string = ResourcesActionTypes.GET_COLLABORATORS;
    constructor(public documentId: any) { }
}

export class GetViewers implements Action {
    readonly type: string = ResourcesActionTypes.GET_VIEWERS;
    constructor(public documentId: any) { }
}

export class GetViewersSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_VIEWERS_SUCCESS;
    constructor(public viewers: any) { }
}

export class GetCollaboratorsSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_COLLOBORATORS_SUCCESS;
    constructor(public collaborators: any) { }
}

export class GetShareSuggestions implements Action {
    readonly type: string = ResourcesActionTypes.GET_SHARE_SUGGESTIONS;
    constructor(public searchString: any, public suggestions?: any) { }
}

export class GetProjectSuggestions implements Action {
    readonly type: string = ResourcesActionTypes.GET_PROJECT_SUGGESTIONS;
    constructor(public searchString: any, public suggestions?: any) { }
}

export class GetShareSuggestionsSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_SHARE_SUGGESTIONS_SUCCESS;
    constructor(public suggestions: any) { }
}

export class GetSharedUsersList implements Action {
    readonly type: string = ResourcesActionTypes.GET_SHARED_USERS_LIST;
    constructor(public documentId: any) { }
}

export class GetSharedUsersListSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_SHARED_USERS_LIST_SUCCESS;
    constructor(public sharedUsers: any) { }
}

export class ShareDocumentToMembers implements Action {
    readonly type: string = ResourcesActionTypes.SHARE_DOCUMENT;
    constructor(public docId: any, public permission: any, public users: any) { }
}

export class EditUserPermissionForDoc implements Action {
    readonly type: string = ResourcesActionTypes.EDIT_USER_PERMISSION_FOR_DOC; file
    constructor(
        public docId: any,
        public userType: any,
        public userId: any,
        public permission: any,
        public isFromAccessModal?: boolean
    ) { }
}

export class RemoveUserForDoc implements Action {
    readonly type: string = ResourcesActionTypes.REMOVE_USER_FOR_DOC;
    constructor(
        public docId: any,
        public userType: any,
        public userId: any,
        public permission: any
    ) { }
}

export class GetUserCapabilitesOfDocument implements Action {
    readonly type: string = ResourcesActionTypes.GET_USER_CAPABILITIES_FOR_DOC;
    constructor(public docId: any, public callType?: any) { }
}

export class GetUserCapabilitesOfDocumentSuccess implements Action {
    readonly type: string =
        ResourcesActionTypes.GET_USER_CAPABILITIES_FOR_DOC_SUCCESS;
    constructor(public capability: any) { }
}

export class SearchDocuments implements Action {
    readonly type: string = ResourcesActionTypes.SEARCH_DOCUMENTS;
    constructor(public searchString: any, public params: any, public searchCallFromAllCmp ?: Boolean) { }
}

export class SearchResultsSuccess implements Action {
    readonly type: string = ResourcesActionTypes.SEARCH_RESULTS_SUCCESS;
    constructor(public searchResults: any) { }
}
export class UPDATEDOCUMENTSTARTACTION implements Action {
    readonly type: string = ResourcesActionTypes.UPDATE_DOCUMENT_START_ACTION;
    constructor(public payload, public id: string, public callType?: string) { }
}
export class GetFileByIdStart implements Action {
    readonly type: string = ResourcesActionTypes.GET_FILE_BY_ID_START;
    constructor(public docId: string) { }
}
export class GetFileByIdSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_FILE_BY_ID_SUCCESS;
    constructor(public response: string) { }
}
export class AddNewDocumentSuccess implements Action {
    readonly type: string = ResourcesActionTypes.ADD_NEW_DOCUMENT_SUCCESS;
    constructor(public response: any) { }
}

export class CreateFolderSuccess implements Action {
    readonly type: string = ResourcesActionTypes.CREATE_FOLDER_SUCCESS;
    constructor(public response: any) { }
}

export class GetMyFolders implements Action {
    readonly type: string = ResourcesActionTypes.GET_MY_FOLDERS;
    constructor() { }
}

export class GetMyFoldersSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_MY_FOLDERS_SUCCESS;
    constructor(public folderList: any) { }
}

export class GetMyFilesAndFolders implements Action {
    readonly type: string = ResourcesActionTypes.GET_MY_FILES_AND_FOLDERS;
    constructor(public params: any) { }
}

export class GetMyFilesAndFoldersSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_MY_FILES_AND_FOLDERS_SUCCESS;
    constructor(public filesAndFolderList: any) { }
}

export class GetMoveMyFolders implements Action {
    readonly type: string = ResourcesActionTypes.GET_MOVE_MY_FOLDERS;
    constructor() { }
}

export class GetMoveMyFoldersSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_MOVE_MY_FOLDERS_SUCCESS;
    constructor(public folderList: any) { }
}

export class GetFilesInMyFolder implements Action {
    readonly type: string = ResourcesActionTypes.GET_FILES_IN_MY_FOLDER;
    constructor(public folderId: string, public params: any) { }
}

export class GetFilesInMyFolderSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_FILES_IN_MY_FOLDER_SUCCESS;
    constructor(public response: any) { }
}

export class DeleteFolder implements Action {
    readonly type: string = ResourcesActionTypes.DELETE_FOLDER;
    constructor(public folderId: string, public parentId?: string) { }
}

export class DeleteFolderSuccess implements Action {
    readonly type: string = ResourcesActionTypes.DELETE_FOLDER_SUCCESS;
    constructor(public response: any) { }
}

export class GetMoveFilesInMyFolder implements Action {
    readonly type: string = ResourcesActionTypes.GET_MOVE_FILES_IN_MY_FOLDER;
    constructor(public folderId: string) { }
}

export class GetMoveFilesInMyFolderSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_MOVE_FILES_IN_MY_FOLDER_SUCCESS;
    constructor(public response: any) { }
}
export class getComments implements Action {
    readonly type: string = ResourcesActionTypes.GET_COMMENTS;
    constructor(public id: string, public commentType: string) { }
}

export class getCommentsSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_COMMENTS_SUCCESS;
    constructor(public comments: object) { }
}

export class postComment implements Action {
    readonly type: string = ResourcesActionTypes.POST_COMMENT;
    constructor(public comment: object) { }
}

export class PostCommentsSuccess implements Action {
    readonly type: string = ResourcesActionTypes.POST_COMMENT_SUCCESS;
    constructor(public comment: object) { }
}
export class MoveFileTo implements Action {
    readonly type: string = ResourcesActionTypes.MOVE_FILE;
    constructor(public docID: string, public payload: any) { }
}

export class MoveFileToSuccess implements Action {
    readonly type: string = ResourcesActionTypes.MOVE_FILE_SUCCESS;
    constructor(public files: any) { }
}

export class CreateFolder implements Action {
    readonly type: string = ResourcesActionTypes.CREATE_FOLDER;
    constructor(public payload: any,public isEdit: boolean = false) { }
}

export class DeleteFile implements Action {
    readonly type: string = ResourcesActionTypes.DELETE_FILE;
    constructor(public id: string, public payload: object, public folderId: string = null) { }
}
export class ClearDocDetailsStartAction implements Action {
    readonly type: string = ResourcesActionTypes.CLEAR_DOC_DETAILS;
    constructor() { }
}
export class AddDocumentSuccess implements Action {
    readonly type: string = ResourcesActionTypes.ADD_DOCUMENT_SUCCESS;
    constructor(public response: any) { }
}

export class AddSuggestedTags implements Action {
    readonly type: string = ResourcesActionTypes.SUBMIT_SUGGESTED_TAGS;
    constructor(public id: string, public suggestedTags: any) { }
}

export class ApproveTags implements Action {
    readonly type: string = ResourcesActionTypes.APPROVE_TAG;
    constructor(public id: string, public payload: any) { }
}

export class RejectTags implements Action {
    readonly type: string = ResourcesActionTypes.REJECT_TAG;
    constructor(public id: string, public payload: any) { }
}
export class ClearShareSuggestions implements Action {
    readonly type: string = ResourcesActionTypes.CLEAR_SHARE_SUGGESTIONS;
    constructor() { }
}

export class DeleteSuggestedTag implements Action {
    readonly type: string = ResourcesActionTypes.DELETE_SUGGESTED_TAG;
    constructor(public docId: string, public payload: any) { }
}

export class GetActivityLog implements Action {
    readonly type: string = ResourcesActionTypes.GET_ACTIVITY_LOG;
    constructor(public documentId: string) { }
}

export class GetActivityLogSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_ACTIVITY_LOG_SUCCESS;
    constructor(public activityLog: any) { }
}

export class GetAllCmpDocuments implements Action {
    readonly type: string = ResourcesActionTypes.GET_ALL_CMP_DOCUMENTS;
    constructor(public params: any) { }
}

export class GetAllCmpDocumentsSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_ALL_CMP_DOCUMENTS_SUCCESS;
    constructor(public response: any) { }
}

export class CommonSuccessAction implements Action {
    readonly type: string = ResourcesActionTypes.COMMON_SUCCESS_ACTION;
    constructor() { }
}

export class RequestDocAccess implements Action {
    readonly type: string = ResourcesActionTypes.REQUEST_ACCESS;
    constructor(public id: string) { }
}

export class GetRequestDocAccessList implements Action {
    readonly type: string = ResourcesActionTypes.GET_REQUEST_ACCESS_LIST;
    constructor(public id: string) { }
}

export class GetRequestDocAccessListSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_REQUEST_ACCESS_LIST_SUCCESS;
    constructor(public accessList: any) { }
}

export class AcceptDocAccess implements Action {
    readonly type: string = ResourcesActionTypes.ACCEPT_REQUEST_ACCESS;
    constructor(public id: string, public docId: string) { }
}

export class DenyDocAccess implements Action {
    readonly type: string = ResourcesActionTypes.DENY_REQUEST_ACCESS;
    constructor(public id: string, public docId: string) { }
}
export class GetUnpublishedDocuments implements Action {
    readonly type: string = ResourcesActionTypes.GET_UNPUBLISHED_DOCUMENTS;
    constructor(public params: any) { }
}

export class GetUnpublishedDocumentsSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_UNPUBLISHED_DOCUMENTS_SUCCESS;
    constructor(public response: any) { }
}

export class ReplaceDocument implements Action {
    readonly type: string = ResourcesActionTypes.REPLACE_DOCUMENT;
    constructor(public params: any, public payload: any) { }
}

export class MarkPublicDocument implements Action {
    readonly type: string = ResourcesActionTypes.MARK_PUBLIC_DOCUMENT;
    constructor(public docId: string) { }
}

export class MoveToRootFolder implements Action {
    readonly type: string = ResourcesActionTypes.MOVE_TO_ROOT_FOLDER;
    constructor(public folderId, public payload: any) { }
}
export class CancelActivityLogStartForDocument implements Action {
    readonly type: string = ResourcesActionTypes.CANCEL_ACTIVITY_LOG_START;
    constructor(public parentId: string) { }
}
export class CancelActivityLogSuccess implements Action {
    readonly type: string = ResourcesActionTypes.CANCEL_ACTIVITY_LOG_SUCCESS;
    constuctor() { }

}
export class MarkUnPublicDocument implements Action {
    readonly type: string = ResourcesActionTypes.MARK_DOCUMENT_AS_UNPUBLIC;
    constructor(public docId: string) { }
}

export class RenameFolder implements Action {
    readonly type: string = ResourcesActionTypes.RENAME_FOLDER;
    constructor(public folderObj: any, public payload: any) { }
}

export class UpdateDocumentStartActionSuccess implements Action {
    readonly type: string = ResourcesActionTypes.UPDATE_DOCUMENT_SUCCESS;
    constructor(public response: any) { }
}

export class GetDocUpdatedDetails implements Action {
    readonly type: string = ResourcesActionTypes.GET_UPDATED_DOC_DETAILS;
    constructor(public docId: any) { }
}


export class GetCitisFolders implements Action {
    readonly type: string = ResourcesActionTypes.GET_CITIS_FOLDER;
    constructor(public params: any) { }
}

export class GetCitisFoldersSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_CITIS_FOLDER_SUCCESS;
    constructor(public response: any) { }
}

export class GetDocActivityLog implements Action {
    readonly type: string = ResourcesActionTypes.GET_DOC_ACTIVITY;
    constructor(public payload: any) { }
}

export class GetDocActivityLogSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_DOC_ACTIVITY_SUCCESS;
    constructor(public response: any) { }
}
export class GetFolderDetails implements Action {
    readonly type: string = ResourcesActionTypes.GET_FOLDER_DETAILS;
    constructor(public folderId: any) { }
}

export class GetFolderDetailsSuccess implements Action {
    readonly type: string = ResourcesActionTypes.GET_FOLDER_DETAILS_SUCCESS;
    constructor(public response: any) { }
}
