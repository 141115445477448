import { Action } from '@ngrx/store';
export enum AuthActionTypes {
  LOGIN = '[auth] Login',
  LOGIN_SUCCESS = '[auth] Login Sucess',
  GET_USER_DETAILS = '[auth] Get User Details',
  GET_USER_DETAILS_SUCCESS = '[auth] Get User Details Success',
  GET_USER_ROLES = '[auth] Get UserRoles',
  GET_USER_ROLES_SUCCESS = '[auth] Get User Roles Success',
  GET_USER_CAPABILTITES = '[auth] Get User Capabilites',
  GET_USER_CAPABILTITES_SUCCESS = '[auth] Get User Capabilites Success',
  GET_USER_DATA = '[auth] Get User Data',
  GET_USER_DATA_SUCCESS = '[auth] Get User Data Success',
  LOGOUT = '[auth] Logout',
  LOGOUT_SUCCESS = '[auth] Logout Success',
  ERROR = '[auth] Error',
  FORGOT_PASSWORD = '[auth] Forgot Password',
  FORGOT_PASSWORD_SUCCESS = '[auth] forgot password success',
  FORGOT_PASSWORD_ERROR = '[auth] Forgot Password Error',
  FORGOT_PASSWORD_VERIFY = '[auth] Forgot Password Verify',
  RESET_PASSWORD = '[auth] Reset Password',
  SET_NEW_PASSWORD = '[auth] Set New Password',
  CLEAR_ACTION = '[auth] clear action start',
  GET_OTP = '[auth] Get Otp',
  VERIFY_OTP = '[auth] Verify Otp',
  VERIFY_OTP_SUCCESS = '[auth] Verify Otp Success',
  RESEND_OTP = '[auth] Resend Otp',
  BACK_TO_LOGIN = '[auth] Back To Login',
  REGISTER_LINK_VALIDATE = '[auth] Validate Register Link',
  GET_IP_ADDRESS = '[auth] Get Ip Address',
  GET_IP_ADDRESS_SUCCESS = '[auth] Get Ip Address Success',
  RECAPTCHA_PERMISSION_START = 'permission for recaptcha start',
  RECAPTCHA_PERMISSION_SUCCESS = 'permission for recaptcha success',
  GET_OTP_START = 'otp start',
  GET_OTP_SUCESS = "otp success",
  VERIFY_OTP_START = "otp verification start",
  VERIFY_OTP_SUCCESS_ = "otp verification success",
  CLEAR_ACTION_OTHERS = '[auth] clear action Others start',
};

export class Login implements Action {
  readonly type: string = AuthActionTypes.LOGIN;
  constructor(public payload: any) { }
}

export class LoginSuccess implements Action {
  readonly type: string = AuthActionTypes.LOGIN_SUCCESS;
  constructor(public token: any) { }
}

export class GetUserDetails implements Action {
  readonly type: string = AuthActionTypes.GET_USER_DETAILS;
  constructor() { }
}

export class GetUserDetailsSuccess implements Action {
  readonly type: string = AuthActionTypes.GET_USER_DETAILS_SUCCESS;
  constructor(public userDetails: any) { }
}

export class GetRegisterUserStartAction implements Action {
  readonly type = AuthActionTypes.GET_USER_DATA;
  constructor(public token, public data) { }
}

export class GetRegisterUserSuccessAction implements Action {
  readonly type = AuthActionTypes.GET_USER_DATA_SUCCESS;
  constructor(public res: any) { }
}

export class GetUserRoles implements Action {
  readonly type: string = AuthActionTypes.GET_USER_ROLES;
  constructor() { }
}

export class GetUserRolesSuccess implements Action {
  readonly type: string = AuthActionTypes.GET_USER_ROLES_SUCCESS;
  constructor(public roles: any) { }
}

export class GetUserCapabilites implements Action {
  readonly type: string = AuthActionTypes.GET_USER_CAPABILTITES;
  constructor() { }
}

export class GetUserCapabilitesSuccess implements Action {
  readonly type: string = AuthActionTypes.GET_USER_CAPABILTITES_SUCCESS;
  constructor(public capabilities: any) { }
}

export class ForgotPassword implements Action {
  readonly type: string = AuthActionTypes.FORGOT_PASSWORD;
  constructor(public email: any) { }
}

export class ForgotPasswordSuccess implements Action {
  readonly type: string = AuthActionTypes.FORGOT_PASSWORD_SUCCESS;
  constructor(public response: any) { }
}

export class ForgotPasswordError implements Action {
  readonly type: string = AuthActionTypes.FORGOT_PASSWORD_ERROR;
  constructor(public email: any) { }
}

export class ForgotPasswordVerify implements Action {
  readonly type: string = AuthActionTypes.FORGOT_PASSWORD_VERIFY;
  constructor(public payload: any) { }
}

export class SetNewPassword implements Action {
  readonly type: string = AuthActionTypes.SET_NEW_PASSWORD;
  constructor(public password: string, public id: string) { }
}

export class ResetPassword implements Action {
  readonly type: string = AuthActionTypes.RESET_PASSWORD;
  constructor(public password: string, public token: string) { }
}

export class ErrorAction implements Action {
  readonly type: string = AuthActionTypes.ERROR;
  constructor(public error: any) { }
}

export class Logout implements Action {
  readonly type: string = AuthActionTypes.LOGOUT;
  constructor(public userId: any, public token: any) { }
}

export class LogoutSuccess implements Action {
  readonly type: string = AuthActionTypes.LOGOUT_SUCCESS;
  constructor() { }
}

export class ClearAction implements Action {
  readonly type: string = AuthActionTypes.CLEAR_ACTION;
  constructor() { }
}

export class GetOtp implements Action {
  readonly type: string = AuthActionTypes.GET_OTP;
  constructor(public id: any, public payload: any) { }
}

export class VerifyOtp implements Action {
  readonly type: string = AuthActionTypes.VERIFY_OTP;
  constructor(public id: string, public payload: any) { }
}

export class VerifyOtpSuccess implements Action {
  readonly type: string = AuthActionTypes.VERIFY_OTP_SUCCESS;
  constructor(public response: any) { }
}

export class ResendOtp implements Action {
  readonly type: string = AuthActionTypes.RESEND_OTP;
  constructor(public id: string, public payload: any) { }
}

export class BackToLogin implements Action {
  readonly type: string = AuthActionTypes.BACK_TO_LOGIN;
  constructor() { }
}

export class RegisterLinkValidate implements Action {
  readonly type: string = AuthActionTypes.REGISTER_LINK_VALIDATE;
  constructor(public token: string) { }
}

export class GetIpAddress implements Action {
  readonly type: string = AuthActionTypes.GET_IP_ADDRESS;
  constructor() { }
}

export class GetIpAddressSuccess implements Action {
  readonly type: string = AuthActionTypes.GET_IP_ADDRESS_SUCCESS;
  constructor(public response: any) { }
}
export class RecaptchaPermissionStart implements Action {
  readonly type: string = AuthActionTypes.RECAPTCHA_PERMISSION_START;
  constructor() { }
}

export class RecaptchaPermissionSuccess implements Action {
  readonly type: string = AuthActionTypes.RECAPTCHA_PERMISSION_SUCCESS;
  constructor(public response: any) { }
}

export class SendOtpToAdminMail implements Action {
  readonly type: string = AuthActionTypes.GET_OTP_START;
  constructor(public payload, public id) { }
}

export class SendOtpToMailSuccess implements Action {
  readonly type: string = AuthActionTypes.GET_OTP_SUCESS;
  constructor(public response: any) { }
}

export class VerifyOtpStart implements Action {
  readonly type: string = AuthActionTypes.VERIFY_OTP_START;
  constructor(public id, public payload: any) { }
}

export class VerifyOtpSuccessForAdmin implements Action {
  readonly type: string = AuthActionTypes.VERIFY_OTP_SUCCESS_;
  constructor(public response: any) { }
}

export class ClearActionOthers implements Action {
  readonly type: string = AuthActionTypes.CLEAR_ACTION_OTHERS;
  constructor() { }
}