import { Action } from '@ngrx/store';

export enum TemplateActionTypes {
    EDIT_TEMPLATE = '[TEMPLATE] Edit TEMPLATE',
    EDIT_TEMPLATE_SUCCESS = '[TEMPLATE] Edit TEMPLATE Success',
    GET_TEMPLATE = '[TEMPLATE] Get TEMPLATE Details',
    GET_TEMPLATES_SUCCESS = '[TEMPLATE] TEMPLATES Success',
    GET_TEMPLATE_BY_ID = '[TEMPLATE] GET TEMPLATE BY ID',
    GET_TEMPLATE_BY_ID_SUCCESS = '[TEMPLATE] GET TEMPLATE BY ID Success',
    GET_REPLACEMENT_PATTERNS = '[TEMPLATE] Get Replacement Pattern',
    GET_REPLACEMENT_PATTERNS_SUCCESS = '[TEMPLATE] Get Replacement Pattern Success',
    ADD_REPLACEMENT_PATTERN = '[TEMPLATE] Add Replacement Pattern',
    GET_REPLACEMENT_PATTERN_DETAILS = '[TEMPLATE] Get Replacement Pattern Details',
    GET_REPLACEMENT_PATTERN_DETAILS_SUCCESS = '[TEMPLATE] Get Replacement Pattern Details Success',
    EDIT_REPLACEMENT_PATTERN = '[TEMPLATE] Edit Replacement Pattern',
    DELETE_REPLACEMENT_PATTERN = '[TEMPLATE] Delete Replacement Pattern',
    TEST_EMAIL_TEMPLATE = '[Template] Test EMail Template',
    TEST_EMAIL_TEMPLATE_SUCCESS = '[Template] Test EMail Template Success'
}

export class GetTemplates implements Action {
    readonly type: string = TemplateActionTypes.GET_TEMPLATE;
    constructor() { }
}

export class GetTemplateById implements Action {
    readonly type: string = TemplateActionTypes.GET_TEMPLATE_BY_ID;
    constructor(public id: string) { }
}

export class GetTemplateByIdSuccess implements Action {
    readonly type: string = TemplateActionTypes.GET_TEMPLATE_BY_ID_SUCCESS
    constructor(public template: object) { }
}

export class EditTemplate implements Action {
    readonly type: string = TemplateActionTypes.EDIT_TEMPLATE;
    constructor(public id: string, public payload: object) { }
}

export class TemplateSuccess implements Action {
    readonly type: string = TemplateActionTypes.GET_TEMPLATES_SUCCESS;
    constructor(public templates: any) { }
}

export class TemplateEditSuccess implements Action {
    readonly type: string = TemplateActionTypes.EDIT_TEMPLATE_SUCCESS;
    constructor(public templateObj: any) { }
}

export class GetReplacementPatterns implements Action {
    readonly type: string = TemplateActionTypes.GET_REPLACEMENT_PATTERNS;
    constructor(public params?: any) { }
}

export class GetReplacementPatternsSuccess implements Action {
    readonly type: string = TemplateActionTypes.GET_REPLACEMENT_PATTERNS_SUCCESS;
    constructor(public response: any) { }
}

export class AddReplacementPattern implements Action {
    readonly type: string = TemplateActionTypes.ADD_REPLACEMENT_PATTERN;
    constructor(public payload: any) { }
}

export class GetReplacementPatternDetails implements Action {
    readonly type: string = TemplateActionTypes.GET_REPLACEMENT_PATTERN_DETAILS;
    constructor(public patternId: string) { }
}

export class GetReplacementPatternDetailsSuccess implements Action {
    readonly type: string = TemplateActionTypes.GET_REPLACEMENT_PATTERN_DETAILS_SUCCESS;
    constructor(public response: any) { }
}

export class EditReplacementPattern implements Action {
    readonly type: string = TemplateActionTypes.EDIT_REPLACEMENT_PATTERN;
    constructor(public patternId: string, public payload: any) { }
}

export class DeleteReplacementPattern implements Action {
    readonly type: string = TemplateActionTypes.DELETE_REPLACEMENT_PATTERN;
    constructor(public patternId: string) { }
}

export class TestEmailTemplate implements Action {
    readonly type: string = TemplateActionTypes.TEST_EMAIL_TEMPLATE;
    constructor(public id: string, public payload: any) { }
}

export class TestEmailTemplateSuccess implements Action {
    readonly type: string = TemplateActionTypes.TEST_EMAIL_TEMPLATE_SUCCESS;
    constructor(public response: any) { }
}




