import { Component, OnInit, Inject } from '@angular/core';
import { AppState } from 'src/app/app.reducer';
import { map } from 'rxjs/operators'
import { Store } from '@ngrx/store';
import { ChangeEmailVerify, ChangeEmail } from '../store/profile.actions'
import { Router, ActivatedRoute } from '@angular/router';
import { API } from '../../../../services/Api.service'
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA } from "@angular/material";
import { ResendOtp } from 'src/app/login/store/auth.actions';

@Component({
    selector: 'app-verify-change-email',
    templateUrl: './verify-change-email.component.html',
    styleUrls: ['./verify-change-email.component.scss']
})
export class VerifyChangeEmailComponent implements OnInit {
    changeEmailVerifyForm;
    email: string;
    userId: string;

    constructor(
        private store: Store<AppState>,
        private api: API,
        private router: Router,
        private fb: FormBuilder,
        private route: ActivatedRoute,
        private dialog: MatDialog,
        @Inject(MAT_DIALOG_DATA) public data: any,
        private dialogRef: MatDialogRef<any>
    ) {
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {
        this.changeEmailVerifyForm = this.fb.group({
            otp: ['', [Validators.required, Validators.minLength, Validators.maxLength, Validators.pattern]],
            mobileOtp: ['', [Validators.required, Validators.minLength, Validators.maxLength, Validators.pattern]]
        });
    }

    verifyOtp() {
        if (this.changeEmailVerifyForm.invalid) {
            return false;
        }

        const payload = {
            otp: this.changeEmailVerifyForm.value.otp,
            mobileOtp: this.changeEmailVerifyForm.value.mobileOtp
        }

        this.store.dispatch(new ChangeEmailVerify(payload));
    }

    isInputNumber(event) {
        const ch = String.fromCharCode(event.which);
        if (!(/[0-9]/.test(ch))) {
            event.preventDefault();
        }
    }

    onCloseDialog(): void {
        this.dialogRef.close();
    }

    resendOTP() {
        const payload = {
            email: this.data.email,
            password: this.data.password
        }
        this.store.dispatch(new ChangeEmail(this.data.registeredCountryCode, this.data.registeredPhone, payload));
    }

}
