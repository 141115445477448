import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { of } from 'rxjs';
import { ResetUser } from '../user-management/store/user-managment.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { GetProjectDataManagement, GetInstitutionalCoordination, GetFinancialProgress, GetPhysicalProgress, RolesAndResponsibilites, GetMonitoringTypes, GetMajorEventsIndicators, GetCapacityBuilding, GetCommunicationOutreach, GetPeerExchange, GetCircumstancesActions } from './store/monitoring-evaluation.actions';
export const BASE_URL = environment.host;

@Injectable({
  providedIn: 'root'
})
export class MonitoringEvaluationService {
  constructor(private http: HttpClient,
    private store: Store<AppState>,
  ) {

  }

  getMasterList(params) {
    // return of([{
    //   "total": 30,
    //   "limit": 10,
    //   "page": 1,
    //   "pages": 3,
    //   "docs": [{
    //     id: "5e104ece4f917828507fe6a4",
    //     indicatorName: "waterIndicator",
    //     theme: "transport",
    //     level: "level1",
    //     type: "outcome",
    //     component: "E&S",
    //     reportingFrequency: "anually",
    //     baseLineData: "",
    //     targetData: "",
    //     monitoringType: "",
    //     measurementUnits: "",
    //     dataType: "single-choice",
    //     options: ["option1", "option2"],
    //     calculationMethod: "",
    //     projects: ["5e104ece4f917828507fe6a4"],
    //     suggestedBy: "5e0c48316b56f3235b4d68e3"
    //   }]
    // }])

    if (params.limit) {
      return this.http.get(`${BASE_URL}/indicator/v1/indicator/list/${params.id}`, { params: params });
    }
    else {
      return this.http.get(`${BASE_URL}/indicator/v1/indicator/list/${params}?limit=30`);
    }
  }

  getSpvList(params) {
    // return of([{
    //   "total": 30,
    //   "limit": 10,
    //   "page": 1,
    //   "pages": 3,
    //   "docs": [{
    //     id: "5e104ece4f917828507fe6a4",
    //     indicatorName: "waterIndicator",
    //     theme: "transport",
    //     level: "level1",
    //     type: "outcome",
    //     component: "E&S",
    //     reportingFrequency: "anually",
    //     baseLineData: "",
    //     targetData: "",
    //     monitoringType: "",
    //     measurementUnits: "",
    //     dataType: "single-choice",
    //     options: ["option1", "option2"],
    //     calculationMethod: "",
    //     projects: ["5e104ece4f917828507fe6a4"],
    //     suggestedBy: "5e0c48316b56f3235b4d68e3",
    //     addedToProject: true
    //   },
    //   {
    //     id: "5e104ece4f917828507fe6a4",
    //     indicatorName: "waterIndicator",
    //     theme: "transport",
    //     level: "level1",
    //     type: "outcome",
    //     component: "E&S",
    //     reportingFrequency: "anually",
    //     baseLineData: "",
    //     targetData: "",
    //     monitoringType: "",
    //     measurementUnits: "",
    //     dataType: "single-choice",
    //     options: ["option1", "option2"],
    //     calculationMethod: "",
    //     projects: ["5e104ece4f917828507fe6a4"],
    //     suggestedBy: "5e0c48316b56f3235b4d68e3",
    //     addedToProject: false
    //   }]
    // }])
    if (params.limit && !params.page) {
      return this.http.get(`${BASE_URL}/indicator/v1/indicator/spv-list/${params.id}?limit=${params.limit}`);
    }
    else if (params.limit && params.page) {
      return this.http.get(`${BASE_URL}/indicator/v1/indicator/spv-list/${params.id}`, { params: params });
    }
    else {
      return this.http.get(`${BASE_URL}/indicator/v1/indicator/spv-list/${params}?limit=30`);
    }
  }

  getIndicatorDetails(id) {

    // return of([
    //   {
    //     id: "5e104ece4f917828507fe6a4",
    //     indicatorName: "waterIndicator",
    //     theme: "transport",
    //     level: "level1",
    //     type: "outcome",
    //     component: "e&s",
    //     reportingFrequency: "annually",
    //     baseLineData: "",
    //     targetData: "",
    //     monitoringType: "environment-indicators",
    //     measurementUnits: "",
    //     dataType: "single-choice",
    //     options: ["option1", "option2"],
    //     calculationMethod: "",
    //     projects: [{
    //       id: "5e104ece4f917828507fe6a4",
    //       name: "projectName",
    //       projectCost: 10000,
    //       createdBy: "5e104ece4f917828507fe6a4"
    //     }],

    //     suggestedBy: {
    //       "is_active": true,
    //       "_id": "5e0c48316b56f3235b4d68e3",
    //       "firstName": "pranay",
    //       "lastName": "Bharadwaj",

    //       "email": "pranay@citiis.com",
    //       "phone": "7989238348",
    //       "countryCode": "+91"
    //     }
    //   }

    // ]) https://cmp-dev.transerve.com/api/indicator/v1/indicator/list/5fdc3ab413a6cc1d930178c5
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/detail/${id}`);

  }

  getDropdownData() {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/dropdowns`);
  }

  addIndicator(payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/addToProject`, payload);
  }

  createIndicator(payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/create`, {
      ...payload
    });
  }
  suggestIndicator(payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/suggest`, {
      ...payload
    });
  }
  editIndicator(id, payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/${id}/edit`, {
      ...payload
    });
  }
  getSuggestedList(params) {
    // return of([{
    //   "total": 30,
    //   "limit": 10,
    //   "page": 1,
    //   "pages": 3,
    //   "docs": [{
    //     id: "5e104ece4f917828507fe6a4",
    //     indicatorName: "waterIndicator",
    //     cityName: "Kochi",
    //     sentOn:"11-23-2021",
    //     sentBy:"Amit Prothi",
    //     theme: "transport",
    //     level: "level1",
    //     type: "outcome",
    //     component: "E&S",
    //     reportingFrequency: "anually",
    //     baseLineData: "",
    //     targetData: "",
    //     monitoringType: "",
    //     measurementUnits: "",
    //     dataType: "single-choice",
    //     options: ["option1", "option2"],
    //     calculationMethod: "",
    //     projects: ["5e104ece4f917828507fe6a4"],
    //     suggestedBy: "5e0c48316b56f3235b4d68e3"
    //   }]
    // }])    
    if (params.limit && !params.page) {
      return this.http.get(`${BASE_URL}/indicator/v1/indicator/suggested-list/${params.id}?limit=${params.limit}`);
    }
    else if (params.limit && params.page) {
      return this.http.get(`${BASE_URL}/indicator/v1/indicator/suggested-list/${params.id}`, { params: params });
    }
    else {
      return this.http.get(`${BASE_URL}/indicator/v1/indicator/suggested-list/${params}?limit=30`);
    }

  }
  acceptIndicator(id) {
    // return of({
    //     Message : 'Indicator suggestion accepted succesfully'     
    // }
    // )
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/${id}/accept`);
  }
  rejectIndicator(id) {
    // return of({
    //     Message : 'Indicator suggestion rejected'    
    // })
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/${id}/reject`);
  }
  getRejectedList(params) {
    // return of([{
    //   "total": 30,
    //   "limit": 10,
    //   "page": 1,
    //   "pages": 3,
    //   "docs": [{
    //     id: "5e104ece4f917828507fe6a4",
    //     indicatorName: "waterIndicator",
    //     cityName: "Kochi",
    //     sentOn:"11-23-2021",
    //     sentBy:"Amit Prothi",
    //     theme: "transport",
    //     level: "level1",
    //     type: "outcome",
    //     component: "E&S",
    //     reportingFrequency: "anually",
    //     baseLineData: "",
    //     targetData: "",
    //     monitoringType: "",
    //     measurementUnits: "",
    //     dataType: "single-choice",
    //     options: ["option1", "option2"],
    //     calculationMethod: "",
    //     projects: ["5e104ece4f917828507fe6a4"],
    //     suggestedBy: "5e0c48316b56f3235b4d68e3",
    //     isRejected: true
    //   }]
    // }])
    if (params.limit && !params.page) {
      return this.http.get(`${BASE_URL}/indicator/v1/indicator/rejected-list/?limit=${params.limit}`);
    }
    else if (params.limit && params.page) {
      return this.http.get(`${BASE_URL}/indicator/v1/indicator/rejected-list/${params.id}`, { params: params });
    }
    else {
      return this.http.get(`${BASE_URL}/indicator/v1/indicator/rejected-list/?limit=30`);
    }

  }

  getSPVIndicatorDetails(id) {
    // return of([
    //   {
    //     id: "5e104ece4f917828507fe6a4",
    //     projectId: "5e0c48316b56f3235b4d68e3",
    //     indicatorId: "5e0c48316b56f3235b4d68e3",

    //     values: [{
    //       id: "5e0c48316b56f3235b4d68e3",
    //       date: "11-23-2019",
    //       value: "true",
    //       status: "pending"
    //     },
    //     {
    //       id: "5e0c48316b56f3235b4d68e3",
    //       date: "11-23-2020",
    //       value: "false",
    //       status: "pending"
    //     },
    //     {
    //       id: "5e0c48316b56f3235b4d68e3",
    //       date: "11-23-2021",
    //       value: "true",
    //       status: "pending"
    //     },
    //     {
    //       id: "5e0c48316b56f3235b4d68e3",
    //       date: "11-23-2022",
    //       value: "false",
    //       status: "pending"
    //     }]
    //   }

    // ])
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/spv-detail/${id}`);
  }

  addValues(payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/monitoring-types/update`, {
      ...payload
    });
  }

  getIndicatorActivityLog(id) {
    // return of(
    //   [{
    //     'activityType': 'ACTIVITY_INDICATOR_TYPE_CHANGED',
    //     'message': 'User A changed indicator type from output to income'
    //   },
    //   {
    //     'activityType': 'ACTIVITY_INDICATOR_TYPE_CHANGED',
    //     'message': 'User A changed indicator type from output to income'
    //   },
    //   {
    //     'activityType': 'ACTIVITY_INDICATOR_TYPE_CHANGED',
    //     'message': 'User A changed indicator type from output to income'
    //   },
    //   {
    //     'activityType': 'ACTIVITY_INDICATOR_TYPE_CHANGED',
    //     'message': 'User A changed indicator type from output to income'
    //   },
    //   {
    //     'activityType': 'ACTIVITY_INDICATOR_TYPE_CHANGED',
    //     'message': 'User A changed indicator type from output to income'
    //   },
    //   {
    //     'activityType': 'ACTIVITY_INDICATOR_TYPE_CHANGED',
    //     'message': 'User A changed indicator type from output to income'
    //   }, {
    //     'activityType': 'ACTIVITY_INDICATOR_TYPE_CHANGED',
    //     'message': 'User A changed indicator type from output to income'
    //   }, {
    //     'activityType': 'ACTIVITY_INDICATOR_TYPE_CHANGED',
    //     'message': 'User A changed indicator type from output to income'
    //   },]
    // )
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/activity-log?indicatorId=${id}`);

  }
  getInnovationActivityLog(id) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/get-innovation-logs?projectId=${id}`);
  }
  getIndicatorValuesActivityLog(projId, id) {
    // return of([{
    //   'activityType': 'ACTIVITY_INDICATOR_TYPE_CHANGED',
    //   'message': 'User A updated value 24 m3.day for 17 jul 2020',
    //   'createdAt': 'Fri Dec 11 2020 14:13:31 GMT+0530 '
    // },
    // {
    //   'activityType': 'ACTIVITY_INDICATOR_TYPE_CHANGED',
    //   'message': 'User A updated value 24 m3.day for 17 jul 2020',
    //   'createdAt': 'Fri Dec 11 2020 14:13:31 GMT+0530 '
    // },
    // {
    //   'activityType': 'ACTIVITY_INDICATOR_TYPE_CHANGED',
    //   'message': 'User A updated value 24 m3.day for 17 jul 2020',
    //   'createdAt': 'Fri Dec 11 2020 14:13:31 GMT+0530 '
    // },
    // {
    //   'activityType': 'ACTIVITY_INDICATOR_TYPE_CHANGED',
    //   'message': 'User A updated value 24 m3.day for 17 jul 2020',
    //   'createdAt': 'Fri Dec 11 2020 14:13:31 GMT+0530 '
    // },
    // {
    //   'activityType': 'ACTIVITY_INDICATOR_TYPE_CHANGED',
    //   'message': 'User A updated value 24 m3.day for 17 jul 2020',
    //   'createdAt': 'Fri Dec 11 2020 14:13:31 GMT+0530 '
    // },
    // {
    //   'activityType': 'ACTIVITY_INDICATOR_TYPE_CHANGED',
    //   'message': 'User A updated value 24 m3.day for 17 jul 2020',
    //   'createdAt': 'Fri Dec 11 2020 14:13:31 GMT+0530 '
    // },]
    // )
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/${id}/activity-log?projectId=${projId}`);
  }

  removeIndicator(payload) {
    // return this.http.post(`${BASE_URL}/indicator/v1/indicator/remove/${id}`, {
    //   ...payload
    // });

    return this.http.post(`${BASE_URL}/indicator/v1/indicator/removeFromProject`, {
      ...payload
    });
  }

  getInnovationDetails(payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/innovation-framework`, { ...payload });
    // /indicator/:id/innovation-matrix?projectId=5e104ece4f917828507fe6a4&from=master/spv
  }

  updateInnovationMatrix(payload, id, quarter) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/innovation-framework/${id}/${quarter}/update`, {
      ...payload
    });
  }

  downloadExcelMaster() {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/dowload-master-list`);
  }
  downloadExcelSpv(id) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/dowload-spv-list/${id}`);
  }

  rolesandresponsibilites(payload) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/roles-responsibilities`, { params: payload });
  }

  createRolesResp(payload, isEdit) {
    if (isEdit) {
      return this.http.post(`${BASE_URL}/indicator/v1/indicator/roles-responsibilities/edit/${payload.id}`, {
        ...payload
      });
    } else {
      return this.http.post(`${BASE_URL}/indicator/v1/indicator/roles-responsibilities/create`, {
        ...payload
      });
    }
  }

  getSingleRolesResp(id) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/roles-responsibilities/${id}`);
  }

  singleProjectDataManagement(id) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/project-data/${id}`);
  }

  singleInstitutionalCoordination(id) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/inst-coordination/${id}`);
  }

  institutionalCoordination(payload) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/inst-coordination`, { params: payload });
  }

  createInstCoord(payload, isEdit) {
    if (isEdit) {
      return this.http.post(`${BASE_URL}/indicator/v1/indicator/inst-coordination/edit/${payload.id}`, {
        ...payload
      });
    } else {
      return this.http.post(`${BASE_URL}/indicator/v1/indicator/inst-coordination/create`, {
        ...payload
      });
    }

  }
  getProjdataManagement(payload) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/project-data`, { params: payload });
  }

  createProjDataManagement(payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/project-data/create`, {
      ...payload
    });
  }

  editProjDataManagement(payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/project-data/edit/${payload.id}`, {
      ...payload
    });
  }

  getPhysicalProgress(payload) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/physical-progress`, { params: payload });
    // return this.http.get(`${BASE_URL}/indicator/v1/indicator/physical-progress/list`);
  }

  createPhysicalProgress(payload, isEdit) {
    if (isEdit) {
      return this.http.post(`${BASE_URL}/indicator/v1/indicator/physical-progress/edit/${payload.id}`, {
        ...payload
      });
    } else {
      return this.http.post(`${BASE_URL}/indicator/v1/indicator/physical-progress/create`, {
        ...payload
      });
    }
  }

  getFinancailProgress(payload) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/financial-progress`, { params: payload });

  }
  createFinancialProgress(payload, isEdit) {
    if (isEdit) {
      return this.http.post(`${BASE_URL}/indicator/v1/indicator/financial-progress/edit/${payload.id}`, {
        ...payload
      });
    } else {
      return this.http.post(`${BASE_URL}/indicator/v1/indicator/financial-progress/create`, {
        ...payload
      });
    }
  }

  singlePhysicalProgress(id) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/physical-progress/${id}`);
  }

  singleFinancialProgress(id) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/financial-progress/${id}`);
  }

  getMajorEventIndicators(payload) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/major-events/list/${payload.projectId}`, { params: payload });
  }
  updateMajorEventIndicators(payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/major-events/create-edit`, {
      ...payload
    });
  }
  getQuarters(type) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/getQuarters`, { params: { 'type': type } });
  }

  getMonitoringTypes(payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/monitoring-types`, {
      ...payload
    });
  }
  createCapacityBuilding(payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/capacity-building/create`, {
      ...payload
    });
  }

  editCapacityBuilding(payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/capacity-building/edit/${payload.id}`, {
      ...payload
    });
  }

  getCapacityBuilding(payload) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/capacity-building?projectId=${payload.projectId}&quarter=${payload.quarter}`);
  }

  getCapacityBuildingDetail(id) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/capacity-building/${id}`);
  }

  createPeerExchange(payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/peer-exchange/create`, {
      ...payload
    });
  }

  editPeerExchange(payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/peer-exchange/edit/${payload.id}`, {
      ...payload
    });
  }

  getPeerExchange(payload) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/peer-exchange?projectId=${payload.projectId}&quarter=${payload.quarter}`);
  }

  getPeerExchangeDetail(id) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/peer-exchange/${id}`);
  }

  createCommunicationOutreach(payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/communication-outreach/create`, {
      ...payload
    });
  }

  editCommunicationOutreach(payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/communication-outreach/edit/${payload.id}`, {
      ...payload
    });
  }

  getCircumstances(payload) {
    // return of([{
    //   'id': '5e104ece4f917828507fe6a4',
    //   'event': 'e1',
    //   'projectId': '5e104ece4f917828507fe6a4',
    //   'nature': '',
    //   'fundamentalCause': '',
    //   'consequences': '',
    //   'action': '',
    // },
    // {
    //   'id': '5e104ece4f917828507fe6a4',
    //   'event': 'e2',
    //   'projectId': '5e104ece4f917828507fe6a4',
    //   'nature': '',
    //   'fundamentalCause': '',
    //   'consequences': '',
    //   'action': ''
    // },
    // ]);

    return this.http.get(`${BASE_URL}/indicator/v1/indicator/circumstances`, { params: payload });
  }

  createCircumstances(payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/circumstances/${payload.id}/update`, {
      ...payload
    });
  }

  getSingleCircumstances(id) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/circumstances/${id}`);
  }
  getCommunicationOutreach(payload) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/communication-outreach?projectId=${payload.projectId}&quarter=${payload.quarter}`);
  }

  getCommunicationOutreachDetail(id) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/communication-outreach/${id}`);
  }

  getQuestionsJson() {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/project-data/questions-json`);
  }

  sendApproval(payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/send-for-approval/${payload.id}`, {
      ...payload
    });
  }
  updateOtherObservation(payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/other-observations/update`, {
      ...payload
    });
  }

  getOtherObservations(payload) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/other-observations`, { params: payload });
  }

  approveTable(payload, isApproved) {
    if (isApproved) {
      return this.http.post(`${BASE_URL}/indicator/v1/indicator/approve/${payload.id}`, {
        ...payload
      });
    } else {
      return this.http.post(`${BASE_URL}/indicator/v1/indicator/reject/${payload.id}`, {
        ...payload
      });
    }
  }

  reopenTable(payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/reopen/${payload.id}`, {
      ...payload
    });
  }
  getPLf1(id) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/get-PLF-table/${id}`);
  }
  getPLf2(id) {
    return this.http.get(`${BASE_URL}/indicator/v1/indicator/get-PLF-table-two/${id}`);
  }

  getOverViewDetails(payload, isBiannual) {
    if (isBiannual) {
      return this.http.post(`${BASE_URL}/indicator/v1/indicator/overview-biannual`, {
        ...payload
      });
    } else{
      return this.http.post(`${BASE_URL}/indicator/v1/indicator/overview`, {
        ...payload
      });
    }
  }

  reLoadTable(payload) {
    let obj = payload;
    let obj1 = {};
    obj1['projectId'] = obj['id'];
    obj1['quarter'] = obj['quarter'];
    if (obj['type'] == 'project-data') {
      this.store.dispatch(new GetProjectDataManagement(obj1))
    } else if (obj['type'] == 'inst-coordination') {
      this.store.dispatch(new GetInstitutionalCoordination(obj1))
    } else if (obj['type'] == 'financial-progress') {
      this.store.dispatch(new GetFinancialProgress(obj1))
    } else if (obj['type'] == 'physical-progress') {
      this.store.dispatch(new GetPhysicalProgress(obj1))
    } else if (obj['type'] == 'roles-responsibilities') {
      this.store.dispatch(new RolesAndResponsibilites(obj1))
    } else if (obj['type'] == 'major-events') {
      this.store.dispatch(new GetMajorEventsIndicators(obj1))

      this.store.dispatch(new GetCircumstancesActions(obj1))
    } else if (obj['type'] == 'capacity-building') {
      this.store.dispatch(new GetCapacityBuilding(obj1))
    } else if (obj['type'] == 'communication-outreach') {
      this.store.dispatch(new GetCommunicationOutreach(obj1))
    } else if (obj['type'] == 'peer-exchange') {
      this.store.dispatch(new GetPeerExchange(obj1))
    } else {
      obj1['monitoringTypes'] = [obj['type']];;
      this.store.dispatch(new GetMonitoringTypes(obj1));
    }
  }

  bulkIndicatorUpload(payload) {
    return this.http.post(`${BASE_URL}/indicator/v1/indicator/bulk-create`, payload);

  }
}
