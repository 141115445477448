import { Injectable } from '@angular/core';
import { API } from '../../../../services/Api.service';
import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    TemplateActionTypes,
    EditTemplate,
    TemplateEditSuccess,
    GetTemplates,
    TemplateSuccess,
    GetTemplateByIdSuccess,
    GetTemplateById,
    GetReplacementPatterns,
    GetReplacementPatternsSuccess,
    AddReplacementPattern,
    GetReplacementPatternDetails,
    GetReplacementPatternDetailsSuccess,
    EditReplacementPattern,
    DeleteReplacementPattern,
    TestEmailTemplate,
    TestEmailTemplateSuccess
} from './templates.actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { ErrorAction } from '../../../../login/store/auth.actions';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';

@Injectable()
export class TemplateEffects {

    @Effect()
    getTemplates$ = this.action$.pipe(
        ofType(TemplateActionTypes.GET_TEMPLATE),
        switchMap((action: GetTemplates) => {
            return this.api.getTemplates().pipe(
                map((response: any) => {
                    return new TemplateSuccess(response)
                }),
                catchError(err => of({ type: '' }))
            );
        })
    );

    @Effect()
    getTemplateById$ = this.action$.pipe(ofType(TemplateActionTypes.GET_TEMPLATE_BY_ID),
        switchMap((action: GetTemplateById) => {
            return this.api.getTemplateById(action.id).pipe(
                map((response: any) => {
                    return new GetTemplateByIdSuccess(response)
                }),
                catchError(err => of({ type: '' }))
            );
        })
    );

    @Effect()
    editTemplate$ = this.action$.pipe(
        ofType(TemplateActionTypes.EDIT_TEMPLATE),
        switchMap((action: EditTemplate) => {
            return this.api.editTemplate(action.id, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success('Template updated successfully')
                    this.router.navigateByUrl('/home/templates/template-list');
                    return new TemplateEditSuccess(response)
                }),
                catchError(err => {
                    //this.notificationService.error(err.error.errors[0].error);
                    return of(new ErrorAction(err));
                })
            );
        })
    );

    @Effect()
    getReplacementPatterns$ = this.action$.pipe(
        ofType(TemplateActionTypes.GET_REPLACEMENT_PATTERNS),
        switchMap((action: GetReplacementPatterns) => {
            return this.api.getReplacementPatterns(action.params).pipe(
                map((response: any) => {
                    return new GetReplacementPatternsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    addReplacementPattern$ = this.action$.pipe(
        ofType(TemplateActionTypes.ADD_REPLACEMENT_PATTERN),
        switchMap((action: AddReplacementPattern) => {
            return this.api.addReplacementPattern(action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success("Replacement pattern added successfully");
                    return new GetReplacementPatterns();
                }),
                catchError((err) => {
                    // this.notificationService.error("Error occurred while adding replacement pattern.");
                    return of(new ErrorAction(err));
                })
            )
        })
    )

    @Effect()
    getReplacementPatternDetails$ = this.action$.pipe(
        ofType(TemplateActionTypes.GET_REPLACEMENT_PATTERN_DETAILS),
        switchMap((action: GetReplacementPatternDetails) => {
            return this.api.getReplacementPatternDetails(action.patternId).pipe(
                map((response: any) => {
                    return new GetReplacementPatternDetailsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    editReplacementPattern$ = this.action$.pipe(
        ofType(TemplateActionTypes.EDIT_REPLACEMENT_PATTERN),
        switchMap((action: EditReplacementPattern) => {
            return this.api.editReplacementPattern(action.patternId, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success("Replacement pattern updated successfully");
                    return new GetReplacementPatterns();
                }),
                catchError((err) => {
                    // this.notificationService.error("Error occurred while updating replacement pattern");
                    return of(new ErrorAction(err));
                })
            )
        })
    )

    @Effect()
    deleteReplacementPattern$ = this.action$.pipe(
        ofType(TemplateActionTypes.DELETE_REPLACEMENT_PATTERN),
        switchMap((action: DeleteReplacementPattern) => {
            return this.api.deleteReplacementPattern(action.patternId).pipe(
                map((response: any) => {
                    this.notificationService.success("Replacement pattern deleted successfully");
                    return new GetReplacementPatterns();
                }),
                catchError((err) => {
                    // this.notificationService.error("Error occurred while deleting replacement pattern");
                    return of(new ErrorAction(err));
                })
            )
        })
    )

    @Effect()
    testEmailId$ = this.action$.pipe(ofType(TemplateActionTypes.TEST_EMAIL_TEMPLATE),
        switchMap((action: TestEmailTemplate) => {
            return this.api.testEmailTemplate(action.id, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message)
                    return new TestEmailTemplateSuccess(response)
                }),
                catchError(err => of({ type: '' }))
            );
        })
    );

    constructor(
        private api: API,
        private action$: Actions,
        private notificationService: NotificationsService,
        private router: Router,
        private store: Store<AppState>
    ) { }
}
