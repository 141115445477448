import { Action } from '@ngrx/store';
import { ProfileActionTypes, ProfileSuccess, GetProfileImageSuccess, ChangePhoneSuccess, GetUserDetailsByAdminSuccess, ChangeEmailSuccess, ChangePasswordOtpVerifySuccess } from './profile.actions';

export interface Profile {
    profile: {};
    image: any;
    changePhoneRes: any;
    changeEmailRes: any;
    userDetail: any;
    changePasswordRes: any;
}

const intiState = {
    profile: {},
    image: '',
    changePhoneRes: {},
    changeEmailRes: {},
    userDetail: {},
    changePasswordRes: {},
};

export function reducer(state: Profile = intiState, action: Action): Profile {
    switch (action.type) {
        case ProfileActionTypes.GET_PROFILE_SUCCESS:
            return Object.assign({}, state, { profile: (action as ProfileSuccess).profile });
        case ProfileActionTypes.GET_PROFILE_IMAGE_SUCCESS:
            return Object.assign({}, state, { image: (action as GetProfileImageSuccess).image });
        case ProfileActionTypes.CHANGE_PHONE_SUCCESS:
            return Object.assign({}, state, { changePhoneRes: (action as ChangePhoneSuccess).response });
        case ProfileActionTypes.GET_USER_DETAILS_SUCCESS:
            return Object.assign({}, state, { userDetail: (action as GetUserDetailsByAdminSuccess).details });
        case ProfileActionTypes.CHANGE_EMAIL_SUCCESS:
            return Object.assign({}, state, { changeEmailRes: (action as ChangeEmailSuccess).response });
        case ProfileActionTypes.CHANGE_PASSWORD_OTP_VERIFY_SUCCESS:
                return Object.assign({}, state, { changePasswordRes: (action as ChangePasswordOtpVerifySuccess).response });
        case ProfileActionTypes.CLEAR_CHANGE_PSWD_RESPONSE:
                return Object.assign({}, state, {changePasswordRes: {}});
        default:
            return state;
    }
}
