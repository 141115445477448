import { Action } from '@ngrx/store';

export enum PrivateMemberGroupActionTypes {
    GET_PRIVATE_MEMBER_GROUPS = '[PrivateMembersGroup] Get Private Members Groups',
    GET_PRIVATE_MEMBER_GROUPS_SUCCESS = '[PrivateMembersGroup]  Get Private Members Success',
    ADD_PRIVATE_MEMBER_TO_GROUP = '[Add Private Member] Add Private Member To Group',
    GET_PRIVATE_MEMBER_GROUP_DETAILS = '[Get Private Member] Get Private Member Group details',
    DELETE_PRIVATE_MEMBER_GROUP = '[Delete Private Member Group] Delete private member group',
    GET_PRIVATE_MEMBER_GROUP_DETAILS_SUCCESS = '[Get private member group details success]',
    UPDATE_PRIVATE_MEMBER_GROUP = '[Update Private member details]',
    REMOVE_USER_FROM_PRIVATE_MEMBER_GROUP = '[Remove members from private list]',
    ADD_PRIVATE_MEMBER_TO_GROUP_SUCCESS = '[Add Private Member Success]',


    CREATE_PRIVATE_MEMBER_GROUP = '[CreatePrivateMemberGroup] Create Private Member Group',
    CREATE_PRIVATE_MEMBER_GROUP_SUCCESS = '[CreatePrivateMemberGroupSuccess] Create Private Member Group Success',

    Clear_DATA_FOR_USER_ADD = '[clear data for added user]',
    CLEAR_PRIVATE_MEMBERS_IN_PRIVATE_GROUP ='[clear data in private group]'

}


export class CreatePrivateMemberGroup implements Action {
    readonly type: string = PrivateMemberGroupActionTypes.CREATE_PRIVATE_MEMBER_GROUP;
    constructor(public payload: any) { }
}

export class CreatePrivateMemberGroupSuccess implements Action {
    readonly type: string = PrivateMemberGroupActionTypes.CREATE_PRIVATE_MEMBER_GROUP_SUCCESS;
    constructor(public response: any) { }
}


export class AddPrivateMemberToGroup implements Action {
    readonly type: string = PrivateMemberGroupActionTypes.ADD_PRIVATE_MEMBER_TO_GROUP;
    constructor(public groupId: string, public payload: any) { }
}

export class AddPrivateMemberToGroupSuccess implements Action {
    readonly type: string = PrivateMemberGroupActionTypes.ADD_PRIVATE_MEMBER_TO_GROUP_SUCCESS;
    constructor(public response: any) { }
}



export class GetPrivateMembersGroups implements Action {
    readonly type: string = PrivateMemberGroupActionTypes.GET_PRIVATE_MEMBER_GROUPS;
    constructor() { }
}

export class GetPrivateMembersGroupSuccess implements Action {
    readonly type: string = PrivateMemberGroupActionTypes.GET_PRIVATE_MEMBER_GROUPS_SUCCESS;
    constructor(public privateMembers: any) { }
}



export class DeletePrivateMemberGroup implements Action {
    readonly type: string = PrivateMemberGroupActionTypes.DELETE_PRIVATE_MEMBER_GROUP;
    constructor(public groupId: string) { }
}

export class GetPrivateMemberGroupDetails implements Action {
    readonly type: string = PrivateMemberGroupActionTypes.GET_PRIVATE_MEMBER_GROUP_DETAILS;
    constructor(public groupId: any) { }
}

export class GetPrivateMemberGroupDetailsSuccess implements Action {
    readonly type: string = PrivateMemberGroupActionTypes.GET_PRIVATE_MEMBER_GROUP_DETAILS_SUCCESS;
    constructor(public groupDetails: any) { }
}

export class UpdatePrivateMemberGroup implements Action {
    readonly type: string = PrivateMemberGroupActionTypes.UPDATE_PRIVATE_MEMBER_GROUP;
    constructor(public groupId: string, public payload: any) { }
}


export class RemoveUserFromPrivateMemberGroup implements Action {
    readonly type: string = PrivateMemberGroupActionTypes.REMOVE_USER_FROM_PRIVATE_MEMBER_GROUP;
    constructor(public groupId: string, public userId: string) { }
}

export class ClearDataForCreateGroup implements Action {
    readonly type: string = PrivateMemberGroupActionTypes.Clear_DATA_FOR_USER_ADD;
    constructor() { }
}
export class ClearDataForUserAddInPrivateGroup implements Action {
    readonly type : string =PrivateMemberGroupActionTypes.CLEAR_PRIVATE_MEMBERS_IN_PRIVATE_GROUP;
    constructor() {}
}
