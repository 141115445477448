
import { Directive, HostListener, ElementRef } from '@angular/core';
import { NgControl } from '@angular/forms';

@Directive({
  selector: '[appOnlyforNumbercharacter]'
})
export class OnlyforcharacterNumberDirective {

  constructor(private el: ElementRef,private control : NgControl) {}

  @HostListener('keypress', ['$event']) onKeyPress(event: KeyboardEvent): void {
    const inputValue = this.el.nativeElement.value;
    const key = event.key;

    // Allow only alphabetic characters and spaces
    const allowedChars = /^[a-zA-Z0-9 ]+$/;
    // /^[a-zA-Z0-9 ]+$/
    if (!allowedChars.test(key)) {
      event.preventDefault();
    }
  }

  @HostListener('paste', ['$event']) blockPaste(event: KeyboardEvent) {
    this.validateFields(event);
  }

  validateFields(event) {
    setTimeout(() => {
      // this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^ A-Za-z0-9._@-]/g, '').replace(/\s/g, '');
      this.el.nativeElement.value = this.el.nativeElement.value.replace(/[^ A-Za-z0-9]/g, '');
      this.control.control.setValue(this.el.nativeElement.value);
      event.preventDefault();

    }, 100)
  }
}