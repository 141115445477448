import {
  GetEnityAndProjectSuccess,
  GetEnityListSuccess,
  GetProjectListByIdSuccess,
  GetProjectListSuccess,
  ProcurementActionTypes,
  addBudgetSuccess,
  addClouserInformationSuccess,
  addCommentsSuccess,
  addMilestonesSuccess,
  addProcureDocumentSuccess,
  addProcurementUnitSuccess,
  addedMemberListSuccess,
  approvalForDocumentProcureDocumentSuccess,
  approvalForSubmitProcureMilestoneSuccess,
  approveRejectFileSuccess,
  commentsListSuccess,
  createAwardSuccess,
  createPacketSuccess,
  createTenderSuccess,
  deleteAwardSuccess,
  deleteBudgetSuccess,
  deleteMilestonesSuccess,
  deletePacketSuccess,
  deleteProcureDocumentSuccess,
  deleteProcurementUnitSuccess,
  deleteTenderSuccess,
  fetchContractActivityListSuccess,
  generateProcurementIdSuccess,
  getClouserDocumentSuccess,
  getMilestonesSuccess,
  getPhysicalpercentageSuccess,
  getProcureDocumentListSuccess,
  getProcurementListSuccess,
  getProcurementPaymentSuccess,
  memberListSuccess,
  pullAllAwardSuccess,
  pullAllPacketSuccess,
  pullAllTenderSuccess,
  submitPhysicalpercentageSuccess,
  updateAwardSuccess,
  updateBudgetSuccess,
  updateClouserInformationSuccess,
  updateMilestonesSuccess,
  updatePacketSuccess,
  updateProcureDocumentSuccess,
  updateProcurementUnitSuccess,
  updateTenderSuccess,
} from "./procurement.action";
import { Action } from "@ngrx/store";
export interface ProcumentManagement {
  entityList: Array<any>;
  entityAndProjectList: Array<any>;
  addProcurementResponse: {};
  updateProcurementResponse: {};
  deleteProcurementResponse: {};
  documentList: Array<any>;
  addProcurementDocumentResponse: {};
  updateProcurementDocumentResponse: {};
  deleteProcuremenDocumenttResponse: {};
  approvalProDoc: {};
  memberList: Array<any>;
  AddedmemberList: Array<any>;
  approvalProMilestone: {};
  updateclouserResponse: {};
  addclouserResponse: {};
  addbudget: {};
  updatebudget: {};
  approveRejectResponse: {};
  BudgetList: Array<any>;
  closerDocumentList: Array<any>;
  projectList: Array<any>;
  projectbyEntList: {};
  procurementDetails: {};
  contactActivityList: {};
  deletebudget: {};
  milestoneList: Array<any>;
  procurementPaymentList: Array<any>;
  procurementList: Array<any>;
  addcomments: {};
  commentsList: Array<any>;
  getPhysicalResponse: {};
  submitPhysicalResponse: {};
  addMilestonesResponse: {},
  updateMilestoneResponse: {},
  deleteMilestonesResponse: {},
  createPacketApiResponse: {},
  deletePacketApiResponse:{},
  updatePacketApiResponse: {},
  pullpacketApiResponse: {},
  createTenderApiResponse: {},
  pulltenderApiResponse: {},
  updateTenderApiResponse: {},
  deleteTenderApiResponse:{},
  createAwardApiResponse: {},
  updateAwardApiResponse: {},
  deleteAwardApiResponse:{},
  pullAwardApiResponse:{}
}

const intiState = {
  entityList: [],
  entityAndProjectList: [],
  addProcurementResponse: {},
  updateProcurementResponse: {},
  deleteProcurementResponse: {},
  documentList: [],
  addProcurementDocumentResponse: {},
  updateProcurementDocumentResponse: {},
  deleteProcuremenDocumenttResponse: {},
  approvalProDoc: {},
  memberList: [],
  AddedmemberList: [],
  approvalProMilestone: {},
  deletebudget: {},
  addbudget: {},
  updatebudget: {},
  approveRejectResponse: {},
  updateclouserResponse: {},
  addclouserResponse: {},
  projectList: [],
  projectbyEntList: {},
  procurementDetails: {},
  contactActivityList: {},
  BudgetList: [],
  closerDocumentList: [],
  milestoneList: [],
  procurementPaymentList: [],
  procurementList: [],
  addcomments: {},
  commentsList: [],
  getPhysicalResponse: {},
  submitPhysicalResponse: {},
  addMilestonesResponse: {},
  updateMilestoneResponse: {},
  deleteMilestonesResponse: {},
  createPacketApiResponse: {},
  deletePacketApiResponse:{},
  updatePacketApiResponse: {},
  pullpacketApiResponse: {},
  createTenderApiResponse: {},
  pulltenderApiResponse: {},
  updateTenderApiResponse: {},
  deleteTenderApiResponse:{},
  createAwardApiResponse: {},
  updateAwardApiResponse: {},
  deleteAwardApiResponse:{},
  pullAwardApiResponse:{}
};

export function reducer(
  state: ProcumentManagement = intiState,
  action: Action
): ProcumentManagement {
  switch (action.type) {
    case ProcurementActionTypes.GET_ENTITY_LIST_SUCCESS:
      return Object.assign({}, state, {
        entityList: (action as GetEnityListSuccess).response.data,
      });
    case ProcurementActionTypes.GET_ENTITY_AND_PROJECT_SUCCESS:
      return Object.assign({}, state, {
        entityAndProjectList: (action as GetEnityAndProjectSuccess).response
          .data,
      });
    case ProcurementActionTypes.ADD_PROCUREMENT_UNIT_SUCCESS:
      return Object.assign({}, state, {
        addProcurementResponse: (action as addProcurementUnitSuccess).response,
      });
    case ProcurementActionTypes.UPDATE_PROCUREMENT_UNIT_SUCCESS:
      return Object.assign({}, state, {
        updateProcurementResponse: (action as updateProcurementUnitSuccess)
          .response,
      });
    case ProcurementActionTypes.DELETE_PROCUREMENT_UNIT_SUCCESS:
      return Object.assign({}, state, {
        deleteProcurementResponse: (action as deleteProcurementUnitSuccess)
          .response,
      });
    case ProcurementActionTypes.GET_PROCUREMENT_DOCUMENT_LIST_SUCCESS:
      return Object.assign({}, state, {
        documentList: (action as getProcureDocumentListSuccess).response.data,
      });
    case ProcurementActionTypes.APPROVE_FOR_DOCUMENT_PROCUREMENT_DOCUMENT_SUCCESS:
      return Object.assign({}, state, {
        approvalProDoc: (action as approvalForDocumentProcureDocumentSuccess)
          .response,
      });
    case ProcurementActionTypes.SUBMIT_FOR_APPROVE_PROCUREMENT_MILESTONE_SUCCESS:
      return Object.assign({}, state, {
        approvalProMilestone: (
          action as approvalForSubmitProcureMilestoneSuccess
        ).response,
      });
    case ProcurementActionTypes.PROCUREMENT_MEMBERLIST_SUCCESS:
      return Object.assign({}, state, {
        memberList: (action as memberListSuccess).response.data,
      });
    case ProcurementActionTypes.ADDED_MEMBER_SUCCESS:
      return Object.assign({}, state, {
        AddedmemberList: (action as addedMemberListSuccess).response.data,
      });

    case ProcurementActionTypes.GET_PROJECT_LIST_SUCCESS:
      return Object.assign({}, state, {
        projectList: (action as GetProjectListSuccess).response.data,
      });
    case ProcurementActionTypes.GET_PROJECT_LIST_BY_ENT_ID_SUCCESS:
      return Object.assign({}, state, {
        projectbyEntList: (action as GetProjectListByIdSuccess).response.data,
      });
    case ProcurementActionTypes.GENERATE_PROCUREMENT_ID_SUCCESS:
      return Object.assign({}, state, {
        procurementDetails: (action as generateProcurementIdSuccess).response
          .data,
      });
    case ProcurementActionTypes.FETCH_CONTRACT_ACTIVITY_LIST_SUCCESS:
      return Object.assign({}, state, {
        contactActivityList: (action as fetchContractActivityListSuccess)
          .response.data,
      });

    case ProcurementActionTypes.GET_BUDGET_SUCCESS:
      return Object.assign({}, state, {
        BudgetList: (action as GetEnityListSuccess).response.data,
      });
    case ProcurementActionTypes.GET_CLOUSER_DOCUMENT_SUCCESS:
      return Object.assign({}, state, {
        closerDocumentList: (action as getClouserDocumentSuccess).response.data,
      });
    // case ProcurementActionTypes.GET_DOCUMENT_SUCCESS:
    //   console.log('kkkkkkkkkkkkkkkkkkkkk' , (action as getDocumentSuccess).response )
    //   return Object.assign({}, state, {

    //     document: (action as getDocumentSuccess).response,
    //   });
    case ProcurementActionTypes.GET_MILESTONES_SUCCESS:
      return Object.assign({}, state, {
        milestoneList: (action as getMilestonesSuccess).response.data,
      });
    case ProcurementActionTypes.GET_PROCUREMENT_PAYMENT_SUCCESS:
      return Object.assign({}, state, {
        procurementPaymentList: (action as getProcurementPaymentSuccess)
          .response.data,
      });
    case ProcurementActionTypes.GET_PROCUREMENT_LIST_SUCCESS:
      return Object.assign({}, state, {
        procurementList: (action as getProcurementListSuccess).response.data,
      });
    case ProcurementActionTypes.DELETE_BUDGET_SUCCESS:
      return Object.assign({}, state, {
        deletebudget: (action as deleteBudgetSuccess).response,
      });
    case ProcurementActionTypes.ADD_BUDGET_SUCCESS:
      return Object.assign({}, state, {
        addbudget: (action as addBudgetSuccess).response,
      });
    case ProcurementActionTypes.UPDATE_BUDGET_SUCCESS:
      return Object.assign({}, state, {
        updatebudget: (action as updateBudgetSuccess).response,
      });
    case ProcurementActionTypes.APPROVE_REJECT_SUCCESS:
      return Object.assign({}, state, {
        approveRejectResponse: (action as approveRejectFileSuccess).response,
      });
    case ProcurementActionTypes.ADD_CLOUSER_INFORMATION_SUCCESS:
      return Object.assign({}, state, {
        addclouserResponse: (action as addClouserInformationSuccess).response,
      });
    case ProcurementActionTypes.UPDATE_CLOUSER_INFORMATION_SUCCESS:
      return Object.assign({}, state, {
        updateclouserResponse: (action as updateClouserInformationSuccess)
          .response,
      });

    case ProcurementActionTypes.ADD_COMMENTS_SUCCESS:
      return Object.assign({}, state, {
        addcomments: (action as addCommentsSuccess).response,
      });
    case ProcurementActionTypes.GET_COMMENTS_LIST_SUCCESS:
      return Object.assign({}, state, {
        commentsList: (action as commentsListSuccess).response.comments,
      });
    case ProcurementActionTypes.GET_PHYSICAL_PERCENTAGE_SUCCESS:
      return Object.assign({}, state, {
        getPhysicalResponse: (action as getPhysicalpercentageSuccess).response
          .data,
      });
    case ProcurementActionTypes.SUBMIT_PHYSICAL_PERCENTAGE_SUCCESS:
      return Object.assign({}, state, {
        submitPhysicalResponse: (action as submitPhysicalpercentageSuccess)
          .response.data,
      });

    case ProcurementActionTypes.ADD_PROCUREMENT_DOCUMENT_SUCCESS:
      return Object.assign({}, state, {
        addProcurementDocumentResponse: (action as addProcureDocumentSuccess)
          .response,
      });
    case ProcurementActionTypes.UPDATE_PROCUREMENT_DOCUMENT_SUCCESS:
      return Object.assign({}, state, {
        updateProcurementDocumentResponse: (
          action as updateProcureDocumentSuccess
        ).response,
      });
    case ProcurementActionTypes.DELETE_PROCUREMENT_DOCUMENT_SUCCESS:
      return Object.assign({}, state, {
        deleteProcuremenDocumenttResponse: (
          action as deleteProcureDocumentSuccess
        ).response,
      });


    case ProcurementActionTypes.ADD_MILESTONES_SUCCESS:
      return Object.assign({}, state, {
        addMilestonesResponse: (action as addMilestonesSuccess)
          .response,
      });
    case ProcurementActionTypes.UPDATE_MILESTONES_SUCCESS:
      return Object.assign({}, state, {
        updateMilestoneResponse: (action as updateMilestonesSuccess).response,
      });
    case ProcurementActionTypes.DELETE_MILESTONES_SUCCESS:
      return Object.assign({}, state, {
        deleteMilestonesResponse: (
          action as deleteMilestonesSuccess
        ).response,
      });

    case ProcurementActionTypes.CREATE_SINGLE_PACKET_SUCCESS:
      return Object.assign({}, state, {
        createPacketApiResponse: (
          action as createPacketSuccess
        ).response,
      });

      case ProcurementActionTypes.DELETE_SINGLE_PACKET_SUCCESS:
        return Object.assign({}, state, {
          deletePacketApiResponse: (
            action as deletePacketSuccess
          ).response,
        });

    case ProcurementActionTypes.UPDATE_SINGLE_PACKET_SUCCESS:
      return Object.assign({}, state, {
        updatePacketApiResponse: (
          action as updatePacketSuccess
        ).response,
      });

    case ProcurementActionTypes.PULL_ALL_PACKET_SUCCESS:
      return Object.assign({}, state, {
        pullpacketApiResponse: (
          action as pullAllPacketSuccess
        ).response,
      });

    case ProcurementActionTypes.CLEAR_ACTION:
      return Object.assign({}, state, {
        createPacketApiResponse: {},
        updatePacketApiResponse: {}
      });

    case ProcurementActionTypes.CREATE_TENDER_SUCCESS:
      return Object.assign({}, state, {
        createTenderApiResponse: (
          action as createTenderSuccess
        ).response,
      });

    case ProcurementActionTypes.CLEAR_TENDER_ACTION:
      return Object.assign({}, state, {
        createTenderApiResponse: {},
        updateTenderApiResponse: {},
        deleteTenderApiResponse:{}
      });


    case ProcurementActionTypes.PULL_ALL_TENDER_SUCCESS:
      return Object.assign({}, state, {
        pulltenderApiResponse: (
          action as pullAllTenderSuccess
        ).response,
      });


    case ProcurementActionTypes.UPDATE_TENDER_SUCCESS:
      return Object.assign({}, state, {
        updateTenderApiResponse: (
          action as updateTenderSuccess
        ).response,
      });

      case ProcurementActionTypes.DELETE_TENDER_SUCCESS:
        return Object.assign({}, state, {
          deleteTenderApiResponse: (
            action as deleteTenderSuccess
          ).response,
        });




        case ProcurementActionTypes.CREATE_AWARD_SUCCESS:
      return Object.assign({}, state, {
        createAwardApiResponse: (
          action as createAwardSuccess
        ).response,
      });

    case ProcurementActionTypes.CLEAR_AWARD_ACTION:
      return Object.assign({}, state, {
        createAwardApiResponse: {},
        updateAwardApiResponse: {},
        deleteAwardApiResponse:{}
      });


    case ProcurementActionTypes.PULL_ALL_AWARD_SUCCESS:
      return Object.assign({}, state, {
        pullAwardApiResponse: (
          action as pullAllAwardSuccess
        ).response,
      });


    case ProcurementActionTypes.UPDATE_AWARD_SUCCESS:
      return Object.assign({}, state, {
        updateAwardApiResponse: (
          action as updateAwardSuccess
        ).response,
      });

      case ProcurementActionTypes.DELETE_AWARD_SUCCESS:
        return Object.assign({}, state, {
          deleteAwardApiResponse: (
            action as deleteAwardSuccess
          ).response,
        });



    default:
      return state;
  }
}

