import { CreateEventComponent } from "./../features/home/events/create-event/create-event.component";
import { Injectable } from "@angular/core";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { environment } from "../../environments/environment";
import { of } from "rxjs";
import { query } from "@angular/animations";
export const BASE_URL = environment.host;
export const BASE_URL_IMPORT = environment.download_template;

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
const EXCEL_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable()
export class API {
  constructor(private http: HttpClient) { }

  login(payload) {
    return this.http.post(`${BASE_URL}/user/email/login`, payload);
  }

  getUserDetails() {
    return this.http.get(`${BASE_URL}/user/me`);
  }

  getUserRoles() {
    return this.http.get(`${BASE_URL}/user/me/role`);
  }

  getRegisterData(token, payload) {
    return this.http.post(`${BASE_URL}/user/register/${token}`, payload);
  }

  getUserCapabilities() {
    return this.http.get(`${BASE_URL}/user/me/capabilities`);
  }

  getUsers(payload) {
    payload['cities_1_flag']=true
    return this.http.get(`${BASE_URL}/user/list`, { params: payload });
  }

  getUserById(Id) {
    return this.http.get(`${BASE_URL}/user/detail/${Id}`);
  }

  getuserImage(Id) {
    return this.http.get(`${BASE_URL}/user/getImage/${Id}`);
  }

  updateUser(userId, payload) {
    return this.http.post(`${BASE_URL}/user/edit/` + userId, payload);
  }

  getRoles() {
    return this.http.get(`${BASE_URL}/role/list`);
  }

  getProjectCodes() {
    return this.http.get(`${BASE_URL}/project/city/code/list`);
  }

  inviteUser(payload) {
    return this.http.post(`${BASE_URL}/user/create`, payload);
  }

  inviteBulkUser(payload) {
    return this.http.post(`${BASE_URL}/user/bulk-invite`, payload);
  }

  resendInvite(payload) {
    return this.http.get(
      `${BASE_URL}/user/invite/resend/${payload.role}/${payload.id}`
    );
  }

  addRolesToUser(userId, payload) {
    return this.http.post(`${BASE_URL}/user/grants/add/${userId}`, payload);
  }

  changeUserStatus(userId) {
    return this.http.put(`${BASE_URL}/user/status/${userId}`, {});
  }

  downloadDocument(id, version) {
    return this.http.get(`${BASE_URL}/docs/${id}/versions/${version}/file`);
  }

  downloadBulkTaskExcel() {
    return this.http.get(`${BASE_URL}/project/download-bulk-task-excel`);
  }

  getMyDocuments(params) {
    return this.http.get(`${BASE_URL}/docs/me`, { params: params });
  }

  getApprovalData() {
    return this.http.get(`${BASE_URL}/docs/approvals`);
  }
  getAsOfVersion(Id) {
    return this.http.get(`${BASE_URL}/docs/${Id}/versions`);
  }

  getAllDocuments(params) {
    return this.http.get(`${BASE_URL}/docs/list`, { params: params });
  }

  getDocumentDetail(documentId, allCmp) {
    return this.http.get(`${BASE_URL}/docs/${documentId}?allCmp=${allCmp}`);
  }

  getDocUpdatedDetails(docId) {
    return this.http.get(`${BASE_URL}/docs/${docId}/view-details`);
  }

  getProjectThemes() {
    return this.http.get(`${BASE_URL}/project/theme/list`);
  }

  uploadNewDocument(payload) {
    return this.http.post(`${BASE_URL}/docs/create/new`, payload);
  }

  submitForApproval(docId, versionId) {
    return this.http.put(`${BASE_URL}/docs/${docId}/submit`, {});
  }

  getProjects(params: any) {
    return this.http.get(`${BASE_URL}/project/list`, { params: params });
  }

  getBudgetProjects(role:any,params: any) {
    return this.http.get(`${BASE_URL}/budget/project/custom/list/budget/${role}`, { params: params });
  }

  getProjectDetail(id) {
    return this.http.get(`${BASE_URL}/project/${id}/detail`);
  }

  createTask(projectId, payload) {
    return this.http.post(`${BASE_URL}/project/${projectId}/task/add`, payload);
  }

  getTasksForProject(projectId) {
    return this.http.get(`${BASE_URL}/project/${projectId}/task-list`);
  }

  getRisksForProject(projectId) {
    return this.http.get(`${BASE_URL}/project/${projectId}/risk/list`);
  }

  getOpportunitiesForProject(projectId) {
    return this.http.get(`${BASE_URL}/project/${projectId}/opportunity/list`);
  }

  getOpportunityDetail(projectId, opportunityId) {
    return this.http.get(
      `${BASE_URL}/project/${projectId}/opportunity/${opportunityId}`
    );
  }
  // getPhases() {
  //   return this.http.get(`${BASE_URL}/phases/list`);
  // }

  getRoleMembers(role) {
    return this.http.get(`${BASE_URL}/role/user/list?role=${role}`);
  }

  // getTagList() {
  //   return this.http.get(`${BASE_URL}/project/tag/list`);
  // }

  getDocumentVersionDetail(docId, version) {
    return this.http.get(`${BASE_URL}/docs/${docId}/versions/${version}`);
  }

  getApprovalDetails(documentId) {
    return this.http.get(`${BASE_URL}/docs/approvals/${documentId}`);
  }

  publishDocument(docId, payload) {
    return this.http.post(`${BASE_URL}/docs/${docId}/publish`, payload);
  }

  rejectDocument(docId, versionId) {
    return this.http.post(
      `${BASE_URL}/docs/${docId}/versions/${versionId}/reject`,
      {}
    );
  }

  updateDocument(docId, docVersion, payload) {
    return this.http.post(`${BASE_URL}/docs/${docId}`, {
      ...payload,
    });
  }
  updateDocumentDetails(payload, docId: string) {
    return this.http.post(`${BASE_URL}/docs/${docId}/new`, payload);
  }

  addNewDocumentVersion(docId, docVersion, payload) {
    return this.http.post(
      `${BASE_URL}/docs/${docId}/versions/${docVersion}/create`,
      {
        ...payload,
      }
    );
  }

  getMyFolders() {
    return this.http.get(`${BASE_URL}/docs/folder/list`);
  }

  getCitisMyFolders(params) {
    return this.http.get(`${BASE_URL}/docs/system-folder/list`, {
      params: params,
    });
  }

  getDocActivity(params) {
    return this.http.get(`${BASE_URL}/activity/get-folder-logs`, {
      params: params,
    });
  }

  getFilesInMyFolder(folderId, params) {
    return this.http.get(`${BASE_URL}/docs/folder/${folderId}/list`, {
      params: params,
    });
  }

  getMyFilesAndFolders(params) {
    return this.http.get(`${BASE_URL}/docs/all/me`, { params: params });
  }

  createFolder(payload, isEdit) {
    if (isEdit) {
      return this.http.post(
        `${BASE_URL}/docs/folder/${payload.parentId}/edit`,
        payload
      );
    } else {
      return this.http.post(`${BASE_URL}/docs/folder/create`, payload);
    }
  }

  deleteFolder(folderId) {
    return this.http.delete(`${BASE_URL}/docs/folder/delete/${folderId}`);
  }

  getUserGroups() {
    return this.http.get(`${BASE_URL}/user/group/list`);
  }

  createGroup(payload) {
    return this.http.post(`${BASE_URL}/user/group/create`, payload);
  }

  addUserToGroup(groupId, users) {
    return this.http.post(`${BASE_URL}/user/group/${groupId}/member/add`, {
      users: users,
    });
  }

  updateGroup(groupId, payload) {
    return this.http.put(`${BASE_URL}/user/group/${groupId}/edit`, payload);
  }

  removeUserFromGroup(groupId, userId) {
    return this.http.post(`${BASE_URL}/user/group/${groupId}/member/remove`, {
      users: [userId],
    });
  }

  removeUserFromPrivateGroup(groupId, userId) {
    return this.http.post(
      `${BASE_URL}/private-group/${groupId}/member/remove`,
      {
        members: [userId],
      }
    );
  }

  getGroupDetails(groupId) {
    return this.http.get(`${BASE_URL}/user/group/${groupId}`);
  }

  deleteGroup(groupId) {
    return this.http.put(`${BASE_URL}/user/group/${groupId}/status`, {});
  }

  addCollaborators(documentId, payload) {
    return this.http.post(
      `${BASE_URL}/docs/${documentId}/collaborator/add`,
      payload
    );
  }

  addViewers(documentId, payload) {
    return this.http.post(`${BASE_URL}/docs/${documentId}/viewer/add`, payload);
  }

  getCollaborators(documentId) {
    return this.http.get(`${BASE_URL}/docs/${documentId}/collaborator/list`);
  }

  getViewers(documentId) {
    return this.http.get(`${BASE_URL}/docs/${documentId}/viewer/list`);
  }

  getSharedDocuments(params) {
    return this.http.get(`${BASE_URL}/docs/shared/me`, { params: params });
  }

  shareSuggestions(searchString) {
    searchString['cities_1_flag']=true
    return this.http.get(`${BASE_URL}/user/suggestion`, {
      params: searchString,
    });
  }

  shareProjectSuggestions(searchString) {
    return this.http.get(`${BASE_URL}/project/searchAllProjects`, {
      params: searchString,
    });
  }

  getSharedUserForDocument(documentId) {
    return this.http.get(`${BASE_URL}/docs/${documentId}/share/list`);
  }

  shareDocment(docId, permission, members) {
    return this.http.post(
      `${BASE_URL}/docs/${docId}/share?role=${permission}`,
      {
        users: members,
      }
    );
  }

  editDocPermissionForUser(docId, userType, userId, permission) {
    return this.http.put(
      `${BASE_URL}/docs/${docId}/share/${userType}/${userId}/edit?role=${permission}`,
      {}
    );
    // return of({});
  }

  removeUserForDoc(docId, userType, userId, permission) {
    return this.http.delete(
      `${BASE_URL}/docs/${docId}/share/${userType}/${userId}/remove?role=${permission}`
    );
    // return of({});
  }

  getUserCapabilitiesForDocument(documentId) {
    return this.http.get(`${BASE_URL}/docs/${documentId}/capabilities`);
    // return of({});
  }

  // getUserCapabilitiesForDocumentNew(documentId) {
  //   return this.http.get(`${BASE_URL}/docs/${documentId}/user-capabilities-new`);
  //   // return of({});
  // }

  getPublishedDocuments() {
    return this.http.get(`${BASE_URL}/docs/publish/list`);
  }

  unPublishDocument(docId: string) {
    return this.http.put(`${BASE_URL}/docs/${docId}/unpublish`, {});
  }

  forgotPassword(email) {
    return this.http.post(`${BASE_URL}/user/forgot`, {
      email: email,
    });
  }

  resetPassword(password, token) {
    return this.http.post(`${BASE_URL}/user/forgot/password/${token}`, {
      password: password,
    });
  }

  searchDocuments(
    searchString: string,
    params: any,
    searchCallFromAllCmp: Boolean = false
  ) {
    if (!searchCallFromAllCmp) {
      return this.http.get(
        `${BASE_URL}/docs/search/doc?search=${encodeURIComponent(
          searchString
        )}`,
        { params: params }
      );
    } else {
      return this.http.get(
        `${BASE_URL}/docs/search/doc?search=${encodeURIComponent(
          searchString
        )}&searchAllCmp=${true}`,
        { params: params }
      );
    }
  }

  getTasksList(type: string) {
    switch (type) {
      case "allTasks":
        return this.http.get(`${BASE_URL}/task/all`);
      case "assigned":
        return this.http.get(`${BASE_URL}/task/assignedToMe`);
      case "created":
        return this.http.get(`${BASE_URL}/task/createdByMe`);
    }
  }

  getSearchedTag(tag) {
    return this.http.get(`${BASE_URL}/tag/list`, { params: tag });
  }

  createNewTask(payload) {
    return this.http.post(`${BASE_URL}/task/create`, {
      ...payload,
    });
  }

  updateTaskDetails(taskId: string, payload: any) {
    return this.http.post(`${BASE_URL}/task/${taskId}/edit`, {
      ...payload,
    });
  }

  getTaskDetail(taskId: string) {
    return this.http.get(`${BASE_URL}/task/${taskId}/detail`);
  }
  addNewTag(tag) {
    return this.http.post(`${BASE_URL}/project/tag/add`, {
      ...tag,
    });
  }

  getLinkedTasksForTaskId(taskId) {
    return of([]);
  }

  unlinkTasks(payload) {
    return this.http.post(
      `${BASE_URL}/task/${payload.id}/unlink-subtask/${payload.subTaskId}`,
      payload
    );
  }

  getSubtaskForTaskId(taskId) {
    return of([]);
  }

  cancelTask(taskId) {
    return this.http.post(`${BASE_URL}/task/${taskId}/cancel`, {});
  }

  changeTaskState(taskId, action, body) {
    return this.http.post(`${BASE_URL}/task/${taskId}/next/${action}`, body);
  }

  createSubTask(taskId, obj) {
    return this.http.post(`${BASE_URL}/task/${taskId}/create-subtask`, obj);
  }
  getSearchedTaskId(id, searchString) {
    return this.http.get(`${BASE_URL}/task/${id}/link-task-suggestion`, {
      params: { search: searchString },
    });
  }

  getMailList(payload) {
    return this.http.get(`${BASE_URL}/mail/v1/list`, { params: payload });
  }

  sendNewMail(payload) {
    return this.http.post(`${BASE_URL}/mail/v1/create`, {
      ...payload,
    });
  }

  getMailById(threadId: string, replacedUserId?: string) {
    if (replacedUserId) {
      return this.http.get(
        `${BASE_URL}/mail/v1/${threadId}/detail?userId=${replacedUserId}`
      );
    } else {
      return this.http.get(`${BASE_URL}/mail/v1/${threadId}/detail`);
    }
  }

  markMailAsRead(threadId) {
    return this.http.post(`${BASE_URL}/mail/v1/${threadId}/read`, {});
  }

  deleteMail(threadId) {
    return this.http.post(`${BASE_URL}/mail/v1/${threadId}/delete`, {});
  }

  forgotPasswordVerify(payload) {
    return this.http.post(`${BASE_URL}/user/forgot/verify`, payload);
  }

  getTagList() {
    return this.http.get(`${BASE_URL}/tag/list`);
  }

  setNewPassword(password, id) {
    return this.http.post(`${BASE_URL}/user/forgot/setPassword`, {
      password: password,
      id: id,
    });
  }

  getCountryCodes() {
    return this.http.get(`${BASE_URL}/user/countryCodes`);
  }

  getTags() {
    return this.http.get(`${BASE_URL}/tag/list`);
  }

  changePassword(payload) {
    return this.http.post(`${BASE_URL}/user/changePassword`, payload);
  }

  getCapabilities() {
    return this.http.get(`${BASE_URL}/role/capabilities/list`);
  }

  getAllCapabilitiesOfRoles() {
    return this.http.get(`${BASE_URL}/role/all/capabilities/list`);
  }

  addCapability(payload) {
    return this.http.post(`${BASE_URL}/role/capability/add`, payload);
  }

  removeCapability(payload) {
    return this.http.put(`${BASE_URL}/role/capability/remove`, payload);
  }

  addRole(payload) {
    return this.http.post(`${BASE_URL}/role/add`, payload);
  }

  editRole(role, payload) {
    return this.http.put(`${BASE_URL}/role/${role}/edit`, payload);
  }

  changeEmail(payload) {
    return this.http.post(`${BASE_URL}/user/changeEmail`, payload);
  }

  changePhone(payload) {
    return this.http.post(`${BASE_URL}/user/change/mobile`, payload);
  }

  changePhoneVerify(payload) {
    return this.http.post(`${BASE_URL}/user/profile/otp/verification`, payload);
  }

  getTemplateById(id) {
    return this.http.get(`${BASE_URL}/template/getTemplate/${id}`);
  }

  getTemplates() {
    return this.http.get(`${BASE_URL}/template/list`);
  }

  editTemplate(id, payload) {
    return this.http.post(`${BASE_URL}/template/edit/${id}`, payload);
  }

  changeEmailVerify(payload) {
    return this.http.post(`${BASE_URL}/user/profile/otp/verification`, payload);
  }

  getAllMailList(obj) {
    return this.http.get(`${BASE_URL}/mail/v1/list/all`, { params: obj });
  }

  getProfileImage(id) {
    return this.http.get(`${BASE_URL}/user/getImage/${id}`);
  }

  getPillars() {
    return this.http.get(`${BASE_URL}/pillars/list`);
  }

  createPillar(pillar) {
    return this.http.post(`${BASE_URL}/pillars/create`, {
      name: pillar,
    });
  }

  editPillar(pillarId, pillarName) {
    return this.http.post(`${BASE_URL}/pillars/${pillarId}/edit`, {
      name: pillarName,
    });
  }

  getGanttChartData(projectId) {
    return this.http.get(`${BASE_URL}/project/${projectId}/gantt-chart`);
  }

  getFileById(id) {
    return this.http.get(`${BASE_URL}/docs/get-document/${id}`);
  }
  deleteDocumentInTask(taskId, documentId) {
    return this.http.post(`${BASE_URL}/task/${taskId}/edit`, {
      document: documentId,
    });
  }

  getComments(id, commentType) {
    return this.http.get(`${BASE_URL}/comments/list/${id}?type=${commentType}`);
  }

  postComment(payload) {
    return this.http.post(`${BASE_URL}/comments/add`, payload);
  }

  moveFile(docID, payload) {
    return this.http.put(`${BASE_URL}/docs/moveTo/folder/${docID}`, payload);
  }

  getAllRoles() {
    return this.http.get(`${BASE_URL}/role/list`);
  }

  createProject(payload) {
    return this.http.post(`${BASE_URL}/project/create`, payload);
  }

  getProjectMembersWithRoles(projectID) {
    return this.http.get(`${BASE_URL}/project/${projectID}/get-member-roles`);
  }

  createTaskForProject(projectId, payload) {
    return this.http.post(
      `${BASE_URL}/project/${projectId}/create-task`,
      payload
    );
  }

  createRisksForProject(projectId, payload) {
    return this.http.post(
      `${BASE_URL}/project/${projectId}/risk/create`,
      payload
    );
  }

  editRiskForProject(projectId, riskId, payload) {
    return this.http.put(
      `${BASE_URL}/project/${projectId}/risk/${riskId}`,
      payload
    );
  }

  createOpportunityForProject(projectId, payload) {
    return this.http.post(
      `${BASE_URL}/project/${projectId}/opportunity/edit/save-all`,
      payload
    );
  }

  editOpportunityForProject(projectId, opportunityId, payload) {
    return this.http.put(
      `${BASE_URL}/project/${projectId}/opportunity/${opportunityId}`,
      payload
    );
  }

  deleteDocument(id, payload) {
    return this.http.put(`${BASE_URL}/docs/delete/${id}`, payload);
  }

  editTaskInProject(projectId, taskId, payload) {
    return this.http.post(
      `${BASE_URL}/project/${projectId}/task/${taskId}/edit-date`,
      payload
    );
  }

  getProjectMembersList(id) {
    return this.http.get(`${BASE_URL}/project/${id}/members`);
  }

  manageMembers(id, userId) {
    return this.http.get(
      `${BASE_URL}/project/${id}/manage-members/${userId}/add`
    );
  }

  searchMail(payload) {
    return this.http.get(`${BASE_URL}/mail/v1/search`, { params: payload });
  }

  searchAllMail(payload) {
    return this.http.get(`${BASE_URL}/mail/v1/search/allcmpMails?`, {
      params: payload,
    });
  }

  editProject(id, payload) {
    return this.http.post(`${BASE_URL}/project/${id}/edit`, payload);
  }

  getMailByIdForAllCmp(threadId: string) {
    return this.http.get(
      `${BASE_URL}/mail/v1/${threadId}/allcmp/detail?allCmp=true`
    );
  }
  checkViewAccessForDocs(obj) {
    return this.http.post(`${BASE_URL}/docs/user-capabilities`, obj);
  }
  checkViewAccessForDocsNew(obj) {
    return this.http.post(`${BASE_URL}/docs/user-capabilities-new`, obj);
  }

  getSteps() {
    return this.http.get(`${BASE_URL}/steps/list`);
  }

  createStep(step) {
    return this.http.post(`${BASE_URL}/steps/create`, {
      name: step,
    });
  }

  editStep(stepId, stepName) {
    return this.http.post(`${BASE_URL}/steps/${stepId}/edit`, {
      name: stepName,
    });
  }
  addViewAccessForDocument(obj) {
    return this.http.post(`${BASE_URL}/docs/add-user-capabilities`, obj);
  }

  addSuggestedTags(docId: string, payload) {
    return this.http.post(
      `${BASE_URL}/docs/${docId}/suggest/tags/addOrRemove`,
      payload
    );
  }
  getMyAllDocuments(params) {
    return this.http.get(`${BASE_URL}/docs/all/list`, { params: params });
  }

  getActivityLog(taskId) {
    return this.http.get(`${BASE_URL}/activity/get-task-logs`, {
      params: { taskId: taskId },
    });
  }
  getRegistrationOtp(id, payload) {
    return this.http.post(`${BASE_URL}/user/send/mobileOtp/${id}`, payload);
  }

  verifyRegistrationOtp(id, payload) {
    return this.http.post(`${BASE_URL}/user/mobile/verify/${id}`, payload);
  }

  resendRegistrationOtp(id, payload) {
    return this.http.post(`${BASE_URL}/user/resend/mobileOtp/${id}`, payload);
  }

  getFinancialSteps(projectId,apiType?) {
    let url;
    if(apiType == 'new'){
      url =`${BASE_URL}/project/${projectId}/financial-info/forSelectedProject/new`
    }else{
      url =`${BASE_URL}/project/${projectId}/financial-info/new`
    }
    // return this.http.get(`${BASE_URL}/project/${projectId}/financial-info`);
    return this.http.get(url);
  }

  addFundReleaseItem(projectId, payload) {
    // return this.http.post(`${BASE_URL}/project/${projectId}/add-released-fund`, payload);
    return this.http.post(
      `${BASE_URL}/project/${projectId}/add-funds`,
      payload
    );
  }

  addFundUtilisedItem(projectId, payload) {
    // return this.http.post(`${BASE_URL}/project/${projectId}/add-utilized-fund`, payload);
    return this.http.post(
      `${BASE_URL}/project/${projectId}/add-funds`,
      payload
    );
  }

  deleteInstallmentItem(projectId, payload, type) {
    if (type === "released") {
      return this.http.put(
        `${BASE_URL}/project/${projectId}/delete-released-fund/new`,
        payload
      );
    } else {
      return this.http.put(
        `${BASE_URL}/project/${projectId}/delete-utilized-fund/new`,
        payload
      );
    }
  }

  approveTag(docId, payload) {
    return this.http.post(`${BASE_URL}/docs/${docId}/approve/tags`, payload);
  }

  rejectTag(docId, payload) {
    return this.http.post(`${BASE_URL}/docs/${docId}/reject/tags`, payload);
  }

  deleteMessage(id) {
    return this.http.post(`${BASE_URL}/mail/v1/delete/${id}`, {});
  }

  getDocumentActivityLog(documentId) {
    return this.http.get(`${BASE_URL}/activity/get-document-logs`, {
      params: { docId: documentId },
    });
  }

  /* Configurations */
  getConfigurations() {
    return this.http.get(`${BASE_URL}/constants/list`);
  }

  updateConfigurations(configObj) {
    return this.http.post(`${BASE_URL}/constants/update`, configObj);
  }

  addMessageTags(parentId, payload) {
    return this.http.post(`${BASE_URL}/mail/v1/${parentId}/tag`, payload);
  }

  mergeTags(payload) {
    return this.http.post(`${BASE_URL}/tag/merge`, payload);
  }

  createPrivateMemberGroup(payload) {
    return this.http.post(`${BASE_URL}/private-group/create`, payload);
  }

  getPrivateUserGroups() {
    return this.http.get(`${BASE_URL}/private-group/list`);
  }

  deletePrivateMemberGroup(groupId, payload) {
    return this.http.put(
      `${BASE_URL}/private-group/${groupId}/status`,
      payload
    );
  }

  getPrivateMemberGroupDetails(groupId) {
    return this.http.get(`${BASE_URL}/private-group/${groupId}/details`);
  }

  addPrivateMembersToGroup(groupId, payload) {
    return this.http.post(`${BASE_URL}/private-group/${groupId}/edit`, payload);
  }
  deleteSuggetsedTag(docId, payload) {
    return this.http.post(
      `${BASE_URL}/docs/${docId}/delete/suggested/tags`,
      payload
    );
  }

  /* Manage Notifications */
  getNotifications() {
    return this.http.get(`${BASE_URL}/notifications/list`);
  }

  updateNotificationSetting(payload) {
    return this.http.post(`${BASE_URL}/notifications/update`, payload);
  }

  updatePrivateMemberGroup(groupId, payload) {
    return this.http.post(`${BASE_URL}/private-group/${groupId}/edit`, payload);
  }

  updateProjectCost(projectId, payload) {
    return this.http.put(
      `${BASE_URL}/project/${projectId}/project-cost`,
      payload
    );
  }

  updateCitiisGrants(projectId, payload) {
    return this.http.put(
      `${BASE_URL}/project/${projectId}/citiis-grants`,
      payload
    );
  }

  getAllComplainces(projectId) {
    return this.http.get(`${BASE_URL}/project/${projectId}/compliance/list`);
  }

  createCompliance(projectId, payload) {
    return this.http.post(
      `${BASE_URL}/project/${projectId}/compliance/create`,
      payload
    );
  }

  getAllCmpDocuments(params) {
    return this.http.get(`${BASE_URL}/docs/allcmp/list`, { params: params });
  }

  getPhases() {
    return this.http.get(`${BASE_URL}/phases/list`);
  }

  getFavList() {
    return this.http.get(`${BASE_URL}/event/fav/list`);
  }

  createPhase(phase, colorCode) {
    return this.http.post(`${BASE_URL}/phases/create`, {
      phaseName: phase,
      colorCode: colorCode,
    });
  }

  editPhase(phaseId, phaseName, colorCode) {
    return this.http.put(`${BASE_URL}/phases/edit/${phaseId}`, {
      phaseName: phaseName,
      colorCode: colorCode,
    });
  }

  getRiskDetails(projectId, riskId) {
    return this.http.get(`${BASE_URL}/project/${projectId}/risk/${riskId}`);
  }

  bulkInvite(projectId, payload) {
    return this.http.post(
      `${BASE_URL}/project/${projectId}/upload-task-excel`,
      payload
    );
  }

  validateRegisterLink(token: string) {
    return this.http.get(`${BASE_URL}/user/validation/${token}`);
  }

  getAllTaskFilters() {
    return this.http.get(`${BASE_URL}/task/all-filters`);
  }

  getCreatedByMeTasksFilters() {
    return this.http.get(`${BASE_URL}/task/createdByMe-filters`);
  }

  getAssignedToMeTasksFilters() {
    return this.http.get(`${BASE_URL}/task/assignedToMe-filter`);
  }

  getAllCmpTaskFilters() {
    return this.http.get(`${BASE_URL}/task/all-cmp-tasks-filters`);
  }

  getFilteredTasks(payload, params) {
    // TODO : need to correct sorting
    /*return this.http.post(`${BASE_URL}/task/filter?
     page=${params.page}&limit=${params.limit}&sortBy=${params.sortOn}
     &ascending=${params.sortOrder}&filterFrom=${params.filterFrom}&search=${params.searchedVal}`, payload);*/
    return this.http.post(
      `${BASE_URL}/task/filter?page=${params.page}&limit=${params.limit}&filterFrom=${params.filterFrom}&sortBy=${params.sortBy}&ascending=${params.ascending}&search=${params.searchedVal}`,
      payload
    );
  }

  sendContentToMail(parentId: string) {
    return this.http.post(`${BASE_URL}/mail/v1/${parentId}/sendTo/email`, {});
  }

  markPublicDocument(docId: string) {
    return this.http.put(`${BASE_URL}/docs/${docId}/mark-as-public`, {});
  }

  /* Email templates: Replacement pattern */
  getReplacementPatterns(params: any) {
    return this.http.get(`${BASE_URL}/pattern/list`, { params: params });
  }

  addReplacementPattern(payload: any) {
    return this.http.post(`${BASE_URL}/pattern/create`, payload);
  }

  getReplacementPatternDetails(patternId: string) {
    return this.http.get(`${BASE_URL}/pattern/${patternId}/details`);
  }

  editReplacementPattern(patternId: string, payload: any) {
    return this.http.post(`${BASE_URL}/pattern/${patternId}/edit`, payload);
  }

  deleteReplacementPattern(patternId: string) {
    return this.http.put(`${BASE_URL}/pattern/${patternId}/delete`, {});
  }

  requestDocAccess(id: string) {
    return this.http.post(`${BASE_URL}/docs/${id}/requests/raise`, {});
  }
  editUserByAdmin(id, payload) {
    return this.http.post(`${BASE_URL}/user/${id}/admin/profile/edit`, payload);
  }

  /* Replace document */
  getUnpublishedDocuments(params: any) {
    return this.http.get(
      `${BASE_URL}/docs/search?publish=false&filter=${params.filter}&page=${params.page}&limit=${params.limit}`
    );
  }

  replaceDocument(params: any, payload: any) {
    return this.http.post(
      `${BASE_URL}/docs/${params.id}/replace/${params.replaceDocId}`,
      payload
    );
  }

  acceptAccessRequest(id: string) {
    return this.http.post(`${BASE_URL}/docs/${id}/requests/accept`, {});
  }

  denyAccessRequest(id: string) {
    return this.http.post(`${BASE_URL}/docs/${id}/requests/denied`, {});
  }

  getAccessRequestsList(id: string) {
    return this.http.get(`${BASE_URL}/docs/${id}/requests/list`);
  }

  getIpAddress() {
    return this.http.get("http://api.ipify.org/?format=json");
  }

  moveToRootFolder(folderId, payload) {
    return this.http.put(
      `${BASE_URL}/docs/removeFrom/folder/${folderId}`,
      payload
    );
  }
  searchTasks(
    type: string,
    searchString: string,
    page: number = 1,
    limit: number = 50
  ) {
    let searchType;
    switch (type) {
      case "allCMPTasks":
        searchType = "allCmp";
        break;
      case "assignedToMe":
        searchType = "owner";
        break;
      case "createdByMe":
        searchType = "creator";
        break;
      case "allTasks":
        searchType = "all";
        break;
    }
    return this.http.get(
      `${BASE_URL}/task/search?search=${encodeURIComponent(
        searchString
      )}&type=${searchType}&page=${page}&limit=${limit}`
    );
    /* switch (type) {
      case 'allTasks':
        return this.http.get(`${BASE_URL}/task/all`, { params: { 'search': searchString } });
      case 'assignedToMe':
        return this.http.get(`${BASE_URL}/task/assignedToMe`, { params: { 'search': searchString } })
      case 'createdByMe':
        return this.http.get(`${BASE_URL}/task/createdByMe`, { params: { 'search': searchString } })
    } */
  }

  getAllCmpTask() {
    return this.http.get(`${BASE_URL}/task/all-cmp-tasks`);
  }

  addEditInstallment(projectId, payload, installmentType,apitype) {
    /* if (installmentType.includes('fundsReleased')) {
      const relasePayload = {
        'funds': payload
      };
    //   return this.http.post(`${BASE_URL}/project/${projectId}/add-released-installment`, relasePayload);
        return this.http.post(`${BASE_URL}/project/${projectId}/add-installments/new`, relasePayload);
    } else {
      const utilisedPayload = {
        'funds': payload
      };
      return this.http.post(`${BASE_URL}/project/${projectId}/add-installments/new`, utilisedPayload);
    } */

    const fundsPayload = {
      funds: payload,
    };
    let url: any
    if (apitype == 'old') {
      url = `${BASE_URL}/project/${projectId}/add-installments/new`
    } else {
      url = `${BASE_URL}/project/${projectId}/add-installments/forSelectedProject/new`
    }
    return this.http.post(
      url,
      fundsPayload
    );
  }

  /* Risks */
  addRisksForProjects(projectId, payload) {
    return this.http.post(
      `${BASE_URL}/project/${projectId}/risk/edit/save-all`,
      payload
    );
  }

  getRiskHistory(projectId, riskId) {
    return this.http.get(
      `${BASE_URL}/project/${projectId}/risk/${riskId}/edit-history/list`,
      {}
    );
  }

  getRecaptchaPermission() {
    return this.http.get(`${BASE_URL}/user/check/recaptcha`);
  }

  getInstallments(projectId, installmentType) {
    return this.http.get(`${BASE_URL}/project/${projectId}/installments`);
  }

  postActivityLogForDocument(docId: string) {
    return this.http.get(`${BASE_URL}/docs/${docId}/cancel`);
  }

  getUserProfileLogById(Id) {
    return this.http.get(
      `${BASE_URL}/activity/get-profile-edit-logs/?userId=${Id}`
    );
  }

  getUserLoginLogById(Id) {
    return this.http.get(`${BASE_URL}/user/login/history/${Id}`);
  }

  unPublicDocument(docId: string) {
    return this.http.post(`${BASE_URL}/docs/${docId}/mark-as-unpublic`, {});
  }

  updateFundReleaseItem(projectId, payload) {
    return this.http.put(
      `${BASE_URL}/project/${projectId}/update-released-fund/new`,
      payload
    );
    // return this.http.post(`${BASE_URL}/project/${projectId}/add-funds`, payload);
  }

  updateFundUtiliseItem(projectId, payload) {
    return this.http.put(
      `${BASE_URL}/project/${projectId}/update-utilized-fund/new`,
      payload
    );
    // return this.http.post(`${BASE_URL}/project/${projectId}/add-funds`, payload);
  }

  /* verfify otp at endorse */
  getEndorseOtp() {
    return this.http.get(`${BASE_URL}/user/task-endorse/send-otp`);
  }

  verifyEndorseOtp(taskId, payload) {
    return this.http.post(
      `${BASE_URL}/user/task-endorse/verify-otp?task=${taskId}`,
      payload
    );
  }

  changePasswordByAdmin(userId, payload) {
    return this.http.post(
      `${BASE_URL}/user/setPassword/admin/${userId}`,
      payload
    );
  }

  /* Open comments */
  getMyOpenComment(projectId) {
    return this.http.get(`${BASE_URL}/project/${projectId}/my-open-comments`);
  }

  saveOpenComment(projectId, payload) {
    return this.http.post(
      `${BASE_URL}/project/${projectId}/add-open-comment`,
      payload
    );
  }

  getCommentsHistoryLog(projectId) {
    return this.http.get(
      `${BASE_URL}/project/${projectId}/my-open-comment-history`
    );
  }

  getCommentedUsers(projectId) {
    return this.http.get(
      `${BASE_URL}/project/${projectId}/view-commented-users`
    );
  }

  GetOtherUsersOpenComment(projectId, userId) {
    return this.http.get(
      `${BASE_URL}/project/${projectId}/view-all-open-comments?userId=${userId}`
    );
  }

  // otp api for change-password when logged as admin
  getOtp(payload, id) {
    return this.http.post(`${BASE_URL}/user/setPassword/admin/${id}`, payload);
  }
  verifyOtp(payload, id) {
    return this.http.post(`${BASE_URL}/user/otp/verify/admin/${id}`, payload);
  }

  /* change password otp verification */
  changePasswordOtpVerify(payload) {
    return this.http.post(`${BASE_URL}/user/otp/verify/user`, payload);
  }

  deleteSuggetsedTaskTag(taskID, payload) {
    return this.http.post(
      `${BASE_URL}/task/${taskID}/delete/suggested/tags`,
      payload
    );
  }

  addSuggestedTaskTags(taskID: string, payload) {
    return this.http.post(
      `${BASE_URL}/task/${taskID}/suggest/tags/addOrRemove`,
      payload
    );
  }

  approveTaskTag(taskID, payload) {
    return this.http.post(`${BASE_URL}/task/${taskID}/approve/tags`, payload);
  }

  rejectTaskTag(taskID, payload) {
    return this.http.post(`${BASE_URL}/task/${taskID}/reject/tags`, payload);
  }

  getTagsLogs() {
    return this.http.get(`${BASE_URL}/activity/get-merged-tags-logs`);
  }

  editOverallProgressDetails(projectId, payload) {
    return this.http.post(
      `${BASE_URL}/project/${projectId}/edit-tripartite`,
      payload
    );
  }

  getOverallProjectDetails(projectId) {
    return of({});
  }

  getProjectActivityLog(projectId) {
    return this.http.get(
      `${BASE_URL}/activity/get-Project-logs/?projectId=${projectId}`
    );
  }
  addUserCapablitiesForDocNew(payload) {
    return this.http.post(
      `${BASE_URL}/docs/add-user-capabilities-new`,
      payload
    );
  }

  misComplianceEdit(projectId, payload) {
    return this.http.get(`${BASE_URL}/project/${projectId}/miscompliance/edit`);
  }

  renameFolder(folderObj, payload) {
    return this.http.put(
      `${BASE_URL}/docs/folder/rename/${folderObj.folderId}`,
      payload
    );
  }

  /* user change email and phone */
  userChangeEmail(payload, userId) {
    return this.http.post(
      `${BASE_URL}/user/changeEmail/admin/${userId}`,
      payload
    );
  }

  userChangePhone(payload, userId) {
    return this.http.post(
      `${BASE_URL}/user/changeMobile/admin/${userId}`,
      payload
    );
  }

  getReplacedUsersInbox() {
    return this.http.get(`${BASE_URL}/mail/v1/possible-inboxes`);
  }
  changeUser(oldUserId, payload) {
    return this.http.post(`${BASE_URL}/user/${oldUserId}/replace/`, payload);
  }

  editTaskStepPillar(taskId, payload) {
    return this.http.post(`${BASE_URL}/task/${taskId}/edit`, payload);
  }

  editMiscompliance(projectId, payload) {
    return this.http.post(
      `${BASE_URL}/project/${projectId}/miscompliance/edit`,
      payload
    );
  }

  fileDocLog(documentId) {
    return this.http.get(`${BASE_URL}/docs/${documentId}/file-download-log`);
  }

  removeCoreTeamUser(projectId: string, userId: string) {
    return this.http.get(
      `${BASE_URL}/project/${projectId}/manage-members/${userId}/remove`
    );
  }

  replaceCoreTeamMember(
    projectId: string,
    oldUserId: string,
    newUserId: string
  ) {
    return this.http.post(
      `${BASE_URL}/project/${projectId}/manage-members/replace`,
      {
        oldUser: oldUserId,
        newUser: newUserId,
      }
    );
  }

  testEmailTemplate(id, payload) {
    return this.http.post(`${BASE_URL}/template/testTemplate/${id}`, payload);
  }

  logout(userId, token) {
    return this.http.post(`${BASE_URL}/user/email/logout?userId=${userId}`, {
      token: token,
    });
  }

  viewTaskDetails(projectId, taskId) {
    return this.http.get(
      `${BASE_URL}/project/${projectId}/task/${taskId}/view`
    );
  }

  getWebNotifications(payload) {
    return this.http.get(`${BASE_URL}/web-notifications/list`, {
      params: payload,
    });
  }

  updateWebNotificationSeen(notificationId) {
    return this.http.put(
      `${BASE_URL}/web-notifications/${notificationId}/mark-as-read`,
      {}
    );
  }

  getOpportunityHistory(projectId, opportunityId) {
    return this.http.get(
      `${BASE_URL}/project/${projectId}/opportunity/${opportunityId}/edit-history/list`
    );
  }

  getProjectPhases(projectId) {
    return this.http.get(`${BASE_URL}/project/${projectId}/list-phases`);
  }

  addProjectPhase(projectId, payload) {
    return this.http.post(
      `${BASE_URL}/project/${projectId}/add-phases`,
      payload
    );
  }

  getProjectStates() {
    return this.http.get(`${BASE_URL}/project/get-states`);
  }

  getDashboardInfo() {
    return this.http.get(`${BASE_URL}/project/dashboard-info`);
  }

  addRemoveCapability(payload) {
    return this.http.post(`${BASE_URL}/role/capabilities/update`, payload);
  }

  addRemoveNotificationSetting(payload) {
    return this.http.post(`${BASE_URL}/notifications/update/new`, payload);
  }

  createBulkComplianceTask(projectId, payload) {
    return this.http.post(
      `${BASE_URL}/project/${projectId}/compliance/bulk-upload`,
      payload
    );
  }

  resetUser(userId, payload) {
    return this.http.post(`${BASE_URL}/user/new/email/${userId}`, payload);
  }

  editTaskDueDate(payload, id) {
    return this.http.post(`${BASE_URL}/task/${id}/edit-end-date`, payload);
  }
  getNewNotifications() {
    return this.http.get(`${BASE_URL}/notifications/list/new`);
  }

  resetAllCapabilitiesOfRoles() {
    return this.http.get(`${BASE_URL}/role/capabilities/reset`);
  }

  getInactiveReplacedusers(taskId) {
    return this.http.get(
      `${BASE_URL}/task/${taskId}/check-inactive-user-exists`
    );
  }

  getCapabilityHistoryLog() {
    return this.http.get(`${BASE_URL}/activity/get-role-logs`);
  }

  getFinancialInfo() {
    return this.http.get(`${BASE_URL}/dashboard/project-financial-information`);
  }

  getProgramTaskStatus() {
    return this.http.get(`${BASE_URL}/dashboard/project-task-information`);
  }

  getL1Indicators(level, payload) {
    return this.http.get(
      `${BASE_URL}/indicator/v1/indicator/L1-indicators/${level}`,
      { params: payload }
    );
  }

  getComponentIndicators(component, payload) {
    return this.http.get(
      `${BASE_URL}/indicator/v1/indicator/component-based/${component}`,
      { params: payload }
    );
  }

  getInnovation(payload) {
    return this.http.get(
      `${BASE_URL}/indicator/v1/indicator/get-innovation-frameworks/${payload.quarter}`
    );
  }

  addValues(payload) {
    return this.http.post(
      `${BASE_URL}/indicator/v1/indicator/update-monitoring-values`,
      {
        ...payload,
      }
    );
  }

  getCities(id, payload) {
    return this.http.get(
      `${BASE_URL}/indicator/v1/indicator/get-projects-by-indicator/${id}`,
      { params: payload }
    );
  }

  getValues(id, payload) {
    // console.log(payload)
    return this.http.post(
      `${BASE_URL}/indicator/v1/indicator/get-values-by-projects/${id}`,
      payload
    );
  }

  getSectoralIndicators(payload) {
    return this.http.get(
      `${BASE_URL}/indicator/v1/indicator/sectoral-indicator/list`,
      { params: payload }
    );
  }

  bulkTasksUpload(payload, projectId) {
    return this.http.post(
      `${BASE_URL}/project/${projectId}/upload-task-excel`,
      payload
    );
  }

  getESINdicatorsList(projectId) {
    return this.http.get(`${BASE_URL}/project/${projectId}/es-task-list`);
  }

  getFolderDetails(folderId) {
    return this.http.get(`${BASE_URL}/docs/folder/${folderId}/details`);
  }

  createEvent(payload) {
    return this.http.post(`${BASE_URL}/event/create`, payload);
  }

  getFavoriteList() {
    return this.http.get(`${BASE_URL}/event/fav/list`);
  }

  getFavoriteMemberList(payload) {
    return this.http.post(`${BASE_URL}/fav/member/by_fav_list`, payload);
  }

  createFavoriteGroupInvitee(payload) {
    return this.http.post(`${BASE_URL}/event/fav/create`, payload);
  }

  getMemberbyIds(payload) {
    return this.http.post(`${BASE_URL}/event/fav/member/by_fav_list`, payload);
  }

  insert_invitees_To_Event(payload, Id) {
    return this.http.patch(`${BASE_URL}/event/${Id}/add/update/invitees`, payload);
  }

  deleteInvitee(eventId,inviteId){
    return this.http.delete(`${BASE_URL}/event/${eventId}/delete/invitee/${inviteId}`);  
  }

  getEventList(payload, type) {
    return this.http.get(`${BASE_URL}/event/list/${type}?`,{ params: payload });
  }


  insert_end_appr_To_Event(payload, Id) {
    console.log('hhh', payload, Id)
    return this.http.post(`${BASE_URL}/event/${Id}/add/approvers`, payload);
  }

  addnewField(payload, Id) {
    return this.http.post(`${BASE_URL}/event/${Id}/add/field`, payload);
  }

  getTemplatefield(Id) {
    return this.http.get(`${BASE_URL}/event/${Id}/get/field`);
  }

  getEventDetailsByIdapi(eventId) {
    return this.http.get(`${BASE_URL}/event/detail/${eventId}`);
  }

  updateInviteeMemberList(payload, Id) {
    return this.http.put(`${BASE_URL}/event/fav/${Id}/update`, payload);
  }

  deleteMemberGroup(Id) {
    return this.http.delete(`${BASE_URL}/event/fav/${Id}/delete`);
  }
  emailTemplateRegUpdate(payload, Id) {
    return this.http.put(`${BASE_URL}/event/template/update/${Id}`, payload);
  }

  emailfeedbackRegUpdate(payload, Id){
    return this.http.put(`${BASE_URL}/event/${Id}/update/feedbackField`, payload);
  }

  submitforapproval(Id) {
    return this.http.put(`${BASE_URL}/event/isSubmitForApproval/${Id}`, {});
  }


  forApprovalList(payload) {
    return this.http.get(`${BASE_URL}/event/approval/list`, { params: payload });
  }

  updateEventDetails(payload) {
    return this.http.put(`${BASE_URL}/event/update`, payload);
  }

  eventApproveAndReject(Id, action) {
    return this.http.get(`${BASE_URL}/event/${action}/approver/${Id}`);
  }

  eventEndroseAndReject(Id, action) {
    return this.http.get(`${BASE_URL}/event/${action}/endorser/${Id}`);
  }

  eventsendMails(Id) {
    return this.http.get(`${BASE_URL}/event/sendInviteeInvitation/${Id}`);
  }

  forEndorsementList(payload) {
    return this.http.get(`${BASE_URL}/event/endorsement/list`, { params: payload });
  }

  filterEndorsementList(payload) {
    return this.http.get(`${BASE_URL}/event/endorsement/list-filters`, { params: payload });
  }


  filterApprovalList(payload) {
    return this.http.get(`${BASE_URL}/event/approval/list-filters`, { params: payload });
  }


  submitRsvp(payload, Id) {
    return this.http.post(`${BASE_URL}/event/${Id}/submitRsvp`, payload);
  }


  addFeedbackTemplateField(payload, Id) {
    return this.http.post(`${BASE_URL}/event/${Id}/add/feedbackField`, payload);
  }

  getFeedbackTemplateFields(Id) {
    return this.http.get(`${BASE_URL}/event/${Id}/get/feedbackField`);
  }

  updateEmailTemplateField(payload,Id) {
    return this.http.patch(`${BASE_URL}/event/template/update/field/${Id}`,payload);
  }

  updatefeedbackFieldTemplateField(payload,Id) {
    return this.http.patch(`${BASE_URL}/event/template/update/feedbackField/${Id}`,payload);
  }

  submitRsvpfeedback(payload, Id) {
    return this.http.post(`${BASE_URL}/event/${Id}/submitFeedback`, payload);
  }

  addComments(payload){
    return this.http.post(`${BASE_URL}/event/add/comment`,payload)
  }

  getCommentlist(id){
    return this.http.get(`${BASE_URL}/event/${id}/commentList`)
  }

  feedbackDetails(id){
    return this.http.get(`${BASE_URL}/event/feedback-details/${id}`)
  }

  submitEventReport(payload, Id){
    return this.http.post(`${BASE_URL}/event/submit-event-report/${Id}`, payload);
  }


  submit_end_appr_To_endorser(payload, Id) {
    return this.http.post(`${BASE_URL}/event/report/add-approver-endoser/${Id}`, payload);
  }

  submit_image_video(payload, Id) {
    return this.http.post(`${BASE_URL}/event/gallery/add/${Id}`, payload);
  }
  remove_image_video(eventid,fileid) {
    return this.http.get(`${BASE_URL}/event/gallery/remove/${eventid}/${fileid}`)
  }
  getEventReport(id){
    return this.http.get(`${BASE_URL}/event/report/get-report/${id}`)
  }

  reportApproverList(payload){
    return this.http.get(`${BASE_URL}/event/report/approval/list`, { params: payload })
  }

  reportEndorserList(payload){
    return this.http.get(`${BASE_URL}/event/report/endorse/list`, { params: payload })
  }


  updateEventReport(payload, Id){
    return this.http.post(`${BASE_URL}/event/report/update/${Id}`, payload);
  }

  deleteApprovarandEndorser(payload,id){
    console.log(payload,id)
    const options = {
      headers: new HttpHeaders({
        'Content-Type': 'application/json'
      }),
      body: payload
    }
    return this.http.delete(`${BASE_URL}/event/${id}/delete/approver`,options);
  }

  getListRsvp(payload,id){
    return this.http.get(`${BASE_URL}/event/rsvp/${id}`, { params: payload })
  }

  getfeedbackList(payload, type) {
    return this.http.get(`${BASE_URL}/event/list/${type}?`,{ params: payload });
  }

  getCalenderList(payload, type) {
    return this.http.get(`${BASE_URL}/event/calander/${type}?`,{ params: payload });
  }

  getEventReportList(payload, type) {
    // /${type}?`,{ params: payload }
    return this.http.get(`${BASE_URL}/event/reports/list/${type}?`,{params: payload });
  }

  eventReportApproveAndReject(Id, action) {
    return this.http.get(`${BASE_URL}/event/report/${action}/approver/${Id}`);
  }

  eventReportEndroseAndReject(Id, action) {
    return this.http.get(`${BASE_URL}/event/report/${action}/endorser/${Id}`);
  }

  submitForApprovalReport(docId) {
    return this.http.put(`${BASE_URL}/event/report/isSubmitForApproval/${docId}`, {});
  }

  getfeedbackListList(payload, type){
    return this.http.get(`${BASE_URL}/event/feedback-list/${type}?`,{params: payload });
  }

  cencelevent(id){
    return this.http.put(`${BASE_URL}/event/cancel/${id}`, {});
  }

  getLogList(id){
    return this.http.get(`${BASE_URL}/event/${id}/logList`);
  }

  public exportLedger(json: any, excelFileName: string,eventName:any): void {

    // const worksheet1: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);

  //     // Create a worksheet for the header information
  // const headerWorksheet: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([
  //   ['Project Name', 'Event Name', 'Date of Download'],
  //   [excelFileName, eventName, this.getCurrentDate()],
  // ]);

  //   const workbook: XLSX.WorkBook = {
  //     Sheets: {
  //       Header: headerWorksheet,
  //       Ledger: worksheet1,  
  //     },
  //     SheetNames: ['Header', 'Ledger'],
  //   };




  //  // Add header information at the top of the worksheet
  //  XLSX.utils.sheet_add_aoa(worksheet1, [
  //   ['Project Name', 'Event Name', 'Date of Download'],
  //   [excelFileName, eventName, this.getCurrentDate()],
  // ], { origin: 'A1' });


  const worksheet1: XLSX.WorkSheet = XLSX.utils.aoa_to_sheet([
    ['Project Name', 'Event Name', 'Date of Download'],
    [excelFileName, eventName, this.getCurrentDate()],
    [],
    ['Prefix', 'Full Name', "Organization",'Email', 'Mobile'],
  ]);

  // Add the data rows
  for (const item of json) {
    XLSX.utils.sheet_add_aoa(worksheet1, [
      [item.Prefix, item.Name, item.Organization,item.Email, item.Mobile],
    ], { origin: -1 }); // Append to the end of the worksheet
  }

    const workbook: XLSX.WorkBook = {
    Sheets: {
      'Ledger': worksheet1,
    },
    SheetNames: ['Ledger'],
  };


    const excelBuffer: any = XLSX.write(workbook, {
      bookType: 'xlsx',
      type: 'array',
    });
    this.saveAsExcelFile(excelBuffer, excelFileName,eventName);
  }

  private getCurrentDate(): string {
    const currentDate = new Date();
    const currentDay = currentDate.getDate();
    const currentMonth = currentDate.getMonth() + 1;
    const currentYear = currentDate.getFullYear();
    return `${currentDay}/${currentMonth}/${currentYear}`;
  }

  private saveAsExcelFile(buffer: any, projectName: string,eventName:string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE,
    });
    var currentMonth:number = new Date().getMonth() + 1;
    FileSaver.saveAs(
      data,projectName+"_"+eventName + '_export_' + new Date().getDate()+"/"+ currentMonth +"/"+new Date().getFullYear() + EXCEL_EXTENSION
    );
  }

  changeClaimAndReassign(id,action_type){
    return this.http.get(`${BASE_URL}/event/${id}/owner/${action_type}`);
  }

  updateOwner(eventid,id){
    return this.http.patch(`${BASE_URL}/event/${eventid}/owner/update`,{
      updatedOwnerId:id
    });
  }

  eventSceduled(id){
    // console.log(id);
    
    return this.http.get(`${BASE_URL}/event/${id}/scheduled`);
  }


  donotattendapi(id,inviteeid){
    return this.http.get(`${BASE_URL}/event/${id}/update/rsvp/${inviteeid}`);
  }

  attendeelistApi(eventid,status,payload){
    return this.http.get(`${BASE_URL}/event/non-rsvp/${eventid}/${status}?`,{params: payload });
  }

  feedbackUserList(id){
    return this.http.get(`${BASE_URL}/event/feedback/user/${id}`);
  }

  addfeedbackUser(id,payload){
    return this.http.post(`${BASE_URL}/event/feedback/user/${id}/add`,payload);
  }

  editfeedbackUser(id,payload,userid){
    return this.http.patch(`${BASE_URL}/event/feedback/user/${id}/update/${userid}`,payload);
  }

  deletefeedbackUser(id,userid){
    return this.http.delete(`${BASE_URL}/event/feedback/user/${id}/delete/${userid}`);
  }

  feedbackmailsent(id){
    return this.http.get(`${BASE_URL}/event/sendRsvpFeedbackEmail/${id}`);
  }

  showQrEvent(id){
    return this.http.get(`${BASE_URL}/event/qrcode/${id}`); 
  }

  deleteApprovar(id,userid){
    console.log(id,userid);
    
    const options = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      }),
      body: {
        userId:userid
      }
    }
    return this.http.delete(`${BASE_URL}/event/${id}/delete/approver/${userid}`);
  }

  deleteEndorser(id,userid){
    console.log(id,userid);
    const options = {
      headers: new HttpHeaders({
        'Content-Type':  'application/json',
      }),
      body: {
        userId:userid
      }
    }
    return this.http.delete(`${BASE_URL}/event/${id}/delete/endorser/${userid}`);
  }

  deleteRegistration(id,fieldId){
    return this.http.delete(`${BASE_URL}/event/${id}/registeration-template/delete/field/${fieldId}`);
  }
  deleteFeedbackField(id,fieldId){
    return this.http.delete(`${BASE_URL}/event/${id}/feedback-template/delete/field/${fieldId}`); 
  }

  markAsAcomplete(id){
    return this.http.get(`${BASE_URL}/event/submit-report/mark/completed/${id}`);
  }

  eventReportApproverDelete(id,userId){
    return this.http.delete(`${BASE_URL}/event/report/${id}/delete/approver/${userId}`); 
  }

  eventReportEndorserDelete(id,userId){
    return this.http.delete(`${BASE_URL}/event/report/${id}/delete/endorser/${userId}`); 
  }

  updatepacketdetails(id,payload){
    return this.http.put(`${BASE_URL}/proc/updatePacket/${id}`,payload);
  }

  createpacketdetails(taskid,projectid,payload) {
    return this.http.post(`${BASE_URL}/proc/task/createPacket/${projectid}/${taskid}`,payload);
  }

  getProcurementProjects(role:any,params: any) {
    return this.http.get(`${BASE_URL}/budget/project/custom/list/procurement/${role}`, { params: params });
  }
}
