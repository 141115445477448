import { Component, OnInit, Inject } from '@angular/core';
import { FormBuilder, Validators, FormControl, FormGroup } from '@angular/forms';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { VerifyOtp, ResendOtp, ClearAction } from '../../store/auth.actions';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material";

@Component({
    selector: 'app-verify-otp',
    templateUrl: './verify-otp.component.html',
    styleUrls: ['./verify-otp.component.scss']
})
export class VerifyOtpComponent implements OnInit {
    verifyOtpForm: FormGroup;

    constructor(private fb: FormBuilder, public store: Store<AppState>, @Inject(MAT_DIALOG_DATA) public data: any, private dialogRef: MatDialogRef<any>, ) {
        this.verifyOtpForm = this.fb.group({
            otp: ['', [Validators.required, Validators.minLength, Validators.maxLength, Validators.pattern('^[0-9]*$')]]
        });
        this.dialogRef.disableClose = true;
    }

    ngOnInit() {

    }

    verifyOtp() {
        if (this.verifyOtpForm.invalid) {
            return false;
        }

        const payload = {
            otp: this.verifyOtpForm.value.otp,
            phone: this.data.countryCode + this.data.phoneNumber
        }
        this.store.dispatch(new ClearAction());
        this.store.dispatch(new VerifyOtp(this.data.decodedToken.id || '',payload));
        this.store.select(state => state.auth.user.verifyOtpRes).subscribe((response) => {
            if (response && Object.keys(response).length) {
                this.dialogRef.close(response);
            }
        });
    }

    resendOtp() {
        this.verifyOtpForm.reset();
        this.store.dispatch(new ResendOtp(this.data.decodedToken.id || '', { phone: this.data.countryCode + this.data.phoneNumber }));
    }

    isInputNumber(event) {
        const ch = String.fromCharCode(event.which);
        if (!(/[0-9]/.test(ch))) {
            event.preventDefault();
        }
    }

    onCloseDialog(): void {
        this.dialogRef.close();
    }
}
