import { Injectable } from '@angular/core';
import { API } from 'src/app/services/Api.service';
import { Effect, Actions, ofType, act } from '@ngrx/effects';
import {
    ProfileActionTypes,
    EditProfile,
    GetProfile,
    ProfileSuccess,
    ChangeEmail,
    ChangeEmailSuccess,
    ChangeEmailVerify,
    GetProfileImage,
    GetProfileImageSuccess,
    ChangePhone,
    ChangePhoneSuccess,
    ChangePhoneVerify,
    GetUserDetailsByAdmin,
    GetUserDetailsByAdminSuccess,
    EditUserByAdmin,
    ProfileErrorAcion,
    ProfileErrorActionComplete,
    ChangePasswordOtpVerify,
    ChangePasswordOtpVerifySuccess
} from './profile.actions';
import { switchMap, map, catchError, tap } from 'rxjs/operators';
import { of } from 'rxjs';
import { Location } from '@angular/common';
import { NotificationsService } from 'angular2-notifications';
import { Router } from '@angular/router';
import { MatDialog, MatDialogRef } from '@angular/material';
import { VerifyChangeEmailComponent } from '../verify-change-email/verify-change-email.component'
import { ChangeEmailSuccessComponent } from '../change-email-success/change-email-success.component'
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { GetUserDetails, ErrorAction } from 'src/app/login/store/auth.actions';
import { VerifyChangePhoneComponent } from '../change-phone/verify-change-phone/verify-change-phone.component'
import { LoaderHide } from 'src/app/features/loader/store/loader.actions';

@Injectable()
export class ProfileEffects {
    email: string;
    dialogRef;

    @Effect()
    getProfileDetails$ = this.action$.pipe(
        ofType(ProfileActionTypes.GET_PROFILE),
        switchMap((action: GetProfile) => {
            return this.api.getUserDetails().pipe(
                map((response: any) => {
                    return new ProfileSuccess(response)
                }),
                catchError(err => of({ type: '' }))
            );
        })
    );

    @Effect()
    getProfileImage$ = this.action$.pipe(
        ofType(ProfileActionTypes.GET_PROFILE_IMAGE),
        switchMap((action: GetProfileImage) => {
            console.log(action.id)
            return this.api.getProfileImage(action.id).pipe(
                map((response: any) => {
                    return new GetProfileImageSuccess(response)
                }),
                catchError(err => of({ type: '' }))
            );
        })
    );

    @Effect()
    updateProfile$ = this.action$.pipe(
        ofType(ProfileActionTypes.EDIT_PROFILE),
        switchMap((action: EditProfile) => {
            return this.api.updateUser(action.id, action.payload).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide());
                    if (action.payload.role) {
                        this.notificationService.success('Role updated successfully');
                    } else {
                        this.notificationService.success('Profile updated successfully');
                    }
                   
                    this.router.navigateByUrl('/home/user-management/users-list');
                    this.store.dispatch(new GetUserDetails());
                    return new ProfileSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    changeEmail$ = this.action$.pipe(
        ofType(ProfileActionTypes.CHANGE_EMAIL),
        switchMap((action: ChangeEmail) => {
            this.email = action.payload.email;
            return this.api.changeEmail(action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message);
                    if (this.dialogRef) {
                        this.dialogRef.close();
                    }
                    if (!response.bypass_otp) {
                        this.dialogRef = this.dialog.open(VerifyChangeEmailComponent, {
                            hasBackdrop: true,
                            width: '700px',
                            panelClass: 'task-detail-modal',
                            data: {
                                alertTitle: 'Enter OTP',
                                email: this.email,
                                registeredCountryCode: action.registeredCountryCode,
                                registeredPhone: action.registeredPhone,
                                password: action.payload.password
                            }
                        });
                    } else {
                        this.dialogRef = this.dialog.open(ChangeEmailSuccessComponent, {
                            hasBackdrop: true,
                            width: '700px',
                            panelClass: 'task-detail-modal'
                        });
                    }
                    return new ChangeEmailSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    );

    @Effect({ dispatch: false })
    changeEmailVerify$ = this.action$.pipe(
        ofType(ProfileActionTypes.CHANGE_EMAIL_VERIFY),
        switchMap((action: ChangeEmailVerify) => {
            return this.api.changeEmailVerify(action.payload).pipe(
                map((response: any) => {
                    // this.notificationService.success("Email updated successfully");
                    this.dialogRef.close();
                    this.dialogRef = this.dialog.open(ChangeEmailSuccessComponent, {
                        hasBackdrop: true,
                        width: '700px',
                        panelClass: 'task-detail-modal'
                    });
                }),
                catchError((error) => {
                    this.notificationService.error(error.error.errors[0].error);
                    return of(new ErrorAction(error));
                })
            )
        })
    )

    @Effect()
    changePhone$ = this.action$.pipe(
        ofType(ProfileActionTypes.CHANGE_PHONE),
        switchMap((action: ChangePhone) => {
            return this.api.changePhone(action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message);
                    if (this.dialogRef) {
                        this.dialogRef.close();
                    }
                    if (!response['bypass_otp']) {
                        this.dialogRef = this.dialog.open(VerifyChangePhoneComponent, {
                            hasBackdrop: true,
                            width: '700px',
                            panelClass: 'task-detail-modal',
                            data: {
                                alertTitle: 'Enter OTP',
                                registeredEmail: action.registeredEmail,
                                countryCode: action.payload.newCountryCode,
                                phone: action.payload.newPhone,
                                password: action.payload.password
                            }
                        });
                    } else {
                        this.store.dispatch(new GetUserDetails());
                        
                    }
                    return new ChangePhoneSuccess(response);
                }),
                catchError((error) => {
                    return of(new ErrorAction(error));
                })
            )
        })
    )

    @Effect()
    changePhoneVerify$ = this.action$.pipe(
        ofType(ProfileActionTypes.CHANGE_PHONE_VERIFY),
        switchMap((action: ChangePhoneVerify) => {
            return this.api.changePhoneVerify(action.payload).pipe(
                map((response) => {
                    this.notificationService.success("Phone number updated successfully");
                    this.dialogRef.close();
                    return new GetUserDetails();
                }),
                catchError(err => of(new ErrorAction(err)))

            )
        })
    )

    @Effect()
    getUserDetails$ = this.action$.pipe(
        ofType(ProfileActionTypes.GET_USER_DETAILS),
        switchMap((action: GetUserDetailsByAdmin) => {
            return this.api.getUserById(action.Id).pipe(
                map((response: any) => {
                    return new GetUserDetailsByAdminSuccess(response)
                }),
                catchError(err => of({ type: '' }))
            );
        })
    );

    @Effect()
    EditUserDetails$ = this.action$.pipe(
        ofType(ProfileActionTypes.EDIT_PROFILE_BY_ADMIN),
        switchMap((action: EditUserByAdmin) => {
            return this.api.editUserByAdmin(action.id, action.payload).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide());
                    this.notificationService.success(response.message);
                    return new GetUserDetailsByAdmin(action.id)
                }),
                catchError(err => of(new ProfileErrorAcion(err)))
            );
        })
    );

    @Effect()
    changePasswordOtpVerify$ = this.action$.pipe(
        ofType(ProfileActionTypes.CHANGE_PASSWORD_OTP_VERIFY),
        switchMap((action: ChangePasswordOtpVerify) => {
            return this.api.changePasswordOtpVerify(action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message);
                    return new ChangePasswordOtpVerifySuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    constructor(
        private api: API,
        private action$: Actions,
        private location: Location,
        private notificationService: NotificationsService,
        private router: Router,
        private store: Store<AppState>,
        private dialog: MatDialog,
    ) { }
}
