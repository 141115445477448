import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { StoreModule } from '@ngrx/store';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { NgxPermissionsModule } from 'ngx-permissions';
import { MAT_DECLARATIONS, MAT_PROVIDERS } from './material.module';
import { ChiplistAutoCompleteComponent } from './chiplist-autocomplete/chiplist-autocomplete.component';
import { TaskAutoComplete } from './tags-autocomplete/tags.autocomplete';
import { ProfileTooltipComponent } from './profile-tooltip/profile-tooltip.component';
import { TestComponentComponent } from './test-component/test-component.component';
import { NumbersOnlyDirective } from '../features/home/budget/directives/numbers-only.directive';
import { OnlyforcharacterDirective } from '../features/home/challenge/directive/onlyforcharacter.directive'
import {OnlyforcharacterNumberDirective} from '../features/home/budget/directives/char-num.directive'
@NgModule({
  declarations: [
    ChiplistAutoCompleteComponent,
    TaskAutoComplete,
    ProfileTooltipComponent,
    TestComponentComponent,
    NumbersOnlyDirective,
    OnlyforcharacterDirective,
    OnlyforcharacterNumberDirective
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    FormsModule,
    NgxPermissionsModule,
    ...MAT_DECLARATIONS,
  ],
  exports: [
    ChiplistAutoCompleteComponent,
    TaskAutoComplete,
    ProfileTooltipComponent,
    TestComponentComponent,
    NumbersOnlyDirective,
    OnlyforcharacterDirective,
    OnlyforcharacterNumberDirective
  ],
  entryComponents: [
  ],
  providers: [...MAT_PROVIDERS],
  bootstrap: []
})
export class SharedModule { }
