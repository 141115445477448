import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AuthGaurd } from './guards/auth.guard';


const routes: Routes = [
  // {
  //   path : '',
  //   loadChildren : './features/technology-lead/technology-lead.module#TechnologyLeadModule'
  // },
  {
    path : 'home',
    loadChildren : './features/home/home.module#HomeModule',
    canActivate: [AuthGaurd]
  },
  {
    path: "",
    redirectTo: "home",
    pathMatch: "full"
  },
  {
    path: "user/**",
    redirectTo: "user",
    pathMatch: "full"
  },
  {
    path: "user",
    loadChildren: "src/app/login/login.module#LoginModule"
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { paramsInheritanceStrategy: 'always'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
