import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Store } from '@ngrx/store';
import { AppState } from '../app.reducer';
import { environment } from 'src/environments/environment';

@Injectable()
export class ProfilePictureService {
    profilePic: any

    private pictureSource = new BehaviorSubject('');

    CurrentPicture = this.pictureSource.asObservable()

    constructor(private store: Store<AppState>) {
        this.store.select(state => state.auth).subscribe(auth => {
            if (auth.user && auth.user.details) {
                this.profilePic = `${environment.host}/user/getImage/${(auth.user.details || {})._id}`;
                this.changePicture(this.profilePic);
            }
        });
    }

    changePicture(picture: any) {
        this.pictureSource.next(picture + '?' + (new Date()).getTime())
    }
}