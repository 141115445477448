import { Action } from '@ngrx/store';

export enum NotificationsActionTypes {
    ERROR = '[auth] Error',
    GET_ALL_ROLES = '[Notifications] Get All Roles',
    GET_ALL_ROLES_SUCCESS = '[Notifications] Get All Roles Success',
    GET_NOTIFICATIONS = '[Notifications] Get Notifications',
    GET_NOTIFICATIONS_SUCCESS = '[Notifications] Get Notifications Success',
    UPDATE_NOTIFICATION_SETTING = '[Notifications] Update Notification Setting',
    UPDATE_NOTIFICATION_SETTING_SUCCESS = '[Notifications] Update Notification Setting Success',
    GET_WEB_NOTIFICATIONS = '[Web Notifications] Get All Web Notifications',
    GET_WEB_NOTIFICATIONS_SUCCESS = '[Web Notifications] Get Web Notifications Success',
    UPDATE_WEB_NOTIFICATION_SEEN = '[Web Notifications] Update notification seen status',
    UPDATE_WEB_NOTIFICATION_SEEN_SUCCESS = '[Web Notification] Update notification seen status Success',
    GET_WEB_NOTIFICATION_COUNT = '[Web Notifications] Get notification seen status',
    GET_WEB_NOTIFICATION_COUNT_SUCCESS = '[Web Notification] Get notification seen status Success',
    ADD_REMOVE_NOTIFICATION_SETTING = '[Notifications] Add Remove Notification Setting',
    ADD_REMOVE_NOTIFICATION_SETTING_SUCCESS = '[Notifications] Add Remove Notification Setting  Success',
    GET_NEW_NOTIFICATIONS = '[Notifications] Get New Notifications',
    GET_NEW_NOTIFICATIONS_SUCCESS = '[Notifications] Get New Notifications Success',
}

export class ErrorAction implements Action {
    readonly type: string = NotificationsActionTypes.ERROR;
    constructor(public error: any) { }
}

export class GetAllRoles implements Action {
    readonly type: string = NotificationsActionTypes.GET_ALL_ROLES;
    constructor() { }
}

export class GetAllRolesSuccess implements Action {
    readonly type: string = NotificationsActionTypes.GET_ALL_ROLES_SUCCESS;
    constructor(public roles: any) { }
}

export class GetNotifications implements Action {
    readonly type: string = NotificationsActionTypes.GET_NOTIFICATIONS;
    constructor() { }
}

export class GetNotificationsSuccess implements Action {
    readonly type: string = NotificationsActionTypes.GET_NOTIFICATIONS_SUCCESS;
    constructor(public notificationsList: any) { }
}

export class UpdateNotificationSetting implements Action {
    readonly type: string = NotificationsActionTypes.UPDATE_NOTIFICATION_SETTING;
    constructor(public payload: any) { }
}

export class UpdateNotificationSettingSuccess implements Action {
    readonly type: string = NotificationsActionTypes.UPDATE_NOTIFICATION_SETTING_SUCCESS;
    constructor(public response: any) { }
}

export class GetWebNotifications implements Action {
    readonly type: string = NotificationsActionTypes.GET_WEB_NOTIFICATIONS;
    constructor(public payload: any) { }
}

export class GetWebNotificationsSuccess implements Action {
    readonly type: string = NotificationsActionTypes.GET_WEB_NOTIFICATIONS_SUCCESS;
    constructor(public notificationsWebList: any) { }
}

export class UpdateWebNotificationSeen implements Action {
    readonly type: string = NotificationsActionTypes.UPDATE_WEB_NOTIFICATION_SEEN;
    constructor(public notificationId: any) { }
}

export class UpdateWebNotificationSeenSuccess implements Action {
    readonly type: string = NotificationsActionTypes.UPDATE_WEB_NOTIFICATION_SEEN_SUCCESS;
    constructor(public response: any) { }
}

export class GetWebNotificationCount implements Action {
    readonly type: string = NotificationsActionTypes.GET_WEB_NOTIFICATION_COUNT;
    constructor() { }
}

export class GetWebNotificationCountSuccess implements Action {
    readonly type: string = NotificationsActionTypes.GET_WEB_NOTIFICATION_COUNT_SUCCESS;
    constructor(public response: any) { }
}

export class AddRemoveNotificationSetting implements Action {
    readonly type: string = NotificationsActionTypes.ADD_REMOVE_NOTIFICATION_SETTING;
    constructor(public payload: any) { }
}

export class AddRemoveNotificationSettingSuccess implements Action {
    readonly type: string = NotificationsActionTypes.ADD_REMOVE_NOTIFICATION_SETTING_SUCCESS;
    constructor(public response: any) { }
}

export class GetNewNotifications implements Action {
    readonly type: string = NotificationsActionTypes.GET_NEW_NOTIFICATIONS;
    constructor() { }
}

export class GetNewNotificationsSuccess implements Action {
    readonly type: string = NotificationsActionTypes.GET_NEW_NOTIFICATIONS_SUCCESS;
    constructor(public notificationsNewList: any) { }
}




