import {
  BudgetActionTypes,
  allListSuccess,
  budgetListSuccess,
  categoryListSuccess,
  categorydetailsSuccess,
  checkCategoryAvailableSuccess,
  checkEurExistingExpensesSuccess,
  checkEurExistingSuccess,
  checkLineItemAvailableSuccess,
  createNiuaOrMohuaSactionSuccess,
  deleteCategorySuccess,
  deleteNiuaOrMohuaSactionSuccess,
  deletegrantSuccess,
  deletelineItemSuccess,
  deletesubCategorySuccess,
  downloadReportSuccess,
  dropdownCategoryListSuccess,
  dropdownsubCategoryListSuccess,
  editBudgetSuccess,
  editExpenseSuccess,
  editNiuaOrMohuaSactionSuccess,
  editgrantSuccess,
  expenseListSuccess,
  generateExpensesTemplateSuccess,
  generateGrantSuccess,
  generatebudgetTemplateSuccess,
  getBudgetReportSuccess,
  grantListSuccess,
  grantdetailsSuccess,
  lineItemListSuccess,
  lineItemdetailsSuccess,
  sanctionListSuccess,
  totalFundListSuccess,
  subcategoryListSuccess,
  subcategorydetailsSuccess,
  updateSequanceSuccess,
  updatesubcategorySequanceSuccess,
  deletetotalFundSactionSuccess,
  edittotalFundSactionSuccess,
  createtotalFundSactionSuccess,
  createNiuaUtilizationSuccess,
  editNiuaUtilizationSuccess,
  deleteNiuaUtilizationSuccess,
  NiuaUtilizationListSuccess,
  getMisDashboardSuccess,
  dropdownsubCategoryListUpdateSeqSuccess,
} from "./budget.action";
import { Action } from "@ngrx/store";
export interface BudgetManagement {
  categoryList: Array<any>;
  SubcategoryList: Array<any>;
  SubcategoryListUpdate: Array<any>;
  categoryListResp: {};
  categoryDetailsResp: {};
  categoryDeleteResp: {};
  subcategoryListResp: {};
  subcategoryDetailsResp: {};
  subcategoryDeleteResp: {};
  lineItemListResp: {};
  lineItemDetailsResp: {};
  lineItemDeleteResp: {};
  AllListResp: {};
  updateSequanceResp: {};
  generateGrantResponse: {};
  GrantlistResponse: {};
  GranteditResponse: {};
  GrantdetailsResponse: {};
  GrantdeleteResponse: {};
  existingEurResponse: {};
  budgetTemplateResponse: {};
  budgetUpdateResponse: {};
  BudgetlistResponse: {};
  existingEurExpensesResponse: {};
  ExpensesTemplateResponse: {};
  ExpenselistResponse: {};
  expensesUpdateResponse: {};
  getBudgetReportResponse: {};
  downloadReportResponse: {};
  checkSubCategoryResponse: {};
  checkLineItemResponse: {};
  createNiuaOrMohuaSactionResponse: {};
  editNiuaOrMohuaSactionResponse: {};
  deleteNiuaOrMohuaSactionResponse: {};
  sanctionListResponse: {};
  createTotalFundSactionResponse: {};
  editTotalFundactionResponse: {};
  deleteTotalFundSactionResponse: {};
  totalFundListResponse: {};
  NiuaUtilizationListResponse:{},
  createNiuaUtilizationResponse:{},
  editNiuaUtilizationResponse:{},
  deleteNiuaUtilizationResponse:{},
  getMisDashboardDataResponse:{},

}

const intiState = {
  categoryList: [],
  SubcategoryList: [],
  SubcategoryListUpdate: [],
  categoryListResp: {},
  categoryDetailsResp: {},
  categoryDeleteResp: {},
  subcategoryListResp: {},
  subcategoryDetailsResp: {},
  subcategoryDeleteResp: {},
  lineItemListResp: {},
  lineItemDetailsResp: {},
  lineItemDeleteResp: {},
  AllListResp: {},
  updateSequanceResp: {},
  generateGrantResponse: {},
  GrantlistResponse: {},
  GranteditResponse: {},
  GrantdetailsResponse: {},
  GrantdeleteResponse: {},
  existingEurResponse: {},
  budgetTemplateResponse: {},
  budgetUpdateResponse: {},
  BudgetlistResponse: {},
  existingEurExpensesResponse: {},
  ExpensesTemplateResponse: {},
  ExpenselistResponse: {},
  expensesUpdateResponse: {},
  getBudgetReportResponse: {},
  downloadReportResponse: {},
  checkSubCategoryResponse: {},
  checkLineItemResponse: {},
  createNiuaOrMohuaSactionResponse: {},
  editNiuaOrMohuaSactionResponse: {},
  deleteNiuaOrMohuaSactionResponse: {},
  sanctionListResponse: {},
  createTotalFundSactionResponse: {},
  editTotalFundactionResponse: {},
  deleteTotalFundSactionResponse: {},
  totalFundListResponse: {},
  NiuaUtilizationListResponse:{},
  createNiuaUtilizationResponse:{},
  editNiuaUtilizationResponse:{},
  deleteNiuaUtilizationResponse:{},
  getMisDashboardDataResponse:{},
};

export function reducer(
  state: BudgetManagement = intiState,
  action: Action
): BudgetManagement {
  switch (action.type) {
    case BudgetActionTypes.DROPDOWN_CATEGORY_LIST_SUCCESS:
      return Object.assign({}, state, {
        categoryList: (action as dropdownCategoryListSuccess).response.data,
      });
    case BudgetActionTypes.DROPDOWN_SUB_CATEGORY_LIST_SUCCESS:
      return Object.assign({}, state, {
        SubcategoryList: (action as dropdownsubCategoryListSuccess).response
          .data,
      });
    case BudgetActionTypes.CATEGORY_LIST_SUCCESS:
      return Object.assign({}, state, {
        categoryListResp: (action as categoryListSuccess).response,
      });
    case BudgetActionTypes.CATEGORY_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        categoryDetailsResp: (action as categorydetailsSuccess).response.data,
      });
    case BudgetActionTypes.DELETE_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        categoryDeleteResp: (action as deleteCategorySuccess).response,
      });

    case BudgetActionTypes.SUB_CATEGORY_LIST_SUCCESS:
      return Object.assign({}, state, {
        subcategoryListResp: (action as subcategoryListSuccess).response,
      });
    case BudgetActionTypes.SUB_CATEGORY_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        subcategoryDetailsResp: (action as subcategorydetailsSuccess).response
          .data,
      });
    case BudgetActionTypes.DELETE_SUB_CATEGORY_SUCCESS:
      return Object.assign({}, state, {
        subcategoryDeleteResp: (action as deletesubCategorySuccess).response,
      });

    case BudgetActionTypes.LINE_ITEM_LIST_SUCCESS:
      return Object.assign({}, state, {
        lineItemListResp: (action as lineItemListSuccess).response,
      });
    case BudgetActionTypes.LINE_ITEM_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        lineItemDetailsResp: (action as lineItemdetailsSuccess).response.data,
      });
    case BudgetActionTypes.DELETE_LINE_ITEM_SUCCESS:
      return Object.assign({}, state, {
        lineItemDeleteResp: (action as deletelineItemSuccess).response,
      });
    case BudgetActionTypes.ALL_LIST_SUCCESS:
      return Object.assign({}, state, {
        AllListResp: (action as allListSuccess).response,
      });
    case BudgetActionTypes.UPDATE_SEQUANCE_SUCCESS:
      return Object.assign({}, state, {
        updateSequanceResp: (action as updateSequanceSuccess).response,
      });
    case BudgetActionTypes.UPDATE_SUBCATEGORY_SEQUANCE_SUCCESS:
      return Object.assign({}, state, {
        updatesubCategorySequanceResp: (
          action as updatesubcategorySequanceSuccess
        ).response,
      });
    case BudgetActionTypes.GENERATE_GRANT_SUCCESS:
      return Object.assign({}, state, {
        generateGrantResponse: (action as generateGrantSuccess).response,
      });
    case BudgetActionTypes.GRANT_LIST_SUCCESS:
      return Object.assign({}, state, {
        GrantlistResponse: (action as grantListSuccess).response,
      });

    case BudgetActionTypes.UPATE_GRANT_SUCCESS:
      return Object.assign({}, state, {
        GranteditResponse: (action as editgrantSuccess).response,
      });
    case BudgetActionTypes.GRANT_DETAILS_SUCCESS:
      return Object.assign({}, state, {
        GrantdetailsResponse: (action as grantdetailsSuccess).response,
      });
    case BudgetActionTypes.DELETE_GRANT_SUCCESS:
      return Object.assign({}, state, {
        GrantdeleteResponse: (action as deletegrantSuccess).response,
      });
    case BudgetActionTypes.CHECK_EUR_CONVERSION_SUCCESS:
      return Object.assign({}, state, {
        existingEurResponse: (action as checkEurExistingSuccess).response.data,
      });
    case BudgetActionTypes.GENERATE_BUDGET_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        budgetTemplateResponse: (action as generatebudgetTemplateSuccess)
          .response,
      });
    case BudgetActionTypes.UPATE_BUDGET_SUCCESS:
      return Object.assign({}, state, {
        budgetUpdateResponse: (action as editBudgetSuccess).response,
      });
    case BudgetActionTypes.BUDGET_LIST_SUCCESS:
      return Object.assign({}, state, {
        BudgetlistResponse: (action as budgetListSuccess).response,
      });
    case BudgetActionTypes.CHECK_EUR_CONVERSION_EXPENSES_SUCCESS:
      return Object.assign({}, state, {
        existingEurExpensesResponse: (action as checkEurExistingExpensesSuccess)
          .response.data,
      });
    case BudgetActionTypes.GENERATE_EXPENSES_TEMPLATE_SUCCESS:
      return Object.assign({}, state, {
        ExpensesTemplateResponse: (action as generateExpensesTemplateSuccess)
          .response,
      });
    case BudgetActionTypes.EXPENSE_LIST_SUCCESS:
      return Object.assign({}, state, {
        ExpenselistResponse: (action as expenseListSuccess).response,
      });
    case BudgetActionTypes.UPDATE_EXPENSE_SUCCESS:
      return Object.assign({}, state, {
        expensesUpdateResponse: (action as editExpenseSuccess).response,
      });
    case BudgetActionTypes.GET_BUDGET_REPORT_SUCCESS:
      return Object.assign({}, state, {
        getBudgetReportResponse: (action as getBudgetReportSuccess).response,
      });
    case BudgetActionTypes.DOWNLOAD_REPORT_SUCCESS:
      return Object.assign({}, state, {
        downloadReportResponse: (action as downloadReportSuccess).response,
      });
    case BudgetActionTypes.IS_CHECK_SHOW_ADD_SUBCATEGORY_BUTTON_SUCCESS:
      return Object.assign({}, state, {
        checkSubCategoryResponse: (action as checkCategoryAvailableSuccess)
          .response,
      });
    case BudgetActionTypes.IS_CHECK_SHOW_ADD_LINEITEMS_BUTTON_SUCCESS:
      return Object.assign({}, state, {
        checkLineItemResponse: (action as checkLineItemAvailableSuccess)
          .response,
      });
    case BudgetActionTypes.CREATE_GRANT_MOHUA_OR_NIUA_SUCCESS:
      return Object.assign({}, state, {
        createNiuaOrMohuaSactionResponse: (
          action as createNiuaOrMohuaSactionSuccess
        ).response,
      });
    case BudgetActionTypes.UPDATE_GRANT_MOHUA_OR_NIUA_SUCCESS:
      return Object.assign({}, state, {
        editNiuaOrMohuaSactionResponse: (
          action as editNiuaOrMohuaSactionSuccess
        ).response,
      });
    case BudgetActionTypes.DELETE_GRANT_MOHUA_OR_NIUA_SUCCESS:
      return Object.assign({}, state, {
        deleteNiuaOrMohuaSactionResponse: (
          action as deleteNiuaOrMohuaSactionSuccess
        ).response,
      });
    case BudgetActionTypes.GET_LIST_GRANT_MOHUA_OR_NIUA_SUCCESS:
      return Object.assign({}, state, {
        sanctionListResponse: (action as sanctionListSuccess).response,
      });
      case BudgetActionTypes.CREATE_GRANT_TOTAL_FUND_SUCCESS:
        return Object.assign({}, state, {
          createTotalFundSactionResponse: (
            action as createtotalFundSactionSuccess
          ).response,
        });
      case BudgetActionTypes.UPDATE_GRANT_TOTAL_FUND_SUCCESS:
        return Object.assign({}, state, {
          editTotalFundSactionResponse: (
            action as edittotalFundSactionSuccess
          ).response,
        });
      case BudgetActionTypes.DELETE_GRANT_TOTAL_FUND_SUCCESS:
        return Object.assign({}, state, {
          deleteTotalFundSactionResponse: (
            action as deletetotalFundSactionSuccess
          ).response,
        });
    case BudgetActionTypes.GET_LIST_GRANT_TOTAL_FUND_SUCCESS:
      return Object.assign({}, state, {
        totalFundListResponse: (action as totalFundListSuccess).response,
    });
    case BudgetActionTypes.CREATE_UTIL_NIUA_SUCCESS:
      return Object.assign({}, state, {
        createNiuaUtilizationResponse: (
          action as createNiuaUtilizationSuccess
        ).response,
      });
    case BudgetActionTypes.UPDATE_UTIL_NIUA_SUCCESS:
      return Object.assign({}, state, {
        editNiuaUtilizationResponse: (
          action as editNiuaUtilizationSuccess
        ).response,
      });
    case BudgetActionTypes.DELETE_UTIL_NIUA_SUCCESS:
      return Object.assign({}, state, {
        deleteNiuaUtilizationResponse: (
          action as deleteNiuaUtilizationSuccess
        ).response,
      });
  case BudgetActionTypes.GET_LIST_UTIL_NIUA_SUCCESS:
    return Object.assign({}, state, {
      NiuaUtilizationListResponse: (action as NiuaUtilizationListSuccess).response,
  });

  case BudgetActionTypes.GET_MIS_DASHBOARD_DATA_SUCCESS:
    return Object.assign({}, state, {
      getMisDashboardDataResponse: (action as getMisDashboardSuccess).response,
  });

      case BudgetActionTypes.DROPDOWN_SUB_CATEGORY_LIST_UPDATE_SUCCESS:
      return Object.assign({}, state, {
        SubcategoryListUpdate: (action as dropdownsubCategoryListUpdateSeqSuccess).response.data,
      });


      case BudgetActionTypes.DROPDOWN_SUB_CATEGORY_LIST_UPDATE_CLEAR:
        return Object.assign({}, state, {
          SubcategoryListUpdate: [],
        });

    default:
      return state;
  }
}
