import { Action } from '@ngrx/store';
import { ConfigurationsActionTypes, GetConfigurationsSuccess } from './configurations.actions';


export interface Configurations {
    configurations: any;
}

const intiState = {
    configurations: {}
};


export function reducer(state: Configurations = intiState, action: Action): Configurations {
    switch (action.type) {
        case ConfigurationsActionTypes.GET_CONFIGURATIONS_SUCCESS:
            return Object.assign([], state, { configurations: (action as GetConfigurationsSuccess).configList });
        case ConfigurationsActionTypes.UPDATE_CONFIGURATIONS_SUCCESS:
            return Object.assign([], state, {});
        default:
            return state;
    }
}