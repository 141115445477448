import { Action } from '@ngrx/store';
import {
    TasksActionTypes,
    GetTasksListSuccessAction,
    GetAllTagsListSuccessAction,
    GetTaskDetailsSuccess,
    AddTagSuccessAction,
    GetLinkedTasksSuccess,
    GetSubtaskSuccess,
    GetActivityLogSuccess,
    GetAllFiltersListSuccess,
    GetCreatedByMeFiltersListSuccess,
    GetAssignedToMeFiltersListSuccess,
    GetFilteredTasksSuccess,
    SearchTasksSuccess,
    GetAllCmpTasksSuccess,
    VerifyEndorseOtpSuccess,
    ViewTaskDetailsSuccess,
    ClearAction,
    FilterApplied,
    GetAllCMPTasksFiltersSuccess,
    GetInactiveReplacedUsersSuccess,
    GetInactiveReplacedUsers,
    updatePacketDetailsSuccess,
    createPacketDetailsSuccess
} from './tasks.actions';

export interface TasksState {
    tasksList: Array<any>;
    tagsList: Array<any>;
    taskDetail: any;
    addedTag: any;
    linkedTasks: Array<any>;
    subTasks: Array<any>;
    activityLog: Array<any>;
    filters: Array<any>;
    filteredTasks: any;
    searchedTasks: Array<any>;
    endorseOtpVerifyRes: any;
    viewTaskDetails: any;
    selectedFilter: any;
    replacedUsers: any
    updatePackectResponse: any;
    createPackectResponse:any;
}

const initialState = {
    tasksList: [],
    tagsList: [],
    taskDetail: {},
    addedTag: {},
    linkedTasks: [],
    subTasks: [],
    activityLog: [],
    filters: [],
    filteredTasks: {
        filteredTaskList: [],
        details: {}
    },
    searchedTasks: [],
    endorseOtpVerifyRes: {},
    viewTaskDetails: {},
    selectedFilter: {},
    replacedUsers: [],
    updatePackectResponse: {},
    createPackectResponse:{}
};

export function reducer(state: TasksState = initialState, action: Action): TasksState {
    switch (action.type) {

        case TasksActionTypes.GET_TASK_LIST_SUCCESS:
            return Object.assign([], state, { tasksList: (action as GetTasksListSuccessAction).data });
        case TasksActionTypes.GET_LINKED_TASKS_SUCCESS:
            return Object.assign([], state, { linkedTasks: (action as GetLinkedTasksSuccess).response });
        case TasksActionTypes.GET_SUB_TASKS_SUCCESS:
            return Object.assign([], state, { subTasks: (action as GetSubtaskSuccess).response });
        case TasksActionTypes.GET_TAGS_LIST_SUCCESS:
            return Object.assign([], state, { tagsList: (action as GetAllTagsListSuccessAction).response });
        case TasksActionTypes.GET_TASK_DETAILS_SUCCESS:
            return {
                ...state,
                taskDetail: (action as GetTaskDetailsSuccess).response
            }
        case TasksActionTypes.ADD_TAG_SUCCESS:
            return { ...state, addedTag: (action as AddTagSuccessAction).response }
        case TasksActionTypes.CLEAR_TAG:
            return { ...state, addedTag: {} }
        case TasksActionTypes.GET_ALL_TASK_FILTERS_SUCCESS:
            return Object.assign([], state, { filters: (action as GetAllFiltersListSuccess).response })
        case TasksActionTypes.GET_CREATEDBYME_TASK_FILTERS_SUCCESS:
            return Object.assign([], state, { filters: (action as GetCreatedByMeFiltersListSuccess).response })
        case TasksActionTypes.GET_ASSIGNEDTOME_TASK_FILTERS_SUCCESS:
            return Object.assign([], state, { filters: (action as GetAssignedToMeFiltersListSuccess).response })
        case TasksActionTypes.GET_ALL_CMP_TASK_FILTERS_SUCCESS:
            console.log("GET_ALL_CMP_TASKS_SUCCESS", (action as GetAllCMPTasksFiltersSuccess).response);
            return Object.assign([], state, { filters: (action as GetAllCMPTasksFiltersSuccess).response })
        case TasksActionTypes.GET_FILTERED_TASKS_SUCCESS:
            let filteredTaskList = (action as GetFilteredTasksSuccess).tasks.tasks;
            let pageNum = (action as GetFilteredTasksSuccess).tasks.page;
            if (pageNum == 1) {
                state.filteredTasks.filteredTaskList = [];
            }
            return Object.assign({}, state, {
                filteredTasks: {
                    filteredTaskList: [...filteredTaskList],
                    details: (action as GetFilteredTasksSuccess).tasks,
                }
            });
        // return Object.assign([], state, { filteredTasks: (action as GetFilteredTasksSuccess).tasks });
        case TasksActionTypes.SEARCH_TASKS_SUCCESS:
            return Object.assign([], state, { searchedTasks: (action as SearchTasksSuccess).response });
        case TasksActionTypes.GET_ALL_CMP_TASKS_SUCCESS:
            return Object.assign([], state, { tasksList: (action as GetAllCmpTasksSuccess).res })
        case TasksActionTypes.GET_ACTIVITY_LOG_SUCCESS:
            return Object.assign([], state, { activityLog: (action as GetActivityLogSuccess).response });
        case TasksActionTypes.VERIFY_ENDORSE_OTP_SUCCESS:
            return Object.assign([], state, { endorseOtpVerifyRes: (action as VerifyEndorseOtpSuccess).response });
        case TasksActionTypes.VIEW_TASK_DETAILS_SUCCESS:
            return Object.assign([], state, { viewTaskDetails: (action as ViewTaskDetailsSuccess).response });
        case TasksActionTypes.CLEAR_ACTION:
            return Object.assign({}, state, { endorseOtpVerifyRes: {} });
        case TasksActionTypes.FILTER_APPLIED:
            return Object.assign({}, state, { selectedFilter: (action as FilterApplied).selectedFilter });
        case TasksActionTypes.GET_INACTIVE_REPLACED_USER_SUCCESS:
            return Object.assign([], state, { replacedUsers: (action as GetInactiveReplacedUsersSuccess).response })
        case TasksActionTypes.UPDATE_PACKET_DETAILS_SUCCESS:
            return Object.assign({}, state, { updatePackectResponse: (action as updatePacketDetailsSuccess).response })
            case TasksActionTypes.CREATE_PACKET_DETAILS_SUCCESS:
            return Object.assign({}, state, { createPackectResponse: (action as createPacketDetailsSuccess).response })
        default:
            return state;
    }
}