import { Injectable } from "@angular/core";
import { CanActivate, Router, ActivatedRouteSnapshot, RouterStateSnapshot } from "@angular/router";
import { Store } from "@ngrx/store";
import { Observable, of } from "rxjs";
import { AppState } from "../app.reducer";
import { first, map } from "rxjs/operators";
import { NgxPermissionsService } from "ngx-permissions";
import { API } from "../services/Api.service";
import { Logout } from "../login/store/auth.actions";

@Injectable()
export class AuthGaurd implements CanActivate {
  userId: any;
  constructor(
    private router: Router,
    private store: Store<AppState>,
    private ngxPermissionService: NgxPermissionsService,
    private api: API
  ) {

  }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot,
  ): Observable<boolean> | Promise<boolean> | boolean {
     return this.store.select('auth').pipe(
      first(),
      map((data: any) => {
        if (!data.isLoggedIn) {
        //   this.notificationService.warn('Login', 'Please login');
          return this.router.navigate(['/user/login']);
        }
        if (data.user) {
          this.userId = (data.user.details || {})._id;
        }
        return this.api.getUserCapabilities().toPromise().then((cpby: any) => {
          const capability = cpby.map(role =>  role.capabilities)
            .reduce((acc, capabilityArray) => {
              return acc.concat(capabilityArray);
            }, []);
           this.ngxPermissionService.loadPermissions([...capability, 'all'] || []);
           console.log(data.isLoggedIn);
           return data.isLoggedIn;
        }).catch(err => {
          if (err.status === 401) {
            this.store.dispatch(new Logout(this.userId, data.accessToken));
            // this.dialogRef.closeAll();
          }
        });
    })).toPromise().then(isLoogedIn => {
      return isLoogedIn;
    });
  }
}
