import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { stringify } from 'querystring';

@Injectable()
export class ViewListService {

    private viewSource = new BehaviorSubject(false);
    private showFlagSource = new BehaviorSubject(true);
    private sortOrder = new BehaviorSubject('date');
    private sortBy = new BehaviorSubject('desc');
    private pageLimit = new BehaviorSubject(30);


    currentViewMode = this.viewSource.asObservable();
    CurrentShowFlag = this.showFlagSource.asObservable();
    CurrentSortOrder = this.sortOrder.asObservable();
    CurrentSortBy = this.sortBy.asObservable();
    CurrentLimit = this.pageLimit.asObservable();



    constructor() { }

    changeView(listView: boolean) {
        this.viewSource.next(listView)
    }

    showNewButton(showFlag: boolean) {
        this.showFlagSource.next(showFlag)
    }

    changeSortOptions(sortBy: string, sortOrder: string, pageLimit:number) {
        this.sortBy.next(sortBy)
        this.sortOrder.next(sortOrder)
        this.pageLimit.next(pageLimit)

    }

}