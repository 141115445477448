import { Component, OnInit } from '@angular/core';
import { MatDialogRef } from "@angular/material";
import { Store } from '@ngrx/store';
import { AppState } from '../../../../app.reducer';
import { Logout } from '../../../../login/store/auth.actions';
import { take } from 'rxjs/operators';

@Component({
  selector: 'app-change-email-success',
  templateUrl: './change-email-success.component.html',
  styleUrls: ['./change-email-success.component.scss']
})
export class ChangeEmailSuccessComponent implements OnInit {

  constructor(
    private dialogRef: MatDialogRef<any>,
    private store: Store<AppState>,
  ) {
    this.dialogRef.disableClose = true;
  }

  ngOnInit() { }

  btnClick() {
    this.store.select(state => state.auth).pipe(take(1)).subscribe(auth => {
      if (auth.user) {
        this.store.dispatch(new Logout(auth.user.details._id, auth.accessToken));
        this.dialogRef.close();
      }
    });
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }
}
