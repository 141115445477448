export const filterResults = (inputArray, searchString) => {
  return inputArray.filter((obj) => {
    return Object.keys(obj).reduce((acc, curr) => {
      if(!obj[curr]) return false;
      if (Array.isArray(obj[curr])) {
        return acc || obj[curr].map((item) => {
          if(typeof item !== 'object') return item.toLowerCase();
          return '';
        }).find(el => el.includes(searchString.toLowerCase()));
      }
      return acc || obj[curr].toString().toLowerCase().includes(searchString.toLowerCase());
    }, false);
  });
};

export const DOCUMENT_STATUS = {
  1: 'Approved',
  2: 'Rejected',
  3: 'Pending'
};

export const MAX_ALLOWED_FILE_SIZE = 20971520; //20MB in binary bytes
export const RECAPTCHA_SITE_KEY = '6LfIqcQUAAAAAFswc_i37A05ZPYHPUYeiqyaklP0';

export const ACCEPTED_FILE_TYPES = [
  // 'video/x-msvideo',
  'video/avi',
  'image/bmp',
  'text/csv',
  'application/msword',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
  'image/gif',
  'image/jpeg',
  'video/mpeg',
  'image/png',
  'image/tiff',
  'application/pdf',
  'application/vnd.ms-powerpoint',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/rtf',
  'image/svg+xml',
  'text/plain',
  'video/mp4',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
  // 'text/xml',
  'application/vnd.google-earth.kml+xml'
];

export const ACCEPTED_FILE_TYPES_TEXT = {
  // 'video/x-msvideo' : '.avi',
  'video/avi': '.avi',
  'image/bmp' : '.bmp',
  'text/csv': '.csv',
  'application/msword': '.doc',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document' : '.docx',
  'image/gif': '.gif',
  'image/tiff': '.tif,.tiff',
  'image/jpeg': '.jpg',
  'video/mpeg': '.mpeg',
  'video/mp4': '.mp4',
  'image/png' : '.png',
  'application/pdf' : '.pdf',
  'application/vnd.ms-powerpoint' : '.ppt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation' : '.pptx',
  'application/rtf':'.rtf',
  'image/svg+xml': '.svg',
  'text/plain' : '.txt',
  'application/vnd.ms-excel' : '.xls',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' : '.xlsx',
  // 'text/xml':'.kml',
  'application/vnd.google-earth.kml+xml':'.kml'
};

export const ACCEPTED_FILE_TYPES_FOR_BULK_UPLOAD = [
  'text/csv',
  'application/vnd.ms-excel',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
];

export const MIME_TYPE_ON_EXTENSION = function (fileExtension) {
  switch (fileExtension) {
    case 'csv':
      return 'text/csv';
      break;
    case 'xls':
     return 'application/vnd.ms-excel';
      break;
    case 'xlsx':
     return 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
      break;
    case 'avi':
     return 'video/avi';
      break;
    case 'bmp':
     return 'image/bmp';
      break;
    case 'doc':
     return 'application/msword';
      break;
    case 'docx':
     return 'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
      break;
    case 'gif':
     return 'image/gif';
      break;
    case 'jpg':
     return 'image/jpeg';
      break;
    case 'png':
     return 'image/png';
      break;
    case 'pdf':
     return 'application/pdf';
      break;
    case 'ppt':
     return 'application/vnd.ms-powerpoint';
      break;
    case 'pptx':
     return 'application/vnd.openxmlformats-officedocument.presentationml.presentation';
      break;
    case 'rtf':
     return 'application/rtf';
      break;
    case 'svg':
     return 'image/svg+xml';
      break;
    case 'txt':
     return 'text/plain';
      break;
    case 'kml':
     return 'application/vnd.google-earth.kml+xml';
      break;
    case 'tif':
    case 'tiff':
      return 'image/tiff';
    default:
        return '';
      break;
  }
};

export const PAGE_LIMIT = [
  '10', '20', '30', '40', '50'
];
