import { Action } from '@ngrx/store';
import { UserGroupActionTypes, GetUserGroupsSuccess, GetGroupDetailsSuccess, CreateGroupSuccess, AddUsersToGroupSuccess, UpdateGroupSuccess } from './user-groups.actions';

export interface UserGroup {
    groups: Array<any>;
    groupDetails: any;
    createdGroup: any;
    userAddedToGroup: any;
    updateGroup: any;

}

const intiState = {
    groups: [],
    groupDetails: {},
    createdGroup: {},
    userAddedToGroup: {},
    updateGroup: {}
};


export function reducer(state: UserGroup = intiState, action: Action): UserGroup {
    switch (action.type) {
        case UserGroupActionTypes.GET_USERS_GROUPS_SUCCESS:
            return Object.assign({}, state, {groups : (action as GetUserGroupsSuccess).userGroups});
        case UserGroupActionTypes.GET_GROUP_DETAILS_SUCCESS:
            return Object.assign({}, state, {groupDetails : (action as GetGroupDetailsSuccess).groupDetails});
            case UserGroupActionTypes.CREATE_GROUP_SUCCESS:
                return Object.assign({}, state, {createdGroup: (action as CreateGroupSuccess).response})
                case UserGroupActionTypes.ADD_USER_TO_GROUP_SUCCESS:
                    return Object.assign({}, state, {userAddedToGroup: (action as AddUsersToGroupSuccess).response})
                    case UserGroupActionTypes.UPDATE_GROUP_SUCCESS: 
                    return Object.assign({}, state, {updateGroup: (action as UpdateGroupSuccess).response})
                    case UserGroupActionTypes.CLEAR_RESPONSE_DATA_FOR_CREATE_GROUP: 
                    return Object.assign({}, state, {createdGroup: {}})
                    case UserGroupActionTypes.CLEAR_DATA_FOR_UPDATEGROUP: 
                    return Object.assign({}, state, {updateGroup: {}})
                    case UserGroupActionTypes.Clear_DATA_FOR_USER_ADD :
                        return Object.assign({}, state,{userAddedToGroup: {}} )
        default :
            return state;
    }
}
