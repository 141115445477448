import { Component, ElementRef, ViewChild, Input, Output, EventEmitter } from "@angular/core";
import { MatAutocompleteSelectedEvent, MatChipInputEvent, MatAutocomplete } from "@angular/material";
import { startWith, map, debounceTime, distinctUntilChanged } from "rxjs/operators";
import { FormControl } from "@angular/forms";
import { Observable } from "rxjs";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { Store } from "@ngrx/store";
import { visitAll } from "@angular/compiler/src/render3/r3_ast";
import { GetAllTagsListStartAction, AddTagStartAction, ClearTagAction } from "src/app/features/home/tasks/store/tasks.actions";
import { CommonService } from "src/app/services/tasks.services";
import { LoaderShow } from "src/app/features/loader/store/loader.actions";

@Component({
  selector: 'tags-autocomplete',
  styleUrls: ['./tags.autocomplete.scss'],
  templateUrl: './tags.autocomplete.html'

})
export class TaskAutoComplete {

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  // fruits: string[] = ['Lemon'];
  allTags: string[] = [];
  selectedTags: string[] = []

  @ViewChild('tagInput', { static: false }) tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  @Output() selectedTagsEvent: EventEmitter<any> = new EventEmitter();
  @Output() selectedTagIdsEvent: EventEmitter<any> = new EventEmitter();
  responseTags: Array<any> = [];
  selectedTagIds: any = [];
  selectedTagWithId: any = [];
  addTag: boolean = false;
  specialCharregex;
  multiSpaceRegex;

  error = false;
  selectedTag: boolean = false;
  regExError: boolean = false

  searchString;


  constructor(private _store: Store<any>, private commonService: CommonService) { }
  ngOnInit() {
    this.tagCtrl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(((val) => {
      this.error = false;
      this.regExError = false

      this.selectedTag = false;

      this.searchString = val ? val.trim() : '';

      if (val != null) {
        (typeof (val) === 'number') ? val.toString() : val;
        let value = val;
        if (val.length) {
          this.specialCharregex = new RegExp(/^[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/);
          this.multiSpaceRegex = new RegExp(/[ ]{2,}/);

          if (this.specialCharregex.test(value)) {
            this._store.dispatch(new GetAllTagsListStartAction({ 'search': value }))
            this._store.select(state => state.tasks.tagsList).subscribe((allTags) => {

              this.responseTags = allTags.filter((tagData) => !this.selectedTagIds.includes(tagData._id));
              if (allTags.length) {
                this.addTag = false;
              }
              else {
                this.addTag = true;
              }

              if (this.searchString && (!this.error && !this.regExError)) {
                this.responseTags.unshift({ tag: ` + Create tag for '${this.searchString}'` });
              }
            })
          }
          else {
            this.regExError = true
            this.responseTags = []
            this.error = false;

          }

          if (!this.multiSpaceRegex.test(value)) {
            this._store.dispatch(new GetAllTagsListStartAction({ 'search': value }))
            this._store.select(state => state.tasks.tagsList).subscribe((allTags) => {

              this.responseTags = allTags.filter((tagData) => !this.selectedTagIds.includes(tagData._id));
              if (allTags.length) {
                this.addTag = false;
              }
              else {
                this.addTag = true;
              }

              if (this.searchString && this.searchString != null && this.searchString != undefined && (!this.error && !this.regExError)) {
                this.responseTags.unshift({ tag: ` + Create tag for '${this.searchString}'` });
              }

            })
          }
          else {
            this.responseTags = []
            this.error = true;
            this.regExError = false
          }
        }
        else {
          this.responseTags = [];
        }
      }

    }))
    this._store.select(state => state.tasks.addedTag).subscribe((response) => {
      //check for response when added new tag
      if (this.commonService.componentForAddingTag !== 'uploadDialog') {

        if (Object.keys(response).length) {
          let data = response;
          let index = this.selectedTags.findIndex(tag => tag === response.tag);
          if (index === -1) {

            this.selectedTags.push(response.tag);
            this.selectedTagIds.push(data._id);
            this.selectedTagsEvent.emit(this.selectedTags);
            this.selectedTagIdsEvent.emit(this.selectedTagIds);
            this.selectedTagWithId.push({ value: response.tag, _id: data._id });
            // this._store.dispatch(new ClearTagAction())   ;
            this.responseTags = [];

          }
        }
      }
    })
    this.responseTags = [];
  }


  add(event: MatChipInputEvent): void {

    if (this.selectedTag) return
    const input = event.input;
    const value = event.value.trim();

    this.onTagToSubmit(value);

    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }


  onTagToSubmit(value) {
    if (!this.matAutocomplete.isOpen) {
      if (value.length) {
        if (this.specialCharregex.test(value) && !this.multiSpaceRegex.test(value)) {
          let index = this.selectedTags.findIndex((tag) => tag === value.trim());
          if (index === -1) {
            if (this.addTag) {
              this._store.dispatch(new LoaderShow());
              this._store.dispatch(new AddTagStartAction({ 'tag': value }));
              this.commonService.componentForAddingTag = ''
            }


          }
        }
      }
      // Reset the input value
    }
    else {
      let index = this.responseTags.findIndex((tag) => tag.tag === value);
      if (index === -1) {
        this._store.dispatch(new LoaderShow());
        this._store.dispatch(new AddTagStartAction({ 'tag': value }));
        this.commonService.componentForAddingTag = ''

      }
      else {
        let index = this.selectedTags.findIndex((tag) => tag === value.trim());
        let tagData: any = this.responseTags.filter((tag) => tag.tag === value);
        if (index === -1) {

          this.selectedTags.push(tagData[0].tag);
          this.selectedTagIds.push(tagData[0]._id);
          this.selectedTagWithId.push({ value: tagData[0].tag, _id: tagData[0]._id });
          this.selectedTagsEvent.emit(this.selectedTags);
          this.selectedTagIdsEvent.emit(this.selectedTagIds);
          this.responseTags = []
        }

      }
    }
  }



  remove(tag: string): void {
    const index = this.selectedTags.indexOf(tag);
    this.selectedTagWithId.forEach((obj, i) => {
      if (obj.value === tag) {
        this.selectedTagWithId.splice(i, 1);
      }
    });

    if (index >= 0) {
      this.selectedTags.splice(index, 1);
      this.selectedTagIds.splice(index, 1);
    }
    this.selectedTagIdsEvent.emit(this.selectedTagIds);
    this.selectedTagsEvent.emit(this.selectedTags);
  }

  selected(event: MatAutocompleteSelectedEvent): void {

    let index = this.selectedTags.findIndex(tag => tag === event.option.value.tag);
    if (index === -1) {

      if (!event.option.value._id) {
        this.onTagToSubmit(this.searchString);
      } else {
        this.selectedTag = true;
        this.selectedTags.push(event.option.value.tag);
        this.selectedTagIds.push(event.option.value._id);
        this.selectedTagWithId.push({ value: event.option.value.tag, _id: event.option.value._id });
        this.selectedTagsEvent.emit(this.selectedTags);
        this.selectedTagIdsEvent.emit(this.selectedTagIds);
      }
    }

    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }


  private _filter(value: string): string[] {
    const filterValue = value.toLowerCase();

    return this.allTags.filter(fruit => fruit.toLowerCase().indexOf(filterValue) === 0);
  }

  ngOnDestroy() {
    this._store.dispatch(new ClearTagAction());
  }

}