import { Action } from '@ngrx/store';

export enum CapabilitiesActionTypes {
    GET_CAPABILITIES = '[Capabilities] Get Capabilities',
    GET_CAPABILITIES_SUCCESS = '[Capabilities] Get Capabilities Sucess',
    GET_ALL_CAPABILITIES_OF_ROLES = '[Capabilities] Get All Capabilities Of Roles',
    GET_ALL_CAPABILITIES_OF_ROLES_SUCCESS = '[Capabilities] Get All Capabilities Of Roles Success',
    ADD_CAPABILITY = '[Capabilities] Add Capability',
    ADD_CAPABILITY_SUCCESS = '[Capabilities] Add Capability Success',
    REMOVE_CAPABILITY = '[Capabilities] Remove Capability',
    REMOVE_CAPABILITY_SUCCESS = '[Capabilities] Remove Capability Success',
    GET_ALL_ROLES= '[Capabilities] Get All Roles',
    GET_ALL_ROLES_SUCCESS= '[Capabilities] Get All Roles Success',
    ADD_ROLE = '[Roles] Add role',
    ADD_ROLE_SUCCESS = '[Roles] Add Role Success',
    EDIT_ROLE = '[Roles] Edit Role',
    EDIT_ROLE_SUCCESS = '[Roles] Edit Role Success',
    ADD_REMOVE_CAPABILITY = '[Capabilities] Add Remove Capability Success',
    ADD_REMOVE_CAPABILITY_SUCCESS = '[Capabilities] Add Remove Capability',
    RESET_CAPABILITY = '[Capabilities] Reset All Capability',
    RESET_ALL_CAPABILITIES_ROLES_SUCCESS = '[Capabilities] Reset All Capability Success',
    GET_CAPABILITIES_ROLES_HISTORY_LOG = '[Capabilities] Get all roles and capability log',
    GET_CAPABILITIES_ROLES_LOG_SUCCESS = '[Capabilities] Get all roles and capability log success',

}

export class GetCapabilities implements Action {
    readonly type: string = CapabilitiesActionTypes.GET_CAPABILITIES;
    constructor() { }
}

export class GetCapabilitiesSuccess implements Action {
    readonly type: string = CapabilitiesActionTypes.GET_CAPABILITIES_SUCCESS;
    constructor(public capabilities: any) { }
}

export class GetAllCapabilitiesOfRoles implements Action {
    readonly type: string = CapabilitiesActionTypes.GET_ALL_CAPABILITIES_OF_ROLES;
    constructor() {}
}

export class GetAllCapabilitiesOfRolesSuccess implements Action {
    readonly type: string = CapabilitiesActionTypes.GET_ALL_CAPABILITIES_OF_ROLES_SUCCESS;
    constructor(public allCapabilitiesOfRoles: any) { }
}

export class AddCapability implements Action {
    readonly type: string = CapabilitiesActionTypes.ADD_CAPABILITY;
    constructor(public payload: any) { }
}

export class AddCapabilitySuccess implements Action {
    readonly type: string = CapabilitiesActionTypes.ADD_CAPABILITY_SUCCESS;
    constructor(public response: any) { }
}

export class RemoveCapability implements Action {
    readonly type: string = CapabilitiesActionTypes.REMOVE_CAPABILITY;
    constructor(public payload: any) { }
}

export class RemoveCapabilitySuccess implements Action {
    readonly type: string = CapabilitiesActionTypes.REMOVE_CAPABILITY_SUCCESS;
    constructor(public response: any) { }
}

export class GetAllRoles implements Action {
    readonly type: string = CapabilitiesActionTypes.GET_ALL_ROLES;
    constructor() { }
}

export class GetAllRolesSuccess implements Action {
    readonly type: string = CapabilitiesActionTypes.GET_ALL_ROLES_SUCCESS;
    constructor(public roles: any) { }
}

export class AddRole implements Action {
    readonly type: string = CapabilitiesActionTypes.ADD_ROLE;
    constructor(public payload: any) {}
}

export class AddRoleSuccess implements Action {
    readonly type: string = CapabilitiesActionTypes.ADD_ROLE_SUCCESS;
    constructor(public response: any) {}
}

export class EditRole implements Action {
    readonly type: string = CapabilitiesActionTypes.EDIT_ROLE;
    constructor(public role: string, public payload: any) {}
}

export class EditRoleSuccess implements Action {
    readonly type: string = CapabilitiesActionTypes.EDIT_ROLE_SUCCESS;
    constructor(public response: any) {}
}


export class AddRemoveCapability implements Action {
    readonly type: string = CapabilitiesActionTypes.ADD_REMOVE_CAPABILITY;
    constructor(public payload: any) { }
}

export class AddRemoveCapabilitySuccess implements Action {
    readonly type: string = CapabilitiesActionTypes.ADD_REMOVE_CAPABILITY_SUCCESS;
    constructor(public response: any) { }
}

export class ResetCapability implements Action {
    readonly type: string = CapabilitiesActionTypes.RESET_CAPABILITY;
    constructor() { }
}

export class ResetCapabilitySuccess implements Action {
    readonly type: string = CapabilitiesActionTypes.RESET_ALL_CAPABILITIES_ROLES_SUCCESS;
    constructor(public allCapabilitiesOfRoles: any) { }
}


export class GetCapabilityHistoryLog implements Action {
    readonly type: string = CapabilitiesActionTypes.GET_CAPABILITIES_ROLES_HISTORY_LOG;
    constructor() { }
}

export class GetCapabilityHistoryLogSuccess implements Action {
    readonly type: string = CapabilitiesActionTypes.GET_CAPABILITIES_ROLES_LOG_SUCCESS;
    constructor(public response: any) { }
}
