import { Component, OnInit } from '@angular/core';
import { GetProjectList } from '../../home/procurement/store/procurement.action';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';
import { Location } from '@angular/common';
@Component({
  selector: 'app-create-entity',
  templateUrl: './create-entity.component.html',
  styleUrls: ['./create-entity.component.scss']
})
export class CreateEntityComponent implements OnInit {
  projectArray: any = [];
  constructor(private store: Store<AppState>, public location: Location) { }

  ngOnInit() {
    this.store.dispatch(new GetProjectList());
    this.store
      .select((state) => state.procumentManagement.projectList)
      .subscribe((list: any) => {
        if (list.length > 0) {
          this.projectArray = list;
          console.log(this.projectArray);
          
        }
      });
  }

}
