import { Injectable } from '@angular/core';
import { API } from 'src/app/services/Api.service';
import { Effect, Actions, ofType } from '@ngrx/effects';
import {
    UserManagementActionTypes,
    GetUsers,
    GetUserSuccess,
    GetUserDetails,
    GetUserDetailsSuccess,
    GetUserImage,
    GetUserImageSuccess,
    GetProjectCodes,
    InviteUser,
    AddRolesNProjectsToUser,
    ChangeUserState,
    ChangeUserStateSuccess,
    GetRoles,
    GetRolesSuccess,
    GetProjectCodesSuccess,
    ChangePassword,
    ChangePasswordSuccess,
    InviteBulkUser,
    InviteBulkUserSuccess,
    UserProfileLog,
    UserProfileLogSuccess,
    UserLoginLog,
    UserLoginLogSuccess,
    ReplaceUser,
    ChangePasswordByAdmin,
    UserChangeEmail,
    UserChangeEmailSuccess,
    UserChangePhone,
    UserChangePhoneSuccess,
    ResetUser
} from './user-managment.actions';
import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Location } from '@angular/common';
import { NotificationsService } from 'angular2-notifications';
import { ErrorAction } from 'src/app/login/store/auth.actions';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/app.reducer';
import { LoaderShow, LoaderHide } from '../../../loader/store/loader.actions'

@Injectable()
export class UserManagementEffects {

    @Effect()
    getUser$ = this.action$.pipe(
        ofType(UserManagementActionTypes.GET_USERS),
        switchMap((action: GetUsers) => {
            this.store.dispatch(new LoaderShow())
            return this.api.getUsers(action.payload).pipe(
                map((users: any) => {
                    this.store.dispatch(new LoaderHide())
                    return new GetUserSuccess(users);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getUserById$ = this.action$.pipe(
        ofType(UserManagementActionTypes.GET_USER_DETAILS),
        switchMap((action: GetUserDetails) => {
            return this.api.getUserById(action.id).pipe(
                map((user: any) => {
                    return new GetUserDetailsSuccess(user);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    getUserImage$ = this.action$.pipe(
        ofType(UserManagementActionTypes.GET_USER_IMAGE),
        switchMap((action: GetUserImage) => {
            return this.api.getuserImage(action.id).pipe(
                map((image: any) => {
                    return new GetUserImageSuccess(image);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    getRoles$ = this.action$.pipe(
        ofType(UserManagementActionTypes.GET_ROLES),
        switchMap((action: GetRoles) => {
            return this.api.getRoles().pipe(
                map((response: any) => {
                    return new GetRolesSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getProjectCodes$ = this.action$.pipe(
        ofType(UserManagementActionTypes.GET_PROJECT_CODES),
        switchMap((action: GetProjectCodes) => {
            return this.api.getProjectCodes().pipe(
                map((projects: any) => {
                    return new GetProjectCodesSuccess(projects.data);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    inviteUser$ = this.action$.pipe(
        ofType(UserManagementActionTypes.INVITE_USER),
        switchMap((action: InviteUser) => {
            const body = {
                email: action.payload.email,
                firstName: action.payload.firstName,
                middleName: action.payload.middleName,
                lastName: action.payload.lastName,
                role: action.payload.role,
                // version:action.payload.version
                cities_1_flag: action.payload.cities_1_flag,
                cities_2_flag: action.payload.cities_2_flag,
            };
            return this.api.inviteUser(body).pipe(
                map((response: any) => {
                    /* const payload = {
                      role: action.payload.role,
                    };
                    if (action.payload.projects && action.payload.projects.length > 0) {
                      payload['project'] = action.payload.projects;
                    }
                    return new AddRolesNProjectsToUser(response.userId, payload); */
                    this.location.back();
                    this.notificationService.success(`User invitation sent successfully`);
                    return new GetUsers({ limit: 40, page: 1 });
                }),
                catchError(err => {
                    // this.notificationService.error(err.error.errors[0].error);
                    return of(new ErrorAction(err));
                })
            );
        })
    );

    @Effect({ dispatch: false })
    resendInvite$ = this.action$.pipe(
        ofType(UserManagementActionTypes.RESEND_INVITE),
        switchMap((action: InviteUser) => {
            const body = {
                id: action.payload.id,
                role: action.payload.role
            };
            return this.api.resendInvite(body).pipe(
                map((response: any) => {
                    this.notificationService.success(`User reinvited successfully`);
                }),
                catchError(err => {
                    this.notificationService.error(err.error.errors[0].error);
                    return of(new ErrorAction(err));
                })
            );
        })
    );

    @Effect()
    inviteBulkUser$ = this.action$.pipe(
        ofType(UserManagementActionTypes.INVITE_BULK_USER),
        switchMap((action: InviteBulkUser) => {
            // console.log('TCL: action', action);
            //   const body = {
            //     upfile: action.payload.upfile,
            //   };
            // console.log('TCL: body', body);
            console.log('TCL: action.payload', action.payload);
            return this.api.inviteBulkUser(action.payload).pipe(
                map((response: any) => {
                    // this.location.back();
                    this.notificationService.success(`Bulk user invitations sent successfully`);
                    this.store.dispatch(new GetUsers({ limit: 40, page: 1 }));
                    return new InviteBulkUserSuccess(response);
                }),
                catchError(err => {
                    return of(new ErrorAction(err));
                })
            );
        })
    );

    @Effect()
    addRolesToUser$ = this.action$.pipe(
        ofType(UserManagementActionTypes.ADD_ROLES_TO_USER),
        switchMap((action: AddRolesNProjectsToUser) => {
            return this.api.addRolesToUser(action.userId, action.payload).pipe(
                map((response: any) => {
                    this.location.back();
                    this.notificationService.success(`User Invited Successfully.`);
                    return new GetUsers({ limit: 40, page: 1 });
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    changeUserState$ = this.action$.pipe(
        ofType(UserManagementActionTypes.CHANGE_USER_STATE),
        switchMap((action: ChangeUserState) => {
            return this.api.changeUserStatus(action.userId).pipe(
                map((response: any) => {
                    console.log(response);
                    this.notificationService.success('Status updated successfully');
                    this.store.dispatch(new GetUsers({ limit: 40, page: 1 }));
                    return new ChangeUserStateSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    changePassword$ = this.action$.pipe(
        ofType(UserManagementActionTypes.CHANGE_PASSWORD),
        switchMap((action: ChangePassword) => {
            return this.api.changePassword(action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message);
                    return new ChangePasswordSuccess(response);
                }),
                catchError((error) => {
                    if (error && error.error.errors[0].error == "Password is Invalid") {
                        error.error.errors[0].error = "Old password is incorrect";
                    }
                    return of(new ErrorAction(error));
                })
            );
        })
    );

    @Effect()
    getUserProfileLog$ = this.action$.pipe(
        ofType(UserManagementActionTypes.USER_PROFILE_LOG),
        switchMap((action: UserProfileLog) => {
            return this.api.getUserProfileLogById(action.userId).pipe(
                map((response: any) => {
                    return new UserProfileLogSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    getUserLoginLog$ = this.action$.pipe(
        ofType(UserManagementActionTypes.USER_LOGIN_LOG),
        switchMap((action: UserLoginLog) => {
            return this.api.getUserLoginLogById(action.userId).pipe(
                map((response: any) => {
                    return new UserLoginLogSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    userChangeEmail$ = this.action$.pipe(
        ofType(UserManagementActionTypes.USER_CHANGE_EMAIL),
        switchMap((action: UserChangeEmail) => {
            return this.api.userChangeEmail(action.payload, action.userId).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message)
                    return new UserChangeEmailSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    userChangePhone$ = this.action$.pipe(
        ofType(UserManagementActionTypes.USER_CHANGE_PHONE),
        switchMap((action: UserChangePhone) => {
            return this.api.userChangePhone(action.payload, action.userId).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message);
                    return new UserChangePhoneSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    )

  @Effect({dispatch: false})
  ChangePasswordByAdmin$ = this.action$.pipe(
    ofType(UserManagementActionTypes.CHANGE_PASSWORD_BY_ADMIN),
    switchMap((action: ChangePasswordByAdmin) => {
      return this.api.changePasswordByAdmin(action.userID, action.payload).pipe(
        map((response: any) => {
          this.notificationService.success(`Password updated successfully`);
        }),
        catchError((error) => {
          if (error && error.error.errors[0].error == "Password is Invalid" ) {
            error.error.errors[0].error = "Old password is incorrect";
          }
          return of(new ErrorAction(error));
        })
    )
    }));

    @Effect()
    replaceUser$ = this.action$.pipe(
        ofType(UserManagementActionTypes.REPLACE_USER),
        switchMap((action: ReplaceUser) => {
            return this.api.changeUser(action.oldUserId, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message);
                    return new GetUsers({ limit: 40, page: 1 });
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    resetUser$ = this.action$.pipe(
        ofType(UserManagementActionTypes.RESET_USER),
        switchMap((action: ResetUser) => {
            return this.api.resetUser(action.userId, action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.message);
                    return new GetUsers({ limit: 40, page: 1, ascending: false });
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );



    constructor(
        private api: API,
        private action$: Actions,
        private location: Location,
        private notificationService: NotificationsService,
        private router: Router,
        private store: Store<AppState>,
    ) { }
}
