import { Component, Inject, ElementRef, ViewChild, Output, EventEmitter, OnDestroy } from "@angular/core";
import { MatDialogRef, MatDialog, MAT_DIALOG_DATA, MatAutocomplete, MatChipInputEvent, MatAutocompleteSelectedEvent } from "@angular/material";
import { Store } from "@ngrx/store";
import { FormControl, FormGroup, FormBuilder, FormArray } from "@angular/forms";
import { ENTER, COMMA } from "@angular/cdk/keycodes";
import { Observable, Subject } from "rxjs";
import { debounceTime, distinctUntilChanged, tap, map, takeUntil } from "rxjs/operators";
import { GetAllTagsListStartAction, AddTagStartAction, ClearTagAction } from "src/app/features/home/tasks/store/tasks.actions";
import { AddMessageTag } from '../../features/home/user-messages/_store/messages.actions'

import { newMessageTagService } from "../../services/messageTags.service";
import { LoaderShow } from "src/app/features/loader/store/loader.actions";

import { NotificationsService } from "angular2-notifications";

@Component({
  selector: 'tag-messages-dialog',
  templateUrl: './tag-messages-dialog.component.html',
  styleUrls: ['./tag-messages-dialog.component.scss']
})

export class TagMessagesDialogComponent implements OnDestroy {

  visible = true;
  selectable = true;
  removable = true;
  separatorKeysCodes: number[] = [ENTER, COMMA];
  tagCtrl = new FormControl();
  filteredFruits: Observable<string[]>;
  // fruits: string[] = ['Lemon'];
  allTags: string[] = [];
  selectedItems: string[] = [];
  selectedLinkIds = [];
  formData = new FormData();
  stopper$: Subject<any> = new Subject();


  @ViewChild('tagInput', { static: false }) tagInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: false }) matAutocomplete: MatAutocomplete;
  form: FormGroup;
  responseTags$: Observable<any>;
  selectedTagIds: any = [];
  selectedTagWithId: any = [];
  addTag: boolean = false;
  specialCharregex;
  multiSpaceRegex;
  error = false;
  taskDetails: any = {};
  searchTasks: boolean = false;
  responseTags: any;
  newTagFlag: boolean = false
  regExError: boolean = false
  searchString;
  // allSelectedApproverIds: Array<any> = [];
  // allSelectedEndorserIds: Array<any> = [];
  // allSelectedApproverobjs: Array<any> = [];
  // allSelectedEndorserObjs: Array<any> = [];

  selectedIds: Array<any> = [];
  dropDownList: Array<any> = [];


  constructor(private dialogRef: MatDialogRef<any>, private dialog: MatDialog, @Inject(MAT_DIALOG_DATA) public data: any, private _store: Store<any>,
    private notificationService: NotificationsService, private fb: FormBuilder, public tagService: newMessageTagService, ) {
    this.dialogRef.disableClose = true;
    this.taskDetails = data.allDetails;
    if (data.type === 'messageTags') {
      if (data.allDetails && data.allDetails.tags && data.allDetails.tags.length) {
        this.selectedItems = data.allDetails.tags.map((tagData) => (tagData.tag));

        this.selectedTagIds = data.allDetails.tags.map((tagData) => (tagData._id))
      }
      if (data.new) {
        this.newTagFlag = data.new
        this.selectedTagWithId = [];
        this.selectedTagWithId = Object.assign([], data.allDetails);
        // this.tagService.currentTags.subscribe(tag => { this.selectedTagWithId = tag; })
      }
    }

  }

  ngOnInit() {
    let loginId = JSON.parse(localStorage.getItem('auth')).user.details._id;
    this._store.select(state => state.tasks.tagsList).pipe(takeUntil(this.stopper$)).subscribe((taglist) => {

      taglist.unshift({ tag: ` + Create tag for '${this.searchString}'` });


      this.responseTags = taglist.filter((tagData) => !this.selectedTagIds.includes(tagData._id));
      if (taglist.length) {
        this.addTag = false;
      }
      else {
        this.addTag = true;
      }

      // console.log(state.tasks.tasksList, "list")
    })

    this.responseTags$ = this._store.select(state => {
      if (this.searchTasks) {

        return state.tasks.tasksList
      }
    })
    this._store.select(state => state.tasks.tasksList).subscribe((list) => {
    })
    this.tagCtrl.valueChanges.pipe(debounceTime(500), distinctUntilChanged()).subscribe(((val) => {

      this.searchString = val;


      this.error = false;
      this.regExError = false
      if (val != null) {

        (typeof (val) === 'number') ? val.toString() : val;
        let value = val.trim();
        if (value.length) {
          if (this.data.type === 'messageTags') {
            this.specialCharregex = new RegExp(/^[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/);
            this.multiSpaceRegex = new RegExp(/[ ]{2,}/);

            if (this.specialCharregex.test(value)) {
              let value = val.trim();
              this._store.dispatch(new GetAllTagsListStartAction({ 'search': value }))
            }
            else {
              this.responseTags = []
              this.error = false;
              this.regExError = true;
            }

            if (!this.multiSpaceRegex.test(value)) {
              let value = val.trim();
              this._store.dispatch(new GetAllTagsListStartAction({ 'search': value }))
            }
            else {
              this.responseTags = []
              this.regExError = false;
              this.error = true;
            }

          }

        }
        else {
          this.responseTags = []
        }
      }
    }))


    this._store.select(state => state.tasks.addedTag).pipe(takeUntil(this.stopper$)).subscribe((response) => {
      //check for response when added new tag
      if (Object.keys(response).length) {
        let data = response;
        let index = this.selectedItems.findIndex(tag => tag === response.tag);
        if (index === -1) {
          this.selectedItems.push(response.tag);
          this.selectedTagIds.push(data._id);

          let tagIndex = this.selectedTagWithId.findIndex(tag => tag.tag === response.tag);
          if (tagIndex === -1) {
            this.selectedTagWithId.push({ tag: response.tag, _id: data._id });
          }

          this._store.dispatch(new ClearTagAction());
          this.responseTags = []
        }
      }
    })

    this._store.select(state => state.resources.shareSuggestions).subscribe((list) => {
      this.dropDownList = list.filter((obj) => !this.selectedIds.includes(obj._id)).filter((obj) => obj._id !== loginId)

    });
    this.responseTags = [];
  }


  add(event: MatChipInputEvent): void {
    const input = event.input;
    const value = event.value.trim();
    
    this.addTagOnSubmit(value)

    if (input) {
      input.value = '';
    }

    this.tagCtrl.setValue(null);
  }

  addTagOnSubmit(value) {
    if (!this.matAutocomplete.isOpen) {
      if (value || '') {
        if (this.data.type === 'messageTags') {
          this.specialCharregex = new RegExp(/^[A-Za-z0-9 ]*[A-Za-z0-9][A-Za-z0-9 ]*$/);
          this.multiSpaceRegex = new RegExp(/[ ]{2,}/);
          if (this.specialCharregex.test(value) && !this.multiSpaceRegex.test(value)) {

            let index = this.selectedItems.findIndex((tag) => tag === value.trim());

            if (index === -1) {
              if (this.addTag) {
                this._store.dispatch(new LoaderShow());
                this._store.dispatch(new AddTagStartAction({ 'tag': value }));
                this.responseTags = []
              }
              else {
                this._store.dispatch(new AddTagStartAction({ 'tag': value }));
                this.responseTags = []
              }
            }
          }
        }
      }
      // Reset the input value
      // this.selectedTagWithId.reduce((unique, item) => unique.includes(item) ? unique : [...unique, item], [])
    }
    else {
      if ((this.data.type === 'tags') || (this.data.type === 'messageTags')) {
        let index = this.responseTags.findIndex((tagData) => (tagData.tag === value))

        if (index === -1) {
          this._store.dispatch(new LoaderShow());
          this._store.dispatch(new AddTagStartAction({ 'tag': value }));
          this.responseTags = []

        }
        else {
          let index = this.selectedItems.findIndex((tag) => tag === value.trim());
          let tagData: any = this.responseTags.filter((tag) => tag.tag === value);
          if (index === -1) {
            this.selectedItems.push(tagData[0].tag);
            this.selectedTagIds.push(tagData[0]._id);
            this.selectedTagWithId.push({ tag: tagData[0].tag, _id: tagData[0]._id });
            // this.selectedTagsEvent.emit(this.selectedTags);
            // this.selectedTagIdsEvent.emit(this.selectedTagIds);
            this.responseTags = []
          }

        }
      }
    }
  }


  remove(item: string): void {
    if ((this.data.type == "tags") || (this.data.type === 'messageTags')) {
      const index = this.selectedItems.indexOf(item);
      this.selectedTagWithId.forEach((obj, i) => {
        if (obj.tag === item) {
          this.selectedTagWithId.splice(i, 1);
        }
      });

      if (index >= 0) {
        this.selectedTagIds.splice(index, 1);
        this.selectedItems.splice(index, 1);
      }
    }
  }
  displayFn(selectedValue) {
    if (selectedValue && Object.keys(selectedValue).length) {
      // console.log(this.selectedApproversIds, "ids")
      return selectedValue.taskId;
    }
  }

  selected(event: MatAutocompleteSelectedEvent): void {
    if ((this.data.type === "tags") || (this.data.type === 'messageTags')) {
      let index = this.selectedItems.findIndex(tag => tag === event.option.value.tag);
      if (index === -1) {

        if (!event.option.value._id) {
          this.addTagOnSubmit(this.searchString);
        } else {
          this.selectedItems.push(event.option.value.tag);
          this.selectedTagIds.push(event.option.value._id);
          this.selectedTagWithId.push({ tag: event.option.value.tag, _id: event.option.value._id });
        }
      }
    }
    this.tagInput.nativeElement.value = '';
    this.tagCtrl.setValue(null);
  }

  submit() {
    let dataObj = { "tags": [] }
    let selectedTags = Object.assign([], this.selectedTagIds)
    dataObj['tags'] = selectedTags



    if (this.data.type === 'messageTags' && !this.newTagFlag) {
      this.formData.append('tags', JSON.stringify(selectedTags));
      this._store.dispatch(new AddMessageTag(this.taskDetails.parentId, dataObj, this.taskDetails.threadId))
    }

    if (this.data.type === 'messageTags' && this.newTagFlag) {
      // this.tagService.newTagsList(this.selectedTagWithId);
      // this.dialogRef.close(dataObj)
      this.notificationService.success("Tags updated successfully")
      this.dialogRef.close(this.selectedTagWithId);
    }
    else {

      this.dialogRef.close();
    }

  }

  onNoClick(): void {
    this.dialogRef.close();
  }
  ngOnDestroy() {
    this.stopper$.next();
    this.stopper$.complete();
  }

}