import { Action } from "@ngrx/store";
import { ContractManagementActions, GetAllContractsSuccess, GetMyContractsSuccess, GetContractDetailsSuccess, ContractId, ContractIdSuccess, CloseContractSuccess, ArchiveContractSuccess, GetPaymentScheduleDetailsSuccess, GetContractActivityLogSuccess, GetInvoiceDetailsSuccess, GetIndividualInvoiceDetailsSuccess, GetTimesheetDetailsSuccess, GetTimesheetProjectDetailsSuccess, GetTimesheetProjectMonthDetailsSuccess, DownloadContractsExcelSuccess, EditContractSuccess, GetInvoiceActivityLogSuccess, GetTimesheetActivityLogSuccess, CreateInvoice, CreateInvoiceSuccess, InvoiceActionSuccess } from "./contract-management.actions";
import { ContractManagementEffects } from "./contract-management.effects";

export interface ContractManagement {
    allContracts: Array<any>;
    myContracts: Array<any>;
    contracDetail: any;
    paymentSchedule: any;
    invoices: any;
    contractId: any;
    activityLog: Array<any>;
    invoiceDetail: any;
    timesheetDetails: any;
    timesheetProjectDetails: any;
    timesheetProjectMonthDetails: any;
    contractsList: any;
    createInvoice: any
    invoiceAction: any
}

const intiState = {
    allContracts: [],
    myContracts: [],
    contracDetail: {},
    paymentSchedule: {},
    contractId: "",
    activityLog: [],
    invoices: [],
    invoiceDetail: [],
    timesheetDetails: [],
    timesheetProjectDetails: [],
    timesheetProjectMonthDetails: [],
    contractsList: [],
    createInvoice: {},
    invoiceAction: {},
};


export function reducer(state: ContractManagement = intiState, action: Action): ContractManagement {
    switch (action.type) {
        case ContractManagementActions.GET_ALL_CONTRACTS_SUCCESS:
            console.log("abc", Object.assign([], state, { allContracts: (action as GetAllContractsSuccess).data }));
            return Object.assign([], state, { allContracts: (action as GetAllContractsSuccess).data });
        case ContractManagementActions.GET_MY_CONTRACTS_SUCCESS:
            return Object.assign([], state, { myContracts: (action as GetMyContractsSuccess).data });
        case ContractManagementActions.GET_CONTRACT_DETAILS_SUCCESS:
            console.log((action as GetContractDetailsSuccess).response);
            return {
                ...state,
                contracDetail: (action as GetContractDetailsSuccess).response
            }
        case ContractManagementActions.EDIT_CONTRACT_SUCCESS:
            console.log((action as EditContractSuccess).response);
            return {
                ...state,
                contracDetail: (action as EditContractSuccess).response
            }
        case ContractManagementActions.CREATE_INVOICE_SUCCESS:
            return {
                ...state,
                createInvoice: (action as CreateInvoiceSuccess).response
            }
        case ContractManagementActions.CLOSE_CONTRACT_SUCCESS:
            return Object.assign({}, state, {
                contracDetail: {
                    ...state.contracDetail,
                    status: "closed"
                }
            })
        case ContractManagementActions.ARCHIVE_CONTRACT_SUCCESS:
            return Object.assign({}, state, {
                contracDetail: {
                    ...state.contracDetail,
                    status: "archived"
                }
            })
        case ContractManagementActions.REOPEN_CONTRACT_SUCCESS:
            return Object.assign({}, state, {
                contracDetail: {
                    ...state.contracDetail,
                    status: "active"
                }
            })
        case ContractManagementActions.CONTRACT_ID:
            console.log(action);
            return {
                ...state,
                contractId: (action as ContractId).response
            }
        case ContractManagementActions.GET_PAYMENT_SCHEDULE_DETAILS_SUCCESS:
            console.log((action as GetPaymentScheduleDetailsSuccess).response);
            return {
                ...state,
                paymentSchedule: (action as GetPaymentScheduleDetailsSuccess).response
            }
        case ContractManagementActions.UPDATE_MILESTONES_SUCCESS:
            return {
                ...state
            }
        case ContractManagementActions.GET_CONTRACT_ACTIVITY_SUCCESS:
            return Object.assign({}, state, { activityLog: (action as GetContractActivityLogSuccess).response });
        case ContractManagementActions.GET_INVOICE_ACTIVITY_SUCCESS:
            return Object.assign({}, state, { activityLog: (action as GetInvoiceActivityLogSuccess).response });
        case ContractManagementActions.GET_TIMESHEET_ACTIVITY_SUCCESS:
            return Object.assign({}, state, { activityLog: (action as GetTimesheetActivityLogSuccess).response });
        case ContractManagementActions.CREATE_INVOICE_SUCCESS:
            return {
                ...state
            }
        case ContractManagementActions.GET_INVOICE_DETAILS_SUCCESS:
            console.log((action as GetInvoiceDetailsSuccess).response);
            return {
                ...state,
                invoices: (action as GetInvoiceDetailsSuccess).response
            }
        case ContractManagementActions.GET_INDIVIDUAL_INVOICE_DETAILS_SUCCESS:
            console.log((action as GetIndividualInvoiceDetailsSuccess).response);
            return {
                ...state,
                invoiceDetail: (action as GetIndividualInvoiceDetailsSuccess).response
            }
        case ContractManagementActions.GET_TIMESHEET_DETAILS_SUCCESS:
            console.log((action as GetTimesheetDetailsSuccess).response);
            return {
                ...state,
                timesheetDetails: (action as GetTimesheetDetailsSuccess).response
            }
        case ContractManagementActions.GET_TIMESHEET_PROJECT_DETAILS_SUCCESS:
            console.log((action as GetTimesheetProjectDetailsSuccess).response);
            return {
                ...state,
                timesheetProjectDetails: (action as GetTimesheetProjectDetailsSuccess).response
            }
        case ContractManagementActions.GET_TIMESHEET_PROJECT_MONTH_DETAILS_SUCCESS:
            console.log((action as GetTimesheetProjectMonthDetailsSuccess).response);
            return {
                ...state,
                timesheetProjectMonthDetails: (action as GetTimesheetProjectMonthDetailsSuccess).response
            }
        case ContractManagementActions.DOWNLOAD_LIST_SUCCESS:
            console.log((action as DownloadContractsExcelSuccess).response);
            var contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
            var blob = new Blob([(action as DownloadContractsExcelSuccess).response.arrayBuffer()], { type: contentType });
            // return blob;
            return {
                ...state,
                contractsList: blob
            }
        case ContractManagementActions.INVOICE_ACTIONS_SUCCESS:
            return {
                ...state,
                invoiceAction: (action as InvoiceActionSuccess).response
            }
        default:
            return state;
    }
}
