import { Injectable } from '@angular/core';
import { API } from 'src/app/services/Api.service';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';

import {
    GetTags,
    GetTagsSuccess,
    TagsActionTypes,
    MergeTags,
    MergeTagsSuccess,
    GetTagsActivityLog,
    GetTagsActivityLogSuccess,
} from './tags.action';

import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { Location } from '@angular/common';
import { NotificationsService } from 'angular2-notifications';
import { ErrorAction } from 'src/app/login/store/auth.actions';

@Injectable()
export class TagsEffects {
    @Effect()
    getTags$ = this.action$.pipe(
        ofType(TagsActionTypes.GET_TAGS),
        switchMap((action: GetTags) => {
            return this.api.getTags().pipe(
                map((tags: any) => {
                    return new GetTagsSuccess(tags);
                }),
                catchError(err => of({ type: '' }))
            );
        })
    );

    @Effect()
    mergeTags$ = this.action$.pipe(
        ofType(TagsActionTypes.MERGE_TAGS),
        switchMap((action: MergeTags) => {
            return this.api.mergeTags(action.payload).pipe(
                map((response: any) => {
                    this.notificationService.success(response.Message);
                    this.store.dispatch(new GetTags());
                    return new MergeTagsSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            )
        })
    )

    @Effect()
    getTagsLogs$ = this.action$.pipe(
        ofType(TagsActionTypes.GET_TAGS_ACTIVITY_LOGS),
        switchMap((action: GetTagsActivityLog) => {
            return this.api.getTagsLogs().pipe(
                map((response: any) => {
                    return new GetTagsActivityLogSuccess(response);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    constructor(
        private api: API,
        private action$: Actions,
        private location: Location,
        private notificationService: NotificationsService,
        private store: Store<AppState>
      ) { }
}