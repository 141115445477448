import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { BehaviorSubject } from "rxjs";
import { environment } from "src/environments/environment";
export const BASE_URL = environment.host;

@Injectable({
  providedIn: "root",
})
export class BudgetService {
  constructor(private http: HttpClient) {}
  // grant
  private state$ = new BehaviorSubject<any>("");

  changeState(myChange) {
    this.state$.next(myChange);
  }

  getState() {
    return this.state$.asObservable();
  }

  // budgetstage
  private statebudget$ = new BehaviorSubject<any>(0);

  changebudgetState(myChange) {
    this.statebudget$.next(myChange);
  }

  getBudgetState() {
    return this.statebudget$.asObservable();
  }

  // // expensestage
  // private stateexpense$ = new BehaviorSubject<any>(0);

  // changeexpenseState(myChange) {
  //   this.stateexpense$.next(myChange);
  // }

  // getexpenseState() {
  //   return this.stateexpense$.asObservable();
  // }

  yearList: any = ["2023-24", "2024-25"];
  totalFundyearList: any = ["2023", "2024", "2025"];


MonthListDist: any = {
  "April":4,
  "May":5,
  "June":6,
  "July":7,
  "August":8,
  "September":9,
  "October":10,
  "November":11,
  "December":12,
  "January":1,
  "February":2,
  "March":3,
};

  MonthList: any = [
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
    "January",
    "February",
    "March",
  ];
  TotalFundMonthList: any = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  // drop down List

  dropDownCategoryList(id, payload) {
    return this.http.get(
      `${BASE_URL}/budget/configuration/category/list/name/${id}`,
      {
        params: payload,
      }
    );
  }

  dropDownSubCategoryList(obj, payload) {
    return this.http.get(
      `${BASE_URL}/budget/configuration/sub-category/list/name/${obj.proj_id}/${obj.cat_id}`,
      {
        params: payload,
      }
    );
  }

  // for category
  categoryList(payload, id) {
    return this.http.get(
      `${BASE_URL}/budget/configuration/category/list/${id}`,
      {
        params: payload,
      }
    );
  }
  createCategory(payload) {
    return this.http.post(
      `${BASE_URL}/budget/configuration/category/create`,
      payload
    );
  }
  categoryDetails(id) {
    return this.http.get(
      `${BASE_URL}/budget/configuration/category/detail/${id}`
    );
  }

  editCategory(id, payload) {
    return this.http.patch(
      `${BASE_URL}/budget/configuration/category/${id}`,
      payload
    );
  }
  deleteCategory(id) {
    return this.http.delete(`${BASE_URL}/budget/configuration/category/${id}`);
  }

  // for Sub Category

  subcategoryList(payload, id) {
    return this.http.get(
      `${BASE_URL}/budget/configuration/sub-category/list/${id}`,
      {
        params: payload,
      }
    );
  }
  createsubCategory(payload) {
    return this.http.post(
      `${BASE_URL}/budget/configuration/sub-category/create`,
      payload
    );
  }

  subcategoryDetails(id) {
    return this.http.get(
      `${BASE_URL}/budget/configuration/sub-category/detail/${id}`
    );
  }

  editsubCategory(id, payload) {
    return this.http.patch(
      `${BASE_URL}/budget/configuration/sub-category/${id}`,
      payload
    );
  }
  deletesubCategory(id) {
    return this.http.delete(
      `${BASE_URL}/budget/configuration/sub-category/${id}`
    );
  }

  checkforSubCategory(id, fy) {
    return this.http.get(
      `${BASE_URL}/budget/configuration/sub-category/check/category?budgetYear=${fy}&projectId=${id}`
    );
  }

  // for line

  lineItemList(payload, id) {
    return this.http.get(
      `${BASE_URL}/budget/configuration/line-item/list/${id}?categoryId=${
        payload.categoryId == undefined ? "" : payload.categoryId
      }&subCategoryId=${
        payload.subCategoryId == undefined ? "" : payload.subCategoryId
      }&budgetYear=${payload.budgetYear}`
    );
  }
  createlineItem(payload) {
    return this.http.post(
      `${BASE_URL}/budget/configuration/line-item/create`,
      payload
    );
  }

  lineItemDetails(id) {
    return this.http.get(
      `${BASE_URL}/budget/configuration/line-item/detail/${id}`
    );
  }

  editlineItem(id, payload) {
    return this.http.patch(
      `${BASE_URL}/budget/configuration/line-item/${id}`,
      payload
    );
  }
  deletelineItem(id) {
    return this.http.delete(`${BASE_URL}/budget/configuration/line-item/${id}`);
  }

  checkforLineItem(id, fy) {
    return this.http.get(
      `${BASE_URL}/budget/configuration/line-item/check/sub-category?budgetYear=${fy}&projectId=${id}`
    );
  }

  // for all

  allList(payload, id) {
    return this.http.get(`${BASE_URL}/budget/configuration/list/${id}`, {
      params: payload,
    });
  }

  updatecategorySequence(payload) {
    return this.http.patch(
      `${BASE_URL}/budget/configuration/category/sequance/update`,
      payload
    );
  }

  updatesubcategorySequence(payload) {
    return this.http.patch(
      `${BASE_URL}/budget/configuration/sub-category/sequance/update`,
      payload
    );
  }

  //for Grant
  grantGenerate(payload) {
    return this.http.post(`${BASE_URL}/budget/entries/grant/generate`, payload);
  }

  grantList(payload, id) {
    return this.http.get(`${BASE_URL}/budget/entries/grant/list/${id}`, {
      params: payload,
    });
  }

  grantUpdate(payload, id) {
    return this.http.patch(`${BASE_URL}/budget/entries/grant/${id}`, payload);
  }

  grantDetails(id) {
    return this.http.get(`${BASE_URL}/budget/entries/grant/detail/${id}`);
  }

  deleteGrant(id) {
    return this.http.delete(`${BASE_URL}/budget/entries/grant/${id}`);
  }

  // entries - budget

  checkEurExist(id, fy) {
    return this.http.get(
      `${BASE_URL}/budget/entries/budget/checkEurRateExistence/${id}?FyYear=${fy}`
    );
  }

  generateTemplate(id, payload) {
    return this.http.post(
      `${BASE_URL}/budget/entries/budget/generate/${id}`,
      payload
    );
  }

  updateBudget(id, payload) {
    return this.http.patch(
      `${BASE_URL}/budget/entries/budget/lineItemTable/update/${id}`,
      payload
    );
  }

  budgetList(payload, id) {
    return this.http.get(`${BASE_URL}/budget/entries/budget/list/${id}`, {
      params: payload,
    });
  }

  // deleteBudget(id) {
  //   return this.http.delete(`${BASE_URL}/budget/${id}`);
  // }

  // entries - expenses

  checkEurExistExpenses(id, fy, month) {
    return this.http.get(
      `${BASE_URL}/budget/entries/expense/checkEurRateExistence/${id}?FyYear=${fy}&month=${month}`
    );
  }

  generateExpensesTemplate(id, payload) {
    return this.http.post(
      `${BASE_URL}/budget/entries/expense/generate/${id}`,
      payload
    );
  }

  expenseList(payload, id) {
    return this.http.get(`${BASE_URL}/budget/entries/expense/list/${id}`, {
      params: payload,
    });
  }

  updateExpense(payload,id) {
    return this.http.patch(
      `${BASE_URL}/budget/entries/expenses/update/${id}`,
      payload
    );
  }

  // deleteExpense(id) {
  //   return this.http.delete(`${BASE_URL}/budget/${id}`);
  // }

  // MIS -Budget Report
  getReport(id, fy, month) {
    return this.http.get(
      `${BASE_URL}/budget/mis/budgetreport?projectId=${id}&budgetYear=${fy}&month=${month}`
    );
  }

  downloadReport(id, fy, month, type) {
    return this.http.get(
      `${BASE_URL}/budget/mis/budgetreport?projectId = ${id}&budgetYear=${fy}&month=${month}&reportType=${type}`
    );
  }

  monthlyChart(id, fy) {
    return this.http.get(
      `${BASE_URL}/budget/mis/dashboard/expense/byFY?projectId=${id}&budgetYear=${fy}`
    );
  }

  //Saction Grant

  sactionCreate(payload) {
    return this.http.post(
      `${BASE_URL}/budget/entries/grant/sanction/create`,
      payload
    );
  }

  sanctionsUpdate(id, payload) {
    return this.http.patch(
      `${BASE_URL}/budget/entries/grant/sanction/${id}`,
      payload
    );
  }

  sactionsDelete(id) {
    return this.http.delete(`${BASE_URL}/budget/entries/grant/sanction//${id}`);
  }

  sactionList(payload, projetId, Saction_id) {
    return this.http.get(
      `${BASE_URL}/budget/entries/grant/sanction/${projetId}/${Saction_id}`,
      {
        params: payload,
      }
    );
  }

  //Total Funds
  totalFundList(payload, projetId, Saction_id) {
    return this.http.get(
      `${BASE_URL}/budget/entries/grant/sanction/totalfund/list/${projetId}`,
      {
        params: payload,
      }
    );
  }

  totalFundCreate(payload) {
    return this.http.post(
      `${BASE_URL}/budget/entries/grant/sanction/totalfund`,
      payload
    );
  }

  totalFundUpdate(id, payload) {
    return this.http.patch(
      `${BASE_URL}/budget/entries/grant/sanction/totalfund/${id}`,
      payload
    );
  }

  totalFundDelete(id) {
    return this.http.delete(
      `${BASE_URL}/budget/entries/grant/sanction/totalfund/${id}`
    );
  }

  //NIUA UTILIZATION
  niuaUtilizationList(payload, projetId, Saction_id) {
    return this.http.get(
      `${BASE_URL}/budget/entries/grant/utilization/niua/list/${projetId}`,
      {
        params: payload,
      }
    );
  }

  niuaUtilizationCategoryId(payload, projetId, Saction_id) {
    return this.http.get(`${BASE_URL}/budget/entries/grant/distributors`, {
      params: payload,
    });
  }

  niuaUtilizationCreate(payload) {
    return this.http.post(
      `${BASE_URL}/budget/entries/grant/utilization/niua`,
      payload
    );
  }

  niuaUtilizationUpdate(id, payload) {
    return this.http.patch(
      `${BASE_URL}/budget/entries/grant/utilization/niua/${id}`,
      payload
    );
  }

  niuaUtilizationDelete(id) {
    return this.http.delete(
      `${BASE_URL}/budget/entries/grant/utilization/niua/${id}`
    );
  }

  getMisDashboardData(id, start_year, start_month, end_year, end_month) {
    // return this.http.get(
    //   `${BASE_URL}/budget/entries/grant/mis/dashboard/report/projectId=${id}&budgetYear=${fyyear}`
    // );

    return this.http.get(
      `${BASE_URL}/budget/entries/grant/mis/dashboard/report/${id}?startMonth=${start_month}&startYear=${start_year}&tillMonth=${end_year}&tillYear=${end_month}`
    );
  }

  getLogList(id) {
    return this.http.get(`${BASE_URL}/budget/configuration/${id}/logList`);
  }

  getBudgetSummery(id, year) {
    return this.http.get(
      `${BASE_URL}/budget/entries/summary?projectId=${id}&budgetYear=${year}`
    );
  }

  getExpenseSummery(id, year,month) {
    return this.http.get(
      `${BASE_URL}/budget/entries/expense/summary?projectId=${id}&budgetYear=${year}&month=${month}`
    );
  }

  sublineItemDelete(subline_id) {
    return this.http.delete(
      `${BASE_URL}/budget/entries/expense/sub_line_item/${subline_id}`
    );
  }

  getSublineList(project_id,lineItem_id) {
    return this.http.get(`${BASE_URL}/budget/entries/expense/sub_line_item/${project_id}/${lineItem_id}`);
  }

  sublineItemCreateUpdate(payload) {
    return this.http.post(
      `${BASE_URL}/budget/entries/expense/sub_line_item/edit`,
      payload
    );
  }
  
}
