import { Injectable } from '@angular/core';
import { API } from 'src/app/services/Api.service';
import { Effect, Actions, ofType } from '@ngrx/effects';
import { AppState } from 'src/app/app.reducer';
import { Store } from '@ngrx/store';

import {
    GetAllRoles,
    GetAllRolesSuccess,
    NotificationsActionTypes,
    ErrorAction,
    GetNotifications,
    GetNotificationsSuccess,
    UpdateNotificationSetting,
    UpdateNotificationSettingSuccess,
    GetWebNotifications,
    GetWebNotificationsSuccess,
    UpdateWebNotificationSeen,
    UpdateWebNotificationSeenSuccess,
    GetWebNotificationCount,
    GetWebNotificationCountSuccess,
    AddRemoveNotificationSetting,
    AddRemoveNotificationSettingSuccess,
    GetNewNotifications,
    GetNewNotificationsSuccess
} from './notifications.actions';


import { switchMap, map, catchError } from 'rxjs/operators';
import { of } from 'rxjs';
import { NotificationsService } from 'angular2-notifications';
import { LoaderHide } from 'src/app/features/loader/store/loader.actions';

import { SocketService } from '../../../../services/socket.service';



@Injectable()
export class NotificationsEffects {

    @Effect()
    getAllRoles$ = this.action$.pipe(
        ofType(NotificationsActionTypes.GET_ALL_ROLES),
        switchMap((action: GetAllRoles) => {
            return this.api.getAllRoles().pipe(
                map((response: any) => {
                    return new GetAllRolesSuccess(response.roles || []);
                }),
                catchError(err => of(new ErrorAction(err)))
            );
        })
    );

    @Effect()
    getNotifications$ = this.action$.pipe(
        ofType(NotificationsActionTypes.GET_NOTIFICATIONS),
        switchMap((action: GetNotifications) => {
            return this.api.getNotifications().pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide());
                    return new GetNotificationsSuccess(response);
                }),
                catchError((err) => {
                    this.store.dispatch(new LoaderHide());
                    return of(new ErrorAction(err))
                })
            );
        })
    )

    @Effect()
    updateNotificationSetting$ = this.action$.pipe(
        ofType(NotificationsActionTypes.UPDATE_NOTIFICATION_SETTING),
        switchMap((action: UpdateNotificationSetting) => {
            return this.api.updateNotificationSetting(action.payload).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide());
                    this.notificationService.success("Notifications updated successfully");
                    return new UpdateNotificationSettingSuccess(response);
                }),
                catchError((err) => {
                    this.store.dispatch(new LoaderHide());
                    //this.notificationService.error("Error occurred while updating notification setting");
                    return of(new ErrorAction(err));
                })
            )
        })
    )

    @Effect()
    getWebNotifications$ = this.action$.pipe(
        ofType(NotificationsActionTypes.GET_WEB_NOTIFICATIONS),
        switchMap((action: GetWebNotifications) => {
            return this.api.getWebNotifications(action.payload).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide());
                    return new GetWebNotificationsSuccess(response);
                }),
                catchError((err) => {
                    this.store.dispatch(new LoaderHide());
                    return of(new ErrorAction(err))
                })
            );
        })
    )

    @Effect()
    updateWebNotificationSeen$ = this.action$.pipe(
        ofType(NotificationsActionTypes.UPDATE_WEB_NOTIFICATION_SEEN),
        switchMap((action: UpdateWebNotificationSeen) => {
            return this.api.updateWebNotificationSeen(action.notificationId).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide());
                    return new UpdateWebNotificationSeenSuccess(response);
                }),
                catchError((err) => {
                    this.store.dispatch(new LoaderHide());
                    return of(new ErrorAction(err));
                })
            )
        })
    )

    @Effect()
    getWebNotificationCount$ = this.action$.pipe(
        ofType(NotificationsActionTypes.GET_WEB_NOTIFICATION_COUNT),
        switchMap((action: GetWebNotificationCount) => {
            return this.socket.getData().pipe(
                map((response: any) => {
                    return new GetWebNotificationCountSuccess(response);
                }),
                catchError((err) => {
                    return of(new ErrorAction(err));
                })
            )
        })
    )

    @Effect()
    addRemoveNotificationSetting$ = this.action$.pipe(
        ofType(NotificationsActionTypes.ADD_REMOVE_NOTIFICATION_SETTING),
        switchMap((action: AddRemoveNotificationSetting) => {
            return this.api.addRemoveNotificationSetting(action.payload).pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide());
                    this.notificationService.success("Notifications updated successfully");
                    return new AddRemoveNotificationSettingSuccess(response);
                }),
                catchError((err) => {
                    this.store.dispatch(new LoaderHide());
                    return of(new ErrorAction(err));
                })
            )
        })
    )

    @Effect()
    getNewNotifications$ = this.action$.pipe(
        ofType(NotificationsActionTypes.GET_NEW_NOTIFICATIONS),
        switchMap((action: GetNewNotifications) => {
            return this.api.getNewNotifications().pipe(
                map((response: any) => {
                    this.store.dispatch(new LoaderHide());
                    return new GetNewNotificationsSuccess(response);
                }),
                catchError((err) => {
                    this.store.dispatch(new LoaderHide());
                    return of(new ErrorAction(err))
                })
            );
        })
    )

    constructor(
        private api: API,
        private action$: Actions,
        private notificationService: NotificationsService,
        private store: Store<AppState>,
        private socket: SocketService
    ) { }

}