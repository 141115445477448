import { Action } from '@ngrx/store';
import {
    ProgramDashboardActionTypes,
    GetProjects, GetProjectsSucess,
    GetMembersSucess, GetProjectDetailSucess, GetTasksForProjectsSuccess, GetProjectPillarSuccess, GetGanttChartDataSuccess, GetMemberRolesSuccess, GetMembersListSuccess,
    GetProjectStepsSuccess,
    GetProjectPhaseSuccess,
    GetFinancialInfoSuccess,
    GetAllComplaincesSuccess,
    GetRisksForProjectsSuccess,
    GetPhasesSuccess,
    GetOpportunitiesForProjectsSuccess,
    GetRiskInfoSuccess,
    GetOpportunityDetailSuccess,
    GetInstallmentsSuccess,
    GetRiskHistorySuccess,
    AddRiskSForProjectsSuccess,
    GetMyOpenCommentSuccess,
    GetCommentsHistoryLogSuccess,
    GetCommentedUsersSuccess,
    GetOtherUsersOpenCommentSuccess,
    GetProjectActivityLog,
    GetProjectActivityLogSuccess,
    GetOpportunityHistorySuccess,
    GetOpportunityHistory,
    GetProjectPhasesSuccess,
    GetProjectStatesSuccess,
    GetDashboardInfoSuccess,
    GetProjectFinancialInfoSuccess,
    GetL1IndicatorsSuccess,
    GetKCIndicatorsSuccess,
    GetESIndicatorsSuccess,
    GetPLFIndicatorsSuccess,
    GetInnovationMatrixSuccess,
    GetProjectCitiesSuccess,
    AggregatedValuesSuccess,
    GetSectoralIndicatorsSuccess,
    GetProjectTaskInfoSuccess,
    GetESIndicatorsListSuccess,
    GetBudgetProjectsSucess,
    GetProcurementProjectsSucess,
} from './program-dashboard.actions';

export interface ProgramDashboard {
    projects: any;
    members: Array<any>;
    projectInfo: any;
    riskInfo: any;
    opportunityDetail: any;
    tasks: Array<any>;
    pillars: Array<any>;
    ganttChart: any;
    memberRoles: Array<any>;
    membersList: object;
    steps: Array<any>;
    financialInfo: any;
    projectFinancialInfo: any;
    taskInfo: any;
    compliances: any;
    risks: Array<any>;
    opportunities: Array<any>;
    phases: Array<any>;
    installments: Array<any>;
    riskHistory: Array<any>;
    addRiskRes: object;
    myOpenComment: any;
    commentsHistoryLog: Array<any>;
    commentedUsers: Array<any>;
    otherUsersOpenComment: any;
    activityLog: Array<any>;
    opportunityHistory: any;
    projectPhases: Array<any>;
    states: Array<any>;
    l1Indicators: Array<any>,
    kcIndicators: Array<any>,
    esIndicators: Array<any>,
    plfIndicators: Array<any>,
    dashboardInfo: any,
    innovationMatrix: any,
    cities: any,
    values: any,
    sectoralIndicators: Array<any>,
    ESIndicatorsList:any,
}

const intiState = {
    projects: {
        projectList: [],
        details: {}
    },
    projectInfo: {},
    riskInfo: {},
    opportunityDetail: {},
    members: [],
    tasks: [],
    pillars: [],
    ganttChart: {},
    memberRoles: [],
    membersList: [],
    steps: [],
    financialInfo: {},
    projectFinancialInfo: {},
    compliances: {},
    risks: [],
    opportunities: [],
    phases: [],
    installments: [],
    riskHistory: [],
    addRiskRes: {},
    myOpenComment: {},
    commentsHistoryLog: [],
    commentedUsers: [],
    otherUsersOpenComment: {},
    activityLog: [],
    opportunityHistory: [],
    projectPhases: [],
    states: [],
    l1Indicators: [],
    kcIndicators: [],
    esIndicators: [],
    plfIndicators: [],
    dashboardInfo: {},
    innovationMatrix: {},
    cities: {},
    values: {},
    sectoralIndicators: [],
    taskInfo: [],
    ESIndicatorsList:[]
};


export function reducer(state: ProgramDashboard = intiState, action: Action): ProgramDashboard {
    switch (action.type) {
        case ProgramDashboardActionTypes.GET_PROJECTS_SUCCESS:
            let projectList = (action as GetProjectsSucess).response.docs;
            let projectPageNum = (action as GetProjectsSucess).response.page;
            if (projectPageNum == 1) {
                state.projects.projectList = [];
            }
            return Object.assign({}, state, {
                projects: {
                    projectList: [...projectList],
                    details: (action as GetProjectsSucess).response
                }
            });
        case ProgramDashboardActionTypes.GET_BUDGET_PROJECTS_SUCCESS:
                let bugetProjectList = (action as GetBudgetProjectsSucess).response.data;
                let budgetProjectPageNum = (action as GetBudgetProjectsSucess).response.page;
                if (budgetProjectPageNum == 1) {
                    state.projects.bugetProjectList = [];
                }
                return Object.assign({}, state, {
                    projects: {
                        bugetProjectList: [...bugetProjectList],
                        details: (action as GetBudgetProjectsSucess).response
                    }
                });
        case ProgramDashboardActionTypes.GET_MEMBERS_SUCCESS:
            return Object.assign({}, state, { members: (action as GetMembersSucess).response });
        case ProgramDashboardActionTypes.GET_PROJECT_DETAILS_SUCCESS:
            return Object.assign({}, state, { projectInfo: (action as GetProjectDetailSucess).response });
        case ProgramDashboardActionTypes.GET_TASKS_FOR_PROJECT_SUCCESS:
            return Object.assign({}, state, { tasks: (action as GetTasksForProjectsSuccess).response });
        case ProgramDashboardActionTypes.GET_RISKS_FOR_PROJECT_SUCCESS:
            return Object.assign({}, state, { risks: (action as GetRisksForProjectsSuccess).response });
        case ProgramDashboardActionTypes.GET_OPPORTUNITIES_FOR_PROJECT_SUCCESS:
            return Object.assign({}, state, { opportunities: (action as GetOpportunitiesForProjectsSuccess).response });
        case ProgramDashboardActionTypes.GET_PILLARS_SUCCESS:
            return Object.assign({}, state, { pillars: (action as GetProjectPillarSuccess).pillars });
        case ProgramDashboardActionTypes.GET_GANTT_CHART_DATA_SUCCESS:
            return Object.assign({}, state, { ganttChart: (action as GetGanttChartDataSuccess).response });
        case ProgramDashboardActionTypes.GET_MEMBER_ROLES_SUCCESS:
            return Object.assign({}, state, { memberRoles: (action as GetMemberRolesSuccess).memberRoles });
        case ProgramDashboardActionTypes.GET_MEMBERS_LIST_SUCCESS:
            return Object.assign({}, state, { membersList: (action as GetMembersListSuccess).members });
        case ProgramDashboardActionTypes.LIST_STEP_SUCCESS:
            return Object.assign({}, state, { steps: (action as GetProjectStepsSuccess).steps });
        case ProgramDashboardActionTypes.GET_FINANCIAL_INFO_SUCCESS:
            return Object.assign({}, state, { financialInfo: (action as GetFinancialInfoSuccess).info });
        case ProgramDashboardActionTypes.GET_ALL_COMPLAINCES_SUCCESS:
            return Object.assign({}, state, { compliances: (action as GetAllComplaincesSuccess).response });
        // case ProgramDashboardActionTypes.GET_PHASES_SUCCESS:
        //     return Object.assign({}, state, { phases: (action as GetPhasesSuccess).response });
        case ProgramDashboardActionTypes.LIST_PHASE_SUCCESS:
            return Object.assign({}, state, { phases: (action as GetProjectPhaseSuccess).phases });
        case ProgramDashboardActionTypes.GET_INSTALLMENTS_SUCCESS:
            return Object.assign({}, state, { installments: (action as GetInstallmentsSuccess).installments });
        case ProgramDashboardActionTypes.GET_RISK_DETAILS_SUCCESS:
            return Object.assign({}, state, { riskInfo: (action as GetRiskInfoSuccess).response });
        case ProgramDashboardActionTypes.GET_OPPORTUNITY_DETAIL_SUCCESS:
            return Object.assign({}, state, { opportunityDetail: (action as GetOpportunityDetailSuccess).response });
        case ProgramDashboardActionTypes.GET_RISK_HISTORY_SUCCESS:
            return Object.assign({}, state, { riskHistory: (action as GetRiskHistorySuccess).response });
        case ProgramDashboardActionTypes.ADD_RISKS_FOR_PROJECT_SUCCESS:
            return Object.assign({}, state, { addRiskRes: (action as AddRiskSForProjectsSuccess).response });
        case ProgramDashboardActionTypes.CLEAR_ACTION:
            return Object.assign({}, state, {
                installments: [],
                dashboardInfo: {}
            })
        case ProgramDashboardActionTypes.GET_MY_OPEN_COMMENT_SUCCESS:
            return Object.assign({}, state, { myOpenComment: (action as GetMyOpenCommentSuccess).response });
        case ProgramDashboardActionTypes.GET_COMMENTS_HISTORY_LOG_SUCCESS:
            return Object.assign({}, state, { commentsHistoryLog: (action as GetMyOpenCommentSuccess).response });
        case ProgramDashboardActionTypes.GET_COMMENTED_USERS_SUCCESS:
            return Object.assign({}, state, { commentedUsers: (action as GetCommentedUsersSuccess).response });
        case ProgramDashboardActionTypes.GET_OTHER_USERS_OPEN_COMMENT_SUCCESS:
            return Object.assign({}, state, { otherUsersOpenComment: (action as GetOtherUsersOpenCommentSuccess).response });
        case ProgramDashboardActionTypes.GET_PROJECT_ACTIVITY_LOG_SUCCESS:
            return Object.assign({}, state, { activityLog: (action as GetProjectActivityLogSuccess).response });
        case ProgramDashboardActionTypes.GET_OPPORTUNITY_HISTORY_SUCCESS:
            return Object.assign({}, state, { opportunityHistory: (action as GetOpportunityHistorySuccess).response });

        case ProgramDashboardActionTypes.GET_PROJECT_PHASES_SUCCESS:
            return Object.assign({}, state, { projectPhases: (action as GetProjectPhasesSuccess).projectPhases });

        case ProgramDashboardActionTypes.GET_PROJECT_STATES_SUCCESS:
            return Object.assign({}, state, { states: (action as GetProjectStatesSuccess).states });
        case ProgramDashboardActionTypes.GET_DASHBOARD_INFO_SUCCESS:
            return Object.assign({}, state, { dashboardInfo: (action as GetDashboardInfoSuccess).response });
        case ProgramDashboardActionTypes.GET_PROJECT_FINANCIAL_INFO_SUCCESS:
            return Object.assign({}, state, { projectFinancialInfo: (action as GetProjectFinancialInfoSuccess).information });
        case ProgramDashboardActionTypes.GET_L1_INDICATORS_SUCCESS:
            return Object.assign({}, state, { l1Indicators: (action as GetL1IndicatorsSuccess).response });
        case ProgramDashboardActionTypes.GET_KC_INDICATORS_SUCCESS:
            return Object.assign({}, state, { kcIndicators: (action as GetKCIndicatorsSuccess).response });
        case ProgramDashboardActionTypes.GET_ES_INDICATORS_SUCCESS:
            return Object.assign({}, state, { esIndicators: (action as GetESIndicatorsSuccess).response });
        case ProgramDashboardActionTypes.GET_PLF_INDICATORS_SUCCESS:
            return Object.assign({}, state, { plfIndicators: (action as GetPLFIndicatorsSuccess).response });
        case ProgramDashboardActionTypes.GET_INNOVATION_MATRIX_SUCCESS:
            return Object.assign({}, state, { innovationMatrix: (action as GetInnovationMatrixSuccess).response });
        case ProgramDashboardActionTypes.GET_CITIES_SUCCESS:
            return Object.assign({}, state, { cities: (action as GetProjectCitiesSuccess).response });
        case ProgramDashboardActionTypes.GET_AGGREGATED_VALUES_SUCCESS:
            return Object.assign({}, state, { values: (action as AggregatedValuesSuccess).response });
        case ProgramDashboardActionTypes.GET_SECTORAL_INDICATORS_SUCCESS:
            return Object.assign({}, state, { sectoralIndicators: (action as GetSectoralIndicatorsSuccess).response });
        case ProgramDashboardActionTypes.GET_PROJECT_TASK_INFO_SUCCESS:
            return Object.assign({}, state, { taskInfo: (action as GetProjectTaskInfoSuccess).response });
        case ProgramDashboardActionTypes.GET_E_S_INDICATORS_LIST_SUCCESS:
            return Object.assign({}, state, { ESIndicatorsList: (action as GetESIndicatorsListSuccess).response });

            case ProgramDashboardActionTypes.GET_PROCUREMENT_PROJECTS_SUCCESS:
                let procurementProjectList = (action as GetProcurementProjectsSucess).response.data;
                let procurementProjectPageNum = (action as GetProcurementProjectsSucess).response.page;
                if (procurementProjectPageNum == 1) {
                    state.projects.procurementProjectList = [];
                }
                return Object.assign({}, state, {
                    projects: {
                        procurementProjectList: [...procurementProjectList],
                        details: (action as GetProcurementProjectsSucess).response
                    }
                });
        default:
            return state;
    }
}
